<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-text color="primary">
            <h6>
              <ion-icon name="chatbox-ellipses-outline"></ion-icon> {{
              'NewMessage.nyMelding' | translate }}
            </h6>
          </ion-text>
          <form [formGroup]="messageForm">
            <div class="kcma-icon-item">
              <ion-icon name="person-outline"></ion-icon>
              <select formControlName="userID">
                <option value="0">
                  {{ 'NewMessage.sendTilAlle' | translate }}
                </option>
                <option
                  *ngFor="let driver of drivers"
                  value="{{driver.UserID}}"
                >
                  {{driver.Name}}
                </option>
              </select>
            </div>
            <textarea
              rows="8"
              maxlength="780"
              placeholder="{{'NewMessage.skrivMelding' | translate}}"
              class="width margin-top-10"
              formControlName="message"
            ></textarea>
            <ion-buttons class="ion-float-right">
              <ion-button
                (click)="dismiss()"
                color="grey"
                fill="outline"
                size="small"
              >
                <span>{{ 'General.avbryt' | translate }}</span>
              </ion-button>
              <ion-button
                (click)="newMessage()"
                [disabled]="!messageForm.valid"
                fill="solid"
                size="small"
              >
                <span>{{ 'General.save' | translate }}</span>
              </ion-button>
            </ion-buttons>
          </form>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
