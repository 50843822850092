<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'CustomerLead.title' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <form [formGroup]="customerLeadForm">
        <ion-row class="ion-no-padding" class="deviation">
          <ion-col size-xs="12" size-md="6" class="ion-no-padding">
            <ion-list lines="none">
              <ion-item class="text-small">
                <ion-label>
                  <p>
                    <ion-icon
                      name="alert-circle-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'CustomerLead.type' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <ion-row>
                    <ion-col size-xs="12" class="ion-no-padding">
                      <ion-radio-group
                        [(ngModel)]="customerTypeOption"
                        [ngModelOptions]="{standalone: true}"
                      >
                        <ion-list lines="none" class="ion-no-padding">
                          <ion-row>
                            <ion-col
                              size-xs="12"
                              size-md="4"
                              class="ion-no-padding"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="customerType.Private"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'CustomerLead.private' | translate }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                            <ion-col
                              size-xs="12"
                              size-md="4"
                              class="ion-no-padding"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="customerType.Company"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'CustomerLead.company' | translate }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                            <ion-col
                              size-xs="12"
                              size-md="4"
                              class="ion-no-padding"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="customerType.Unknown"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'CustomerLead.unknown' | translate }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                          </ion-row>
                        </ion-list>
                      </ion-radio-group>
                    </ion-col>
                  </ion-row>
                </ion-label>
              </ion-item>
              <ion-item class="text-small z-index mt-2">
                <ion-label class="ion-no-margin">
                  <p>
                    <ion-icon
                      name="person-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'CustomerLead.contactInfo' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <div class="ion-margin-start ion-margin-top">
                    <mat-form-field appearance="fill">
                      <mat-label></mat-label>
                      <input
                        type="text"
                        placeholder="{{ 'NewOrder.customerName' | translate }}"
                        matInput
                        [formControl]="customerCtrl"
                        [matAutocomplete]="auto"
                        #customerComplete
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFn"
                      >
                        <mat-option
                          *ngFor="let customer of filteredCustomers | async"
                          [value]="customer"
                        >
                          {{ customer.name }} - {{ customer.company }} - {{
                          customer.description }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <input
                      type="text"
                      formControlName="customer"
                      placeholder="{{ 'CustomerLead.search' | translate }}"
                    />
                  </div>
                  <ion-text color="danger">
                    <div class="m-t-5">
                      <p
                        class="ion-no-margin ion-padding-start"
                        *ngIf="(customerLeadForm.controls.customer.errors?.required && !customerLeadForm.controls.customer.pristine) || (customerTypeOption===customerType.Private && customerLeadForm.controls.customer.errors?.incorrect)"
                      >
                        {{'CustomerLead.required' | translate }}
                      </p>
                    </div>
                  </ion-text>
                </ion-label>
              </ion-item>

              <ion-item class="text-small">
                <ion-label class="ion-no-margin">
                  <div class="ion-padding-start">
                    <input
                      type="text"
                      formControlName="contact"
                      placeholder="{{ 'CustomerLead.Contact' | translate }}"
                    />
                  </div>
                </ion-label>
              </ion-item>

              <ion-item class="text-small">
                <ion-label class="ion-no-margin">
                  <div class="ion-padding-start">
                    <input
                      type="text"
                      formControlName="phone"
                      placeholder="{{ 'CustomerLead.phoneNumber' | translate }}"
                    />
                  </div>
                </ion-label>
              </ion-item>
              <ion-item class="text-small">
                <ion-label class="ion-no-margin">
                  <div class="ion-padding-start">
                    <input
                      type="text"
                      formControlName="email"
                      placeholder="{{ 'CustomerLead.email' | translate }}"
                    />
                    <ion-text color="danger">
                      <div class="m-t-5">
                        <p
                          class="ion-no-margin"
                          *ngIf="!customerLeadForm.controls.email.valid && !customerLeadForm.controls.email.errors?.required && customerLeadForm.controls.email.dirty && !customerLeadForm.controls.email.pristine"
                        >
                          {{'CustomerLead.validationEmail' | translate }}
                        </p>
                      </div>
                    </ion-text>
                  </div>
                </ion-label>
              </ion-item>

              <ion-item class="text-small ion-padding-start">
                <ion-checkbox
                  color="primary"
                  class="ion-no-margin"
                  formControlName="InContact"
                  [(ngModel)]="inContact"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp; {{'CustomerLead.InContact' | translate }}</ion-label
                >
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-xs="12" size-md="6" class="ion-no-padding">
            <ion-list lines="none">
              <ion-item class="text-small">
                <p>
                  <ion-icon
                    name="checkbox-outline"
                    class="vertical-middle"
                    color="lightFive"
                  ></ion-icon
                  >&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'CustomerLead.productType' | translate }}</span
                    ></ion-text
                  >
                </p>
              </ion-item>
              <ion-item
                class="m-l-24 checkbox text-small ion-padding-start"
                *ngFor="let item of products"
              >
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="item.isChecked"
                  (ngModelChange)="changeSelection()"
                  [ngModelOptions]="{standalone: true}"
                  class="ion-no-margin"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'LeadView.' +item.Name | translate }}</ion-label
                >
              </ion-item>
              <ion-item class="text-small">
                <ion-label>
                  <p>
                    <ion-icon
                      name="chatbubbles-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'CustomerLead.kommentar' | translate | uppercase
                        }}</span
                      ></ion-text
                    >
                  </p>
                  <div class="ion-padding-start ion-padding-top">
                    <textarea rows="4" formControlName="comment"></textarea>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div>
      <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'CustomerLead.back' | translate }}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="save()"
        [disabled]="!customerLeadForm.valid || submitting"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'CustomerLead.saveForm' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
