<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'OrdreDetails.ordre' | translate }} {{order.OrderNo}}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <form [formGroup]="detailsForm">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-lg="6" class="bgWhite">
            <ion-list lines="none">
              <ion-list-header class="ion-no-padding">
                <ion-text *ngIf="isOrderMine()" color="success">
                  <h2>{{ 'OrdreDetails.ordrenErTildeltDeg' | translate }}</h2>
                </ion-text>
                <ion-text
                  *ngIf="!isOrderMine() && order.AssignedDriver > 0"
                  color="danger"
                >
                  <h2>
                    {{'OrdreDetails.denneOrdrenErTildelt' | translate }} -
                    {{order.AssignedToName}}
                  </h2>
                </ion-text>
              </ion-list-header>
              <ion-item class="text-small ion-no-padding">
                <ion-label>
                  <p class="h6">
                    <ion-icon name="person-outline"></ion-icon> &nbsp;
                    <ion-text color="primary"
                      ><span
                        >{{ 'OrdreDetails.kundeLeveringsadresse' | translate
                        }}</span
                      >
                    </ion-text>
                  </p>
                  <span
                    class="text-small"
                    [class.biggest-font]="userService.fontSettings==4"
                    [class.bigger-font]="userService.fontSettings==2"
                  >
                    <div class="orderlistHeading">
                      <strong>{{order.DeliveryName}}</strong>&nbsp;
                      ({{order.CustomerID}})
                      <ng-container
                        *ngIf="order.CustomerName != order.DeliveryName"
                      >
                        <br />
                        {{order.CustomerName}}
                      </ng-container>
                      <br />
                      {{order.DeliveryAddress1}}
                      <br />{{order.DeliveryPostalCode}} {{order.DeliveryCity}}
                    </div>
                  </span>

                  <p>&nbsp;</p>
                  <ion-row
                    class="ion-no-padding margin-top-10 box-divider-shadow pt-10"
                  >
                    <ion-col size="12">
                      <p class="h6">
                        <ion-icon
                          name="calendar-outline"
                          class="vertical-text-bottom"
                          color="lightFive"
                        ></ion-icon>
                        &nbsp;
                        <ion-text color="primary"
                          ><span
                            >{{ 'OrdreDetails.leveringsdato' | translate
                            }}</span
                          ></ion-text
                        >
                      </p>
                      <div
                        class="padding-left"
                        [class.biggest-font]="userService.fontSettings==4"
                        [class.bigger-font]="userService.fontSettings==2"
                      >
                        {{ showDeliveryDate() | date:dateFormat}}

                        <!--Delivery date-->
                        &nbsp;&nbsp;
                        <ion-button
                          class="vertical-middle"
                          size="small"
                          fill="outline"
                          (click)="openModal = true"
                        >
                          <ion-modal
                            [cssClass]="'center'"
                            id="dtOrder"
                            [isOpen]="openModal"
                            (didDismiss)="closeModal()"
                          >
                            <ng-template>
                              <ion-datetime
                                #picker
                                presentation="date"
                                formControlName="deliveryDate"
                                [(ngModel)]="selectedDeliveryDate"
                                size="cover"
                                [showDefaultButtons]="true"
                                doneText="{{ 'buttons.ok' | translate }}"
                                cancelText="{{ 'buttons.cancel' | translate }}"
                                class="dateText"
                                first-day-of-week="1"
                                (ionChange)="dateTimeUpdated($event)"
                              >
                              </ion-datetime>
                            </ng-template>
                          </ion-modal>
                          <ion-icon
                            name="calendar-outline"
                            slot="start"
                          ></ion-icon
                          >&nbsp;{{ 'General.edit' | translate }}
                        </ion-button>
                      </div>
                    </ion-col>
                    <ion-col size-xs="12" size-sm>
                      <p class="h6">
                        <ion-icon
                          name="person-outline"
                          color="lightFive"
                        ></ion-icon>
                        &nbsp;
                        <ion-text color="primary" class="upper"
                          ><span
                            >{{ 'OrdreDetails.CustomerBuyer' | translate
                            }}</span
                          ></ion-text
                        >
                      </p>
                      <span
                        class="text-small"
                        [class.biggest-font]="userService.fontSettings==4"
                        [class.bigger-font]="userService.fontSettings==2"
                        >{{order.CustomerBuyer}}</span
                      >
                    </ion-col>
                    <ion-col size-xs="12" size-sm>
                      <p class="h6">
                        <ion-icon
                          name="person-outline"
                          color="lightFive"
                        ></ion-icon>
                        &nbsp;
                        <ion-text color="primary" class="upper"
                          ><span
                            >{{ 'OrdreDetails.OurSeller' | translate }}</span
                          ></ion-text
                        >
                      </p>
                      <span
                        class="text-small"
                        [class.biggest-font]="userService.fontSettings==4"
                        [class.bigger-font]="userService.fontSettings==2"
                        >{{order.Seller}}</span
                      >
                    </ion-col>
                  </ion-row>
                </ion-label>
              </ion-item>

              <ion-item
                class="text-small ion-no-padding margin-top-10 box-divider-shadow pt-10"
              >
                <ion-col size-xs="12" size-sm="6" class="min-height-100">
                  <ion-label>
                    <p class="h6">
                      <ion-icon
                        name="call-outline"
                        color="lightFive"
                      ></ion-icon>
                      &nbsp;
                      <ion-text color="primary"
                        ><span
                          >{{ 'OrdreDetails.kontaktinfo' | translate }}</span
                        ></ion-text
                      >
                    </p>

                    <ion-row>
                      <ion-col size-xs="6">
                        <b>{{ 'OrdreDetails.onSite' | translate }}</b>
                        <div *ngIf="order.PhoneOnSite">
                          <br />
                          <span
                            class="text-small"
                            [class.biggest-font]="userService.fontSettings==4"
                            [class.bigger-font]="userService.fontSettings==2"
                            appPhoneRegex
                            [inputText]="order.PhoneOnSite"
                          >
                          </span>
                        </div>
                      </ion-col>
                      <ion-col size-xs="6">
                        <b>{{ 'OrdreDetails.manager' | translate }}</b>
                        <div *ngIf="order.PhoneManager">
                          <br />
                          <span
                            class="text-small"
                            [class.biggest-font]="userService.fontSettings==4"
                            [class.bigger-font]="userService.fontSettings==2"
                            appPhoneRegex
                            [inputText]="order.PhoneManager"
                          >
                          </span>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-label>
                </ion-col>

                <ion-col size-xs="12" size-sm="6" class="min-height-100">
                  <ion-label>
                    <p class="h6">
                      <ion-icon
                        name="cube-outline"
                        color="lightFive"
                      ></ion-icon>
                      &nbsp;
                      <ion-text color="primary">
                        <span
                          >{{ 'OrdreDetails.leveringsinfo' | translate }}</span
                        ></ion-text
                      >
                    </p>
                    <ion-row>
                      <ion-col size-xs="12">
                        <span *ngIf="order.InternalInfo">
                          <span
                            class="text-small"
                            [class.biggest-font]="userService.fontSettings==4"
                            [class.bigger-font]="userService.fontSettings==2"
                            >{{order.InternalInfo}}</span
                          >
                          <br />
                        </span>
                        <span *ngIf="order.TankInfo">
                          <span
                            class="text-small"
                            [class.biggest-font]="userService.fontSettings==4"
                            [class.bigger-font]="userService.fontSettings==2"
                            >{{order.TankInfo}}</span
                          >
                          <br />
                        </span>
                        <span *ngIf="order.DeliveryInfo">
                          <span
                            class="text-small"
                            [class.biggest-font]="userService.fontSettings==4"
                            [class.bigger-font]="userService.fontSettings==2"
                            >{{order.DeliveryInfo}}</span
                          >
                          <br />
                        </span>
                      </ion-col>
                    </ion-row>
                  </ion-label>
                </ion-col>
              </ion-item>
              <ion-item
                class="text-small ion-no-padding box-divider-shadow pt-10"
                lines="none"
              >
                <ion-label>
                  <div class="padding-left ion-padding-bottom">
                    <ion-icon name="mail-outline" color="lightFive"></ion-icon>
                    &nbsp;
                    <ion-text color="primary" class="upper bold"
                      >{{ 'OrdreDetails.emailReceipt' | translate }}</ion-text
                    >
                    <br />
                    <input
                      type="text"
                      formControlName="emailTo"
                      class="m-t-5"
                    />
                    <ion-item
                      class="item-validation text-small ion-no-padding"
                      lines="none"
                      *ngIf="!detailsForm.controls.emailTo.valid && (detailsForm.controls.emailTo.dirty || submitAttempt)"
                    >
                      <ion-text
                        color="danger"
                        *ngIf="detailsForm.controls.emailTo.errors.emailValidator"
                      >
                        <p ion-text color="danger">
                          {{ 'OrdreDetails.validationEmail' | translate }}
                        </p>
                      </ion-text>
                    </ion-item>
                  </div>
                </ion-label>
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <ion-label color="primary" position="stacked">
                  <p class="h6">
                    <ion-icon
                      name="chatbox-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;
                    <span ion-text color="primary"
                      >{{ 'OrdreDetails.internkommentar' | translate }}</span
                    >
                  </p>
                </ion-label>
                <ion-textarea
                  rows="2"
                  formControlName="orderInternalComments"
                ></ion-textarea>
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <ion-label color="primary" position="stacked">
                  <p class="h6">
                    <ion-icon
                      name="chatbox-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;
                    <ion-text color="primary"
                      ><span
                        >{{ 'OrdreDetails.kommentar' | translate }}</span
                      ></ion-text
                    >
                  </p>
                </ion-label>
                <ion-textarea
                  rows="2"
                  formControlName="orderComments"
                ></ion-textarea>
              </ion-item>

              <ion-item
                class="text-small ion-no-padding"
                *ngIf="!detailsForm.controls.orderComments.valid"
              >
                <ion-label class="ion-no-margin">
                  <ion-text color="danger">
                    <p>{{ 'OrdreDetails.validationComments' | translate }}</p>
                  </ion-text>
                </ion-label>
              </ion-item>
            </ion-list>
            <ion-item lines="none">
              <ion-checkbox
                class="ion-no-margin"
                formControlName="additionalProductsDelivered"
                (ionChange)="additionalProductsDeliveredChanged()"
              ></ion-checkbox>
              <ion-label
                >&nbsp;{{ 'OrdreDetails.andreProdukterErUtlevert' | translate
                }}</ion-label
              >
            </ion-item>
            <ion-item
              lines="none"
              *ngIf="VolumeToStandardTruck && !VolumeToStandardOff"
            >
              <ion-checkbox
                class="ion-no-margin"
                formControlName="doNotCalculateVolume"
              ></ion-checkbox>
              <ion-label
                >&nbsp;{{ 'OrdreDetails.DoNotCalc' | translate }}</ion-label
              >
            </ion-item>
            <ion-item lines="none">
              <ion-checkbox
                class="ion-no-margin"
                formControlName="doNotSendGPS"
              ></ion-checkbox>
              <ion-label
                >&nbsp;{{ 'OrdreDetails.DoNotSendGPS' | translate }}</ion-label
              >
            </ion-item>
          </ion-col>
          <ion-col size-xs="12" size-lg="6" class="bgWhite">
            <div
              class="map-card"
              leaflet
              [leafletOptions]="leafletOptions"
            ></div>
            <div>
              <ion-button
                size="small"
                class="no-padding"
                (click)="leggTilProdukt()"
              >
                <ion-icon name="add-circle-outline"></ion-icon>&nbsp;
                <span>{{ 'OrdreDetails.leggTilProdukt' | translate }}</span>
              </ion-button>
              <ion-button
                size="small"
                class="no-padding pull-right-md"
                (click)="loadDrivingInstructions()"
              >
                <ion-icon name="book-outline"></ion-icon>&nbsp;
                <span>{{ 'OrdreDetails.veibeskrivelse' | translate }}</span>
              </ion-button>
              <div class="clear"></div>
            </div>
            <div class="ion-margin-top">
              <h6>
                <ion-icon
                  name="flame-outline"
                  class="vertical-text-bottom"
                ></ion-icon>
                <ion-text color="primary"><span>PRODUKTER</span></ion-text>
              </h6>
              <ion-row class="grid-header">
                <ion-col size-xs="4">
                  {{ 'OrdreDetails.produkt' | translate }}
                </ion-col>
                <ion-col size-xs="3">
                  {{ 'OrdreDetails.bestilt' | translate }}
                </ion-col>
                <ion-col size-xs="3">
                  {{ 'OrdreDetails.levert' | translate }}
                </ion-col>
                <ion-col size-xs="2">
                  {{ 'OrdreDetails.standard' | translate }} &nbsp;
                  {{order.LoadingTemp}}&deg;C
                </ion-col>
              </ion-row>

              <ion-row
                *ngFor="let orderLine of order.Orderlines"
                formGroupName="orderLines"
              >
                <ion-col size-xs="4" class="litte-big-font-2">
                  <ion-badge [style.background]="orderLine.ProductColorCode"
                    >{{orderLine.ProductShortCode}}</ion-badge
                  >
                  {{orderLine.Description}}
                </ion-col>
                <ion-col size-xs="3" class="litte-big-font-2">
                  <div class="inline-block bestilt">{{orderLine.Quantity}}</div>
                </ion-col>
                <ion-col size-xs="3">
                  <input
                    type="number"
                    appRemoveZeroOnFocus
                    class="border-input ion-no-margin"
                    (click)="validateQuantity(orderLine)"
                    (keyup)="validateQuantity(orderLine)"
                    formControlName="{{'o' + orderLine.LineNo}}"
                    ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                    step="any"
                  />
                  <ng-container
                    *ngIf="!detailsForm.controls.orderLines.get('o' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('o' + orderLine.LineNo).dirty || submitAttempt))"
                  >
                    <ion-text color="danger"
                      ><span
                        *ngIf="detailsForm.controls.orderLines.get('o' + orderLine.LineNo).errors.pattern"
                        >{{ 'OrdreDetails.onlyNumbers' | translate }}</span
                      ></ion-text
                    >
                    <ion-text color="danger"
                      ><span
                        *ngIf="detailsForm.controls.orderLines.get('o' + orderLine.LineNo).errors.required"
                        >{{ 'OrdreDetails.quantityRequired' | translate }}</span
                      ></ion-text
                    >
                  </ng-container>
                  <!--[(ngModel)]="orderLine.QuantityDelivered"-->
                </ion-col>
                <ion-col
                  size-xs="2"
                  *ngIf="VolumeToStandardTruck && orderLine.ProductType == 10"
                >
                  <span class="slight-big-font">
                    <strong
                      >{{orderLine.QuantityDelivered*orderLine.VolumeToStandardFactor
                      | number:'1.0-0'}}</strong
                    >
                  </span>
                </ion-col>
                <ion-col size-xs="2" *ngIf="!VolumeToStandardTruck">
                  <span class="slight-big-font">
                    <strong
                      >{{orderLine.QuantityDelivered | number:'1.0-1'}}</strong
                    >
                  </span>
                </ion-col>
              </ion-row>
              <ion-text color="danger">
                <p *ngFor="let errorMessage of errorMessages">
                  <strong>{{errorMessage}}</strong>
                </p>
              </ion-text>
            </div>
            <div class="pull-right-md"></div>
            <div class="clear"></div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-sm-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="setOrderAsCompleted()"
        [disabled]="!detailsForm.valid ||!isOrderMine() ||!deliveredQuantityExists() || (errorMessages.length > 0)"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'OrdreDetails.ferdigmeldOrdre' | translate }}</span>
      </ion-button>
    </div>
    <!--<button ion-button fill="outline" class="no-padding"><ion-icon name="md-contacts" color="primary"></ion-icon>&nbsp; <span class="black">{{ 'OrdreDetails.overforTilSjafor' | translate }}</span></button>-->
    <div [hidden]="!expand">
      <ion-button
        fill="outline"
        class="no-padding"
        [hidden]="!isOrderMine()"
        (click)="unassignOrder()"
      >
        <ion-icon name="close-circle-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'OrdreDetails.slippOrdre' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        [hidden]="isOrderMine()"
        (click)="plukkOrApne()"
      >
        <ion-icon name="checkbox-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'OrderAssignment.AssignToMe' | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        [hidden]="!isOrderMine() || UsePlanner"
        (click)="moveOrderToNextDay()"
      >
        <ion-icon name="arrow-forward-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'OrdreDetails.utsettLevering' | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="navigateToOrdersList()"
      >
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'OrdreDetails.tilbake' | translate }}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right-md hidden-xs"
        (click)="setOrderAsCompleted()"
        [disabled]="!detailsForm.valid ||!isOrderMine() ||!deliveredQuantityExists() || (errorMessages.length > 0)"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'OrdreDetails.ferdigmeldOrdre' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
