import { Component, OnInit,ViewChild  } from '@angular/core';
import { NavController, LoadingController, AlertController, IonDatetime, ModalController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { PlannerOrderView, InternalOrdertype } from '../../shared/enums';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep'
import * as moment from 'moment';
import { of, forkJoin } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { switchMap } from 'rxjs/operators';
import { clear, timeStamp } from 'console';
import { DATE_PIPE_DEFAULT_TIMEZONE, getLocaleExtraDayPeriodRules, SlicePipe } from '@angular/common';
import { createHistogram } from 'perf_hooks';
import { ThisReceiver } from '@angular/compiler';
import { OrderConfirmComponent } from 'src/app/components/order-confirm/order-confirm';
import { OrderlineSplitComponent } from 'src/app/components/orderline-split/orderline-split';
import { TankMonitoringPage2 } from '../tank-monitoring2/tank-monitoring2';
import { NavigationExtras, Router } from '@angular/router';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
    selector: 'page-order-planner',
    templateUrl: 'order-planner.html',
    styleUrls: ['order-planner.scss']
})
export class OrderplannerPage implements OnInit {
     @ViewChild('soolo') private childSoolo: TankMonitoringPage2;
  @ViewChild('automat') private childAutomat: TankMonitoringPage2;
  
    currentDate: any = new Date();
    private today: any = this.currentDate;
    private yesterday: any;
    private orders: any[];
    filteredOrders: any[];
    assignedOrders: any[];
    groupedOrders:any[];
    submitAttempt = false;
    dateFormat;
    sortProperty = 'IsUrgent';
    sortDirection = 'desc';
    avgiftsfrie = false;
    visKunDagens = null;
    utildelteOrdrer = true;
    prioritet = -1;
    // private userlevel: number;
    trucks: any[];
    trailers: any[];
    filteredTrucks: any[];
    filteredTrailers: any[];
        customerList:any[];
    st1Products:any[];
    currentTruck: any;
    currentTrailer: any;
    zones: any[];
    RouteNos = [1,2,3,4,5,6,7,8];
    st1ProductTanks = [1,2,3];
    filteresZones: any[];
    currentZone: any;
    plannerOrderView: PlannerOrderView = PlannerOrderView.showOrderlist;
    PlannerOrderView = PlannerOrderView;
    companies = [];
    company = '0';
    expand = false;
    currentRoute = 0;
    cntUnAssigned = 0;
    cntAssigned = 0;
    inventoryList = [];
    selectedTruckTrailerVolume = 0;
    assignedTruckTrailerVolume = 0;
    filteredOrderTotalVolume = 0;
    showOverview = false;
    truckTrailerRooms: any[];
    currentRoomNo:any;
    currentOrderNo:number;
    assignedOrderRooms:any = [];
    currentNote = "";
    selectedRoute:any = {UserID: "", OrderNo:0, RouteID: 0, TruckID: 0, TrailerID:0, RouteNo: 0, PlannedDate:"", Notes:""};
    deleteAssignments:boolean = false;
    tabUnassinged = "bulk";
    dataIsLoaded:boolean = false;
    transporters;
    currentTransporter=0;
    products: any[];
    prodFilter: any[];
        dateSelector = this.currentDate.toISOString();
        openModal = false;


    constructor(
        public navCtrl: NavController, private userService: UserService, private alertCtrl:AlertController,private router: Router,
        private translateService: TranslateService, private loadingController: LoadingController, private storage: Storage,private modalCtrl: ModalController) {
        this.yesterday = Utils.addDays(this.today, -1);
    }

    ngOnInit() {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });

        this.loadData();
    }

    numberOfCustomers()
    {
        this.customerList == undefined ? 0:this.customerList.length;
    }

    ionViewWillEnter()
    {
         const params = this.router.parseUrl(this.router.url);
        let reloadData = Boolean(params.queryParams.reloadData);

        if(reloadData)
        {
            this.visKunDagensChanged(null);
        }

    }

    loadData() {
        this.deleteAssignments = false;
        
        this.translateService.get('Loading.planner').subscribe(value => {
            // let loader = this.loadingController.create({ content: value });

            // loader.present().then(() => {
            // ,this.storage.get(AppSettings.StorageKeys.Userlevel)
            // this.storage.get(AppSettings.StorageKeys.FilterUtildelteOrdrer)
            forkJoin([
                this.storage.get(AppSettings.StorageKeys.FilterAvgiftsfrie),
                this.storage.get(AppSettings.StorageKeys.FilterVisKunDagens), this.userService.getCompanies(),
                this.storage.get(AppSettings.StorageKeys.CompanyNo)
                ]).subscribe(data => {
                    this.avgiftsfrie = data[0] == null ? false : data[0];
                    this.visKunDagens = false;//data[1] == null ? true : data[1];
                    this.companies = data[2];
                    this.company = data[3];
                    
                    // this.utildelteOrdrer = data[2] == null ? false : data[2];
                    // this.userlevel = data[4];
            if(this.dataIsLoaded === false){

                    this.visKunDagensChanged(null);
                    this.userService.getTrailersAndTrucks().subscribe(drivers => {
                        this.trucks = _.filter(drivers.Trucks, (truck) => {
                            let value = true;
                            value = truck.PlannerStatus != 9 && (truck.CompanyNo.toString() === '0' || truck.CompanyNo == this.company);
                            return value;
                        });

                        this.trailers = _.filter(drivers.Trailers, (truck) => {
                            let value = true;
                            value = truck.PlannerStatus != 9 && (truck.CompanyNo.toString() === '0' || truck.CompanyNo == this.company);
                   
                            return value;
                        });                       

                    });

                    this.userService.getDeliveryZones().subscribe(drivers => {
                        this.zones = drivers; //TODO ADD PLANNERSTATUS FILTER // CHANGE TO ZONE!!
                    });

                    this.userService.GetTransporters().subscribe(data => { this.transporters = data; });


                    this.userService.getProducts().subscribe(prod => {
                        this.products = _.filter(prod, (p)=>{
                            let value = true;
                            value = p.ProductType == "10";
                            return value;
                        });
                        this.st1Products = _.filter(prod, (p)=>{
                            let value = true;
                            value = p.Group3 == 999;
                            return value;
                        });

                        try {
                            
                        
                    this.userService.customerByGroup("999").subscribe(customer => {
                        this.customerList = customer;
                       
                        this.customerList.forEach(c =>{

                            
                            c.st1Products = cloneDeep(this.st1Products);
                        });
                           });
                       
                          
                        }
                        catch (error) {
                            console.log(error);
                        }
 });
                    this.dataIsLoaded = true;
                 }

                    
                    this.calculateOrderListTotals();
                    
                     //loader.dismiss();
                });

            // });
            this.deleteAssignments = true;
        });
        
    }

    enableAssign()
    {
            if(this.currentTruck != undefined && this.currentTrailer != undefined  && this.currentRoute>0)
            return false;
            else
            return true;
            
    }
    selectTruck(driver) {

        this.selectTrailer(this.currentTrailer);

        if(driver == this.currentTrailer)
        {
            this.removeOrderRoomsCheckModel(true);
            this.currentTruck = undefined;
            
            
        }
        else{

        this.currentTruck = driver;
        if (this.plannerOrderView === PlannerOrderView.showTruckOrder) {
            this.visKunDagensChanged(null);
        }

        if(driver.DefaultTrailer > 0){ this.selectTrailer(this.trailers.find(t => parseInt(t.ID) == driver.DefaultTrailer));}

        this.addOrderRoomsCheckModel(true);

        this.getRouteData().then(e=>{
            this.totalVolumTruckTrailer();
            
            this.filterData();
            this.getCurrentInventory();
        });
        
    }
    }

    selectTrailer(driver) {

        if(driver == this.currentTrailer)
        {
            this.removeOrderRoomsCheckModel(false);
            this.currentTrailer = undefined;
            
        }
        else{
        this.currentTrailer = driver;
        if (this.plannerOrderView === PlannerOrderView.showTruckOrder) {
            this.visKunDagensChanged(null);
        }}
        
        this.addOrderRoomsCheckModel(false);

        this.getRouteData().then(e=>{
            this.totalVolumTruckTrailer();
            this.filterData();
            this.getCurrentInventory();
        });

       
    
    }


    gettrucktrailerroom(isTruck, order, LineNo)
    {
    
        let rooms = this.assignedOrderRooms.filter(e=>e.OrderNo == order.OrderNo && e.isTruck == isTruck && e.LineNo == LineNo);
        return rooms;
        // this.currentTruck?.truckRooms;//return [...this.currentTruck.truckRooms, ...this.currentTrailer?.truckRooms]; //this.trucks.filter(e => e.TruckID ==this.currentTruck.ID);///.map(o => ({ TruckID:o.TruckID, ID: o.ID, Name:o.Name, Volume:o.Volume } ));
      

    }

    getRoomVolume(roomId, isTruck)
    {
        if(isTruck)
        return this.currentTruck.truckRooms.find(e=>e.Id == roomId).Volume/1000;
        else
        return this.currentTrailer.truckRooms.find(e=>e.Id == roomId).Volume/1000;
    }

    getAssignedRoomVolume(roomId, isTruck)
    {
        if(isTruck)
        return this.currentTruck.truckRooms.find(e=>e.Id == roomId).AssignedVolume/1000;
        else
        return this.currentTrailer.truckRooms.find(e=>e.Id == roomId).AssignedVolume/1000;
    }

    isRoomProductDifferent(roomId, isTruck, order, LineNo)
    {
        let ret = false;
        let _arr = isTruck?this.currentTruck:this.currentTrailer;
        
        order.Orderlines.forEach(l=>{
            if(l.LineNo == LineNo){
            _arr.truckRooms.find(r=>r.Id == parseInt(roomId)).AssignedToRoom.forEach(element => {
                if(element.ProductNo != l.ProductNo)
                {
                   ret = true;
                    
                }
            });
        }
        });
        return ret;

    }

    async roomDisableCheck($event, order, LineNo){
       setTimeout(() => {
        this.assignedOrderRooms.find( e=> +e.Id == +$event.detail.value && e.OrderNo == order.OrderNo && e.LineNo == LineNo).IsChecked = false;
       }, 500); 
    }

   async fillRoom($event, isTruck, order, LineNo)
    {
       
            if(this.isRoomProductDifferent($event.detail.value,isTruck, order, LineNo) && $event.detail.checked)
                Utils.presentAlert("OBS! Ulike produkt!","Du prøver nå å legge inn et annet produkt enn det som finnes på rommet. Ønsker du å gjøre det?", "Nei", "JA!",this,this.fillRoomOk,this.roomDisableCheck, [$event, isTruck, order, LineNo] );
            else
                this.fillRoomOk($event, isTruck,order, LineNo);
        
      
        
    }

   async fillRoomOk($event, isTruck, order, LineNo)
    {
        let operator = 1;

        let total =0;

        let idx : number[];
        let _arr:any;

        this.currentRoomNo = $event.detail.value;
        this.currentOrderNo = order.OrderNo;
        
        order.Orderlines.forEach(e=>{total+=e.Quantity;});


        ($event.detail.checked == false) ? operator = -1:operator = 1;

        _arr =  (isTruck)?this.currentTruck:this.currentTrailer;
            
        let _truckRooms:any;
        _truckRooms =  _arr.truckRooms.find(e=>e.Id == parseInt($event.detail.value));

        order.Orderlines
        .forEach(element => {
           
            if(element.LineNo == LineNo){
                let qtyToAdd = 0;
                let available = 0;
                let addedBefore = 0;
                
                if(operator == 1) //add to assigned
                {
                    available = _truckRooms.Volume - _truckRooms.AssignedVolume;
                    addedBefore = this.totalAssigned(order, LineNo);
                    qtyToAdd = ((element.Quantity-addedBefore) <= available) ? element.Quantity-addedBefore : (available);
                    //find available for room
                    if(qtyToAdd >0)
                    {
                        let _item =  {Pk:parseInt(order.OrderNo.toString() + $event.detail.value.toString() + LineNo.toString()) , PlannedDate:  this.currentDate.toISOString().substring(0,10) + "T00:00:00", RouteNo: this.currentRoute, OrderNo:order.OrderNo, LineNo:element.LineNo, RoomID:$event.detail.value, AssignedVolume:qtyToAdd, ProductNo:element.ProductNo, RouteID: this.selectedRoute?.RouteID, TruckID:this.currentTruck.ID, TrailerID:(this.currentTrailer != undefined)?this.currentTrailer.ID:0,AssignedToTruck:isTruck,CreatedBy:AppSettings.Username};
                        _truckRooms.AssignedToRoom.push(_item);

                        this.userService.assignToRoom(_item).subscribe();
                    }
                        

                     else{
                        this.roomDisableCheck($event, order, LineNo);
                        
                     }
                }
                else{ //remove if unchecked
                    _truckRooms.AssignedToRoom.forEach((item, index) => {
                        if(item.OrderNo == order.OrderNo && item.LineNo == element.LineNo){
                            let _item =  {Pk:parseInt(order.OrderNo.toString() + $event.detail.value.toString() + LineNo.toString()) , PlannedDate:  this.currentDate.toISOString().substring(0,10) + "T00:00:00", RouteNo: this.currentRoute, OrderNo:order.OrderNo, LineNo:element.LineNo, RoomID:$event.detail.value, AssignedVolume:qtyToAdd, ProductNo:element.ProductNo, RouteID: this.selectedRoute?.RouteID, TruckID:this.currentTruck.ID, TrailerID:(this.currentTrailer != undefined)?this.currentTrailer.ID:0,AssignedToTruck:isTruck,CreatedBy:AppSettings.Username};

                            qtyToAdd = item.AssignedVolume;
                            _truckRooms.AssignedToRoom.splice(index,1);
                           
                        if(this.deleteAssignments)
                        this.userService.deleteAssignedToRoom(_item).subscribe();


                  }
                });
            }
        
                //add / remove from total
                _truckRooms.AssignedVolume += qtyToAdd*operator;
                this.isRoomFull(this.currentRoomNo, isTruck, order, LineNo);
        }     
        
        });
        
        
    }


    // returns total assigned volume for given order
    totalAssigned(order, LineNo)
    {
        let total = 0;

        try {
      //      let lines = order.Orderlines.find(l=> l.LineNo == LineNo);
        //    order.Orderlines.find(l=> l.LineNo == LineNo).forEach(element => {
                //if(element.LineNo == LineNo || Pk == 0)
                {
                this.currentTruck?.truckRooms.forEach(room => {
                    total += this.assignedTotalByOrder(room.AssignedToRoom, order.OrderNo, LineNo)
                });
                
                this.currentTrailer?.truckRooms.forEach(room => {
                    total += this.assignedTotalByOrder(room.AssignedToRoom, order.OrderNo, LineNo)
                });
            }
        //     });    
        } catch (error) {
            
        }
        
        return total;
    }




    // returns total assigned volume for given order and line
    assignedTotalByOrder(_arr, OrderNo, LineNo):number
    {
            let total = 0;
    if(_arr != undefined){
        _arr.forEach(element => {
            if(LineNo==0)
                { if(element.OrderNo == OrderNo && this.isRoomLinechecked(element.Pk))
                    total += element.AssignedVolume;
                    console.log(OrderNo + ' - ' + total.toString());
                }
            else
            {
                if(element.OrderNo == OrderNo && element.LineNo == LineNo && this.isRoomLinechecked(element.Pk))
                {
                    total += element.AssignedVolume;
                }
        }
        });

    }
    return total;
    }
    isRoomLinechecked(pk)
    {
        let room = this.assignedOrderRooms.find(e => e.Pk == pk);
        return room.IsChecked;

    }

    // is the room full or not
    isRoomFull(roomID, isTruck, order, LineNo)
    {

   /*    if(this.totalAssigned(order, LineNo) == 0)
        {
            let _e = this.assignedOrderRooms.find(e=> e.OrderNo == order.OrderNo && e.Id== roomID);
                        _e.IsChecked = false;
            return false;
        }
        else*/
        {
         let _arr:any;
        (isTruck)?_arr = this.currentTruck:_arr=this.currentTrailer;
        let room = _arr.truckRooms.find(e=>e.Id == roomID);
        
            
            this.assignedOrderRooms.filter(e => e.Id == room.Id).forEach(element => {
                    if(room.Volume-room.AssignedVolume <= 0)
            {
                    if(parseInt(order.OrderNo.toString() + element.Id.toString()+ LineNo.toString()) && element.IsChecked == false)
                        element.IsDisabled = true;
                    else
                        element.IsDisabled = false;
            }
            else
            {
                
                element.IsDisabled = false;}       
                });

        }
    }

    selectZone(zone) {

        if(zone == this.currentZone)
        {
            this.currentZone = null;
        }
        else
        {
        this.currentZone = zone;
        if (this.plannerOrderView === PlannerOrderView.showTruckOrder) {
            this.visKunDagensChanged(null);
        }
    }
    this.filterData();
    this.calculateOrderListTotals();
    }

    selectRouteNo(route) {
        this.currentRoute = route.detail.value;
    
        this.getRouteData().then(e =>{
            this.getCurrentInventory();
            this.filterData();
        }
        );
        
        
    }


    

    async getRouteData()
    {
        if(this.currentTruck != undefined && this.currentRoute > 0){
        this.clearAllDataAndChangeSelection().then(e=>{
        
        let _item =  {Pk:0, PlannedDate:  this.currentDate.toISOString().substring(0,10) + "T00:00:00", OrderNo:0, LineNo:0, RoomID:0, AssignedVolume:0, ProductNo:"", RouteID: 0,RouteNo: this.currentRoute, TruckID:this.currentTruck.ID, TrailerID:(this.currentTrailer != undefined)?this.currentTrailer.ID:0};
 
        this.userService.getAssignedRoute(_item).subscribe(res =>{
            this.selectedRoute = res;

            this.currentNote = (typeof this.selectedRoute.Notes!=undefined && this.selectedRoute.Notes)?this.selectedRoute.Notes:"";

            if(this.selectedRoute.AssignedRoomVolume != null)
            {
            this.assignedOrderRooms.forEach(element => {
                this.selectedRoute.AssignedRoomVolume.forEach(assignedDb => {
                    if(assignedDb.Pk == element.Pk)
                    {
                        element.IsChecked = true;
                        element.AssignedVolume = assignedDb.AssignedVolume;
                        element.isTruck = assignedDb.AssignedToTruck;
                        element.ProductNo = assignedDb.ProductNo.trim();
                        element.LineNo = assignedDb.LineNo;


                        _item.Pk = assignedDb.Pk;
                        _item.OrderNo = assignedDb.OrderNo;
                        _item.LineNo = assignedDb.LineNo;
                        _item.RouteID = assignedDb.RouteID;
                        _item.RoomID = assignedDb.RoomID;
                        _item.AssignedVolume = assignedDb.AssignedVolume;
                        _item.ProductNo = assignedDb.ProductNo.trim();

                        if(assignedDb.AssignedToTruck){
                            let truck = this.currentTruck.truckRooms.find(e=>e.Id == assignedDb.RoomID);
                           

                           // truck.AssignedVolume += assignedDb.AssignedVolume;
                           // truck.AssignedToRoom.push(_item);
                        }
                        else
                            if(assignedDb.TrailerID>0)
                            {
                                let trailer = this.currentTrailer.truckRooms.find(e=>e.Id == assignedDb.RoomID);
                              //  trailer.AssignedVolume += assignedDb.AssignedVolume;
                               // trailer.AssignedToRoom.push(_item);
                            }
                            
                    }
                    
                });
            });

        }
           //{Id: element.Id, Pk: (order.OrderNo.toString() + element.Id.toString()), OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck: true});    
        });
    });
        
    }
    
    }


    totalVolumTruckTrailer()
    {

        
        this.selectedTruckTrailerVolume = (
                (this.currentTruck?.Volume != undefined ? this.currentTruck.Volume : 0) 
                +(this.currentTrailer?.Volume != undefined ? this.currentTrailer.Volume : 0)) ;
    }


    calculateTotals()
    {
        this.assignedTruckTrailerVolume = 0;
        if(this.inventoryList?.length > 0)
        {
            this.assignedOrders.forEach(o =>{
                o.Orderlines.forEach(element => {
                    this.inventoryList.find(i => i.ProductNo == element.ProductNo).QuantityInOrder += element.Quantity;
                    this.assignedTruckTrailerVolume +=element.Quantity;
                });
            });
        }

    }

    

    calculateOrderListTotals()
    {
        this.filteredOrderTotalVolume = 0;

        if(this.inventoryList?.length > 0)
        {
           //CLEAR
           this.inventoryList.forEach(i =>{
                i.QuantityInOrderList = 0;
           });

            //CALCULATE
            this.filteredOrders.forEach(o =>{
                o.Orderlines.forEach(element => {
                    try {
                        this.inventoryList.find(i => i.ProductNo == element.ProductNo).QuantityInOrderList += element.Quantity;
                        this.filteredOrderTotalVolume +=element.Quantity;
                    } catch  {
                        
                    }
                
                });
            });
        }

    }



    isOrderMine(order): boolean {
        if (order.AssignedToTruck.toString() === this.currentTruck?.ID) {
            return false;
        }
        return false;
    }

    changeplannerOrderView(plannerOrderView: PlannerOrderView) {
        if (plannerOrderView === PlannerOrderView.showTruckOrder) {
            this.utildelteOrdrer = false;
        } else {
            this.utildelteOrdrer = true;
        }
        this.plannerOrderView = plannerOrderView;
        this.visKunDagensChanged(null);
    }

    routeDBCheck(order, deleteRoute)
    {
            
            //creat route in DB if first assignment
            if(deleteRoute == false)
            {
                if(this.selectedRoute.RouteID == 0)
                {
                this.selectedRoute = {UserID: AppSettings.Username, OrderNo: order.OrderNo, RouteID: 0, 
                                TruckID: this.currentTruck.ID, TrailerID:this?.currentTrailer?.ID, RouteNo: this.currentRoute, 
                                    PlannedDate:  this.currentDate.toISOString().substring(0,10) + "T00:00:00", Notes:this.currentNote};
                }
                
                if(this.selectedRoute.RouteID == 0 || (this.selectedRoute.TrailerID == undefined && this.currentTrailer != undefined) || this.selectedRoute.Notes != this.currentNote)

                this.selectedRoute.TrailerID = (this.currentTrailer != undefined)?this.currentTrailer.ID:0;
                this.selectedRoute.Notes = this.currentNote;
                this.selectedRoute.RouteNo = this.currentRoute;
                this.selectedRoute.PlannedDate = this.currentDate.toISOString().substring(0,10) + "T00:00:00";

                                    this.userService.assignRoute(this.selectedRoute).subscribe(res =>{
                                        if(this.selectedRoute.RouteID ==0)
                                        this.selectedRoute.RouteID = res.ID;
                                    });
        }
            else{
                if(deleteRoute){
                            this.userService.deleteAssignedRoute(this.selectedRoute).subscribe(res =>{
                                this.selectedRoute.RouteID = 0;
                    });
                }
            }

            
    }

    assignOrder(order) {

       this.routeDBCheck(order, false);

        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            let _trailerID = (this.currentTrailer?.ID > 0 ? this.currentTrailer?.ID : 0);
            loader.present().then(() => {
                this.userService.assignOrderToTruck(order.OrderNo, this.currentTruck.ID,_trailerID, this.currentRoute, this.currentDate).subscribe(data => {
                    order.AssignedToTruck = parseInt(this.currentTruck.ID);
                    order.AssignedToTrailer = parseInt(_trailerID);
                    order.RouteNo = this.currentRoute;
                    order.PlannedDeliveryDate = this.currentDate.toISOString().substring(0,10) + "T00:00:00";
                    this.getCurrentInventory();
                    this.filterData();
                    loader.dismiss();
                });
            });
        });

        
    }

    addTankOrder(order){
    
        this.orders.push(order);
        

        //add rooms to truck
        this.currentTruck?.truckRooms.forEach(element => {
            this.assignedOrderRooms.push({Id: element.Id, Pk: parseInt(order.OrderNo.toString() + element.Id.toString()+"1"), LineNo: 1, OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck: true});    
            });


            //add rooms to trailer
            if(this.currentTrailer != undefined){
                this.currentTrailer?.truckRooms.forEach(element => {
          
                    this.assignedOrderRooms.push({Id: element.Id, Pk: parseInt(order.OrderNo.toString() + element.Id.toString()+"1"), LineNo:1, OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck:false});    
                });
    
            }
        this.assignOrder(order);
    
    }


    UpdateOrderETA(order) {

       

                 this.userService.UpdateOrderETA(order.OrderNo, order.Information6).subscribe(data => {

                 });

 
         
     }

    removeOrderRoomsCheckModel(isTruck)
    {
        this.assignedOrderRooms.forEach((e,i)=>{
            if(e.isTruck == isTruck )
            this.assignedOrderRooms.splice(i,1);
        });
    }

    addOrderRoomsCheckModel(isTruck)
    {
        try {

            this.assignedOrderRooms = [];

            this.orders.forEach(order =>{

                order.Orderlines.forEach(ln =>{
 
            this.currentTruck?.truckRooms.forEach(element => {
                this.assignedOrderRooms.push({Id: element.Id, Pk: parseInt(order.OrderNo.toString() + element.Id.toString()+ln.LineNo.toString()), LineNo: ln.LineNo, OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck: true});    
                });

            
        if(this.currentTrailer != undefined){
            this.currentTrailer?.truckRooms.forEach(element => {
      
                this.assignedOrderRooms.push({Id: element.Id, Pk: parseInt(order.OrderNo.toString() + element.Id.toString()+ln.LineNo.toString()), LineNo: ln.LineNo, OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck:false});    
            });

        }
    });
        });
        } catch (error) {
            console.log(error);
        }
    
      
        
    }

    onProdfilterSelect(event: {
  component: IonicSelectableComponent,
  item: any,
  isSelected: boolean
}) {
    this.filterData();
//alert(event.item);
}

    unassignOrder(order) {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.unassignOrderFromTruck(order.OrderNo).subscribe(data => {
                    order.AssignedToTruck = 0;
                    order.RouteNo = 0;
                    order.PlannedDeliveryDate = 0;
                    order.expandedView = false;

                    this.assignedOrders.forEach((element, index) => {
                        if(element.OrderNo == order.OrderNo)
                        {
                            element.Orderlines.forEach(ordln =>{
                            this.currentTruck.truckRooms.forEach(room =>{
                                room.AssignedToRoom.forEach((item, idxRoom) => {
                                if(item.OrderNo == order.OrderNo && item.LineNo == ordln.LineNo)
                                {
                                    let _item =  {Pk:parseInt(order.OrderNo.toString() + item.RoomID.toString()+ ordln.LineNo.toString()) , PlannedDate:  this.currentDate.toISOString().substring(0,10) + "T00:00:00", RouteNo: this.currentRoute, OrderNo:order.OrderNo, LineNo:ordln.LineNo, RoomID:item.RoomID, AssignedVolume:0, ProductNo:item.ProductNo, RouteID: this.selectedRoute?.RouteID, TruckID:this.currentTruck.ID, TrailerID:(this.currentTrailer != undefined)?this.currentTrailer.ID:0,AssignedToTruck:true,CreatedBy:AppSettings.Username};

                                    //add / remove from total
                                    room.AssignedVolume += item.AssignedVolume*-1
                                   
                                    this.userService.deleteAssignedToRoom(_item).subscribe();
    
                                    room.AssignedToRoom.splice(idxRoom,1);
                                }
                            
                                
                          ///     this.isRoomFull(item.RoomID, true, order);
                            });
                        });
                            
                            this?.currentTrailer?.truckRooms.forEach((room) =>{
                                room.AssignedToRoom.forEach((item, idxRoom) => {
                                if(item.OrderNo == order.OrderNo && item.LineNo == ordln.LineNo)
                                {
                                    let _item =  {Pk:parseInt(order.OrderNo.toString() + item.RoomID.toString() + ordln.LineNo.toString()) , PlannedDate:  this.currentDate.toISOString().substring(0,10) + "T00:00:00", RouteNo: this.currentRoute, OrderNo:order.OrderNo, LineNo:ordln.LineNo, RoomID:item.RoomID, AssignedVolume:0, ProductNo:item.ProductNo, RouteID: this.selectedRoute?.RouteID, TruckID:this.currentTruck.ID, TrailerID:(this.currentTrailer != undefined)?this.currentTrailer.ID:0,AssignedToTruck:false,CreatedBy:AppSettings.Username};
                                    this.userService.deleteAssignedToRoom(_item).subscribe();
                                    
                                    //add / remove from total
                                    room.AssignedVolume += item.AssignedVolume*-1
                                    room.AssignedToRoom.splice(idxRoom,1);
                                    
                                }
                            
                                
                            ///   this.isRoomFull(item.RoomID, false, order);
                    });
                    });
                });
                    

                    /* //remove from truck rooms
                        this.currentTruck?.truckRooms.forEach(room => {
                            this.clearOrderFromTruckRooms(room, order.OrderNo);
                        });
                        
                        //remove from trailer rooms
                            this.currentTrailer?.truckRooms.forEach(room => {
                                this.clearOrderFromTruckRooms(room, order.OrderNo);
                            });

*/


                    if(this.assignedOrders.length == 0)
                    {
                        this.routeDBCheck(order, true);
                    }

                    this.assignedOrders.splice(index,1);

                    this.getCurrentInventory();
                    this.filterData();

                    loader.dismiss();
                }
                });
            });
         
        });
    });
}

   async clearAllDataAndChangeSelection()
    {
        this.deleteAssignments = false;

        this.currentTruck?.truckRooms.forEach(room => {
            this.clearOrderFromTruckRooms(room, 0,true);
        });

        if(this.currentTrailer != undefined){
        this.currentTrailer?.truckRooms.forEach(room => {
            this.clearOrderFromTruckRooms(room, 0,true);
        });
    }
    
    this.removeOrderRoomsCheckModel(true);
    this.removeOrderRoomsCheckModel(false);
    this.addOrderRoomsCheckModel(true);
    this.addOrderRoomsCheckModel(false);

    this.deleteAssignments = true;

    }
    //remove order from assigned truck/trailer rooms
    clearOrderFromTruckRooms(_arr, orderNo, clearAll:boolean = false)
    {
        
        if(_arr != undefined){
            
            if(clearAll)
            {
                _arr.AssignedVolume = 0;
                _arr.AssignedToRoom = [];
            }
            else{

            _arr.AssignedToRoom.forEach((element, index) => {
                if(element.OrderNo == orderNo)
                {
                    _arr.AssignedVolume = (clearAll)?0:-element.AssignedVolume;
                    _arr.AssignedToRoom.splice(index,1);
                }
                
     
            });

            this.assignedOrderRooms.forEach((e,i)=>{
                if(e.Id == _arr.Id){
                    e.IsChecked = false;
                    e.IsDisabled = false; 
                }
            });

        }
    
        }
        
    }



    getDateText(order) {
        const deliveryDate = new Date(order.DeliveryDate);
   /*     if (Utils.isSameDateAs(deliveryDate, this.today)) {
            return this.translateService.get('Ordrer.iDag');
        } else if (Utils.isSameDateAs(deliveryDate, this.yesterday)) {
            return this.translateService.get('Ordrer.iMorgen');
        }*/
        return this.translateService.get('General.dateFormat').pipe(switchMap(format => of(moment(deliveryDate)
            .format(format.toUpperCase()))));

    }

    isSelectedDate(order) {
        const deliveryDate = new Date(order.DeliveryDate);
        if (Utils.isSameDateAs(deliveryDate, this.currentDate)) 
            return true;
       else
       return false;

    }

    filterChanged($event) {
        this.filterData();
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    refresh()
    {
        this.dataIsLoaded = false;
        this.visKunDagens(null);
    }

    visKunDagensChanged($event) {
        if (this.plannerOrderView === PlannerOrderView.showOrderlist || (this.currentTruck &&
            this.plannerOrderView === PlannerOrderView.showTruckOrder)) {
            this.translateService.get('Loading.planner').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });

                loader.present().then(() => {
                    this.userService.getOrders(this.visKunDagens ? this.currentDate : null, this.currentTruck ?
                        this.currentTruck.ID : AppSettings.Username,
                        this.plannerOrderView === PlannerOrderView.showTruckOrder, InternalOrdertype.Bulk, +this.company)
                        .subscribe(data => {
                            for (const item of data) {
                                item.expandedView = false;
                            }
                            this.orders = data;
                            this.storage.set(AppSettings.StorageKeys.CompanyNo, this.company);
                            this.filterData();
                            
                            loader.dismiss();
                        });
                });
            });
        } else {
            this.orders = null;
        }
    }

    filterData() {
        this.deleteAssignments = false;
        this.filteredOrders = _.filter(this.orders, (order) => {
            let value = true;

           /* if (this.plannerOrderView === PlannerOrderView.showOrderlist && this.utildelteOrdrer === false) {
                value = value && (order.AssignedDriver === +AppSettings.Username);
            } else if (this.plannerOrderView === PlannerOrderView.showOrderlist && this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === 0);
            } else if (this.plannerOrderView === PlannerOrderView.showTruckOrder) {
                value = value && (order.AssignedDriver === +this.currentTruck.ID);
            }
            // else if (this.plannerOrderView == PlannerOrderView.showTruckOrder && this.utildelteOrdrer == true) {
            //    value = value && (order.AssignedDriver == this.currentDriver.UserID || order.AssignedDriver == 0);
            // }
*/               if(this.prodFilter?.length > 0)
            {
                value = value && order.Orderlines.find(e => this.prodFilter.includes(e.ProductNo.trim())) != undefined;
            }


            if (this.avgiftsfrie === true) {
                value = value && (order.ExVatOrder === true);
            }

            if (!value) {
                return value;
            }
            if (this.prioritet > -1) {
                value = value && (order.IsUrgent === (this.prioritet === 1 ? true : false));
            }

            value = value && (order.AssignedToTruck ==0 || (order.AssignedToTruck != 0 && order.PlannedDeliveryDate.toString().substring(0,4) == "0001"));
            
            if(this?.currentZone?.ID > 0)
            {
                value = value && order.DeliveryZone == this.currentZone.ID;
            }

            if(this?.currentTransporter> 0)
            {
                value = value && order.Transporter == this.currentTransporter;
            }

            // if (this.plannerOrderView == PlannerOrderView.showOrderlist) {
            //    value = value && (order.AssignedDriver == 0);
            // }
            // else {
            //    value = value && (order.AssignedDriver == this.currentDriver.UserID);
            // }
            return value;
        });

        this.assignedOrders = _.filter(this.orders, (order) => {
            let value = true;


            value = order.AssignedToTruck == this.currentTruck?.ID;

            if(this.currentTrailer != undefined)
            {
                value = value && order.AssignedToTrailer == this.currentTrailer.ID;
            }
            
            value = value && order.RouteNo == this.currentRoute;

            value= value && order?.PlannedDeliveryDate.toString().substring(0,10) == this.currentDate.toISOString().substring(0,10);

    
            return value;
            
        });

 


        // save for furture use

        this.storage.set(AppSettings.StorageKeys.FilterAvgiftsfrie, this.avgiftsfrie);
        this.storage.set(AppSettings.StorageKeys.FilterVisKunDagens, this.visKunDagens);
        // this.storage.set(AppSettings.StorageKeys.FilterUtildelteOrdrer, this.utildelteOrdrer);
        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);
        

        // filter drivers list
        this.filteredTrucks = _.filter(this.trucks, (truck) => {
            let value = true;

            if (this.company !== '0') {
                if (truck.CompanyNo !== undefined) {
                    value = value &&  (this.company.toString() === truck.CompanyNo.toString() || truck.CompanyNo == 0);
                }
            }
            return value;
        });

        this.filteredTrailers = _.filter(this.trailers, (truck) => {
            let value = true;

            if (this.company !== '0') {
                if (truck.CompanyNo !== undefined) {
                    value = value && (this.company.toString() === truck.CompanyNo.toString() || truck.CompanyNo == 0);
                }
            }
            return value;
        });

        
        this.setGrouperOrders();
        this.cntUnAssigned = this.filteredOrders.length;
        this.deleteAssignments = true;

    }

    sort(property) {

        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }

        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);
    }

    setGrouperOrders()
    { 
        

        if(this.assignedOrders.length > 0)
        {
            //this.groupedOrders = _.groupBy(this.assignedOrders, o => o.AssignedToTruck);
            this.groupedOrders = Utils.orderByList(
                    this.orders.filter(e => e.AssignedToTruck >0).map(o => ({ TruckID:o.AssignedToTruck, TrailerID: o.AssignedToTrailer, PlannedDeliveryDate:o.PlannedDeliveryDate, RouteNo:o.RouteNo } )),
                    ['PlannedDeliveryDate','RouteNo','AssignedToTruck'],['asc','asc','asc']
            );

        }
    }
    assignedOrdersOverview()
    {
      
            //this.groupedOrders = _.groupBy(this.assignedOrders, o => o.AssignedToTruck);
            return Utils.orderByList(this.orders,['PlannedDeliveryDate','RouteNo','AssignedToTruck'],['asc','asc','asc']);
    }
    assignedTrucks()
    {
        try {
            this.setGrouperOrders();
            return this.groupedOrders.filter((thing, i, arr) => arr.findIndex(t => t.TruckID === thing.TruckID && t?.TrailerID === thing?.TrailerID) === i);
        } catch (error) {
            return [];
        }

    }

    assignedDates(truck)
    {
        try {
            
            return Utils.orderBy(this.groupedOrders.filter((thing, i, arr) => arr.findIndex(t => t.PlannedDeliveryDate === thing.PlannedDeliveryDate) === i),'PlannedDeliveryDate','asc');
        } catch (error) {
            return [];
        }

    }

    getTruckName(id)
    {
        try {
            return this.trucks.find(e => e.ID ==id).Description;
            
        } catch (error) {
            return "Error";
        }
    }
    
    getTrailerName(id)
    {
        try {
            if(id == 0)
            return "(kun bil)";
            else
            return this.trailers.find(e => e.ID ==id).Description;
            
        } catch (error) {
            return "Error";
        }
    }

    previousDay() {
     
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.getRouteData().then(e =>{
            this.getCurrentInventory();
            this.filterData();
          
           
        }
        );
  
       // this.loadData();
    }

    nextDay() {
   
        this.currentDate = Utils.addDays(this.currentDate, 1);
        
        this.getRouteData().then(e =>{
            this.getCurrentInventory();
            this.filterData();
            
          
        }
        );

       // this.loadData();
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    getCurrentInventory()
    {
        this.userService.getCurrentInventoryCurrent(this.currentTruck?.ID,null).subscribe(data => { 
            this.inventoryList = data.InventoryList; 
            this.calculateTotals();
            this.calculateOrderListTotals();
        });
    }

    toggleOverview()
    {
        this.showOverview = !this.showOverview;
        this.currentRoute = 0;

        if(this.assignedOrders.length === 0 && this.showOverview    )
        {
            this.filterData();
        }
    }

    multipleProducts(order)
    {

       var _tmp =  _.map(_.groupBy(order.Orderlines, 'ProductNo'), (o,idx) => { return { ProductNo: idx, 
                QuantityTotal: _.sumBy(o,'Quantity') }})

        let _info = "";
        _tmp.forEach(a =>{
            _info += a.ProductNo + ": " + a.QuantityTotal + "<br>";
        });
      return _tmp;//_info;


        
    }

    multipleProductsExists(order)
    {

       var _tmp =  _.map(_.groupBy(order.Orderlines, 'ProductNo'), (o,idx) => { return { ProductNo: idx, 
                QuantityTotal: _.sumBy(o,'Quantity') }})

        if(_tmp.length==1){
            order.Orderlines[0].IsSelected = true;
        }

      return _tmp.length > 1?true:false;


        
    }

    
    selectOrderLine($event, order)
    {
        console.log($event.detail.value);
    }

    trailerSelected()
    {
        if(this.currentTrailer != undefined)
        {
            return this.currentTrailer.Description == "Ingen henger" ?false:true;
        }
        else
        return false;
            return this.currentTrailer !=  undefined?true:false;
    }

    truckSelected()
    {
            return this.currentTruck.truckRooms.length == 0 ?true:false;
    }

    unassignedView($event)
    {
       
        this.tabUnassinged = ($event.detail.value);

        if(this.tabUnassinged == 'automat')
        {
             this.childAutomat.dataHost = true;
             this.childAutomat.dataIsLoaded = false;
             this.childAutomat.changeView();
        }
        else if(this.tabUnassinged == 'soolo')
        {
            this.childSoolo.dataHost =false;
            this.childSoolo.dataIsLoaded = false;
            this.childSoolo.loadData();
        }
    }

    showUnassigned(item:string)
    {
        return (item==this.tabUnassinged)?false:true;
    }


async  createNewOrder(item) {

        let boolOK = true;
        let boolAssign = true;

        if(_.sumBy(item.st1Products, 'DefaultQty') == 0){
            await Utils.presentAlert("Mangler data!","En eller flere produkter må ha antall oppgitt på før en ordre kan opprettes. Korriger og prøv på nytt.", "", "OK",this,()=>({}),()=>({}),[]);
            
            boolOK = false;
        }

        if(this.currentTruck == undefined || this.currentRoute == 0)
        {
       await Utils.presentAlertYesNo("Opprette uten tildeling?","Du har ikke valgt noen lastebil eller tur, ordren kan opprettes, men den må tildeles under 'Bulkordre' oversikten etterpå.", "AVBRYT", "LAG ORDRE").then((data) => {
      boolOK = data;
      boolAssign = false;
});;
}
        if(boolOK)
        {
        this.submitAttempt = true;
        let orderLines = [];
        let i = 0;
        const order: any = {};

        item.st1Products.forEach(prod => {
        if(prod.DefaultQty > 0)
        {
        const orderline: any = {
            ProductColorCode: '',
            ProductShortCode: prod.ShortCode,
            Description: prod.Description,
            LineNo: i,
            ProductNo: prod.ProductNo,
            Quantity: Math.round(prod.DefaultQty),
            QuantityDelivered: 0
        };

        orderLines.push(orderline);
    }
 });

        const date = Date.now();

        order.CustomerID = item.CustomerNo;
        order.DeliveryInfo = '';
        order.Email = '';
        order.PhoneOnSite = '';
         order.DeliveryName = item.Name;
        order.DeliveryAddress1 = '';
        order.DeliveryDate = this.currentDate.toISOString().substring(0,10) + "T00:00:00"
        order.Longitude = -1;
        order.Latitude = -1;
        order.Orderlines = orderLines;
        order.OrderComments = '';
        order.OrderInternalComments = '';
        order.CustomerReference = '';
        order.AdditionalProductsDelivered = false;
        order.TankInfo = '';
        order.OrderType = 1;
        order.InternalOrderType = InternalOrdertype.Bulk;
        this.storage.get(AppSettings.StorageKeys.UserFullName).then(data => { order.CustomerBuyer = data; });

        order.DoNotCalculateVolume = true;
        order.LoadingTemp = 15;
        this.storage.get(AppSettings.StorageKeys.CompanyNo).then(data => { order.CompanyNo = data; });

        const modal = await this.modalCtrl.create({
            component: OrderConfirmComponent,
            cssClass: 'order-confirm',
            componentProps: { order }

        });
        modal.present();

        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });

                    loader.present().then(async () => {
                        (await this.userService.createNewOrder(order)).subscribe(resp => {
                            if (resp) {
                                 order.OrderNo = resp[0].VismaBuisnessID;
                                

                                        
                                this.currentTruck?.truckRooms.forEach(element => {

                                    let i = 1;
                                    order?.Orderlines.forEach(line => {

                                        line.LineNo = i;
                                    //add rooms to truck
                                    this.assignedOrderRooms.push({Id: element.Id, Pk: parseInt(order.OrderNo.toString() + element.Id.toString()+i.toString()), LineNo: i, OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck: true});    
                                   

                                    //add rooms to trailer
                                    if(this.currentTrailer != undefined){
                                        this.currentTrailer?.truckRooms.forEach(element => {
                                
                                            this.assignedOrderRooms.push({Id: element.Id, Pk: parseInt(order.OrderNo.toString() + element.Id.toString()+i.toString()), LineNo:i, OrderNo: order.OrderNo, IsChecked:false, IsDisabled:false, isTruck:false});    
                                        });
                            
                                    }
                                    i++;
                                    });
                                    });
                                   this.orders.push(order);

                                 if(boolAssign){
                                     this.assignOrder(order);
                                     //this.loadData();
                                     this.getRouteData();
                                 }
                                 
                            }
                            loader.dismiss();
                        });
                    });
                });
            }
        });
    }
    }

        confirmDeleteOrder(orderNo) {
        let modalTitle;
        let labelText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.orderDeleteMessage').subscribe(value => {
            labelText = value;
        });
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            inputs: [
                {
                    name: 'checkbox',
                    type: 'checkbox',
                    label: labelText,
                    checked: false,
                    value: 'true',
                    handler: (input) => {
                        const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
                        if (input.checked) {
                            confirmBtn.disabled = false;
                        } else {
                            confirmBtn.disabled = true;
                        }
                    },
                }],
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    cssClass: 'confirmation',
                    handler: data => {
                        if (data.length > 0) {
                            this.deleteOrder(orderNo);
                        }
                    }
                }
            ]
        }).then(confirm => {
            confirm.present();
            const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
            confirmBtn.disabled = true;
        });
    }

    deleteOrder(orderNo) {
        this.userService.deleteOrder(orderNo).subscribe(data => {
            
          this.visKunDagensChanged(null);

        });
    }

    async splitOrder(item) {
        const inventoryList = _.cloneDeep(this.inventoryList);
        const index = inventoryList.findIndex(f => f.ProductNo === item.ProductNo);
        if (index !== -1) {
            inventoryList.splice(index, 1);
        }

        const modal = await this.modalCtrl.create({
            component: OrderlineSplitComponent,
            cssClass: 'orderline-split',
            componentProps: { inventoryList, item }
        });
        modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.convertProduct(item.ProductNo, data.data.Product.ProductNo, data.data.Quantity, this.currentDate)
                            .subscribe(resp => {
                                loader.dismiss();
                                this.loadData();
                            });
                    });
                });
            }
        });
    }
    
        navigateToCreateOrder() {


         const navigationExtras: NavigationExtras = {
                queryParams: {
                    RouteNo:0,
                    CameFromPlanner: true
                },
                skipLocationChange: true
            };
        
            this.router.navigate(['/new-order-sales'],navigationExtras);
      
    }

       dateChangeEvent(event) {
      
            this.currentDate = new Date(event.detail.value);
            this.loadData();
        
    }
    closeModal(){
    this.openModal = false;
}
}
