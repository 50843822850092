import { Component } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TransactionType, TypeOfTank } from '../../shared/enums';
import { UserService } from '../../shared/user-service';
import { Utils } from '../../shared/utils';
import { Observable, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import _ from 'lodash';
import { EgneTankerConfirmComponent } from '../../components/egne-tanker-confirm/egne-tanker-confirm';
import { Storage } from '@ionic/storage-angular';
import { AppSettings } from '../../shared/app-settings';
import { Geolocation } from '@capacitor/geolocation';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'page-egne-tanker-automater',
    templateUrl: 'egne-tanker-automater.html',
    styleUrls: ['egne-tanker-automater.scss']
})
export class EgneTankerAutomaterPage {
    egneForm: FormGroup;
    products: any[];
    trucks: any[];
    tanks: any[];
    tanksData: any[];
    submitting = false;
    submitAttempt = false;
    currentProduct: any;
    tank: any;
    truck: any;
    currentDate: any = new Date();
    dateFormat;
    TransactionType: any = TransactionType;
    transactionType: TransactionType;
    transactionTypeTitle: any = '';
    inventoryList: any[] = null;
    inventoryList2: any[] = null;
    currentTruck: any;
    private tankNo: number;
    temperature: number = null;
    companies: any[];
    currentLatitude: number = null;
    currentLongitude: number = null;
    private identity = 0;
    productGroup: any[] = [];
    expand = false;
dateSelector = this.currentDate.toISOString();
openModal = false;

    constructor(
        private storage: Storage, public navCtrl: NavController, private route: ActivatedRoute, private userService: UserService,
        private translateService: TranslateService, private loadingController: LoadingController, private formBuilder: FormBuilder,
        private modalCtrl: ModalController, private router: Router, private toastController: ToastController,
        private alertCtrl: AlertController,
        private geolocation: Geolocation) {

             this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        this.route.queryParams.subscribe(params => {
            this.currentDate = params.currentDate;
            this.transactionType = JSON.parse(params.transactionType);
            const config: any = {
                product: ['', Validators.compose([Validators.required])],
                antLiter: ['', Validators.compose([Validators.required])],
                company: []
            };
            const posOptions = {
                enableHighAccuracy: false,
                timeout: 30000,
                maximumAge: 30000
            };

            this.userService.smUpLargeScreen.subscribe(data => {
                if (data) {
                    this.expand = data;
                }
            });

            this.getCurrentLocation();
            
            if (this.transactionType === TransactionType.UnloadingAutoGas) {
                this.transactionTypeTitle = 'EgneTankerSelect.fylleEnAutomatstasjon';
                config.tank = ['', Validators.compose([Validators.required])];
                config.tankno = ['', Validators.compose([Validators.required])];
                config.productGroup = new FormGroup({});
            } else if (this.transactionType === TransactionType.UnloadingToStorage) {
                this.transactionTypeTitle = 'EgneTankerSelect.fyllePAEnLagertank';
                config.tank = ['', Validators.compose([Validators.required])];
            } else if (this.transactionType === TransactionType.LoadingFromStorage) {
                this.transactionTypeTitle = 'EgneTankerSelect.lasteFraEnLagertank';
                config.tank = ['', Validators.compose([Validators.required])];
                config.loadingTemp = ['', Validators.compose([Validators.required])];
            } else if (this.transactionType === TransactionType.LoadingFromTruck) {
                this.transactionTypeTitle = 'EgneTankerSelect.overforeFraLastebil';
                config.truck = ['', Validators.compose([Validators.required])];
            }

            this.egneForm = formBuilder.group(config);
            this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });

                loader.present().then(() => {

                    let observable: Observable<any> = null;
                    if (this.transactionType === TransactionType.LoadingFromStorage) {
                        observable = Observable.create(observer => {
                            observer.next([]);
                            observer.complete();
                        });
                    } else if (this.transactionType === TransactionType.UnloadingAutoGas ||
                        this.transactionType === TransactionType.UnloadingToStorage) {
                        observable = this.userService.getInventoryProducts();
                    } else if (this.transactionType === TransactionType.LoadingFromTruck) {
                        observable = this.userService.getCurrentTruckProducts();
                    } else {
                        observable = this.userService.getProducts();
                    }

                    let observable2: Observable<any> = null;

                    if (this.transactionType === TransactionType.LoadingFromTruck) {
                        observable2 = this.userService.getTrailersAndTrucks();
                    } else {
                        observable2 = this.userService.getTanks();
                    }

                    forkJoin([observable, observable2, this.userService.getCurrentTruckAndTrailer().pipe(first()),
                        this.storage.get(AppSettings.StorageKeys.CompanyNo)]).subscribe((res: any) => {
                            this.translateService.get('Loading.loading').subscribe(v1 => {
                                this.currentTruck = res[2].Truck;
                                if (this.transactionType === TransactionType.UnloadingAutoGas ||
                                    this.transactionType === TransactionType.UnloadingToStorage) {
                                    this.products = res[0];
                                } else {
                                    const products: any[] = res[0];

                                    this.products = _.filter(products, (p) => {
                                        return p.ProductType === 10;
                                    });
                                }
                                loader.dismiss();
                            });

                            if (this.transactionType === TransactionType.LoadingFromTruck) {
                                this.trucks = _.filter(res[1].Trucks, t => {
                                    const truck = t as any;
                                    if (this.transactionType === TransactionType.LoadingFromTruck && this.currentTruck.ID === truck.ID) {
                                        return false;
                                    }
                                    return true;
                                });
                            } else if (this.transactionType === TransactionType.UnloadingAutoGas) {
                                let tankParams;
                                if (params.tank !== undefined) {
                                    tankParams = JSON.parse(params.tank);
                                    this.egneForm.get('company').setValue(tankParams.CompanyNo === 0 ? '' : tankParams.CompanyNo);
                                    this.egneForm.get('tank').setValue(tankParams.TankID);
                                } else {
                                    this.egneForm.get('company').setValue(res[3]);
                                }
                                this.tanks = _.filter(res[1], (t) => {
                                    const tank = t as any;
                                    return tank.TypeOfTank === TypeOfTank.AutoGas && t.CompanyNo.toString() ===
                                        (params.tank === undefined ? res[3].toString() : tankParams.CompanyNo.toString());
                                });
                            } else if (this.transactionType === TransactionType.UnloadingToStorage ||
                                this.transactionType === TransactionType.LoadingFromStorage) {
                                this.tanks = _.filter(res[1], (t) => {
                                    const tank = t as any;
                                    return tank.TypeOfTank === TypeOfTank.Storage && t.CompanyNo.toString() === res[3].toString();
                                });
                            }
                            if (this.transactionType !== TransactionType.LoadingFromTruck) {
                                this.tanksData = res[1];
                                this.userService.getCompanies().subscribe(data => { this.companies = data; });
                                if (this.transactionType !== TransactionType.UnloadingAutoGas) {
                                    this.egneForm.get('company').setValue(res[3]);
                                }
                            }
                        });
                });
            });
        });
    }

      async getCurrentLocation() {
    try {
      const position = await Geolocation.getCurrentPosition();
      this.currentLatitude = position.coords.latitude;
      this.currentLongitude = position.coords.longitude;

      this.userService.weatherLookup(this.currentLatitude, this.currentLongitude).subscribe(res => {
          this.temperature = res.temperature;
      });

    } catch (error) {
      //
    }
  }

    addDynamicControl(formGroup: FormGroup) {
        const product: FormControl = new FormControl('', Validators.compose([Validators.required]));
        const antLiter: FormControl = new FormControl('', Validators.compose([Validators.required]));
        const tankno: FormControl = new FormControl('', Validators.compose([Validators.required]));
        formGroup.addControl('product' + this.identity, product);
        formGroup.addControl('antLiter' + this.identity, antLiter);
        formGroup.addControl('tankno' + this.identity, tankno);
    }

    addProduct() {
        this.identity++;
        const formGroup: FormGroup = this.egneForm.controls.productGroup as FormGroup;
        this.addDynamicControl(formGroup);
        const data: any = {
            LineNo: this.identity,
            ProductNo: ''
        };
        this.productGroup.push(data);
    }

    setTemperature() {
        const loadingTemp = this.egneForm.get('loadingTemp');
        loadingTemp.setValue(this.temperature);
    }

    selectDefaultTank(lineNo) {
        if (this.inventoryList && this.currentProduct && this.transactionType === TransactionType.UnloadingAutoGas) {
            const matches = _.filter(this.inventoryList, { ProductNo: this.currentProduct.ProductNo });
            if (lineNo === 0) {
                if (matches.length === 1) {
                    this.egneForm.get('tankno').setValue(matches[0].TankRoomNo);
                } else {
                    this.egneForm.get('tankno').setValue('');
                }
            } else if (lineNo === null) {
                if (matches.length === 1) {
                    this.egneForm.get('tankno').setValue(matches[0].TankRoomNo);
                    for (const item of this.productGroup) {
                        if (item.LineNo !== 0) {
                            this.egneForm.controls.productGroup.get('tankno' + item.LineNo).setValue(matches[0].TankRoomNo);
                        }
                    }
                } else {
                    this.egneForm.get('tankno').setValue('');
                    for (const item of this.productGroup) {
                        if (item.LineNo !== 0) {
                            this.egneForm.controls.productGroup.get('tankno' + item.LineNo).setValue('');
                        }
                    }
                }
            } else {
                if (matches.length === 1) {
                    this.egneForm.controls.productGroup.get('tankno' + lineNo).setValue(matches[0].TankRoomNo);
                } else {
                    this.egneForm.controls.productGroup.get('tankno' + lineNo).setValue('');
                }
            }
        }
    }

    setProductGroupTankValue(matches, control) {
        if (matches.length === 1) {
            this.egneForm.controls.productGroup.get(control).setValue(matches[0].TankRoomNo);
        } else {
            this.egneForm.controls.productGroup.get(control).setValue('');
        }
    }

    ProductChangedUnloadingAutoGas(productNo, lineNo) {
        if (lineNo === 0) {
            this.productGroup.push({ LineNo: 0, ProductNo: productNo });
        }
        for (const item of this.productGroup) {
            if (lineNo === item.LineNo) {
                item.ProductNo = productNo;
            }
        }
        const index = this.productGroup.findIndex(data => data.ProductNo.toString() === productNo && data.LineNo !== lineNo);
        if (index !== -1) {
            forkJoin([this.translateService.get('EgneTanker.productAlreadyExists'), this.translateService.get('buttons.ok')])
                .subscribe(item => {
                    this.alertCtrl.create({
                        message: item[0],
                        buttons: [item[1]]
                    }).then(alert => { alert.present(); });
                });
            if (lineNo !== 0) {
                this.egneForm.controls.productGroup.get('product' + lineNo).setValue('');
            } else {
                this.egneForm.get('product').setValue('');
            }
        } else {
            this.currentProduct = _.find(this.products, { ProductNo: productNo });
            this.selectDefaultTank(lineNo);
        }
    }

    productChanged($event, lineNo) {
        if ($event) {
            if (this.transactionType === TransactionType.UnloadingAutoGas) {
                this.ProductChangedUnloadingAutoGas($event, lineNo);
            } else {
                this.currentProduct = _.find(this.products, { ProductNo: $event });
                this.selectDefaultTank(lineNo);
            }
        }
    }
        closeModal(){
    this.openModal = false;
}

    tankChanged($event) {
        if ($event) {
            this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });

                loader.present().then(() => {
                    if ($event) {
                        this.tank = _.find(this.tanks, { TankID: $event });

                        if (this.transactionType === TransactionType.LoadingFromStorage) {
                            this.userService.getTankProducts(this.tank.TankID).subscribe(data => {
                                this.products = data;
                            });
                        }

                        // if (this.inventoryList == null) {
                        this.userService.getCurrentInventoryCurrent(null, $event).subscribe(data => {
                            {
                                this.inventoryList = data.InventoryList;
                            }
                            this.inventoryList2 = data.InventoryList2;
                            this.selectDefaultTank(null);
                        });
                        loader.dismiss();
                    }
                    // }
                });
            });
        }
    }

    companyChanged(value) {
        // if (value !== '') {
        //  this.tanks = _.filter(this.tanksData, (tank) => {
        //     return tank.CompanyNo.toString() === value.toString();
        //  });
        // } else {
        if (this.transactionType === TransactionType.UnloadingAutoGas) {
            this.tanks = _.filter(this.tanksData, (t) => {
                const tank = t as any;
                return tank.TypeOfTank.toString() === TypeOfTank.AutoGas.toString()
                    && tank.CompanyNo.toString() === value.toString();
            });
        } else if (this.transactionType === TransactionType.UnloadingToStorage ||
            this.transactionType === TransactionType.LoadingFromStorage) {
            this.tanks = _.filter(this.tanksData, (t) => {
                const tank = t as any;
                return tank.TypeOfTank.toString() === TypeOfTank.Storage.toString()
                    && tank.CompanyNo.toString() === value.toString();
            });
        }
        // }
        this.egneForm.get('tank').setValue('');
    }

    truckChanged($event) {
        if ($event) {
            this.truck = _.find(this.trucks, { ID: $event });

            // if (this.inventoryList == null) {
            this.userService.getCurrentInventoryCurrent($event, null).subscribe(data => {
                this.inventoryList = data.InventoryList;
                this.inventoryList2 = data.InventoryList2;
            });
            // }
        }
    }

    navigateToEgneTankerSelect() {
        this.navCtrl.pop();
    }

    setQty(qty: number) {
        const loadingTemp = this.egneForm.get('loadingTemp');
        if (!loadingTemp.value) {
            loadingTemp.setValue(0);
        }
        loadingTemp.setValue((loadingTemp.value + qty));
    }

    ferdigmeld() {
        this.submitAttempt = true;
        if (this.egneForm.valid) {
            // get the deviation limit
            this.userService.getDeviationLimit(this.transactionType).subscribe(async totalData => {
                // check for validations
                const antLiter: number = this.egneForm.get('antLiter').value;
                const productNo: string = this.egneForm.get('product').value;
                const tankNo = this.egneForm.get('tankno') ? this.egneForm.get('tankno').value : 0;
                let inventory: any = null;
                if (this.transactionType === TransactionType.LoadingFromStorage) {
                    inventory = _.find(this.inventoryList, data => data.ProductNo.toString() === productNo);
                } else if (this.transactionType === TransactionType.UnloadingAutoGas) {
                    inventory = [];
                    for (const item of this.productGroup) {
                        let inv: any = null;
                        const product = item.LineNo === 0 ? productNo :
                            this.egneForm.controls.productGroup.get('product' + item.LineNo).value;
                        inv = _.find(this.inventoryList2, data => data.ProductNo.toString() === product);
                        if (inv) {
                            inv.AntLiter = item.LineNo === 0 ? antLiter :
                                this.egneForm.controls.productGroup.get('antLiter' + item.LineNo).value;
                            inv.TankNo = item.LineNo === 0 ? tankNo : this.egneForm.controls.productGroup.get('tankno' + item.LineNo).value;
                            inventory.push(inv);
                        }
                    }

                } else {
                    inventory = _.find(this.inventoryList2, data => data.ProductNo.toString() === productNo);
                }

                if (this.transactionType === TransactionType.UnloadingAutoGas) {
                    this.tankNo = tankNo;
                }
                if (inventory) {
                    if (this.transactionType === TransactionType.UnloadingToStorage) {
                        let maxValue: any = null;
                        if (this.tank.MaxVolume <= 0 && inventory.Quantity + totalData.DeviationLimit < antLiter) {
                            maxValue = inventory.Quantity;
                        } else if (this.tank.MaxVolume > 0 && Math.min(inventory.Quantity + totalData.DeviationLimit,
                            this.tank.MaxVolume) < antLiter) {
                            maxValue = Math.min(inventory.Quantity, this.tank.MaxVolume);
                        }

                        if (maxValue != null) {
                            this.translateService.get('EgneTanker.MaxValueAntLiter').subscribe(value => {
                                this.toastController.create({
                                    message: value.format(maxValue),
                                    duration: 3000
                                }).then(toast => { toast.present(); });

                            });
                            return;
                        }
                    } else if (this.transactionType === TransactionType.UnloadingAutoGas) {
                        const errorMessages = [];
                        for (const item of inventory) {
                            let maxValue: any = null;
                            if (this.tank.MaxVolume <= 0 && item.Quantity + totalData.DeviationLimit < item.AntLiter) {
                                maxValue = item.Quantity;
                            } else if (this.tank.MaxVolume > 0 && Math.min(item.Quantity + totalData.DeviationLimit,
                                this.tank.MaxVolume) < item.AntLiter) {
                                maxValue = Math.min(item.Quantity, this.tank.MaxVolume);
                            }
                            if (maxValue != null) {
                                this.translateService.get('EgneTanker.maxValue').subscribe(value => {
                                    errorMessages.push(value.format(item.ProductDescription, maxValue));
                                });
                            }
                        }
                        if (errorMessages.length > 0) {
                            let message = '';
                            for (const item of errorMessages) {
                                message += item + '\n';
                            }
                            this.toastController.create({
                                message,
                                duration: 3000
                            }).then(toast => { toast.present(); });
                            return;
                        }
                    } else if (this.transactionType === TransactionType.LoadingFromStorage ||
                        this.transactionType === TransactionType.LoadingFromTruck) {
                        let maxValue: any = null;

                        if (inventory.Quantity + totalData.DeviationLimit < antLiter) {
                            maxValue = inventory.Quantity;
                        }

                        if (maxValue != null) {
                            this.translateService.get('EgneTanker.MaxValueAntLiter').subscribe(value => {
                                this.toastController.create({
                                    message: value.format(maxValue),
                                    duration: 3000
                                }).then(toast => { toast.present(); });
                            });
                            return;
                        }
                    }
                } else {
                    this.translateService.get('EgneTanker.inventoryNotFound').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });
                    });
                    return;
                }
                // else if (this.tank) {
                //    let maxValue: any = null;

                //    if (this.tank.MaxVolume > 0 && this.tank.MaxVolum < antLiter) {
                //        maxValue = Math.min(inventory.Quantity + totalData.DeviationLimit, this.tank.MaxVolume);
                //    }

                //    if (maxValue != null) {
                //        this.translateService.get('EgneTanker.MaxValueAntLiter').subscribe(value => {
                //            let toast = this.toastController.create({
                //                message: value.format(maxValue),
                //                duration: 3000
                //            });
                //            toast.present();
                //        });
                //        return;
                //    }
                // }
                const modal = await this.modalCtrl.create({
                    component: EgneTankerConfirmComponent,
                    cssClass: 'egne-tanker-confirm',
                    componentProps: {
                        antLiter,
                        tankNo: this.tankNo,
                        tankName: this.tank ? this.tank.Name : null,
                        truckName: this.truck ? this.truck.Description : null,
                        productDescription: this.currentProduct.Description,
                        currentTruck: this.currentTruck,
                        transactionType: this.transactionType,
                        data: this.transactionType === TransactionType.UnloadingAutoGas ? inventory : null,
                        selectedDate: this.currentDate

                    }
                });
                modal.present();

                modal.onDidDismiss().then(data => {
                    if (data.data) {
                        this.currentDate = new Date(this.currentDate);
                        const dateStr = (Utils.padNumber(this.currentDate.getMonth() + 1)) + '-' +
                            (Utils.padNumber(this.currentDate.getDate())) + '-' +
                            (Utils.padNumber(this.currentDate.getFullYear()));
                        const egneTankerRequest: any = [];
                        if (this.transactionType === TransactionType.UnloadingAutoGas) {
                            for (const item of inventory) {
                                const request: any = {
                                    ProductNo: item.ProductNo,
                                    Quantity: item.AntLiter,
                                    TankNo: item.TankNo,
                                    TankStation: this.egneForm.get('tank') ? this.egneForm.get('tank').value : null,
                                    Truck: null,
                                    TransType: this.transactionType,
                                    TransDateStr: dateStr,
                                    LoadingTemp: null,
                                    Latitude: this.currentLatitude != null ? this.currentLatitude : 0,
                                    Longitude: this.currentLongitude != null ? this.currentLongitude : 0
                                };
                                egneTankerRequest.push(request);
                            }


                        } else {
                            const request: any = {
                                ProductNo: this.egneForm.get('product').value,
                                TankStation: this.egneForm.get('tank') ? this.egneForm.get('tank').value : null,
                                Truck: this.egneForm.get('truck') ? this.egneForm.get('truck').value : null,
                                TransType: this.transactionType,
                                TransDateStr: dateStr,
                                LoadingTemp: this.egneForm.get('loadingTemp') ? this.egneForm.get('loadingTemp').value : null,
                                Quantity: antLiter,
                                TankNo: 0,
                                Latitude: this.currentLatitude != null ? this.currentLatitude : 0,
                                Longitude: this.currentLongitude != null ? this.currentLongitude : 0
                            };
                            egneTankerRequest.push(request);
                        }


                        this.translateService.get('Loading.saving').subscribe(async value => {
                            const loader = await this.loadingController.create({ message: value });

                            loader.present().then(() => {
                                this.submitting = true;

                                // this.userService.getCurrentInventoryPlusDeviationLimit(this.transactionType).subscribe(totalData => {

                                //    if (totalData.CurrentInventoryTotal <= 0) {
                                //        this.submitting = false;
                                //        loader.dismiss();
                                //        this.translateService.get('EgneTanker.inventoryZero').subscribe(key => {

                                //            let toast = this.toastController.create({
                                //                message: key,
                                //                duration: 3000
                                //            });

                                //            toast.present();
                                //        });
                                //    }
                                //    if (request.Quantity > (totalData.DeviationLimit + totalData.CurrentInventoryTotal)) {
                                //        this.submitting = false;
                                //        loader.dismiss();
                                //        this.translateService.get('EgneTanker.MaxValue').subscribe(key => {

                                //            let toast = this.toastController.create({
                                //                message: key.format(totalData.CurrentInventoryTotal),
                                //                duration: 3000
                                //            });

                                //            toast.present();
                                //        });
                                //    }
                                //    else {
                                this.userService.egneAddTransaction(egneTankerRequest).subscribe(res => {
                                    this.submitting = false;
                                    if (res) {
                                        this.router.navigate(['/egne-tanker-select']);
                                    }
                                    loader.dismiss();
                                });
                                // }
                                // });
                            });
                        });
                    }
                });
            });
        }
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

       dateChangeEvent(event) {
      
            this.currentDate = new Date(event.detail.value);
        
        
    }
}
