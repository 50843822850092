<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span class="pull-left">{{ 'Settings.settings' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <ion-row>
        <ion-col class="ion-no-padding" size-xs="12" size-sm>
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="lock-open-outline"
                ion-text
                size="large"
                color="primary"
              ></ion-icon
              >{{ 'Settings.endrePassord' | translate}}
            </ion-card-header>
            <ion-card-content>
              <form [formGroup]="changePassForm">
                <ion-list lines="none">
                  <ion-item class="ion-icon icon-wrapper login-control">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                    <ion-input
                      class="ion-no-padding"
                      type="password"
                      formControlName="password"
                      autocomplete="off"
                      placeholder="{{ 'Settings.password' | translate }}"
                    ></ion-input>
                  </ion-item>
                  <ion-item
                    class="item-validation"
                    *ngIf="changePassForm.controls.password.dirty"
                  >
                    <ion-text color="lightFive">
                      <p
                        *ngIf="changePassForm.controls.password.errors?.required"
                      >
                        {{ 'Settings.validationPasswordRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-icon icon-wrapper login-control">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                    <ion-input
                      class="ion-no-padding"
                      type="password"
                      formControlName="confirmPassword"
                      autocomplete="off"
                      placeholder="{{ 'Settings.confirmPassword' | translate }}"
                    ></ion-input>
                  </ion-item>
                  <ion-item class="item-validation">
                    <ion-text color="lightFive">
                      <p
                        *ngIf="changePassForm.controls.confirmPassword.errors?.equalTo"
                      >
                        {{ 'Settings.passwordConfirmPasswordMatch' | translate
                        }}
                      </p>
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-float-right">
                    <ion-buttons>
                      <ion-button
                        fill="solid"
                        size="small"
                        (click)="setPassword()"
                        [disabled]="!changePassForm.valid"
                      >
                        <span>{{ 'General.save' | translate }}</span>
                      </ion-button>
                    </ion-buttons>
                  </ion-item>
                </ion-list>
              </form>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col class="ion-no-padding" col-12 col-sm>
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="refresh"
                ion-text
                color="primary"
                size="large"
              ></ion-icon>
              v.{{version}}
            </ion-card-header>
            <ion-card-content>
              <ion-list lines="none">
                <ion-item class="ion-margin-top">
                  <ion-buttons
                    class="ion-text-center ion-text-right"
                    routerLink="/release-notes"
                    slots="end"
                    ><ion-button size="default" fill="outline">
                      <span>{{ 'Settings.changeLog' | translate }}</span>
                    </ion-button>
                  </ion-buttons>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-no-padding" size-xs="12" size-sm size-lg="6">
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="resize"
                ion-text
                color="primary"
                size="large"
              ></ion-icon>
              {{ 'Settings.fontSettings' | translate }}
            </ion-card-header>
            <ion-card-content>
              <ion-list lines="none">
                <ion-radio-group
                  [(ngModel)]="fontSettings"
                  (ionChange)="radioChecked()"
                >
                  <ion-item>
                    <ion-label>{{ 'Settings.normal' | translate }} </ion-label>
                    <ion-radio value="0"></ion-radio>
                  </ion-item>
                  <ion-item>
                    <ion-label>{{ 'Settings.bigger' | translate }} </ion-label>
                    <ion-radio value="2"></ion-radio>
                  </ion-item>
                  <ion-item>
                    <ion-label>{{ 'Settings.biggest' | translate }} </ion-label>
                    <ion-radio value="4"></ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col class="ion-no-padding" size-xs="12" size-sm size-lg="6">
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="document-outline"
                ion-text
                size="large"
                color="primary"
              ></ion-icon>
              {{ 'Settings.setStartupView' | translate }}
            </ion-card-header>
            <ion-card-content *ngIf="menuItems">
              <ion-list lines="none">
                <ion-select
                  [(ngModel)]="startupViewSettings"
                  placeholder="{{ 'General.select' | translate }}"
                  (ionChange)="savestartupViewSettings()"
                >
                  <ion-select-option value=""
                    >{{ 'General.select' | translate }}</ion-select-option
                  >
                  <ion-select-option
                    *ngFor="let item of menuItems"
                    [value]="item.url"
                    >{{item.title}}</ion-select-option
                  >
                </ion-select>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
