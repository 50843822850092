import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseHttpClient } from './http-client';
import { API_URL } from './tokens';

@Injectable({
  providedIn: 'root'
})
export class CustomerData {
  private url: string;

  constructor(private http: HttpClient, @Inject(API_URL) private apiurl: string) {
    this.url = apiurl;
  }

  search(term: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}` + term)
      .pipe(
        map(res => res.map(customer => ({
          Name: customer.Name,
          Company: customer.CustomerCompany,
          Address: customer.Address,
          Address2: customer.Address2,
          CustomerPO: customer.CustomerPO != null ? customer.CustomerPO : '',
          CustomerReference: customer.CustomerReference,
          City: customer.City,
          PostalCode: customer.PostalCode,
          CustomerBlockedStatus: customer.CustomerBlockedStatus,
          Description: customer.Description,
          OrgNo: customer.OrgNo,
          CustomerNo: customer.CustomerNo,
          AddressList: customer.CustomerAddresses,
          ErpID: customer.ErpID,
          PriceAgreementID: customer.PriceAgreementID,

        })))
      );
  }
}
