import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';



@Component({
  selector: 'app-map-view',
  templateUrl: './map-viewer.html',
  styleUrls: ['./map-viewer.scss'],
})
export class MapViewerComponent implements OnInit, OnChanges {

   @Input() latitude!: number;
  @Input() longitude!: number;
  private map!: L.Map;private marker!: L.Marker;

  leafletOptions: L.MapOptions;

  constructor() { 
    // Initialize leafletOptions with default values
    this.leafletOptions = {
      layers: [
        L.tileLayer('https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png', { attribution: '&copy; <a href="http://www.kartverket.no/">Kartverket</a>' })
      ],
      zoom: 15,
      center: L.latLng(0, 0) // Default center, to be updated in ngAfterViewInit
    };
  }

  ngOnInit() {
    // Update the center in leafletOptions based on initial latitude and longitude
    this.leafletOptions.center = L.latLng(this.latitude, this.longitude);
  }

  ngAfterViewInit() {
    this.initializeMap();
  }

   ngOnChanges(changes: SimpleChanges) {
    if (changes.latitude || changes.longitude) {
      if(this.latitude > 0)
      this.updateMapLocation();
    }
  }

  private initializeMap() {
    if (!this.map) {
      // Initialize the map with the defined options
      this.map = L.map('map', this.leafletOptions);

      this.addMarker(this.latitude, this.longitude);

      // Ensure the map is correctly resized
      setTimeout(() => {
        this.map.invalidateSize();
      }, 100);
    }
  }

  private updateMapLocation() {
    if (this.map) {
      // Update the map view and the marker position
      this.map.setView([this.latitude, this.longitude], this.map.getZoom());
      this.addMarker(this.latitude, this.longitude);
    }
  }

  private addMarker(lat: number, lng: number) {
    const customIcon = L.icon({
      iconUrl: 'assets/icon/marker-icon.png',
      shadowUrl: 'assets/icon/marker-shadow.png',
      iconSize: [30, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    if (this.marker) {
      this.marker.setLatLng([lat, lng]);
    } else {
      this.marker = L.marker([lat, lng], { icon: customIcon }).addTo(this.map);
    }
  }
}