import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { UserService } from '../../shared/user-service';

@Component({
    selector: 'page-checklist-monthly-control',
    templateUrl: 'checklist-monthly-control.html',
    styleUrls: ['checklist-monthly-control.scss']
})
export class ChecklistMonthlyControlPage implements OnInit {
    @ViewChild('picker') picker: any;
    @ViewChild('approvalPicker') approvalPicker: any;
    @ViewChild('nextPressurePicker') nextPressurePicker: any;
    fireExtinguisher = new Date().toISOString();
    approvalDate = new Date().toISOString();
    nextDate = new Date().toISOString();
    checklistForm: FormGroup;
    submitAttempt = false;
    checkPoints: any = [];
    submit = false;
    constructor(private router: Router, private formBuilder: FormBuilder, private userService: UserService) {
        this.checklistForm = formBuilder.group(
            {
                ownerName: ['', Validators.required],
                ownerAddress: ['', Validators.required],
                signatureDriver: ['', Validators.required],
                controlMonths: ['', Validators.required],
                regNo: ['', Validators.required],
                regNr: [''],
                modelYearCar: ['', Validators.required],
                modelYearTrailer: ['', Validators.required],
                internetNo: ['', Validators.required],
                tankbil: [false],
                semiTrailer: [false],
                supporter: [false],
                brandCar: ['', Validators.required],
                tankMakeCar: ['', Validators.required],
                tankVolume: ['', Validators.required],
                tankManufacturer: ['', Validators.required],
                tankVolumeHenger: ['', Validators.required],
            });
    }

    navigateToBack() {
        this.router.navigate(['/documentation']);
    }

    getFormControlValue(field) {
        return this.checklistForm.get(field).value;
    }

    ionViewDidEnter() {
        this.loadData();
    }

    loadData() {
        for (var item of this.checkPoints) {
            item.BilOk = false;
            item.BilRepair = false;
            item.BilDoubt = false;
            item.HengerOk = false;
            item.HengerRepair = false;
            item.HengerDoubt = false;
            item.Comment = '';
        }
    }

    ngOnInit() {
        this.userService.getCheckPoints().subscribe(data => {
            this.checkPoints = data;
            this.loadData();
        });
    }

    openPicker(picker) {
        if (picker === 'picker') {
            this.picker.open();
        } else if (picker === 'approvalPicker') {
            this.approvalPicker.open();
        } else {
            this.nextPressurePicker.open();
        }
    }

    save() {
        this.submitAttempt = true;
        if (this.checklistForm.valid) {
            this.submit = true;
            const selectedCheckPoints = _.filter(this.checkPoints, (item) => {
                if (item.BilOk || item.BilRepair || item.BilDoubt || item.HengerOk || item.HengerRepair || item.HengerDoubt) {
                    return item;
                }
            });
            const checklist: any = {
                FireExtinguisher: new Date(this.fireExtinguisher),
                ADRApproval: new Date(this.approvalDate),
                NextPressureTestWithin: new Date(this.nextDate),
                OwnerName: this.getFormControlValue('ownerName'),
                OwnerAddress: this.getFormControlValue('ownerAddress'),
                SignatureDriver: this.getFormControlValue('signatureDriver'),
                ControlMonths: this.getFormControlValue('controlMonths'),
                RegNo: this.getFormControlValue('regNo'),
                RegNr: this.getFormControlValue('regNr'),
                ModelYearCar: this.getFormControlValue('modelYearCar'),
                ModelYearTrailer: this.getFormControlValue('modelYearTrailer'),
                InternalId: this.getFormControlValue('internetNo'),
                TankBil: this.getFormControlValue('tankbil'),
                Supporter: this.getFormControlValue('supporter'),
                SemiTrailer: this.getFormControlValue('semiTrailer'),
                BrandCar: this.getFormControlValue('brandCar'),
                TankMakeCar: this.getFormControlValue('tankMakeCar'),
                TankVoumeBil: this.getFormControlValue('tankVolume'),
                TankMakeHanger_Semi: this.getFormControlValue('tankManufacturer'),
                TankVolumeHanger: this.getFormControlValue('tankVolumeHenger'),
                CheckListCheckPoints: selectedCheckPoints
            };
            this.userService.saveChecklist(checklist).subscribe(data => {
                this.submit = false;
                this.submitAttempt = false;
                this.checklistForm.reset();
                this.navigateToBack();
            });
        }
    }
}
