import { Component, ViewChild, OnInit } from '@angular/core';
import { NavController, LoadingController, ModalController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { NewMessageComponent } from '../../components/new-message/new-message';
import _ from 'lodash';
import { Modules } from '../../shared/enums';
import { Router } from '@angular/router';

@Component({
    selector: 'page-home',
    templateUrl: 'home.html',
    styleUrls: ['home.scss']
})
export class HomePage implements OnInit {
    name: string;
    private currentDate: any = new Date();
    dateFormat;
    userlevel: number;
    inventoryList: any[];
    private chart: any;
    options: any;
    private orderCount = 0;
    messages: any[] = null;
    showActivityAlert:boolean = false;
    showActivityAlertMessage = "";
    showAlert = "";
    showDanger:boolean = false;
    currentMessageIndex = 0;
    hideShortcutInventory = false;
    hideShortcutOrder = false;
    hideShortcutInternalOrder = false;
    hideShortcutTankMonitoring = false;
    userScore;
    topLeadScore={first:{name:'',score:0},second:{name:'',score:0},third:{name:'',score:0}};
    @ViewChild('chartCon')
    private chartCon: any;
    appShortcuts: any;

    constructor(
        public navCtrl: NavController, private userService: UserService, private loadingController: LoadingController,
        public translateService: TranslateService, private router: Router,
        private storage: Storage, private modalCtrl: ModalController) {

    }

    ionViewDidEnter() {

      
        this.userService.getCurrentInventory().subscribe(data => {
                this.inventoryList = data;
                let _inventory: any[] = [];
                this.inventoryList .forEach(element => {
                    if (element.Quantity + element.QuantityInOrder !== 0) {
                        _inventory.push(element);
                    }
                });
                this.inventoryList = _inventory;
            });
            this.userService.getTopLeadScore().subscribe(data => {
                if(data.length>0){
                    this.topLeadScore.first.name=data[0].UserName;
                    this.topLeadScore.first.score=data[0].Count;
                }
                if(data.length>1){
                    this.topLeadScore.second.name=data[1].UserName;
                    this.topLeadScore.second.score=data[1].Count;
                }
                if(data.length>2){
                    this.topLeadScore.third.name=data[2].UserName;
                    this.topLeadScore.third.score=data[2].Count;
                }
            });
            this.userService.getUserLeadScore().subscribe(data => {
                this.userScore = data;
            });
    }

    ngOnInit() {
        setTimeout(() => {
            if (this.hideShortcutTankMonitoring) {
                if (this.chart) {
                    while (this.chart.series.length > 0) {
                        this.chart.series[0].remove(false);
                    }

                    this.chart.redraw();
                }

                // legend: {
                //    enabled: false,
                //    },

                this.options = {
                    spacing: [0, 0, 0, 0],
                    xAxis: {
                        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                    },
                    chart: {
                        type: 'line',
                        width: this.chartCon.el.clientWidth,
                        height: 230
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    series: [],
                    tooltip: {
                        formatter: function formatter() {
                            return this.y;
                        }
                    }
                };
                this.userService.getDeliveryStats(this.currentDate).subscribe(data => {

                    const seriesCurrent = { name: 'I år', data: [], color: '#FFCE55', allowPointSelect: true };
                    const seriesPrevious = { name: 'I fjor', data: [], color: '#4CD0B0', allowPointSelect: true };

                    for (let index = 1; index <= 12; index++) {

                        const item = _.find(data, { Month: index }) as any;
                        let currentYear = 0;
                        let previousYear = 0;

                        if (item) {
                            currentYear = item.CurrentYear;
                            previousYear = item.PreviousYear;
                        }

                        seriesCurrent.data.push(currentYear);
                        seriesPrevious.data.push(previousYear);

                    }

                    if (this.chart) {
                        this.chart.addSeries(seriesCurrent);
                        this.chart.addSeries(seriesPrevious);
                        this.chart.reflow();
                    }

                });
            }
            this.translateService.get('General.dateFormat').subscribe(value => {
                this.dateFormat = value;
            });
            this.translateService.get('Loading.loading').subscribe(async value => {
                // const loader = await this.loadingController.create({ message: value });

                //   loader.present().then(() => {

                forkJoin(
                    [this.storage.get(AppSettings.StorageKeys.UserFullName),
                    this.storage.get(AppSettings.StorageKeys.Userlevel),
                    this.userService.getOrderCount(this.currentDate), 
                    this.userService.getMessages(),
                    this.userService.getBasicData(),
                this.userService.getAppShortcuts()]
                    ).subscribe(data => {
                        this.name = data[0];
                        this.userlevel = data[1];
                        this.orderCount = data[2] as number;
                        this.messages = data[3];
                         this.appShortcuts = JSON.parse(data[5]);

                        try {
                            this.showAlert = data[4].ShowAlert as string;
                            this.showActivityAlert = data[4].ShowActivityAlert as boolean;
                            this.showActivityAlertMessage = data[4].ShowActivityAlertMessage as string;
                            this.showDanger = data[4].ShowDanger as boolean;
                           

                        } catch (error) {

                        }
                        try {
                            this.storage.set(AppSettings.StorageKeys.LastMessageId, this.messages[0].MessageID);
                        } catch (exception) { this.storage.set(AppSettings.StorageKeys.LastMessageId, 0); }
                        try {

                        } catch (exception) { }

                        // loader.dismiss();
                        // });
                    });
            });
        }, 500);

        this.userService.moduleAccess.subscribe((value: any) => {
            if (value) {
            value.forEach(element => {
                switch (element.Module) {
                    case Modules.Inventory as number:
                        this.hideShortcutInventory = element.HideModule;
                        break;
                    case Modules.Orders as number:
                        this.hideShortcutOrder = element.HideModule;
                        break;
                    case Modules.InternalOrders as number:
                        this.hideShortcutInternalOrder = element.HideModule;
                        break;
                    case Modules.TankMonitoring as number:
                        this.hideShortcutTankMonitoring = element.HideModule;
                        break;
                }
            });
        }
        });
    }

    nextMessage() {
        this.currentMessageIndex++;
    }

    previousMessage() {
        this.currentMessageIndex--;
    }

    saveChart(chart) {
        this.chart = chart;
    }

    navigateToBeholdning() {
        if (!this.hideShortcutInventory) {
            this.router.navigate(['/beholdning']);
            this.userService.events.next('beholdning');
        }
    }

    navigateToOrders() {
        if (!this.hideShortcutOrder) {
            this.router.navigate(['/orders']);
            this.userService.events.next('orders');
        }
    }

    navigateToInternalOrders() {
        if (!this.hideShortcutInternalOrder) {
            this.router.navigate(['/egne-tanker-select']);
            this.userService.events.next('egne-tanker-select');
        }
    }

    async showNewMessageDialog() {
        const modal = await this.modalCtrl.create({ component: NewMessageComponent, cssClass: 'new-message' });
        modal.present();

        modal.onDidDismiss().then((newMessage: any) => {
            if (newMessage.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.newMessage(newMessage.data).subscribe(data => {
                            if (newMessage.data.UserID === '0' || newMessage.data.UserID === AppSettings.Username) {
                                newMessage.data.CreatedByUser = this.name;
                                // push the data instead of refetching
                                if(this.messages.length == 0){ this.messages = new Array();}

                                this.messages.push(newMessage);
                            }
                            loader.dismiss();
                        });
                    });
                });
            }
        });
    }
}
