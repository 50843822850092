<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Menu.orderForApproval' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <div class="max-100 overflow-x">
            <div class="grid-width">
              <ion-row class="grid-header">
                <ion-col size="8" class="ion-text-left">
                  <div class="search-container">
                    <ion-searchbar
                      [(ngModel)]="search"
                      class="customer-search"
                      placeholder="{{ 'TransactionLog.searchPlaceHolder' | translate }}"
                      (keyup)="filterChanged(null)"
                    >
                    </ion-searchbar>
                    <ion-icon
                      name="close-circle-outline"
                      class="clear-icon"
                      (click)="clearSearch()"
                    ></ion-icon>
                  </div>
                  <br />&nbsp;&nbsp;&nbsp;
                  <strong>{{ 'Ordrer.ordre' | translate }}</strong>
                  <ion-button
                    (click)="sort('OrderNo')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin"
                    fill="clear"
                    >&nbsp;
                    <ion-icon
                      ion-text
                      color="primary"
                      [name]="(sortProperty == 'OrderNo' && sortDirection == 'asc') || sortProperty != 'OrderNo' ? 'caret-up' : 'caret-down'"
                    ></ion-icon>
                  </ion-button>
                  &nbsp;
                  <strong>{{ 'OrdreDetails.delDate' | translate }}</strong>
                  <ion-button
                    (click)="sort('ConfirmedDeliveryDate')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin"
                    fill="clear"
                    >&nbsp;
                    <ion-icon
                      ion-text
                      color="primary"
                      [name]="(sortProperty == 'ConfirmedDeliveryDate' && sortDirection == 'asc') || sortProperty != 'ConfirmedDeliveryDate' ? 'caret-up' : 'caret-down'"
                    ></ion-icon>
                  </ion-button>
                  &nbsp;

                  <strong>{{ 'Ordrer.kunde' | translate }}</strong>
                  <ion-button
                    (click)="sort('DeliveryName')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin"
                    fill="clear"
                    >&nbsp;
                    <ion-icon
                      ion-text
                      color="primary"
                      [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'"
                    ></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col size="4" class="ion-text-right">
                  <select
                    title="Transporter filter"
                    id="transporters"
                    class="mr-10"
                    [(ngModel)]="currentTransporter"
                    (ngModelChange)="filterOrders($event)"
                  >
                    <option
                      *ngFor="let item of transporters"
                      value="{{item.ID}}"
                    >
                      {{item.Description}}
                    </option>
                  </select>
                </ion-col>
              </ion-row>
              <ion-list>
                <ion-row
                  *ngFor="let order of filteredOrders; let isOdd = odd; let i = index"
                  [class.odd]="isOdd"
                  [attr.itemid]="order.OrderNo"
                  id="{{order.OrderNo}}"
                >
                  <ion-col>
                    <ion-row>
                      <!-- ORDER NO -->
                      <ion-col size="1" class="handle ion-text-center">
                        <div
                          (click)="expandOrder(order)"
                          class="cursor-pointer"
                        >
                          <ion-text
                            [color]="isOrderMine(order) == true ? 'success' : ''"
                            ><strong>{{order.OrderNo}}</strong></ion-text
                          >

                          <p>
                            {{order.ConfirmedDeliveryDate | date:dateFormat}}
                          </p>
                          <div
                            *ngIf="isOrderMine(order) == true"
                            class="handle"
                          ></div>
                        </div>
                      </ion-col>

                      <!-- ORDER NAME -->
                      <ion-col size="4">
                        <div
                          class="cursor-pointer orderlist"
                          (click)="expandOrder(order)"
                        >
                          <ion-icon
                            color="primary"
                            [name]="!order.expandedView ? 'add-circle-outline' : 'remove-circle-outline'"
                            class="litte-big-font vertical-text-bottom orderlistIcon"
                          >
                          </ion-icon>
                          <div class="orderlistHeading">
                            <strong>{{order.DeliveryName}}</strong>&nbsp;
                            ({{order.CustomerID}})
                            <ng-container
                              *ngIf="order.CustomerName != order.DeliveryName"
                            >
                              <br />
                              {{order.CustomerName}}
                            </ng-container>
                            <br />
                            {{order.DeliveryAddress1}}
                            <br />{{order.DeliveryPostalCode}}
                            {{order.DeliveryCity}}
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size="3">
                        <div
                          class="internal m-t-5"
                          [class.bigger-font]="userService.fontSettings==2"
                          [class.biggest-font]="userService.fontSettings==4"
                        >
                          <ion-text
                            ><strong
                              >{{ 'NewOrder.customerReference' | translate
                              }}</strong
                            >&nbsp; {{order.CustomerReference}}
                            &nbsp;&nbsp;{{order.CustomerPurchaseOrderNo}}</ion-text
                          >
                        </div>
                      </ion-col>
                      <ion-col size="4" class="ion-text-right">
                        <ion-button
                          (click)="navigteToOrderDetilsSales(order)"
                          fill="solid"
                          *ngIf="order.InvoiceNo == '' || order.InvoiceNo == null"
                        >
                          <ion-icon name="create-outline"></ion-icon> &nbsp; {{
                          'Ordrer.edit' | translate }}
                        </ion-button>
                        <ion-button
                          (click)="approveForInvoice(order)"
                          [hidden]="order.Approve"
                          fill="solid"
                          color="success"
                          *ngIf="order.InvoiceNo == '' || order.InvoiceNo == null"
                        >
                          <ion-icon name="checkbox-outline"></ion-icon> &nbsp;
                          {{ 'Ordrer.approve' | translate }}
                        </ion-button>
                        <ion-button
                          *ngIf="order.InvoiceNo != '' && order.InvoiceNo != null"
                          (click)="getinvoicePDF(order.InvoiceNo)"
                          class="bigger-font"
                          title="Faktura nr {{ order.InvoiceNo}}"
                          color="primary"
                          fill="outline"
                          ><ion-icon name="document-attach-outline"></ion-icon
                          >&nbsp;Faktura nr {{ order.InvoiceNo}}</ion-button
                        >
                      </ion-col>

                      <!-- EXPANDED VIEW -->
                      <ion-col
                        size-sm="12"
                        *ngIf="order.expandedView"
                        [class.expandedOrder]="!isOdd"
                        class="orderlistExpanded"
                      >
                        <ion-list lines="none">
                          <ion-item>
                            <ion-row class="w-100">
                              <!-- EXPANDED VIEW - DATES -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="calendar-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold">
                                    <span
                                      >{{ 'OrdreDetails.leveringsdato' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span class="detailsIconText"
                                  >{{order.DeliveryDate | date:dateFormat}}

                                  <span
                                    *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                                  >
                                    /
                                    <ion-icon
                                      name="alert-circle-outline"
                                      class="circle vertical-text-bottom"
                                      color="danger"
                                    ></ion-icon>
                                    {{order.ConfirmedDeliveryDate |
                                    date:dateFormat}}</span
                                  >
                                </span>
                              </ion-col>

                              <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="people-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.CustomerBuyer' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span class="detailsIconText"
                                  >{{order.CustomerBuyer}}</span
                                >
                              </ion-col>

                              <!-- EXPANDED VIEW - OUR SELLER -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="person-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.OurSeller' | translate
                                      }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span class="detailsIconText"
                                  >{{order.Seller}}</span
                                >
                              </ion-col>
                            </ion-row>
                          </ion-item>

                          <ion-item>
                            <ion-row class="w-100 margin-top-10">
                              <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="cube-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.leveringsinfo' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <ng-container *ngIf="order.TankInfo"
                                  ><span class="detailsIconText"
                                    >{{order.TankInfo}}</span
                                  >
                                  <br />
                                </ng-container>
                                <ng-container *ngIf="order.DeliveryInfo"
                                  ><span class="detailsIconText"
                                    >{{order.DeliveryInfo}}</span
                                  >
                                  <br />
                                </ng-container>
                              </ion-col>
                              <!--Phone onsite-->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="call-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.onSite' | translate
                                      }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span
                                  class="detailsIconText"
                                  appPhoneRegex
                                  [inputText]="order.PhoneOnSite"
                                ></span>
                              </ion-col>
                              <!--Phone manager-->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="call-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.manager' | translate
                                      }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span
                                  class="detailsIconText"
                                  appPhoneRegex
                                  [inputText]="order.PhoneManager"
                                ></span>
                              </ion-col>
                            </ion-row>
                          </ion-item>

                          <ion-item
                            *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite"
                          >
                            <ion-row class="w-100 margin-top-10">
                              <!-- INTERNAL INFO-->
                              <ion-col size-xs="12" size-sm="4">
                                <ion-row *ngIf="order.InternalInfo != ''">
                                  <ion-col size-xs="12">
                                    <p>
                                      <ion-icon
                                        name="chatbox-ellipses-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.internInfo' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span
                                      class="detailsIconText"
                                      appPhoneRegex
                                      [inputText]="order.InternalInfo"
                                    ></span>
                                  </ion-col>
                                </ion-row>
                              </ion-col>

                              <!-- EXTERNAL INFO -->
                              <ion-col size-xs="12" size-sm="4">
                                <ion-row *ngIf="order.ExternalInfo != ''">
                                  <ion-col size-xs="12">
                                    <p>
                                      <ion-icon
                                        name="chatbox-ellipses-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.externInfo' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span class="detailsIconText"
                                      >{{order.ExternalInfo}}</span
                                    >
                                  </ion-col>
                                </ion-row>
                              </ion-col>

                              <ion-col size-xs="12" size-sm="4"> </ion-col>
                            </ion-row>
                          </ion-item>

                          <!-- PRODUCT LIST -->
                          <div
                            class="text-small ion-margin-bottom ion-margin-start margin-top-10"
                            *ngIf="order.Orderlines.length > 0"
                          >
                            <ion-row class="orderlistExpandProductHeading">
                              <ion-col size-xs="12" size-sm="2"
                                ><b
                                  >{{ 'OrdreDetails.produkt' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.levert' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.cost' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.pris' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.freight' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.rab' | translate | uppercase
                                  }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.priceAdditions' | translate
                                  | uppercase }}&nbsp;&nbsp;{{
                                  'OrdreDetails.priceIncAdditions' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.total' | translate |
                                  uppercase }}</b
                                ></ion-col
                              >
                              <ion-col size-xs="1"
                                ><b
                                  >{{ 'OrdreDetails.DB' | translate | uppercase
                                  }}</b
                                ></ion-col
                              >
                            </ion-row>
                            <ion-row *ngFor="let orderLine of order.Orderlines">
                              <ion-col size-xs="12" size-sm="2">
                                <div class="flex-parent">
                                  <ion-badge
                                    [style.background]="orderLine.ProductColorCode"
                                    >{{orderLine.ProductShortCode}}</ion-badge
                                  >&nbsp;
                                  <span class="truncate-text"
                                    >{{orderLine.Description}}</span
                                  >
                                </div>
                              </ion-col>
                              <ion-col size-xs="1">
                                <span class="slight-big-font"
                                  ><strong
                                    >{{orderLine.Quantity | number:'1.0-1'}} /
                                    {{orderLine.QuantityDelivered |
                                    number:'1.0-1'}}</strong
                                  ></span
                                >
                              </ion-col>
                              <ion-col size-xs="1"
                                >{{ orderLine.UnitCost |
                                number:'1.1-3'}}</ion-col
                              >
                              <ion-col size-xs="1"
                                >{{ orderLine.UnitPrice |
                                number:'1.1-3'}}</ion-col
                              >
                              <ion-col size-xs="1"
                                >{{ orderLine.UnitFreightCost |
                                number:'1.1-2'}}</ion-col
                              >
                              <ion-col size-xs="1"
                                >{{ orderLine.UnitDiscount |
                                number:'1.0-1'}}</ion-col
                              >
                              <ion-col size-xs="1"
                                >{{ orderLine.PriceAdditions |
                                number:'1.1-2'}}</ion-col
                              >

                              <ion-col size-xs="1"
                                >{{ orderLine.OrderLineTotal |
                                number:'1.0-2'}}</ion-col
                              >
                              <ion-col size-xs="1"
                                >{{
                                ((orderLine.OrderLineTotal)-(orderLine.UnitCost*orderLine.QuantityDelivered))/orderLine.QuantityDelivered|
                                number:'1.2-2'}}</ion-col
                              >
                            </ion-row>
                          </div>
                        </ion-list>
                      </ion-col>
                      <!-- END -->
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-list>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button fill="outline" class="no-padding" routerLink="/work-orders">
    <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
    <span class="black">{{ 'Ordrer.tilbake' | translate }}</span>
  </ion-button>
  <ion-button fill="outline" class="no-padding" (click)="expandAllOrders()">
    <ion-icon name="expand" color="primary"></ion-icon>
  </ion-button>
  <ion-button fill="outline" class="no-padding" (click)="filterOrders(null)">
    <ion-icon name="refresh" color="primary"></ion-icon>
  </ion-button>
</ion-footer>
