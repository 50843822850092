<!-- src/app/dynamic-form/dynamic-form.component.html -->
<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ pageTitle }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <br /><br /><br /><br /><br />
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <ion-grid>
      <!-- Iterate over form schema to maintain order -->
      <ion-row>
        <ng-container
          *ngFor="let field of formSchema"
          [ngSwitch]="field.type === 'array' ? 'array' : 'field'"
        >
          <!-- Field Columns -->
          <ion-col size="12" size-md="3" *ngSwitchCase="'field'">
            <ion-item *ngIf="field.type !== 'array'">
              <ion-label position="stacked">{{ field.label }}</ion-label>
              <ng-container [ngSwitch]="field.type">
                <ion-input
                  *ngSwitchCase="'text'"
                  [formControlName]="field.name"
                  [placeholder]="field.placeholder"
                ></ion-input>

                <ion-textarea
                  *ngSwitchCase="'textarea'"
                  [formControlName]="field.name"
                  [placeholder]="field.placeholder"
                ></ion-textarea>

                <ion-button
                  fill="clear"
                  color="dark"
                  class="picker"
                  (click)="openModal(field.name)"
                  *ngSwitchCase="'date'"
                >
                  <ion-modal
                    [cssClass]="'center'"
                    [isOpen]="openModals[field.name]"
                    (didDismiss)="closeModal(field.name)"
                  >
                    <ng-template>
                      <ion-datetime
                        presentation="date"
                        size="cover"
                        doneText="OK"
                        cancelText="Cancel"
                        class="dateText"
                        first-day-of-week="1"
                        [formControlName]="field.name"
                        (ionChange)="onDateChange($event, field.name)"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-modal>
                  <ion-icon
                    color="primary"
                    name="calendar-outline"
                    slot="end"
                    *ngSwitchCase="'date'"
                  ></ion-icon
                  >&nbsp; {{ selectedDates[field.name] }} &nbsp;
                </ion-button>

                <ionic-selectable
                  *ngSwitchCase="'trucks'"
                  [items]="trucks"
                  itemValueField="ID"
                  itemTextField="Description"
                  [formControlName]="field.name"
                  placeholder="{{ 'StockGoods.truckAndTrailer' | translate }}"
                  itemValueField="ID"
                  itemTextField="Description"
                  [canSearch]="true"
                  [canClear]="true"
                  searchPlaceholder="{{'StockGoods.typeToSearch' | translate }}"
                  clearButtonText="{{'StockGoods.clear' | translate }}"
                  closeButtonText="X"
                >
                  <ng-template ionicSelectableCloseButtonTemplate>
                    <ion-icon
                      name="close-circle-outline"
                      style="font-size: 24px"
                    ></ion-icon>
                    &nbsp;{{ 'StockGoods.cancel' | translate }}
                  </ng-template>
                </ionic-selectable>
              </ng-container>
            </ion-item>
            <ion-item *ngIf="form.get(field.name + '_comment')">
              <ion-input
                [formControlName]="field.name + '_comment'"
                placeholder="Kommentar"
              ></ion-input>
            </ion-item>
          </ion-col>
        </ng-container>
      </ion-row>

      <!-- Array Rows -->
      <ng-container
        *ngFor="let field of formSchema"
        [ngSwitch]="field.type === 'array' ? 'array' : 'field'"
      >
        <ion-row *ngSwitchCase="'array'">
          <ion-col size="12">
            <ion-item-divider>
              <ion-label>{{ field.label }}</ion-label>
            </ion-item-divider>
            <div formArrayName="{{ field.name }}">
              <ion-row
                *ngFor="let item of getFormArrayControls(field.name); let i = index"
                [formGroupName]="i"
              >
                <ion-col size="12" size-md="6">
                  <ion-item>
                    <ion-label
                      >{{ getFieldLabel(getFirstControlName(item)) }}</ion-label
                    >
                    <ion-checkbox
                      slot="start"
                      [formControlName]="getFirstControlName(item)"
                      (ionChange)="onCheckboxChange($event, field.name, field.items[i].name)"
                    ></ion-checkbox>
                  </ion-item>
                </ion-col>
                <ion-col size="12" size-md="6">
                  <ion-item
                    *ngIf="item.get(getFirstControlName(item) + '_comment')"
                  >
                    <ion-input
                      [formControlName]="getFirstControlName(item) + '_comment'"
                      placeholder="Kommentar"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
    <ion-button [disabled]="submitting" expand="full" type="submit"
      >Send</ion-button
    >
  </form>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button fill="outline" class="no-padding" routerLink="/documentation">
    <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
    <span class="black">{{ 'Ordrer.tilbake' | translate }}</span>
  </ion-button>
  <ion-button fill="outline" class="no-padding" routerLink="/forms-handling">
    <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
    <span class="black">{{ 'Menu.formsADM' | translate }}</span>
  </ion-button>
</ion-footer>
