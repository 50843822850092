<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Menu.stockGoods' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <form [formGroup]="stockGoodsForm" class="stockGoods">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12">
            <ion-searchbar
              class="search-bar ion-no-padding"
              formControlName="productNo"
              debounce="2000"
              #searchInput
              (ionChange)="searchProducts($event)"
              placeholder="{{ 'StockGoods.searchProduct' | translate }}"
            ></ion-searchbar>
            <ion-text color="danger">
              <div class="m-t-5">
                <p
                  class="ion-padding-start ion-no-margin"
                  *ngIf="stockGoodsForm.controls.productNo.errors?.required && !stockGoodsForm.controls.productNo.pristine"
                >
                  {{'StockGoods.required' | translate }}
                </p>
                <p
                  class="ion-padding-start ion-no-margin"
                  *ngIf="searchProduct.length === 0 && stockGoodsForm.controls['productNo'].value.length > 3 && !product"
                >
                  {{'StockGoods.noProductsFound' | translate }}
                </p>
              </div>
            </ion-text>
            <div class="product-item">
              <ion-item
                *ngFor="let product of searchProduct; let i = index"
                [ngClass]="(i % 2 == 0) ? 'item-odd' : 'item-even'"
                (click)="selectProduct(product)"
                lines="none"
              >
                <ion-badge class="ion-badge-large">
                  <!--ion-icon name="cube-outline" class="item-icon"></ion-icon-->
                  <strong>{{product.CurrentStockBalance}}</strong>
                </ion-badge>
                &nbsp;
                <span
                  ><strong>{{product.Description}}</strong>
                  <div
                    class="item-info"
                    *ngIf="showInfo(product.CurrentStockInfo)==true"
                  >
                    <br /><em>{{product.CurrentStockInfo}} </em>
                  </div>
                </span>
              </ion-item>
            </div>
          </ion-col>
        </ion-row>

        <ion-radio-group
          [(ngModel)]="transactionType"
          [ngModelOptions]="{standalone: true}"
          [hidden]="isHidden"
          (ngModelChange)="changeTransactionType()"
        >
          <ion-row>
            <ion-col size-xs="12" size-md="2">
              <ion-item>
                <ion-label>{{ 'StockGoods.incoming' | translate }} </ion-label>
                <ion-radio [value]="stockTransactiontype.Incoming"></ion-radio>
              </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="2">
              <ion-item>
                <ion-label>{{ 'StockGoods.outgoing' | translate }} </ion-label>
                <ion-radio [value]="stockTransactiontype.Outgoing"></ion-radio>
              </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="3">
              <ion-item>
                <ion-label
                  >{{ 'StockGoods.outgoinginternal' | translate }}
                </ion-label>
                <ion-radio
                  [value]="stockTransactiontype.OutgoingInternal"
                ></ion-radio>
              </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="3">
              <ion-item>
                <ion-label>{{ 'StockGoods.transfer' | translate }} </ion-label>
                <ion-radio [value]="stockTransactiontype.Transfer"></ion-radio>
              </ion-item>
            </ion-col>
            <ion-col size-xs="12" size-md="2">
              <ion-item>
                <ion-label>{{ 'StockGoods.inventory' | translate }} </ion-label>
                <ion-radio [value]="stockTransactiontype.Inventory"></ion-radio>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-radio-group>
        <ion-row class="selectionPane" [hidden]="isHidden">
          <ion-col size-xs="12" size-sm="3">
            <ion-icon
              name="cube-outline"
              class="inpIcon"
              [hidden]="isHidden"
            ></ion-icon>
            <ion-select
              class="inpSelect"
              formControlName="stockNo"
              (ionChange)="stockChange($event)"
              placeholder="{{ 'StockGoods.fromStock' | translate }}"
              [hidden]="isHidden"
            >
              <ion-select-option value=""
                >{{ 'StockGoods.fromStock' | translate }}</ion-select-option
              >
              <ion-select-option
                *ngFor="let stock of stocks"
                [value]="stock.StockNo"
                >{{stock.Name}}
              </ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size-xs="12" size-sm="3">
            <ng-container
              *ngIf="transactionType==stockTransactiontype.Transfer && !isHidden"
            >
              <ion-icon name="cube-outline" class="inpIcon"></ion-icon>
              <ion-select
                class="inpSelect"
                formControlName="stockToNo"
                placeholder="{{ 'StockGoods.toStock' | translate }}"
                [hidden]="isHidden"
              >
                <ion-select-option value=""
                  >{{ 'StockGoods.toStock' | translate }}</ion-select-option
                >
                <ion-select-option
                  *ngFor="let stock of stocks"
                  [value]="stock.StockNo"
                  >{{stock.Name}}
                </ion-select-option>
              </ion-select>
            </ng-container>
          </ion-col>
          <ion-col
            size-xs="12"
            size-sm="3"
            *ngIf="transactionType==stockTransactiontype.Incoming && !isHidden"
          >
            <ng-container>
              <ionic-selectable
                class="inpSelect"
                formControlName="PONumber"
                (onChange)="poChange($event)"
                [items]="poOrders"
                placeholder="{{ 'StockGoods.PO' | translate }}"
                itemValueField="OrderNo"
                itemTextField="InternalInfo"
                [canSearch]="true"
                [canClear]="true"
                searchPlaceholder="{{'StockGoods.typeToSearch' | translate }}"
                clearButtonText="{{'StockGoods.clear' | translate }}"
              >
                <ng-template ionicSelectableCloseButtonTemplate>
                  <ion-icon
                    name="close-circle-outline"
                    style="font-size: 24px"
                  ></ion-icon>
                  &nbsp;{{ 'StockGoods.cancel' | translate }}
                </ng-template>
              </ionic-selectable>
            </ng-container>
          </ion-col>
          <ion-col size-xs="12" size-sm="4">
            <ng-container
              *ngIf="transactionType==stockTransactiontype.Outgoing && !isHidden"
            >
              <ion-searchbar
                class="search-bar ion-no-padding"
                formControlName="customerNo"
                debounce="2000"
                (ionInput)="searchCustomers($event)"
                placeholder="{{ 'StockGoods.searchCustomer' | translate }}"
              ></ion-searchbar>
              <ion-text color="danger">
                <div class="m-t-5">
                  <p
                    class="ion-padding-start ion-no-margin"
                    *ngIf="!selectedCustomer && stockGoodsForm.controls['customerNo'].value.length > 3 && customers.length === 0"
                  >
                    {{'StockGoods.noCustomersFound' | translate }}
                  </p>
                </div>
              </ion-text>
              <div class="product-item">
                <ion-item
                  *ngFor="let customer of customers"
                  (click)="selectCustomer(customer)"
                  lines="none"
                >
                  <span>{{customer.Name}}</span>
                </ion-item>
              </div>
            </ng-container>
          </ion-col>
          <ion-col size-xs="12" size-md="2">
            <ng-container
              *ngIf="transactionType==stockTransactiontype.Incoming && isHidden"
            >
              <ionic-selectable
                class="inpSelect"
                formControlName="PONumber"
                [items]="poOrders"
                placeholder="{{ 'StockGoods.PO' | translate }}"
                itemValueField="OrderNo"
                itemTextField="OrderNo"
                [canSearch]="true"
                [canClear]="true"
                searchPlaceholder="{{'StockGoods.typeToSearch' | translate }}"
                clearButtonText="{{'StockGoods.clear' | translate }}"
              >
                <ng-template ionicSelectableCloseButtonTemplate>
                  <ion-icon
                    name="close-circle-outline"
                    style="font-size: 24px"
                  ></ion-icon>
                  &nbsp;{{ 'StockGoods.cancel' | translate }}
                </ng-template>
              </ionic-selectable>

              <ion-text color="danger">
                <div class="m-t-5">
                  <p
                    class="ion-padding-start ion-no-margin"
                    *ngIf="stockGoodsForm.controls.PONumber.errors?.required && !stockGoodsForm.controls.PONumber.pristine"
                  >
                    {{'StockGoods.required' | translate }}
                  </p>
                </div>
              </ion-text>
            </ng-container>
          </ion-col>
        </ion-row>

        <ion-row
          class="ion-margin-top ion-margin-bottom inpOptions"
          *ngIf="transactionType==stockTransactiontype.OutgoingInternal && !isHidden"
        >
          <ion-col size="12" class="text-xs-center m-t-sm">
            <span class="inpTitle">
              {{'StockGoods.internalInfo' | translate }}
            </span>
          </ion-col>
          <ion-col size-xs="12" size-md="3">
            <ionic-selectable
              class="inpSelect"
              formControlName="trailersAndTruck"
              [items]="trailersAndTrucks"
              placeholder="{{ 'StockGoods.truckAndTrailer' | translate }}"
              itemValueField="ID"
              itemTextField="Description"
              [canSearch]="true"
              [canClear]="true"
              searchPlaceholder="{{'StockGoods.typeToSearch' | translate }}"
              clearButtonText="{{'StockGoods.clear' | translate }}"
              closeButtonText="X"
            >
              <ng-template ionicSelectableCloseButtonTemplate>
                <ion-icon
                  name="close-circle-outline"
                  style="font-size: 24px"
                ></ion-icon>
                &nbsp;{{ 'StockGoods.cancel' | translate }}
              </ng-template>
            </ionic-selectable>
            <ion-text color="danger">
              <div class="m-t-5">
                <p
                  class="ion-padding-start ion-no-margin"
                  *ngIf="stockGoodsForm.controls.trailersAndTruck.errors?.required && !stockGoodsForm.controls.trailersAndTruck.pristine"
                >
                  {{'StockGoods.required' | translate }}
                </p>
              </div>
            </ion-text>
          </ion-col>
          <ion-col size-xs="12" size-md="3">
            <ionic-selectable
              class="inpSelect"
              formControlName="tank"
              [items]="tanks"
              placeholder="{{ 'StockGoods.tank' | translate }}"
              itemValueField="TankID"
              itemTextField="Name"
              [canSearch]="true"
              [canClear]="true"
              searchPlaceholder="{{'StockGoods.typeToSearch' | translate }}"
              clearButtonText="{{'StockGoods.clear' | translate }}"
            >
              <ng-template ionicSelectableCloseButtonTemplate>
                <ion-icon
                  name="close-circle-outline"
                  style="font-size: 24px"
                ></ion-icon>
                &nbsp;{{ 'StockGoods.cancel' | translate }}
              </ng-template>
            </ionic-selectable>
            <ion-text color="danger">
              <div class="m-t-5">
                <p
                  class="ion-padding-start ion-no-margin"
                  *ngIf="stockGoodsForm.controls.tank.errors?.required && !stockGoodsForm.controls.tank.pristine"
                >
                  {{'StockGoods.required' | translate }}
                </p>
              </div>
            </ion-text>
          </ion-col>
          <ion-col size-xs="12" size-md="3">
            <input
              type="text"
              formControlName="other"
              class="m-t-sm"
              placeholder="{{ 'StockGoods.other' | translate }}"
            />
            <ion-text color="danger">
              <div class="m-t-5">
                <p
                  class="ion-padding-start ion-no-margin"
                  *ngIf="stockGoodsForm.controls.other.errors?.required && !stockGoodsForm.controls.other.pristine"
                >
                  {{'StockGoods.required' | translate }}
                </p>
              </div>
            </ion-text>
          </ion-col>
          <ion-col size-xs="12" size-md="3">
            <input
              type="text"
              formControlName="userSign"
              class="m-t-sm"
              placeholder="{{ 'StockGoods.userSign' | translate }}"
              [required]="transactionType===stockTransactiontype.OutgoingInternal"
            />
            <ion-text color="danger">
              <div class="m-t-5">
                <p
                  class="ion-padding-start ion-no-margin"
                  *ngIf="stockGoodsForm.controls.userSign.errors?.required && !stockGoodsForm.controls.userSign.pristine"
                >
                  {{'StockGoods.required' | translate }}
                </p>
              </div>
            </ion-text>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size-xs="12" *ngIf="selectedProducts.length > 0">
            <div class="overflow-x">
              <div class="product-grid">
                <ion-row class="grid-header hidden-xs">
                  <ion-col [attr.size-xs]="isHidden==true?6:2">
                    {{ 'StockGoods.product' | translate }}</ion-col
                  >
                  <ion-col size-xs="2" [hidden]="isHidden"
                    >{{ 'StockGoods.costPrice' | translate }}
                  </ion-col>
                  <ion-col size-xs="2" [hidden]="isHidden"
                    >{{ 'StockGoods.price' | translate }}
                  </ion-col>
                  <ion-col [attr.size-xs]="isHidden==true?5:3"
                    >{{ 'StockGoods.quantity' | translate }}
                  </ion-col>
                  <ion-col size-xs="2" [hidden]="isHidden"
                    >{{ 'StockGoods.comment' | translate }}
                  </ion-col>
                  <ion-col size-xs="1"></ion-col>
                </ion-row>
                <ion-row
                  *ngFor="let product of selectedProducts; let i = index"
                  [ngClass]="(i % 2 == 0) ? 'even' : 'odd'"
                  formGroupName="products"
                >
                  <ion-col size-xs="12" [attr.size-md]="isHidden==true?6:2">
                    <div class="flex-parent">
                      <ion-badge>{{product.CurrentStockBalance}} </ion-badge
                      >&nbsp;
                      <span
                        class="truncate-text"
                        title="{{product.Description}}({{product.CurrentStockInfo}})"
                        ><strong>{{product.Description}}</strong>
                      </span>
                    </div>
                    <div class="ItemInfo">
                      {{product.ProductNo}}<em
                        *ngIf="showInfo(product.CurrentStockInfo)"
                        class="truncate-text"
                        >&nbsp; - &nbsp; {{product.CurrentStockInfo}}</em
                      >
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-md="2" [hidden]="isHidden">
                    <input
                      type="number"
                      remove-zero-on-focus
                      class="bigger-font ion-no-margin input-height border-input litte-big-font-2"
                      (keyup)="setValue(product, 'costPrice')"
                      (click)="setValue(product, 'costPrice')"
                      formControlName="{{'costPrice' + product.identity}}"
                      ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                      step="any"
                    />
                    <ng-container
                      *ngIf="!stockGoodsForm.controls.products.get('costPrice'+ product.identity).valid && (stockGoodsForm.controls.products.get('costPrice'+ product.identity).dirty || submitting)"
                    >
                      <ion-text color="danger">
                        <p
                          *ngIf="stockGoodsForm.controls.products.get('costPrice'+ product.identity).errors.required"
                          class="ion-no-margin"
                        >
                          {{'StockGoods.required' | translate }}
                        </p>
                        <p
                          *ngIf="stockGoodsForm.controls.products.get('costPrice'+ product.identity).errors.pattern"
                          class="ion-no-margin"
                        >
                          {{'StockGoods.onlyNumbers' | translate }}
                        </p>
                      </ion-text>
                    </ng-container>
                  </ion-col>
                  <ion-col size-xs="12" size-md="2" [hidden]="isHidden">
                    <input
                      type="number"
                      remove-zero-on-focus
                      class="bigger-font ion-no-margin input-height border-input litte-big-font-2"
                      (keyup)="setValue(product, 'price')"
                      (click)="setValue(product, 'price')"
                      formControlName="{{'price' + product.identity}}"
                      ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                      step="any"
                    />
                    <ng-container
                      *ngIf="!stockGoodsForm.controls.products.get('price'+ product.identity).valid && (stockGoodsForm.controls.products.get('price'+ product.identity).dirty || submitting)"
                    >
                      <ion-text color="danger">
                        <p
                          *ngIf="stockGoodsForm.controls.products.get('price'+ product.identity).errors.required"
                          class="ion-no-margin"
                        >
                          {{'StockGoods.required' | translate }}
                        </p>
                        <p
                          *ngIf="stockGoodsForm.controls.products.get('price'+ product.identity).errors.pattern"
                          class="ion-no-margin"
                        >
                          {{'StockGoods.onlyNumbers' | translate }}
                        </p>
                      </ion-text>
                    </ng-container>
                  </ion-col>
                  <ion-col
                    [attr.size-xs]="isHidden==true?10:12"
                    [attr.size-md]="isHidden==true?5:3"
                  >
                    <input
                      type="number"
                      remove-zero-on-focus
                      class="bigger-font ion-no-margin input-height border-input litte-big-font-2 input-qty"
                      (keyup)="setValue(product, 'quantity')"
                      (click)="setValue(product, 'quantity')"
                      formControlName="{{'quantity' + product.identity}}"
                      ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                      step="any"
                    /><span style="min-width: 35px"
                      >&nbsp;{{ product.Unit }}&nbsp;</span
                    >
                    <ion-button
                      class="no-padding tempButton"
                      (click)="setQty(product, product.StockFactor)"
                    >
                      + {{product.StockFactor}}
                    </ion-button>
                    <ion-button
                      class="no-padding tempButton"
                      (click)="setQty(product, product.StockFactor*-1)"
                    >
                      - {{product.StockFactor}}
                    </ion-button>
                    <ng-container
                      *ngIf="!stockGoodsForm.controls.products.get('quantity'+ product.identity).valid && (stockGoodsForm.controls.products.get('quantity'+ product.identity).dirty || submitting)"
                    >
                      <ion-text color="danger">
                        <p
                          *ngIf="stockGoodsForm.controls.products.get('quantity'+ product.identity).errors.required"
                          class="ion-no-margin"
                        >
                          {{'StockGoods.required' | translate }}
                        </p>
                        <p
                          *ngIf="stockGoodsForm.controls.products.get('quantity'+ product.identity).errors.pattern"
                          class="ion-no-margin"
                        >
                          {{'StockGoods.onlyNumbers' | translate }}
                        </p>
                      </ion-text>
                    </ng-container>
                  </ion-col>
                  <ion-col size-xs="10" size-md="2" [hidden]="isHidden">
                    <input
                      type="text"
                      class="litte-big-font-2 bigger-font ion-no-margin input-height"
                      (keyup)="setValue(product, 'comment')"
                      (click)="setValue(product, 'comment')"
                      formControlName="{{'comment' + product.identity}}"
                    />
                  </ion-col>
                  <ion-col
                    size-xs="2"
                    size-md="1"
                    class="ion-no-padding ion-text-center"
                  >
                    <ion-icon
                      name="trash-outline"
                      color="primary"
                      class="icon-size cursor-pointer ion-margin-top"
                      (click)="showDeleteProductConfirm(product)"
                    ></ion-icon>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-xs-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        [disabled]="!stockGoodsForm.valid || submitting || selectedProducts.length === 0"
        (click)="showConfirm()"
      >
        <ion-icon name="cube"></ion-icon>&nbsp;{{ 'General.save' | translate}}
      </ion-button>
    </div>
    <div>
      <ion-button
        fill="outline"
        [hidden]="isHidden"
        class="no-padding"
        (click)="showhide()"
        size="default"
      >
        <ion-icon name="caret-up-circle-outline"></ion-icon>
        &nbsp;
        <span class="black">{{ 'StockGoods.HideDetailView' | translate}}</span>
      </ion-button>
      <ion-button
        fill="outline"
        [hidden]="!isHidden"
        size="default"
        class="no-padding"
        (click)="showhide()"
      >
        <ion-icon name="caret-down-circle-outline"></ion-icon>
        &nbsp;
        <span class="black">{{ 'StockGoods.ShowDetailView' | translate}}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        [disabled]="!stockGoodsForm.valid || submitting || selectedProducts.length === 0"
        (click)="showConfirm()"
      >
        <ion-icon name="cube"></ion-icon>&nbsp;{{ 'General.save' | translate}}
      </ion-button>
    </div>
  </div>
</ion-footer>
