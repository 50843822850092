import { Component } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Component({
    selector: 'page-tank-monitoring-select',
    templateUrl: 'tank-monitoring-select.html',
    styleUrls: ['tank-monitoring-select.scss']
})
export class TankMonitoringSelectPage {
    selectDatahost = false;
    constructor(private router: Router) {
    }

    navigate(dataHost) {
        this.selectDatahost = dataHost;
        const navigationExtras: NavigationExtras = {
            queryParams: {
                dataHost: dataHost
            },
            skipLocationChange: true
        };
        this.router.navigate(['/tank-monitoring'], navigationExtras);
    }
}
