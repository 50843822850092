<ion-header>
  <ion-toolbar>
    <ion-title>{{ "NewCustomer.searchAddress" | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">{{
        "General.lukk" | translate
      }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item class="input-search">
    <ion-icon name="search-outline"></ion-icon>
    &nbsp;&nbsp;
    <ion-input
      [formControl]="addressCtrl"
      placeholder="{{ 'General.Type2Search' | translate }}"
      #addressComplete
    ></ion-input>
    <ion-button fill="none" (click)="clearSearch()" class="ion-input-button">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-list>
    <ion-item
      *ngFor="let address of filteredAddresses | async"
      (click)="selectAddress(address)"
    >
      <ion-label>
        <strong>{{ address.Name }}</strong
        >&nbsp;
        <ion-badge *ngIf="address.AddressID == 0" class="ion-badge"
          >Google</ion-badge
        >
        <br />
        {{ address.Address1 != address.Name ? address.Address1 : "" }}
        {{ address.Address2 != address.Address1 ? address.Address2 : "" }},
        {{ address.PostalCode }} {{ address.City }}
        <ion-icon *ngIf="address.GPS != ''" name="location-outline"></ion-icon>
        <div
          class="m-t-5"
          *ngIf="
            address.AddressID > 0 &&
            (address.ContactNameOnSite != '' ||
              (address.InternalInfo != '' && address.InternalInfo != ' '))
          "
        >
          <span *ngIf="address.ContactNameOnSite != ''">
            <em>
              <ion-icon name="person-outline"></ion-icon>&nbsp;
              {{ address.ContactNameOnSite }}
            </em>
          </span>
          <span
            *ngIf="address.InternalInfo != '' && address.InternalInfo != ' '"
          >
            <em
              >&nbsp;
              <ion-icon name="information-circle-outline"></ion-icon>&nbsp;
              {{ address.InternalInfo }}
            </em>
          </span>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
