import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, AlertController, IonInput } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomerData } from '../../shared/customer-data';
import { BaseHttpClient } from '../../shared/http-client';
import { AddressData } from '../../shared/address-data';
import { CustomValidators } from '../../shared/validators';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@ionic/storage-angular';
import { Router, NavigationExtras } from '@angular/router';
import { WorkOrderConfirmComponent } from '../../components/work-order-confirm/work-order-confirm';
import _ from 'lodash';
import { Observable, debounceTime, filter, forkJoin, map, of, startWith, switchMap } from 'rxjs';
import { InternalOrdertype } from '../../shared/enums';
import { IonicSelectableComponent } from 'ionic-selectable';
import { HttpClient } from '@angular/common/http';
import { CustomerSearchComponent } from 'src/app/components/customer-search/customer-search/customer-search.component';
import { CustomerAddressSearchComponent } from 'src/app/components/customer-address-search/customer-address-search/customer-address-search.component';

@Component({
    selector: 'page-work-new-order',
    templateUrl: 'work-new-order.html',
    styleUrls: ['work-new-order.scss']
})
export class WorkNewOrderPage implements AfterViewInit {
    dateFormat;
    orderLines: any[] = [];
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    // private customer: string;
    // private address: string;
    customerDataService;
    addressDataService: AddressData;
    private currentLatitude: number = null;
    private currentLongitude: number = null;
    private VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    private VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = false;
    private showAllProducts = false;
    totalOrder: any = 0;
    products: any[];
    @ViewChild('picker')
    private picker: any;
    isHidden: boolean;
    private navigationExtras: NavigationExtras;

    priceAgreements;
    allowDescriptionChange;
    expand = false;
    openModal = false;
    private addressID = 0;
    selectedCustomer: any = null;

    customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;

    addressCtrl = new FormControl();
    filteredAddresses: Observable<any[]>;

    @ViewChild('customerNameInput', { static: false }) customerNameInput: IonInput;
    @ViewChild('addressInput', { static: false }) addressInput: IonInput;
    mapLat: number;
    mapLong: number;
     latLongPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    
    customerModal: boolean = false;

    customerAddressModal: boolean = false;
    isMapVisible: boolean;


 ngAfterViewInit() {
        setTimeout(() => {
            if (this.customerNameInput) {
                this.customerNameInput.setFocus();
            }
        }, 1000);

        if (this.customerNameInput) {
            this.customerNameInput.getInputElement().then(input => {
                input.addEventListener('keyup', (event) => {
                    if (event.key === 'Enter') {
                        
                        this.initCustomerSearch();
                    }
                });
            });
        }

        if (this.addressInput) {
            this.addressInput.getInputElement().then(input => {
                input.addEventListener('keyup', (event) => {
                    if (event.key === 'Enter') {
                        
                        this.initCustomerAddressSearch();
                    }
                });
            });
        }
    }
    

    constructor(
        private storage: Storage, private userService: UserService, public navCtrl: NavController,
        private translateService: TranslateService, private toastController: ToastController,
        private loadingController: LoadingController, private geolocation: Geolocation, private modalCtrl: ModalController,
        private formBuilder: FormBuilder, 
        private http: HttpClient, private router: Router, private alertCtrl: AlertController) {
        this.storage.get(AppSettings.StorageKeys.ShowAllProducts).then(data => this.showAllProducts = data);
        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });
 this.detailsForm = formBuilder.group({
    customerName: ['', Validators.compose([Validators.required])],
    customerAddress1: ['', Validators.compose([Validators.required])],
    customerAddress2: [''],
    customerPostalCode: ['', Validators.compose([Validators.required])],
    priceAgreementID: [''],
    transporterID: [''],  // Added from the second form builder
    customerCity: [''],
    address: [''],
    addressAddress1: [''],
    addressAddress2: [''],
    addressZip: [''],
    addressCity: [''],
    gps: ['', [Validators.pattern(this.latLongPattern)]],  // Updated with the pattern validator
    customerBuyer: [''],
    email: ['', [CustomValidators.emailValidator]],
    DeliveryEmail: ['', [CustomValidators.emailValidator]],  // Added from the second form builder
    phoneOnSite: [''],
    phoneManager: [''],  // Added from the second form builder
    customerReference: [''],
    deliveryInfo: [''],
    internalInfo: [''],
    tankInfo: [''],  // Added from the second form builder
    additionalProductsDelivered: [false],  // Added from the second form builder
    repeatInterval: [''], 
    orderComments: [''],
    prioritet: ['0', Validators.required],
    orderInternalComments: [''],
    deliveryDate: [new Date().toISOString()],
    orderLines: new FormGroup({}),
    organizationNo: [''],
    customerPurchaseOrderNo: [''],
});



        for (let i = 0; i < 10; i++) {
            const orderline: any = {
                LineNo: this.identity,
                Quantity: 0,
                QuantityDelivered: 0,
                UnitPrice: 0,
                UnitCost: 0,
                UnitDiscount: 0,
                OrderLineTotal: 0.00,
                TimesheetQuantity: 0,
                IsTimesheetProduct: false,
                changeProduct: true
            };
            this.dynamicControl(this.detailsForm.controls.orderLines as FormGroup, orderline);
            this.orderLines.push(orderline);
            this.identity--;
        }

        this.storage.get(AppSettings.StorageKeys.AllowDescriptionChange).then(data => {
            this.allowDescriptionChange = data;
        });

        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        // used to pass info to orders view for init an update of orderslist
        this.navigationExtras = {
            queryParams: {
                reloadData: true
            },
            skipLocationChange: true
        };

        const posOptions = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 30000
        };
        const that = this;
      
    
        this.userService.searchProducts('energiteknikk').subscribe(data => {
            this.products = data;
        });
        this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
        this.customerDataService = new CustomerData(http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
        this.addressDataService = new AddressData(http, 0, `${AppSettings.API_ENDPOINT}/User/AddressSearch/?query=`);
    }

      ngOnInit() {
    this.getCurrentPosition();
    this.watchPosition();

     this.filteredCustomers = this.customerCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(name => name ? this._filterCustomers(name) : of([])) // Use switchMap instead of map
            );

        this.filteredAddresses = this.addressCtrl.valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            switchMap(name => name ? this._filterAddresses(name) : this.addressDataService.addressList.slice())
        );

  }

     displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

    private _filterAddresses(value: string): Observable<any[]> {
    return this.addressDataService.search(value);
  }

  
  showCustomerNo()
  {
if(this?.selectedCustomer != null)
        return this.selectedCustomer?.CustomerNo == 0 ? true : false;
else
    return false;
  }





  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    } catch (error) {
      //this.showGeolocationError();
    }
  }

    async gpsSearch() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    await this.getCurrentPosition();
    this.userService.addressGPSSearch(this.selectedCustomer.ErpID, this.currentLatitude, this.currentLongitude).subscribe(data => {
      const addresses = data.map((address: any) => ({
        name: address.Name, // Assuming 'Name' and 'Address' are properties in the data
        address: address.Address // Replace 'Address' with the actual property name if different
      }));

    });
  }

  watchPosition() {
    const watch = Geolocation.watchPosition({}, position => {
      if (position && position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      } else {
        this.showGeolocationError();
      }
    });
  }
  
  async showGeolocationError() {
    const value = await this.translateService.get('Error.locationError').toPromise(); // Assuming 'Error.locationError' is a valid key
    const toast = await this.toastController.create({
      message: value,
      duration: 3000,
    });
    toast.present();

    this.currentLatitude = this.currentLongitude = null;
  }

    getFormControl(field) {
        try {
            return this.detailsForm.get(field);
        } catch (error) {
            console.log("Missing element: " + field);
        }
        
    }

    dynamicControl(formGroup: FormGroup, orderline) {
        orderline.changeProduct = false;
        const quantityDelivered: FormControl = new FormControl(orderline.QuantityDelivered);
        // Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const price: FormControl = new FormControl(orderline.UnitPrice);
        // Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const discount: FormControl = new FormControl(orderline.UnitDiscount);
        // Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const cost: FormControl = new FormControl(orderline.UnitCost);
        // Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const quantity: FormControl = new FormControl(orderline.Quantity);
        //           Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));

        //        if (this.allowDescriptionChange) {
        const txtproductNo: FormControl = new FormControl(orderline.Description); // Validators.required);
        formGroup.addControl('txt-productNo' + orderline.LineNo, txtproductNo);
        //  }
        const productNo: FormControl = new FormControl(orderline.ProductNo); // Validators.required);
        formGroup.addControl('productNo' + orderline.LineNo, productNo);

        if (orderline.IsTimesheetProduct) {
            const timesheetQuantity: FormControl = new FormControl(orderline.TimesheetQuantity);
            formGroup.addControl('hours' + orderline.LineNo, timesheetQuantity);
        }
        formGroup.addControl('quantityDelivered' + orderline.LineNo, quantityDelivered);
        formGroup.addControl('quantity' + orderline.LineNo, quantity);
        formGroup.addControl('price' + orderline.LineNo, price);
        formGroup.addControl('discount' + orderline.LineNo, discount);
        formGroup.addControl('cost' + orderline.LineNo, cost);
    }

    changeElement($event, orderline) {
        if ($event) {
            orderline.changeProduct = false;
        } else {

            orderline.changeProduct = true;
        }
    }

        closeModal(){
    this.openModal = false;
}

    setValue(orderline, fieldName) {
        const control = this.detailsForm.controls.orderLines;
        if (fieldName === 'price') {
            orderline.UnitPrice = control.get('price' + orderline.LineNo).value;
        }
        if (fieldName === 'discount') {
            orderline.UnitDiscount = control.get('discount' + orderline.LineNo).value;
        }
        if (fieldName === 'cost') {
            orderline.UnitCost = control.get('cost' + orderline.LineNo).value;
        }
        if (fieldName === 'quantityDelivered') {
            orderline.QuantityDelivered = control.get('quantityDelivered' + orderline.LineNo).value;
        }
        if (fieldName === 'quantity') {
            orderline.Quantity = control.get('quantity' + orderline.LineNo).value;
        }
        if (fieldName === 'hours') {
            orderline.TimesheetQuantity = control.get('hours' + orderline.LineNo).value;
        }
        if (orderline.QuantityDelivered === 0 && !orderline.IsTimesheetProduct) {
            orderline.TimesheetCode = '';
        }
        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    getTotalOrder() {
        this.totalOrder = 0;
        for (const item of this.orderLines) {
            const total = +item.OrderLineTotal + (+this.totalOrder);
            this.totalOrder = Math.round(total).toFixed(2);
        }
    }

    orderlineTotal(orderline) {
        let lineTotal = 0;
        orderline.OrderLineTotal = 0;
        if (orderline.UnitPrice >= 0) {
            if (orderline.QuantityDelivered != 0) {
                lineTotal = (orderline.UnitPrice * orderline.QuantityDelivered);
            } else {
                lineTotal = (orderline.UnitPrice * orderline.Quantity);
            }
        }
        if (orderline.UnitDiscount > 0) {
            orderline.OrderLineTotal = (lineTotal - ((lineTotal * orderline.UnitDiscount) / 100)).toFixed(2);
        } else {
            orderline.OrderLineTotal = lineTotal.toFixed(2);
        }
    }

    selectProduct(event, orderline) {
        if (event.type === 'change' || event.value) {
            const control = this.detailsForm.controls.orderLines as FormGroup;
            const product = _.find(this.products, { ProductNo: event.target ? event.target.value : event.value.ProductNo });
            if (product) {
                orderline.ProductNo = product.ProductNo;
                orderline.Description = product.Description;
                orderline.ProductShortCode = product.ShortCode;
                orderline.ProductColorCode = product.ColorCode;
                orderline.UnitPrice = product.UnitPrice;
                orderline.UnitCost = product.UnitCost;
                orderline.IsTimesheetProduct = product.IsTimesheetProduct;
                orderline.TimesheetCode = product.TimesheetCode;
                if (orderline.IsTimesheetProduct && control.get('hours' + orderline.LineNo) == null) {
                    const timesheetQuantity: FormControl = new FormControl(orderline.TimesheetQuantity);
                    control.addControl('hours' + orderline.LineNo, timesheetQuantity);
                } else if (!orderline.IsTimesheetProduct && control.get('hours' + orderline.LineNo)) {
                    control.removeControl('hours' + orderline.LineNo);
                }
                if (orderline.QuantityDelivered === 0 && !orderline.IsTimesheetProduct) {
                    orderline.TimesheetCode = '';
                }
                control.get('price' + orderline.LineNo).setValue(orderline.UnitPrice);
                control.get('quantity' + orderline.LineNo).setValue(orderline.Quantity);
                control.get('cost' + orderline.LineNo).setValue(orderline.UnitCost);
                this.orderlineTotal(orderline);
                this.getTotalOrder();
            }
            if (this.allowDescriptionChange) {
                if (control.get('txt-productNo' + orderline.LineNo).value.trim() !== '') {
                    orderline.Description = control.get('txt-productNo' + orderline.LineNo).value;
                }
            }
        } else {
            orderline.changeProduct = false;
        }
    }

    showConfirm(orderline) {
        if (orderline.ProductNo) {
            let modalTitle;
            let modalText;
            let cancel;
            let save;
            this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
            this.translateService.get('OrdreDetails.confirmMessage')
                .subscribe(value => { modalText = value.format(orderline.Description); });
            this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
            this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
            this.alertCtrl.create({
                header: modalTitle,
                message: modalText,
                buttons: [
                    {
                        text: cancel,
                        handler: () => {
                        }
                    },
                    {
                        text: save,
                        handler: () => {
                            this.deleteOrderLine(orderline);
                        }
                    }
                ]
            }).then(confirm => { confirm.present(); });
        } else {
            this.deleteOrderLine(orderline);
        }
    }

    deleteOrderLine(orderline) {
        const index = _.findIndex(this.orderLines, { LineNo: orderline.LineNo });
        if (index > -1) {
            this.orderLines.splice(index, 1);
            const control = this.detailsForm.controls.orderLines as FormGroup;
            if (control.get('hours' + orderline.LineNo)) { control.removeControl('hours' + orderline.LineNo); }
            control.removeControl('quantity' + orderline.LineNo);
            control.removeControl('quantityDelivered' + orderline.LineNo);
            control.removeControl('price' + orderline.LineNo);
            control.removeControl('discount' + orderline.LineNo);
            control.removeControl('cost' + orderline.LineNo);
            control.removeControl('productNo' + orderline.LineNo);
            control.removeControl('txt-productNo' + orderline.LineNo);
            this.getTotalOrder();
        }
    }

  
     invokeEvent(data) {
        this.addressID = 0;
        const place = data;
        if (place.formatted_address !== '' && place.formatted_address !== null || place.name !== '' && place.name !== null) {
            const components: any = {};
            place.address_components.map((value, index) => {
                value.types.map((value2, index2) => {
                    components[value2] = value.long_name;
                    if (value2 === 'country') {
                        components.country_id = value.short_name;
                    }
                    if (value2 === 'administrative_area_level_1') {
                        components.state_code = value.short_name;
                    }
                });
            });
            var address1 = typeof components.route !== 'undefined' ? components.route + ' '  : '' ; 
            address1 += typeof components.street_number !== 'undefined' ? components.street_number + ' '  : '';
            
            if(place.name.trim().toLowerCase() != address1.trim().toLowerCase())
                this.getFormControl('address').setValue(place.name);
            else
                this.getFormControl('address').setValue(this.getFormControl('customerName').value);

            this.getFormControl('addressAddress1').setValue(address1);
            this.getFormControl('addressAddress2')
                .setValue(components.sublocality);
            this.getFormControl('addressZip').setValue(components.postal_code);
            this.getFormControl('addressCity').setValue(components.postal_town !== undefined ? 
               components.postal_town : components.locality);
        }
    }




 addressSelected($event) {
        if ($event != null) {
                        this.customerAddressModal = true;

            const originalObject = $event;
            this.addressID = originalObject.AddressID;
            this.getFormControl('address').setValue(originalObject.Name == originalObject.Address1 ? this.selectedCustomer.Name : originalObject.Name);
            this.getFormControl('addressAddress1').setValue(originalObject.Address1);
            this.getFormControl('addressAddress2').setValue(originalObject.Address2);
            this.getFormControl('addressZip').setValue(originalObject.PostalCode);
            this.getFormControl('addressCity').setValue(originalObject.City);
            this.getFormControl('DeliveryEmail').setValue(originalObject.Email);
            this.getFormControl('internalInfo').setValue(originalObject.InternalInfo);
            this.getFormControl('customerBuyer').setValue(originalObject.ContactNameOnSite);
            this.getFormControl('phoneOnSite').setValue(originalObject.PhoneOnSite);
            this.getFormControl('phoneManager').setValue(originalObject.ProjectManager);
            this.getFormControl('deliveryInfo').setValue(originalObject.DeliveryInfo);
            this.getFormControl('gps').setValue(originalObject.GPS);
            this.splitGpsCoordinates(originalObject.GPS);
                      //  this.customerAddressModal = false;

        }
    }

      customerSelected(customer) {
        {
            try {
                
                       this.customerModal = true;

            if (customer.CustomerBlockedStatus > 0) {
                this.getFormControl('customerName').setErrors({ incorrect: true });
                this.customerModal = false;
            } else {
                if(customer != this.selectedCustomer)
                    {
                        this.clearAddress();
                    }
                this.selectedCustomer = customer;

                //this.addressDataService.addressList = this.selectedCustomer.CustomerAddresses;
                //this.addressDataService.setErpIDParent(customer.ErpID);
                
                this.getFormControl('customerName').setValue(customer.Name);
                this.getFormControl('customerAddress1').setValue(customer.Address.trim() == '' ? customer.Address2 : customer.Address);
                this.getFormControl('customerAddress2').setValue('');
                this.getFormControl('customerPostalCode').setValue(customer.PostalCode);
                this.getFormControl('customerCity').setValue(customer.City);
                this.getFormControl('organizationNo').setValue(customer.OrgNo);
                this.getFormControl('email').setValue(customer.Email);
                this.getFormControl('customerReference').setValue(customer.CustomerReference);

                if(this.selectedCustomer?.AddressList?.length > 0)
                    {
                        this.initCustomerAddressSearch();
                    }
                                    //   this.customerModal = false;

            }
             } catch (error) {
                console.error(error);
            }
          
        }
    }

    async leggTilProdukt() {


        const orderline: any = {
            LineNo: this.identity,
            Quantity: 0,
            QuantityDelivered: 0,
            UnitPrice: 0,
            UnitCost: 0,
            UnitDiscount: 0,
            OrderLineTotal: 0.00,
            TimesheetQuantity: 0,
            IsTimesheetProduct: false,
            changeProduct: true
        };
        this.orderLines.push(orderline);
        const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
        this.dynamicControl(formGroup, orderline);
        this.identity--;



    }


    showhide() {
        this.isHidden = !this.isHidden;
    }

    async  createNewOrder() {
        this.submitAttempt = true;
        if (this.detailsForm.valid) {
            const controls = this.detailsForm.controls;
            const order: any = {};

            if (!this.selectedCustomer) {

                order.CustomerID = 0;
                order.CustomerName = this.getFormControl('customerName').value;
                order.CustomerAddress1 = this.getFormControl('customerAddress1').value;
                order.CustomerAddress2 = this.getFormControl('customerAddress2').value;
                order.CustomerCity = this.getFormControl('customerCity').value;
                order.CustomerPostalCode = this.getFormControl('customerPostalCode').value;

                order.DeliveryName = this.getFormControl('address').value;
                order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                order.DeliveryCity = this.getFormControl('addressCity').value;
                order.DeliveryPostalCode = this.getFormControl('addressZip').value;

            } else {
                order.CustomerID = this.selectedCustomer.CustomerNo;


                if (this.addressID <= 0) {
                    order.DeliveryName = this.getFormControl('address').value;
                    order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                    order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                    order.DeliveryCity = this.getFormControl('addressCity').value;
                    order.DeliveryPostalCode = this.getFormControl('addressZip').value;
                } else {
                    order.DeliveryActorNo = this.addressID;
                }
            }
                        order.CustomerOrgNo = this.getFormControl('organizationNo').value;

            order.RepeatInterval = this.getFormControl('repeatInterval').value;
            order.DeliveryDate = this.getFormControl('deliveryDate').value;
            order.ConfirmedDeliveryDate = order.DeliveryDate;
            order.CustomerBuyer = this.getFormControl('customerBuyer').value;
            order.Email = this.getFormControl('email').value;
            order.PhoneOnSite = this.getFormControl('phoneOnSite').value;
            order.OrderCategory = this.getFormControl('prioritet').value;

            if (this.DoNotSendGPS) { // do not send gps location
                order.Longitude = -1;
                order.Latitude = -1;
            } else {
                order.Latitude = this.currentLatitude;
                order.Longitude = this.currentLongitude;
            }

            // remove blank lines
            let items = this.orderLines.length - 1;
            for (let e = 0; e <= items; e++) {
                if (this.orderLines[e].LineNo < 0 && this.orderLines[e].Quantity === 0 && this.orderLines[e].QuantityDelivered === 0) {
                    this.deleteOrderLine(this.orderLines[e]);
                    items--;
                    e--;
                }
            }

            order.Orderlines = this.orderLines;


            order.OrderComments = this.getFormControl('orderComments').value;
            order.orderInternalComments = this.getFormControl('orderInternalComments').value;
            order.CustomerReference = this.getFormControl('customerReference').value;
            order.PriceAgreementID = this.getFormControl('priceAgreementID').value;
            order.DeliveryInfo = this.getFormControl('deliveryInfo').value;
            this.storage.get(AppSettings.StorageKeys.UserFullName).then(data => { order.Seller = data; });
            this.storage.get(AppSettings.StorageKeys.Depot).then(data => { order.DepotNo = data; });
            order.LoadingTemp = AppSettings.LoadingTemp;
            order.TotalOrder = this.totalOrder;
            order.OrderType = 1;
            order.InternalOrderType = InternalOrdertype.Workorder;

            order.TransporterID = this.getFormControl('transporterID').value;
order.DeliveryEmail = this.getFormControl('DeliveryEmail').value;
order.PhoneManager = this.getFormControl('phoneManager').value;
order.TankInfo = this.getFormControl('tankInfo').value;


            this.storage.get(AppSettings.StorageKeys.CompanyNo).then(data => { order.CompanyNo = data; });
            const modal = await this.modalCtrl.create({
                component: WorkOrderConfirmComponent,
                cssClass: 'order-confirm-sales',
                componentProps: { order }

            });
            modal.present();
            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });
                        loader.present().then(async () => {
                            (await this.userService.createNewOrder(order)).subscribe(resp => {
                                if (resp) {
                                    forkJoin([this.translateService.get('WorkOrders.confirmation'),
                                    this.translateService.get('WorkOrders.documents'),
                                    this.translateService.get('WorkOrders.workOrders')
                                    ]).subscribe(item => {
                                        this.alertCtrl.create({
                                            message: item[0],
                                            buttons: [{
                                                text: item[1],
                                                handler: () => {
                                                    this.navigationExtras = {
                                                        queryParams: {
                                                            orderNo: resp[0].VismaBuisnessID
                                                        },
                                                    };

                                                    this.router.navigate(['/work-orders-documents'], this.navigationExtras);
                                                }
                                            }, {
                                                text: item[2],
                                                handler: () => {
                                                    this.router.navigate(['/work-orders']);
                                                }
                                            }]
                                        }).then(alert => { alert.present(); });
                                    });
                                }
                                loader.dismiss();
                            });
                        });
                    });
                }
            });
        }
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }


    //Ionic-selectable - search multiple fields
    searchProducts(event: {
        component: IonicSelectableComponent,
        text: string
    }) 
    {
    let text = event.text.trim().toLowerCase();

    if(text != '')
    {
        event.component.startSearch();
        event.component.items = this.filterProducts(this.products, text);
        event.component.endSearch();
    }
    else
    {
        event.component.items = this.products;
    }


};

  filterProducts(products: any[], text: string) {
    return products.filter(prod => {
      return prod.ProductNo.toLowerCase().indexOf(text) !== -1 ||
      prod.Description.toLowerCase().indexOf(text) !== -1;
    });
  }

   checkInput(controlName: string) {
    try {
         const control = this.detailsForm.get(controlName);
    const inputValue = control ? control.value : '';
    const ionInput = document.querySelector(`[formControlName="${controlName}"]`);

    if (ionInput) {
      if (inputValue) {
        ionInput.classList.add('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.add('item-has-value');
        }
      } else {
        ionInput.classList.remove('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.remove('item-has-value');
        }
      }
    }
    } catch (error) {
        
    }
   
  }

  checkAllInputs() {
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo'
    ];

   formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        this.checkInput(controlName);
    }
});
  }
 clearAddress() {
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo'
    ];

    formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        control.setValue('');
      }
    });

    this.addressID = 0;
    this.mapLat = 0;
            this.mapLong = 0;

    this.checkAllInputs(); // Ensure labels are updated after clearing values
  }

  
    async initCustomerSearch()
    {
        this.findCustomer(this.getFormControl('customerName').value);
    }
    
    async initCustomerAddressSearch()
    {
        if(this.selectedCustomer != null)
            this.findCustomerAddress(this.getFormControl('address').value);
        else
            {
             this.translateService.get('General.SelectCustomer').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
      }
    }


      async findCustomer(query) {
        
      
        const modal = await this.modalCtrl.create({
            component: CustomerSearchComponent,
            cssClass: 'customer-search.component', componentProps: { query: query }
        });
        this.customerModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
            if (data) {
                
                this.customerSelected(data.data.customer);
               
            }
        });
    }

       async findCustomerAddress(query) {
        
        const modal = await this.modalCtrl.create({
            component: CustomerAddressSearchComponent,
            cssClass: 'customer-address-search.component', componentProps: { customerNo: this.selectedCustomer.CustomerNo, addressList: this.selectedCustomer.AddressList, query: query }
        });
        this.customerAddressModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
          try {
             if (data) {
               if(data != null)
                this.addressSelected(data.data.address);
               
            }
          } catch (error) {
            
          }
           
        });
    }
onGpsInputChange(event: any) {
    const value = event.detail.value; // The new value of the input field
    console.log('GPS Input Changed:', value);

    // Additional processing, such as validation or updating other component properties
    this.splitGpsCoordinates(value);
  }

 splitGpsCoordinates(coordinates: string) {


      if(coordinates.trim() == "")
      {
        this.mapLat = 0.0;
        this.mapLong = 0.0;
      }
      else
{
      try {
        const [latitudeStr, longitudeStr] = coordinates.split(',');

  if (latitudeStr && longitudeStr) {
    const latitude = parseFloat(latitudeStr.trim());
    const longitude = parseFloat(longitudeStr.trim());

    // Validate that parsed values are numbers
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.mapLat = latitude;
            this.mapLong = longitude;
  }else
{this.mapLat = 0.0;
  this.mapLong = 0.0;
}
  }
      } catch (error) {
        this.mapLat = 0;
    this.mapLong = 0;
      }
  
    }
}
  toggleMapVisibility() {
    this.isMapVisible = !this.isMapVisible;  // Toggle visibility
  }
}
