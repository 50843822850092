<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-text color="primary">
        <h6>
          <ion-icon name="checkmark-circle-outline"></ion-icon> {{
          'OrderConfirm.bekreftLevering' | translate }}
        </h6>
      </ion-text>
      <div class="overflow-x">
        <div class="grid-width">
          <ion-row class="grid-header">
            <ion-col size-xs="3"
              >{{ 'OrdreDetails.produkt' | translate }}</ion-col
            >
            <ion-col size-xs="3"
              >{{ 'OrdreDetails.bestilt' | translate }}</ion-col
            >

            <ion-col size-xs="3"
              >{{ 'OrdreDetails.total' | translate }}</ion-col
            >
          </ion-row>
          <ion-row *ngFor="let orderLine of order.Orderlines">
            <ion-col size-xs="3">
              <div class="flex-parent">
                <span class="truncate-text">{{orderLine.Description}}</span>
              </div>
            </ion-col>
            <ion-col size-xs="3">
              <div class="inline-block bestilt">
                {{orderLine.Quantity}} /

                <span class="slight-big-font"
                  ><strong>{{orderLine.QuantityDelivered}}</strong></span
                >
              </div>
            </ion-col>

            <ion-col size-xs="3">{{ orderLine.OrderLineTotal}}</ion-col>
          </ion-row>
          <ion-row class="m-t-5">
            <ion-col size-xs="10" class="bigger-font ion-text-right">
              <ion-text color="black"
                ><b>{{'OrdreDetails.orderTotal' | translate }}</b
                >&nbsp;</ion-text
              >
            </ion-col>
            <ion-col size-xs="2" class="bigger-font">
              <ion-text color="black"><b>{{order.TotalOrder}}</b></ion-text>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-grid>
  </div>
</ion-content>
<ion-footer>
  <ion-buttons class="ion-float-right ion-margin">
    <ion-button
      (click)="dismiss(false)"
      color="grey"
      fill="outline"
      size="small"
    >
      <span>{{ 'General.avbryt' | translate }}</span>
    </ion-button>
    <ion-button (click)="dismiss(true)" fill="solid" size="small">
      <span>{{ 'General.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</ion-footer>
