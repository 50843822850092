import {
    Component, NgZone, ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector,
    ChangeDetectorRef, OnInit, ViewChild
} from '@angular/core';
import { NavController, LoadingController, ToastController, IonModal, isPlatform } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { MapMarkerComponent } from '../../components/map-marker/map-marker';
import { Storage } from '@ionic/storage-angular';
import { DragulaService } from 'ng2-dragula';
import { Map, latLng, tileLayer, marker, icon } from 'leaflet';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Modules, UserType, InternalOrdertype } from '../../shared/enums';
import { AppLauncher } from '@capacitor/app-launcher';

enum ViewType {
    LIST = 1,
    MAP = 2
}

@Component({
    selector: 'page-ordrer',
    templateUrl: 'ordrer.html',
    styleUrls: ['ordrer.scss'],
})
export class OrdrerPage implements OnInit {
      @ViewChild(IonModal) modal: IonModal;

    currentDate: any = new Date();
    private today: any = this.currentDate;
    private yesterday: any;
    private orders: any[];
    filteredOrders: any;
    dateFormat;
    sortProperty = 'OrderSorting';
    sortDirection = 'asc';
    mine = false;
    utildelteOrdrer = false;
    nearMeg = false;
    avgiftsfrie = false;
    showAllProducts = false;
    visKunBulk: boolean;
    visKunDagens: boolean = null;
    showAssignedToTruck: boolean = false;
    prioritet = -1;
    latitude: number = null;
    longitude: number = null;
    nearMegDistance = 10;
    viewType: any = ViewType;
    view: any = this.viewType.LIST;
    leafletOptions: any;
    layers: any = [];
    map: Map;
    userlevel: number = null;
    userType: number = null;
    UserType: any = UserType;
    inventoryList: any[];
    hideNewOrderModule = true;
    companies = [];
    company = '0';
    expand = false;
    private reloadData = false;
    CurrentTruckTrailer: any;
    RouteNos = [1, 2, 3, 4, 5, 6, 7, 8];
    currentRoute = 0;
    currentNote = "";
    UsePlanner = false;
    DisableUserFilter = false;
    userSettings;
    dateSelector = this.currentDate.toISOString();
    openModal = false;
    search = '';

    constructor(
        public navCtrl: NavController, public userService: UserService, public route: ActivatedRoute,
        private translateService: TranslateService, private loadingController: LoadingController, private ngZone: NgZone,
        private geolocation: Geolocation, private toastController: ToastController, private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver, private storage: Storage, private router: Router,
        private applicationRef: ApplicationRef, private dragulaService: DragulaService, private cd: ChangeDetectorRef) {
        this.yesterday = Utils.addDays(this.today, -1);
        const posOptions = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 30000
        };

        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });

       
try {
             dragulaService.createGroup("HANDLES", {
      moves: (el, container, handle) => {
        return handle.className === 'drag-hssandle';
      }
    });
} catch (error) {
    
}

    }

    onDrop(event) {
        var orders:number[] = new Array(event.length);
        var i = 0;
        var itm;
        for (itm of event) {
            orders[i] = itm.OrderNo;
            i++;
        }

        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.SaveOrderSorting(orders).subscribe(data => {
                    loader.dismiss();
                });
            });
        });
    }

    onMapReady(map: Map) {
        this.ngZone.runOutsideAngular(() => {
        map.setView([0, 0], 8);
        this.map = map;
        });
       
    }

    switchView() {
        if (this.view === this.viewType.LIST) {
            this.view = this.viewType.MAP;
            this.map.setView([0, 0], 10);

        } else {
            if (this.map) {
                // this.map.remove();
            }
            this.view = this.viewType.LIST;
        }
    }

    ngOnInit() {

        setTimeout(() => {

        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });
        this.loadData();
this.setupGeolocation();
        try {
              this.dragulaService.createGroup('dragdrop', {
                removeOnSpill: true,
    moves: function (el, container, handle) {
      // Only allow dragging when clicking on the 'drag-handle' element
      return handle.classList.contains('drag-handle');
    }
  });

        } catch (error) {
            
        }
}, 0);


         // Register the drag and drop container

    }

       clearSearch()
    {
        this.search = '';
        this.filterChanged(null);
    } 
async setupGeolocation() {
  const posOptions = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 30000
  };

  // Check if running on a mobile platform (iOS or Android)
  if (isPlatform('hybrid')) {
    // Use Capacitor's Geolocation plugin
    console.log("Using Capacitor's Geolocation plugin");
    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
    } catch (error) {
      this.handleGeolocationError();
      return; // Exit the function if there's an error
    }
  } else {
    console.log("Using web's Geolocation plugin");
    // Fallback to web's HTML5 Geolocation
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    }, (err) => {
      this.handleGeolocationError();
      return; // Exit the function if there's an error
    }, posOptions);
  }

  // Common setup for Leaflet map
  this.leafletOptions = {
    layers: [
     tileLayer('https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png', {
        maxZoom: 18,
        attribution: ' '
      })
    ],
    zoom: 10,
    center: latLng(this.latitude, this.longitude)
  };
}

    

  handleGeolocationError() {
    this.leafletOptions = {
      zoom: 7 // default zoom when geolocation fails
    };
    this.latitude = this.longitude = 0; // Reset coordinates on error
    // Add more error handling logic here, like showing a notification to the user
  }
    ionViewDidEnter() {
        if (this.userService.moduleAccess.getValue() != null) {
            this.userService.moduleAccess.getValue().forEach(m => {
                if (m.Module.toString() === Modules.CreateNewOrder.toString()) {
                    this.hideNewOrderModule = m.HideModule;
                }
            });
        }
        this.userService.getCurrentInventory().subscribe(data => { this.inventoryList = data; });
        const params = this.router.parseUrl(this.router.url);
        this.reloadData = Boolean(params.queryParams.reloadData);
        
        if(params.queryParams.orderno != undefined)
        {
            try{
            const index = this.orders.indexOf(this.orders.find(o=> o.OrderNo == params.queryParams.orderno), 0);
            if (index > -1) {
                this.orders.splice(index, 1);
            }
        }catch(e){};
            
        }
        // check if parent windows have init a data reload
        if (this.reloadData) {
             this.visKunDagensChanged(true);
             this.reloadData = false;
        }
    }

    loadData() {
        this.translateService.get('Loading.loading').subscribe(value => {
            // let loader = this.loadingController.create({ content: value });
            // loader.present().then(() => {

            forkJoin([this.storage.get(AppSettings.StorageKeys.FilterMine),
                this.storage.get(AppSettings.StorageKeys.FilterUtildelteOrdrer), this.storage.get(AppSettings.StorageKeys.FilterNearMeg),
                this.storage.get(AppSettings.StorageKeys.FilterAvgiftsfrie), this.storage.get(AppSettings.StorageKeys.FilterVisKunDagens),
                this.storage.get(AppSettings.StorageKeys.Userlevel),
                this.storage.get(AppSettings.StorageKeys.ShowAllProducts),
                this.storage.get(AppSettings.StorageKeys.FilterVisKunBulk),
                this.storage.get(AppSettings.StorageKeys.UserType), this.userService.getCompanies(),
                this.storage.get(AppSettings.StorageKeys.CompanyNo),
                this.storage.get(AppSettings.StorageKeys.UsePlanner),
                this.storage.get(AppSettings.StorageKeys.DisableUserFilter),
                this.storage.get(AppSettings.StorageKeys.UserSettings)
            ]).subscribe(data => {

                    this.mine = data[0] == null ? false : data[0];
                    this.utildelteOrdrer = data[1] == null ? false : data[1];
                    this.nearMeg = data[2] == null ? false : data[2];
                    this.avgiftsfrie = data[3] == null ? false : data[3];
                    this.visKunDagens = data[4] == null ? true : data[4];
                    this.userlevel = data[5];
                    this.showAllProducts = data[6];
                    this.visKunBulk = data[7];
                    this.userType = data[8];
                    this.companies = data[9];
                    this.company = data[10];
                    this.UsePlanner = data[11] == '1'?true:false;
                    this.DisableUserFilter = data[12] == '1'?true:false;
                    this.userSettings = data[13];
                    
                    if(this.UsePlanner)
                    {
                        this.showAssignedToTruck = this.UsePlanner;
                        this.visKunDagens = this.UsePlanner

                        try {
                            if(this.userSettings.AllowOrderFiltering)
                            {
                                this.DisableUserFilter = false;
                            }
                        } catch (error) {
                            
                        }
                    }
                    
                    this.visKunDagensChanged(null);
                    this.reloadData = false;
                    // loader.dismiss();
                    // this.filterData();

                this.userService.getCurrentTruckAndTrailer().subscribe(data => {
                    this.CurrentTruckTrailer = data;
                });

            });

            // });
        });
    }

    reloadInventory() {
        forkJoin([this.userService.getCurrentInventory()]).subscribe(data => {
            this.inventoryList = data[0] == null ? null : data[0];
            this.cd.markForCheck();
        });
    }

    isOrderMine(order): boolean {
        if (order.AssignedDriver && order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

    plukkOrApne(order) {
        if (order.AssignedDriver === 0) {
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.assignOrderToDriver(order.OrderNo, null).subscribe(data => {
                        order.AssignedDriver = AppSettings.Username;
                        loader.dismiss();
                        this.reloadInventory();
                    });
                });
            });
        } else {
            // navigate
            const getCircularReplacer = () => {
                const seen = new WeakSet();
                return (key, value) => {
                    if (typeof value === 'object' && value !== null) {
                        if (seen.has(value)) {
                            return;
                        }
                        seen.add(value);
                    }
                    return value;
                };
            };

            const navigationExtras: NavigationExtras = {
                queryParams: {
                    order: JSON.stringify(order),
                    approval: false,
                },
                skipLocationChange: true
            };

            if (this.userlevel === 1) {
                this.router.navigate(['/order-details-sales'], navigationExtras);
            } else {
                this.router.navigate(['/order-details'], navigationExtras);
            }
        }
    }

    navigateToCreateOrder() {


         const navigationExtras: NavigationExtras = {
                queryParams: {
                    RouteNo:this.currentRoute,
                    CameFromPlanner: false
                },
                skipLocationChange: true
            };

            
        if (this.userlevel === 1) {
            this.router.navigate(['/new-order'],navigationExtras);
        } else {
            this.router.navigate(['/new-order'],navigationExtras);
        }
    }

    getDateText(order, iDate) {

        let deliveryDate;

        if (iDate === 1) {
            deliveryDate = new Date(order.DeliveryDate);
        } else if (iDate === 2) {
            deliveryDate = new Date(order.ConfirmedDeliveryDate);
        }
        else {
            deliveryDate = new Date(order ?.PlannedDeliveryDate)
        }

        //  if (Utils.isSameDateAs(deliveryDate, this.today)) {
        //    return this.translateService.get('Ordrer.iDag');
        //}/* else if (Utils.isSameDateAs(deliveryDate, this.yesterday)) {
        //    return this.translateService.get('Ordrer.iMorgen');
        //  }*/
        return this.translateService.get('General.dateFormat')
            .pipe(switchMap(format => of(moment(deliveryDate).format(format.toUpperCase()))));
    }

    filterChanged($event) {
        try {
               if ($event.srcElement.id == "truckFilter" && $event.detail.checked == true) {
            this.visKunDagens = true;
            this.storage.set(AppSettings.StorageKeys.FilterVisKunDagens, this.visKunDagens);

            this.visKunDagensChanged($event);
        }
        else if ($event.srcElement.id == "truckFilter" && $event.detail.checked == false) {
            this.visKunDagens = false;
            this.storage.set(AppSettings.StorageKeys.FilterVisKunDagens, this.visKunDagens);
            this.filterData();

        }
        else {
            this.currentNote = "";
            this.currentRoute = 0;
            this.filterData();
        }
        } catch (error) {
                this.currentNote = "";
            this.currentRoute = 0;
            this.filterData();
        }
     
    }

    visKunDagensChanged($event) {
        
        
if(this.orders == undefined || this.orders.length ==0 || $event == true)
{
       this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.storage.get(AppSettings.StorageKeys.Username).then(userName => {
                this.userService.getOrders(null, userName, false, //this.visKunDagens ? this.currentDate : null
                    InternalOrdertype.Bulk, +this.company).subscribe(data => {
                    this.orders = data;
                    this.storage.set(AppSettings.StorageKeys.CompanyNo, this.company);
                    this.filterData();
                    loader.dismiss();
                });
            });
            });
        });
}
    else
    {        this.filterData();
    }

    }

    filterData() {
        this.filteredOrders = _.filter(this.orders, (order) => {
            let value = true;
            if (this.showAllProducts) {
                if (this.visKunBulk) {
                    order.FilteredOrderlines = _.filter(order.Orderlines, (item) => {
                        return item.ProductType === 10;
                    });
                } else {
                    order.FilteredOrderlines = order.Orderlines;
                }
            } else {
                order.FilteredOrderlines = order.Orderlines;
            }

            if (this.mine === true && this.utildelteOrdrer === false) {
                value = value && (order.AssignedDriver === +AppSettings.Username);
            } else if (this.mine === true && this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === +AppSettings.Username || order.AssignedDriver === 0);
            } else if (this.mine === false && this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === 0);
            }

            if (this.showAssignedToTruck === true && this ?.CurrentTruckTrailer != undefined) {
                value = value && (order.AssignedToTruck.toString() === this ?.CurrentTruckTrailer ?.Truck.ID.toString());

                if (this.CurrentTruckTrailer ?.Trailer.ID > 0)
                    value = value && (order.AssignedToTrailer.toString() === this ?.CurrentTruckTrailer ?.Trailer.ID.toString());
            }

            if (this.currentRoute > 0) {
                value = value && (order.RouteNo == this.currentRoute);
            }

            var _company = parseInt(this.company);
            
            if (!isNaN(_company)){
                if(_company>0)
                value = value && (order.CompanyNo == this.company);
            }

            if (this.UsePlanner && this.visKunDagens) {
                value = value && order ?.PlannedDeliveryDate == this.currentDate.toISOString().substring(0, 10) + "T00:00:00";
            }

            if (!this.UsePlanner && this.visKunDagens) {
                value = value && order.DeliveryDate == this.currentDate.toISOString().substring(0, 10) + "T00:00:00";
            }

            if (!value) {
                return value;
            }
            if (this.avgiftsfrie === true) {
                value = value && (order.ExVatOrder === true);
            }

            if (!value) {
                return value;
            }

            if (this.nearMeg === true && this.latitude != null && this.longitude != null) {
                value = value && (Utils.calculateDistance(this.latitude, this.longitude, order.Latitude, order.Longitude)
                    <= this.nearMegDistance);
            }

            if (!value) {
                return value;
            }

            if (this.prioritet > -1) {
                value = value && (order.IsUrgent === (this.prioritet === 1 ? true : false));
            }

               //search
            if(this.search.length >= 3)
            {
                value = value && (order.OrderNo.toString().toLowerCase().includes(this.search.toLowerCase()) 
                    || order.CustomerName.toLowerCase().includes(this.search.toLowerCase())
                    || order.DeliveryAddress1.toLowerCase().includes(this.search.toLowerCase())
                    || order.DeliveryAddress2.toLowerCase().includes(this.search.toLowerCase())
                    || order.DeliveryName.toLowerCase().includes(this.search.toLowerCase())
                    || order.DeliveryInfo.toLowerCase().includes(this.search.toLowerCase())
                    || order.CustomerReference.toLowerCase().includes(this.search.toLowerCase())
                    || order.CustomerPurchaseOrderNo.toLowerCase().includes(this.search.toLowerCase())                    
                    );
            }


            return value;
        });
        // save for furture use

        // this.mine = data[0] == null ? false : data[0];
        // this.utildelteOrdrer = data[1] == null ? false : data[1];
        // this.nearMeg = data[2] == null ? false : data[2];
        // this.avgiftsfrie = data[3] == null ? false : data[3];
        // this.visKunDagens = data[4] == null ? true : data[4];

        this.storage.set(AppSettings.StorageKeys.FilterMine, this.mine);
        this.storage.set(AppSettings.StorageKeys.FilterUtildelteOrdrer, this.utildelteOrdrer);
        this.storage.set(AppSettings.StorageKeys.FilterNearMeg, this.nearMeg);
        this.storage.set(AppSettings.StorageKeys.FilterAvgiftsfrie, this.avgiftsfrie);
        this.storage.set(AppSettings.StorageKeys.FilterVisKunDagens, this.visKunDagens);
        this.storage.set(AppSettings.StorageKeys.FilterVisKunBulk, this.visKunBulk);

        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);

        // update map view
        this.layers = [];
        // this.layers.push(L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: ' ' }));
        // if(this.view === this.viewType.MAP)
        //  {
        let orderItem;
        for (orderItem of this.filteredOrders) {

            // dynamically create map markers
            const compFactory = this.componentFactoryResolver.resolveComponentFactory(MapMarkerComponent);
            const compRef: ComponentRef<MapMarkerComponent> = compFactory.create(this.injector);
            compRef.instance.order = orderItem;
            const attachView = 'attachView';
            const detachView = 'detachView';
            this.applicationRef[attachView](compRef.hostView);
            compRef.onDestroy(() => {
                this.applicationRef[detachView](compRef.hostView);
            });

            this.layers.push(marker([orderItem.Latitude, orderItem.Longitude], {
                icon: icon({
                    iconSize: [30, 41],
                    iconAnchor: [13, 0],
                    iconUrl: 'assets/icon/marker-icon.png',
                    shadowUrl: 'assets/icon/marker-shadow.png'
                })
            }).bindPopup(compRef.location.nativeElement));
        }
        // }
    }

    sort(property) {
        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }
        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.loadData();
        this.getRouteData();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.loadData();
        this.getRouteData();
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    ShowCompanyName(companyno) {

        try {
            var nm = "";

            this.companies.forEach(x => {
                if (x.CompanyNo == companyno) {
                    nm = x.Name;

                }
            });
            return nm;
        } catch (error) {
            return "";
        }
    }

    companyFilter()
    {
        this.visKunDagensChanged(true);
    }

    selectRoute($event) {
        this.currentRoute = $event.detail.value;
        this.getRouteData();
        this.filterData();
    }

    clearRoute() {
        this.currentRoute = 0;
        this.currentNote = "";
        this.getRouteData();
        this.filterData();
    }


    getRouteData() {
        if (this.UsePlanner) {
            let _item = { Pk: 0, PlannedDate: this.currentDate.toISOString().substring(0, 10) + "T00:00:00", OrderNo: 0, LineNo: 0, RoomID: 0, AssignedVolume: 0, ProductNo: "", RouteID: 0, RouteNo: this.currentRoute, TruckID: this.CurrentTruckTrailer.Truck.ID, TrailerID: (this.CurrentTruckTrailer.Trailer != undefined) ? this.CurrentTruckTrailer.Trailer.ID : 0 };

            this.userService.getAssignedRoute(_item).subscribe(res => {
                this.currentNote = (typeof res.Notes != undefined && res.Notes) ? res.Notes : "";
            });

        }
    }

    onDrag() {
  console.log('Dragging:', event.target);
}
   async loadDrivingInstructions(order) {
    let destinationUrl;
    if (order.Latitude !== 0 && order.Longitude !== 0) {
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${order.Latitude},${order.Longitude}`;
    } else {
        // Handle the case where no coordinates are available, use address
        const address = encodeURIComponent(`${order.DeliveryAddress1} ${order.DeliveryPostalCode} ${order.DeliveryCity}`);
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${address}`;
    }

    try {
        await AppLauncher.openUrl({ url: destinationUrl });
    } catch (error) {
        console.error('Error launching maps:', error);
        this.translateService.get('Error.navigatorError').subscribe(async value => {
            const toast = await this.toastController.create({
                message: value + error,
                duration: 3000
            });
            toast.present();
        });
    }
}


       dateChangeEvent(event) {
      
       
            this.currentDate = new Date(event.detail.value);
            this.loadData();
            
        
    }
    closeModal(){
    this.openModal = false;
}

}
