<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Checklist.title' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content monthly-control" style="margin-top: 65px">
    <ion-row>
      <ion-col size-xs="0" size-sm="6" size-md="8"></ion-col>
      <ion-col size-xs="12" size-sm="6" size-md="4">
        <table class="dates-table">
          <tbody>
            <tr>
              <td>
                <strong
                  >{{ 'Checklist.fireExtinguisher' | translate }}:
                  <span class="date-picker">
                    <ion-modal trigger="fire" [cssClass]="'center'">
                      <ng-template>
                        <ion-datetime
                          #approvalPicker
                          [(ngModel)]="fireExtinguisher"
                          [disabled]="disabledControl"
                          presentation="date"
                          size="cover"
                          [showDefaultButtons]="true"
                          doneText="{{ 'buttons.ok' | translate }}"
                          cancelText="{{ 'buttons.cancel' | translate }}"
                          first-day-of-week="1"
                          class="dateText"
                        >
                        </ion-datetime>
                      </ng-template>
                    </ion-modal>
                    &nbsp;&nbsp;
                    <ion-icon
                      name="calendar-outline"
                      id="fire"
                      ion-text
                      color="primary"
                      class="litte-big-font vertical-middle"
                    ></ion-icon> </span
                ></strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  {{ 'Checklist.aDRGyldig' | translate }}:
                  <span class="date-picker">
                    <ion-modal trigger="approval" [cssClass]="'center'">
                      <ng-template>
                        <ion-datetime
                          #approvalPicker
                          [(ngModel)]="approvalDate"
                          [disabled]="disabledControl"
                          presentation="date"
                          size="cover"
                          [showDefaultButtons]="true"
                          doneText="{{ 'buttons.ok' | translate }}"
                          cancelText="{{ 'buttons.cancel' | translate }}"
                          first-day-of-week="1"
                          class="dateText"
                        >
                        </ion-datetime>
                      </ng-template> </ion-modal
                    >&nbsp;&nbsp;
                    <ion-icon
                      name="calendar-outline"
                      ion-text
                      color="primary"
                      class="litte-big-font vertical-middle"
                      id="approval"
                    ></ion-icon></span
                ></strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  {{ 'Checklist.nextPressureTest' | translate }}:
                  <span class="date-picker">
                    <ion-modal trigger="nextdate" [cssClass]="'center'">
                      <ng-template>
                        <ion-datetime
                          #approvalPicker
                          [(ngModel)]="nextDate"
                          [disabled]="disabledControl"
                          presentation="date"
                          size="cover"
                          [showDefaultButtons]="true"
                          doneText="{{ 'buttons.ok' | translate }}"
                          cancelText="{{ 'buttons.cancel' | translate }}"
                          class="dateText"
                        >
                        </ion-datetime>
                      </ng-template> </ion-modal
                    >&nbsp;&nbsp;
                    <ion-icon
                      name="calendar-outline"
                      ion-text
                      color="primary"
                      class="litte-big-font vertical-middle"
                      id="nextdate"
                    ></ion-icon></span
                ></strong>
              </td>
            </tr>
          </tbody>
        </table>
      </ion-col>
    </ion-row>
    <form [formGroup]="checklistForm">
      <ion-row>
        <ion-col>
          <div class="overflow-x">
            <table class="details-table">
              <tbody>
                <tr>
                  <td colspan="3">
                    <div class="pull-left">
                      <span class="label"
                        ><strong
                          >{{ 'Checklist.ownerName' | translate }}:</strong
                        ></span
                      >&nbsp;
                      <input
                        type="text"
                        formControlName="ownerName"
                        class="details-input"
                      />
                      <div
                        class="item-validation ion-no-padding"
                        *ngIf="!checklistForm.controls.ownerName.valid && (checklistForm.controls.ownerName.dirty || submitAttempt)"
                      >
                        <ion-text color="danger">
                          <p>
                            {{ 'Checklist.ownerDetailsRequired' | translate }}
                          </p>
                        </ion-text>
                      </div>
                      <br />
                      <span class="label"
                        ><strong
                          >{{ 'Checklist.ownerAddress' | translate }}:</strong
                        ></span
                      >&nbsp;
                      <input
                        type="text"
                        formControlName="ownerAddress"
                        class="details-input"
                      />
                      <div
                        class="item-validation ion-no-padding"
                        *ngIf="!checklistForm.controls.ownerAddress.valid && (checklistForm.controls.ownerAddress.dirty || submitAttempt)"
                      >
                        <ion-text color="danger">
                          <p>{{ 'Checklist.required' | translate }}</p>
                        </ion-text>
                      </div>
                    </div>
                    <div class="pull-right">
                      <span class="label"
                        ><strong
                          >{{ 'Checklist.signatureDriver' | translate}}:</strong
                        ></span
                      >
                      <input
                        type="text"
                        formControlName="signatureDriver"
                        class="details-input"
                      />
                      <div
                        class="item-validation ion-no-padding"
                        *ngIf="!checklistForm.controls.signatureDriver.valid && (checklistForm.controls.signatureDriver.dirty || submitAttempt)"
                      >
                        <ion-text color="danger">
                          <p>
                            {{ 'Checklist.signatureDriverRequired' | translate
                            }}
                          </p>
                        </ion-text>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.controlMonths' | translate }}:</div>
                    <input type="text" formControlName="controlMonths" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.controlMonths.valid && (checklistForm.controls.controlMonths.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="pull-left w-50">
                      <strong> {{ 'Checklist.regNo' | translate }}: </strong>
                    </div>
                    <div class="pull-right w-50 ion-text-right">
                      <input type="text" formControlName="regNo" />
                      <div
                        class="item-validation ion-no-padding margin-boottom-4 ion-text-left"
                        *ngIf="!checklistForm.controls.regNo.valid && (checklistForm.controls.regNo.dirty || submitAttempt)"
                      >
                        <ion-text color="danger">
                          <p>{{ 'Checklist.required' | translate }}</p>
                        </ion-text>
                      </div>
                    </div>

                    <div class="clear"></div>

                    <div class="pull-left w-50">
                      {{ 'Checklist.regNr' | translate }}:
                    </div>
                    <div class="pull-right w-50 ion-text-right">
                      <input type="text" formControlName="regNr" />
                    </div>

                    <div class="clear"></div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.modelYearCar' | translate }}:</div>
                    <input type="text" formControlName="modelYearCar" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.modelYearCar.valid && (checklistForm.controls.modelYearCar.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.modelYearTrailer' | translate }}:</div>
                    <input type="text" formControlName="modelYearTrailer" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.modelYearTrailer.valid && (checklistForm.controls.modelYearTrailer.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.internetNo' | translate }}:</div>
                    <input type="text" formControlName="internetNo" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.internetNo.valid && (checklistForm.controls.internetNo.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <div class="check-box">
                      <ion-label
                        >{{ 'Checklist.tankbil' | translate }}</ion-label
                      >
                      <ion-checkbox formControlName="tankbil"></ion-checkbox>
                    </div>
                    <div class="check-box">
                      <ion-label
                        >{{ 'Checklist.supporter' | translate }}</ion-label
                      >
                      <ion-checkbox formControlName="supporter"></ion-checkbox>
                    </div>
                    <div class="check-box">
                      <ion-label
                        >{{ 'Checklist.semiTrailer' | translate }}</ion-label
                      >
                      <ion-checkbox
                        formControlName="semiTrailer"
                      ></ion-checkbox>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.brandCar' | translate }}:</div>
                    <input type="text" formControlName="brandCar" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.brandCar.valid && (checklistForm.controls.brandCar.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.tankMakeCar' | translate }}:</div>
                    <input type="text" formControlName="tankMakeCar" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.tankMakeCar.valid && (checklistForm.controls.tankMakeCar.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.tankVolume' | translate }}:</div>
                    <input
                      type="number"
                      formControlName="tankVolume"
                    />&nbsp;<strong>m³</strong>
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.tankVolume.valid && (checklistForm.controls.tankVolume.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div>{{ 'Checklist.tankManufacturer' | translate }}:</div>
                    <input type="text" formControlName="tankManufacturer" />
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.tankManufacturer.valid && (checklistForm.controls.tankManufacturer.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                  <td>
                    <div>{{ 'Checklist.tankVolumeHenger' | translate }}:</div>
                    <input
                      type="number"
                      formControlName="tankVolumeHenger"
                    />&nbsp;<strong>m³</strong>
                    <div
                      class="item-validation ion-no-padding"
                      *ngIf="!checklistForm.controls.tankVolumeHenger.valid && (checklistForm.controls.tankVolumeHenger.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p>{{ 'Checklist.required' | translate }}</p>
                      </ion-text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <span>{{ 'Checklist.codes' | translate }}:</span>
                    <span class="m-l-40"
                      >{{ 'Checklist.ok' | translate }}: &nbsp;<ion-icon
                        name="checkmark-outline"
                      ></ion-icon
                    ></span>
                    <span class="m-l-40"
                      >{{ 'Checklist.repair' | translate }}: &nbsp;R</span
                    >
                    <span class="m-l-40"
                      >{{ 'Checklist.doubt' | translate }}: &nbsp;?</span
                    >
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="padding-0">
                    <table>
                      <tr>
                        <td class="w-180">
                          <strong
                            >{{ 'Checklist.checkPoint' | translate }}</strong
                          >
                        </td>
                        <td class="border-none driver-check" colspan="7">
                          <strong
                            >{{ 'Checklist.driverCheck' | translate }}
                            <span
                              >&nbsp;&nbsp;&nbsp; {{ 'Checklist.MND' | translate
                              }}
                            </span></strong
                          >
                        </td>
                      </tr>
                      <tr class="check-point">
                        <td class="w-180"></td>
                        <td colspan="3" class="ion-text-center bil">
                          <strong>{{ 'Checklist.bil' | translate }} </strong>
                        </td>
                        <td colspan="3" class="ion-text-center henger">
                          <strong>{{ 'Checklist.henger' | translate }} </strong>
                        </td>
                        <td rowspan="2" class="ion-text-center">
                          <strong
                            >{{ 'Checklist.comments' | translate }}:
                          </strong>
                        </td>
                      </tr>
                      <tr class="codes ion-text-center">
                        <td class="w-180"></td>
                        <td>
                          <strong>{{ 'Checklist.ok' | translate }}</strong>
                        </td>
                        <td><strong>R</strong></td>
                        <td><strong>?</strong></td>
                        <td>
                          <strong>{{ 'Checklist.ok' | translate }}</strong>
                        </td>
                        <td><strong>R</strong></td>
                        <td><strong>?</strong></td>
                      </tr>
                      <tr
                        class="codes ion-text-center"
                        *ngFor="let item of checkPoints"
                      >
                        <td class="w-180 ion-text-left">
                          {{ item.CheckPointId <=9 ?
                          "0"+item.CheckPointId:item.CheckPointId }}&nbsp; {{
                          'Checklist.'+item.Name | translate }}
                        </td>
                        <td>
                          <ion-checkbox
                            [(ngModel)]="item.BilOk"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disabledControl"
                            (ngModelChange)="changeSelection()"
                          ></ion-checkbox>
                        </td>
                        <td>
                          <ion-checkbox
                            [(ngModel)]="item.BilRepair"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disabledControl"
                            (ngModelChange)="changeSelection()"
                          ></ion-checkbox>
                        </td>
                        <td>
                          <ion-checkbox
                            [(ngModel)]="item.BilDoubt"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disabledControl"
                            (ngModelChange)="changeSelection()"
                          ></ion-checkbox>
                        </td>
                        <td>
                          <ion-checkbox
                            [(ngModel)]="item.HengerOk"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disabledControl"
                            (ngModelChange)="changeSelection()"
                          ></ion-checkbox>
                        </td>
                        <td>
                          <ion-checkbox
                            [(ngModel)]="item.HengerRepair"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disabledControl"
                            (ngModelChange)="changeSelection()"
                          ></ion-checkbox>
                        </td>
                        <td>
                          <ion-checkbox
                            [(ngModel)]="item.HengerDoubt"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disabledControl"
                            (ngModelChange)="changeSelection()"
                          ></ion-checkbox>
                        </td>
                        <ng-container *ngIf="item.CheckPointId==1">
                          <td>
                            <input
                              type="text"
                              [(ngModel)]="item.Comment"
                              [ngModelOptions]="{standalone: true}"
                              [disabled]="disabledControl"
                              (ngModelChange)="changeSelection()"
                            />
                          </td>
                        </ng-container>
                        <ng-container *ngIf="item.CheckPointId >1">
                          <td class="border-none"></td>
                        </ng-container>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ion-col>
      </ion-row>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div>
      <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'CustomerLead.back' | translate }}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="save()"
        [disabled]="!checklistForm.valid || submit"
        *ngIf="!disabledControl"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'General.save' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
