import { Component, } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentationType, Userlevel } from '../../shared/enums';
import { AppSettings } from '../../shared/app-settings';
import { Storage } from '@ionic/storage-angular';

@Component({
    selector: 'page-documentation',
    templateUrl: 'documentation.html',
    styleUrls: ['documentation.scss'],
})

export class DocumentationPage {
    documentationType = DocumentationType;
    documentationTypeOption: DocumentationType;
    userlevel = Userlevel;
    level = AppSettings.UserLevel;
    constructor(
        private router: Router, private storage: Storage) {
        this.documentationTypeOption = this.documentationType.Deviation;
        this.storage.get(AppSettings.StorageKeys.Userlevel).then(value => {
            this.level = value;
        });
    }

    setOption(value) {
        this.documentationTypeOption = value;
        this.next();
    }

    next() {
        if (this.documentationTypeOption === this.documentationType.Deviation) {
            this.router.navigate(['/new-deviation']);
        } else if (this.documentationTypeOption === this.documentationType.CustomerLead) {
            this.router.navigate(['/customer-lead']);
        } 
        else if (this.documentationTypeOption === this.documentationType.MonthlyChecklist) {
            this.router.navigate(['/dynamic-form'],{ queryParams: { formType: 'monthly' } });}
        else {
            this.router.navigate(['/dynamic-form']);
        }
    }

    navigateToDocuments() {
     this.router.navigateByUrl('/documents');
    }
}
