<ion-content class="ion-padding">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-text color="primary">
          <h6>
            <ion-icon name="add-circle-outline"></ion-icon> {{
            'ConvertProduct.title' | translate }}
          </h6>
        </ion-text>
        <p class="description">
          {{description}}: <strong>{{descriptionProd}}</strong>.
          {{description2}}
        </p>
        <ion-list lines="none">
          <form [formGroup]="convertProductForm">
            <ion-item>
              <ion-label
                >{{ 'ConvertProduct.selectProduct' | translate }}</ion-label
              >
              <ion-select
                formControlName="productNo"
                placeholder="{{ 'General.select' | translate }}"
              >
                <ion-select-option value=""
                  >{{ 'General.select' | translate }}</ion-select-option
                >
                <ion-select-option
                  *ngFor="let product of inventoryList"
                  [value]="product"
                  >{{product.ProductDescription}}</ion-select-option
                >
              </ion-select>
            </ion-item>
            <ion-item
              class="item-validation"
              *ngIf="!convertProductForm.controls.productNo.valid && (convertProductForm.controls.productNo.dirty)"
            >
              <ion-text color="danger">
                <p
                  [hidden]="!convertProductForm.controls.productNo.errors.required"
                >
                  {{ 'ConvertProduct.validationProductRequired' | translate }}
                </p>
              </ion-text>
            </ion-item>
            <ion-item>
              <input
                type="number"
                formControlName="quantity"
                class="ion-text-right"
                autocomplete="off"
                placeholder="{{ 'ConvertProduct.quantity' | translate }}"
              />
            </ion-item>
            <ion-item
              class="item-validation"
              *ngIf="!convertProductForm.controls.quantity.valid && (convertProductForm.controls.quantity.dirty)"
            >
              <ion-text color="danger">
                <p
                  [hidden]="!convertProductForm.controls.quantity.errors.required"
                >
                  {{ 'ConvertProduct.quantityRequired' | translate }}
                </p>
              </ion-text>
              <ion-text color="danger">
                <p [hidden]="!convertProductForm.controls.quantity.errors.min">
                  {{ 'ConvertProduct.minQuantity' | translate }} {{min}}
                </p>
              </ion-text>
              <ion-text color="danger">
                <p [hidden]="!convertProductForm.controls.quantity.errors.max">
                  {{ 'ConvertProduct.maxQuantity' | translate }} {{max}}
                </p>
              </ion-text>
            </ion-item>
            <ion-item class="ion-float-right ion-margin-top">
              <ion-buttons>
                <ion-button
                  (click)="dismiss()"
                  color="grey"
                  fill="outline"
                  size="small"
                >
                  <span>{{ 'ConvertProduct.cancel' | translate }}</span>
                </ion-button>
                <ion-button
                  (click)="addProduct()"
                  [disabled]="!convertProductForm.valid"
                  fill="solid"
                  size="small"
                >
                  <span>{{ 'ConvertProduct.save' | translate }}</span>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
