import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
    selector: 'customer-sign',
    templateUrl: 'customer-sign.html',
    styleUrls: ['customer-sign.scss']
})
export class CustomerSignComponent implements AfterViewInit {
    signForm: FormGroup;
    @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
    signaturePadOptions = {
        minWidth: 2,
        canvasHeight: 120,
    };

    constructor(public modalCtrl: ModalController, private formBuilder: FormBuilder) {
        this.signForm = formBuilder.group({
            sign: ['', Validators.compose([Validators.required])],
            name: ['', Validators.compose([Validators.required])]
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const clientWidth = document.getElementById('sign').clientWidth;
            this.signaturePad.set('canvasWidth', clientWidth);
        }, 0);

        this.signaturePad.set('minWidth', 2);
        this.signaturePad.clear();
    }

    drawComplete() {
        this.signForm.get('sign').setValue(this.signaturePad.toDataURL());
    }

    addSign() {
        const sign = this.signForm.get('sign').value;
        const name = this.signForm.get('name').value;
        this.modalCtrl.dismiss({ Name: name, Sign: sign });
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
