<ion-header>
  <ion-toolbar>
    <ion-menu-toggle auto-hide="false">
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Beholdning.beholdningBulk' | translate }}</span>
      <logout
        (truckTrailerChanged)="truckTrailerChanged()"
        class="hidden-xs"
      ></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <ion-row>
        <ion-col size="12" class="overflow-x">
          <div class="page-title">
            <ion-icon
              name="calendar-outline"
              ion-text
              color="primary"
              class="vertical-middle m-r-10"
              id="datepicker"
              (click)="openModal = true"
            ></ion-icon>

            <ion-modal
              [cssClass]="'center'"
              id="dtOrder"
              [isOpen]="openModal"
              (didDismiss)="closeModal()"
            >
              <ng-template>
                <ion-datetime
                  #picker
                  [(ngModel)]="dateSelector"
                  (ionChange)="dateChangeEvent($event)"
                  presentation="date"
                  first-day-of-week="1"
                  size="cover"
                  [showDefaultButtons]="true"
                  doneText="{{ 'buttons.ok' | translate }}"
                  cancelText="{{ 'buttons.cancel' | translate }}"
                  class=""
                >
                </ion-datetime>
              </ng-template>
            </ion-modal>
            <span (click)="openModal = true"
              >{{'Day.' + currentDate.getDay() | translate}}</span
            >
            <span class="secondary">
              {{currentDate | date:dateFormat}}
              <ion-button
                (click)="previousDay()"
                class="no-padding button-no-text vertical-top ion-no-margin"
                fill="clear"
              >
                <ion-icon
                  color="lightFour"
                  slot="icon-only"
                  name="caret-back-circle"
                ></ion-icon>
              </ion-button>
              <ion-button
                (click)="nextDay()"
                class="no-padding button-no-text vertical-top ion-no-margin"
                fill="clear"
              >
                <ion-icon
                  color="lightFour"
                  slot="icon-only"
                  name="caret-forward-circle"
                ></ion-icon>
              </ion-button>
            </span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="overflow-x">
          <div class="beholding-grid">
            <ion-row>
              <ion-col size="4" class="grid-container ion-no-padding">
                <ion-row class="grid-header border-top-bottom">
                  <ion-col size="2" class="border-left"> &nbsp; </ion-col>
                  <ion-col size="10" class="border-right"> &nbsp; </ion-col>
                </ion-row>
                <ion-row
                  *ngFor="let item of inventoryList; let isOdd = odd; let i = index"
                  [class.odd]="isOdd && !item.IsSt1Product"
                  class="inventoryList ion-no-padding"
                  [class.marked]="item.IsSt1Product"
                >
                  <ion-col size="2" class="border-left ion-text-center">
                    <ion-button
                      (click)="convertProduct(item)"
                      class="no-padding ion-no-margin"
                      fill="clear"
                    >
                      <ion-icon name="swap-horizontal-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                  <ion-col size="10" class="border-right">
                    <!--<ion-badge>FD</ion-badge> Farget diesel-->
                    <ion-badge [style.background]="item.ProductColorCode"
                      >{{item.ProductShortCode}}
                    </ion-badge>
                    {{item.ProductDescription}}
                  </ion-col>
                </ion-row>
                <ion-row class="border-top-bottom ion-no-padding">
                  <ion-col size="2" class="border-left"> &nbsp; </ion-col>
                  <ion-col size="10" class="border-right"> &nbsp; </ion-col>
                </ion-row>
              </ion-col>
              <ion-col size="5" class="grid-container ion-no-padding">
                <ion-row class="grid-header border-top-bottom">
                  <ion-col size="3" class="border-right ion-text-center">
                    <strong>{{ 'Beholdning.na' | translate }}</strong>
                  </ion-col>
                  <ion-col size="4" class="ion-text-right ion-padding-end">
                    <strong>{{ 'Beholdning.liter' | translate }}</strong>
                  </ion-col>
                  <ion-col
                    size="5"
                    class="border-left-right ion-text-right ion-padding-end"
                  >
                    <strong>{{ 'Beholdning.nyttVolum' | translate }}</strong>
                  </ion-col>
                </ion-row>
                <ion-row
                  class="ion-no-padding"
                  *ngFor="let item of inventoryList; let isOdd = odd; let i = index"
                  [class.odd]="isOdd && !item.IsSt1Product"
                  [class.marked]="item.IsSt1Product"
                >
                  <ion-col size="3" class="border-right ion-text-center">
                    {{item.Quantity}}
                  </ion-col>
                  <ion-col size="4" class="ion-text-center">
                    <input
                      type="number"
                      class="amount"
                      [(ngModel)]="item.Liter"
                      appRemoveZeroOnFocus
                      (ngModelChange)="literUpdated($event)"
                    />
                  </ion-col>
                  <ion-col
                    size="5"
                    class="border-left-right ion-text-right ion-padding-end"
                  >
                    <ion-text color="success">
                      <strong>{{item.Quantity + item.Liter}}</strong></ion-text
                    >
                  </ion-col>
                </ion-row>
                <ion-row class="border-top-bottom ion-no-padding">
                  <ion-col size="3" class="border-right ion-text-center">
                    <strong>{{totalQuantity}}</strong>
                  </ion-col>
                  <ion-col size="4" class="ion-text-center"> </ion-col>
                  <ion-col
                    size="5"
                    class="border-left-right ion-text-right ion-padding-end"
                  >
                    <strong>{{totalNyttVolum}}</strong>
                  </ion-col>
                </ion-row>
              </ion-col>
              <ion-col size="3" class="grid-container ion-no-padding">
                <ion-row class="grid-header border-top-bottom border-right">
                  <ion-col class="ion-text-right">
                    <strong>{{ 'Beholdning.totalt' | translate }}</strong>
                  </ion-col>
                  <ion-col class="ion-text-right">
                    <strong>{{ 'Beholdning.avvik' | translate }}</strong>
                  </ion-col>
                </ion-row>
                <ion-row
                  *ngFor="let item of inventoryList; let isOdd = odd; let i = index"
                  [class.odd]="isOdd"
                  class="border-right"
                >
                  <ion-col class="ion-text-right">
                    <strong>{{item.QuantityInOrder}}</strong>
                  </ion-col>
                  <ion-col class="ion-text-right">
                    <strong
                      >{{item.Quantity + item.Liter -
                      item.QuantityInOrder}}</strong
                    >
                  </ion-col>
                </ion-row>
                <ion-row class="border-top-bottom border-right ion-no-padding">
                  <ion-col class="ion-text-right">
                    <strong>{{totalQuantityInOrder}}</strong>
                  </ion-col>
                  <ion-col class="ion-text-right">
                    <strong>{{totalAvvik}}</strong>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
      <br />
      <ion-row [hidden]="!showStats">
        <ion-col class="overflow-x">
          <div class="bottom-section">
            <ion-row>
              <ion-col size="6" class="min-height-250 ion-no-padding">
                <fieldset class="full-height">
                  <legend>
                    <strong
                      >{{ 'Beholdning.avvikLastingLevering' |
                      translate}}</strong
                    >
                  </legend>
                  <p>{{ 'Beholdning.oversiktOverRegistrete' | translate}}</p>
                  <ion-row>
                    <ion-col size="6"> </ion-col>
                    <ion-col>
                      <strong>{{ 'Beholdning.lastet' | translate }}</strong>
                    </ion-col>
                    <ion-col>
                      <strong>{{ 'Beholdning.levert' | translate }}</strong>
                    </ion-col>
                    <ion-col>
                      <strong>{{ 'Beholdning.avvik' | translate }}</strong>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6">
                      {{ 'Beholdning.iDag' | translate }}
                    </ion-col>
                    <ion-col> {{deviationStatus?.TodayLoaded}} </ion-col>
                    <ion-col> {{deviationStatus?.TodayDelivered}} </ion-col>
                    <ion-col> {{deviationStatus?.TodaySumDeviation}} </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="6">
                      {{ 'Beholdning.totaltIAr' | translate }}
                    </ion-col>
                    <ion-col>
                      <strong>{{deviationStatus?.YearLoaded}}</strong>
                    </ion-col>
                    <ion-col>
                      <strong>{{deviationStatus?.YearDelivered}}</strong>
                    </ion-col>
                    <ion-col>
                      <strong>{{deviationStatus?.YearSumDeviation}}</strong>
                    </ion-col>
                  </ion-row>
                </fieldset>
              </ion-col>
              <ion-col size="6" class="min-height-250 ion-no-padding">
                <fieldset class="full-height">
                  <legend>
                    <strong
                      >{{ 'Beholdning.dineLiterLevertPrManed' | translate
                      }}</strong
                    >
                  </legend>
                  <!--chart (load)="saveChart($event.context)"></chart-->
                </fieldset>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="12">
          <form [formGroup]="inventoryForm">
            <ion-row>
              <ion-col size="12" size-sm="3">
                <ion-text color="lightFour">
                  <h6>{{ 'Beholdning.kommentarer' | translate }}</h6>
                </ion-text>
                <textarea
                  rows="2"
                  class="width"
                  class="comment"
                  formControlName="comment"
                ></textarea>
                <p
                  class="ion-no-margin ion-no-padding"
                  color="danger"
                  *ngIf="inventoryForm.controls.comment.errors?.required && (inventoryForm.controls.comment.dirty || submitAttempt)"
                >
                  {{ 'Beholdning.validationCommentRequired' | translate }}
                </p>
              </ion-col>
              <ion-col size="12" size-sm="3">
                <ion-text color="lightFour">
                  <h6>{{ 'Beholdning.depot' | translate }}</h6>
                </ion-text>
                <select formControlName="depot" class="depot">
                  <option value="">{{ 'General.select' | translate }}</option>
                  <option *ngFor="let depot of depos" value="{{depot.DepotNo}}">
                    {{depot.Name}}
                  </option>
                </select>
                <ion-text color="danger">
                  <p
                    class="ion-no-margin ion-no-padding"
                    *ngIf="inventoryForm.controls.depot.errors?.required && (inventoryForm.controls.depot.dirty || submitAttempt)"
                  >
                    {{ 'Beholdning.validationDepotRequired' | translate }}
                  </p>
                </ion-text>
              </ion-col>
              <ion-col size="12" size-sm="6">
                <ion-text color="lightFour">
                  <h6>{{ 'Beholdning.temp' | translate }}</h6>
                </ion-text>
                <input
                  type="number"
                  class="inputTemp"
                  formControlName="loadingTemperature"
                />
                <ion-button
                  class="no-padding tempButton"
                  *ngIf="temperature != null"
                  (click)="setTemperature()"
                  >{{temperature}} &deg;C</ion-button
                >
                <ion-button class="no-padding tempButton" (click)="setQty(0.5)">
                  +
                </ion-button>
                <ion-button
                  class="no-padding tempButton"
                  (click)="setQty(-0.5)"
                >
                  -
                </ion-button>
                <ion-text color="danger">
                  <p
                    class="ion-no-margin ion-no-padding"
                    *ngIf="inventoryForm.controls.loadingTemperature.errors?.required && (inventoryForm.controls.loadingTemperature.dirty || submitAttempt)"
                  >
                    {{ 'Beholdning.validationTemperatureRequired' | translate }}
                  </p>
                </ion-text>
              </ion-col>
            </ion-row>
          </form>
        </ion-col>
        <ion-col size="12" size-sm="12">
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox [(ngModel)]="IsStandardLiter"></ion-checkbox>
            <ion-label>{{ 'Beholdning.VolStandard' | translate }}</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <em>{{ 'Beholdning.VolstandardInfo' | translate }}</em>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-sm-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="lagreLasting()"
        [disabled]="!inventoryForm.valid || submitting"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'Beholdning.lagreLasting' | translate }}</span>
      </ion-button>
    </div>
    <div [hidden]="!expand">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="nullstillAvvik()"
        [disabled]="submitting"
      >
        <ion-icon name="close-circle-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'Beholdning.nullstillAvvik' | translate }}</span>
      </ion-button>
      <ion-button fill="outline" class="no-padding" (click)="toggleStats()">
        <ion-icon name="podium-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'Beholdning.visStatistikk' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        routerLink="/transaction-log"
      >
        <ion-icon name="document-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'TransactionLog.logg' | translate }}</span
        > </ion-button
      >&nbsp;
      <ion-button
        class="no-padding pull-right-md hidden-xs"
        (click)="lagreLasting()"
        [disabled]="!inventoryForm.valid || submitting"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'Beholdning.lagreLasting' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
