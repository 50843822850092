import { Component } from '@angular/core';
import { NavController, LoadingController, isPlatform } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationExtras } from '@angular/router';
import { InternalOrdertype, UserType } from '../../shared/enums';
import { Storage } from '@ionic/storage-angular';
import _ from 'lodash';
import { latLng, tileLayer } from 'leaflet';
import { Geolocation } from '@capacitor/geolocation';
import { forkJoin, of } from 'rxjs';
import * as moment from 'moment';
import { switchMap } from 'rxjs/operators';
@Component({
    selector: 'page-work-orders',
    templateUrl: 'work-orders.html',
    styleUrls: ['work-orders.scss'],
})

export class WorkOrdersPage {
    currentDate: any = new Date();
    private orders: any[];
    private today: any = this.currentDate;
    private yesterday: any;
    sortProperty = 'OrderSorting';
    sortDirection = 'desc';
    companies = [];
    company = '0';
    prioritet = -1;
    ordlocation = '';
    filteredOrders: any;
    userlevel: number = null;
    dateFormat;
    expand = false;
    mine = false;
    utildelteOrdrer = false;
    nearMeg = false;
    nearMegDistance = 10;
    userType: number = null;
    UserType: any = UserType;
    visKunDagens: boolean = null;
    latitude: number = null;
    longitude: number = null;
    leafletOptions: any;
    search = '';
    constructor(
        public navCtrl: NavController, public userService: UserService, private translateService: TranslateService,
        private loadingController: LoadingController, private router: Router, private storage: Storage,
        private geolocation: Geolocation) {
        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });
    
    }

      ngOnInit() {
    this.setupGeolocation();
  }

async setupGeolocation() {
  const posOptions = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 30000
  };

  // Check if running on a mobile platform (iOS or Android)
  if (isPlatform('hybrid')) {
    // Use Capacitor's Geolocation plugin
    console.log("Using Capacitor's Geolocation plugin");
    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
    } catch (error) {
      this.handleGeolocationError();
      return; // Exit the function if there's an error
    }
  } else {
    console.log("Using web's Geolocation plugin");
    // Fallback to web's HTML5 Geolocation
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    }, (err) => {
      this.handleGeolocationError();
      return; // Exit the function if there's an error
    }, posOptions);
  }

  // Common setup for Leaflet map
  this.leafletOptions = {
    layers: [
      tileLayer('https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png', {
        maxZoom: 18,
        attribution: ' '
      })
    ],
    zoom: 10,
    center: latLng(this.latitude, this.longitude)
  };
}

  handleGeolocationError() {
    this.leafletOptions = {
      zoom: 7 // default zoom when geolocation fails
    };
    this.latitude = this.longitude = 0; // Reset coordinates on error
    // Add more error handling logic here, like showing a notification to the user
  }


    visKunDagensChanged($event) {
        
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                console.log(AppSettings.Username);
                this.userService.getOrders(this.visKunDagens ? this.currentDate : null, AppSettings.Username, false,
                    InternalOrdertype.Workorder, +this.company).subscribe(data => {
                        this.orders = data;
                        this.sort('OrderNo');
                        this.filterChanged(null);
                        this.storage.set(AppSettings.StorageKeys.CompanyNo, this.company);
                        loader.dismiss();
                    });
            });
        });
    }

    loadData() {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        forkJoin([this.storage.get(AppSettings.StorageKeys.WorkOrderFilterMine),
        this.storage.get(AppSettings.StorageKeys.WorkOrderFilterNearMeg),
        this.storage.get(AppSettings.StorageKeys.WorkOrderFilterUtildelteOrdrer),
        this.storage.get(AppSettings.StorageKeys.WorkOrderFilterVisKunDagens), this.storage.get(AppSettings.StorageKeys.CompanyNo),
        this.storage.get(AppSettings.StorageKeys.Userlevel),
        this.storage.get(AppSettings.StorageKeys.OrderLocation),
        this.userService.getCompanies()]).subscribe(data => {
            this.mine = data[0];
            this.nearMeg = data[1];
            this.utildelteOrdrer = data[2];
            this.visKunDagens = data[3];
            this.company = data[4];
            this.userlevel = data[5];
            this.ordlocation = data[6];
            this.companies = data[7];
            this.visKunDagensChanged(null);
        });
    }
    clearSearch()
    {
        this.search = '';
        this.filterChanged(null);
    }
    filterChanged($event) {
        this.filteredOrders = _.filter(this.orders, (order) => {
            let value = true;
            if (this.mine === true && this.utildelteOrdrer === false) {
                value = value && (order.AssignedDriver === +AppSettings.Username);
            } else if (this.mine === true && this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === +AppSettings.Username || order.AssignedDriver === 0);
            } else if (this.mine === false && this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === 0);
            }

            if (!value) {
                return value;
            }

            if (this.nearMeg === true && this.latitude != null && this.longitude != null) {
                value = value && (Utils.calculateDistance(this.latitude, this.longitude, order.Latitude, order.Longitude)
                    <= this.nearMegDistance);
            }

            if (!value) {
                return value;
            }

            // priority
            if (this.prioritet > -1) {
                value = value && (order.IsUrgent === (this.prioritet === 1 ? true : false));
            }
            //search
            if(this.search.length >= 3)
            {
                value = value && (order.OrderNo.toString().toLowerCase().includes(this.search.toLowerCase()) 
                    || order.CustomerName.toLowerCase().includes(this.search.toLowerCase())
                    || order.DeliveryInfo.toLowerCase().includes(this.search.toLowerCase())
                    || order.CustomerReference.toLowerCase().includes(this.search.toLowerCase())
                    || order.CustomerPurchaseOrderNo.toLowerCase().includes(this.search.toLowerCase())

                    
                    );
            }

            // company

            // orderlocation
            try {
                if (this.ordlocation !== '') {
                    // filter
                    value = value && (order.OrderLocation === this.ordlocation);
                }
                // save usersetting
                this.storage.set(AppSettings.StorageKeys.OrderLocation, this.ordlocation);
            } catch (error) {

            }

            return value;
        });
        this.storage.set(AppSettings.StorageKeys.WorkOrderFilterMine, this.mine);
        this.storage.set(AppSettings.StorageKeys.WorkOrderFilterUtildelteOrdrer, this.utildelteOrdrer);
        this.storage.set(AppSettings.StorageKeys.WorkOrderFilterNearMeg, this.nearMeg);
        this.storage.set(AppSettings.StorageKeys.WorkOrderFilterVisKunDagens, this.visKunDagens);
    }

    ionViewDidEnter() {
        this.loadData();
    }

    isOrderMine(order): boolean {
        if (order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

    navigteToWorkOrderDetils(order) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                order: JSON.stringify(order),
                approval: false,
            },
            skipLocationChange: false
        };
        this.router.navigate(['/work-order-details'], navigationExtras);
    }

    approveForInvoice(order) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.sendOrderToApproval(order).subscribe(data => {
                    order.Approve = true;
                    this.removeApprovedItem(order);
                    loader.dismiss();
                });
            });
        });
    }

    sort(property) {
        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'desc' : 'asc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'desc';
        }
        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    removeApprovedItem(order) {
        this.filteredOrders.splice(
            this.filteredOrders.findIndex(x => x.OrderNo = order.OrderNo),
            1
        );
    }

    showOrderLocation(location) {
        let val = '';
        this.translateService.get('General.Location' + location).subscribe(value => {
            val = value;
        });
        return val;
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.loadData();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.loadData();
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    

    getDateText(order, iDate) {

        let deliveryDate;

        if (iDate === 1) {
            deliveryDate = new Date(order.DeliveryDate);
        } else {
            deliveryDate = new Date(order.ConfirmedDeliveryDate);
        }

        if (Utils.isSameDateAs(deliveryDate, this.today)) {
            return this.translateService.get('Ordrer.iDag');
        }/* else if (Utils.isSameDateAs(deliveryDate, this.yesterday)) {
            return this.translateService.get('Ordrer.iMorgen');
        }*/
        return this.translateService.get('General.dateFormat')
            .pipe(switchMap(format => of(moment(deliveryDate).format(format.toUpperCase()))));
    }



    plukkOrApne(order) {
        if (order.AssignedDriver === 0) {
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.assignOrderToDriver(order.OrderNo, null).subscribe(data => {
                        order.AssignedDriver = AppSettings.Username;
                        loader.dismiss();
                        //this.loadData();
                    });
                });
            });
        }
    }
}
