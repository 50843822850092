import { Component, ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector, OnInit, ViewChild } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import * as moment from 'moment';
import { of, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { MapMarkerComponent } from '../../components/map-marker/map-marker';
import { Storage } from '@ionic/storage-angular';
import { DragulaService } from 'ng2-dragula';
import { ActivatedRoute } from '@angular/router';
import { InternalOrdertype } from '../../shared/enums';
import { icon, marker, latLng, tileLayer, Map, Marker, Layer } from 'leaflet';
enum ViewType {
    LIST = 1,
    MAP = 2
}

@Component({
    selector: 'page-fleet',
    templateUrl: 'fleet.html',
    styleUrls: ['fleet.scss']
})
export class FleetPage implements OnInit {
    @ViewChild('picker') picker: any;
    currentDate: any = new Date();
    private today: any = this.currentDate;
    private yesterday: any;
    private orders: any[];
    filteredOrders: any;
    dateFormat;
    sortProperty = 'OrderSorting';
    sortDirection = 'asc';
    utildelteOrdrer = true;
    nearMeg = false;
    nearMegDistance = 10;
    latitude: number = null;
    longitude: number = null;
    leafletOptions: any;
    layers: any = [];
    map: Map;
    mapLoading = false;
    locations: any = [];
    visKunDagens = false;
    dateSelector = this.currentDate.toISOString();
    showAll = false;
    filteredLocations: any = [];
    inventory: any = [];
    showInventory = true;

    constructor(
        public navCtrl: NavController, public userService: UserService, public route: ActivatedRoute,
        private translateService: TranslateService, private loadingController: LoadingController, private storage: Storage,
        private geolocation: Geolocation, private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef, private dragulaService: DragulaService) {
        this.yesterday = Utils.addDays(this.today, -1);
        const posOptions = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 30000
        };

       

        const bag: any = this.dragulaService.find('dragdrop');

        if (bag !== undefined) {
            dragulaService.destroy(bag.name);
        }
        /*      dragulaService.setOptions('dragdrop', {
                  moves: function moves(el, container, handle) {
                      if (handle.className.indexOf('handle') !== -1) {
                          return true;
                      } else {
                          return false;
                      }
                  }
              });
      
              dragulaService.drop.subscribe((value) => {
                  this.onDrop(value.slice(1));
              });
      */
        interval(300 * 1000)
            .subscribe(() => {
                // console.log('call function every five minute' + new Date().getHours() + ':' + new Date().getMinutes());
                this.visKunDagensChanged(null);
            });
    }

    

    
  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.leafletOptions = {
        layers: [tileLayer('https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png', { attribution: ' ' })],
        zoom: 10,
        center: latLng({ lat: this.latitude, lng: this.longitude })
      };
    } catch (error) {
      this.leafletOptions = {
        zoom: 7
      };
      this.latitude = this.longitude = 0;
    }
  }

    private onDrop(args) {
        const [e, el] = args;
        const items = el.querySelectorAll('ion-row');
        const orders: number[] = new Array(items.length);
        let i = 0;
        let itm;
        for (itm of items) {
            orders[i] = itm.id;
            i++;
        }
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.SaveOrderSorting(orders).subscribe(data => {
                    loader.dismiss();
                });
            });
        });
    }

    onMapReady(map: Map) {
        this.map = map;
    }

    ngOnInit() {
        this.getCurrentPosition();
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });
        this.visKunDagensChanged(null);
    }

    getDateText(order, iDate) {
        let deliveryDate;

        if (iDate === 1) {
            deliveryDate = new Date(order.DeliveryDate);
        } else {
            deliveryDate = new Date(order.ConfirmedDeliveryDate);
        }

        if (Utils.isSameDateAs(deliveryDate, this.today)) {
            return this.translateService.get('Ordrer.iDag');
        } else if (Utils.isSameDateAs(deliveryDate, this.yesterday)) {
            return this.translateService.get('Ordrer.iMorgen');
        }
        return this.translateService.get('General.dateFormat')
            .pipe(switchMap(format => of(moment(deliveryDate).format(format.toUpperCase()))));
    }

    filterChanged($event) {
        this.filterData();
    }

    visKunDagensChanged($event) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                const date = this.visKunDagens ? this.currentDate : new Date();
                /*  const from = moment(date).format('YYYY-MM-DD') + 'T00:00:00.000Z';
                 const to = moment(date).format('YYYY-MM-DD') + 'T23:59:59.000Z'; */
                this.storage.get(AppSettings.StorageKeys.Username).then(user => {
                    this.userService.getOrders(this.visKunDagens ? this.currentDate : null, user, false,
                        InternalOrdertype.Bulk, 0).subscribe(orders => {
                            this.userService.getVehicles().subscribe(locations => {
                                var data = _.uniqBy(locations.items, obj => obj.license_plate.number.replace(/\D/g, ""));
                                this.orders = orders;
                                this.locations = [];
                                var truckIds: any = [];
                                this.userService.getTrailersAndTrucks().subscribe(trucksData => {
                                    var trucks = trucksData.Trucks;
                                    for (const item of data) {
                                        if (item.license_plate) {
                                            var truck = _.find(trucks, function trucks(truckItem: any) {
                                                return truckItem.Description.replace(/\s+/g, "").includes(item.license_plate.number);
                                            });
                                            if (truck) {
                                                truckIds.push(truck.ID);
                                                if (item.location) {
                                                    this.locations.push({
                                                        Name: item.license_plate ? item.license_plate.number : "",
                                                        Latitude: item.location.latitude,
                                                        Longitude: item.location.longitude,
                                                        TruckID: truck.ID,
                                                        Inventory: []
                                                    });
                                                }
                                            }
                                        }
                                    }

                                    // this.locations = [];
                                    // this.locations.push({
                                    //     Latitude: 59.4472656,
                                    //     Longitude: 6.429458,
                                    //     Name: "M5557  Arvid/Trond",
                                    //     TruckID: 9999,
                                    //     Inventory: []
                                    // });
                                    // truckIds=[];
                                    // truckIds.push("9999");

                                    if (truckIds.length > 0) {
                                        this.userService.getTruckInventory(truckIds).subscribe((data: any) => {
                                            data.forEach(item => {
                                                const inventory = _.find(this.locations, { TruckID: +(item.TruckID) });
                                                if (inventory) {
                                                    inventory.Inventory.push(item);
                                                }
                                            });
                                            this.filterData();
                                            loader.dismiss();
                                        });
                                    } else {
                                        this.filterData();
                                        loader.dismiss();
                                    }
                                });
                            });
                        }, error => {
                            loader.dismiss();
                        });
                });
            });
        });
    }

    filterData() {
        this.filteredOrders = _.filter(this.orders, (order) => {
            let value = true;
            order.FilteredOrderlines = order.Orderlines;
            if (this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === 0);
            }
            if (!value) {
                return value;
            }
            /*  if (this.nearMeg === true && this.latitude != null && this.longitude != null) {
                 value = value && (Utils.calculateDistance(this.latitude, this.longitude, order.Latitude, order.Longitude)
                     <= this.nearMegDistance);
             }
             if (!value) {
                 return value;
             } */
            return value;
        });

        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);

        // update map view
        this.layers = [];
        let orderItem;
        for (orderItem of this.filteredOrders) {
            const compFactory = this.componentFactoryResolver.resolveComponentFactory(MapMarkerComponent);
            const compRef: ComponentRef<MapMarkerComponent> = compFactory.create(this.injector);
            compRef.instance.order = orderItem;
            const attachView = 'attachView';
            const detachView = 'detachView';
            this.applicationRef[attachView](compRef.hostView);
            compRef.onDestroy(() => {
                this.applicationRef[detachView](compRef.hostView);
            });

            this.layers.push(marker([orderItem.Latitude, orderItem.Longitude], {
                icon: icon({
                    iconSize: [30, 41],
                    iconAnchor: [13, 0],
                    iconUrl: 'assets/icon/marker-icon.png',
                    shadowUrl: 'assets/icon/marker-shadow.png'
                })
            }).bindPopup(compRef.location.nativeElement));
        }

        this.filteredLocations = _.filter(this.locations, (location) => {
            let value = true;
            if (this.nearMeg === true && this.latitude != null && this.longitude != null) {
                value = value && (Utils.calculateDistance(this.latitude, this.longitude, location.Latitude, location.Longitude)
                    <= this.nearMegDistance);
            }
            if (!value) {
                return value;
            }
            return value;
        });
        this.mapLoading = true;
        for (const item of this.filteredLocations) {
            let content = `<span>${item.Name}</span>`;
            if (this.showInventory) {
                for (const orderLine of item.Inventory) {
                    content += `<span class="popup-content"><ion-badge style="background-color:${orderLine.ProductColorCode};">
                ${orderLine.ProductShortCode}</ion-badge>&nbsp;&nbsp;${orderLine.Quantity}</span><br/>`;
                }
            }
            this.layers.push(marker([item.Latitude, item.Longitude], {
                icon: icon({
                    iconSize: [50, 50],
                    iconAnchor: [13, 0],
                    iconUrl: 'assets/icon/Icon_Truck_solid.svg',
                    shadowUrl: 'assets/icon/Icon_Truck_solid.svg'
                })
            }).bindPopup(content, { autoClose: false }));
        }

        if (this.map) {
            this.togglePins();
        }
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.dateSelector = this.currentDate.toISOString();
        this.visKunDagensChanged(null);
    }

    togglePins() {
        if (this.showAll) {
            this.map.eachLayer((layer) => {
                layer.openPopup();
            });
        } else {
            this.map.eachLayer((layer) => {
                layer.closePopup();
            });
        }
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.dateSelector = this.currentDate.toISOString();
        this.visKunDagensChanged(null);
    }

    dateChangeEvent(event) {
        this.currentDate = new Date(event.detail.value);
        this.visKunDagensChanged(null);
    }

    openPicker() {
        this.picker.open();
    }
}
