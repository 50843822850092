import { Component, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { AppSettings } from '../../shared/app-settings';

@Component({
    selector: 'page-checklist-view',
    templateUrl: 'checklist-view.html',
    styleUrls: ['checklist-view.scss']
})
export class ChecklistViewPage {
    @ViewChild('picker') picker: any;
    @ViewChild('approvalPicker') approvalPicker: any;
    @ViewChild('nextPressurePicker') nextPressurePicker: any;
    checklist: any;
    checkPoints = [];
    selectedCheckPoints = [];
    submit = false;
    fireExtinguisher = new Date().toISOString();
    approvalDate = new Date().toISOString();
    nextDate = new Date().toISOString();
    checklistForm: FormGroup;
    submitAttempt = false;
    form: any;
    disabledControl = true;
    constructor(
        public userService: UserService, public router: Router, private formBuilder: FormBuilder, public navCtrl: NavController,
        public route: ActivatedRoute, private storage: Storage) {
        this.route.queryParams.subscribe(params => {
            this.form = JSON.parse(params.form);
            this.checklist = this.form.CheckLists[0];
            this.fireExtinguisher = this.checklist.FireExtinguisher;
            this.approvalDate = this.checklist.ADRApproval;
            this.nextDate = this.checklist.NextPressureTestWithin;
            this.loadData();
            this.checklistForm = formBuilder.group(
                {
                    ownerName: [this.checklist.OwnerName, Validators.required],
                    ownerAddress: [this.checklist.OwnerAddress, Validators.required],
                    signatureDriver: [this.checklist.SignatureDriver, Validators.required],
                    controlMonths: [this.checklist.ControlMonths, Validators.required],
                    regNo: [this.checklist.RegNo, Validators.required],
                    regNr: [this.checklist.RegNr],
                    modelYearCar: [this.checklist.ModelYearCar, Validators.required],
                    modelYearTrailer: [this.checklist.ModelYearTrailer, Validators.required],
                    internetNo: [this.checklist.InternalId, Validators.required],
                    tankbil: [this.checklist.TankBil],
                    semiTrailer: [this.checklist.SemiTrailer],
                    supporter: [this.checklist.Supporter],
                    brandCar: [this.checklist.BrandCar, Validators.required],
                    tankMakeCar: [this.checklist.TankMakeCar, Validators.required],
                    tankVolume: [this.checklist.TankVoumeBil, Validators.required],
                    tankManufacturer: [this.checklist.TankMakeHanger_Semi, Validators.required],
                    tankVolumeHenger: [this.checklist.TankVolumeHanger, Validators.required]
                });
            this.storage.get(AppSettings.StorageKeys.Username).then(value => {
                if (this.form.UserID === value) {
                    this.disabledControl = false;
                }
                else {
                    this.disabledControl = true;
                    this.checklistForm.disable();
                }
            });
        });
    }

    getFormControlValue(field) {
        return this.checklistForm.get(field).value;
    }

    loadData() {
        this.userService.getCheckPoints().subscribe((data: any) => {
            this.checkPoints = data;
            for (var item of this.checkPoints) {
                item.BilOk = false;
                item.BilRepair = false;
                item.BilDoubt = false;
                item.HengerOk = false;
                item.HengerRepair = false;
                item.HengerDoubt = false;
                item.Comment = '';
            }

            this.checklist.CheckListCheckPoints.forEach(point => {
                _.filter(this.checkPoints, item => {
                    if (item.CheckPointId === point.CheckPointId) {
                        item.CheckListCheckPointId = point.CheckListCheckPointId
                        item.BilOk = point.BilOk;
                        item.BilRepair = point.BilRepair;
                        item.BilDoubt = point.BilDoubt;
                        item.HengerOk = point.HengerOk;
                        item.HengerRepair = point.HengerRepair;
                        item.HengerDoubt = point.HengerDoubt;
                        item.Comment = point.Comment;
                        if (item.BilOk || item.BilRepair || item.BilDoubt || item.HengerOk || item.HengerRepair || item.HengerDoubt) {
                            this.selectedCheckPoints.push(item);
                        }
                        return item;
                    }
                });
            });
        });
    }

    navigateToBack() {
        this.router.navigateByUrl('/forms-handling');
    }

    changeSelection() {
        this.selectedCheckPoints = _.filter(this.checkPoints, (item) => {
            if (item.BilOk || item.BilRepair || item.BilDoubt || item.HengerOk || item.HengerRepair || item.HengerDoubt) {
                return item;
            }
        });
    }

    save() {
        this.submitAttempt = true;
        if (this.checklistForm.valid) {
            this.submit = true;
            const checklist: any = {
                FireExtinguisher: new Date(this.fireExtinguisher),
                ADRApproval: new Date(this.approvalDate),
                NextPressureTestWithin: new Date(this.nextDate),
                OwnerName: this.getFormControlValue('ownerName'),
                OwnerAddress: this.getFormControlValue('ownerAddress'),
                SignatureDriver: this.getFormControlValue('signatureDriver'),
                ControlMonths: this.getFormControlValue('controlMonths'),
                RegNo: this.getFormControlValue('regNo'),
                RegNr: this.getFormControlValue('regNr'),
                ModelYearCar: this.getFormControlValue('modelYearCar'),
                ModelYearTrailer: this.getFormControlValue('modelYearTrailer'),
                InternalId: this.getFormControlValue('internetNo'),
                TankBil: this.getFormControlValue('tankbil'),
                Supporter: this.getFormControlValue('supporter'),
                SemiTrailer: this.getFormControlValue('semiTrailer'),
                BrandCar: this.getFormControlValue('brandCar'),
                TankMakeCar: this.getFormControlValue('tankMakeCar'),
                TankVoumeBil: this.getFormControlValue('tankVolume'),
                TankMakeHanger_Semi: this.getFormControlValue('tankManufacturer'),
                TankVolumeHanger: this.getFormControlValue('tankVolumeHenger'),
                CheckListCheckPoints: this.selectedCheckPoints,
                CheckListId: this.checklist.CheckListId
            };
            this.userService.updateCheckList(checklist).subscribe(data => {
                this.submit = false;
                this.submitAttempt = false;
                this.checklistForm.reset();
                this.navigateToBack();
            });
        }
    }

    openPicker(picker) {
        if (!this.disabledControl) {
            if (picker === 'picker') {
                this.picker.open();
            } else if (picker === 'approvalPicker') {
                this.approvalPicker.open();
            } else {
                this.nextPressurePicker.open();
            }
        }
    }
}
