export class AppSettings {

  // production server
  // public static API_ENDPOINT = 'https://connectapi.knapphus.net/api';
  // public static API_ENDPOINT = 'https://connectapi-prod2.knapphus.net/api';
   
 // public static API_ENDPOINT = 'https://connectapi-prod2.knapphus.net/api';

   
 // public static API_ENDPOINT = 'https://connectapi-prod3.knapphus.net/api';

  // staging server
   //public static API_ENDPOINT = 'https://staging-api.knapphus.net/api';

   public static API_ENDPOINT = 'https://staging-api2.knapphus.net/api';

  // local development

  // public static API_ENDPOINT = 'http://localhost:2200/api';
   public static NorwegianRegistryAPI = 'https://data.brreg.no/enhetsregisteret/api/enheter/?navn=';
  // public static API_ENDPOINT = 'http://110.227.241.101:10000/api';
  // public static API_ENDPOINT = 'http://223.229.143.74:2200/api';


  public static FileBaseUrl = AppSettings.API_ENDPOINT.replace('.net/api', '.net') + '/WorkOrderFiles/';

  public static appVersion = '2.9.1';
  public static UnauthenticatedRequest = false;
  public static Token: string = null;
  public static Username: string = null;
  public static UserLevel: number = null;
  public static UserType: number = null;
  public static VolumeToStandardTruck = false;
  public static ValidateTruckStock = false;
  public static LoadingTemp = 15;
  public static Vol2StdOff = false;

  public static NorTraceBearer='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdpZCI6IjIxNjU0NDI0LWQxYjItNGMzZS04Yjc4LTgwNjAxOTk2ZGE4MyIsInVzZXJpZCI6IjQ5ODNjMThhLWNkY2UtNDBhYi1iNTY4LWE0NjU4MjM2ODQ3MSIsInVzZXJuYW1lIjoiamFybGVsIiwiZnVsbG5hbWUiOiJKYXJsZSBMam9zbmVzIiwicm9sZXMiOnsicm9sZXMiOlsiYWRtaW4iLCJ2aWV3ZXIiXX0sIm1ldGFfZGF0YSI6eyJlbWFpbCI6ImphcmxlQGpobC5ubyJ9LCJ0YWdzIjp7InB1YmxpYyI6WyJBdmRlbGluZzpUZXN0Il19LCJpYXQiOjE1NzgyOTQyMjB9.YSOengvK6X2cSMPNCxPBPWUXSXwK7JQcXbRxPt2xGT0'

  static StorageKeys = class {
    public static Token = 'token';
    public static Username = 'username';
    public static Userlevel = 'userlevel';
    public static UserType = 'usertype';
    public static ValidateTruckStock = 'validateTruckStock';
    public static ShowAllProducts = 'showAllProducts';
    public static UserFullName = 'UserFullName';
    public static UserSettings = 'UserSettings';

    // order listing filter storing
    public static FilterMine = 'filter-mine';
    public static FilterUtildelteOrdrer = 'filter-utildelteOrdrer';
    public static FilterNearMeg = 'filter-nearMeg';
    public static FilterAvgiftsfrie = 'filter-avgiftsfrie';
    public static FilterVisKunDagens = 'filter-visKunDagens';
    public static ClientID = 'clientid';
    public static LocalStorageData = 'local-storage-data';
    public static FontSettings = 'font-settings';
    public static FilterLowVolume = 'filter-low-volume';
    public static FilterVisKunBulk = 'filter-vis-kun-bulk';
    public static Depot = 'Depot';
    public static Department = 'department';
    public static CompanyNo = 'CompanyNo';
    public static StockNo = 'StockNo';
    public static AllowDescriptionChange = 'allow-description-change';
    public static OrderLocation = 'OrderLocation';
    public static WorkOrderFilterMine = 'work-order-filter-mine';
    public static WorkOrderFilterUtildelteOrdrer = 'work-order-filter-utildelteOrdrer';
    public static WorkOrderFilterNearMeg = 'work-order-filter-nearMeg';
    public static WorkOrderFilterVisKunDagens = 'work-order-filter-visKunDagens';
    public static StartupViewSettings  = 'startup-view-settings';
    public static FavoritesTank = 'favorites-tank';
    public static FavoritesLevels = 'favorites-levels';
    public static Holidays = 'Holidays';
    public static UsePlanner = 'UsePlanner';
    public static DisableUserFilter = 'DisableUserFilter';
    public static LastMessageId = 'LastMessageId';
    public static ReleaseNotesRead = 'ReleaseNotesRead';
    public static DisableGPSSend = 'DisableGPSSend';
    public static FeaturedProducts = "FeaturedProducts";

  };
}
