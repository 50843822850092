import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { CustomerType } from '../../shared/enums';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { CustomerData } from '../../shared/customer-data';
import { CustomValidators } from '../../shared/validators';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, of, startWith, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'page-customer-lead',
    templateUrl: 'customer-lead.html',
    styleUrls: ['customer-lead.scss']
})
export class CustomerLeadPage implements AfterViewInit {
    customerLeadForm: FormGroup;
    selectedCustomer: any = null;
    customerType = CustomerType;
    customerTypeOption: CustomerType = CustomerType.Private;
    products: any = [];
    selectedProducts: any = [];
    userFullName = '';
    inContact = false;
    submitting = false;
    customerDataService;
    customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;
    @ViewChild('customerComplete') customerComplete: ElementRef;
  
    constructor(
        private formBuilder: FormBuilder, public userService: UserService, public router: Router,
        public navCtrl: NavController, private http: HttpClient, private storage: Storage,
        private translateService: TranslateService, private loadingController: LoadingController) {
        this.customerLeadForm = formBuilder.group({
            customer: ['', Validators.required],
            phone: [''],
            contact: [''],
            InContact: [''],
            email: ['', Validators.compose([CustomValidators.emailValidator])],
            comment: ['']
        });
        this.customerDataService = new CustomerData(http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.getLeadsProducts().subscribe(data => {
                    this.products = data;
                    this.products.forEach(item => {
                        item.isChecked = false;
                    });
                });
                this.storage.get(AppSettings.StorageKeys.UserFullName).then(name => {
                    this.userFullName = name;
                });
            });
            loader.dismiss();
        });
    }

    ngAfterViewInit() {
        this.customerComplete.nativeElement.setAttribute('type', 'text');
    }

    ngOnInit(){
        
        this.filteredCustomers = this.customerCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(name => name ? this._filterCustomers(name) : of([])) // Use switchMap instead of map
            );
    }

    displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

    getFormControl(field) {
        return this.customerLeadForm.get(field);
    }

    clearFormControl(form: FormGroup) {
        for (const key in form.controls) {
            if (key) {
                form.get(key).setValue('');
                form.get(key).clearValidators();
                form.get(key).updateValueAndValidity();
            }
        }
    }

    customerSelected($event) {
        if ($event != null) {
            this.selectedCustomer = $event.originalObject;
            this.getFormControl('email').setValue(this.selectedCustomer.Email !== null ? this.selectedCustomer.Email : '');
        } else {
            this.selectedCustomer = null;
        }
    }

    navigateToBack() {
        this.router.navigateByUrl('/documentation');
    }

    changeSelection() {
        this.selectedProducts = this.products.filter((value: any) => {
            return value.isChecked;
        });
    }

    save() {
        
        if(this.getFormControl('InContact').value == true)
        {
            if(this.getFormControl('contact').value.trim() ==""){
                alert("Kontakperson må fylles ut!");
                return false;
            }
        }

        if (this.customerLeadForm.valid) {
            this.submitting = true;
            const customerLead: any = {};
            customerLead.TypeOfCustomer = this.customerTypeOption;
            customerLead.PhoneNumber = this.getFormControl('phone').value;
            customerLead.Comment = this.getFormControl('comment').value;
            customerLead.EmailAddress = this.getFormControl('email').value;
            customerLead.CustomerContact = this.getFormControl('contact').value;
            customerLead.InContact =  this.getFormControl('InContact').value;
            customerLead.FormTypeId = 1;
            customerLead.Award = 1;
            customerLead.StatusId = 1;
            customerLead.CustomerName = this.getFormControl('customer').value;
            customerLead.LeadsProducts = [];
            this.selectedProducts.forEach(item => {
                customerLead.LeadsProducts.push({ LeadsProductId: 0, ProductId: item.ProductId, LeadId: 0 });
            });
            this.userService.saveCustomerLead(customerLead).subscribe(data => {
                this.submitting = false;
                this.clearFormControl(this.customerLeadForm);
                this.router.navigateByUrl('/documentation');
            }, error => {
                this.submitting = false;
            });
        }
    }
}

