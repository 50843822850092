<ion-header>
    <ion-toolbar hideBackButton="true">
        <ion-menu-toggle>
            <ion-button fill="clear">
                <ion-icon slot="icon-only" name="menu"></ion-icon>
            </ion-button>
        </ion-menu-toggle>
        <ion-title>
            <span>{{ 'Documentation.documents' | translate |uppercase }}</span>
            <logout class="hidden-xs"></logout>
        </ion-title>
    </ion-toolbar>
</ion-header>
<iframe width="100%" height="100%" frameborder="0" src="https://knapphus.sharepoint.com/:f:/g/Eru1CWtExxtJnv7NvkpWbn8B4PZpZgS6vgHUqgMbYjgOqA?e=q6kswt"></iframe>