import { Component, OnInit } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { AssignmentOrderView, InternalOrdertype } from '../../shared/enums';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import * as moment from "moment";
import { of, forkJoin } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'page-order-assignment',
    templateUrl: 'order-assignment.html',
    styleUrls: ['order-assignment.scss']
})
export class OrderAssignmentPage implements OnInit {
    currentDate: any = new Date();
    private today: any = this.currentDate;
    private yesterday: any;
    private orders: any[];
    filteredOrders: any[];
    dateFormat;
    sortProperty = '';
    sortDirection = '';
    avgiftsfrie = false;
    visKunDagens = null;
    utildelteOrdrer = true;
    prioritet = -1;
    // private userlevel: number;
    drivers: any[];
    filteredDrivers: any[];
    currentDriver: any;
    assignmentOrderView: AssignmentOrderView = AssignmentOrderView.VisOrdreListe;
    AssignmentOrderView = AssignmentOrderView;
    companies = [];
    company = '0';
    expand = false;
    constructor(
        public navCtrl: NavController, private userService: UserService,
        private translateService: TranslateService, private loadingController: LoadingController, private storage: Storage) {
        this.yesterday = Utils.addDays(this.today, -1);
    }

    ngOnInit() {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });

        this.loadData();
    }

    loadData() {
        this.translateService.get('Loading.loading').subscribe(value => {
            // let loader = this.loadingController.create({ content: value });

            // loader.present().then(() => {
            // ,this.storage.get(AppSettings.StorageKeys.Userlevel)
            // this.storage.get(AppSettings.StorageKeys.FilterUtildelteOrdrer)
            forkJoin([
                this.storage.get(AppSettings.StorageKeys.FilterAvgiftsfrie),
                this.storage.get(AppSettings.StorageKeys.FilterVisKunDagens), this.userService.getCompanies(),
                this.storage.get(AppSettings.StorageKeys.CompanyNo)]).subscribe(data => {
                    this.avgiftsfrie = data[0] == null ? false : data[0];
                    this.visKunDagens = data[1] == null ? true : data[1];
                    this.companies = data[2];
                    this.company = data[3];
                    // this.utildelteOrdrer = data[2] == null ? false : data[2];
                    // this.userlevel = data[4];
                    this.visKunDagensChanged(null);
                    this.userService.getDrivers().subscribe(drivers => {
                        this.drivers = drivers;
                    });
                    // loader.dismiss();
                });

            // });
        });
    }

    selectDriver(driver) {
        this.currentDriver = driver;
        if (this.assignmentOrderView === AssignmentOrderView.VisSjaForensOrdrer) {
            this.visKunDagensChanged(null);
        }
    }

    isOrderMine(order): boolean {
        if (order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

    changeAssignmentOrderView(assignmentOrderView: AssignmentOrderView) {
        if (assignmentOrderView === AssignmentOrderView.VisSjaForensOrdrer) {
            this.utildelteOrdrer = false;
        } else {
            this.utildelteOrdrer = true;
        }
        this.assignmentOrderView = assignmentOrderView;
        this.visKunDagensChanged(null);
    }

    tildel(order) {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.assignOrderToDriver(order.OrderNo, this.currentDriver.UserID).subscribe(data => {
                    order.AssignedDriver = this.currentDriver.UserID;
                    this.filterData();
                    loader.dismiss();
                });
            });
        });
    }

    unassignOrder(order) {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.unassignOrder(order.OrderNo).subscribe(data => {
                    order.AssignedDriver = 0;
                    this.filterData();
                    loader.dismiss();
                });
            });
        });
    }

    getDateText(order) {
        const deliveryDate = new Date(order.DeliveryDate);
        if (Utils.isSameDateAs(deliveryDate, this.today)) {
            return this.translateService.get('Ordrer.iDag');
        } else if (Utils.isSameDateAs(deliveryDate, this.yesterday)) {
            return this.translateService.get('Ordrer.iMorgen');
        }
        return this.translateService.get('General.dateFormat').pipe(switchMap(format => of(moment(deliveryDate)
            .format(format.toUpperCase()))));

    }

    filterChanged($event) {
        this.filterData();
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    visKunDagensChanged($event) {
        if (this.assignmentOrderView === AssignmentOrderView.VisOrdreListe || (this.currentDriver &&
            this.assignmentOrderView === AssignmentOrderView.VisSjaForensOrdrer)) {
            this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });

                loader.present().then(() => {
                    this.userService.getOrders(this.visKunDagens ? this.currentDate : null, this.currentDriver ?
                        this.currentDriver.UserID : AppSettings.Username,
                        this.assignmentOrderView === AssignmentOrderView.VisSjaForensOrdrer, InternalOrdertype.Bulk, +this.company)
                        .subscribe(data => {
                            for (const item of data) {
                                item.expandedView = false;
                            }
                            this.orders = data;
                            this.storage.set(AppSettings.StorageKeys.CompanyNo, this.company);
                            this.filterData();
                            loader.dismiss();
                        });
                });
            });
        } else {
            this.orders = null;
        }
    }

    filterData() {
        this.filteredOrders = _.filter(this.orders, (order) => {
            let value = true;

            if (this.assignmentOrderView === AssignmentOrderView.VisOrdreListe && this.utildelteOrdrer === false) {
                value = value && (order.AssignedDriver === +AppSettings.Username);
            } else if (this.assignmentOrderView === AssignmentOrderView.VisOrdreListe && this.utildelteOrdrer === true) {
                value = value && (order.AssignedDriver === 0);
            } else if (this.assignmentOrderView === AssignmentOrderView.VisSjaForensOrdrer) {
                value = value && (order.AssignedDriver === +this.currentDriver.UserID);
            }
            // else if (this.assignmentOrderView == AssignmentOrderView.VisSjaForensOrdrer && this.utildelteOrdrer == true) {
            //    value = value && (order.AssignedDriver == this.currentDriver.UserID || order.AssignedDriver == 0);
            // }


            if (this.avgiftsfrie === true) {
                value = value && (order.ExVatOrder === true);
            }

            if (!value) {
                return value;
            }
            if (this.prioritet > -1) {
                value = value && (order.IsUrgent === (this.prioritet === 1 ? true : false));
            }

            // if (this.assignmentOrderView == AssignmentOrderView.VisOrdreListe) {
            //    value = value && (order.AssignedDriver == 0);
            // }
            // else {
            //    value = value && (order.AssignedDriver == this.currentDriver.UserID);
            // }
            return value;
        });

        // save for furture use

        this.storage.set(AppSettings.StorageKeys.FilterAvgiftsfrie, this.avgiftsfrie);
        this.storage.set(AppSettings.StorageKeys.FilterVisKunDagens, this.visKunDagens);
        // this.storage.set(AppSettings.StorageKeys.FilterUtildelteOrdrer, this.utildelteOrdrer);
        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);

        // filter drivers list
        this.filteredDrivers = _.filter(this.drivers, (driver) => {
            let value = true;

            if (this.company !== '0') {
                if (driver.CompanyNo !== undefined) {
                    value = value && this.company.toString() === driver.CompanyNo.toString();
                }
            }
            return value;
        });

    }

    sort(property) {

        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }

        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.loadData();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.loadData();
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }
}
