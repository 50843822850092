import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from '../app/pages/home/home';
import { BeholdningPage } from '../app/pages/beholdning/beholdning';
import { EgneTankerAutomaterPage } from '../app/pages/egne-tanker-automater/egne-tanker-automater';
import { OrdrerPage } from '../app/pages/ordrer/ordrer';
import { OrdrerSalesPage } from '../app/pages/ordrer-sales/ordrer-sales';
import { WorkorderApprovalPage } from '../app/pages/workorder-approval/workorder-approval';
import { OrdreDetailsPage } from '../app/pages/ordre-details/ordre-details';
import { OrdreDetailsSalesPage } from '../app/pages/ordre-details-sales/ordre-details-sales';
import { LoginPage } from '../app/pages/login/login';
import { NewOrderPage } from '../app/pages/new-order/new-order';
import { NewOrderSalesPage } from '../app/pages/new-order-sales/new-order-sales';
import { EgneTankerSelectPage } from '../app/pages/egne-tanker-select/egne-tanker-select';
import { OrderAssignmentPage } from '../app/pages/order-assignment/order-assignment';
import { OrderplannerPage } from '../app/pages/order-planner/order-planner';
import { SettingsPage } from '../app/pages/settings/settings';
import { ActivityRegistrationPage } from '../app/pages/activity-registration/activity-registration';
import { TransactionLogPage } from '../app/pages/transaction-log/transaction-log';
import { ActivityRegistrationMultiplePage } from '../app/pages/activity-registration-multiple/activity-registration-multiple';
import { ReleaseNotesPage } from '../app/pages/release-notes/release-notes';
import { TankMonitoringPage } from '../app/pages/tank-monitoring/tank-monitoring';
import { TankMonitoringPage2 } from '../app/pages/tank-monitoring2/tank-monitoring2';
import { AuthService } from '../app/shared/auth-service';
import { StockGoodsPage } from './pages/stock-goods/stock-goods';
import { CustomerCreatePage } from '../app/pages/customer-create/customer-create';
import { WorkOrdersPage } from '../app/pages/work-orders/work-orders';
import { WorkOrdersDocumentsPage } from '../app/pages/work-orders-documents/work-orders-documents';
import { WorkOrderDetailsPage } from '../app/pages/work-order-details/work-order-details';
import { WorkNewOrderPage } from '../app/pages/work-new-order/work-new-order';
import { WorkOrderHistoryPage } from '../app/pages/work-order-history/work-order-history';
import { DocumentationPage } from '../app/pages/documentation/documentation';
import { NewDeviationPage } from '../app/pages/new-deviation/new-deviation';
import { CustomerLeadPage } from '../app/pages/customer-lead/customer-lead';
import { OrdrersProductPage } from '../app/pages/orders-product/orders-product';
import { OrdreDetailsProductPage } from '../app/pages/ordre-details-product/ordre-details-product';
import { NewOrderProductPage } from '../app/pages/new-order-product/new-order-product';
import { DocumentsPage } from '../app/pages/documents/documents';
import { FleetPage } from '../app/pages/fleet/fleet';
import { LeadViewPage } from '../app/pages/lead-view/lead-view';
import { FormsHandlingPage } from '../app/pages/forms-handling/forms-handling';
import { ChecklistMonthlyControlPage } from '../app/pages/checklist-monthly-control/checklist-monthly-control';
import { ChecklistViewPage } from '../app/pages/checklist-view/checklist-view';
import { SooloTanklevelsPage } from './pages/soolo-tanklevels/soolo-tanklevels';
import { TankMonitoringSelectPage } from '../app/pages/tank-monitoring-select/tank-monitoring-select';
import { DynamicFormPage } from './pages/dynamic-form/dynamic-form';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePage, canActivate: [AuthService] },
  { path: 'beholdning', component: BeholdningPage, canActivate: [AuthService] },
  { path: 'egne-tanker-automater', component: EgneTankerAutomaterPage, canActivate: [AuthService] },
  { path: 'orders', component: OrdrerPage, canActivate: [AuthService] },
  { path: 'orders-sales', component: OrdrerSalesPage, canActivate: [AuthService] },
  { path: 'order-details', component: OrdreDetailsPage, canActivate: [AuthService] },
  { path: 'order-details-sales', component: OrdreDetailsSalesPage, canActivate: [AuthService] },
  { path: 'login', component: LoginPage },
  { path: 'new-order', component: NewOrderPage, canActivate: [AuthService] },
  { path: 'new-order-sales', component: NewOrderSalesPage, canActivate: [AuthService] },
  { path: 'egne-tanker-select', component: EgneTankerSelectPage, canActivate: [AuthService] },
  { path: 'order-assignment', component: OrderAssignmentPage, canActivate: [AuthService] },
  { path: 'order-planner', component: OrderplannerPage, canActivate: [AuthService] },
  { path: 'settings', component: SettingsPage, canActivate: [AuthService] },
  { path: 'activity-registration', component: ActivityRegistrationPage, canActivate: [AuthService] },
  { path: 'transaction-log', component: TransactionLogPage, canActivate: [AuthService] },
  { path: 'activity-registration-multiple', component: ActivityRegistrationMultiplePage, canActivate: [AuthService] },
  { path: 'release-notes', component: ReleaseNotesPage, canActivate: [AuthService] },
  { path: 'tank-monitoring', component: TankMonitoringPage, canActivate: [AuthService] },
  { path: 'tank-monitoring2', component: TankMonitoringPage2, canActivate: [AuthService] },
  { path: 'stock-goods', component: StockGoodsPage, canActivate: [AuthService] },
  { path: 'new-customer', component: CustomerCreatePage, canActivate: [AuthService] },
  { path: 'work-orders', component: WorkOrdersPage, canActivate: [AuthService] },
  { path: 'work-orders-documents', component: WorkOrdersDocumentsPage, canActivate: [AuthService] },
  { path: 'work-order-details', component: WorkOrderDetailsPage, canActivate: [AuthService] },
  { path: 'work-new-order', component: WorkNewOrderPage, canActivate: [AuthService] },
  { path: 'work-order-history', component: WorkOrderHistoryPage, canActivate: [AuthService] },
    { path: 'workorder-approval', component: WorkorderApprovalPage, canActivate: [AuthService] },
  { path: 'documentation', component: DocumentationPage, canActivate: [AuthService] },
  { path: 'new-deviation', component: NewDeviationPage, canActivate: [AuthService] },
  { path: 'customer-lead', component: CustomerLeadPage, canActivate: [AuthService] },
  { path: 'orders-product', component: OrdrersProductPage, canActivate: [AuthService] },
  { path: 'order-details-product', component: OrdreDetailsProductPage, canActivate: [AuthService] },
  { path: 'new-order-product', component: NewOrderProductPage, canActivate: [AuthService] },
  { path: 'documents', component: DocumentsPage, canActivate: [AuthService] },
  { path: 'fleet', component: FleetPage, canActivate: [AuthService] },
  { path: 'lead-view', component: LeadViewPage, canActivate: [AuthService] },
  { path: 'checklist-view', component: ChecklistViewPage, canActivate: [AuthService] },
  { path: 'forms-handling', component: FormsHandlingPage, canActivate: [AuthService] },
  { path: 'forms-handling', component: FormsHandlingPage, canActivate: [AuthService] },
  { path: 'checklist-monthly-control', component: ChecklistMonthlyControlPage, canActivate: [AuthService] },
  { path: 'tank-monitoring-select', component: TankMonitoringSelectPage, canActivate: [AuthService] },
   { path: 'dynamic-form', component: DynamicFormPage, canActivate: [AuthService] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
