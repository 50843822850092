<ion-content class="ion-padding">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-text color="primary">
          <h6>
            <ion-icon name="add-circle-outline"></ion-icon> {{
            'OrderlineSplit.title' | translate }}
          </h6>
        </ion-text>
        <p class="description">
          {{description}} <span class="m-t-5"><p>{{description2}}</p></span>
        </p>
        <ion-list lines="none">
          <form [formGroup]="convertProductForm">
            <ion-item>
              <ion-grid>
                <ion-row
                  *ngFor="let orderLine of order.Orderlines"
                  formGroupName="orderLines"
                >
                  <ion-col size="6" class="litte-big-font-2">
                    <ion-badge [style.background]="orderLine.ProductColorCode"
                      >{{orderLine.ProductShortCode}}</ion-badge
                    >
                    {{orderLine.Description}}
                  </ion-col>
                  <ion-col size="2" class="litte-big-font-2">
                    {{orderLine.Quantity}}
                  </ion-col>
                  <ion-col size="2">
                    <input
                      type="number"
                      appRemoveZeroOnFocus
                      class="border-input ion-no-margin"
                      formControlName="{{'o' + orderLine.LineNo}}"
                      ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                      step="any"
                    />
                  </ion-col>
                  <ion-col size="2" class="litte-big-font-2 ion-text-right">
                    {{ orderLine.Quantity - formValue('o'+orderLine.LineNo) }}
                  </ion-col>
                  <!--[(ngModel)]="orderLine.QuantityDelivered"-->
                </ion-row>
              </ion-grid>
            </ion-item>
            <ion-item class="ion-float-right ion-margin-top">
              <ion-buttons>
                <ion-button
                  (click)="dismiss()"
                  color="grey"
                  fill="outline"
                  size="small"
                >
                  <span>{{ 'OrderlineSplit.cancel' | translate }}</span>
                </ion-button>
                <ion-button
                  (click)="addProduct()"
                  [disabled]="!convertProductForm.valid"
                  fill="solid"
                  size="small"
                  disabled="true"
                >
                  <span>{{ 'OrderlineSplit.save' | translate }}</span>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
