import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
    selector: 'beholdning-lagre-confirm',
    templateUrl: 'beholdning-lagre-confirm.html',
    styleUrls: ['beholdning-lagre-confirm.scss']
})
export class BeholdningLagreConfirmComponent {
    public inventoryList: any[];

    constructor(public navParams: NavParams, public modalCtrl: ModalController) {
        this.inventoryList = this.navParams.data.inventoryList;
    }

    dismiss(flag) {
        this.modalCtrl.dismiss(flag);
    }
}
