import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from './app-settings';
import { timeout } from 'rxjs/operators';

@Injectable()
export class BaseHttpClient {
    constructor(private http: HttpClient) { }

    createAuthorizationHeader(headers: HttpHeaders) {
        if (AppSettings.Token != null) {
            headers.append('Authorization', 'Bearer ' + AppSettings.Token);
        }
    }

    get(url) {
        return this.http.get(url).pipe(timeout(60000));
    }

    post(url, data) {
        return this.http.post(url, data).pipe(timeout(60000));
    }
}
