<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'LeadView.title' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px;">
    <ion-grid>
      <ion-row class="ion-no-padding" class="deviation">
        <ion-col size-xs="12" size-md="6" class="ion-no-padding">
          <ion-list lines="none">
            <ion-item class="text-small">
              <ion-label class="ion-no-margin">
                <p class="m-b-12">
                  <ion-icon name="alert-circle" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.award' | translate }}</span></ion-text>
                </p>
                <span class="m-l-24"><strong>{{lead.Award}}p</strong></span>
                <select [(ngModel)]="lead.Award" class="award" *ngIf="userlevel <= 1">
                  <option *ngFor="let item of awards" value="{{item}}">{{item}}</option>
                </select>
              </ion-label>
            </ion-item>
            <ion-item class="text-small">
              <ion-label class="ion-no-margin">
                <p class="m-b-12">
                  <ion-icon name="person" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.assignedUser' | translate }}</span></ion-text>
                </p>
                <select [(ngModel)]="lead.AssignedUserId" class="award">
                  <option value="null">{{ 'General.select' | translate }}</option>
                  <option *ngFor="let item of drivers" value="{{item.UserID}}">{{item.Name}}</option>
                </select>
              </ion-label>
            </ion-item>
            <ion-item class="text-small">
                <ion-label class="ion-no-margin">
                  <p class="m-b-12">
                    <ion-icon name="alert-circle" color="lightFive"></ion-icon>&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'LeadView.status' | translate }}</span></ion-text>
                  </p>
                  <select [(ngModel)]="lead.StatusId" class="award">
                    <option *ngFor="let item of statuses" value="{{item.StatusId}}">{{item.StatusName}}</option>
                  </select>
                </ion-label>
              </ion-item>
            <ion-item class="text-small z-index">
              <ion-label class="ion-no-margin">
                <p class="m-b-12">
                  <ion-icon name="person" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.contactInfo' | translate }}</span></ion-text>
                </p>
                <div class="m-l-24">
                  <p>{{lead.TypeOfCustomer}}</p><br>
                  <p class="mt-2"><b>{{lead.CustomerName}}</b></p>
                  <p>{{lead.CustomerContact}}</p>
                  <p>{{lead.PhoneNumber}}</p>
                  <p>{{lead.EmailAddress}}</p>
                  <p class="mt-2" *ngIf="lead.InContact"><br> {{'CustomerLead.InContact' | translate }} <b>{{lead.InContact}}</b></p>
                </div>
              </ion-label>
            </ion-item>
            <ion-item class="text-small m-t-16 ion-no-margin">
              <ion-label class="ion-no-margin">
                <p>
                  <ion-icon name="checkbox" class="vertical-text-bottom" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.productType' | translate }}</span></ion-text>
                </p>
              </ion-label>
            </ion-item>
            <ion-item class="m-l-24 checkbox text-small" *ngFor="let item of selectedProducts">
              <ion-label>
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                &nbsp;{{ 'LeadView.' +item.Name | translate }}</ion-label>
            
            </ion-item>
            <!--ion-item class="text-small">
              <ion-label>
                <p class="m-b-12">
                  <ion-icon name="chatbubbles" class="vertical-middle" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.comment' | translate | uppercase }}</span></ion-text>
                </p>
                <p class="m-l-24">{{lead.Comment}}</p>
              </ion-label>
            </ion-item-->
          </ion-list>
        </ion-col>
        <ion-col size-xs="12" size-md="6" class="ion-no-padding">
          <ion-list lines="none">
            <ion-item class="text-small set-height wrap-text">
              <ion-label class="wrap-text">
                <p class="m-b-12">
                  <ion-icon name="chatbubbles" class="vertical-middle" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.meldinger' | translate | uppercase }}</span></ion-text>
                </p>
                <div class="m-l-24 message" *ngFor="let item of lead.Messages">
                  <strong>
                    {{item.MessageCreationDateTime | date:dateFormat}}&nbsp;&nbsp;{{item.NameOfMessagePublisher}}
                  </strong>
                  <p>{{item.Message}}</p>
                  <hr />
                </div>
              </ion-label>
            </ion-item>
            <ion-item class="text-small bottom">
              <ion-label>
                <p class="m-b-12">
                  <ion-icon name="chatbubbles" class="vertical-middle" color="lightFive"></ion-icon>&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'LeadView.nyMelding' | translate | uppercase }}</span></ion-text>
                </p>
                <div class="m-l-24 message">
                  <div>
                    <textarea rows="3" class="width margin-top-10" [(ngModel)]="message"></textarea>
                  </div>
                  <ion-button class="pull-right" (click)="addMessage()" [disabled]="message==''">
                    <ion-icon name="add-circle"></ion-icon>&nbsp; <span>{{ 'LeadView.addMessage' |
                            translate }}</span>
                  </ion-button>
                </div>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div>
      <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'LeadView.back' | translate }}</span>
      </ion-button>
      <ion-button (click)="saveLead()" class="pull-right" *ngIf="userlevel <= 1">
        <ion-icon name="save-outline"></ion-icon>&nbsp;
        {{ 'General.save' | translate }}
      </ion-button>
    </div>
  </div>
</ion-footer>