<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Documentation.title' | translate |uppercase }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <ion-row class="full-height documentation">
        <ion-col size-md="6" offset-md="3" class="ion-text-center">
          <h3 class="m-t-100">{{ 'Documentation.register' | translate }}</h3>
          <ion-list lines="none" class="ion-no-padding">
            <ion-item>
              <ion-button
                [fill]="documentationTypeOption === documentationType.Deviation?'solid': 'outline'"
                (click)="setOption(documentationType.Deviation)"
                size="default"
                expand="block"
                class="ion-margin-bottom ion-margin-top"
              >
                {{'Documentation.deviation' | translate }}</ion-button
              >
            </ion-item>

            <ion-item>
              <ion-button
                [fill]="documentationTypeOption === documentationType.CustomerLead?'solid': 'outline'"
                (click)="setOption(documentationType.CustomerLead)"
                size="default"
                expand="block"
                class="ion-margin-bottom"
              >
                {{'Documentation.customerLead' | translate }}</ion-button
              >
            </ion-item>

            <ion-item>
              <ion-button
                [fill]="documentationTypeOption === documentationType.DailyChecklist?'solid': 'outline'"
                (click)="setOption(documentationType.DailyChecklist)"
                size="default"
                expand="block"
                class="ion-margin-bottom"
              >
                {{'Documentation.dailyCheckList' | translate }}</ion-button
              >
              <ion-button
                [fill]="documentationTypeOption === documentationType.MonthlyChecklist?'solid': 'outline'"
                (click)="setOption(documentationType.MonthlyChecklist)"
                size="default"
                expand="block"
                class="ion-margin-bottom"
              >
                {{'Documentation.monthlyCheckList' | translate }}</ion-button
              >
            </ion-item>
          </ion-list>
          <br /><br />

          <ion-button
            fill="outline"
            color="primary"
            onclick="window.open('https://knapphus.sharepoint.com/:f:/g/Eru1CWtExxtJnv7NvkpWbn8B4PZpZgS6vgHUqgMbYjgOqA?e=JnNfpT', '_system', 'location=yes'); return false;"
            ><ion-icon name="cloud"></ion-icon>&nbsp;{{
            'Documentation.documents' | translate | uppercase }}</ion-button
          >
          <ion-button
            fill="outline"
            color="primary"
            [routerLink]="['/forms-handling']"
            ><ion-icon name="clipboard"></ion-icon>&nbsp;{{ 'Menu.formsADM' |
            translate | uppercase }}</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
