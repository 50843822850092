import { Component, } from '@angular/core';
@Component({
    selector: 'page-documents',
    templateUrl: 'documents.html',
    styleUrls: ['documents.scss'],
})

export class DocumentsPage {
    constructor() {
    }
}
