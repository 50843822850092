<div class="slight-big-font">
    <b>{{order.OrderNo}}</b> <div class="circle" [class.red-circle]="order.IsUrgent" [class.grey-circle]="!order.IsUrgent"></div> <span [innerText]="getDateText(order) | async"></span>
    <br>
    <b>{{order.DeliveryName}}</b>
    <br>Address:{{order.DeliveryAddress1}} {{order.DeliveryPostalCode}} {{order.DeliveryCity}}<br> 
    <span ion-text color="danger" class="small-font" *ngIf="order.InternalInfo">
        {{order.InternalInfo}}<br/>
    </span>
    <span *ngFor="let orderLine of order.Orderlines">
        <ion-badge [style.background]="orderLine.ProductColorCode">{{orderLine.ProductShortCode}}</ion-badge> {{orderLine.Quantity}}
    </span><br/>
    <ion-button (click)="plukkOrApne(order)" color="secondary" class="plukk-apne pull-right">
        <ion-icon name="checkbox-outline"></ion-icon> &nbsp; <ng-container *ngIf="order.AssignedDriver == 0">{{ 'Ordrer.plukk' | translate }}</ng-container>
        <ng-container *ngIf="order.AssignedDriver != 0">{{ 'Ordrer.apne' | translate }}</ng-container>
    </ion-button>
    <div class="clear"></div>
</div>