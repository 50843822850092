import { Component } from '@angular/core';
import { UserService } from '../../shared/user-service';
import _ from 'lodash';
import { Router, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { AppSettings } from 'src/app/shared/app-settings';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { LoadingController } from '@ionic/angular';
import { FormSchemaService  } from '../../services/form-schema.service';
import { ToastController } from '@ionic/angular';
@Component({
    selector: 'page-forms-handling',
    templateUrl: 'forms-handling.html',
    styleUrls: ['forms-handling.scss']
})
export class FormsHandlingPage {
    dateFormat;
    forms = [];
    deviationForms = [];
    lead = true;
    checkList = true;
    deviationList = true;
    search = '';
    userlevel = 0;
    config = {
        itemsPerPage: 100,
        currentPage: 1,
        totalItems: 0
    };
    statuses: any;
    status = null;
    drivers = [];
    tabView = "avvik";
    showAvvik = true;
    showLeads = false;
    formResponses: any[] = [];
    filteredForms: any[];
    searchQuery: any;


    constructor(
        private userService: UserService, private router: Router, private formResponseService:FormSchemaService, private toastController:ToastController,
        private translateService: TranslateService, private storage: Storage,private loadingController: LoadingController) {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        forkJoin([this.storage.get(AppSettings.StorageKeys.Userlevel),this.userService.getDrivers()]).subscribe((data: any) => {
                this.userlevel = data[0];
                this.drivers = data[1];
            });
        this.getStatuses();           
    }

    ionViewDidEnter() {

        this.getForms();
    }

    tabViewChange($event)
    {
        this.tabView = ($event.detail.value);
        
        if(this.tabView == 'checklist')
            this.loadFormResponses();
        
       
    }
      tabViewHidden(item:string)
    {
     
        return (item==this.tabView)?false:true;
    }

    
    getForms() {

          this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });

                loader.present().then(() => {


        this.userService.getForms(this.config.currentPage - 1, this.config.itemsPerPage,
            this.search, this.lead, this.checkList,this.status).subscribe(data => {
                this.forms = data.Forms;
                this.config.totalItems = data.TotalNumberOfResults;
            });

            this.userService.getDeviationForms(this.config.currentPage - 1, this.config.itemsPerPage,
            this.search, this.status).subscribe(data => {
                this.deviationForms = data;
                loader.dismiss();
               // this.config.totalItems = data.TotalNumberOfResults;
            });
            
        });
        
    });
    }

    

    pageChanged(event) {
        this.config.currentPage = event;
        this.getForms();
    }

    openLead(form) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                lead: JSON.stringify(form.Leads[0])
            },
            skipLocationChange: true
        };
        this.router.navigate(['/lead-view'], navigationExtras);
    }

    isAssignedTo(lead)
    {
        if(lead != undefined)
            if(lead.AssignedUserId != null && lead.AssignedUserId != undefined) {return true; } else {return false;}
            else
            return false
    }


    filterEvents() {
        this.config.currentPage = 1;
        this.getForms();
    }

    openChecklist(form) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                form: JSON.stringify(form)
            },
            skipLocationChange: true
        };
        this.router.navigate(['/checklist-view'], navigationExtras);
    }

    getStatuses() {
        this.userService.getStatuses().subscribe(data => {
            this.statuses = data;
        });
    }

    getAssignedName(driverID)
    {
     
        var nm = "";
      for(var i=0;i<this.drivers.length-1;i++)
      {
            if(this.drivers[i].UserID == driverID)
            nm = this.drivers[i].Name;

      }
      return nm;
    }

    
  
  loadFormResponses() {
    this.formResponseService.getAllForms().subscribe(responses => {
      this.formResponses = JSON.parse(responses.toString());
      this.filteredForms = this.formResponses;
    });
  }


  filterForms() {

    if(this.searchQuery.length>=2){
    this.filteredForms = this.formResponses.filter(form =>
      this.tabView === 'checklist' && 
      (form.FormType.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
        form.FormObject.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
       form.CreatedBy.toLowerCase().includes(this.searchQuery.toLowerCase())
       )
    );
}
else{this.loadFormResponses();}
  }


 downloadPdf(formId: number): void {
    this.formResponseService.exportReport(formId).subscribe(response => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Report_' + formId + '.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  
  }
}
