import { Component } from '@angular/core';
import { UserService } from '../../shared/user-service';
import { Utils } from '../../shared/utils';
import { AppSettings } from '../../shared/app-settings';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NavController, LoadingController } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';

@Component({
    selector: 'map-marker',
    templateUrl: 'map-marker.html',
    styleUrls: ['map-marker.scss']
})
export class MapMarkerComponent {

    public order: any;
    private today: any = new Date();
    private yesterday: any;

    constructor(
        public navCtrl: NavController, private translateService: TranslateService,
        private userService: UserService, private loadingController: LoadingController, private router: Router) {

        this.yesterday = Utils.addDays(this.today, -1);
    }

    getDateText(order) {
        const deliveryDate = new Date(order.DeliveryDate);
        if (Utils.isSameDateAs(deliveryDate, this.today)) {
            return this.translateService.get('Ordrer.iDag');
        } else if (Utils.isSameDateAs(deliveryDate, this.yesterday)) {
            return this.translateService.get('Ordrer.iMorgen');
        }
        return this.translateService.get('General.dateFormat').pipe(switchMap(format => of(moment(deliveryDate).
            format(format.toUpperCase()))));
    }

    plukkOrApne(orderData) {
        if (orderData.AssignedDriver === 0) {
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.assignOrderToDriver(orderData.OrderNo, null).subscribe(data => {
                        orderData.AssignedDriver = AppSettings.Username;
                        loader.dismiss();
                    });
                });
            });
        } else {
            // navigate
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    order: JSON.stringify(orderData),
                },
                skipLocationChange: true
            };

            this.router.navigate(['/order-details'], navigationExtras);

        }
    }
}
