import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { NavController, LoadingController, ToastController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { CustomValidators } from 'ngx-custom-validators';


import _ from 'lodash';
import { Modules } from '../../shared/enums';
@Component({
    selector: 'page-settings',
    templateUrl: 'settings.html',
    styleUrls: ['settings.scss']
})
export class SettingsPage implements OnInit {

    changePassForm: FormGroup;
    fontSettings: any;
    version = AppSettings.appVersion;
    pages: any = [];
    menuItems: any;
    startupViewSettings: any;

    constructor(
        public router: Router, public navCtrl: NavController, private translateService: TranslateService,
        private toastController: ToastController, private userService: UserService, private formBuilder: FormBuilder,
        private alertController: AlertController, private storage: Storage, private loadingController: LoadingController,
        public platform: Platform) {
    }

    ionViewDidEnter() {
        this.loadData();
    }


    loadData() {
        this.pages = [
            {
              title: '', moduleID: Modules.Home, hide: false, url: 'home'
            },
            {
              title: '', moduleID: Modules.Inventory, hide: false, url: 'beholdning'
            },
            {
              title: '', moduleID: Modules.Orders, hide: false, url: 'orders'
            },
            {
              title: '', moduleID: Modules.WorkOrder, hide: false, url: 'work-orders'
            },
            {
              title: '', moduleID: Modules.OrdersProduct, hide: false, url: 'orders-product'
            },
            {
              title: '', moduleID: Modules.InternalOrders, hide: false, url: 'egne-tanker-select'
            },
            {
              title: '', moduleID: Modules.TankMonitoring, hide: false, url: 'tank-monitoring-select'
            },
            {
              title: '', moduleID: Modules.Activity, hide: true, url: 'activity-registration'
            },
            {
              title: '', moduleID: Modules.ActivityNew, hide: false,
              url: 'activity-registration-multiple'
            },
            {
              title: '', moduleID: Modules.Documentation, hide: false, url: 'documentation'
            },
            {
              title: '', moduleID: Modules.StockGoods, hide: false, url: 'stock-goods'
            },
            {
              title: '', moduleID: Modules.Settings, hide: false, url: 'settings'
            },
            {
               title: '', moduleID: Modules.SooloOrders, hide: false, url: 'fleet'
            },
            {
                title: '', moduleID: Modules.Leads, hide: false, url: 'forms-handling'
            }
          ];
        this.translateService.get('Menu.oversikt').subscribe(data => this.pages[0].title = data);
        this.translateService.get('Menu.beholdning').subscribe(data => this.pages[1].title = data);
        this.translateService.get('Menu.ordrer').subscribe(data => this.pages[2].title = data);
        this.translateService.get('Menu.workOrder').subscribe(data => this.pages[3].title = data);
        this.translateService.get('Menu.ordersProduct').subscribe(data => this.pages[4].title = data);
        this.translateService.get('Menu.egneTankerAutomater').subscribe(data => this.pages[5].title = data);
        this.translateService.get('Menu.tankpeiling').subscribe(data => this.pages[6].title = data);
        this.translateService.get('Menu.timer').subscribe(data => this.pages[7].title = data);
        this.translateService.get('Menu.timer').subscribe(data => this.pages[8].title = data);
        this.translateService.get('Menu.documentation').subscribe(data => this.pages[9].title = data);
        this.translateService.get('Menu.stockGoods').subscribe(data => this.pages[10].title = data);
        this.translateService.get('Menu.settings').subscribe(data => this.pages[11].title = data);
        this.translateService.get('Menu.sooloOrders').subscribe(data => this.pages[12].title = data);
        this.translateService.get('Menu.formsADM').subscribe(data => this.pages[13].title = data);
        if (this.userService.moduleAccess.getValue() != null) {
            this.pages.forEach(p => {
                this.userService.moduleAccess.getValue().forEach(m => {
                if (p.moduleID === m.Module) {
                  p.hide = m.HideModule;
                }
              });
            });
          }
        const pages = _.filter(this.pages, (page) => {
                if (page.hide === false) {
                return page;
                }
            });
        this.menuItems = pages;
    }

    ngOnInit() {
        const password = new FormControl('', Validators.required);
        this.changePassForm = this.formBuilder.group({
            password,
            confirmPassword: ['', Validators.compose([CustomValidators.equalTo(password)])]
        });
        this.fontSettings = this.userService.fontSettings;
        this.startupViewSettings = this.userService.startupViewSettings;
    }

    checkVersion() {

      

    }

    savestartupViewSettings() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.events.next(this.startupViewSettings);
                this.userService.saveStartupViewSettings(this.startupViewSettings);
                loader.dismiss();
            });
        });
    }

    setPassword() {
        if (this.changePassForm.valid) {
            const password = this.changePassForm.get('password').value;

            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });

                loader.present().then(() => {
                    this.userService.setPassword({ Password: password }).subscribe(data => {
                        loader.dismiss();
                        if (data) {
                            this.translateService.get('Settings.passwordChanged').subscribe(passwordChanged => {
                                this.translateService.get('buttons.ok').subscribe(ok => {
                                    this.alertController.create({
                                        subHeader: passwordChanged,
                                        buttons: [{
                                            text: ok,
                                            role: 'cancel',
                                            handler: () => {

                                                this.storage.remove(AppSettings.StorageKeys.Token).then(token => {
                                                    this.userService.clearCache();
                                                    this.router.navigate(['/login']);
                                                    this.userService.events.next('login');
                                                });

                                            }
                                        }]
                                    }).then(alertt => { alertt.present(); });


                                });
                            });
                        } else {
                            this.translateService.get('Settings.errorSavePassword').subscribe(async errorSavePassword => {

                                this.toastController.create({
                                    message: value,
                                    duration: 3000
                                }).then(toast => { toast.present(); });

                            });
                        }
                    }, error => {
                        loader.dismiss();
                        this.toastController.create({
                            message: error.statusText,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
                });
            });
        }
    }

    radioChecked() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.saveFontSettings(this.fontSettings);
                loader.dismiss();
            });
        });
    }
}
