import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ModalController, LoadingController, NavParams, IonInput, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressData } from '../../shared/address-data';
import { CustomerData } from '../../shared/customer-data';
import { AppSettings } from '../../shared/app-settings';
import { Geolocation } from '@capacitor/geolocation';
import { Observable, debounceTime, filter, map, of, startWith, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomerSearchComponent } from '../customer-search/customer-search/customer-search.component';
import { CustomerAddressSearchComponent } from '../customer-address-search/customer-address-search/customer-address-search.component';
@Component({
    selector: 'edit-order-shared',
    templateUrl: 'edit-order-shared.html',
    styleUrls: ['edit-order-shared.scss']
})
export class EditOrderSharedComponent implements AfterViewInit {
    detailsForm: FormGroup;
    order: any;
    customerDataService;
    addressDataService: AddressData;
    customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;

    addressCtrl = new FormControl();
    filteredAddresses: Observable<any[]>;
  
 latLongPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    
    customerModal: boolean = false;

    customerAddressModal: boolean = false;

    mapLat: number = 0.0;
    mapLong: number = 0.0;
    

    @ViewChild('customerNameInput', { static: false }) customerNameInput: IonInput;
    @ViewChild('addressInput', { static: false }) addressInput: IonInput;

    private addressID = 0;
    private customerNo = 0;
    selectedCustomer: any;
    priceAgreements: any;
    private currentLatitude: number = null;
    private currentLongitude: number = null;
    protected saveText = "";

    constructor(
        public modalCtrl: ModalController, private userService: UserService, private translateService: TranslateService,private toastController: ToastController,
        private loadingController: LoadingController, private formBuilder: FormBuilder, public navParams: NavParams,
        private http: HttpClient, private geolocation: Geolocation, ) {
        this.order = this.navParams.data.order;
        this.addressID = this.order.DeliveryActorNo;
        this.customerNo = this.order.CustomerID;
        this.detailsForm = formBuilder.group({
            customerName: [this.order.CustomerName, Validators.compose([Validators.required])],
            customerAddress1: [this.order.CustomerAddress1],
            customerAddress2: [this.order.CustomerAddress2],
            customerPostalCode: [this.order.CustomerPostalCode],
            priceAgreementID: [this.order.PriceAgreementID !== 0 ? this.order.PriceAgreementID : ''],
            customerCity: [this.order.CustomerCity],
            address: [this.order.DeliveryName],
            addressAddress1: [this.order.DeliveryAddress1],
            addressAddress2: [this.order.DeliveryAddress2],
            addressZip: [this.order.DeliveryPostalCode],
            addressCity: [this.order.DeliveryCity]
        });
        const posOptions = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 30000
        };
  
        this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
        this.customerDataService = new CustomerData(http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
        this.addressDataService = new AddressData(http, 0, `${AppSettings.API_ENDPOINT}/User/AddressSearch/?query=`, true);
    }

    
    getFormControl(field) {
        return this.detailsForm.get(field);
    }

    ngAfterViewInit() {
       
    }

      ngOnInit() {
       
        this.getCurrentPosition();
        this.filteredCustomers = this.customerCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(name => name ? this._filterCustomers(name) : of([])) // Use switchMap instead of map
            );

        this.filteredAddresses = this.addressCtrl.valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            switchMap(name => name ? this._filterAddresses(name) : this.addressDataService.addressList.slice())
        );



    }

    displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

    private _filterAddresses(value: string): Observable<any[]> {
    return this.addressDataService.search(value);
  }

 

  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    } catch (error) {
      //this.showGeolocationError();
    }
  }


     invokeEvent(data) {
         this.addressID = 0;
         const place = data;
         if (place.formatted_address !== '' && place.formatted_address !== null || place.name !== '' && place.name !== null) {
             const components: any = {};
             place.address_components.map((value, index) => {
                 value.types.map((value2, index2) => {
                     components[value2] = value.long_name;
                     if (value2 === 'country') {
                         components.country_id = value.short_name;
                     }
                     if (value2 === 'administrative_area_level_1') {
                         components.state_code = value.short_name;
                     }
                 });
             });
             let address1 = typeof components.route !== 'undefined' ? components.route + ' '  : '' ;
             address1 += typeof components.street_number !== 'undefined' ? components.street_number + ' '  : '';
             if (place.name.trim().toLowerCase() !== address1.trim().toLowerCase()) {
                this.getFormControl('address').setValue(place.name);
            } else {
                this.getFormControl('address').setValue(this.getFormControl('customerName').value);
            }

             this.getFormControl('addressAddress1').setValue(address1);
             this.getFormControl('addressAddress2')
                .setValue(components.sublocality);
             this.getFormControl('addressZip').setValue(components.postal_code);
             this.getFormControl('addressCity').setValue(components.postal_town !== undefined ?
               components.postal_town : components.locality);
         }
     }



 addressSelected($event) {
        if ($event != null) {
                        this.customerAddressModal = true;

            const originalObject = $event;
            this.addressID = originalObject.AddressID;
            this.getFormControl('address').setValue(originalObject.Name == originalObject.Address1 ? this.selectedCustomer.Name : originalObject.Name);
            this.getFormControl('addressAddress1').setValue(originalObject.Address1);
            this.getFormControl('addressAddress2').setValue(originalObject.Address2);
            this.getFormControl('addressZip').setValue(originalObject.PostalCode);
            this.getFormControl('addressCity').setValue(originalObject.City);
            this.getFormControl('DeliveryEmail').setValue(originalObject.Email);
            this.getFormControl('internalInfo').setValue(originalObject.InternalInfo);
            this.getFormControl('customerBuyer').setValue(originalObject.ContactNameOnSite);
            this.getFormControl('tankInfo').setValue(originalObject.TankInfo);
            this.getFormControl('phoneOnSite').setValue(originalObject.PhoneOnSite);
            this.getFormControl('phoneManager').setValue(originalObject.ProjectManager);
            this.getFormControl('deliveryInfo').setValue(originalObject.DeliveryInfo);
            this.getFormControl('gps').setValue(originalObject.GPS);
           this.splitGpsCoordinates(originalObject.GPS);
                      //  this.customerAddressModal = false;

        }
    }

        splitGpsCoordinates(coordinates: string) {


      if(coordinates.trim() == "")
      {
        this.mapLat = 0.0;
        this.mapLong = 0.0;
      }
      else
{
      try {
        const [latitudeStr, longitudeStr] = coordinates.split(',');

  if (latitudeStr && longitudeStr) {
    const latitude = parseFloat(latitudeStr.trim());
    const longitude = parseFloat(longitudeStr.trim());

    // Validate that parsed values are numbers
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.mapLat = latitude;
            this.mapLong = longitude;
  }else
{this.mapLat = 0.0;
  this.mapLong = 0.0;
}
  }
      } catch (error) {
        this.mapLat = 0;
    this.mapLong = 0;
      }
  
    }
}
    customerSelected($event) {
        this.selectedCustomer = null;
        if ($event != null) {
            const originalObject = $event.originalObject;
            const controls = this.detailsForm.controls;
            if (originalObject.CustomerBlockedStatus > 0) {
                this.getFormControl('customerName').setErrors({ incorrect: true });
            } else {
                this.clearAddress();
                this.selectedCustomer = originalObject;
                this.addressDataService.setErpIDParent(originalObject.ErpID);
                this.customerNo = originalObject.CustomerNo;
                this.getFormControl('customerAddress1').setValue(originalObject.Address);
                this.getFormControl('customerAddress2').setValue('');
                this.getFormControl('customerPostalCode').setValue(originalObject.PostalCode);
                this.getFormControl('customerCity').setValue(originalObject.City);
            }
        }
    }


     clearAddress() {
        this.getFormControl('addressAddress1').setValue('');
        this.getFormControl('addressAddress2').setValue('');
        this.getFormControl('addressZip').setValue('');
        this.getFormControl('addressCity').setValue('');
        this.getFormControl('address').setValue('');
        this.addressID = 0;
    }

    getCustomerDetails() {
        const customerDetails: any = {};
        customerDetails.CustomerName = this.getFormControl('customerName').value;
        customerDetails.CustomerAddress1 = this.getFormControl('customerAddress1').value;
        customerDetails.CustomerAddress2 = this.getFormControl('customerAddress2').value;
        customerDetails.CustomerCity = this.getFormControl('customerCity').value;
        customerDetails.CustomerPostalCode = this.getFormControl('customerPostalCode').value;
        customerDetails.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
        customerDetails.DeliveryName = this.getFormControl('address').value;
        customerDetails.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
        customerDetails.DeliveryPostalCode = this.getFormControl('addressZip').value;
        customerDetails.DeliveryCity = this.getFormControl('addressCity').value;
        customerDetails.PriceAgreementID = this.getFormControl('priceAgreementID').value;
        return customerDetails;
    }

    updateOrder() {
        if (this.detailsForm.valid) {
            const order: any = {};

            order.PriceAgreementID = this.getFormControl('priceAgreementID').value;
            order.OrderNo = this.order.OrderNo;
            order.CustomerCreationStatus =0;
            if (this.selectedCustomer != undefined
                || (
                    this.getFormControl('customerName').value != order.CustomerName
                    || this.getFormControl('customerAddress1').value != order.CustomerAddress1
                    || this.getFormControl('customerAddress2').value != order.CustomerAddress2
                    || this.getFormControl('customerCity').value != order.CustomerCity
                    || this.getFormControl('customerPostalCode').value != order.CustomerPostalCode
                )
                ) {
                
                    if(this.selectedCustomer == undefined && this.getFormControl('customerName').value != this.order.CustomerName)
                    {
                        order.CustomerID = 0; // New Customer
                        this.addressID =0;
                        order.CustomerCreationStatus =4;
                    }
                    else{
                        if(this.order.CustomerID != this.customerNo){
                            order.CustomerCreationStatus =4;
                        }
                        order.CustomerID = this.customerNo;
                    }

                order.CustomerName = this.getFormControl('customerName').value;
                order.CustomerAddress1 = this.getFormControl('customerAddress1').value;
                order.CustomerAddress2 = this.getFormControl('customerAddress2').value;
                order.CustomerCity = this.getFormControl('customerCity').value;
                order.CustomerPostalCode = this.getFormControl('customerPostalCode').value;
                order.DeliveryName = this.getFormControl('address').value;
                order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                order.DeliveryCity = this.getFormControl('addressCity').value;
                order.DeliveryPostalCode = this.getFormControl('addressZip').value;
                order.DeliveryActorNo = this.addressID;

                  if(order.DeliveryAddress1.trim() == '' && order.DeliveryName.trim() != order.CustomerName.trim())
                    {
                        order.DeliveryName = order.CustomerName;
                        order.DeliveryAddress1 = this.getFormControl('address').value; 
                    }

            } else {
                //order.CustomerID = this.selectedCustomer.CustomerNo;
               // if (this.addressID <= 0) {
                  if(this.selectedCustomer == undefined && this.getFormControl('customerName').value != order.CustomerName)
                    order.DeliveryActorNo = 0;
                else
                    order.DeliveryActorNo = this.addressID;

                    order.DeliveryName = this.getFormControl('address').value;
                    order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                    order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                    order.DeliveryCity = this.getFormControl('addressCity').value;
                    order.DeliveryPostalCode = this.getFormControl('addressZip').value;

                    if(order.DeliveryAddress1.trim() == '' && order.DeliveryName.trim() != order.CustomerName.trim())
                    {
                        order.DeliveryName = order.CustomerName;
                        order.DeliveryAddress1 = this.getFormControl('address').value; 
                    }

              //  } else {
               //     order.DeliveryActorNo = this.addressID;
              //  }
            }
            const customerDetails = this.getCustomerDetails();
            this.modalCtrl.dismiss({ Order: order, CustomerDetails: customerDetails });
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

     checkInput(controlName: string) {
    const control = this.detailsForm.get(controlName);
    const inputValue = control ? control.value : '';
    const ionInput = document.querySelector(`[formControlName="${controlName}"]`);

    if (ionInput) {
      if (inputValue) {
        ionInput.classList.add('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.add('item-has-value');
        }
      } else {
        ionInput.classList.remove('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.remove('item-has-value');
        }
      }
    }
  }

  
    async initCustomerSearch()
    {
        this.findCustomer(this.getFormControl('customerName').value);
    }
    
    async initCustomerAddressSearch()
    {
        if(this.customerNo > 0)
            this.findCustomerAddress(this.getFormControl('address').value);
        else
            {
             this.translateService.get('General.SelectCustomer').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
      }
    }

    
      async findCustomer(query) {
        
      
        const modal = await this.modalCtrl.create({
            component: CustomerSearchComponent,
            cssClass: 'customer-search.component', componentProps: { query: query }
        });
        this.customerModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
            if (data) {
                
                this.customerSelected(data.data.customer);
               
            }
        });
    }

       async findCustomerAddress(query) {
        
        const modal = await this.modalCtrl.create({
            component: CustomerAddressSearchComponent,
            cssClass: 'customer-address-search.component', componentProps: { customerNo: this.customerNo, addressList: [], query: query }
        });
        this.customerAddressModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
          try {
             if (data) {
               if(data != null)
                this.addressSelected(data.data.address);
               
            }
          } catch (error) {
            
          }
           
        });
    }


}
