<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'NewCustomer.nyKunde' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <form [formGroup]="customerForm">
        <ion-row>
          <ion-col size-xs="12" size-md="6">
            <!--Customer-->
            <ion-list lines="none">
              <ion-item class="text-small ion-no-padding z-index">
                <ion-label>
                  <p>
                    <ion-icon
                      name="person-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<ion-text color="primary">
                      <span
                        >{{ 'NewCustomer.kunde' | translate }}</span
                      ></ion-text
                    >
                  </p>
                </ion-label>
              </ion-item>
              <div class="completer-container address">
                <mat-form-field appearance="fill">
                  <mat-label></mat-label>
                  <input
                    type="text"
                    placeholder="{{ 'NewOrder.customerName' | translate }}"
                    matInput
                    [formControl]="customerCtrl"
                    [matAutocomplete]="auto"
                    #customerComplete
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option
                      *ngFor="let customer of filteredCustomers | async"
                      [value]="customer"
                    >
                      {{ customer.name }} - {{ customer.company }} - {{
                      customer.description }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!customerForm.controls.customerName.valid && (customerForm.controls.customerName.dirty || submitAttempt)"
              >
                <ion-text
                  color="danger"
                  *ngIf="customerForm.controls.customerName.errors.required"
                >
                  <p>
                    {{ 'NewCustomer.validationcustomerNameRequired' | translate
                    }}
                  </p>
                </ion-text>
                <ion-text
                  color="danger"
                  *ngIf="customerForm.controls.customerName.errors.incorrect"
                >
                  <p>{{'NewCustomer.customerBlockedStatus' | translate }}</p>
                </ion-text>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.customerAddress1' | translate }}"
                  formControlName="customerAddress1"
                />
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!customerForm.controls.customerAddress1.valid && (customerForm.controls.customerAddress1.dirty || submitAttempt)"
              >
                <ion-text color="danger">
                  <p>
                    {{ 'NewCustomer.validationAddress1Required' | translate }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.customerAddress2' | translate }}"
                  formControlName="customerAddress2"
                />
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <ion-row class="completer-row">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <input
                      type="text"
                      placeholder="{{ 'NewCustomer.zip' | translate }}"
                      formControlName="customerPostalCode"
                    />
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <div class="city">
                      <input
                        type="text"
                        placeholder="{{ 'NewCustomer.city' | translate }}"
                        formControlName="customerCity"
                      />
                    </div>
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="(!customerForm.controls.customerCity.valid || !customerForm.controls.customerPostalCode.valid) && (customerForm.controls.customerCity.dirty || customerForm.controls.customerPostalCode.dirty || submitAttempt)"
              >
                <ion-row class="completer-row">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        *ngIf="!customerForm.controls.customerPostalCode.valid && (customerForm.controls.customerPostalCode.dirty || submitAttempt)"
                      >
                        {{ 'NewCustomer.validationZipRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        class="cityVal"
                        *ngIf="!customerForm.controls.customerCity.valid && (customerForm.controls.customerCity.dirty || submitAttempt)"
                      >
                        {{ 'NewCustomer.validationCityRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <select id="priceAgreements" formControlName="priceAgreementID">
                  <option value="">
                    {{ 'NewCustomer.priceAgreement' | translate }}
                  </option>
                  <option
                    *ngFor="let item of priceAgreements"
                    [ngValue]="item.ID"
                    [selected]="item.ID == currentPriceID"
                  >
                    {{item.Description}}
                  </option>
                </select>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-xs="12" size-md="6">
            <!--Contact-->
            <ion-list lines="none">
              <ion-item class="text-small ion-no-padding">
                <ion-label>
                  <p>
                    <ion-icon name="call-outline" color="lightFive"></ion-icon>
                    &nbsp;<ion-text color="primary"
                      ><span
                        >{{ 'NewCustomer.kontaktinfo' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <input
                    type="text"
                    placeholder="{{ 'NewCustomer.email' | translate }}"
                    formControlName="email"
                  />
                </ion-label>
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!customerForm.controls.email.valid && (customerForm.controls.email.dirty || submitAttempt)"
              >
                <ion-text color="danger">
                  <p ion-text color="danger">
                    {{ 'NewCustomer.validationEmail' | translate }}
                  </p>
                </ion-text>
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.customerReference' | translate }}"
                  formControlName="customerReference"
                />
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'OrdreDetails.organizationNo' | translate }}"
                  formControlName="organizationNo"
                />
              </ion-item>
            </ion-list>
            <div>
              <ion-item lines="none">
                <ion-checkbox
                  [(ngModel)]="addUserToDatahost"
                  class="ion-no-margin"
                  [ngModelOptions]="{standalone: true}"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewCustomer.addUserToDatahost' | translate
                  }}</ion-label
                >
              </ion-item>
              <ion-item lines="none">
                <ion-checkbox
                  class="ion-no-margin"
                  [(ngModel)]="addUserToOrderApp"
                  [ngModelOptions]="{standalone: true}"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewCustomer.addUserToOrderApp' | translate
                  }}</ion-label
                >
              </ion-item>
            </div>
            <div class="clear"></div>
          </ion-col>
        </ion-row>
      </form>
      <!--Delivery address-->
      <form [formGroup]="addressForm">
        <ion-row>
          <ion-col size-xs="12" size-md="6">
            <ion-list lines="none">
              <ion-item class="text-small ion-no-padding">
                <ion-label>
                  <p>
                    <ion-icon
                      name="location-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<ion-text color="primary">
                      <span
                        >{{ 'NewCustomer.leveringsadresse' | translate }}</span
                      >
                    </ion-text>
                  </p>
                  <input
                    type="text"
                    placeholder="{{ 'NewCustomer.deliveryName' | translate }}"
                    formControlName="deliveryName"
                  />
                </ion-label>
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!addressForm.controls.deliveryName.valid && (addressForm.controls.deliveryName.dirty || submitAddress)"
              >
                <ion-text color="danger">
                  <p>
                    {{ 'NewCustomer.validationcustomerNameRequired' | translate
                    }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.customerAddress1' | translate }}"
                  formControlName="addressAddress1"
                />
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!addressForm.controls.addressAddress1.valid && (addressForm.controls.addressAddress1.dirty || submitAddress)"
              >
                <ion-text color="danger">
                  <p>
                    {{ 'NewCustomer.validationAddress1Required' | translate }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.customerAddress2' | translate }}"
                  formControlName="addressAddress2"
                />
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <ion-row class="completer-row">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <input
                      type="text"
                      placeholder="{{ 'NewCustomer.zip' | translate }}"
                      formControlName="addressZip"
                    />
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <input
                      type="text"
                      placeholder="{{ 'NewCustomer.city' | translate }}"
                      class="city"
                      formControlName="addressCity"
                    />
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="(!addressForm.controls.addressCity.valid || !addressForm.controls.addressZip.valid) && (addressForm.controls.addressCity.dirty || addressForm.controls.addressZip.dirty || submitAddress)"
              >
                <ion-row class="completer-row">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        *ngIf="!addressForm.controls.addressZip.valid && (addressForm.controls.addressZip.dirty || submitAddress)"
                      >
                        {{ 'NewCustomer.validationZipRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        class="cityVal"
                        *ngIf="!addressForm.controls.addressCity.valid && (addressForm.controls.addressCity.dirty || submitAddress)"
                      >
                        {{ 'NewCustomer.validationCityRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.gps' | translate }}"
                  formControlName="gps"
                />
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-xs="12" size-md="6">
            <ion-list lines="none">
              <div class="max-100 mt-address2">
                <ion-item class="text-small ion-no-padding">
                  <input
                    type="text"
                    placeholder="{{ 'NewCustomer.contactNameOnSite' | translate }}"
                    formControlName="contactNameOnSite"
                  />
                </ion-item>
                <ion-item class="text-small ion-no-padding">
                  <input
                    type="text"
                    placeholder="{{ 'NewCustomer.phoneNumber' | translate }}"
                    formControlName="phoneOnSite"
                  />
                </ion-item>
                <ion-item class="text-small ion-no-padding">
                  <input
                    type="text"
                    placeholder="{{ 'NewCustomer.email' | translate }}"
                    formControlName="emailOnSite"
                  />
                </ion-item>

                <ion-item class="text-small ion-no-padding">
                  <textarea
                    rows="2"
                    placeholder="{{ 'NewCustomer.addressDetails' | translate }}"
                    formControlName="addressDetails"
                  ></textarea>
                </ion-item>
                <ion-item class="text-small ion-no-padding">
                  <select id="addressType" formControlName="addressType">
                    <option value="">
                      {{ 'NewCustomer.addressType' | translate }}
                    </option>
                    <option value="1">
                      {{'NewCustomer.normal' | translate }}
                    </option>
                    <option value="2">
                      {{'NewCustomer.serviceLocation' | translate }}
                    </option>
                  </select>
                </ion-item>
                <ion-item
                  class="item-validation ion-no-padding"
                  *ngIf="!addressForm.controls.addressType.valid && (addressForm.controls.addressType.dirty || submitAddress)"
                >
                  <ion-text color="danger">
                    <p>{{ 'NewCustomer.addressTypeRequired' | translate }}</p>
                  </ion-text>
                </ion-item>
                <ion-button
                  (click)="addAddress()"
                  fill="outline"
                  [disabled]="!addressForm.valid"
                  class="btn-small ion-margin-top"
                >
                  <ion-icon name="checkmark-circle-outline"></ion-icon>
                  &nbsp;{{ 'NewCustomer.save' | translate }}
                </ion-button>
              </div>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-xs="12" size-md="12">
            <div class="max-100 overflow-x">
              <div class="grid-width">
                <ion-row class="grid-header ion-margin-top">
                  <ion-col size-xs="3"
                    >{{ 'NewCustomer.deliveryName' | translate }}</ion-col
                  >
                  <ion-col size-xs="3"
                    >{{ 'NewCustomer.customerAddress1' | translate }}</ion-col
                  >
                  <ion-col size-xs="1"
                    >{{ 'NewCustomer.zip' | translate }}</ion-col
                  >
                  <ion-col size-xs="1"
                    >{{ 'NewCustomer.city' | translate }}</ion-col
                  >
                  <ion-col size-xs="2"></ion-col>
                  <ion-col size-xs="1"></ion-col>
                  <ion-col size-xs="1"></ion-col>
                </ion-row>
                <ng-container *ngIf="customerAddresses">
                  <ng-container
                    *ngFor="let address of customerAddresses; let isOdd = odd; let i = index"
                  >
                    <ion-row [class.odd]="isOdd">
                      <ion-col size-xs="3" class="flex-parent">
                        <ion-icon
                          *ngIf="address.AddressType == 2"
                          name="build-outline"
                        ></ion-icon>
                        &nbsp;<strong>{{ address.Name}}</strong>
                        <div class="clear"></div>
                      </ion-col>
                      <ion-col size-xs="3" class="flex-parent"
                        >{{ address.Address1 }} <br />
                        {{ address.Address2 }}</ion-col
                      >
                      <ion-col size-xs="1" class="flex-parent"
                        >{{ address.PostalCode }}</ion-col
                      >
                      <ion-col size-xs="1" class="flex-parent"
                        >{{ address.City }}</ion-col
                      >

                      <ion-col size-xs="2" class="flex-parent">
                        <ion-button
                          *ngIf="address.AddressID > 0"
                          fill="outline"
                          class="no-padding"
                          (click)="navigateToWorkOrderHistory(address.AddressID)"
                        >
                          <ion-icon
                            name="book-outline"
                            color="primary"
                          ></ion-icon>
                          &nbsp;
                          <span class="black"
                            >{{ 'NewCustomer.showHistory' | translate }}</span
                          >
                        </ion-button>
                      </ion-col>
                      <ion-col size-xs="1" class="flex-parent">
                        <ion-icon
                          name="create-outline"
                          color="primary"
                          class="icon-size cursor-pointer"
                          (click)="editAddress(address)"
                        ></ion-icon>
                      </ion-col>
                      <ion-col size-xs="1" class="flex-parent">
                        <ion-icon
                          name="trash-outline"
                          color="primary"
                          class="icon-size cursor-pointer"
                          (click)="deleteAddress(address)"
                        ></ion-icon>
                      </ion-col>
                    </ion-row>
                    <ion-row [class.odd]="isOdd">
                      <ion-col size-xs="12" size-md="12" class="flex-parent">
                        &nbsp;{{ address.AddressDetails}}</ion-col
                      >
                    </ion-row>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
      <ion-icon name="book-outline" color="primary"></ion-icon>
      &nbsp; <span class="black">{{ 'NewCustomer.tilbake' | translate }}</span>
    </ion-button>
    <ion-button
      class="no-padding pull-right-md"
      (click)="createCustomer()"
      [disabled]="!customerForm.valid"
    >
      <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
      <span>{{ 'NewCustomer.saveCustomer' |translate }}</span>
    </ion-button>
  </div>
</ion-footer>
