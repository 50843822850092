    <div>
        <div class="max-100">
            <div class="grid-width">
                <ion-grid>
                    <ion-row>
                        <ion-col size-xs="12" size-md="4" *ngFor="let item of norTraceItems;let isOdd = odd; let i = index">
                           
                            <ion-row [ngClass]="{'red': item.data.data.pri.percent <= 10, 'yellow': item.data.data.pri.percent > 10 ,'green': item.data.data.pri.percent > 50}"
                                class="grey-text border ion-no-padding">
                                <ion-col size-xs="1" class="row-padding">
                                    
                                        <ion-content [style.height]="100+'px'">
                                            <div class="volume">
                                            <div class="volume-info"
                                                style.height="{{item.data.data.pri.percent | number : '1.0-0'}}%">
                                                <span class="dark-text"></span>
                                            </div>
                                            </div>
                                        </ion-content>
                                </ion-col>
                                <ion-col size-xs="11">
                                <ion-row>
                                        <ion-col size-xs="12" class="row-padding">
                                            <img src="assets/icon/Icon_TankMonitor.svg" class="vertical-middle" />
                                            &nbsp;
                                            <strong class="dark-text">{{item.name}}</strong>
                                        </ion-col>
                                        <ion-col size-xs="5" size-md="4" class="row-padding bigger-font">
                                                {{(item.data.data.pri.volume / item.data.data.pri.percent * 100)-item.data.data.pri.volume | number : '1.0-0'}} L
                                            </ion-col>
                                            <ion-col size-xs="4" size-md="4" class="row-padding">
                                                <ion-text class="vertical-sub">
                                                    {{item.data.data.pri.percent | number : '1.0-0'}}%</ion-text>
                                            </ion-col>
                                        <ion-col size-xs="3" size-md="4" class="row-padding">
                                            <span>
                                                    <ion-badge [style.background]="item.colorCode"> {{item.shortCode}}</ion-badge>
                                            </span>
                                        </ion-col>
                                       
                                       
                                    </ion-row>  
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </div>
        <ion-button fill="outline" color="primary" class="no-padding" (click)="navigateToTankMonitoring()">
            <ion-icon name="arrow-redo-outline"></ion-icon>&nbsp;{{ 'Menu.tankpeiling' |translate }}
        </ion-button>
    </div>