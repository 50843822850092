<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-text color="primary">
        <h6>
          <ion-icon name="checkmark-circle-outline"></ion-icon> {{
          'EgneConfirm.bekreftOverforing' | translate }}
        </h6>
      </ion-text>
      <ion-row>
        <ion-col>
          <ng-container
            *ngIf="transactionType == TransactionType.UnloadingAutoGas"
          >
            <div *ngFor="let item of inventoryList" class="m-t-5">
              <strong>{{item.AntLiter}}</strong> {{'EgneTanker.liter' |
              translate | lowercase}}
              <strong>{{item.ProductDescription}}</strong>
              {{'EgneTanker.overforesFra' | translate}}
              <strong>{{currentTruck.Description}}</strong> {{'EgneTanker.til' |
              translate}} <strong>{{tankName ? tankName: truckName}}</strong>
            </div>
          </ng-container>
          <ng-container
            *ngIf="transactionType != TransactionType.LoadingFromStorage && 
                        transactionType != TransactionType.UnloadingAutoGas"
          >
            <strong>{{antLiter}}</strong> {{'EgneTanker.liter' | translate |
            lowercase}}
            <strong>{{productDescription}}</strong>
            {{'EgneTanker.overforesFra' | translate}}
            <strong>{{currentTruck.Description}}</strong> {{'EgneTanker.til' |
            translate}} <strong>{{tankName ? tankName: truckName}}</strong>
          </ng-container>
          <ng-container
            *ngIf="transactionType == TransactionType.LoadingFromStorage"
          >
            <strong>{{antLiter}}</strong> {{'EgneTanker.liter' | translate |
            lowercase}}
            <strong>{{productDescription}}</strong>
            {{'EgneTanker.overforesFra' | translate}}
            <strong>{{tankName ? tankName: truckName}}</strong>
            {{'EgneTanker.til' | translate}}
            <strong>{{currentTruck.Description}}</strong>
          </ng-container>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center">
          <ion-item>
            <h6>
              {{ 'General.selectedDate' | translate }}: {{ currentDate |
              date:'dd.MM.yyyy'}}
            </h6>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-list lines="none">
      <ion-item class="ion-float-right">
        <ion-buttons end>
          <ion-button
            (click)="dismiss(false)"
            color="grey"
            size="small"
            fill="outline"
          >
            <span>{{ 'General.avbryt' | translate }}</span>
          </ion-button>
          <ion-button (click)="dismiss(true)" size="small" fill="solid">
            <span>{{ 'General.save' | translate }}</span>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
