import { Component } from '@angular/core';
import { NavController, LoadingController, AlertController } from '@ionic/angular';
import { ActivityView, RespUnit } from '../../shared/enums';
import { UserService } from '../../shared/user-service';
import { Utils } from '../../shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';

@Component({
    selector: 'page-activity-registration',
    templateUrl: 'activity-registration.html',
    styleUrls: ['activity-registration.scss']
})
export class ActivityRegistrationPage {
    currentDate: any = new Date();
    activityView: any = ActivityView;
    view: any = this.activityView.DateView;
    activities: any[];
    activityTypes: any[];
    activityOrders: any[];
    departments: any[];
    projects: any[];
    dateFormat;
    activityForm: FormGroup;
    submitting = false;
    submitAttempt = false;
    modalTitle;

    constructor(
        public navCtrl: NavController, private userService: UserService,
        private alertCtrl: AlertController, private loadingController: LoadingController, private translateService: TranslateService,
        private formBuilder: FormBuilder) {

        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        this.activityForm = formBuilder.group({
            activityID: ['', Validators.compose([Validators.required])],
            orderNo: ['', Validators.compose([])],
            department: ['', Validators.compose([])],
            project: ['', Validators.compose([])],
            quantity: [0, Validators.compose([Validators.required])],
            comment: ['', Validators.compose([])]
        });

        this.loadData();
    }

    ionViewDidLoad() {
    }

    getFormControlValue(field) {
        return this.activityForm.get(field).value;
    }

    getTotalQuantity() {
        return _.sumBy(this.activities, activity => {
            return activity.Quantity;
        });
    }

    loadData() {

        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {

                forkJoin([this.userService.getRegistrations(this.currentDate, this.view),
                    this.userService.getActivityTypes(), this.userService.getResponsibleUnits(RespUnit.ActivityOrders),
                    this.userService.getResponsibleUnits(RespUnit.Department),
                    this.userService.getResponsibleUnits(RespUnit.Project)]).subscribe(data => {

                        this.activities = data[0];
                        this.activityTypes = data[1];
                        this.activityOrders = null; // data[2];
                        this.departments = data[3];
                        this.projects = data[4];
                        loader.dismiss();
                    });

            });
        });
    }

    loadRegistrations() {
        this.userService.getRegistrations(this.currentDate, this.view).subscribe(data => {
            this.activities = data;
        });
    }

    deleteActivity(activity) {
        this.submitting = true;
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.deleteActivity(activity.ActivityID).subscribe(data => {
                    this.submitting = false;
                    this.activities = _.remove(this.activities, (current) => {
                        return current.ActivityID !== activity.ActivityID;
                    });
                    loader.dismiss();
                });
            });
        });
    }

    save() {
        this.submitAttempt = true;
        if (this.activityForm.valid) {
            const activity: any = {};

            activity.DateStr = (Utils.padNumber(this.currentDate.getMonth() + 1)) + '-' +
                (Utils.padNumber(this.currentDate.getDate())) + '-' + (Utils.padNumber(this.currentDate.getFullYear()));
            activity.Description = this.getFormControlValue('orderNo');
            activity.OrderNo = this.getFormControlValue('orderNo');
            activity.Department = this.getFormControlValue('department');
            activity.Project = this.getFormControlValue('project');
            activity.Invoiceable = false;
            activity.Quantity = this.getFormControlValue('quantity');
            activity.Comment = this.getFormControlValue('comment');
            activity.ActivityType = this.getFormControlValue('activityID');


            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                this.submitting = true;
                loader.present().then(() => {
                    this.userService.saveActivity(activity).subscribe(data => {
                        this.submitting = false;
                        this.activityForm.reset();
                        this.activityForm.get('quantity').setValue(0);
                        this.submitAttempt = false;
                        // controls['orderNo'].setValue('');
                        // controls['department'].setValue('');
                        // controls['project'].setValue('');
                        // controls['quantity'].setValue(0);
                        // controls['comment'].setValue('');
                        // controls['activityID'].setValue('');
                        // this.activityForm.markAsPristine();
                        // this.activityForm.markAsUntouched();
                        // this.activityForm.updateValueAndValidity();
                        this.loadRegistrations();
                        loader.dismiss();
                    });
                });
            });
        }
    }

    setQty(qty: number) {
        const controls = this.activityForm.controls;

        if (qty > 0.5) {
            this.activityForm.get('quantity').setValue(qty);
        } else {
            this.activityForm.get('quantity').setValue(this.getFormControlValue('quantity') + qty);
        }

    }

    showConfirm() {
        let modalTitle;
        let modalText;
        let cancel;
        let save;

        if ((this.getTotalQuantity() + this.getFormControlValue('quantity')) > 14) {
            this.translateService.get('ActivityRegistration.ManyHoursTitle').subscribe(value => { modalTitle = value; });
            this.translateService.get('ActivityRegistration.ManyHours').subscribe(value => { modalText = value; });
            this.translateService.get('ActivityRegistration.tilbake').subscribe(value => { cancel = value; });
            this.translateService.get('ActivityRegistration.lagre').subscribe(value => { save = value; });

            this.alertCtrl.create({
                header: modalTitle + ' (' + (this.getTotalQuantity() + this.getFormControlValue('quantity')) + ')',
                message: modalText,
                buttons: [
                    {
                        text: cancel,
                        handler: () => {

                        }
                    },
                    {
                        text: save,
                        handler: () => {
                            this.save();
                        }
                    }
                ]
            }).then(confirm => { confirm.present(); });

        } else {

            this.save();
        }
    }

    switchView(viewType: ActivityView) {
        this.activities = null;
        this.view = viewType;
        this.loadRegistrations();
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.loadRegistrations();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.loadRegistrations();

    }

    previousMonth() {
        this.currentDate = this.currentDate.addMonths(-1);
        this.loadRegistrations();
    }

    nextMonth() {
        this.currentDate = this.currentDate.addMonths(1);
        this.loadRegistrations();

    }
}
