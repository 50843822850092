import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, startWith, catchError } from 'rxjs/operators';
import { AppSettings } from '../../../shared/app-settings';
import { AddressData } from '../../../shared/address-data';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-customer-address-search',
  templateUrl: './customer-address-search.component.html',
  styleUrls: ['./customer-address-search.component.css']
})
export class CustomerAddressSearchComponent implements OnInit, AfterViewInit {
  @Input() query: string = '';
  @Input() customerNo: number = 0;
  @Input() addressList: any[];

  addressCtrl = new FormControl();
  filteredAddresses: Observable<any[]>;

  @ViewChild('addressComplete', { static: false }) addressComplete: IonInput;
  @ViewChild('addressInput', { static: false }) addressInput: ElementRef;

  private addressDataService: AddressData;

  constructor(private http: HttpClient, private modalCtrl: ModalController) {
    this.addressDataService = new AddressData(this.http, 0, `${AppSettings.API_ENDPOINT}/User/AddressSearch/?query=`);
    this.addressDataService.addressList = this.addressList;
  }

  ngOnInit() {
  this.addressCtrl.setValue(this.query);

  // Subscribe to value changes
  this.filteredAddresses = this.addressCtrl.valueChanges.pipe(
    startWith(this.query),
    debounceTime(300),
    switchMap(query => 
      query.trim() === '' ? of(this.addressList) : this.searchAddresses(query)
    ),
    catchError(() => of([])) // Handle errors gracefully
  );
}

  ngAfterViewInit() {
    // Set focus on the input field after the view has been initialized
    setTimeout(() => {
      if (this.addressComplete) {
        this.addressComplete.setFocus();
      }
    }, 1000);

    // Trigger search on every keyup event
    if (this.addressInput) {
      this.addressInput.nativeElement.addEventListener('keyup', () => {
        this.triggerSearch(this.addressCtrl.value);
      });
    }
  }

  private _filterAddresses(value: string): Observable<any[]> {
    this.addressDataService.setErpIDParent(this.customerNo, true);
    this.addressDataService.setAddresslist(this.addressList);
    return this.addressDataService.search(value);
  }

  private searchAddresses(query: string): Observable<any[]> {
    
    if (query.trim() === '') {
      return of(this.addressList);
    } else {
      if (this.customerNo > 0) {
        return this._filterAddresses(query).pipe(
          switchMap(addresses => {
            if (addresses.length > 0) {
              return of(addresses);
            } else {
              return this.searchGooglePlaces(query);
            }
          }),
          catchError(() => of([]))  // Handle any errors gracefully
        );
      } else {
        return this.searchGooglePlaces(query);
      }
    }
  }

  private searchGooglePlaces(query: string): Observable<any[]> {
    return new Observable(observer => {
      const autocomplete = new google.maps.places.AutocompleteService();
      autocomplete.getPlacePredictions({ input: query, componentRestrictions: { country: 'no' } }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          const placeService = new google.maps.places.PlacesService(document.createElement('div'));
          const detailedResults = [];

          predictions.forEach((prediction, index) => {
            placeService.getDetails({ placeId: prediction.place_id }, (place, placeStatus) => {
              if (placeStatus === google.maps.places.PlacesServiceStatus.OK) {
                const addressComponents = this.getAddressComponents(place.address_components);
                detailedResults.push({
                  AddressID: 0,
                  Name: place.name,
                  Address1: place.name,
                  PostalCode: addressComponents.postal_code,
                  City: addressComponents.locality,
                  Latitude: place?.geometry?.location.lat(),
                  Longitude: place?.geometry?.location.lng(),
                  GPS: place?.geometry?.location?.lat().toString() + ',' + place?.geometry?.location?.lng().toString(),
                  URL: place.url
                });

                // Emit results when all predictions have been processed
                if (index === predictions.length - 1) {
                  observer.next(detailedResults);
                  observer.complete();
                }
              }
            });
          });
        } else {
          observer.next([]);
          observer.complete();
        }
      });
    });
  }

  private getAddressComponents(components: any): any {
    const address = {
      street_address: '',
      postal_code: '',
      locality: ''
    };

    components.forEach(component => {
      component.types.forEach(type => {
        if (type === 'street_number' || type === 'route') {
          address.street_address += `${component.long_name} `;
        }
        if (type === 'postal_code') {
          address.postal_code = component.long_name;
        }
        if (type === 'locality' || type === 'postal_town') {
          address.locality = component.long_name;
        }
      });
    });

    return address;
  }

  triggerSearch(query: string) {
    this.searchAddresses(query).subscribe(addresses => {
      this.filteredAddresses = of(addresses);
    });
  }

  async clearSearch() {
    this.addressCtrl.setValue('');
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  async selectAddress(address) {
    await this.modalCtrl.dismiss({ address });
  }
}
