import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AppSettings } from '../../shared/app-settings';

@Component({
    selector: 'order-confirm-sales',
    templateUrl: 'order-confirm-sales.html',
    styleUrls: ['order-confirm-sales.scss']
})
export class OrderConfirmSalesComponent {
   order: any;
   VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;


    constructor(public navParams: NavParams, public modalCtrl: ModalController) {
        this.order = this.navParams.data.order;
    }

    dismiss(flag) {
        this.modalCtrl.dismiss(flag);
    }
}
