import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../../shared/user-service';
import _ from 'lodash';
import { forkJoin } from 'rxjs';
@Component({
    selector: 'page-soolo-tanklevels',
    templateUrl: 'soolo-tanklevels.html',
    styleUrls: ['soolo-tanklevels.scss']
})

export class SooloTanklevelsPage implements OnInit {
    showTanks = environment.showTanks;
    norTraceItems: any[];
    constructor(private userService: UserService, private router: Router) { }

    ngOnInit() {
        try {
            forkJoin([
                this.userService.getNorTraceItems(), this.userService.getProducts()]).subscribe(data => {
                    const norTraceItems = data[0].NotTraceDev;
                    const products = data[1];
                    for (const item of norTraceItems) {
                        try {
                            if (item.data.data.pri.percent != null) {
                                item.fuelLevelRemainingPercent = (item.data.data.pri.percent).toFixed(0);
                            } else {
                                item.fuelLevelRemainingPercent = 0;
                            }

                            let prodNo = item.appdata.fueltype;

                            if (prodNo != null && prodNo.indexOf('-') > 0) {
                                prodNo = prodNo.substring(0, prodNo.lastIndexOf('-'));
                            }

                            for (const product of products) {
                                if (product.ProductNo === prodNo) {
                                    item.shortCode = product.ShortCode;
                                    item.colorCode = product.ColorCode;
                                }
                            }
                        } catch (error) {

                        }

                    }
                    this.norTraceItems = _.take(_.sortBy(norTraceItems, [function items(item) { return item.fuelLevelRemainingPercent; }]),
                        this.showTanks).reverse();


                });
        } catch (error) {

        }

    }

    navigateToTankMonitoring() {
        this.router.navigate(['/tank-monitoring']);
        this.userService.events.next('tank-monitoring');
    }
}
