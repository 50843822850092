<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-text color="primary">
            <h6>
              <ion-icon name="add-circle-outline"></ion-icon> {{
              'OrdreDetails.leggTilProdukt' | translate }}
            </h6>
          </ion-text>
          <ion-list lines="none">
            <form [formGroup]="leggForm">
              <ion-item>
                <ion-label
                  >{{ 'LeggTilProdukt.selectProduct' | translate }}</ion-label
                >
                <ion-select formControlName="productNo">
                  <ion-select-option value=""
                    >{{ 'General.select' | translate }}</ion-select-option
                  >
                  <ion-select-option
                    *ngFor="let product of products"
                    value="{{product.ProductNo}}"
                    >{{product.Description}}</ion-select-option
                  >
                </ion-select>
              </ion-item>
              <ion-item
                class="item-validation"
                *ngIf="!leggForm.controls.productNo.valid && (leggForm.controls.productNo.dirty)"
              >
                <ion-text color="danger">
                  <p
                    [hidden]="!leggForm.controls.productNo.errors.required"
                    no-margin
                  >
                    {{ 'LeggTilProdukt.validationProductRequired' | translate }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item>
                <input
                  type="number"
                  formControlName="quantity"
                  class="ion-text-right"
                  autocomplete="off"
                  placeholder="{{ 'LeggTilProdukt.quantity' | translate }}"
                />
              </ion-item>
              <ion-item
                class="item-validation"
                *ngIf="!leggForm.controls.quantity.valid && (leggForm.controls.quantity.dirty)"
              >
                <ion-text color="danger">
                  <p
                    [hidden]="!leggForm.controls.quantity.errors.required"
                    no-margin
                  >
                    {{ 'LeggTilProdukt.quantityRequired' | translate }}
                  </p>
                </ion-text>
                <ion-text color="danger">
                  <p
                    [hidden]="!leggForm.controls.quantity.errors.pattern"
                    no-margin
                  >
                    {{ 'LeggTilProdukt.quantityOnlyNumbers' | translate }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item class="ion-float-right">
                <ion-buttons class="ion-margin-top">
                  <ion-button
                    small
                    (click)="dismiss()"
                    color="grey"
                    fill="outline"
                    size="small"
                  >
                    <span>{{ 'General.avbryt' | translate }}</span>
                  </ion-button>
                  <ion-button
                    small
                    (click)="addProdukt()"
                    [disabled]="!leggForm.valid"
                    fill="solid"
                    size="small"
                  >
                    <span>{{ 'General.save' | translate }}</span>
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </form>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
