import { Directive, Input, OnInit, ElementRef } from '@angular/core';

@Directive({
    selector: '[appPhoneRegex]'
})
export class PhoneRegexDirective implements OnInit {
    regexp = /((\d{8})|(\d{3}\s\d{2}\s\d{3})|(\d{2}\s\d{2}\s\d{2}\s\d{2}))/g;
    @Input() inputText;
    constructor(private elementRef: ElementRef) { }
    ngOnInit() {
        const matches = this.inputText.match(this.regexp);
        if (matches) {
            let html = '';
            for (const match of matches) {
                html = this.inputText.replace(match, '<a href="tel:' + match + '">' + match + '</a>');
            }
            this.elementRef.nativeElement.insertAdjacentHTML('beforeend', html);
        } else {
            this.elementRef.nativeElement.insertAdjacentHTML('beforeend', '<span>' + this.inputText + '</span>');
        }
    }
}
