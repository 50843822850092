<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle auto-hide="false">
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'TransactionLog.transactionLog' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <div class="page-title">
            <ion-row>
              <ion-col size-xs="12" size-md="5">
                <ion-icon
                  name="calendar-outline"
                  ion-text
                  color="primary"
                  class="vertical-middle m-r-10"
                  id="datepicker"
                  (click)="openModal = true"
                ></ion-icon>

                <ion-modal
                  [cssClass]="'center'"
                  id="dtOrder"
                  [isOpen]="openModal"
                  (didDismiss)="closeModal()"
                >
                  <ng-template>
                    <ion-datetime
                      #picker
                      [(ngModel)]="dateSelector"
                      (ionChange)="dateChangeEvent($event)"
                      presentation="date"
                      first-day-of-week="1"
                      size="cover"
                      [showDefaultButtons]="true"
                      doneText="{{ 'buttons.ok' | translate }}"
                      cancelText="{{ 'buttons.cancel' | translate }}"
                      class=""
                    >
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
                <span (click)="openModal = true"
                  >{{'Day.' + currentDate.getDay() | translate}}</span
                >
                <span class="secondary">
                  {{currentDate | date:dateFormat}}
                  <ion-button
                    (click)="previousDay()"
                    class="no-padding button-no-text vertical-top ion-no-margin"
                    fill="clear"
                  >
                    <ion-icon
                      color="lightFour"
                      slot="icon-only"
                      name="caret-back-circle"
                    >
                    </ion-icon>
                  </ion-button>
                  <ion-button
                    (click)="nextDay()"
                    class="no-padding button-no-text vertical-top ion-no-margin"
                    fill="clear"
                  >
                    <ion-icon
                      color="lightFour"
                      slot="icon-only"
                      name="caret-forward-circle"
                    >
                    </ion-icon>
                  </ion-button>
                </span>
              </ion-col>

              <ion-col size-xs="12" size-md="3">
                <ion-searchbar
                  [(ngModel)]="search"
                  class="customer-search"
                  placeholder="{{ 'TransactionLog.searchPlaceHolder' | translate }}"
                >
                </ion-searchbar>
              </ion-col>

              <ion-col size-xs="8" size-md="3">
                <select
                  size="1"
                  [(ngModel)]="driver"
                  class="driver"
                  (ngModelChange)="filteredtransactionLogItems()"
                  *ngIf="userlevel==1 || userlevel==2"
                >
                  <option value="0">{{ 'General.select' | translate }}</option>
                  <option
                    *ngFor="let driver of drivers"
                    value="{{driver.UserID}}"
                  >
                    {{driver.Name}}
                  </option>
                </select>
              </ion-col>
              <ion-col size-xs="4" size-md="1">
                <ion-button
                  fill="outline"
                  class="no-padding"
                  (click)="filteredtransactionLogItemsSearch()"
                >
                  <ion-icon name="refresh-outline" color="primary"></ion-icon
                  >&nbsp; <span class="black"></span>
                </ion-button>
              </ion-col>
            </ion-row>
          </div>

          <div class="overflow-x">
            <div class="transaction-grid">
              <ion-row class="grid-header">
                <ion-col size-md="2">
                  <strong
                    >{{ 'TransactionLog.transactionDetails' | translate
                    }}</strong
                  ><button
                    type="button"
                    (click)="sort('TransDate')"
                    ion-button
                    icon-only
                    class="no-padding button-small-no-text vertical-top ion-no-margin"
                  >
                    <ion-icon
                      ion-text
                      color="lightFour"
                      [name]="(sortProperty == 'TransDate' && sortDirection == 'asc') || sortProperty != 'TransDate' ? 'caret-up' : 'caret-down'"
                    >
                    </ion-icon>
                  </button>
                </ion-col>
                <ion-col size-md="2">
                  <strong
                    >{{ 'TransactionLog.produkterVolum' | translate }}</strong
                  >
                </ion-col>
                <ion-col size-md="2">
                  <strong
                    >{{ 'TransactionLog.truckTankStation' | translate }}</strong
                  >
                </ion-col>
                <ion-col size-md="1" class="ion-text-center">
                  <strong>{{ 'TransactionLog.ordre' | translate }}</strong>
                </ion-col>
                <ion-col size-md="3">
                  <strong>{{ 'TransactionLog.kunde' | translate }}</strong>
                </ion-col>

                <ion-col size-md="1">
                  <strong
                    >{{ 'TransactionLog.temperature' | translate }}</strong
                  >
                </ion-col>
                <ion-col size-md="1" class="ion-text-center"> </ion-col>
              </ion-row>

              <ion-row
                *ngFor="let item of filteredtransactionLogs;let isOdd = odd"
                [class.odd]="isOdd"
              >
                <ion-col>
                  <ion-row>
                    <ion-col size-md="2">
                      <strong
                        ><span
                          [innerText]="getDateText(item.TransDate) | async"
                        ></span></strong
                      ><br />
                      {{'TransactionType.' + item.TransType | translate}}
                      <br />{{item.Comment}}
                    </ion-col>
                    <ion-col size-md="2" class="orderline">
                      <ion-badge [style.background]="item.ProductColorCode">
                        {{item.ProductShortCode}}</ion-badge
                      >&nbsp; <strong>{{item.Quantity}}</strong> &nbsp;
                      ({{item.VolumeQuantity}})
                    </ion-col>
                    <ion-col size-md="2">
                      {{item.Truck}}<br />
                      <strong>{{item.TankStation}}</strong>
                      {{ driverName(item.EmployeeNo)}}
                    </ion-col>
                    <ion-col size-md="1" class="ion-text-center">
                      <strong>{{item.OrderNo}}</strong>
                    </ion-col>
                    <ion-col size-md="3">
                      <strong>{{item.CustomerName}}</strong><br />
                      {{item.CustomerAddress}}<br />
                    </ion-col>
                    <ion-col size-md="1">
                      <strong>{{item.VolumeTemp}}</strong> C<br />
                    </ion-col>

                    <ion-col size-md="1" class="orderline ion-text-center">
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <!--<div>
        {{ 'Beholdning.levert' | translate }} <ion-toggle class="inline-block" color="success"></ion-toggle> &nbsp;&nbsp;
        {{ 'Beholdning.lastet' | translate }} <ion-toggle class="inline-block" color="success"></ion-toggle> &nbsp;&nbsp;
    </div>-->
  <div class="primary litte-big-font footer-border">
    <ion-button fill="outline" class="no-padding" (click)="navigateBack()">
      <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
      <span class="black">{{ 'OrdreDetails.tilbake' | translate }}</span>
    </ion-button>
  </div>
</ion-footer>
