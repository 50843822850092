<ion-header>
    <ion-toolbar>
        <ion-button menuToggle>
            <ion-icon name="menu-outline"></ion-icon>
        </ion-button>
        <ion-title>
            <span *ngIf="view == activityView.MonthView">{{ 'ActivityRegistration.manedsoversikt' | translate }}</span>
            <span *ngIf="view == activityView.DateView">{{ 'ActivityRegistration.timeregistrering' | translate }}</span>
            <logout class="hidden-xs"></logout>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <form [formGroup]="activityForm" *ngIf="view == activityView.DateView">
        <ion-grid>
            <ion-row>
                <ion-col col-12 class="overflow-x">
                    <div class="page-title">
                        <ion-icon name="calendar-outline" ion-text color="primary" class="litte-big-font vertical-middle"></ion-icon>
                        {{'Day.' + currentDate.getDay() | translate}}<span class="secondary">
                            {{currentDate | date:dateFormat}}
                            <ion-button (click)="previousDay()" icon-only class="no-padding button-no-text vertical-top ion-no-margin">
                                <ion-icon class="litte-big-font" ion-text color="lightFour" name="caret-back-circle"></ion-icon>
                            </ion-button>
                            <ion-button (click)="nextDay()" icon-only class="no-padding button-no-text vertical-top ion-no-margin ">
                                <ion-icon class="litte-big-font" ion-text color="lightFour" name="caret-forward-circle"></ion-icon>
                            </ion-button>
                        </span>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="overflow-x">
                    <div class="registration-grid">
                        <ion-row>
                            <ion-col col-12 col-sm>
                                <select size="1" class="activity-type inputHeight" formControlName="activityID">
                                    <option value="" disabled>{{ 'ActivityRegistration.aktivitet2' | translate }}</option>
                                    <option *ngFor="let activityType of activityTypes" value="{{activityType.ActivityNo}}">{{activityType.ActivityNo}}
                                        - {{activityType.Description}}</option>
                                </select>

                                <span ion-text color="danger" *ngIf="activityForm.controls.activityID.errors?.required && (activityForm.controls.activityID.dirty || submitAttempt)"><br />{{
                                    'ActivityRegistration.activityRequired' | translate }}</span>
                            </ion-col>
                            <ion-col col-12 col-sm *ngIf="activityOrders && activityOrders.length > 0">
                                <select size="1" class="activity-type inputHeight" formControlName="orderNo" *ngIf="activityOrders && activityOrders.length > 0">
                                    <option value="" disabled>{{ 'ActivityRegistration.ordre2' | translate }}</option>
                                    <option *ngFor="let activityOrder of activityOrders" value="{{activityOrder.UnitID}}">{{activityOrder.UnitID}}
                                        - {{activityOrder.UnitName}}</option>
                                </select>
                            </ion-col>
                            <ion-col col-12 col-sm>
                                <select size="1" class="activity-type inputHeight" formControlName="department" *ngIf="departments && departments.length > 0">
                                    <option value="" disabled>{{ 'ActivityRegistration.avdeling2' | translate }}</option>
                                    <option *ngFor="let department of departments" value="{{department.UnitID}}">{{department.UnitID}}
                                        - {{department.UnitName}}</option>
                                </select>
                            </ion-col>
                            <ion-col col-12 col-sm *ngIf="projects && projects.length > 0">
                                <select size="1" class="activity-type inputHeight" formControlName="project" *ngIf="projects && projects.length > 0">
                                    <option value="" disabled>{{ 'ActivityRegistration.prosjekt2' | translate }}</option>
                                    <option *ngFor="let project of projects" value="{{project.UnitID}}">{{project.UnitID}}
                                        - {{project.UnitName}}</option>
                                </select>
                            </ion-col>
                            <ion-col col-12 col-sm>
                                <input type="number" placeholder="{{'ActivityRegistration.antall2' | translate }}"
                                    class="border-input qty-input inputHeight ion-no-margin ion-text-center" appRemoveZeroOnFocus
                                    formControlName="quantity" />
                                <ion-button class="no-padding input-button" (click)="setQty(8)">8</ion-button>
                                    <ion-button class="no-padding input-button" (click)="setQty(0.5)"> + </ion-button>
                                    <ion-button class="no-padding input-button" (click)="setQty(-0.5)"> - </ion-button>
                                    <span ion-text color="danger" *ngIf="activityForm.controls.quantity.errors?.required && (activityForm.controls.quantity.dirty || submitAttempt)">{{
                                        'ActivityRegistration.quantityRequired' | translate }}</span>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col col-12>
                                <textarea rows="2" class="width" formControlName="comment" placeholder="{{'ActivityRegistration.beskrivelse' | translate}}"></textarea>
                                <br />

                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <div class="page-title">
                                <br />
                                <ion-icon name="list-circle-outline" ion-text color="primary" class="litte-big-font vertical-middle"></ion-icon>
                                <strong>{{'ActivityRegistration.dagensTimer' | translate}}</strong>
                            </div>
                            <ion-grid>
                                <ion-row class="grid-header">
                                    <ion-col col-md-6>
                                        <strong>{{ 'ActivityRegistration.aktivitet' | translate }}</strong>
                                    </ion-col>
                                    <ion-col col-md-4 class="hidden-xs"></ion-col>
                                    <ion-col col-md-2>
                                        <strong>{{ 'ActivityRegistration.antall' | translate }}</strong>
                                    </ion-col>
                                </ion-row>
                                <ion-row *ngFor="let activity of activities; let isOdd = odd" [class.odd]="isOdd" class="row-height">
                                    <ion-col col-12>
                                        <ion-row>

                                            <ion-col col-md-6 col-xs-10>
                                                {{activity.ActivityType}} - {{activity.Description}}

                                            </ion-col>
                                            <ion-col col-md-4 class="hidden-xs">
                                                {{activity.Comment}}
                                            </ion-col>
                                            <ion-col col-md-2>
                                                {{activity.Quantity}}
                                                <ion-button color="primary" class="pull-right delete" [disabled]="activity.IsLocked"
                                                    (click)="deleteActivity(activity)">
                                                    <ion-icon name="trash-outline"></ion-icon>
                                                </ion-button>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col col-md-12>
                                                <span class="hidden-sm-up" *ngIf="activity.Comment!=''">{{activity.Comment}}<br></span>
                                                <span *ngIf="activity.OrderNo !=0">
                                                    <strong>{{ 'ActivityRegistration.ordre' | translate }}</strong>
                                                    {{activity.OrderNo}}&nbsp;&nbsp;
                                                </span>

                                                <span *ngIf="activity.Department !=0">
                                                    <strong>{{ 'ActivityRegistration.avdeling' | translate }}</strong>
                                                    {{activity.Department}}&nbsp;&nbsp;
                                                </span>


                                                <span *ngIf="activity.Project !=0">
                                                    <strong>{{ 'ActivityRegistration.prosjekt' | translate }}</strong>
                                                    {{activity.Project}}
                                                </span>

                                            </ion-col>
                                        </ion-row>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col col-md-12 class="text-xs-right">
                                        <strong>{{ 'ActivityRegistration.sumTotalt' | translate }}</strong> <strong>{{
                                            getTotalQuantity()}}</strong>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-row>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
    <ion-grid *ngIf="view == activityView.MonthView">
        <ion-row>
            <ion-col col-12 class="overflow-x">
                <div class="page-title">
                    <ion-icon name="calendar-outline" ion-text color="primary" class="litte-big-font vertical-middle"></ion-icon>
                    {{'Month.' + currentDate.getMonth() | translate}}<span class="secondary">
                        <ion-button (click)="previousMonth()" icon-only class="no-padding button-no-text vertical-top ion-no-margin">
                            <ion-icon class="litte-big-font" ion-text color="lightFour" name="caret-back-circle"></ion-icon>
                        </ion-button>
                        <ion-button (click)="nextMonth()" icon-only class="no-padding button-no-text vertical-top ion-no-margin">
                            <ion-icon class="litte-big-font" ion-text color="lightFour" name="caret-forward-circle"></ion-icon>
                        </ion-button>
                    </span>
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="overflow-x">
                <div class="registration-grid">
                    <ion-row>
                        <div class="page-title">
                            <ion-icon name="list-circle-outline" ion-text color="primary" class="litte-big-font vertical-middle"></ion-icon>
                            <strong>{{'ActivityRegistration.dagensTimer' | translate}}</strong>
                        </div>
                        <ion-grid>
                            <ion-row class="grid-header">
                                <ion-col col-md-2>
                                    <strong>{{ 'ActivityRegistration.dato' | translate }}</strong>
                                </ion-col>
                                <ion-col col-md-4>
                                    <strong>{{ 'ActivityRegistration.aktivitet' | translate }}</strong>
                                </ion-col>
                                <ion-col col-md-4 class="hidden-xs"></ion-col>
                                <ion-col col-md-2>
                                    <strong>{{ 'ActivityRegistration.antall' | translate }}</strong>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngFor="let activity of activities; let isOdd = odd" [class.odd]="isOdd" class="row-height">
                                <ion-col col-12>
                                    <ion-row>
                                        <ion-col col-md-2>
                                            {{activity.Date | date:dateFormat}}
                                        </ion-col>
                                        <ion-col col-md-4 col-xs-8>
                                            {{activity.ActivityType}} - {{activity.Description}}

                                        </ion-col>
                                        <ion-col col-md-4 class="hidden-xs">
                                            {{activity.Comment}}
                                        </ion-col>
                                        <ion-col col-md-2>
                                            {{activity.Quantity}}
                                            <ion-button color="primary" class="pull-right delete" [disabled]="activity.IsLocked"
                                                (click)="deleteActivity(activity)">
                                                <ion-icon name="trash-outline"></ion-icon>
                                            </ion-button>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col col-md-2 class="hidden-xs"></ion-col>
                                        <ion-col col-md-10 col-xs-12>
                                            <span class="hidden-sm-up" *ngIf="activity.Comment!=''">{{activity.Comment}}<br></span>
                                            <span *ngIf="activity.OrderNo !=0">
                                                <strong>{{ 'ActivityRegistration.ordre' | translate }}</strong>
                                                {{activity.OrderNo}}&nbsp;&nbsp;
                                            </span>

                                            <span *ngIf="activity.Department !=0">
                                                <strong>{{ 'ActivityRegistration.avdeling' | translate }}</strong>
                                                {{activity.Department}}&nbsp;&nbsp;
                                            </span>


                                            <span *ngIf="activity.Project !=0">
                                                <strong>{{ 'ActivityRegistration.prosjekt' | translate }}</strong>
                                                {{activity.Project}}
                                            </span>

                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col col-md-12 class="text-xs-right">
                                    <strong>{{ 'ActivityRegistration.sumTotalt' | translate }}</strong> <strong>{{
                                        getTotalQuantity()}}</strong>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-row>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer class="ion-padding">
    <div class="primary litte-big-font footer-border">
        <ion-button fill="outline" class="no-padding" (click)="switchView(activityView.MonthView)" *ngIf="view == activityView.DateView">
            <ion-icon name="calendar-outline" color="primary"></ion-icon>&nbsp; <span class="black">{{
                'ActivityRegistration.mandedsoversikt' | translate }}</span>
        </ion-button>
        <ion-button fill="outline" class="no-padding" (click)="switchView(activityView.DateView)" *ngIf="view == activityView.MonthView">
            <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp; <span class="black">{{ 'NewOrder.tilbake' |
                translate }}</span>
        </ion-button>
        <ion-button class="no-padding pull-right" (click)="showConfirm()" [disabled]="submitting || !activityForm.valid">
            <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp; <span>{{
                'ActivityRegistration.lagre' |
                translate }}</span>
        </ion-button>
    </div>
</ion-footer>