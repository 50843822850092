import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { AppSettings } from '../../shared/app-settings';
import { Storage } from '@ionic/storage-angular';

@Component({
    selector: 'page-lead-view',
    templateUrl: 'lead-view.html',
    styleUrls: ['lead-view.scss']
})
export class LeadViewPage {
    awards = [0,1, 2, 3, 4, 5, 10, 15, 20, 30, 40, 50];
    lead: any;
    products = [];
    selectedProducts: any = [];
    dateFormat;
    messages = [];
    message = '';
    userFullName;
    userlevel = 9;
    drivers = [];
    statuses = [];

    constructor(
        public userService: UserService, public router: Router, public navCtrl: NavController,
        private translateService: TranslateService, public route: ActivatedRoute, private storage: Storage) {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
            
        });
        this.route.queryParams.subscribe(params => {
            this.lead = JSON.parse(params.lead);
            this.loadData();
        });
    }

    loadData() {
        forkJoin([this.storage.get(AppSettings.StorageKeys.UserFullName),
        this.userService.getLeadsProducts(),
        this.storage.get(AppSettings.StorageKeys.Userlevel),this.userService.getDrivers(),this.userService.getStatuses()]).subscribe((data: any) => {
            this.userFullName = data[0];
            this.products = data[1];
            this.userlevel = data[2];
            this.products.forEach(item => {
                const index = _.findIndex(this.lead.LeadsProducts, product => {
                    return product.ProductId === item.ProductId;
                });
                if (index !== -1) {
                    item.isChecked = true;
                    this.selectedProducts.push(item);
                } else {
                    item.isChecked = false;
                }
            });
            this.drivers = data[3];
            this.statuses = data[4];
        });
    }

    navigateToBack() {
        this.router.navigateByUrl('/forms-handling');
    }

    changeSelection() {
        this.selectedProducts = this.products.filter((value: any) => {
            return value.isChecked;
        });
    }

    addMessage() {
        if (this.message !== '') {
            const message = {
                MessageId: 0, LeadId: this.lead.LeadId, MessageCreationDateTime: new Date(),
                NameOfMessagePublisher: this.userFullName, Message: this.message
            };
            this.userService.saveMessage(message).subscribe(data => {
                if (data === true) {
                    this.lead.Messages.push(message);
                    this.message = '';
                }
            });
        }
    }

    saveLead() {
        this.lead.LeadsProducts = [];
        this.selectedProducts.forEach(item => {
            this.lead.LeadsProducts.push({ LeadsProductId: 0, ProductId: item.ProductId, LeadId: this.lead.LeadId });
        });
        this.userService.saveLead(this.lead).subscribe(data => {
            this.router.navigateByUrl('forms-handling');
        }, error => {
        });
    }
}

