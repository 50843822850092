import { Component, OnInit } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { LoadingController, ToastController, ModalController, NavController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { forkJoin } from 'rxjs';
import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@ionic/storage-angular';
import * as L from 'leaflet';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderConfirmComponent } from '../../components/order-confirm/order-confirm';
import { InternalOrdertype } from '../../shared/enums';

@Component({
    selector: 'page-tank-monitoring',
    templateUrl: 'tank-monitoring.html',
    styleUrls: ['tank-monitoring.scss']
})
export class TankMonitoringPage implements OnInit {
    lowVolume = false;
    nearMeg = false;
    nearMegDistance = 10;

    orderLines: any[] = [];
    submitAttempt = false;

    leafletOptions: any;
    latitude: number = null;
    longitude: number = null;

    filteredNorTraceItems: any[];
    private norTraceItems: any[];
    products: any[];
    dateFormatTime;
    prioritet = -1;
    departments = [];
    department = '';
    fueltypes = [];
    fueltype = '';
    // datahost view variable
    dataHost = false;
    companies;
    company = 0;
    favorites;
    tankInventory = [];
    filteredTankInventory = [];
    favoritesTank = [];
    search = null;
    favoritesLevels = [];

    constructor(
        private userService: UserService, private translateService: TranslateService,private route:ActivatedRoute,public navCtrl: NavController,
        private loadingController: LoadingController, private geolocation: Geolocation, private toastController: ToastController,
        private storage: Storage, private router: Router, private modalCtrl: ModalController) {
            this.route.queryParams.subscribe(params => {
            const posOptions = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 30000
        };
        const that = this;
       
        this.dataHost = params.dataHost == 'true' ? true : false;
        this.changeView();
      
     
    });

    }

    ngOnInit() {
        this.translateService.get('General.dateFormatTime').subscribe(value => {
            this.dateFormatTime = value;
        });
          this.getCurrentPosition();
    this.watchPosition();
        this.loadData();
    }


  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: true, // Adjust based on your needs
      timeout: 10000,
      maximumAge: 0
    };

    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.updateMap();
    } catch (error) {
      this.showGeolocationError();
    }
  }

  watchPosition() {
    const watch = Geolocation.watchPosition({}, (position, err) => {
      if (err) {
        this.showGeolocationError();
        return;
      }
      if (position && position.coords) {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.updateMap();
      }
    });
  }

  updateMap() {
    this.leafletOptions = {
      layers: [
        L.tileLayer('https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png', {
          maxZoom: 18,
          attribution: ' '
        })
      ],
      zoom: 10,
      center: L.latLng({ lat: this.latitude, lng: this.longitude })
    };
  }

  async showGeolocationError() {
    const value = await this.translateService.get('Error.locationError').toPromise();
    const toast = await this.toastController.create({
      message: value,
      duration: 3000
    });
    toast.present();
    this.latitude = this.longitude = 0;
  }

    loadData() {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                forkJoin([this.storage.get(AppSettings.StorageKeys.FilterLowVolume),
                this.storage.get(AppSettings.StorageKeys.FilterNearMeg),
                this.userService.getNorTraceItems(), this.userService.getProducts(),
                this.storage.get(AppSettings.StorageKeys.Department), this.userService.getCompanies(),
                this.storage.get(AppSettings.StorageKeys.FavoritesLevels)]).subscribe(data => {
                    this.lowVolume = data[0] == null ? false : data[0];
                    this.nearMeg = data[1] == null ? false : data[1];
                    this.norTraceItems = _.sortBy(data[2].NotTraceDev, (item) => {
                        return item.data.data.pri.volume;
                    });
                    const products = data[3];
                    this.department = data[4] == null ? '' : data[4];
                    this.companies = data[5];
                    if (data[6] !== 'null' && data[6] !== '' && data[6] !== null) {
                        this.favoritesLevels = data[6].split(',');
                    }
                    this.products = _.filter(products, (p) => {
                        return p.ProductType === 10;
                    });
                    let product;
                    const oneDay = 1000 * 60 * 60 * 24;
                    for (product of this.products) {
                        for (const item of this.norTraceItems) {
                            const endDate = new Date(item.data.data.meta.aquisitiontimeRFC3339);
                            const startDate = new Date();
                            const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                            const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                            item.days_since_report = (start - end) / oneDay;

                            let prodNo = item.appdata.fueltype;

                            if (prodNo != null && prodNo.indexOf('-') > 0) {
                                prodNo = prodNo.substring(0, prodNo.lastIndexOf('-'));
                            }

                            if (product.ProductNo === prodNo) {
                                item.shortCode = product.ShortCode;
                                item.colorCode = product.ColorCode;
                                item.ProductNo = product.ProductNo;
                            }
                        }
                    }
                    this.filterData(null, false);
                    loader.dismiss();
                    for (const item of this.norTraceItems) {
                        try {
                            if (item.tags.public !== undefined) {
                                if (item.tags.public[0].indexOf(':') >= 0 && item.tags.public[0] !== '' &&
                                    item.tags.public[0] !== null && item.tags.public[0] !== undefined) {
                                    const department = item.tags.public[0].split(':')[1];
                                    const departmentIndex = this.departments.indexOf(department) === -1;
                                    if (departmentIndex) {
                                        this.departments.push(department);
                                    }
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }

                        const fueltype = item.appdata.fueltype;
                        const fueltypeIndex = this.fueltypes.indexOf(fueltype) === -1;
                        if (fueltypeIndex && fueltype !== '' && fueltype !== undefined && fueltype !== null) {
                            this.fueltypes.push(fueltype);
                        }
                    }
                });
            });
        });
    }

    filterChanged($event, fromFilter) {
        this.filterData($event, fromFilter);
    }

    filterData($event: any, fromFilter) {
        this.filteredNorTraceItems = _.filter(this.norTraceItems, (item) => {
            let value = true;
            if ($event && $event.detail && $event.detail.value && !fromFilter) {
                const searchPhrase = $event.detail.value.toString().toLowerCase();

                if (
                    (item.name && item.name.toLowerCase().includes(searchPhrase))
                    ||
                    (item.tags.public[0] &&
                        item.tags.public[0].toLowerCase().includes(searchPhrase))

                ) {
                    value = true;
                } else {
                    value = false;
                }
            }

            if (this.lowVolume === true) {
                item.fuelLevelRemainingPercent = (item.data.data.pri.volume * 100) / item.appdata.volume;
                value = value && item.fuelLevelRemainingPercent <= 10;
            }

            if (this.prioritet !== -1) {
                value = value && this.prioritet === item.appdata.fueltype;
            }
            if (this.department !== '') {
                try {
                    value = value && this.department === item.tags.public[0].split(':')[1].toUpperCase();
                } catch (error) {

                }

            }
            if (this.fueltype !== '') {
                value = value && this.fueltype === item.appdata.fueltype;
            }
            if (this.nearMeg === true && this.latitude != null && this.longitude != null) {

                if (item.appdata.expected_location && item.appdata.expected_location.lat && item.appdata.expected_location._long) {
                    value = value && (Utils.calculateDistance
                        (this.latitude, this.longitude, item.appdata.expected_location.lat, item.appdata.expected_location._long)
                        <= this.nearMegDistance);
                }
            }

            if (this.favoritesLevels.length > 0) {
                _.filter(this.favoritesLevels, (level) => {
                    if (item.devid === level) {
                        item.fav = true;
                    }
                });
            }
            return value;
        });

        this.storage.set(AppSettings.StorageKeys.FilterLowVolume, this.lowVolume);
        this.storage.set(AppSettings.StorageKeys.FilterNearMeg, this.nearMeg);
    }

    async loadDrivingInstructions(norTraceItem) {
    let destination;
    if (norTraceItem.appdata.expected_location.lat !== 0 && norTraceItem.appdata.expected_location._long !== 0) {
        destination = `https://www.google.com/maps/dir/?api=1&destination=${norTraceItem.appdata.expected_location.lat},${norTraceItem.appdata.expected_location._long}`;
    } else {
        // Handle the alternative case where the address needs to be constructed or you have the address already
        destination = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(norTraceItem.address)}`;
    }

    try {
        await AppLauncher.openUrl({ url: destination });
    } catch (error) {
        console.error('Error launching maps:', error);
        this.translateService.get('Error.navigatorError').subscribe(async value => {
            const toast = await this.toastController.create({
                message: value + error,
                duration: 3000
            });
            toast.present();
        });
    }
}


    expandNorTraceItem(item) {
        item.expandedView = !item.expandedView;
    }

    onDepartmentChange($event) {
        this.storage.set(AppSettings.StorageKeys.Department, this.department);
        this.filterData($event, true);
    }

    async  createTankOrder(item) {
        this.submitAttempt = true;
        this.orderLines = [];

        const order: any = {};

        const orderline: any = {
            ProductColorCode: '',
            ProductShortCode: item.shortCode,
            Description: item.appdata.fueltype,
            LineNo: 0,
            ProductNo: item.ProductNo,
            Quantity: Math.round(((item.data.data.pri.volume / item.data.data.pri.percent * 100) - item.data.data.pri.volume)),
            QuantityDelivered: 0
        };

        this.orderLines.push(orderline);

        const date = Date.now();

                let customerNo = 0;
        let deliveryID = 0;
        let customerIds = null;
let custData = item.custommerdata;

        if(custData === ''){ custData=item.comment;}

        if (custData != null) {
        if (custData.toString().includes("/")) {
            customerIds = custData.toString().split("/");
        }

        if (custData.toString().includes("\\")) {
            customerIds = custData.toString().split("\\");
        }

         if (customerIds?.length ==2){
            customerNo = parseInt(customerIds[0].toString());
            deliveryID = parseInt(customerIds[1].toString());
        } else {
            customerNo = parseInt(custData.toString());
        }
        }

    if(deliveryID > 0)
        {
            order.DeliveryActorNo = deliveryID;
        }

        order.CustomerID = customerNo;
        order.DeliveryInfo = 'Peiler: ' + item.name;
        order.Email = '';
        order.PhoneOnSite = '';
        order.Longitude = -1;
        order.Latitude = -1;
        order.Orderlines = this.orderLines;
        order.OrderComments = '';
        order.OrderInternalComments = '';
        order.CustomerReference = '';
        order.AdditionalProductsDelivered = false;
        order.TankInfo = item.appdata.tanktype;
        order.OrderType = 1;
        order.InternalOrderType = InternalOrdertype.Bulk;
        this.storage.get(AppSettings.StorageKeys.UserFullName).then(data => { order.CustomerBuyer = data; });

        order.DoNotCalculateVolume = true;
        order.LoadingTemp = 15;
        this.storage.get(AppSettings.StorageKeys.CompanyNo).then(data => { order.CompanyNo = data; });

        const modal = await this.modalCtrl.create({
            component: OrderConfirmComponent,
            cssClass: 'order-confirm',
            componentProps: { order }

        });
        modal.present();

        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });

                    loader.present().then(async () => {
                        (await this.userService.createNewOrder(order)).subscribe(resp => {
                            if (resp) {
                                this.router.navigate(['/orders']);
                            }
                            loader.dismiss();
                        });
                    });
                });
            }
        });
    }

    changeView() {
        if (this.dataHost) {
            this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    forkJoin([this.userService.getTankInventory(), this.storage.get(AppSettings.StorageKeys.FavoritesTank)])
                        .subscribe(data => {
                            this.tankInventory = data[0];
                            this.tankInventory.forEach(item => {
                                item.InventoryList = _.sortBy(item.InventoryList, (vol) => {
                                    return vol.MaxQuantity;
                                });
                            });
                            if (data[1] !== 'null' && data[1] !== '' && data[1] !== null) {
                                this.favoritesTank = data[1].split(',');
                            }
                            this.filterRecords();
                            loader.dismiss();
                        });
                });
            });
        } else {
            this.tankInventory = [];
            this.filteredTankInventory = [];
            this.search = null;
        }
    }

    markTanks(inventory) {
        inventory.fav = !inventory.fav;
        const index = _.findIndex(this.favoritesTank, (fav) => {
            return fav === inventory.Tank.TankID;
        });
        if (index > -1) {
            if (!inventory.fav) {
                this.favoritesTank.splice(index, 1);
            }
        } else {
            if (inventory.fav) {
                this.favoritesTank.push(inventory.Tank.TankID);
            }
        }
        this.storage.set(AppSettings.StorageKeys.FavoritesTank, this.favoritesTank.toString());
        this.filterRecords();
    }

    markLevels(item) {
        item.fav = !item.fav;
        const index = _.findIndex(this.favoritesLevels, (fav) => {
          return fav === item.devid;
        });
        if (index > -1) {
            if (!item.fav) {
                this.favoritesLevels.splice(index, 1);
            }
        } else {
            if (item.fav) {
                this.favoritesLevels.push(item.devid);
            }
        }
        this.storage.set(AppSettings.StorageKeys.FavoritesLevels, this.favoritesLevels.toString());
        this.filterData(null, false);
    }

    calculatePercent(inventory) {
        if (inventory.MaxQuantity > 0) {
            return (inventory.Quantity * 100) / inventory.MaxQuantity;
        } else {
            return 0;
        }
    }

    filterRecords() {
        this.filteredTankInventory = _.filter(this.tankInventory, (item) => {
            let value = true;
            if (this.company !== 0) {
                value = value && this.company.toString() === item.Tank.CompanyNo.toString();
            }
            if (this.prioritet !== -1) {
                item.FilteredInventory = _.filter(item.InventoryList, (product) => {
                    return product.ProductDescription.toUpperCase() === this.prioritet.toString().toUpperCase();
                });
            } else {
                item.FilteredInventory = item.InventoryList;
            }
            if (this.lowVolume) {
                item.FilteredInventory = _.filter(item.FilteredInventory, (product) => {
                    return this.calculatePercent(product) <= 10;
                });
            }
            if (this.favoritesTank.length > 0) {
                _.filter(this.favoritesTank, (tank) => {
                    if (item.Tank.TankID === tank) {
                        item.fav = true;
                    }
                });
            }
            if (this.favorites) {
                value = value && item.fav;
            }
            if (this.search !== '' && this.search !== null) {
                const searchPhrase = this.search.toString().toLowerCase();
                if (item.Tank.Name.toLowerCase().includes(searchPhrase)) {
                    value = value && true;
                } else {
                    value = value && false;
                }
            }
            return value;
        });
    }

    navigateToBack(){
        this.navCtrl.pop();
    }
}
