<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'EgneTanker.internOrdre' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <form [formGroup]="egneForm">
      <ion-grid>
        <ion-row>
          <ion-col size-md="10 " offset-md="1">
            <div class="ion-text-center">
              <h4>
                {{ 'EgneTanker.duHarValgtA' | translate }}

                <span id="datepicker"
                  >{{ transactionTypeTitle | translate | uppercase}} -
                  {{currentDate | date:dateFormat}}</span
                >
                &nbsp;
                <ion-icon
                  name="calendar-outline"
                  ion-text
                  color="primary"
                  class="vertical-middle m-r-10"
                  id="datepicker"
                  (click)="openModal = true"
                ></ion-icon>

                <ion-modal
                  [cssClass]="'center'"
                  id="dtOrder"
                  [isOpen]="openModal"
                  (didDismiss)="closeModal()"
                >
                  <ng-template>
                    <ion-datetime
                      #picker
                      [(ngModel)]="dateSelector"
                      (ionChange)="dateChangeEvent($event)"
                      presentation="date"
                      first-day-of-week="1"
                      size="cover"
                      [showDefaultButtons]="true"
                      doneText="{{ 'buttons.ok' | translate }}"
                      cancelText="{{ 'buttons.cancel' | translate }}"
                      class=""
                    >
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
              </h4>
              {{ 'EgneTanker.velgNaProduktTankOgAntall' | translate }}

              <ion-row class="margin-top-30 prodRow">
                <ion-col
                  offset-sm="2"
                  size-xs="12"
                  size-sm="4"
                  *ngIf="transactionType == TransactionType.UnloadingAutoGas"
                >
                  <h3>{{ 'EgneTanker.company' | translate }}</h3>
                  <select
                    size="1"
                    class="tank"
                    formControlName="company"
                    (ngModelChange)="companyChanged($event)"
                  >
                    <option value="0">
                      {{ 'General.select' | translate }}
                    </option>
                    <option
                      *ngFor="let company of companies"
                      value="{{company.CompanyNo}}"
                    >
                      {{company.Name}}
                    </option>
                  </select>
                </ion-col>
                <ion-col
                  size-xs="12"
                  size-sm="4"
                  *ngIf="transactionType == TransactionType.UnloadingAutoGas"
                >
                  <h3>{{ 'EgneTanker.tank' | translate }}</h3>
                  <select
                    size="1"
                    class="tank"
                    formControlName="tank"
                    (ngModelChange)="tankChanged($event)"
                  >
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let tank of tanks" value="{{tank.TankID}}">
                      {{tank.Name}}
                    </option>
                  </select>
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.tank.errors?.required && !egneForm.controls.tank.pristine"
                    >
                      {{ 'EgneTanker.tankRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>
              </ion-row>

              <ion-row class="margin-top-30">
                <ion-col
                  size-xs="12"
                  size-sm
                  *ngIf="transactionType != TransactionType.LoadingFromTruck && transactionType != TransactionType.UnloadingAutoGas"
                >
                  <h3>{{ 'EgneTanker.company' | translate }}</h3>
                  <select
                    size="1"
                    class="tank"
                    formControlName="company"
                    (ngModelChange)="companyChanged($event)"
                  >
                    <option value="0">
                      {{ 'General.select' | translate }}
                    </option>
                    <option
                      *ngFor="let company of companies"
                      value="{{company.CompanyNo}}"
                    >
                      {{company.Name}}
                    </option>
                  </select>
                </ion-col>
                <ion-col
                  size-xs="12"
                  size-sm
                  *ngIf="transactionType != TransactionType.LoadingFromTruck && transactionType != TransactionType.UnloadingAutoGas"
                >
                  <h3>{{ 'EgneTanker.tank' | translate }}</h3>
                  <select
                    size="1"
                    class="tank"
                    formControlName="tank"
                    (ngModelChange)="tankChanged($event)"
                  >
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let tank of tanks" value="{{tank.TankID}}">
                      {{tank.Name}}
                    </option>
                  </select>
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.tank.errors?.required && !egneForm.controls.tank.pristine"
                    >
                      {{ 'EgneTanker.tankRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>

                <ion-col
                  size-xs="12"
                  size-sm
                  *ngIf="transactionType == TransactionType.LoadingFromTruck"
                >
                  <h3>{{ 'EgneTanker.truck' | translate }}</h3>
                  <select
                    size="1"
                    class="truck"
                    formControlName="truck"
                    (ngModelChange)="truckChanged($event)"
                  >
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let truck of trucks" value="{{truck.ID}}">
                      {{truck.Description}}
                    </option>
                  </select>
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.truck.errors?.required && !egneForm.controls.truck.pristine"
                    >
                      {{ 'EgneTanker.truckRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>

                <ion-col size-xs="12" size-sm>
                  <h3>{{ 'EgneTanker.produkt' | translate }}</h3>
                  <select
                    size="1"
                    class="produkt"
                    formControlName="product"
                    (ngModelChange)="productChanged($event,0)"
                  >
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option
                      *ngFor="let product of products"
                      value="{{product.ProductNo}}"
                    >
                      {{product.Description}}
                    </option>
                  </select>
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.product.errors?.required && !egneForm.controls.product.pristine"
                    >
                      {{ 'EgneTanker.productRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>
                <ion-col
                  size-xs="12"
                  size-sm
                  *ngIf="transactionType == TransactionType.UnloadingAutoGas"
                >
                  <h3>{{ 'EgneTanker.tankno' | translate }}</h3>
                  <select size="1" class="truck" formControlName="tankno">
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.tankno.errors?.required && !egneForm.controls.tankno.pristine"
                    >
                      {{ 'EgneTanker.tanknoRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>
                <ion-col
                  size-xs="12"
                  size-sm
                  *ngIf="transactionType == TransactionType.LoadingFromStorage"
                >
                  <h3>{{ 'EgneTanker.temp' | translate }}</h3>
                  <input
                    type="number"
                    class="inputTemp"
                    formControlName="loadingTemp"
                  />
                  <ion-button
                    class="no-padding tempButton tempValueButton"
                    *ngIf="temperature != null"
                    (click)="setTemperature()"
                    >{{temperature}}&deg;C
                  </ion-button>
                  <ion-button
                    class="no-padding tempButton"
                    (click)="setQty(0.5)"
                  >
                    +
                  </ion-button>
                  <ion-button
                    class="no-padding tempButton"
                    (click)="setQty(-0.5)"
                  >
                    -
                  </ion-button>
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.loadingTemp.errors?.required && !egneForm.controls.loadingTemp.pristine"
                    >
                      {{ 'EgneTanker.validationTemperatureRequired' | translate
                      }}
                    </p>
                  </ion-text>
                </ion-col>
                <ion-col size-xs="12" size-sm size-md="3">
                  <h3>{{ 'EgneTanker.antLiter' | translate }}</h3>
                  <input
                    class="ant-liter"
                    type="number"
                    formControlName="antLiter"
                  />
                  <br />
                  <ion-text color="danger">
                    <p
                      *ngIf="egneForm.controls.antLiter.errors?.required && !egneForm.controls.antLiter.pristine"
                    >
                      {{ 'EgneTanker.antLiterRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>
                <ion-col
                  size-xs="12"
                  size-md="3"
                  size-sm
                  *ngIf="transactionType == TransactionType.UnloadingAutoGas"
                >
                  <ion-button
                    class="add-button"
                    [disabled]="!egneForm.valid || submitting"
                    (click)="addProduct()"
                  >
                    <ion-icon name="add-circle-outline"></ion-icon>&nbsp; {{
                    'EgneTanker.add' | translate}}
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row
                *ngFor="let item of productGroup"
                formGroupName="productGroup"
              >
                <ng-container *ngIf="item.LineNo > 0">
                  <ion-col size-xs="12" size-sm>
                    <select
                      size="1"
                      class="produkt"
                      formControlName="{{'product' + item.LineNo}}"
                      (ngModelChange)="productChanged($event,item.LineNo)"
                    >
                      <option value="">
                        {{ 'General.select' | translate }}
                      </option>
                      <option
                        *ngFor="let product of products"
                        value="{{product.ProductNo}}"
                      >
                        {{product.Description}}
                      </option>
                    </select>
                    <ion-text color="danger">
                      <p
                        *ngIf="egneForm.controls.productGroup.get('product' + item.LineNo).errors?.required && !egneForm.controls.productGroup.get('product' + item.LineNo).pristine"
                      >
                        {{ 'EgneTanker.productRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm>
                    <select
                      size="1"
                      class="truck"
                      formControlName="{{'tankno' + item.LineNo}}"
                    >
                      <option value="">
                        {{ 'General.select' | translate }}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    <ion-text color="danger">
                      <p
                        *ngIf="egneForm.controls.productGroup.get('tankno' + item.LineNo).errors?.required && !egneForm.controls.productGroup.get('tankno' + item.LineNo).pristine"
                      >
                        {{ 'EgneTanker.tanknoRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm size-md="3">
                    <input
                      class="ant-liter"
                      type="number"
                      formControlName="{{'antLiter'+ item.LineNo}}"
                    />
                    <br />
                    <ion-text color="danger">
                      <p
                        *ngIf="egneForm.controls.productGroup.get('antLiter' + item.LineNo).errors?.required && !egneForm.controls.productGroup.get('antLiter' + item.LineNo).pristine"
                      >
                        {{ 'EgneTanker.antLiterRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="2"></ion-col>
                </ng-container>
              </ion-row>
              <ion-row
                *ngIf="(egneForm.get('tank') && egneForm.get('tank').value) || (egneForm.get('truck') && egneForm.get('truck').value)"
              >
                <ion-col size-xs="12" size-sm>
                  <br />
                  <ng-container *ngIf="tank">
                    <div>
                      <img
                        class="vertical-middle"
                        src="assets/icon/Icon_Station.svg"
                        width="60"
                      />
                      <span class="vertical-middle" ion-text color="dark"
                        >{{'EgneTanker.navaerendeBeholdning' | translate}}
                        <strong>{{tank.Name | uppercase}}</strong>
                      </span>
                    </div>
                    <br />
                  </ng-container>
                  <ng-container *ngIf="truck">
                    <div>
                      <img
                        class="vertical-middle"
                        src="assets/icon/Icon_Truck.svg"
                        width="60"
                      />
                      <span class="vertical-middle" ion-text color="dark"
                        >{{'EgneTanker.navaerendeBeholdning' | translate}}
                        <strong>{{truck.Description | uppercase}}</strong>
                      </span>
                    </div>
                    <br />
                  </ng-container>
                  <ion-grid class="border inventory ion-no-padding">
                    <div class="overflow-x">
                      <div class="inventory-grid">
                        <ion-row class="grid-header">
                          <ion-col
                            size-xs="5"
                            class="text-xs-left ion-no-padding"
                          >
                            <strong
                              >{{'EgneTanker.produkt' | translate}}</strong
                            >
                          </ion-col>
                          <ion-col size-xs="3" class="ion-no-padding">
                            <strong>{{'EgneTanker.room' | translate}}</strong>
                          </ion-col>

                          <ion-col
                            size-xs="2"
                            class="text-xs-right ion-no-padding"
                          >
                            <strong>{{'EgneTanker.liter' | translate}}</strong>
                          </ion-col>

                          <ion-col size-xs="2" class="ion-no-padding">
                            <strong
                              >{{'EgneTanker.capacity' | translate}}</strong
                            >
                          </ion-col>
                        </ion-row>

                        <ion-row
                          *ngFor="let inventory of inventoryList; let isOdd = odd; let i = index"
                          [class.odd]="isOdd"
                        >
                          <ion-col
                            size-xs="5"
                            class="text-xs-left ion-no-padding"
                          >
                            <ion-badge
                              [style.background]="inventory.ProductColorCode"
                            >
                              {{inventory.ProductShortCode}}</ion-badge
                            >
                            {{inventory.ProductDescription}}
                          </ion-col>
                          <ion-col size-xs="3" class="ion-no-padding">
                            {{inventory.TankRoomNo}}
                          </ion-col>
                          <ion-col
                            size-xs="2"
                            class="text-xs-right ion-no-padding"
                          >
                            {{inventory.Quantity | number:'1.0-0':'en'}}
                          </ion-col>

                          <ion-col size-xs="2" class="ion-no-padding">
                            {{inventory.MaxQuantity | number:'1.0-0':'en'}}
                            &nbsp;({{(inventory.MaxQuantity -
                            inventory.Quantity) | number:'1.0-0':'en'}})
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                  </ion-grid>
                </ion-col>

                <ion-col size-xs="12" size-sm>
                  <br />
                  <ng-container *ngIf="currentTruck">
                    <div>
                      <img
                        class="vertical-middle"
                        src="assets/icon/Icon_Truck.svg"
                        width="60"
                      />
                      <ion-text color="dark">
                        <span class="vertical-middle"
                          >{{'EgneTanker.navaerendeBeholdning' | translate}}
                          <strong
                            >{{currentTruck.Description | uppercase}}</strong
                          >
                        </span>
                      </ion-text>
                    </div>
                    <br />
                  </ng-container>
                  <ion-grid class="border inventory ion-no-padding">
                    <div class="overflow-x">
                      <div class="inventory-grid">
                        <ion-row class="grid-header">
                          <ion-col
                            size-xs="6"
                            class="text-xs-left ion-no-padding"
                          >
                            <strong
                              >{{'EgneTanker.produkt' | translate}}</strong
                            >
                          </ion-col>
                          <ion-col
                            size-xs="3"
                            class="text-xs-right ion-no-padding"
                          >
                            <strong>{{'EgneTanker.liter' | translate}}</strong>
                          </ion-col>
                        </ion-row>
                        <ion-row
                          *ngFor="let inventory2 of inventoryList2; let isOdd = odd; let i = index"
                          [class.odd]="isOdd"
                        >
                          <ion-col
                            size-xs="6"
                            class="text-xs-left ion-no-padding"
                          >
                            <ion-badge
                              [style.background]="inventory2.ProductColorCode"
                            >
                              {{inventory2.ProductShortCode}}</ion-badge
                            >
                            {{inventory2.ProductDescription}}
                          </ion-col>
                          <ion-col
                            size-xs="3"
                            class="text-xs-right ion-no-padding"
                          >
                            {{inventory2.Quantity | number:'1.0-0':'en'}}
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-sm-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        [disabled]="!egneForm.valid || submitting"
        (click)="ferdigmeld()"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp; {{
        'EgneTanker.ferdigmeld' | translate}}
      </ion-button>
    </div>
    <div [hidden]="!expand">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="navigateToEgneTankerSelect()"
      >
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'OrdreDetails.tilbake' | translate }}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right-md hidden-xs"
        [disabled]="!egneForm.valid || submitting"
        (click)="ferdigmeld()"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp; {{
        'EgneTanker.ferdigmeld' | translate}}
      </ion-button>
    </div>
  </div>
</ion-footer>
