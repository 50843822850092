<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'NewDeviation.title' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <form [formGroup]="deviationForm">
        <ion-row class="ion-no-padding" class="deviation">
          <ion-col size-xs="12" size-md="6" class="ion-no-padding">
            <ion-list lines="none">
              <ion-item
                [class.bigger-font]="userService.fontSettings==2"
                [class.biggest-font]="userService.fontSettings==4"
              >
                <ion-label>
                  <p>
                    <ion-icon
                      name="alert-circle-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'NewDeviation.type' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <ion-row>
                    <ion-col
                      size-xs="12"
                      class="ion-no-padding"
                      [class.bigger-font]="userService.fontSettings==2"
                      [class.biggest-font]="userService.fontSettings==4"
                    >
                      <ion-radio-group
                        [(ngModel)]="deviationTypeOption"
                        [ngModelOptions]="{standalone: true}"
                        (ionChange)="changeDeviationTypeOption()"
                      >
                        <ion-list
                          lines="none"
                          class="ion-no-padding"
                          [class.bigger-font]="userService.fontSettings==2"
                          [class.biggest-font]="userService.fontSettings==4"
                        >
                          <ion-row>
                            <ion-col
                              size-xs="12"
                              size-md="6"
                              class="ion-no-padding"
                              [class.bigger-font]="userService.fontSettings==2"
                              [class.biggest-font]="userService.fontSettings==4"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="deviationType.Customer"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'NewDeviation.customer' | translate }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                            <ion-col
                              size-xs="12"
                              size-md="6"
                              class="ion-no-padding"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="deviationType.AutomateStationStorageTank"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'NewDeviation.station/tanks' | translate
                                  }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                            <ion-col
                              size-xs="12"
                              size-md="6"
                              class="ion-no-padding"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="deviationType.CarTrailer"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'NewDeviation.bil/henger' | translate }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                            <ion-col
                              size-xs="12"
                              size-md="6"
                              class="ion-no-padding"
                            >
                              <ion-item>
                                <ion-radio
                                  [value]="deviationType.Other"
                                ></ion-radio
                                >&nbsp;
                                <ion-label
                                  >{{ 'NewDeviation.other' | translate }}
                                </ion-label>
                              </ion-item>
                            </ion-col>
                          </ion-row>
                        </ion-list>
                      </ion-radio-group>
                      <div
                        *ngIf="deviationTypeOption===deviationType.Other"
                        class="ion-margin-top ion-padding-start"
                        [class.bigger-font]="userService.fontSettings==2"
                        [class.biggest-font]="userService.fontSettings==4"
                      >
                        <ion-label>
                          <input
                            type="text"
                            formControlName="other"
                            placeholder="{{ 'NewDeviation.other' | translate }}"
                            [required]="deviationTypeOption===deviationType.Other"
                          />
                        </ion-label>
                        <ion-text color="danger">
                          <div class="m-t-5">
                            <p
                              class="ion-no-margin"
                              *ngIf="deviationForm.controls.other.errors?.required && !deviationForm.controls.other.pristine"
                            >
                              {{'NewDeviation.required' | translate }}
                            </p>
                          </div>
                        </ion-text>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-label>
              </ion-item>
              <ion-item class="z-index">
                <ion-label>
                  <p>
                    <ion-icon
                      name="location-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text
                      color="primary"
                      [class.bigger-font]="userService.fontSettings==2"
                      [class.biggest-font]="userService.fontSettings==4"
                    >
                      <span>
                        {{'NewDeviation.details' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <div
                    class="ion-margin-start ion-margin-top"
                    *ngIf="deviationTypeOption===deviationType.Customer"
                    [class.bigger-font]="userService.fontSettings==2"
                    [class.biggest-font]="userService.fontSettings==4"
                  >
                    <mat-form-field appearance="fill">
                      <mat-label></mat-label>
                      <input
                        type="text"
                        placeholder="{{ 'NewOrder.customerName' | translate }}"
                        matInput
                        [formControl]="customerCtrl"
                        [matAutocomplete]="auto"
                        #customerComplete
                      />
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFn"
                      >
                        <mat-option
                          *ngFor="let customer of filteredCustomers | async"
                          [value]="customer"
                        >
                          {{ customer.name }} - {{ customer.company }} - {{
                          customer.description }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <ion-text color="danger">
                    <div
                      class="m-t-5"
                      [class.bigger-font]="userService.fontSettings==2"
                      [class.biggest-font]="userService.fontSettings==4"
                    >
                      <p
                        class="ion-no-margin ion-padding-start"
                        *ngIf="(deviationForm.controls.customer.errors?.required && !deviationForm.controls.customer.pristine) || (deviationTypeOption===deviationType.Customer && deviationForm.controls.customer.errors?.incorrect)"
                      >
                        {{'NewDeviation.required' | translate }}
                      </p>
                    </div>
                  </ion-text>
                </ion-label>
              </ion-item>
              <ion-item
                class="ion-margin-start checkbox"
                [class.bigger-font]="userService.fontSettings==2"
                [class.biggest-font]="userService.fontSettings==4"
                [hidden]="true"
              >
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="copyToCustomer"
                  [ngModelOptions]="{standalone: true}"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewDeviation.copyTocustomer' | translate
                  }}</ion-label
                >
              </ion-item>
              <!-- <ion-item class="" *ngIf="deviationTypeOption===deviationType.Other">
                <ion-label class="ion-no-margin">
                  <div class="ion-padding-start">
                    <input type="text" formControlName="other" placeholder="{{ 'NewDeviation.other' | translate }}"
                      [required]="deviationTypeOption===deviationType.Other">
                    <ion-text color="danger">
                      <div class="m-t-5">
                        <p class="ion-no-margin"
                          *ngIf="deviationForm.controls.other.errors?.required && !deviationForm.controls.other.pristine">
                          {{'NewDeviation.required' | translate }}</p>
                      </div>
                    </ion-text>
                  </div>
                </ion-label>
              </ion-item> -->
              <ion-item
                class=""
                *ngIf="deviationTypeOption===deviationType.CarTrailer"
                [class.bigger-font]="userService.fontSettings==2"
                [class.biggest-font]="userService.fontSettings==4"
              >
                <ionic-selectable
                  class="inpSelect"
                  formControlName="trailersAndTrucks"
                  [items]="trailersAndTrucks"
                  placeholder="{{ 'NewDeviation.truckAndTrailer' | translate }}"
                  itemValueField="ID"
                  itemTextField="Description"
                  [canSearch]="true"
                  [required]="deviationTypeOption===deviationType.CarTrailer"
                >
                </ionic-selectable>
                <ion-text color="danger">
                  <div class="m-t-5">
                    <p
                      class="ion-no-margin ion-padding-start"
                      *ngIf="deviationForm.controls.trailersAndTrucks.errors?.required && !deviationForm.controls.trailersAndTrucks.pristine"
                    >
                      {{'NewDeviation.required' | translate }}
                    </p>
                  </div>
                </ion-text>
              </ion-item>
              <ion-item
                class=""
                *ngIf="deviationTypeOption===deviationType.AutomateStationStorageTank"
                [class.bigger-font]="userService.fontSettings==2"
                [class.biggest-font]="userService.fontSettings==4"
              >
                <ionic-selectable
                  class="inpSelect"
                  formControlName="tank"
                  [items]="tanks"
                  placeholder="{{ 'NewDeviation.tank' | translate }}"
                  itemValueField="TankID"
                  itemTextField="Name"
                  [canSearch]="true"
                  [required]="deviationTypeOption===deviationType.AutomateStationStorageTank"
                >
                </ionic-selectable>
                <ion-text color="danger">
                  <div class="m-t-5">
                    <p
                      class="ion-no-margin ion-padding-start"
                      *ngIf="deviationForm.controls.tank.errors?.required && !deviationForm.controls.tank.pristine"
                    >
                      {{'NewDeviation.required' | translate }}
                    </p>
                  </div>
                </ion-text>
              </ion-item>
              <ion-item class="">
                <p>
                  <ion-icon
                    name="checkbox-outline"
                    class="vertical-middle"
                    color="lightFive"
                  ></ion-icon
                  >&nbsp;
                  <ion-text color="primary">
                    <span>
                      {{'NewDeviation.typeEvent' | translate }}</span
                    ></ion-text
                  >
                </p>
              </ion-item>
              <ion-item class="ion-margin-start checkbox">
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="trafficConditions"
                  [ngModelOptions]="{standalone: true}"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewDeviation.traffic' | translate }}</ion-label
                >
              </ion-item>
              <ion-item class="ion-margin-start checkbox">
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="deliveryConditions"
                  [ngModelOptions]="{standalone: true}"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewDeviation.delivery' | translate }}</ion-label
                >
              </ion-item>
              <ion-item class="ion-margin-start checkbox">
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="accident"
                  [ngModelOptions]="{standalone: true}"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewDeviation.accident' | translate }}</ion-label
                >
              </ion-item>
              <ion-item class="ion-margin-start checkbox">
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="personalInjury"
                  [ngModelOptions]="{standalone: true}"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewDeviation.personalInjury' | translate
                  }}</ion-label
                >
              </ion-item>
              <ion-item class="ion-margin-start checkbox">
                <ion-checkbox
                  color="primary"
                  [(ngModel)]="other"
                  [ngModelOptions]="{standalone: true}"
                >
                </ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewDeviation.other' | translate }}</ion-label
                >
              </ion-item>
              <ion-item class="" *ngIf="other">
                <ion-label class="ion-no-margin">
                  <div class="ion-padding-start">
                    <input
                      type="text"
                      formControlName="category"
                      placeholder="{{ 'NewDeviation.category' | translate }}"
                    />
                    <ion-text color="danger">
                      <div class="m-t-5">
                        <p
                          class="ion-no-margin ion-padding-start"
                          *ngIf="deviationForm.controls.category.errors?.required && !deviationForm.controls.category.pristine"
                        >
                          {{'NewDeviation.required' | translate }}
                        </p>
                      </div>
                    </ion-text>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-xs="12" size-md="6" class="ion-no-padding">
            <ion-list lines="none">
              <ion-item class="">
                <ion-label>
                  <p>
                    <ion-icon
                      name="chatbubbles-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'NewDeviation.comments' | translate | uppercase
                        }}</span
                      ></ion-text
                    >
                  </p>
                  <div class="ion-padding-start ion-padding-top">
                    <textarea
                      rows="10"
                      formControlName="comments"
                      required
                    ></textarea>
                  </div>
                  <ion-text color="danger">
                    <div class="m-t-5 ion-padding-start">
                      <p
                        class="ion-no-margin"
                        *ngIf="deviationForm.controls.comments.errors?.required && !deviationForm.controls.comments.pristine"
                      >
                        {{'NewDeviation.required' | translate }}
                      </p>
                    </div>
                  </ion-text>
                </ion-label>
              </ion-item>
              <ion-item class="">
                <ion-label>
                  <p>
                    <ion-icon
                      name="chatbubbles-outline"
                      class="vertical-middle"
                      color="lightFive"
                    ></ion-icon
                    >&nbsp;
                    <ion-text color="primary">
                      <span>
                        {{'NewDeviation.reason' | translate | uppercase }}</span
                      ></ion-text
                    >
                  </p>
                  <div class="ion-padding-start ion-padding-top">
                    <textarea rows="3" formControlName="reason"></textarea>
                  </div>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <ion-row>
                    <ion-col
                      size-sm="6"
                      size-xs="12"
                      class="ion-no-padding ion-margin-bottom"
                    >
                      <p class="">
                        <ion-icon
                          name="calendar-outline"
                          class="vertical-middle"
                          color="lightFive"
                        ></ion-icon
                        >&nbsp;
                        <ion-text color="primary">
                          <span>
                            {{'NewDeviation.date' | translate | uppercase
                            }}</span
                          ></ion-text
                        >
                      </p>
                      <ion-button
                        fill="clear"
                        color="dark"
                        class="picker"
                        (click)="openModal = true"
                      >
                        <ion-modal
                          [cssClass]="'center'"
                          [isOpen]="openModal"
                          (didDismiss)="closeModal()"
                        >
                          <ng-template>
                            <ion-datetime
                              #picker
                              presentation="date"
                              formControlName="date"
                              size="cover"
                              [showDefaultButtons]="true"
                              doneText="{{ 'buttons.ok' | translate }}"
                              cancelText="{{ 'buttons.cancel' | translate }}"
                              class="dateText"
                              first-day-of-week="1"
                            >
                            </ion-datetime>
                          </ng-template>
                        </ion-modal>
                        <ion-icon
                          color="lightFive"
                          name="calendar-outline"
                          slot="end"
                        ></ion-icon>

                        {{deviationForm.controls['date'].value |
                        date:dateFormat}} &nbsp;
                      </ion-button>
                    </ion-col>
                    <ion-col
                      size-sm="6"
                      size-xs="12"
                      class="ion-no-padding ion-padding-start"
                    >
                      <p class="">
                        <ion-text color="primary">
                          <span>
                            {{'NewDeviation.prority' | translate | uppercase
                            }}</span
                          ></ion-text
                        >
                      </p>
                      <select
                        formControlName="priority"
                        id="prioritet"
                        title="Prioritet"
                      >
                        <option [ngValue]="-1">
                          {{ 'Ordrer.alle' | translate }}
                        </option>
                        <option [ngValue]="0">
                          {{ 'Ordrer.normal' | translate }}
                        </option>
                        <option [ngValue]="1">
                          {{ 'Ordrer.urgent' | translate }}
                        </option>
                      </select>
                    </ion-col>
                  </ion-row>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <br />
                  <div class="input-group form-group">
                    <div class="custom-file no-padding ion-padding-start">
                      <label for="file" class="custom-file-upload">
                        <ion-icon
                          name="camera-outline"
                          class="vertical-middle"
                        ></ion-icon>
                        {{ 'NewDeviation.addImage' | translate }}
                      </label>
                      <input type="file" multiple id="file" accept="image/*" />
                    </div>
                  </div>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <ion-row>
                    <ion-col
                      size-xs="6"
                      size-sm="4"
                      class="ion-padding-bottom ion-text-center"
                      *ngFor="let file of httpFiles"
                    >
                      <div class="file-preview">
                        <img class="card-img" [src]="file.url" title="Blide" />
                        <div>
                          <ion-icon
                            name="trash-outline"
                            class="vertical-middle cursor-pointer"
                            color="primary"
                            (click)="deleteFile(file)"
                          ></ion-icon>
                        </div>
                        <div class="file-name">{{file.name}}</div>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div>
      <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'NewDeviation.back' | translate }}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="save()"
        [disabled]="!deviationForm.valid ||(deviationTypeOption===deviationType.Customer && selectedCustomer==null)"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'NewDeviation.saveForm' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
