import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AppSettings } from '../../shared/app-settings';

@Component({
    selector: 'work-order-confirm',
    templateUrl: 'work-order-confirm.html',
    styleUrls: ['work-order-confirm.scss']
})
export class WorkOrderConfirmComponent {
    order: any;
    VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;

    constructor(public navParams: NavParams, public modalCtrl: ModalController) {
        this.order = this.navParams.data.order;
    }

    dismiss(flag) {
        this.modalCtrl.dismiss(flag);
    }
}
