<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-text color="primary">
        <h6>
          <ion-icon name="checkmark-circle-outline"></ion-icon> {{
          'BeholdningConfirm.bekreftLevering' | translate }}
        </h6>
      </ion-text>
      <ion-row class="grid-header">
        <ion-col size="8"> </ion-col>
        <ion-col size="4">
          <strong>{{ 'Beholdning.liter' | translate }}</strong>
        </ion-col>
      </ion-row>
      <ng-container *ngFor="let item of inventoryList">
        <ion-row *ngIf="item.Liter != 0">
          <ion-col size="8">
            <ion-badge [style.background]="item.ProductColorCode"
              >{{item.ProductShortCode}}</ion-badge
            >
            {{item.ProductDescription}}
          </ion-col>
          <ion-col size="4">
            <strong>{{item.Liter}}</strong>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
    <ion-list lines="none">
      <ion-item class="ion-float-right">
        <ion-buttons end>
          <ion-button
            size="small"
            (click)="dismiss(false)"
            color="grey"
            fill="outline"
          >
            <span>{{ 'General.avbryt' | translate }}</span>
          </ion-button>
          <ion-button size="small" (click)="dismiss(true)" fill="solid">
            <span>{{ 'General.save' | translate }}</span>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
