import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL } from './tokens';

@Injectable({
  providedIn: 'root'
})
export class CustomerNorwegianData {
    private url: string;

    constructor(private http: HttpClient, @Inject(API_URL) private apiurl: string) {
        this.url = apiurl;
    }

    public search(): Observable<any[]> {
        return this.http.get<any>(`${this.url}`)
            .pipe(map(res => {
                const customers = [];
                if (res._embedded) {
                    for (const item of res._embedded.enheter) {
                        var adr = item.forretningsadresse
                      customers.push({
    Address: adr?.adresse.join(', '),
    City: adr?.poststed,
    PostalCode: adr?.postnummer,
    Name: item.navn,
    Description: item.organisasjonsform.beskrivelse,
    OrgNo: item.organisasjonsnummer,
    CustomerBlockedStatus: 0,
    CustomerNo: 0
});

                    }
                }
                return customers;
            }));
    }
}
