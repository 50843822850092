<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'OrdreDetails.ordre' | translate }} {{order.OrderNo}}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <form
      [formGroup]="detailsForm"
      class="order-details"
      *ngIf="allowDescriptionChange !== undefined"
    >
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-lg="6" [hidden]="isHidden">
            <ion-text *ngIf="isOrderMine()" color="success">
              <h2>{{ 'OrdreDetails.ordrenErTildeltDeg' | translate }}</h2>
            </ion-text>
            <ion-text
              *ngIf="!isOrderMine() && order.AssignedDriver > 0"
              color="danger"
            >
              <h2>{{'OrdreDetails.denneOrdrenErTildelt' | translate }}</h2>
            </ion-text>

            <ion-list lines="none" class="ion-no-padding">
              <ion-item class="text-small ion-no-padding">
                <ion-label>
                  <ion-row>
                    <ion-col size-xs="12" size-lg="6">
                      <p class="h6">
                        <ion-icon
                          name="person-outline"
                          class="vertical-text-bottom"
                        ></ion-icon>
                        &nbsp;
                        <ion-text color="primary"
                          ><span
                            >{{ 'OrdreDetails.kundeLeveringsadresse' | translate
                            }}</span
                          >
                        </ion-text>
                      </p>
                    </ion-col>
                    <ion-col size-xs="12" size-lg="6" class="ion-no-padding">
                      <p>
                        <ion-button
                          class="vertical-middle pull-right-md"
                          size="small"
                          (click)="editCustomerAndAddress()"
                        >
                          <ion-icon
                            name="create-outline"
                            class="vertical-text-bottom"
                          ></ion-icon>
                          &nbsp;{{ 'General.edit' | translate }}
                        </ion-button>
                      </p>
                    </ion-col>
                  </ion-row>

                  <div
                    class="text-small padding-left"
                    [class.biggest-font]="userService.fontSettings==4"
                    [class.bigger-font]="userService.fontSettings==2"
                  >
                    {{order.CustomerName == order.DeliveryName ?
                    order.CustomerName : order.DeliveryName + " " +
                    order.CustomerName}}
                    <br />
                    {{order.DeliveryAddress1}}, {{order.DeliveryPostalCode}}
                    {{order.DeliveryCity}}
                  </div>
                  <p>
                    <ion-button
                      class="pull-right-md"
                      size="small"
                      [disabled]="order.Latitude == null || order.Longitude == null || currentLatitude == null || currentLongitude == null"
                      (click)="loadDrivingInstructions()"
                    >
                      <ion-icon
                        name="navigate"
                        class="vertical-text-bottom"
                      ></ion-icon>
                      &nbsp;{{ 'OrdreDetails.veibeskrivelse' | translate
                      }}&nbsp;
                    </ion-button>
                  </p>
                  <div class="padding-left">
                    <ion-text color="black"
                      ><strong
                        >{{ 'OrdreDetails.bestiltav' | translate }}</strong
                      ></ion-text
                    >
                    &nbsp;{{order.CustomerBuyer}}
                  </div>
                  <div class="padding-left">
                    <ion-text color="black"
                      ><strong
                        >{{ 'OrdreDetails.solgtav' | translate }}</strong
                      ></ion-text
                    >
                    &nbsp;{{order.Seller}}
                  </div>
                  <p>&nbsp;</p>
                  <p class="h6">
                    <ion-icon
                      name="calendar-outline"
                      class="vertical-text-bottom"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;
                    <ion-text color="primary"
                      ><span
                        >{{ 'OrdreDetails.leveringsdato' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <div
                    class="padding-left"
                    [class.biggest-font]="userService.fontSettings==4"
                    [class.bigger-font]="userService.fontSettings==2"
                  >
                    {{order.DeliveryDate | date:dateFormat}}
                    <span
                      *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                    >
                      /
                      <ion-icon
                        name="alert-circle-outline"
                        class="circle vertical-text-bottom"
                      ></ion-icon
                      >&nbsp;
                      <span
                        class="text-small"
                        [class.biggest-font]="userService.fontSettings==4"
                        [class.bigger-font]="userService.fontSettings==2"
                        >{{ order.ConfirmedDeliveryDate |
                        date:dateFormat}}</span
                      >
                    </span>
                  </div>
                </ion-label>
              </ion-item>

              <!--OPTIONS-->
              <ion-item lines="none" class="ion-padding-start">
                <ion-checkbox
                  class="ion-no-margin"
                  formControlName="additionalProductsDelivered"
                  (ionChange)="additionalProductsDeliveredChanged()"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'OrdreDetails.andreProdukterErUtlevert' | translate
                  }}</ion-label
                >
              </ion-item>
              <ion-item
                lines="none"
                class="ion-padding-start"
                *ngIf="VolumeToStandardTruck && !VolumeToStandardOff"
              >
                <ion-checkbox
                  class="ion-no-margin"
                  formControlName="doNotCalculateVolume"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'OrdreDetails.DoNotCalc' | translate }}</ion-label
                >
              </ion-item>
              <ion-item lines="none" class="ion-padding-start">
                <ion-checkbox
                  class="ion-no-margin"
                  formControlName="doNotSendGPS"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'OrdreDetails.DoNotSendGPS' | translate
                  }}</ion-label
                >
              </ion-item>
            </ion-list>
          </ion-col>

          <ion-col size-xs="12" size-lg="6" [hidden]="isHidden">
            <!--CONTACT-->
            <ion-item class="ion-no-padding" class="text-small">
              <ion-label>
                <p class="h6">
                  <ion-icon
                    name="call-outline"
                    color="lightFive"
                    class="vertical-text-bottom"
                  ></ion-icon>
                  &nbsp;
                  <ion-text color="primary"
                    ><span
                      >{{ 'OrdreDetails.kontaktinfo' | translate }}</span
                    ></ion-text
                  >
                </p>
                <div class="padding-left">
                  <ion-label>
                    <ion-row>
                      <ion-col class="ion-no-padding" size-lg="6">
                        <div class="ion-padding-bottom">
                          <b>{{ 'OrdreDetails.on-site' | translate }}</b>
                          <br />
                          <input
                            type="text"
                            formControlName="phoneOnSite"
                            class="m-t-5"
                          />
                        </div>
                      </ion-col>
                      <ion-col
                        class="ion-no-padding"
                        size-lg="6"
                        style="padding-left: 5px"
                      >
                        <div class="ion-padding-bottom">
                          <b
                            >{{ 'OrdreDetails.project-manager' | translate }}</b
                          >
                          <br />
                          <input
                            type="text"
                            formControlName="phoneManager"
                            class="m-t-5"
                          />
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-label>
                  <div class="ion-padding-bottom">
                    <b>{{ 'OrdreDetails.email' | translate }}</b>
                    <br />
                    <input type="text" formControlName="email" class="m-t-5" />
                    <ion-item
                      class="item-validation text-small ion-no-padding"
                      *ngIf="!detailsForm.controls.email.valid && (detailsForm.controls.email.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p ion-text color="danger">
                          {{ 'OrdreDetails.validationEmail' | translate }}
                        </p>
                      </ion-text>
                    </ion-item>
                  </div>

                  <div class="ion-padding-bottom">
                    <strong
                      >{{ 'OrdreDetails.emailReceipt' | translate }}</strong
                    >
                    <br />
                    <input
                      type="text"
                      formControlName="emailTo"
                      class="m-t-5"
                    />
                    <ion-item
                      class="item-validation text-small ion-no-padding"
                      lines="none"
                      *ngIf="!detailsForm.controls.emailTo.valid && (detailsForm.controls.emailTo.dirty || submitAttempt)"
                    >
                      <ion-text
                        color="danger"
                        *ngIf="detailsForm.controls.emailTo.errors.emailValidator"
                      >
                        <p ion-text color="danger">
                          {{ 'OrdreDetails.validationEmail' | translate }}
                        </p>
                      </ion-text>
                    </ion-item>
                  </div>
                </div>
              </ion-label>
            </ion-item>
            <!--DEL.INFO-->

            <ion-item class="ion-no-padding" class="text-small">
              <ion-label>
                <p class="h6">
                  <ion-icon
                    name="cube-outline"
                    color="lightFive"
                    class="vertical-text-bottom"
                  ></ion-icon>
                  &nbsp;
                  <ion-text color="primary">
                    <span
                      >{{ 'OrdreDetails.leveringsinfo' | translate }}</span
                    ></ion-text
                  >
                </p>
                <div class="padding-left ion-padding-bottom">
                  <b>{{ 'OrdreDetails.internInfo' | translate }}</b>
                  <br />
                  <input
                    type="text"
                    formControlName="internalInfo"
                    class="m-t-5"
                  />
                </div>
                <div class="padding-left ion-padding-bottom">
                  <b>{{ 'OrdreDetails.leveringsInfo' | translate }}</b>
                  <br />
                  <input
                    type="text"
                    formControlName="deliveryInfo"
                    class="m-t-5"
                  />
                </div>
                <div class="padding-left ion-padding-bottom">
                  <ion-row>
                    <ion-col class="ion-no-padding" size-lg="6">
                      <b>{{ 'OrdreDetails.tankInfo' | translate }}</b>
                      <br />
                      <input
                        type="text"
                        formControlName="tankInfo"
                        class="m-t-5"
                      />
                    </ion-col>
                    <ion-col class="ion-no-padding" size-lg="6">
                      <b>{{ 'NewOrder.customerReference' | translate }}</b>
                      <br />
                      <input
                        type="text"
                        formControlName="customerReference"
                        class="m-t-5"
                      />
                    </ion-col>
                  </ion-row>
                </div>
              </ion-label>
            </ion-item>
          </ion-col>

          <!--EXTERNAL COMMENTS-->
          <ion-col size-xs="12" size-md="6">
            <ion-item class="text-small ion-no-padding">
              <ion-label>
                <p class="h6">
                  <ion-icon
                    name="chatbox-outline"
                    class="vertical-text-bottom"
                    color="lightFive"
                  ></ion-icon>
                  &nbsp;
                  <ion-text color="primary"
                    ><span
                      >{{ 'OrdreDetails.kommentar' | translate }}</span
                    ></ion-text
                  >
                </p>
                <div class="padding-left">
                  <textarea rows="2" formControlName="orderComments"></textarea>
                  <ion-item
                    class="item-validation text-small ion-no-padding"
                    *ngIf="!detailsForm.controls.orderComments.valid"
                  >
                    <ion-text color="danger">
                      <p ion-text color="danger">
                        {{ 'OrdreDetails.validationComments' | translate }}
                      </p>
                    </ion-text>
                  </ion-item>
                </div>
              </ion-label>
            </ion-item>
          </ion-col>

          <ion-col size-xs="12" size-md="6">
            <!---INTERNAL COMMENTS-->
            <ion-item class="text-small ion-no-padding">
              <ion-label>
                <p class="h6">
                  <ion-icon
                    name="chatbox-outline"
                    class="vertical-text-bottom"
                    color="lightFive"
                  ></ion-icon>
                  &nbsp;
                  <ion-text color="primary"
                    ><span
                      >{{ 'OrdreDetails.internkommentar' | translate }}</span
                    ></ion-text
                  >
                </p>
                <div class="padding-left">
                  <textarea
                    rows="2"
                    formControlName="orderInternalComments"
                  ></textarea>
                </div>
              </ion-label>
            </ion-item>
          </ion-col>

          <!--Products-->
          <ion-col size-xs="12">
            <div class="ion-margin-top ion-margin-bottom">
              <p class="pull-left-md">
                <ion-icon
                  name="flame-outline"
                  color="lightFive"
                  class="vertical-text-bottom"
                ></ion-icon>
                <ion-text color="primary"
                  ><span ion-text>
                    {{'OrdreDetails.produkter' | translate }}</span
                  >
                </ion-text>
              </p>
              <ion-button
                size="default"
                class="no-padding pull-right-md ion-margin-bottom"
                (click)="leggTilProdukt()"
              >
                <ion-icon
                  name="add-circle-outline"
                  class="vertical-text-bottom"
                ></ion-icon
                >&nbsp;
                <span>{{ 'OrdreDetails.leggTilProdukt' | translate }}</span>
              </ion-button>
              <div class="clear"></div>
              <div class="overflow-x">
                <div class="product-grid">
                  <ion-row class="grid-header">
                    <ion-col size="2">
                      {{ 'OrdreDetails.produkt' | translate }}
                    </ion-col>
                    <ion-col size="1">
                      {{ 'OrdreDetails.bestilt' | translate }}
                    </ion-col>
                    <ion-col size="1">
                      {{ 'OrdreDetails.levert' | translate }}
                    </ion-col>
                    <ion-col size="1"
                      >{{ 'OrdreDetails.cost' | translate }}</ion-col
                    >
                    <ion-col size="1"
                      >{{ 'OrdreDetails.pris' | translate }}</ion-col
                    >
                    <ion-col size="1"
                      >{{ 'OrdreDetails.rab' | translate }}</ion-col
                    >
                    <ion-col size="2">
                      {{ 'OrdreDetails.standard' | translate
                      }}&nbsp;{{order.LoadingTemp}}&deg;C
                    </ion-col>

                    <ion-col size="2"
                      >{{ 'OrdreDetails.total' | translate }}</ion-col
                    >
                    <ion-col size="1"></ion-col>
                  </ion-row>
                  <ion-row
                    *ngFor="let orderLine of order.Orderlines;let isOdd = odd; let i = index"
                    [class.odd]="isOdd"
                    formGroupName="orderLines"
                    class="text-small"
                  >
                    <ion-col
                      class="cursor-pointer litte-big-font-2"
                      size="2"
                      appClickOutside
                      (clickOutside)="changeElement($event,orderLine)"
                    >
                      <div
                        [hidden]="orderLine.changeProduct"
                        class="flex-parent"
                      >
                        <ion-badge
                          [style.background]="orderLine.ProductColorCode"
                          >{{orderLine.ProductShortCode}} </ion-badge
                        >&nbsp;
                        <span class="truncate-text"
                          >{{orderLine.Description}}</span
                        >
                      </div>
                      <div [hidden]="!orderLine.changeProduct">
                        <!--  <select id="productNo" formControlName="{{'productNo' + orderLine.LineNo}}"
                          *ngIf="!allowDescriptionChange" (change)="selectProduct($event,orderLine)"
                          (blur)="selectProduct($event,orderLine)">
                          <option value="">{{ 'General.select' | translate }}</option>
                          <option *ngFor="let product of products" value="{{product.ProductNo}}">{{product.Description}}
                          </option>
                        </select> -->
                        <ionic-selectable
                          class="inpSelect"
                          formControlName="{{'productNo' + orderLine.LineNo}}"
                          *ngIf="!allowDescriptionChange"
                          [items]="products"
                          (onChange)="selectProduct($event,orderLine)"
                          placeholder="{{ 'General.select' | translate }}"
                          itemValueField="ProductNo"
                          itemTextField="Description"
                          [canSearch]="true"
                        >
                        </ionic-selectable>
                        <input
                          type="text"
                          formControlName="{{'productNo' + orderLine.LineNo}}"
                          (change)="selectProduct($event,orderLine)"
                          *ngIf="allowDescriptionChange"
                          class="border-input litte-big-font-2 ion-no-margin"
                        />
                        <ng-container
                          *ngIf="!detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).dirty || submitAttempt))"
                        >
                          <ion-text color="danger"
                            ><span
                              *ngIf="detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).errors.required"
                            >
                              {{'OrdreDetails.descriptionRequired' | translate
                              }}</span
                            ></ion-text
                          >
                        </ng-container>
                      </div>
                    </ion-col>
                    <ion-col size="1" class="litte-big-font-2">
                      <div class="inline-block bestilt">
                        {{orderLine.Quantity}}
                      </div>
                    </ion-col>
                    <ion-col size="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input litte-big-font-2 ion-no-margin"
                        (keyup)="validateQuantity(orderLine)"
                        (click)="validateQuantity(orderLine)"
                        formControlName="{{'quantity' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          ></ion-text
                        >
                      </ng-container>
                    </ion-col>
                    <ion-col size="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input litte-big-font-2 ion-no-margin"
                        [class.hideCostWhite]="hideCost"
                        (keyup)="setValue(orderLine, 'cost')"
                        (click)="setValue(orderLine, 'cost')"
                        formControlName="{{'cost' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          ></ion-text
                        >
                      </ng-container>
                    </ion-col>
                    <ion-col size="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input litte-big-font-2 ion-no-margin"
                        (keyup)="setValue(orderLine, 'price')"
                        (click)="setValue(orderLine, 'price')"
                        formControlName="{{'price' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('price' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('price' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('price' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('price' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          ></ion-text
                        >
                      </ng-container>
                    </ion-col>
                    <ion-col size="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input litte-big-font-2 ion-no-margin"
                        formControlName="{{'discount' + orderLine.LineNo}}"
                        (keyup)="setValue(orderLine, 'discount')"
                        (click)="setValue(orderLine, 'discount')"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          ></ion-text
                        >
                      </ng-container>
                    </ion-col>
                    <ion-col size="2">
                      <span
                        class="slight-big-font"
                        *ngIf="VolumeToStandardTruck && orderLine.ProductType == 10"
                      >
                        <strong
                          >{{orderLine.QuantityDelivered*orderLine.VolumeToStandardFactor
                          | number:'1.0-0'}}</strong
                        >
                      </span>
                      <span
                        class="slight-big-font"
                        *ngIf="!VolumeToStandardTruck"
                      >
                        <strong
                          >{{orderLine.QuantityDelivered |
                          number:'1.0-1'}}</strong
                        >
                      </span>
                    </ion-col>
                    <ion-col size="2" class="slight-big-font"
                      >{{orderLine.OrderLineTotal | number:'1.0-0'}}</ion-col
                    >
                    <ion-col size="1" class="ion-no-padding">
                      <ion-icon
                        name="trash-outline"
                        color="primary"
                        class="icon-size cursor-pointer"
                        (click)="showConfirm(orderLine)"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                  <ion-row class="m-t-5">
                    <ion-col size-xs="9" class="bigger-font ion-text-right">
                      <ion-text color="black"
                        ><b>{{'OrdreDetails.orderTotal' | translate }}</b
                        >&nbsp;</ion-text
                      >
                    </ion-col>
                    <ion-col size-xs="3" class="slight-big-font">
                      <ion-text color="black"
                        ><b>{{order.TotalOrder | number:'1.0-0'}}</b></ion-text
                      >
                    </ion-col>
                  </ion-row>
                </div>
              </div>
              <ion-text color="danger">
                <p *ngFor="let errorMessage of errorMessages">
                  <strong>{{errorMessage}}</strong>
                </p>
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-sm-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button class="pull-right" fill="outline" (click)="SaveOrder()">
        <ion-icon name="save-outline"></ion-icon>&nbsp;
        <span>{{ 'Ordrer.saveOrder' | translate }}</span>
      </ion-button>
    </div>
    <div [hidden]="!expand">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="navigateToOrdersList()"
      >
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'OrdreDetails.tilbake' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        [hidden]="!isOrderMine()"
        (click)="moveOrderToNextDay()"
      >
        <ion-icon name="arrow-forward-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'OrdreDetails.utsettLevering' | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        [hidden]="isOrderMine()"
        (click)="plukkOrApne()"
      >
        <ion-icon name="checkbox-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'OrderAssignment.AssignToMe' | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        [hidden]="!isOrderMine()"
        (click)="unassignOrder()"
      >
        <ion-icon name="close-circle-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'OrdreDetails.slippOrdre' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        [hidden]="isHidden"
        class="no-padding"
        (click)="showhide()"
        size="default"
      >
        <ion-icon name="caret-up-circle-outline"></ion-icon>
        &nbsp;
        <span class="black">{{ 'OrdreDetails.HideContact' | translate}}</span>
      </ion-button>
      <ion-button
        fill="outline"
        [hidden]="!isHidden"
        size="default"
        class="no-padding"
        (click)="showhide()"
      >
        <ion-icon name="caret-down-circle-outline"></ion-icon>
        &nbsp;
        <span class="black">{{ 'OrdreDetails.ShowContact' | translate}}</span>
      </ion-button>
      <ion-button
        fill="outline"
        size="default"
        class="no-padding"
        (click)="confirmDeleteOrder()"
        *ngIf="userlevel <= 3"
      >
        <ion-icon name="trash-outline"></ion-icon>
        &nbsp; <span class="black">{{ 'General.delete' | translate}}</span>
      </ion-button>
      <ion-button
        fill="outline"
        size="default"
        class="no-padding"
        (click)="addSign()"
      >
        <ion-icon name="create-outline"></ion-icon>
        &nbsp; <span class="black">{{ 'OrdreDetails.sign' | translate}}</span>
      </ion-button>
      <ion-button
        fill="outline"
        size="default"
        class="no-padding"
        (click)="toggleHideCost()"
      >
        <ion-icon name="cash-outline"></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right-md"
        (click)="setOrderAsCompleted()"
        [hidden]="showhideCameFrom()"
        [disabled]="!detailsForm.valid ||!isOrderMine() ||!deliveredQuantityExists() || (errorMessages.length > 0)"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'OrdreDetails.ferdigmeldOrdre' | translate }}</span>
      </ion-button>

      <ion-button
        class="no-padding pull-right-md hidden-xs"
        fill="outline"
        (click)="SaveOrder()"
      >
        <ion-icon name="save-outline"></ion-icon>&nbsp;
        <span>{{ 'Ordrer.saveOrder' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
