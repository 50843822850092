import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FormSchemaService } from '../../services/form-schema.service';
import { format, parseISO } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { forkJoin, first, firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/shared/user-service';
import { ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.html',
  styleUrls: ['./dynamic-form.css']
})
export class DynamicFormPage implements OnInit {
  form: FormGroup;
  submitting = false;
  openModals: { [key: string]: boolean } = {};
  selectedDates: { [key: string]: string } = {};
  trucks:any[];
  formSchema: any[] = [];
  translations;
  formtypeId = 3;
  currentDate = new Date().toISOString();

  formMonthly = [
   {
    "type": "trucks",
    "label": "Lastebil / henger",
    "name": "truck",
    "value": "",
    "showComment": false,
    "comment": "",
    "validations": [  
      {
        "name": "required",
        "validator": "required",
        "message": "Lastebil/henger må fylles ut"
      }
    ]
  },
  {
    "type": "date",
    "label": "Brannslukker gyldig til",
    "name": "brannslukker",
    "value": "",
    "showComment": false,
    "comment": "",
    "validations": [
      {
        "name": "required",
        "validator": "required",
        "message": "Brannslukker gyldig til må fylles ut"
      }
    ]
  },
  {
    "type": "date",
    "label": "ADR-godkjenning gyldig til",
    "name": "adr",
    "value": "",
    "showComment": false,
    "comment": "",
    "validations": [
      {
        "name": "required",
        "validator": "required",
        "message": "ADR-godkj. Gyldig til må fylles ut"
      }
    ]
  },
  {
    "type": "date",
    "label": "Trykktest innen",
    "name": "trykktest",
    "value": "",
    "showComment": false,
    "comment": "",
    "validations": [
      {
        "name": "required",
        "validator": "required",
        "message": "Trykktest må fylles ut"
      }
    ]
  },
  {
    "type": "date",
    "label": "Kontroll mnd",
    "name": "date",
    "value": "2025-05-01",
    "showComment": false,
    "comment": "",
    "validations": [
      {
        "name": "required",
        "validator": "required",
        "message": "Kontroll mnd må fylles ut"
      }
    ]
  },
  {
        type: "textarea",
        label: "Kommentarer",
        name: "kommentarer",
        value: "",
        showComment: false,
        comment: "",
            
      },
  {
    "type": "array",
    "label": "Kontrollpunkt",
    "name": "kontrollpunkt",
    "showComment": false,
    "items": [
      {
        "type": "checkbox",
        "label": "Multiplugg",
        "name": "multiplugg",
        "value": false,
        "showComment": true,
        "comment": "",
    "validations": [
      {
        "name": "required",
        "validator": "required",
        "message": "Må fylles ut"
      }
    ]
      },
      {
        "type": "checkbox",
        "label": "Bilmappe m/godkjenning",
        "name": "bilmappe",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Hydrolikk system",
        "name": "hydrolikk_system",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Tanktopp drenering",
        "name": "tanktopp_dren",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Kipp ventil",
        "name": "kipp_ventil",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Overfyllingsvern",
        "name": "overfyllingsvern",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Bunnventiler",
        "name": "bunnventiler",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Gassretur system",
        "name": "gassretur_system",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Rørgater",
        "name": "rorgater",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Lekkasje/Svetting",
        "name": "lekkasje_svetting",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Tappeutstyr/Slanger",
        "name": "tappeutstyr_slanger",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Øyeskyllevann, dato",
        "name": "oyeskyllevanndato",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Jordingspunkt",
        "name": "jordingspunkt",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Startsperre",
        "name": "startsperre",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Hengerdrag",
        "name": "hengerdrag",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Pumpe",
        "name": "pumpe",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Måler / luftutskiller",
        "name": "maler_luftutskiller",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Oppsamlingskar/sekk",
        "name": "oppsamlingskar_sekk",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Absorberende strø",
        "name": "absorberende_stro",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Fareskilt",
        "name": "fareskilt",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Faresedler",
        "name": "faresedler",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Brannslukkere",
        "name": "brannslukkere",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Stoppeklosser 2 stk",
        "name": "stoppeklosser",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Varseltrekanter / lys",
        "name": "varseltrekanter_lys",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "ADR lommelykt",
        "name": "adr_lommelykt",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Refleksvest",
        "name": "refleksvest",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Varslingsplan",
        "name": "varslingsplan",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Skriftlige instrukser",
        "name": "skriftlige_instr",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "Spade/kost/sluktetter",
        "name": "spade_kost_sluktetter",
        "value": false,
        "showComment": true,
        "comment": ""
      },
      {
        "type": "checkbox",
        "label": "ID Plate",
        "name": "id_plate",
        "value": false,
        "showComment": true,
        "comment": ""
      }
    ]
  }
];

  formDaily = [
      {
        type: "trucks",
        label: "Lastebil / henger",
        name: "truck",
        value: "",
        showComment: false,
        comment: "",
        validations: [
          {
            name: "required",
            validator: "required",
            message: "Kjøretøy må fylles ut"
          }
        ]
      },
      {
        type: "date",
        label: "Kontrollert",
        name: "date",
        value: "",
        showComment: false,
        validations: [
          {
            name: "required",
            validator: "required",
            message: "Date må fylles ut"
          }
        ]
      },
      {
        type: "array",
        label: "Kontrollpunkter",
        name: "Kontrollpunkter",
        showComment: false,
        items: [
          {
            type: "checkbox",
            label: "Rent/Pent Inni hytta",
            name: "rentPent",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Lys",
            name: "lys",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Henger-drag",
            name: "hengerDrag",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Bremser",
            name: "bremser",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Dekk",
            name: "dekk",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Lekkasjer Luft/olje div",
            name: "lekkasjer",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Slanger Dropp/Pumpe",
            name: "slangerDropp",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          },
          {
            type: "checkbox",
            label: "Hjulmutter",
            name: "hjulmutter",
            value: false,
            showComment: true,
            comment: "",
            validations: [
              {
                name: "required",
                validator: "required",
                message: "Feltet må fylles ut"
              }
            ]
          }
        ]
      },
      {
        type: "textarea",
        label: "Kommentarer",
        name: "kommentarer",
        value: "",
        showComment: false,
        comment: "",
            
      }
    ];
  pageTitle: string = "Sjekkliste";


   constructor(private fb: FormBuilder, private formSchemaService: FormSchemaService,private translateService: TranslateService,
        private loadingController: LoadingController,private userService: UserService,    private toastController: ToastController,
        private route: ActivatedRoute,    private alertController: AlertController

) {}

  ngOnInit() {

        this.currentDate = new Date().toISOString();

    //load data
       this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

             this.translations = await firstValueFrom(forkJoin([
                    this.translateService.get('General.lukk'),
                    this.translateService.get('Documentation.checklist'),
                    this.translateService.get('Documentation.dailyCheckList'),
                    this.translateService.get('Documentation.monthlyCheckList')
                ])); 

            //set page title
            this.pageTitle = this.translations[2];

            loader.present().then(() => {
                // first() is called only then forkJoin would be called. Additionally here unsubscribe is not needed as it is first()
                forkJoin([this.userService.getCurrentTruckAndTrailer().pipe(first()),
                this.userService.getTrailersAndTrucks()]).subscribe(data => {
                   
                    this.trucks = [...data[1].Trucks, ...data[1].Trailers];
                
                    loader.dismiss();
                });
            });

        });

 this.route.queryParams.subscribe(params => {
      const formType = params['formType'];
      if (formType === 'monthly') {
        this.formSchema = this.formMonthly;
        this.formtypeId = 4;
        this.pageTitle = this.translations[3];
      } else if (formType === 'daily') {
        this.formSchema = this.formDaily;
        this.formtypeId = 3;
      } else {
        // Default to daily if no or invalid parameter is provided
        this.formSchema = this.formDaily;
        this.formtypeId = 3;
        
      }
      this.form = this.createForm(this.formSchema);
    });
  }
  
 createForm(schema: any): FormGroup {
    const group = this.fb.group({});
    schema.forEach(field => {
      if (field.type === 'array') {
        group.addControl(field.name, this.createFormArray(field.items, field.name));
      } else {
        const control = this.fb.control(field.value, this.bindValidations(field.validations || []));
        group.addControl(field.name, control);
        if (field.showComment) {
          group.addControl(field.name + '_comment', this.fb.control(''));
        }
        if (field.type === 'date') {
          this.selectedDates[field.name] = '';
          this.openModals[field.name] = false;
        }
      }
    });
    return group;
  }
createFormArray(items: any[], groupKey: string): FormArray {
  const arr = items.map(item => {
    const formGroup = this.createFormGroup(item, groupKey);
    if (item.type === 'checkbox') {
      formGroup.get(item.name)?.valueChanges.subscribe(value => {
        this.onCheckboxChange({ detail: { value } }, groupKey, item.name);
      });
    }
    return formGroup;
  });
  return this.fb.array(arr);
}

  createFormGroup(field: any, groupKey: string): FormGroup {
    const group: FormGroup<any> = this.fb.group({
      [field.name]: [field.value, this.bindValidations(field.validations || [])]
    });

    if (field.showComment) {
       const commentControl = this.fb.control('');
      group.addControl(field.name + '_comment', commentControl);
    

 if (field.type === 'checkbox') {
      if (!field.value) {
    
        commentControl.setValidators([Validators.required]);
      }
      group.get(field.name)?.valueChanges.subscribe(value => {
        this.onCheckboxChange({ detail: { value } }, groupKey,field.name);
      });
    }
  }
    return group;
  }

onCheckboxChange(event: any, groupKey: string, controlKey: string) {
  const formArray = this.form.get(groupKey) as FormArray;
  const checkboxControl = formArray.controls.find(ctrl => ctrl.get(controlKey))?.get(controlKey);
  const commentControl = formArray.controls.find(ctrl => ctrl.get(controlKey))?.get(controlKey + '_comment');
  
  if (checkboxControl && commentControl) {
    if (!checkboxControl.value) {
      commentControl.setValidators([Validators.required]);
    } else {
      commentControl.clearValidators();
    }
    commentControl.updateValueAndValidity();
  }
}



  getFormArrayControls(name: string): AbstractControl[] {
    const control = this.form.get(name);
    if (control instanceof FormArray) {
      return control.controls;
    }
    return [];
  }

  
   bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        if (valid.validator === 'required') {
          validList.push(Validators.required);
        }
        // Add more validators as needed
      });
      return Validators.compose(validList);
    }
    return null;
  }

  getFirstControlName(group: FormGroup): string {
    return Object.keys(group.controls)[0];
  }

  getFieldLabel(name: string): string {
    const field = this.getFieldByName(name);
    return field ? field.label : '';
  }

  getFieldPlaceholder(name: string): string {
    const field = this.getFieldByName(name);
    return field ? field.placeholder : '';
  }
  getFieldType(name: string): string {
    const field = this.getFieldByName(name);
    return field ? field.type : '';
  }

getFieldByName(name: string): any {
  for (const field of this.formSchema) {
    if (field.name === name) {
      return field;
    }
    if (field.type === 'array') {
      for (const item of field.items) {
        if (item.name === name) {
          return item;
        }
      }
    }
  }
  return null;
}

  

findInvalidControls(formGroup: FormGroup | FormArray): string[] {
  const invalidControls: string[] = [];
  Object.keys(formGroup.controls).forEach(key => {
    const control = formGroup.get(key);
    if (control instanceof FormGroup || control instanceof FormArray) {
      invalidControls.push(...this.findInvalidControls(control));
    } else if (control && control.invalid) {
      const errorMessage = this.getFieldValidationMessage(key, control);
      invalidControls.push(errorMessage || key);
    }
  });
  return invalidControls;
}

getFieldValidationMessage(key: string, control: AbstractControl): string | null {
  let fieldName = key;
  let isComment = false;

  if (key.endsWith('_comment')) {
    fieldName = key.replace('_comment', '');
    isComment = true;
  }

  const field = this.getFieldByName(fieldName);
  if (field && field.validations) {
    for (const validation of field.validations) {
      if (control.hasError(validation.validator)) {
        return isComment ? `${field.label} mangler kommentar.` : validation.message;
      }
    }
  }
  return null;
}

async showErrorToast(message: string) {
  const toast = await this.toastController.create({
    message: message,
    duration: 10000,
    color: 'danger',
    buttons: [
      {
        text: this.translations[0],
        role: 'cancel',
        handler: () => {
          console.log('Close clicked');
        }
      }
    ],
    position: 'middle', // Center the toast message
    cssClass: 'custom-toast' // Custom CSS class for further styling if needed
  });
  await toast.present();
}

  onDateChange(event: any, key: string) {
    const dateValue = event.detail.value;
    this.selectedDates[key] = format(parseISO(dateValue), 'yyyy-MM-dd');
      this.closeModal(key);  // Close the modal when a date is selected
  }
  closeModal(key: string) {
    this.openModals[key] = false;
  }

  openModal(key: string) {

    if(this.form.get(key).value ==''){
    const today = new Date();
    const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
    this.form.get(key).setValue(currentMonthStart);
    }
    this.openModals[key] = true;
  }

  

async onSubmit() {
  this.submitting = true;
  if (this.form.valid) {
this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });

                    loader.present().then(() => {

                      
    const formValue = this.form.value;
    const arrayItems = [];

   // Extract non-array items from form value
    for (const key in formValue) {
      if (!(this.form.get(key) instanceof FormArray)) {
        // Check if the item is a truck
        if (key === 'truck') {
          arrayItems.push({
            ArrayKey: '', // Not part of an array
            ItemKey: key,
            Value: formValue[key]?.Description || '',
            Comment: formValue[key + '_comment'] || '',
            ItemLabel: this.getFieldLabel(key) // Add the label for non-array fields
          });
        } else {
          arrayItems.push({
            ArrayKey: '', // Not part of an array
            ItemKey: key,
            Value: formValue[key],
            Comment: formValue[key + '_comment'] || '',
            ItemLabel: this.getFieldLabel(key) // Add the label for non-array fields
          });
        }
      }
    }

    // Extract array items from form value
    for (const key in formValue) {
      if (this.form.get(key) instanceof FormArray) {
        formValue[key].forEach(item => {
          for (const subKey in item) {
            if (subKey.endsWith('_comment')) {
              continue; // skip the comment fields here to handle them separately
            }
            
// Check if the item is a truck
            if (subKey === 'truck') {
              arrayItems.push({
                ArrayKey: key,
                ItemKey: subKey,
                Value: item[subKey]?.Description || '',
                Comment: item[subKey + '_comment'] || '',
                ItemLabel: this.getFieldLabel(subKey) // Add the label for array fields
              });
            } else {
              arrayItems.push({
                ArrayKey: key,
                ItemKey: subKey,
                Value: item[subKey],
                Comment: item[subKey + '_comment'] || '',
                ItemLabel: this.getFieldLabel(subKey) // Add the label for array fields
              });
            }

          }
        });
      }
    }

    const payload = {
      ...formValue,
      ArrayItems: arrayItems
    };

    const truckControl = this.form.get('truck');
    const dateControl = this.form.get('date');

    if (truckControl && dateControl) {
      const truckId = truckControl.value.Description;
      const date = dateControl.value;
      this.formSchemaService.checkExistingEntry(truckId, date, this.formtypeId).subscribe(async (response) => {
        if (response.existingId) {
          const alert = await this.alertController.create({
            header: 'Duplikat',
            message: 'Det finnes allrede et skjema for valgt dato og kjøretøy, vil du overskrive skjema med dette?',
            buttons: [
              {
                text: this.translations[0],
                role: 'cancel',
                handler: () => {
                  this.form.reset();
                  this.form = this.createForm(this.formSchema);
                }
              },
              {
                text: 'OK',
                handler: async () => {
                  payload.FormDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
                  payload.Created = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
                  payload.FormType = this.formtypeId;
                  payload.FormObject = truckId;
                  payload.Id = response.existingId;
                  await this.saveForm(payload);
                }
              }
            ]
          });
          await alert.present();
        } else {
            payload.FormDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
            payload.Created = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
            payload.FormType = this.formtypeId;
            payload.FormObject = truckId;
          await this.saveForm(payload);
        }
      });
    }
             loader.dismiss();
            });
        });
    
  } else {
    const invalidFields = this.findInvalidControls(this.form);
    const errorToast = await this.toastController.create({
      message: ` ${invalidFields.join(', ')}`,
      duration: 5000,
      color: 'danger',
      position: 'middle',
      buttons: [
        {
          text: this.translations[0],
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    await errorToast.present();
    console.log('Form is invalid');
  }
  this.submitting = false;
}




async saveForm(data:any) {
  this.formSchemaService.submitForm(data).subscribe(async () => {
    const successToast = await this.toastController.create({
      message: 'Skjema er sendt inn!',
      duration: 3000,
      color: 'success',
      position: 'middle',
      buttons: [
        {
          text: this.translations[0],
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    });
    await successToast.present();
    this.form.reset();
    this.form = this.createForm(this.formSchema);
  });
}

}
