import { NgModule, Injectable, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { from, Observable } from 'rxjs';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HTTP_INTERCEPTORS, HttpClientModule, HttpClient
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { createTranslateLoader } from '../app/loader/create-translate-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Geolocation } from '@capacitor/geolocation';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import * as HighCharts from 'highcharts';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { Network } from '@capacitor/network';
import { switchMap, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { AppSettings } from './shared/app-settings';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
//import { HighchartsChartModule } from 'highcharts-angular';

import { LogoutComponent } from './components/logout/logout';
import { DrivingInstructionsComponent } from './components/driving-instructions/driving-instructions';
import { TruckTrailerChangeComponent } from './components/truck-trailer-change/truck-trailer-change';
import { MapMarkerComponent } from './components/map-marker/map-marker';
import { LeggTilProduktComponent } from './components/legg-til-produkt/legg-til-produkt';
import { ConvertProductComponent } from './components/convert-product/convert-product';
import { OrderlineSplitComponent } from './components/orderline-split/orderline-split';
import { LeggTilProduktSalesComponent } from './components/legg-til-produkt-sales/legg-til-produkt-sales';
import { RichTextComponent } from './components/rich-text/rich-text';
import { OrderConfirmComponent } from './components/order-confirm/order-confirm';
import { OrderConfirmSalesComponent } from './components/order-confirm-sales/order-confirm-sales';
import { BeholdningLagreConfirmComponent } from './components/beholdning-lagre-confirm/beholdning-lagre-confirm';
import { EgneTankerConfirmComponent } from './components/egne-tanker-confirm/egne-tanker-confirm';
import { OrderNextDayConfirmComponent } from './components/order-next-day-confirm/order-next-day-confirm';
import { NewMessageComponent } from './components/new-message/new-message';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password';
import { WorkLeggTilProduktComponent } from './components/work-legg-til-produkt/work-legg-til-produkt';
import { WorkOrderConfirmComponent } from './components/work-order-confirm/work-order-confirm';
import { EditOrderSharedComponent } from './components/edit-order-shared/edit-order-shared';
import { CustomerSignComponent } from './components/customer-sign/customer-sign';
import { ChecklistViewPage } from '../app/pages/checklist-view/checklist-view';

import { HomePage } from '../app/pages/home/home';
import { BeholdningPage } from '../app/pages/beholdning/beholdning';
import { EgneTankerAutomaterPage } from '../app/pages/egne-tanker-automater/egne-tanker-automater';
import { OrdrerPage } from '../app/pages/ordrer/ordrer';
import { OrdrerSalesPage } from '../app/pages/ordrer-sales/ordrer-sales';
import { OrdreDetailsPage } from '../app/pages/ordre-details/ordre-details';
import { OrdreDetailsSalesPage } from '../app/pages/ordre-details-sales/ordre-details-sales';
import { LoginPage } from '../app/pages/login/login';
import { NewOrderPage } from '../app/pages/new-order/new-order';
import { CustomerCreatePage } from '../app/pages/customer-create/customer-create';
import { NewOrderSalesPage } from '../app/pages/new-order-sales/new-order-sales';
import { EgneTankerSelectPage } from '../app/pages/egne-tanker-select/egne-tanker-select';
import { OrderAssignmentPage } from '../app/pages/order-assignment/order-assignment';
import { OrderplannerPage } from '../app/pages/order-planner/order-planner';
import { SettingsPage } from '../app/pages/settings/settings';
import { ActivityRegistrationPage } from '../app/pages/activity-registration/activity-registration';
import { TransactionLogPage } from '../app/pages/transaction-log/transaction-log';
import { ActivityRegistrationMultiplePage } from '../app/pages/activity-registration-multiple/activity-registration-multiple';
import { ReleaseNotesPage } from '../app/pages/release-notes/release-notes';
import { TankMonitoringPage } from '../app/pages/tank-monitoring/tank-monitoring';
import { TankMonitoringPage2 } from '../app/pages/tank-monitoring2/tank-monitoring2';
import { StockGoodsPage } from './pages/stock-goods/stock-goods';
import { SooloTanklevelsPage } from './pages/soolo-tanklevels/soolo-tanklevels';
import { WorkOrdersPage } from '../app/pages/work-orders/work-orders';
import { WorkOrdersDocumentsPage } from '../app/pages/work-orders-documents/work-orders-documents';
import { WorkOrderDetailsPage } from '../app/pages/work-order-details/work-order-details';
import { WorkNewOrderPage } from '../app/pages/work-new-order/work-new-order';
import { WorkOrderHistoryPage } from '../app/pages/work-order-history/work-order-history';
import { WorkorderApprovalPage } from './pages/workorder-approval/workorder-approval';
import { DocumentationPage } from '../app/pages/documentation/documentation';
import { NewDeviationPage } from '../app/pages/new-deviation/new-deviation';
import { CustomerLeadPage } from '../app/pages/customer-lead/customer-lead';
import { OrdrersProductPage } from '../app/pages/orders-product/orders-product';
import { OrdreDetailsProductPage } from '../app/pages/ordre-details-product/ordre-details-product';
import { NewOrderProductPage } from '../app/pages/new-order-product/new-order-product';
import { DocumentsPage } from '../app/pages/documents/documents';
import { FleetPage } from '../app/pages/fleet/fleet';
import { LeadViewPage } from '../app/pages/lead-view/lead-view';
import { FormsHandlingPage } from '../app/pages/forms-handling/forms-handling';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChecklistMonthlyControlPage } from '../app/pages/checklist-monthly-control/checklist-monthly-control';
import { TankMonitoringSelectPage } from '../app/pages/tank-monitoring-select/tank-monitoring-select';
import { BaseHttpClient } from './shared/http-client';
import { CompanyService } from './shared/company-service';
import { UserService } from './shared/user-service';
import { AuthService } from './shared/auth-service';
import { KCMAErrorHandler } from './shared/kcma-error-handler';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { PhoneRegexDirective } from './shared/directives/phone-regex.directive';
import { RemoveZeroOnFocusDirective } from './components/remove-zero-on-focus/remove-zero-on-focus';
import { SafePipe } from './shared/pipes/safe';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { API_URL, ERP_ID_PARENT, IS_CUSTOMER_NO } from './shared/tokens';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { IonicSelectableComponent } from 'ionic-selectable';
import { DynamicFormPage } from './pages/dynamic-form/dynamic-form';
import { BaseHttpClient2 } from './shared/http-client2';
import { FormSchemaService } from './services/form-schema.service';
import { CustomerSearchComponent } from './components/customer-search/customer-search/customer-search.component';
import { CustomerAddressSearchComponent} from './components/customer-address-search/customer-address-search/customer-address-search.component'
import { MapViewerComponent } from './components/map-viewer/map-viewer';

@Injectable()
export class AngularInterceptor implements HttpInterceptor {
  constructor(private storage: Storage) {
    
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storage.get(AppSettings.StorageKeys.Token)).pipe(switchMap(token => {
      if (token) {
        if (!request.url.includes('soolo.no')) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        } else {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + AppSettings.NorTraceBearer) });
        }
      }
      if (!request.headers.has('Accept')) {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
      }
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }));
    }));
  }
}

declare global {
  interface String {
    format(...replacements: string[]): string;
  }
}

declare var require: any;
export function highchartsFactory() {
  const hc = require('highcharts');
  const hm = require('highcharts/highcharts-more');
  const mr = require('highcharts/modules/solid-gauge');
  mr(hc);
  hm(hc);
  return hc;
}

if (!String.prototype.format) {
  String.prototype.format = function format() {
    const args = arguments;
    return this.replace(/{(\d+)}/g, function replace(match, num) {
      return typeof args[num] !== 'undefined'
        ? args[num]
        : match;
    });
  };
}
@NgModule({
    declarations: [AppComponent,
        LogoutComponent,
        TruckTrailerChangeComponent,
        DrivingInstructionsComponent,
        SafePipe,
        MapMarkerComponent,
        LeggTilProduktComponent,
        LeggTilProduktSalesComponent,
        ConvertProductComponent,
        RichTextComponent,
        OrderConfirmComponent,
        OrderConfirmSalesComponent,
        BeholdningLagreConfirmComponent,
        EgneTankerConfirmComponent,
        RemoveZeroOnFocusDirective,
        OrderNextDayConfirmComponent,
        NewMessageComponent,
        ForgotPasswordComponent,
        ClickOutsideDirective,
        PhoneRegexDirective,
        WorkLeggTilProduktComponent,
        WorkOrderConfirmComponent,
        EditOrderSharedComponent,
        CustomerSearchComponent,
        CustomerAddressSearchComponent,
        CustomerSignComponent,
        ChecklistViewPage,
        BeholdningPage,
        HomePage,
        TransactionLogPage,
        EgneTankerAutomaterPage,
        EgneTankerSelectPage,
        OrdrerPage,
        OrdrerSalesPage,
        OrdreDetailsPage,
        OrdreDetailsSalesPage,
        LoginPage,
        StockGoodsPage,
        SooloTanklevelsPage,
        OrderAssignmentPage,
        OrderplannerPage,
        SettingsPage,
        ActivityRegistrationPage,
        ActivityRegistrationMultiplePage,
        ReleaseNotesPage,
        TankMonitoringPage,
        TankMonitoringPage2,
        NewOrderPage,
        NewOrderSalesPage,
        CustomerCreatePage,
        WorkOrdersPage,
        WorkOrdersDocumentsPage,
        WorkOrderDetailsPage,
        WorkNewOrderPage,
        WorkOrderHistoryPage,
        WorkorderApprovalPage,
        DocumentationPage,
        NewDeviationPage,
        CustomerLeadPage,
        OrdrersProductPage,
        OrdreDetailsProductPage,
        NewOrderProductPage,
        DocumentsPage,
        FleetPage,
        LeadViewPage,
        FormsHandlingPage,
        ChecklistMonthlyControlPage,
        TankMonitoringSelectPage,
        OrderlineSplitComponent,
        DynamicFormPage,
        MapViewerComponent
        ],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,IonicSelectableComponent,
        MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    
        NgxPaginationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        LeafletModule,
        IonicStorageModule.forRoot(),
        AngularSignaturePadModule,
        DragulaModule, HttpClientModule],
    providers: [
        ScreenOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        HttpClientModule,
        BaseHttpClient,
        BaseHttpClient2,
        FormSchemaService,
        CompanyService,
        UserService,
        AuthService,
        DragulaService,
        {
            provide: HighCharts,
            useFactory: highchartsFactory
        },
        //   BarcodeScanner,
        { provide: ErrorHandler, useClass: KCMAErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AngularInterceptor,
            multi: true
        },
        {provide: API_URL, useValue: AppSettings.API_ENDPOINT},
        {provide: ERP_ID_PARENT, useValue: 0},
        {provide: IS_CUSTOMER_NO, useValue: false}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
