import { Component } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';
@Component({
    selector: 'page-work-order-history',
    templateUrl: 'work-order-history.html',
    styleUrls: ['work-order-history.scss'],
})

export class WorkOrderHistoryPage {
    orders: any[];
    sortProperty = 'OrderSorting';
    sortDirection = 'asc';
    dateFormat;
    constructor(
        private userService: UserService, private translateService: TranslateService, private navCtrl: NavController,
        private loadingController: LoadingController, private router: Router, private route: ActivatedRoute) {
        this.loadData();
        this.translateService.get('General.dateFormat').subscribe(data => { this.dateFormat = data; });
    }

    loadData() {
        this.route.queryParams.subscribe(params => {
            const deliveryActorNo = JSON.parse(params.deliveryActorNo);
            this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                this.userService.getCustomerHistory(deliveryActorNo).subscribe(data => {
                    this.orders = _.orderBy(data, 'DeliveryDate', 'desc');
                });
            });
        });
    }

    sort(property) {
        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'desc';
        }
        this.orders = Utils.orderBy(this.orders, this.sortProperty, this.sortDirection);
    }

    getDateText(order) {
        let deliveryDate;
        deliveryDate = new Date(order.OrderDate);
        return this.translateService.get('General.dateFormat')
            .pipe(switchMap(format => of(moment(deliveryDate).format(format.toUpperCase()))));
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    navigateToBack() {
        this.navCtrl.pop();
    }

}
