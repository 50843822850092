<ion-content>
  <form [formGroup]="detailsForm" class="order-shared">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <ion-list lines="none" class="ion-padding">
            <ion-item class="text-small ion-no-padding z-index">
              <ion-label>
                <p>
                  <ion-icon
                    name="person-outline"
                    color="lightFive"
                    class="ion-margin-top"
                  ></ion-icon>
                  &nbsp;<ion-text color="primary">
                    <span>{{ 'NewOrder.kunde' | translate }}</span></ion-text
                  >
                </p>
              </ion-label>
            </ion-item>
            <div class="completer-container address">
              <ion-item class="text-small ion-no-padding input-container">
                <ion-row class="w-100">
                  <ion-col size-xs="8" size-md="10" class="ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.customerName' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      formControlName="customerName"
                      placeholder="{{ 'NewOrder.customerName' | translate }}"
                      #customerNameInput
                      (ionInput)="checkInput($event)"
                    ></ion-input>
                  </ion-col>
                  <ion-col size-xs="4" size-md="2" class="ion-col-button">
                    <ion-button
                      fill="outline"
                      (click)="initCustomerSearch()"
                      class="ion-input-button"
                    >
                      <ion-icon name="search-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-item>
            </div>
            <ion-item
              class="item-validation ion-no-padding"
              *ngIf="!detailsForm.controls.customerName.valid && (detailsForm.controls.customerName.dirty)"
            >
              <ion-text
                color="danger"
                *ngIf="detailsForm.controls.customerName.errors.required"
              >
                <p>
                  {{ 'NewOrder.validationcustomerNameRequired' | translate }}
                </p>
              </ion-text>
              <ion-text
                color="danger"
                *ngIf="detailsForm.controls.customerName.errors.incorrect"
              >
                <p>{{'NewOrder.customerBlockedStatus' | translate }}</p>
              </ion-text>
            </ion-item>
            <ion-item class="text-small ion-no-padding">
              <input
                type="text"
                placeholder="{{ 'NewOrder.customerAddress1' | translate }}"
                formControlName="customerAddress1"
              />
            </ion-item>
            <ion-item
              class="item-validation ion-no-padding"
              *ngIf="!detailsForm.controls.customerAddress1.valid && (detailsForm.controls.customerAddress1.dirty)"
            >
              <ion-text color="danger">
                <p>{{ 'NewOrder.validationAddress1Required' | translate }}</p>
              </ion-text>
            </ion-item>
            <ion-item class="text-small ion-no-padding">
              <input
                type="text"
                placeholder="{{ 'NewOrder.customerAddress2' | translate }}"
                formControlName="customerAddress2"
              />
            </ion-item>

            <ion-item class="text-small ion-no-padding">
              <ion-row class="completer-row2">
                <ion-col size-xs="3" class="ion-no-padding">
                  <input
                    type="text"
                    placeholder="{{ 'NewOrder.zip' | translate }}"
                    formControlName="customerPostalCode"
                  />
                </ion-col>
                <ion-col size-xs="9" class="ion-no-padding">
                  <div class="city">
                    <input
                      type="text"
                      placeholder="{{ 'NewOrder.city' | translate }}"
                      formControlName="customerCity"
                    />
                  </div>
                </ion-col>
              </ion-row>
            </ion-item>
            <ion-item
              class="item-validation ion-no-padding"
              *ngIf="(!detailsForm.controls.customerCity.valid || !detailsForm.controls.customerPostalCode.valid) && (detailsForm.controls.customerCity.dirty || detailsForm.controls.customerPostalCode.dirty)"
            >
              <ion-row class="completer-row">
                <ion-col size-xs="3" class="ion-no-padding">
                  <ion-text color="danger">
                    <p
                      *ngIf="!detailsForm.controls.customerPostalCode.valid && (detailsForm.controls.customerPostalCode.dirty)"
                    >
                      {{ 'NewOrder.validationZipRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>
                <ion-col size-xs="9" class="ion-no-padding">
                  <ion-text color="danger">
                    <p
                      class="cityVal"
                      *ngIf="!detailsForm.controls.customerCity.valid && (detailsForm.controls.customerCity.dirty)"
                    >
                      {{ 'NewOrder.validationCityRequired' | translate }}
                    </p>
                  </ion-text>
                </ion-col>
              </ion-row>
            </ion-item>

            <ion-item class="text-small ion-no-padding">
              <select id="priceAgreements" formControlName="priceAgreementID">
                <option value="">
                  {{ 'NewOrder.priceAgreement' | translate }}
                </option>
                <option
                  *ngFor="let item of priceAgreements"
                  value="{{item.ID}}"
                >
                  {{item.Description}}
                </option>
              </select>
            </ion-item>

            <ion-item class="text-small address ion-no-padding z-index">
              <ion-label>
                <p>
                  <ion-icon
                    name="location-outline"
                    color="lightFive"
                  ></ion-icon>
                  &nbsp;<ion-text color="primary"
                    ><span
                      >{{ 'NewOrder.leveringsadresse' | translate }}</span
                    ></ion-text
                  >
                </p>
              </ion-label>
            </ion-item>
            <div class="completer-container address">
              <ion-item class="text-small ion-no-padding">
                <ion-row class="w-100">
                  <ion-col size-xs="8" size-md="10" class="ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewCustomer.deliveryName' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewCustomer.deliveryName' | translate }}"
                      formControlName="address"
                      #addressInput
                    />
                  </ion-col>
                  <ion-col
                    size-xs="4"
                    size-md="2"
                    class="ion-no-padding ion-col-button"
                  >
                    <ion-button
                      fill="outline"
                      (click)="initCustomerAddressSearch()"
                      class="ion-input-button"
                    >
                      <ion-icon name="search-outline"></ion-icon>
                    </ion-button>

                    <ion-button
                      fill="outline"
                      (click)="clearAddress()"
                      class="ion-input-button"
                    >
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-item>
              <div class="clear"></div>
            </div>
            <ion-item class="text-small ion-no-padding">
              <input
                type="text"
                placeholder="{{ 'NewOrder.customerAddress1' | translate }}"
                formControlName="addressAddress1"
              />
            </ion-item>
            <ion-item class="text-small ion-no-padding">
              <input
                type="text"
                placeholder="{{ 'NewOrder.customerAddress2' | translate }}"
                formControlName="addressAddress2"
              />
            </ion-item>
            <ion-item class="text-small ion-no-padding">
              <ion-row class="completer-row2">
                <ion-col size-xs="3" class="ion-no-padding">
                  <input
                    type="text"
                    placeholder="{{ 'NewOrder.zip' | translate }}"
                    formControlName="addressZip"
                  />
                </ion-col>
                <ion-col size-xs="9" class="ion-no-padding">
                  <input
                    type="text"
                    placeholder="{{ 'NewOrder.city' | translate }}"
                    class="city"
                    formControlName="addressCity"
                  />
                </ion-col>
              </ion-row>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
<ion-footer>
  <ion-buttons class="ion-float-right ion-margin">
    <div class="ion-float-right">{{ saveText }}</div>
    <ion-button small (click)="dismiss()" fill="outline" size="small">
      <span>{{ 'General.avbryt' | translate }}</span>
    </ion-button>
    <ion-button
      padding-right
      small
      (click)="updateOrder()"
      [disabled]="!detailsForm.valid"
      fill="solid"
      size="small"
    >
      <span>{{ 'General.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</ion-footer>
