import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { AppSettings } from './app-settings';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
  })
export class CompanyService {
    private companies: any;
    private company: any;

    constructor(private http: HttpClient) {
    }

    getCompanies(): Observable<any> {
        if (this.companies) {
            return of(this.companies);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/Company/GetCompanies`).pipe(map((response: Response) => {
                if (response != undefined) {
                    this.companies = response;
                    return this.companies;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

    getCompany(clientId: number): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/Company/GetCompany/?clientId=${clientId}`).pipe(map((response: Response) => {
            if (response !=undefined) {
                this.company = response.json();
                return this.company;
            } else {
                return 'FAILURE';
            }
        }));

    }
}
