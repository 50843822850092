<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'WorkOrders.orderHistory' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px;">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <div class="max-100">
            <div class="grid-width">
              <ion-row class="grid-header">
                <ion-col size-xs="3" size-md="1">
                  <strong>{{ 'Ordrer.ordre' | translate }}</strong>
                  <ion-button (click)="sort('OrderNo')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">
                    <ion-icon ion-text color="lightFour"
                      [name]="(sortProperty == 'OrderNo' && sortDirection == 'asc') || sortProperty != 'OrderNo' ? 'caret-up' : 'caret-down'">
                    </ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col size-xs="9" size-md="4">
                  <strong>{{ 'Ordrer.kunde' | translate }}</strong>
                  <ion-button (click)="sort('DeliveryName')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">
                    <ion-icon ion-text color="lightFour"
                      [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'">
                    </ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col class="hidden-xs" size-xs="6" size-md="3">
                </ion-col>
                <ion-col class="hidden-xs" size-xs="6" size-md="4">
                </ion-col>
              </ion-row>

              <ion-list>
                <ion-row *ngFor="let order of orders; let isOdd = odd; let i = index" [class.odd]="isOdd"
                  [attr.itemid]="order.OrderNo" id="{{order.OrderNo}}">
                  <ion-col>
                    <ion-row>
                      <ion-col size-xs="3" size-md="1" class="handle">
                        <div (click)="expandOrder(order)" class="cursor-pointer">
                          <ion-text>
                            <strong>{{order.OrderNo}}</strong></ion-text>
                        </div>
                      </ion-col>
                      <ion-col size-xs="9" size-md="3">
                        <div class="cursor-pointer" (click)="expandOrder(order)">
                          <strong>{{order.DeliveryName}}</strong>
                          <br /> {{ order.BackofficeStatus }}
                        </div>
                      </ion-col>
                      <ion-col size-xs="6" size-md="2">
                        <strong>
                          <span [innerText]="getDateText(order) | async"></span>&nbsp;
                        </strong> {{order.AssignedDriver}}
                      </ion-col>
                      <ion-col size-xs="6" size-md="4">
                        <strong>
                         
                        </strong> {{order.OrderComments}}
                      </ion-col>
                      
                      <ion-col size="2" class="ion-text-right">
                        <ion-button (click)="expandOrder(order)" fill="solid" color="success">
                          <strong><ion-icon name="checkbox-outline" class="vertical-middle"></ion-icon> &nbsp;
                          {{ 'WorkOrders.details' | translate }}</strong>
                        </ion-button>
                      </ion-col>
                      <ion-col size="12" *ngIf="order.expandedView">
                        <ion-list lines="none">
                          <ion-item class="text-small">
                            <ion-row class="completer-row">
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon name="calendar-outline" class="vertical-text-bottom" color="lightFive">
                                  </ion-icon> &nbsp;
                                  <ion-text color="primary"> <span>{{ 'OrdreDetails.leveringsdato' | translate }}</span>
                                  </ion-text>
                                </p>
                                <strong>{{order.DeliveryDate | date:dateFormat}}</strong>
                                <span *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"> /
                                  <ion-icon name="alert-circle-outline" class="circle vertical-text-bottom"
                                    color="lightFive"></ion-icon>&nbsp;{{
                                    order.ConfirmedDeliveryDate | date:dateFormat}}</span>
                              </ion-col>
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon name="person-outline" color="lightFive" class="vertical-text-bottom"></ion-icon>
                                  &nbsp;
                                  <ion-text color="primary"><span>{{ 'OrdreDetails.CustomerBuyer' | translate }}</span>
                                  </ion-text>
                                </p> {{order.CustomerBuyer}}
                              </ion-col>
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon name="person-outline" color="lightFive" class="vertical-text-bottom"></ion-icon>
                                  &nbsp;
                                  <ion-text color="primary"><span>{{ 'OrdreDetails.OurSeller' | translate }}</span>
                                  </ion-text>
                                </p>{{order.Seller}}
                              </ion-col>
                            </ion-row>
                          </ion-item>
                          <ion-item *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo"
                            class="text-small">
                            <ion-label>
                              <p>
                                <ion-icon name="cube-outline" class="vertical-text-bottom"></ion-icon> &nbsp;
                                <ion-text color="primary">{{ 'OrdreDetails.leveringsinfo' | translate }}</ion-text>
                              </p>
                              <ng-container *ngIf="order.InternalInfo">{{order.InternalInfo}}
                                <br />
                              </ng-container>
                              <ng-container *ngIf="order.TankInfo">{{order.TankInfo}}
                                <br />
                              </ng-container>
                              <ng-container *ngIf="order.DeliveryInfo">{{order.DeliveryInfo}}
                                <br />
                              </ng-container>
                            </ion-label>
                          </ion-item>
                          <ion-item class="text-small">
                            <ion-label>
                              <p>
                                <ion-icon name="call-outline" class="vertical-text-bottom"></ion-icon> &nbsp;
                                <ion-text color="primary"><span>{{ 'OrdreDetails.kontaktinfo' | translate }}</span>
                                </ion-text>
                              </p>
                              <ion-row>
                                <ion-col size-xs="6">
                                  <div *ngIf="order.PhoneOnSite">
                                    <b>{{ 'OrdreDetails.onSite' | translate }}</b>
                                    <br /> {{order.PhoneOnSite}}
                                  </div>
                                </ion-col>
                                <ion-col size-xs="6">
                                  <div *ngIf="order.PhoneManager">
                                    <b>{{ 'OrdreDetails.manager' | translate }}</b>
                                    <br /> {{order.PhoneManager}}
                                  </div>
                                </ion-col>
                              </ion-row>
                            </ion-label>
                          </ion-item>
                          <div class="text-small ion-margin-bottom ion-margin-start"
                            *ngIf="order.Orderlines.length > 0">
                            <p>
                              <ion-icon name="flame-outline" color="lightFive" class="vertical-text-bottom"></ion-icon>&nbsp;
                              <ion-text color="primary"><span ion-text>
                                  {{'OrdreDetails.produkter' | translate }}</span>
                              </ion-text>
                            </p>
                            <ion-row>
                              <ion-col size-xs="9" size-md="5"><b>{{ 'OrdreDetails.produkt' | translate | uppercase }}</b></ion-col>
                              <ion-col size-xs="3" size-md="1"><b>{{ 'OrdreDetails.levert' | translate | uppercase }}</b></ion-col>
                              <ion-col class="hidden-xs" size-xs="1"><b>{{ 'OrdreDetails.cost' | translate | uppercase }}</b></ion-col>
                              <ion-col class="hidden-xs" size-xs="1"><b>{{ 'OrdreDetails.pris' | translate | uppercase }}</b></ion-col>
                              <ion-col class="hidden-xs" size-xs="1"><b>{{ 'OrdreDetails.rab' | translate | uppercase }}</b></ion-col>
                              <ion-col class="hidden-xs" size-xs="2"><b>{{ 'OrdreDetails.total' | translate | uppercase }}</b></ion-col>
                              <ion-col class="hidden-xs" size-xs="1"><b>{{ 'OrdreDetails.DB' | translate | uppercase }}</b></ion-col>
                            </ion-row>
                            <ion-row *ngFor="let orderLine of order.Orderlines">
                              <ion-col size-xs="9" size-md="5">
                                <div class="flex-parent">
                                  <ion-badge [style.background]="orderLine.ProductColorCode">
                                    {{orderLine.ProductShortCode}}</ion-badge>&nbsp;
                                  <span class="truncate-text">{{orderLine.Description}}</span>
                                </div>
                              </ion-col>
                              <ion-col size-xs="3" size-md="1">
                                <span
                                  class="slight-big-font"><strong>{{orderLine.QuantityDelivered | number:'1.0-1'}}</strong></span>
                              </ion-col>
                              <ion-col class="hidden-xs" size-xs="1">{{ orderLine.UnitCost | number:'1.1-3'}}</ion-col>
                              <ion-col class="hidden-xs" size-xs="1">{{ orderLine.UnitPrice | number:'1.1-3'}}</ion-col>
                              <ion-col class="hidden-xs" size-xs="1">{{ orderLine.UnitDiscount | number:'1.0-1'}}</ion-col>
                              <ion-col class="hidden-xs" size-xs="2">{{ orderLine.OrderLineTotal | number:'1.0-0'}}</ion-col>
                              <ion-col class="hidden-xs" size-xs="1">
                                {{ orderLine.OrderLineTotal-(orderLine.UnitCost * orderLine.QuantityDelivered)| number:'1.0-0'}}
                              </ion-col>
                            </ion-row>
                          </div>
                        </ion-list>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-list>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
      <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
      <span class="black">{{ 'OrdreDetails.tilbake' | translate }}</span>
    </ion-button>
  </div>
</ion-footer>