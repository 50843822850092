<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-text color="primary" class="ion-margin-top">
          <h6>
            <ion-icon name="swap-horizontal-outline"></ion-icon> {{
            'TruckTrailer.velgHvilkenBilOgHenger' | translate }}
          </h6>
        </ion-text>
        <ion-list lines="none">
          <form [formGroup]="trailerForm">
            <ion-item>
              <img src="assets/icon/Icon_Truck.svg" class="truck-trailer" />
              <select formControlName="truckID" class="select">
                <option value="">{{ 'General.select' | translate }}</option>
                <option *ngFor="let truck of trucks" value="{{truck.ID}}">
                  {{truck.Description}}
                </option>
              </select>
            </ion-item>
            <ion-item
              class="item-validation"
              *ngIf="!trailerForm.controls.truckID.valid && (trailerForm.controls.truckID.dirty || submitAttempt)"
            >
              <ion-text color="danger">
                <p>{{ 'TruckTrailer.validationTruckRequired' | translate }}</p>
              </ion-text>
            </ion-item>
            <ion-item>
              <img src="assets/icon/Icon_Trailer.svg" class="truck-trailer" />
              <select formControlName="trailerID" class="select">
                <option value="">{{ 'General.select' | translate }}</option>
                <option *ngFor="let trailer of trailers" value="{{trailer.ID}}">
                  {{trailer.Description}}
                </option>
              </select>
            </ion-item>
            <ion-item
              class="item-validation"
              *ngIf="!trailerForm.controls.trailerID.valid && (trailerForm.controls.trailerID.dirty || submitAttempt)"
            >
              <ion-text color="danger">
                <p>
                  {{ 'TruckTrailer.validationTrailerRequired' | translate }}
                </p>
              </ion-text>
            </ion-item>
            <ion-item class="ion-float-right">
              <ion-buttons>
                <ion-button
                  (click)="dismiss()"
                  [disabled]="submitting"
                  color="grey"
                  size="small"
                  fill="outline"
                >
                  <span>{{ 'General.avbryt' | translate }}</span>
                </ion-button>
                <ion-button
                  (click)="saveTrailerAndTruck()"
                  [disabled]="!trailerForm.valid || submitting"
                  size="small"
                  fill="solid"
                >
                  <span>{{ 'General.save' | translate }}</span>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
