import { Component, OnDestroy } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, AlertController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TransactionType } from '../../shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { OrderNextDayConfirmComponent } from '../../components/order-next-day-confirm/order-next-day-confirm';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLauncher } from '@capacitor/app-launcher';
import { Geolocation } from '@capacitor/geolocation';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LeggTilProduktSalesComponent } from '../../components/legg-til-produkt-sales/legg-til-produkt-sales';
import { CustomValidators } from '../../shared/validators';
import { OrderConfirmSalesComponent } from '../../components/order-confirm-sales/order-confirm-sales';
import { Storage } from '@ionic/storage-angular';
import { EditOrderSharedComponent } from '../../components/edit-order-shared/edit-order-shared';
import { CustomerSignComponent } from '../../components/customer-sign/customer-sign';

@Component({
    selector: 'page-ordre-details-product',
    templateUrl: 'ordre-details-product.html',
    styleUrls: ['ordre-details-product.scss']
})
export class OrdreDetailsProductPage implements OnDestroy {
    order: any;
    dateFormat;
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    currentLatitude: number = null;
    currentLongitude: number = null;
    VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = false;
    private currentTruckTrailerSub$: Subscription;
    private deviationLimit = 0;
    private inventoryList: any;
    errorMessages: any = [];
    products: any[];
    isHidden: boolean;
    private CameFromApproval: boolean;
    private navigationExtras: NavigationExtras;
    allowDescriptionChange;
    expand = false;
    location = location.pathname;
    hideCost = false;
    userlevel;
    private posOptions = {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 30000
            };

    constructor(
        private formBuilder: FormBuilder, private route: ActivatedRoute, public userService: UserService,
        public navCtrl: NavController, private router: Router, private alertCtrl: AlertController,
        private translateService: TranslateService, private loadingController: LoadingController, private modalCtrl: ModalController,
        private geolocation: Geolocation, private toastController: ToastController,
        private storage: Storage) {
        this.route.queryParams.subscribe(params => {
            this.order = JSON.parse(params.order);
            this.CameFromApproval = params.approval;
            //    this.order = JSON.parse(params['order']);
            this.CameFromApproval = (params.approval.toString() === 'true');
            this.userlevel = AppSettings.UserLevel;

            // used to pass info to orders view for init an update of orderslist
            this.navigationExtras = {
                queryParams: {
                    reloadData: true
                },
                skipLocationChange: true
            };

            this.userService.smUpLargeScreen.subscribe(data => {
                if (data) {
                    this.expand = data;
                }
            });
            const orderlinesGroup: FormGroup = new FormGroup({});
            this.currentTruckTrailerSub$ = this.userService.getCurrentTruckAndTrailer().subscribe(resp => {
                if (AppSettings.ValidateTruckStock) {
                    this.userService.getCurrentInventoryCurrent(null, null).subscribe(data => {
                        this.inventoryList = data.InventoryList2;
                        this.userService.getDeviationLimit(TransactionType.LoadingFromTruck).subscribe(totalData => {
                            this.deviationLimit = totalData.DeviationLimit;
                        });
                    });
                }
            });
            // console.log(this.order);
            this.order.TotalOrder = 0;
            this.storage.get(AppSettings.StorageKeys.AllowDescriptionChange).then(data => {
                this.allowDescriptionChange = data;
                if (this.order.Orderlines) {
                    for (const orderline of this.order.Orderlines) {
                        this.dynamicControl(orderlinesGroup, orderline);
                    }
                    this.getTotalOrder();
                }
            });

            this.detailsForm = formBuilder.group({
                orderLines: orderlinesGroup,
                orderComments: [this.order.OrderComments],
                orderInternalComments: [this.order.OrderInternalComments],
                internalInfo: [this.order.InternalInfo],
                tankInfo: [this.order.TankInfo],
                deliveryInfo: [this.order.DeliveryInfo],
                phoneOnSite: [this.order.PhoneOnSite],
                phoneManager: [this.order.PhoneManager],
                email: [this.order.Email, [CustomValidators.emailValidator]],
                additionalProductsDelivered: [this.order.AdditionalProductsDelivered],
                doNotCalculateVolume: [this.order.DoNotCalculateVolume],
                doNotSendGPS: false,
                customerReference: [this.order.CustomerReference],
                emailTo: ['',  [CustomValidators.emailValidator]]
            });

            this.userService.getProducts().subscribe(data => {
                this.products = data;
            });

            this.updateCommentValidators();

            this.translateService.get('General.dateFormat').subscribe(value => {
                this.dateFormat = value;
            });

         
            const that = this;
         

          
        });
    }

 ngOnInit() {
    this.getCurrentPosition();
    this.startWatchingPosition();
  }



  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: true,
      timeout: 30000,
      maximumAge: 30000,
    };

    await Geolocation.getCurrentPosition(posOptions).then((resp) => {
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    }).catch((error) => {
      // Implement your error handling logic here
      this.showGeolocationError();
    });
  }

  async startWatchingPosition() {
    const watch = await Geolocation.watchPosition({}, (position, err) => {
      if (err) {
        // Implement your error handling logic here
        this.showGeolocationError();
        return;
      }
      if (position && position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      }
    });
  }

  showGeolocationError() {
    // Implement your error handling logic here, such as showing a notification to the user
  }

    showhide() {
        this.isHidden = !this.isHidden;
    }

    dynamicControl(formGroup: FormGroup, orderline) {
        orderline.changeProduct = false;
        const quantityDelivered: FormControl = new FormControl(orderline.QuantityDelivered,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const price: FormControl = new FormControl(orderline.UnitPrice,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const discount: FormControl = new FormControl(orderline.UnitDiscount,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const cost: FormControl = new FormControl(orderline.UnitCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        if (this.allowDescriptionChange) {
            const productNo: FormControl = new FormControl(orderline.Description, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        } else {
            const productNo: FormControl = new FormControl(orderline, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        }
        formGroup.addControl('quantity' + orderline.LineNo, quantityDelivered);
        formGroup.addControl('price' + orderline.LineNo, price);
        formGroup.addControl('discount' + orderline.LineNo, discount);
        formGroup.addControl('cost' + orderline.LineNo, cost);
    }

    validateQuantity(orderline) {
        this.setValue(orderline, 'quantity');
        if (this.inventoryList != null) {
            this.errorMessages = [];
            let item;
            for (item of this.order.Orderlines) {
                if (item.ProductType === 10) {
                    let truckProduct = _.find(this.inventoryList, (p: any) => {
                        return p.ProductNo === item.InventoryProductNo;
                    });
                    if (!truckProduct) {
                        this.translateService.get('LeggTilProdukt.productNotFound').subscribe(value => {
                            this.errorMessages.push(value);
                        });
                        return;
                    } else {
                        if (truckProduct.InventoryProductNo) {
                            truckProduct = _.find(this.inventoryList, (p: any) => {
                                return p.ProductNo === truckProduct.InventoryProductNo;
                            });
                        }

                        let maxValue: any = null;

                        if (truckProduct.Quantity + this.deviationLimit < item.QuantityDelivered) {
                            maxValue = truckProduct.Quantity;
                        }
                        if (maxValue != null) {
                            this.translateService.get('OrdreDetails.maxValue').subscribe(value => {
                                this.errorMessages.push(value.format(item.Description, maxValue));
                            });
                        }
                    }
                }
            }
        }
        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    setValue(orderline, fieldName) {
        const control = this.detailsForm.controls.orderLines;
        if (fieldName === 'price') {
            orderline.UnitPrice = control.get('price' + orderline.LineNo).value;
            orderline.UserChangedPrice = true;
        }
        if (fieldName === 'discount') {
            orderline.UnitDiscount = control.get('discount' + orderline.LineNo).value;
        }
        if (fieldName === 'cost') {
            orderline.UnitCost = control.get('cost' + orderline.LineNo).value;
            orderline.UserChangedCost = true;
        }
        if (fieldName === 'quantity') {
            orderline.QuantityDelivered = control.get('quantity' + orderline.LineNo).value;
        }

        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    getTotalOrder() {
        this.order.TotalOrder = 0;
        for (const item of this.order.Orderlines) {
            const total = +item.OrderLineTotal + (+this.order.TotalOrder);
            this.order.TotalOrder = Math.round(total).toFixed(2);
        }
    }

    orderlineTotal(orderline) {
        let lineTotal = 0;
        orderline.OrderLineTotal = 0;
        if (orderline.UnitPrice >= 0) {
            if (orderline.QuantityDelivered > 0) {
                lineTotal = (orderline.UnitPrice * orderline.QuantityDelivered);
            } else {
                lineTotal = (orderline.UnitPrice * orderline.Quantity);
            }
        }
        if (orderline.UnitDiscount > 0) {
            orderline.OrderLineTotal = (lineTotal - ((lineTotal * orderline.UnitDiscount) / 100)).toFixed(2);
        } else {
            orderline.OrderLineTotal = lineTotal.toFixed(2);
        }
    }

    changeElement($event, orderline) {
        if ($event) {
            orderline.changeProduct = false;
        } else {
            orderline.changeProduct = true;
        }
    }

    selectProduct(event, orderline) {
        if (event.type === 'change' || event.value) {
            const control = this.detailsForm.controls.orderLines;
            const product = _.find(this.products, { ProductNo: event.target ? event.target.value : event.value.ProductNo });
            if (product) {
                orderline.ProductNo = product.ProductNo;
                orderline.Description = product.Description;
                orderline.ProductShortCode = product.ShortCode;
                orderline.ProductColorCode = product.ColorCode;
                orderline.UnitPrice = product.UnitPrice;
                orderline.UnitCost = product.UnitCost;
                control.get('price' + orderline.LineNo).setValue(orderline.UnitPrice);
                control.get('cost' + orderline.LineNo).setValue(orderline.UnitCost);
                this.orderlineTotal(orderline);
                this.getTotalOrder();
            }
            if (this.allowDescriptionChange) {
                if (control.get('productNo' + orderline.LineNo).value !== '') {
                    orderline.Description = control.get('productNo' + orderline.LineNo).value;
                }
            }
        } else {
            orderline.changeProduct = false;
        }
    }

    showConfirm(orderline) {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.confirmMessage').subscribe(value => { modalText = value.format(orderline.Description); });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.deleteOrderLine(orderline);
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }

    deleteOrderLine(orderline) {
        const index = _.findIndex(this.order.Orderlines, { ProductNo: orderline.ProductNo });
        if (index > -1) {
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.deleteOrderline(this.order.OrderNo, orderline.LineNo).subscribe(resp => {
                        loader.dismiss();

                        this.order.Orderlines.splice(index, 1);
                        this.getTotalOrder();

                    });
                });
            });
        }
    }

    updateCommentValidators() {
        const commentControl = this.detailsForm.get('orderComments');
        const additionalProductsDelivered = this.detailsForm.get('additionalProductsDelivered');
        if (additionalProductsDelivered.value) {
            commentControl.setValidators([Validators.required]);
        } else {
            commentControl.setValidators([]);
        }
        commentControl.updateValueAndValidity();
    }

    deliveredQuantityExists() {
        const orderline = _.find(this.order.Orderlines, (orderLine: any) => {
            return orderLine.QuantityDelivered > 0;
        });
        if (orderline) {
            return true;
        }
        return false;
    }

    additionalProductsDeliveredChanged() {
        this.updateCommentValidators();
    }

    async  moveOrderToNextDay() {
        const modal = await this.modalCtrl.create(
            {
                component: OrderNextDayConfirmComponent,
                cssClass: 'order-next-day-confirm'
            });
        modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.moveOrderToNextDay(this.order.OrderNo).subscribe(resp => {
                            loader.dismiss();
                            this.router.navigate(['/orders']);
                        });
                    });
                });
            }
        });
    }

    showhideCameFrom() {
        return this.CameFromApproval;
    }

    async leggTilProdukt() {
        const modal = await this.modalCtrl.create({
            component: LeggTilProduktSalesComponent,
            cssClass: 'legg-til-produkt-sales',
            componentProps: { inventoryList: this.inventoryList, deviationLimit: this.deviationLimit }
        });
        await modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                const orderline: any = {
                    ProductColorCode: data.data.Product.ColorCode,
                    ProductShortCode: data.data.Product.ShortCode,
                    Description: data.data.Product.Description,
                    LineNo: this.identity,
                    ProductNo: data.data.Product.ProductNo,
                    Quantity: data.data.Quantity,
                    QuantityDelivered: data.data.Quantity,
                    UnitPrice: data.data.Product.UnitPrice,
                    UnitCost: data.data.Product.UnitCost,
                    UnitDiscount: 0,
                    OrderLineTotal: 0,
                    UserChangedPrice:false,
                    UserChangedCost:false,
                    UserChangedFreight:false
                };
                this.order.Orderlines.push(orderline);
                const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
                this.dynamicControl(formGroup, orderline);
                this.identity--;
            }
        });
    }

    async setOrderAsCompleted() {
        const modal = await this.modalCtrl.create({
            component: OrderConfirmSalesComponent,
            cssClass: 'order-confirm-sales',
            componentProps: { order: this.order }
        });


        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });
                        const date = new Date();
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.InternalInfo = this.detailsForm.get('internalInfo').value;
                        this.order.TankInfo = this.detailsForm.get('tankInfo').value;
                        this.order.DeliveryInfo = this.detailsForm.get('deliveryInfo').value;
                        this.order.CustomerReference = this.detailsForm.get('customerReference').value;
                        this.order.PhoneOnSite = this.detailsForm.get('phoneOnSite').value;
                        this.order.PhoneManager = this.detailsForm.get('phoneManager').value;
                        this.order.Email = this.detailsForm.get('email').value;
                        this.order.AdditionalProductsDelivered = this.detailsForm.get('additionalProductsDelivered').value;
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.DoNotSendGPS = this.detailsForm.get('doNotSendGPS').value;
                        this.order.DoNotConfirmOrder = false;
                        this.order.EmailTo = this.detailsForm.get('emailTo').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });
                        if (this.DoNotSendGPS) {
                            this.order.Longitude = -1;
                            this.order.Latitude = -1;
                        } else {
                            this.order.Latitude = this.currentLatitude;
                            this.order.Longitude = this.currentLongitude;
                        }
                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();

                                this.router.navigate(['/orders-product'], this.navigationExtras);
                            });
                        });
                    });
                }
            }
        });
    }



    async SaveOrder() {
        const modal = await this.modalCtrl.create({
            component: OrderConfirmSalesComponent,
            cssClass: 'order-confirm-sales',
            componentProps: { order: this.order }
        });


        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });
                        const date = new Date();
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.InternalInfo = this.detailsForm.get('internalInfo').value;
                        this.order.TankInfo = this.detailsForm.get('tankInfo').value;
                        this.order.DeliveryInfo = this.detailsForm.get('deliveryInfo').value;
                        this.order.CustomerReference = this.detailsForm.get('customerReference').value;
                        this.order.CustomerPurchaseOrderNo = this.detailsForm.get('customerReference').value;
                        this.order.PhoneOnSite = this.detailsForm.get('phoneOnSite').value;
                        this.order.PhoneManager = this.detailsForm.get('phoneManager').value;
                        this.order.Email = this.detailsForm.get('email').value;
                        this.order.AdditionalProductsDelivered = this.detailsForm.get('additionalProductsDelivered').value;
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.order.EmailTo = this.detailsForm.get('emailTo').value;
                        this.DoNotSendGPS = this.detailsForm.get('doNotSendGPS').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });
                        this.order.DoNotConfirmOrder = false;
                        if (this.DoNotSendGPS) {
                            this.order.Longitude = -1;
                            this.order.Latitude = -1;
                        } else {
                            this.order.Latitude = this.currentLatitude;
                            this.order.Longitude = this.currentLongitude;
                        }
                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();

                                if (this.CameFromApproval === true) {
                                    this.router.navigate(['/orders-product'], this.navigationExtras);
                                } else {
                                    this.router.navigate(['/orders'], this.navigationExtras);
                                }
                            });
                        });
                    });
                }
            }
        });
    }



    isOrderMine(): boolean {
        if (this.order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

   async loadDrivingInstructions() {
    let destinationUrl;

    // Check if coordinates are valid, otherwise use the address
    if (this.currentLatitude !== 0 && this.currentLongitude !== 0) {
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.currentLatitude},${this.currentLongitude}`;
    } else {
        // Encode the address for URL use
        const encodedAddress = encodeURIComponent(`${this.order.DeliveryAddress1} ${this.order.DeliveryPostalCode} ${this.order.DeliveryCity}`);
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    }

    // Use AppLauncher to open the URL
    try {
        await AppLauncher.openUrl({ url: destinationUrl });
    } catch (error) {
        console.error('Error launching URL:', error);
        const toast = await this.toastController.create({
            message: 'Could not open navigation: ' + error.message,
            duration: 3000
        });
        toast.present();
    }
}


    unassignOrder() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.unassignOrder(this.order.OrderNo).subscribe(data => {
                    this.order.AssignedDriver = 0;
                    loader.dismiss();
                    this.router.navigate(['/orders']);
                });
            });
        });
    }

    ngOnDestroy() {
        this.currentTruckTrailerSub$.unsubscribe();
    }

    navigateToOrdersList() {
        this.navCtrl.pop();
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    async editCustomerAndAddress() {
        const modal = await this.modalCtrl.create({
            component: EditOrderSharedComponent,
            cssClass: 'order-shared',
            componentProps: { order: this.order }
        });
        modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.updateOrder(data.data.Order).subscribe(() => {
                            this.order.CustomerName = data.data.CustomerDetails.CustomerName;
                            this.order.CustomerAddress1 = data.data.CustomerDetails.CustomerAddress1;
                            this.order.CustomerAddress2 = data.data.CustomerDetails.CustomerAddress2;
                            this.order.CustomerCity = data.data.CustomerDetails.CustomerCity;
                            this.order.CustomerPostalCode = data.data.CustomerDetails.CustomerPostalCode;
                            this.order.DeliveryName = data.data.CustomerDetails.DeliveryName;
                            this.order.DeliveryAddress1 = data.data.CustomerDetails.DeliveryAddress1;
                            this.order.DeliveryAddress2 = data.data.CustomerDetails.DeliveryAddress2;
                            this.order.DeliveryCity = data.data.CustomerDetails.DeliveryCity;
                            this.order.DeliveryPostalCode = data.data.CustomerDetails.DeliveryPostalCode;
                            this.order.PriceAgreementID = data.data.CustomerDetails.PriceAgreementID;
                            loader.dismiss();
                        });
                    });
                });
            }
        });
    }

    async addSign() {
        this.hideCost = true;
        const modal = await this.modalCtrl.create({
            component: CustomerSignComponent,
            cssClass: 'customer-sign',
        });
        modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.order.Name = data.data.Name;
                this.order.CustomerSign = data.data.Sign;
            }
        });
    }

    toggleHideCost()
    {
        this.hideCost = !this.hideCost;
    }

    confirmDeleteOrder() {
        let modalTitle;
        let labelText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.orderDeleteMessage').subscribe(value => {
            labelText = value;
        });
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            inputs: [
                {
                    name: 'checkbox',
                    type: 'checkbox',
                    label: labelText,
                    checked: false,
                    value: 'true',
                    handler: (input) => {
                        const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
                        if (input.checked) {
                            confirmBtn.disabled = false;
                        } else {
                            confirmBtn.disabled = true;
                        }
                    },
                }],
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    cssClass: 'confirmation',
                    handler: data => {
                        if (data.length > 0) {
                            this.deleteOrder();
                        }
                    }
                }
            ]
        }).then(confirm => {
            confirm.present();
            const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
            confirmBtn.disabled = true;
        });
    }

    deleteOrder() {
        this.userService.deleteOrder(this.order.OrderNo).subscribe(data => {
            this.router.navigate(['/orders-sales'], this.navigationExtras);
        });
    }

    plukkOrApne() {
        
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.assignOrderToDriver(this.order.OrderNo, null).subscribe(data => {
                    this.order.AssignedDriver = AppSettings.Username;
                    loader.dismiss();
                    this.router.navigate(['/orders'], this.navigationExtras);
                    //this.loadData();
                });
            });
        });
    
}

}
