import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseHttpClient2 } from '../shared/http-client2';
import { AppSettings } from '../shared/app-settings';
import { HttpResponse } from '@angular/common/http';
import { formatISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class FormSchemaService {
  private apiUrl = `${AppSettings.API_ENDPOINT}/forms`;

  constructor(private http: BaseHttpClient2) {}

  getAllForms(): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}/GetAllForms`)
      .pipe(map((response: any[]) => response));
  }

  checkExistingEntry(formObject: any, date: Date, formType: number): Observable<any> {
    const params = { formObject: (formObject), date: formatISO(date), formType: formType };
    return this.http.get<any>(`${this.apiUrl}/CheckExistingEntry`, { params });
  }

  submitForm(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/SubmitForm`, data);
  }

  exportReport(formId: number): Observable<Blob> {
    return this.http.getFile(`${this.apiUrl}/export?formId=${formId}`);
  }
}
