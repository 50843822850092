import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, AlertController, IonSearchbar } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import { BaseHttpClient } from '../../shared/http-client';
import { StockTransactiontype, TypeOfTank } from '../../shared/enums';
import _ from 'lodash';
import { AppSettings } from '../../shared/app-settings';
import { Storage } from '@ionic/storage-angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Console } from 'console';


@Component({
    selector: 'page-stock-goods',
    templateUrl: 'stock-goods.html',
    styleUrls: ['stock-goods.scss']
})
export class StockGoodsPage {
    protected customerDataService: any;
    stockGoodsForm: FormGroup;
    identity = -1;
    product;
    products: any[];
    submitting = false;
    searchProduct: any[] = [];
    selectedProducts: any[] = [];
    customers: any[] = [];
    selectedCustomer: any;
    stockTransactiontype = StockTransactiontype;
    transactionType: StockTransactiontype = StockTransactiontype.Outgoing;
    stocks: any[] = [];
    isHidden = false;
    expand = false;
    trailersAndTrucks = [];
    tanks = [];
    poOrders = [];

    @ViewChild('searchInput', { static: false }) searchInput: IonSearchbar;

    constructor(
        private formBuilder: FormBuilder, private translateService: TranslateService, private userService: UserService,
        private http: BaseHttpClient, private loadingController: LoadingController, private alertCtrl: AlertController,
        private storage: Storage) {
        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });
        const productsGroup: FormGroup = new FormGroup({});
        this.stockGoodsForm = formBuilder.group({
            productNo: ['', Validators.compose([Validators.required])],
            customerNo: [''],
            stockNo: ['',Validators.compose([Validators.required])],
            stockToNo: [''],
            trailersAndTruck: [''],
            tank: [''],
            other: [''],
            userSign: [''],
            PONumber:[''],
            products: productsGroup
        });
        
        this.getProducts();

        try {
             this.getStocks();
        this.getTrailersAndTrucks();
        this.getTanks();
        
        this.storage.get(AppSettings.StorageKeys.StockNo).then(data => {
            this.stockGoodsForm.get('stockNo').setValue(data);
        });
        } catch (error) {
            
        }
       
    }

    getFormControl(field) {
        return this.stockGoodsForm.get(field);
    }

    getProducts() {

        try {
            this.translateService.get('Loading.loading').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.getProducts().subscribe(data => {
                        loader.dismiss();
                        this.products = data;
                        
                    });
                });
            });
        } catch (error) {
            
        }
        
    }

    getTrailersAndTrucks() {
        this.userService.getTrailersAndTrucks().subscribe(data => {
            this.trailersAndTrucks = data.Trailers;
            this.trailersAndTrucks = this.trailersAndTrucks.concat(data.Trucks);
        });
    }

    getTanks() {
        this.userService.getTanks().subscribe(tanks => {
            this.tanks = tanks;
        });
    }

    getStocks() {
        this.userService.getStocks().subscribe(data => {
            this.stocks = data;
        });
    }

    getPOs() {
        this.userService.getPurchaseOrders(this.getFormControl('stockNo').value).subscribe(pos => {
            this.poOrders = pos;
        });
    }

    dynamicControl(formGroup: FormGroup, product) {
        const costPrice: FormControl = new FormControl(product.UnitCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const price: FormControl = new FormControl(product.UnitPrice,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const quantity: FormControl = new FormControl(product.Quantity,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const comment: FormControl = new FormControl('');
        formGroup.addControl('costPrice' + product.identity, costPrice);
        formGroup.addControl('price' + product.identity, price);
        formGroup.addControl('quantity' + product.identity, quantity);
        formGroup.addControl('comment' + product.identity, comment);
    }

    setValue(product, fieldName) {
        const control = this.stockGoodsForm.controls.products;
        if (fieldName === 'costPrice') {
            product.CostPrice = control.get('costPrice' + product.identity).value;
        }
        if (fieldName === 'price') {
            product.Price = control.get('price' + product.identity).value;
        }
        if (fieldName === 'quantity') {
            product.Quantity = control.get('quantity' + product.identity).value;
        }
        if (fieldName === 'comment') {
            product.Comment = control.get('comment' + product.identity).value;
        }
    }

    searchProducts(event) {
        if (event.target.value.toLowerCase().length >= 2) {
            const query = event.target.value.toLowerCase();
            const stockNo = this.getFormControl('stockNo').value

            this.userService.searchProducts(query, stockNo).subscribe(data => {
                this.searchProduct = data;
            
              /*  this.searchProduct = _.filter(this.products, (product) => {
                    // search for text, prodno and EAN
                    if (product.ProductNo.toLowerCase() != "") {
                        product.Quantity = 0;stockNo

                        return product;
                    }
                });*/

                if (this.searchProduct.length === 1) {
                    this.searchProduct[0].Quantity = this.searchProduct[0].StockFactor;
                    this.selectProduct(this.searchProduct[0]);
                }
        });
        }
    }

    clearFormControl(form: FormGroup) {
        for (const key in form.controls) {
            if (key) {
                form.get(key).setValue('');
                form.get(key).clearValidators();
                form.get(key).updateValueAndValidity();
            }
        }
    }

    changeTransactionType() {
        if (this.transactionType === this.stockTransactiontype.OutgoingInternal) {
           // this.getFormControl('trailersAndTruck').setValidators([Validators.required]);
          //  this.getFormControl('tank').setValidators([Validators.required]);
          //  this.getFormControl('other').setValidators([Validators.required]);
            this.getFormControl('userSign').setValidators([Validators.required]);
        } else {
            this.getFormControl('trailersAndTruck').setValidators([]);
            this.getFormControl('tank').setValidators([]);
            this.getFormControl('other').setValidators([]);
            this.getFormControl('userSign').setValidators([]);
            this.getFormControl('userSign').markAsPristine();
            this.getFormControl('other').markAsPristine();
            this.getFormControl('tank').markAsPristine();
            this.getFormControl('trailersAndTruck').markAsPristine();
        }
        this.getFormControl('trailersAndTruck').updateValueAndValidity();
        this.getFormControl('tank').updateValueAndValidity();
        this.getFormControl('other').updateValueAndValidity();
        this.getFormControl('userSign').updateValueAndValidity();
        this.getFormControl('PONumber').updateValueAndValidity();

        if(this.transactionType === this.stockTransactiontype.Incoming){
            this.getPOs();
        }
        else
        {
            this.getFormControl('PONumber').setValue('');
        }
    }

    selectProduct(product) {
        this.getFormControl('productNo').setValue(product.Description + '(' + product.CurrentStockBalance + ')');
        this.product = product;
        var selectProduct = true; //this.selectedProducts.indexOf(product) === -1;

        if(product.Quantity == null){product.Quantity = product.StockFactor;}

        this.selectedProducts.forEach(function(value){

 
            if(value.ProductNo == product.ProductNo && selectProduct){
                selectProduct = false;
                
            }

        });

        
        if (selectProduct) {
            product.identity = this.identity;
            product.CostPrice = product.UnitCost;
            product.Price = product.UnitPrice;
            product.Quantity = product.Quantity;
            product.Comment = '';
            this.selectedProducts.unshift(product);
            const formGroup: FormGroup = this.stockGoodsForm.controls.products as FormGroup;
            this.dynamicControl(formGroup, product);
            this.identity--;
        } else {
            _.filter(this.selectedProducts, (item) => {
                if (item.ProductNo.toLowerCase() === product.ProductNo.toLowerCase()) {
                    const control = this.stockGoodsForm.controls.products;

                    item.Quantity = control.get('quantity' + item.identity).value + product.Quantity;

                    control.get('quantity' + item.identity).setValue(item.Quantity);
                    return item;
                }
            });
        }

        const stockNo = this.getFormControl('stockNo').value
        if( stockNo > 0 && !this.isHidden)
            this.showhide();

        this.searchProduct = [];
        this.getFormControl('productNo').setValue('');
        this.getFormControl('productNo').setErrors(null);
        this.searchInput.setFocus();
    }

    searchCustomers(event) {
        if (event.target.value === '') {
            this.customers = [];
        }
        if (event.target.value.length > 3) {
            this.userService.customerSearch(event.target.value).subscribe(customers => {
                this.customers = customers;
            });
        }
    }

    selectCustomer(customer) {
        this.selectedCustomer = {};
        this.getFormControl('customerNo').setValue(customer.Name);
        this.selectedCustomer = customer;
        this.customers = [];
    }

    deleteProduct(product) {
        const index = _.findIndex(this.selectedProducts, { ProductNo: product.ProductNo });
        if (index > -1) {
            this.selectedProducts.splice(index, 1);
        }
    }

    showDeleteProductConfirm(product) {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('StockGoods.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('StockGoods.confirmationMessage').subscribe(value => { modalText = value.format(product.Description); });
        this.translateService.get('StockGoods.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('StockGoods.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.deleteProduct(product);
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }

    showhide() {
        this.isHidden = !this.isHidden;
    }

    saveStockGoods() {
        if (this.stockGoodsForm.valid) {
            const stockNo = this.getFormControl('stockNo').value;
            const stockToNo = this.getFormControl('stockToNo').value;
            this.submitting = true;
            const stockGoods = [];
            for (const item of this.selectedProducts) {
                const product: any = {};
                product.ProductNo = item.ProductNo;
                product.CostPrice = item.CostPrice;
                product.Price = item.Price;
                product.Quantity = item.Quantity;
                product.Comment = item.Comment;
                product.CustomerNo = this.selectedCustomer ? this.selectedCustomer.CustomerNo : null;
                product.TransactionType = this.transactionType;
                product.StockNo = stockNo;
                product.StockToNo = stockToNo;
                product.TruckTrailerID = this.getFormControl('trailersAndTruck').value.ID;
                product.TankID = this.getFormControl('tank').value.TankID;
                product.PONumber = this.getFormControl('PONumber').value.OrderNo;
                product.UserSign = this.getFormControl('userSign').value;
                product.Other = this.getFormControl('other').value;
                stockGoods.push(product);
            }
            this.userService.saveStockGoods(stockGoods).subscribe(() => {
                this.storage.set(AppSettings.StorageKeys.StockNo, stockNo);
                this.submitting = false;
                this.selectedProducts = [];
                this.stockGoodsForm.controls.products = new FormGroup({});
                this.getFormControl('productNo').setErrors(null);
                this.stockGoodsForm.updateValueAndValidity();
                this.selectedCustomer = null;
                this.customers = [];
                this.clearFormControl(this.stockGoodsForm);
                this.stockGoodsForm.get('stockNo').setValue(stockNo);
                
            });
        }
    }

    setQty(item, qty: number) {
        const control = this.stockGoodsForm.controls.products;
        item.Quantity += qty;
        control.get('quantity' + item.identity).setValue(item.Quantity);
    }

    showConfirm() {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('StockGoods.confirm').subscribe(value => { modalTitle = value; });
        this.translateService.get('StockGoods.confirmMessage').subscribe(value => { modalText = value; });
        this.translateService.get('General.avbryt').subscribe(value => { cancel = value; });
        this.translateService.get('General.save').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.saveStockGoods();
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    showInfo(info:string)
    {
        if(info != null)
        {
            if(info.indexOf("Telt:") >=0 && this.transactionType != StockTransactiontype.Inventory)
                return false;
            else 
                return info.trim() != "" ?  true : false;
        }
            
        else
            return false;
    }
    poChange(event: {
        component: IonicSelectableComponent,
        value: any
      }) {

        this.selectedProducts = [];
        if(event.value.Orderlines != null)
        {
        var orderlines = event.value.Orderlines;
        orderlines.forEach(line => {
            const product: any = {};
            product.ProductNo = line.ProductNo;
            product.UnitCost = line.UnitCost;
            product.UnitPrice = line.UnitPrice;
            product.Quantity = line.Quantity;
            product.Unit = line.Unit;
            product.StockFactor = 1;
            product.Comment = line.Comment;
            product.Description = line.Description;
            product.CurrentStockBalance = line.CurrentStockBalance;
           // product.CustomerNo = this.selectedCustomer ? this.selectedCustomer.CustomerNo : null;
            product.TransactionType = this.transactionType;
         //   product.StockNo = stockNo;
           // product.StockToNo = stockToNo;
          //  product.TruckTrailerID = this.getFormControl('trailersAndTruck').value.ID;
         //   product.TankID = this.getFormControl('tank').value.TankID;
            product.PONumber = line.OrderNo;
        //    product.UserSign = this.getFormControl('userSign').value;
         //   product.Other = this.getFormControl('other').value;
         this.selectProduct(product);
        });
    }
      }

      stockChange(event)
      {
          console.log(event.detail.value);

          if(this.transactionType === this.stockTransactiontype.Incoming){
            this.getPOs();

            this.storage.set(AppSettings.StorageKeys.StockNo, event.detail.value);
        }
      }
}

