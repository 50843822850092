<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'NewOrder.nyOrdre' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <form [formGroup]="detailsForm">
        <ion-row>
          <ion-col size-xs="12" size-md="6" class="bgWhite" [hidden]="isHidden">
            <ion-list lines="none">
              <!-- CUSTOMER LABEL -->
              <ion-item class="text-small ion-no-padding z-index">
                <ion-label>
                  <p>
                    <ion-icon
                      name="person-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<ion-text color="primary">
                      <span class="fw500"
                        >{{ 'NewOrder.kunde' | translate }}</span
                      > </ion-text
                    >&nbsp;
                    <ion-badge *ngIf="showCustomerNo()">
                      {{ 'NewOrder.NewCustomer' | translate }}</ion-badge
                    >
                    <ion-badge *ngIf="!showCustomerNo() && selectedCustomer">
                      {{ selectedCustomer.CustomerNo }}</ion-badge
                    >
                  </p>
                </ion-label>
              </ion-item>
              <div class="completer-container address" hidden></div>

              <!-- CUSTOMER NAME -->

              <ion-item class="text-small ion-no-padding input-container">
                <ion-row class="w-100">
                  <ion-col size-xs="8" size-md="10" class="ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.customerName' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      formControlName="customerName"
                      placeholder="{{ 'NewOrder.customerName' | translate }}"
                      #customerNameInput
                      (ionInput)="checkInput($event)"
                    ></ion-input>
                  </ion-col>
                  <ion-col size-xs="4" size-md="2" class="ion-col-button">
                    <ion-button
                      fill="outline"
                      (click)="initCustomerSearch()"
                      class="ion-input-button"
                    >
                      <ion-icon name="search-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-item>

              <!-- CUSTOMER NAME VALIDATION -->
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!detailsForm.controls.customerName.valid && (detailsForm.controls.customerName.dirty || submitAttempt)"
              >
                <ion-text
                  color="danger"
                  *ngIf="detailsForm.controls.customerName.errors.required"
                >
                  <p>
                    {{ 'NewOrder.validationcustomerNameRequired' | translate }}
                  </p>
                </ion-text>
                <ion-text
                  color="danger"
                  *ngIf="detailsForm.controls.customerName.errors.incorrect"
                >
                  <p>{{'NewOrder.customerBlockedStatus' | translate }}</p>
                </ion-text>
              </ion-item>

              <!-- CUSTOMER ADDRESS 1 -->
              <ion-item class="text-small ion-no-padding">
                <ion-label position="floating" class="floating-label">
                  {{ 'NewOrder.customerAddress1' | translate }}
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress1' | translate }}"
                  formControlName="customerAddress1"
                />
              </ion-item>

              <!-- CUSTOMER ADDRESS 1 VALIDATION -->
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!detailsForm.controls.customerAddress1.valid && (detailsForm.controls.customerAddress1.dirty || submitAttempt)"
              >
                <ion-text color="danger">
                  <p>{{ 'NewOrder.validationAddress1Required' | translate }}</p>
                </ion-text>
              </ion-item>

              <!-- CUSTOMER ADDRESS 2 -->
              <ion-item class="text-small ion-no-padding">
                <ion-label position="floating" class="floating-label">
                  {{ 'NewOrder.customerAddress2' | translate }}
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress2' | translate }}"
                  formControlName="customerAddress2"
                />
              </ion-item>

              <!-- CUSTOMER POSTAL CODE AND CITY -->

              <ion-row class="completer-row2">
                <ion-col size-xs="3" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.zip' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.zip' | translate }}"
                      formControlName="customerPostalCode"
                  /></ion-item>
                </ion-col>

                <ion-col size-xs="6" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.city' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.city' | translate }}"
                      formControlName="customerCity"
                    />
                  </ion-item>
                </ion-col>

                <ion-col size-xs="3" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'OrdreDetails.organizationNo' | translate }}
                    </ion-label>
                    <ion-input
                      type="number"
                      placeholder="{{ 'OrdreDetails.organizationNo' | translate }}"
                      formControlName="organizationNo"
                  /></ion-item>
                </ion-col>
              </ion-row>

              <!-- POSTAL CODE AND CITY VALIDATION -->
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="(!detailsForm.controls.customerCity.valid || !detailsForm.controls.customerPostalCode.valid) && (detailsForm.controls.customerCity.dirty || detailsForm.controls.customerPostalCode.dirty || submitAttempt)"
              >
                <ion-row class="completer-row2">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        *ngIf="!detailsForm.controls.customerPostalCode.valid && (detailsForm.controls.customerPostalCode.dirty || submitAttempt)"
                      >
                        {{ 'NewOrder.validationZipRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        class="cityVal"
                        *ngIf="!detailsForm.controls.customerCity.valid && (detailsForm.controls.customerCity.dirty || submitAttempt)"
                      >
                        {{ 'NewOrder.validationCityRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-item>
            </ion-list>

            <ion-list lines="none">
              <!-- DELIVERY ADDRESS LABEL -->
              <ion-item class="text-small address ion-no-padding z-index">
                <ion-label>
                  <p>
                    <ion-icon
                      name="location-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<ion-text color="primary"
                      ><span class="fw500"
                        >{{ 'NewOrder.leveringsadresse' | translate }}</span
                      >
                      {{ 'NewOrder.leveringsadresseInfo' | translate
                      }}</ion-text
                    >
                  </p>
                </ion-label>
              </ion-item>
              <div class="completer-container address">
                <!-- DELIVERY ADDRESS SEARCH -->
                <ion-item class="text-small ion-no-padding">
                  <ion-row class="w-100">
                    <ion-col size-xs="8" size-md="10" class="ion-no-padding">
                      <ion-label position="floating" class="floating-label">
                        {{ 'NewCustomer.deliveryName' | translate }}
                      </ion-label>
                      <ion-input
                        type="text"
                        placeholder="{{ 'NewCustomer.deliveryName' | translate }}"
                        formControlName="address"
                        #addressInput
                      />
                    </ion-col>
                    <ion-col
                      size-xs="4"
                      size-md="2"
                      class="ion-no-padding ion-col-button"
                    >
                      <ion-button
                        fill="outline"
                        (click)="initCustomerAddressSearch()"
                        class="ion-input-button"
                      >
                        <ion-icon name="search-outline"></ion-icon>
                      </ion-button>

                      <ion-button
                        fill="outline"
                        (click)="clearAddress()"
                        class="ion-input-button"
                      >
                        <ion-icon name="trash-outline"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-item>
                <div class="clear"></div>
              </div>

              <!-- DELIVERY ADDRESS 1 -->
              <ion-item class="text-small ion-no-padding">
                <ion-label position="floating" class="floating-label">
                  {{ 'NewOrder.customerAddress1' | translate }}
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress1' | translate }}"
                  formControlName="addressAddress1"
                />
              </ion-item>

              <!-- DELIVERY ADDRESS 2 -->
              <ion-item class="text-small ion-no-padding">
                <ion-label position="floating" class="floating-label">
                  {{ 'NewOrder.customerAddress2' | translate }}
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress2' | translate }}"
                  formControlName="addressAddress2"
                />
              </ion-item>

              <!-- DELIVERY POSTAL CODE AND CITY -->

              <ion-row class="completer-row2">
                <ion-col size-xs="3" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.zip' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.zip' | translate }}"
                      formControlName="addressZip"
                  /></ion-item>
                </ion-col>
                <ion-col size-xs="9" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.city' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.city' | translate }}"
                      class="city"
                      formControlName="addressCity"
                  /></ion-item>
                </ion-col>
              </ion-row>

              <!-- GPS-->
              <ion-item class="text-small ion-no-padding">
                <ion-row class="w-100">
                  <ion-col size-xs="8" size-md="10" class="ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewCustomer.gps' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewCustomer.gps' | translate }}"
                      formControlName="gps"
                      (ionChange)="onGpsInputChange($event)"
                    />
                  </ion-col>
                  <ion-col
                    size-xs="4"
                    size-md="2"
                    class="ion-no-padding ion-col-button"
                  >
                    <ion-button
                      (click)="toggleMapVisibility()"
                      fill="outline"
                      class="ion-input-button"
                    >
                      <ion-icon
                        [name]="isMapVisible ? 'eye-off-outline' : 'eye-outline'"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row> </ion-item
              ><ion-item>
                <ion-content
                  [ngClass]="{'map-viewer': true, 'map-hidden': !isMapVisible}"
                >
                  <app-map-view
                    *ngIf="isMapVisible"
                    [latitude]="mapLat"
                    [longitude]="mapLong"
                  ></app-map-view>
                </ion-content>
              </ion-item>
              <!-- GPS LOCATION VALIDATION -->
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!detailsForm.controls.gps.valid && (detailsForm.controls.gps.dirty || submitAttempt)"
              >
                <ion-text
                  color="danger"
                  *ngIf="detailsForm.controls.gps.errors.pattern"
                >
                  <p>
                    <ion-icon name="alert-circle-outline"></ion-icon>&nbsp;{{
                    'NewCustomer.gps' | translate }}
                  </p>
                </ion-text>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-xs="12" size-md="6" class="bgWhite" [hidden]="isHidden">
            <ion-list lines="none">
              <!-- CONTACT INFO LABEL -->
              <ion-label>
                <p>
                  <ion-icon name="call-outline" color="lightFive"></ion-icon>
                  &nbsp;<ion-text color="primary"
                    ><span class="fw500"
                      >{{ 'NewOrder.kontaktinfo' | translate }}</span
                    ></ion-text
                  >
                </p></ion-label
              >

              <ion-row class="w-100 ion-no-padding">
                <ion-col size-md="6" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.nameOfPurchaser' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.nameOfPurchaser' | translate }}"
                      formControlName="customerBuyer"
                    />
                  </ion-item>

                  <!-- CONTACT INFO VALIDATION -->
                  <ion-item
                    class="item-validation ion-no-padding"
                    *ngIf="!detailsForm.controls.customerBuyer.valid && (detailsForm.controls.customerBuyer.dirty || submitAttempt)"
                  >
                    <ion-text color="danger">
                      <p>
                        {{ 'NewOrder.validationCustomerBuyerRequired' |
                        translate }}
                      </p>
                    </ion-text>
                  </ion-item>
                </ion-col>
                <ion-col
                  class="text-small ion-no-padding"
                  style="padding-left: 5px"
                  size-md="6"
                >
                  <ion-item class="text-small ion-no-padding">
                    <select
                      id="priceAgreements"
                      formControlName="priceAgreementID"
                      title="Prisavtale"
                      class="w50pct"
                    >
                      <option value="">
                        {{ 'NewOrder.priceAgreement' | translate }}
                      </option>
                      <option
                        *ngFor="let item of priceAgreements"
                        value="{{item.ID}}"
                      >
                        {{item.Description}}
                      </option>
                    </select>
                    &nbsp;
                    <select
                      id="transporters"
                      formControlName="transporterID"
                      title="Transportør"
                      class="w50pct"
                    >
                      <option value="">
                        {{ 'NewOrder.transporter' | translate }}
                      </option>
                      <option
                        *ngFor="let item of transporters"
                        value="{{item.ID}}"
                      >
                        {{item.Description}}
                      </option>
                    </select>
                  </ion-item>
                </ion-col>
              </ion-row>

              <!-- EMAIL AND DELIVERY EMAIL -->

              <ion-row class="w-100 ion-no-padding">
                <ion-col size-md="6" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.email' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.email' | translate }}"
                      formControlName="email"
                    />
                    <span
                      class="item-validation ion-no-padding mb-10"
                      *ngIf="!detailsForm.controls.email.valid && (detailsForm.controls.email.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p ion-text color="danger">
                          <ion-icon name="alert-circle-outline"></ion-icon
                          >&nbsp; {{ 'NewOrder.validationEmail' | translate }}
                        </p>
                      </ion-text>
                    </span></ion-item
                  >
                </ion-col>
                <ion-col
                  class="text-small ion-no-padding"
                  style="padding-left: 5px"
                  size-md="6"
                >
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'OrdreDetails.emailDelivery' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'OrdreDetails.emailDelivery' | translate }}"
                      formControlName="DeliveryEmail"
                    />
                    <span
                      class="item-validation ion-no-padding mb-10"
                      *ngIf="!detailsForm.controls.DeliveryEmail.valid && (detailsForm.controls.DeliveryEmail.dirty || submitAttempt)"
                    >
                      <ion-text color="danger">
                        <p ion-text color="danger">
                          <ion-icon name="alert-circle-outline"></ion-icon
                          >&nbsp; {{ 'NewOrder.validationEmail' | translate }}
                        </p>
                      </ion-text>
                    </span></ion-item
                  >
                </ion-col>
              </ion-row>

              <!-- PHONE NUMBERS -->

              <ion-row class="w-100 ion-no-padding">
                <ion-col size-md="6" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.phoneNumber' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.phoneNumber' | translate }}"
                      formControlName="phoneManager"
                  /></ion-item>
                </ion-col>
                <ion-col
                  class="text-small ion-no-padding"
                  style="padding-left: 5px"
                  size-md="6"
                  ><ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'OrdreDetails.project-manager' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'OrdreDetails.project-manager' | translate }}"
                      formControlName="phoneOnSite"
                  /></ion-item>
                </ion-col>
              </ion-row>

              <!-- REFERENCES -->

              <ion-row class="w-100 ion-no-padding">
                <ion-col size-md="6" class="ion-no-padding"
                  ><ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'NewOrder.customerReference' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'NewOrder.customerReference' | translate }}"
                      formControlName="customerReference"
                  /></ion-item>
                </ion-col>
                <ion-col
                  class="text-small ion-no-padding"
                  style="padding-left: 5px"
                  size-md="6"
                  ><ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'OrdreDetails.customerPurchaseOrderNo' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'OrdreDetails.customerPurchaseOrderNo' | translate }}"
                      formControlName="customerPurchaseOrderNo"
                  /></ion-item>
                </ion-col>
              </ion-row>

              <!-- DELIVERY AND INTERNAL INFO -->
              <ion-row class="w-100 ion-no-padding">
                <ion-col size-md="6" class="ion-no-padding">
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'OrdreDetails.leveringsInfo' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'OrdreDetails.leveringsInfo' | translate }}"
                      formControlName="deliveryInfo"
                    />
                  </ion-item>
                </ion-col>
                <!-- TANK INFO-->
                <ion-col
                  class="text-small ion-no-padding"
                  style="padding-left: 5px"
                  size-md="6"
                >
                  <ion-item class="text-small ion-no-padding">
                    <ion-label position="floating" class="floating-label">
                      {{ 'OrdreDetails.tankInfo' | translate }}
                    </ion-label>
                    <ion-input
                      type="text"
                      placeholder="{{ 'OrdreDetails.tankInfo' | translate }}"
                      formControlName="tankInfo"
                    />
                  </ion-item>
                </ion-col>
              </ion-row>

              <!--INTERNAL INFO-->
              <ion-item class="text-small ion-no-padding">
                <ion-label position="floating" class="floating-label">
                  {{ 'OrdreDetails.internInfo' | translate }}
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="{{ 'OrdreDetails.internInfo' | translate }}"
                  formControlName="internalInfo"
                />
              </ion-item>
            </ion-list>

            <!-- Additional info -->

            <ion-list lines="none">
              <ion-row>
                <!-- EXTERNAL COMMENTS -->
                <ion-col size-xs="12" size-md="6">
                  <ion-list lines="none">
                    <ion-item class="text-small ion-no-padding">
                      <ion-label>
                        <p class="h6">
                          <ion-icon
                            name="chatbox-outline"
                            class="vertical-text-bottom"
                            color="lightFive"
                          ></ion-icon>
                          &nbsp;
                          <ion-text color="primary">
                            <span
                              >{{ 'OrdreDetails.kommentar' | translate }}</span
                            >
                          </ion-text>
                        </p>
                        <div class="padding-left">
                          <textarea
                            title="Ekstern kommentar"
                            rows="2"
                            formControlName="orderComments"
                          ></textarea>
                          <ion-item
                            class="item-validation text-small ion-no-padding"
                            *ngIf="!detailsForm.controls.orderComments.valid"
                          >
                            <ion-text color="danger">
                              <p ion-text color="danger">
                                {{ 'OrdreDetails.validationComments' | translate
                                }}
                              </p>
                            </ion-text>
                          </ion-item>
                        </div>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>

                <!-- INTERNAL COMMENTS -->
                <ion-col size-xs="12" size-md="6">
                  <ion-list lines="none">
                    <ion-item class="text-small ion-no-padding">
                      <ion-label>
                        <p class="h6">
                          <ion-icon
                            name="chatbox-outline"
                            class="vertical-text-bottom"
                            color="lightFive"
                          ></ion-icon>
                          &nbsp;
                          <ion-text color="primary">
                            <span
                              >{{ 'OrdreDetails.internkommentar' | translate
                              }}</span
                            >
                          </ion-text>
                        </p>
                        <div class="padding-left">
                          <textarea
                            title="Intern kommentar"
                            rows="2"
                            formControlName="orderInternalComments"
                          ></textarea>
                        </div>
                      </ion-label>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>

              <ion-row>
                <!-- DELIVERY DATE -->
                <ion-col size-xs="12" size-md="4">
                  <ion-item class="ion-no-padding">
                    <ion-label color="primary">
                      <p>
                        <ion-icon
                          name="calendar-outline"
                          color="lightFive"
                        ></ion-icon>
                        &nbsp;<span
                          >{{ 'NewOrder.leveringsdato' | translate }}</span
                        >
                      </p>
                    </ion-label>
                  </ion-item>
                  <strong
                    [class.biggest-font]="userService.fontSettings==4"
                    [class.bigger-font]="userService.fontSettings==2"
                    >{{getFormControl('deliveryDate').value |
                    date:dateFormat}}</strong
                  >
                  &nbsp;&nbsp;
                  <ion-button
                    class="vertical-middle"
                    size="small"
                    fill="outline"
                    (click)="openModal = true"
                  >
                    <ion-modal
                      [cssClass]="'center'"
                      [isOpen]="openModal"
                      (didDismiss)="closeModal()"
                    >
                      <ng-template>
                        <ion-datetime
                          #picker
                          presentation="date"
                          formControlName="deliveryDate"
                          size="cover"
                          [showDefaultButtons]="true"
                          doneText="{{ 'buttons.ok' | translate }}"
                          cancelText="{{ 'buttons.cancel' | translate }}"
                          class="dateText"
                          first-day-of-week="1"
                        >
                        </ion-datetime>
                      </ng-template>
                    </ion-modal>
                    <ion-icon name="calendar-outline" slot="start"></ion-icon
                    >&nbsp;{{ 'General.edit' | translate }}
                  </ion-button>
                </ion-col>

                <ion-col size-xs="12" size-md="8">
                  <div class="pull-right-md w-100">
                    <!-- ADDITIONAL PRODUCTS DELIVERED -->
                    <ion-item lines="none">
                      <ion-checkbox
                        class="ion-no-margin"
                        formControlName="additionalProductsDelivered"
                        (ionChange)="additionalProductsDeliveredChanged()"
                        >{{ 'NewOrder.andreProdukterErUtlevert' | translate
                        }}</ion-checkbox
                      >
                    </ion-item>

                    <!-- VOLUME TO STANDARD TRUCK -->
                    <ion-item
                      lines="none"
                      *ngIf="VolumeToStandardTruck && !VolumeToStandardOff"
                    >
                      <ion-checkbox
                        class="ion-no-margin"
                        [(ngModel)]="DoNotCalcVolToStandard"
                        [ngModelOptions]="{standalone: true}"
                        >{{ 'OrdreDetails.DoNotCalc' | translate
                        }}</ion-checkbox
                      >
                    </ion-item>

                    <!-- DO NOT SEND GPS -->
                    <ion-item lines="none">
                      <ion-checkbox
                        class="ion-no-margin"
                        [(ngModel)]="DoNotSendGPS"
                        [ngModelOptions]="{standalone: true}"
                        >{{ 'OrdreDetails.DoNotSendGPS' | translate
                        }}</ion-checkbox
                      >
                    </ion-item>

                    <!-- DO NOT CHANGE PRICES -->
                    <ion-item lines="none">
                      <ion-checkbox
                        *ngIf="userlevel <= 3"
                        class="ion-no-margin"
                        [(ngModel)]="DoNotChangePrice"
                        [ngModelOptions]="{standalone: true}"
                        >{{ 'OrdreDetails.DoNotChangePrice' | translate
                        }}</ion-checkbox
                      >
                    </ion-item>
                  </div>
                </ion-col>
              </ion-row>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col *ngIf="userlevel >= 5">
            <!-- PRODUCTS SECTION NORMAL USER-->
            <div class="ion-margin-top">
              <p class="pull-left-md">
                <ion-icon name="flame-outline" color="lightFive"></ion-icon>
                <ion-text color="primary"
                  ><span class="fw500"
                    >{{ 'NewOrder.produkter' | translate }}</span
                  ></ion-text
                >
              </p>
              <ion-button
                size="small"
                class="no-padding pull-right-md"
                (click)="leggTilProdukt()"
              >
                <ion-icon name="add-circle-outline"></ion-icon>&nbsp;
                <span>{{ 'NewOrder.leggTilProdukt' | translate }}</span>
              </ion-button>
              <div class="clear"></div>
              <ion-row class="grid-header">
                <ion-col size-xs="5">
                  {{ 'NewOrder.produkt' | translate }}
                </ion-col>
                <ion-col size-xs="4">
                  {{ 'NewOrder.bestilt' | translate }}
                </ion-col>
                <ion-col size-xs="3">
                  {{ 'NewOrder.levert' | translate }}
                </ion-col>
              </ion-row>
              <ion-row
                *ngFor="let orderLine of orderLines"
                formGroupName="orderLines"
                class="ion-align-items-center"
              >
                <ion-col size-xs="5"> {{orderLine.Description}} </ion-col>
                <ion-col size-xs="4">
                  <div class="inline-block bestilt">{{orderLine.Quantity}}</div>
                </ion-col>
                <ion-col size-xs="3">
                  <input
                    title="Antall"
                    type="number"
                    class="border-input ion-no-margin"
                    step="any"
                    formControlName="{{'o' + orderLine.LineNo}}"
                    (keyup)="setValue(orderLine,'')"
                    (click)="setValue(orderLine,'')"
                  />
                  <ng-container
                    *ngIf="!detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).dirty || submitAttempt))"
                  >
                    <ion-text color="danger"
                      ><span
                        *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.pattern"
                        >{{ 'NewOrder.onlyNumbers' | translate }}</span
                      ></ion-text
                    >
                    <ion-text color="danger">
                      <span
                        *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.required"
                        >{{ 'NewOrder.quantityRequired' | translate }}</span
                      ></ion-text
                    >
                  </ng-container>
                </ion-col>
              </ion-row>
            </div>
          </ion-col>

          <!-- PRODUCTS SECTION EXTENDED USER-->
          <ion-col size-xs="12" *ngIf="userlevel < 5">
            <div class="ion-margin-top">
              <p class="pull-left-md">
                <ion-icon name="flame-outline" color="lightFive"></ion-icon>
                <ion-text color="primary"
                  ><span ion-text
                    >{{ 'NewOrder.produkter' | translate }}</span
                  ></ion-text
                >
              </p>
              <ion-button
                size="default"
                class="no-padding pull-right-md ion-margin-bottom"
                (click)="leggTilProdukt()"
              >
                <ion-icon name="add-circle-outline"></ion-icon>&nbsp;
                <span>{{ 'NewOrder.leggTilProdukt' | translate }}</span>
              </ion-button>
              <div class="clear"></div>
              <div class="overflow-x">
                <div class="product-grid">
                  <ion-row class="grid-header">
                    <ion-col size-xs="3"
                      >{{ 'OrdreDetails.produkt' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.bestilt' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.levert' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.cost' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.pris' | translate }}</ion-col
                    >
                    <ion-col size-xs="1">
                      {{ 'OrdreDetails.freight' | translate | uppercase }}
                    </ion-col>
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.rab' | translate }}</ion-col
                    >
                    <ion-col size-xs="2"
                      >{{ 'OrdreDetails.total' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"></ion-col>
                  </ion-row>
                  <ion-row
                    *ngFor="let orderLine of orderLines;let isOdd = odd; let i = index"
                    [class.odd]="isOdd"
                    formGroupName="orderLines"
                    class="text-small ion-align-items-center"
                  >
                    <ion-col
                      class="cursor-pointer litte-big-font-2"
                      size-xs="3"
                      size-md="3"
                      appClickOutside
                      (clickOutside)="changeElement($event,orderLine)"
                    >
                      <div
                        [hidden]="orderLine.changeProduct"
                        class="flex-parent"
                      >
                        <ion-badge
                          [style.background]="orderLine.ProductColorCode"
                        >
                          {{orderLine.ProductShortCode}}</ion-badge
                        >&nbsp;
                        <span class="truncate-text"
                          >{{orderLine.Description}}</span
                        >
                      </div>
                      <div [hidden]="!orderLine.changeProduct">
                        <select
                          *ngIf="!allowDescriptionChange"
                          id="productNo"
                          formControlName="{{'productNo' + orderLine.LineNo}}"
                          (change)="selectProduct($event,orderLine)"
                          (blur)="selectProduct($event,orderLine)"
                        >
                          <option value="">
                            {{ 'General.select' | translate }}
                          </option>
                          <option
                            *ngFor="let product of products"
                            value="{{product.ProductNo}}"
                          >
                            {{product.Description}}
                          </option>
                        </select>
                        <input
                          type="text"
                          formControlName="{{'productNo' + orderLine.LineNo}}"
                          (change)="selectProduct($event,orderLine)"
                          *ngIf="allowDescriptionChange"
                          class="border-input litte-big-font-2 ion-no-margin"
                        />
                        <ng-container
                          *ngIf="!detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).dirty || submitAttempt))"
                        >
                          <ion-text color="danger"
                            ><span
                              *ngIf="detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).errors.required"
                            >
                              {{'OrdreDetails.descriptionRequired' | translate
                              }}</span
                            >
                          </ion-text>
                        </ng-container>
                      </div>
                    </ion-col>
                    <ion-col size-xs="1" size-md="1" class="litte-big-font-2">
                      <div class="inline-block bestilt">
                        {{orderLine.Quantity}}
                      </div>
                    </ion-col>
                    <ion-col size-xs="3" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        (keyup)="setValue(orderLine, 'quantity')"
                        (click)="setValue(orderLine, 'quantity')"
                        formControlName="{{'quantity' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>
                    <ion-col size-xs="3" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        (keyup)="setValue(orderLine, 'cost')"
                        (click)="setValue(orderLine, 'cost')"
                        formControlName="{{'cost' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>
                    <ion-col size-xs="2" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        (keyup)="setValue(orderLine, 'price')"
                        (click)="setValue(orderLine, 'price')"
                        formControlName="{{'price' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('price' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('price' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('price' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('price' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>

                    <ion-col size-xs="2" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        formControlName="{{'UnitFreightCost' + orderLine.LineNo}}"
                        (keyup)="setValue(orderLine, 'UnitFreightCost')"
                        (click)="setValue(orderLine, 'UnitFreightCost')"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>

                    <ion-col size-xs="2" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        formControlName="{{'discount' + orderLine.LineNo}}"
                        (keyup)="setValue(orderLine, 'discount')"
                        (click)="setValue(orderLine, 'discount')"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>

                    <ion-col size-xs="6" size-md="2"
                      >{{orderLine.OrderLineTotal | number:'1.2-2'}}</ion-col
                    >
                    <ion-col size-xs="6" size-md="1" class="ion-no-padding">
                      <ion-icon
                        name="trash-outline"
                        color="primary"
                        class="icon-size cursor-pointer"
                        (click)="showConfirm(orderLine)"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                  <ion-row class="m-t-5">
                    <ion-col
                      size-xs="9"
                      size-md="9"
                      class="bigger-font ion-text-right"
                    >
                      <ion-text color="black">
                        <b>{{'OrdreDetails.orderTotal' | translate }}</b
                        >&nbsp;</ion-text
                      >
                    </ion-col>
                    <ion-col size-xs="3" size-md="3" class="bigger-font">
                      <ion-text color="black"
                        ><b>{{ totalOrder | number:'1.2-2'}}</b></ion-text
                      >
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </div>
</ion-content>

<!--BUTTONS-->
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <ion-button
      fill="outline"
      class="no-padding"
      (click)="navigateToOrdersList()"
    >
      <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
      <span class="black">{{ 'NewOrder.tilbake' | translate }}</span>
    </ion-button>

    <ion-button
      fill="outline"
      [hidden]="isHidden"
      class="no-padding"
      (click)="showhide()"
      size="default"
    >
      <ion-icon name="caret-up-circle-outline"></ion-icon>
      &nbsp;
      <span class="black">{{ 'OrdreDetails.HideContact' | translate}}</span>
    </ion-button>

    <ion-button
      fill="outline"
      [hidden]="!isHidden"
      size="default"
      class="no-padding"
      (click)="showhide()"
    >
      <ion-icon name="caret-down-circle-outline"></ion-icon>
      &nbsp;
      <span class="black">{{ 'OrdreDetails.ShowContact' | translate}}</span>
    </ion-button>

    <ion-button class="no-padding pull-right-md" (click)="createNewOrder()">
      <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
      <span>{{ 'NewOrder.ferdigmeldOrdre' | translate }}</span>
    </ion-button>
  </div>
</ion-footer>
