import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { TransactionType } from '../../shared/enums';

@Component({
    selector: 'egne-tanker-confirm',
    templateUrl: 'egne-tanker-confirm.html',
    styleUrls: ['egne-tanker-confirm.scss']
})
export class EgneTankerConfirmComponent {
    public productDescription = '';
    public tankName = '';
    public antLiter: any;
    public truckName = '';
    public currentTruck: any;
    public transactionType: TransactionType;
    public TransactionType: any = TransactionType;
    private tankNo = 0;
    public inventoryList: any;
    public currentDate;

    constructor(public navParams: NavParams, public modalCtrl: ModalController) {
        const data = this.navParams.data;
        this.inventoryList = data.data;
        this.transactionType = data.transactionType;
        this.productDescription = data.productDescription;
        this.tankName = data.tankName;
        this.antLiter = data.antLiter;
        this.truckName = data.truckName;
        this.currentTruck = data.currentTruck;
        this.tankNo = data.tankNo;
        this.currentDate = data.selectedDate;
    }

    dismiss(flag) {
        this.modalCtrl.dismiss(flag);
    }
}
