<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span
        >{{ (view == viewType.LIST ? 'Menu.ordrer' : 'Menu.kundeordrer') |
        translate }}</span
      >
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content content-margin-top">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <div class="page-title">
            <ion-row class="flexCenter ion-align-items-center">
              <ion-col size-xs="12" size-md="4" *ngIf="visKunDagens">
                <ng-container *ngIf="visKunDagens">
                  <ion-row class="ion-align-items-center">
                    <ion-col size="7">
                      <ion-icon
                        name="calendar-outline"
                        ion-text
                        color="primary"
                        class="vertical-middle m-r-10"
                        id="datepicker"
                        (click)="openModal = true"
                      ></ion-icon>
                      <ion-modal
                        [cssClass]="'center'"
                        id="dtOrder"
                        [isOpen]="openModal"
                        (didDismiss)="closeModal()"
                      >
                        <ng-template>
                          <ion-datetime
                            #picker
                            [(ngModel)]="dateSelector"
                            (ionChange)="dateChangeEvent($event)"
                            presentation="date"
                            first-day-of-week="1"
                            size="cover"
                            [showDefaultButtons]="true"
                            doneText="{{ 'buttons.ok' | translate }}"
                            cancelText="{{ 'buttons.cancel' | translate }}"
                            class=""
                          >
                          </ion-datetime>
                        </ng-template>
                      </ion-modal>
                      <span (click)="openModal = true"
                        >{{'Day.' + currentDate.getDay() | translate}}</span
                      >
                      {{currentDate | date:dateFormat}}
                    </ion-col>
                    <ion-col size="5"
                      ><span class="secondary">
                        <ion-button
                          (click)="previousDay()"
                          class="no-padding button-no-text vertical-top ion-no-margin"
                          fill="clear"
                        >
                          <ion-icon
                            color="lightFour"
                            slot="icon-only"
                            name="caret-back-circle"
                          ></ion-icon>
                        </ion-button>
                        <ion-button
                          (click)="nextDay()"
                          class="no-padding button-no-text vertical-top ion-no-margin"
                          fill="clear"
                        >
                          <ion-icon
                            color="lightFour"
                            slot="icon-only"
                            name="caret-forward-circle"
                          ></ion-icon>
                        </ion-button>
                      </span>
                    </ion-col>
                  </ion-row>
                </ng-container>
              </ion-col>
              <ion-col seize-xs="12" size-md="4" *ngIf="!visKunDagens">
              </ion-col>
              <ion-col seize-xs="9" size-md="4">
                <div class="search-container">
                  <ion-searchbar
                    [(ngModel)]="search"
                    class="customer-search"
                    placeholder="{{ 'TransactionLog.searchPlaceHolder' | translate }}"
                    (keyup)="filterChanged(null)"
                  >
                  </ion-searchbar>
                  <ion-icon
                    name="close-circle-outline"
                    class="clear-icon"
                    (click)="clearSearch()"
                  ></ion-icon>
                </div>
              </ion-col>
              <ion-col size-xs="3" size-md="4" class="ion-text-right">
                <ng-container *ngFor="let item of inventoryList">
                  <span
                    *ngIf="item.QuantityInOrder != 0 || item.Quantity != 0"
                    class="m-r-5 text-small inventoryItem"
                  >
                    <ion-badge [style.background]="item.ProductColorCode"
                      >{{item.ProductShortCode}}</ion-badge
                    >
                    <strong> {{item.Quantity}}</strong> &nbsp; | &nbsp;
                    <span
                      [class.alert-danger]="(item.Quantity-item.QuantityInOrder)<0"
                      [class.alert-success]="(item.Quantity-item.QuantityInOrder)>=0"
                    >
                      <strong>{{item.QuantityInOrder}}</strong>
                    </span>
                  </span>
                </ng-container>
                &nbsp;
                <span class="display-inline-block text-small">
                  <select
                    [(ngModel)]="company"
                    (ngModelChange)="companyFilter()"
                    class="margin-boottom-4"
                    title="Velg selskap"
                  >
                    <option value="0">
                      {{ 'General.select' | translate }}
                    </option>
                    <option
                      *ngFor="let company of companies"
                      value="{{company.CompanyNo}}"
                    >
                      {{company.Name}}
                    </option>
                  </select>
                </span>
              </ion-col>
              <ion-col size-xs="12">
                <ng-container *ngIf="showAssignedToTruck && visKunDagens">
                  <!-- Code for large screens -->
                  <ion-radio-group
                    value="RouteNo"
                    (ionChange)="selectRoute($event)"
                    class="hidden-xs"
                  >
                    <ion-row>
                      <ion-col *ngFor="let i of RouteNos">
                        <ion-item>
                          <ion-radio [value]="i"></ion-radio>
                          <ion-label>&nbsp;Tur nr. {{i}}</ion-label>
                        </ion-item>
                      </ion-col>
                      <ion-col>
                        <ion-item>
                          <ion-radio [value]="0"></ion-radio>
                          <ion-label>&nbsp;Oversikt</ion-label>
                        </ion-item>
                      </ion-col>
                    </ion-row>
                  </ion-radio-group>

                  <!-- Code for mobile devices -->
                  <ion-select
                    value="RouteNo"
                    (ionChange)="selectRoute($event)"
                    class="hidden-sm-up"
                    placeholder="Velg tur"
                  >
                    <ion-select-option [value]="0"
                      >&nbsp;Oversikt</ion-select-option
                    >
                    <ion-select-option *ngFor="let i of RouteNos" [value]="i"
                      >&nbsp;Tur nr. {{i}}</ion-select-option
                    >
                  </ion-select>
                </ng-container>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="currentNote != ''">
              <ion-col class="message">
                <ion-icon name="megaphone-outline"></ion-icon>&nbsp;
                {{currentNote}}
              </ion-col>
            </ion-row>
          </div>
          <ng-container *ngIf="view == viewType.LIST">
            <div class="max-100">
              <div class="grid-width">
                <ion-row class="grid-header ion-align-items-center">
                  <ion-col
                    size-xs="12"
                    size-md="1"
                    class="ion-text-center"
                    (click)="sort('OrderNo')"
                  >
                    <strong>{{ 'Ordrer.ordre' | translate }}</strong>

                    <ion-icon
                      ion-text
                      (click)="sort('OrderNo')"
                      color="lightFour"
                      [name]="(sortProperty == 'OrderNo' && sortDirection == 'asc') || sortProperty != 'OrderNo' ? 'caret-up' : 'caret-down'"
                    >
                    </ion-icon>
                  </ion-col>
                  <ion-col
                    size="4"
                    class="hidden-xs"
                    (click)="sort('DeliveryName')"
                  >
                    <strong>{{ 'Ordrer.kunde' | translate }}</strong>

                    <ion-icon
                      ion-text
                      (click)="sort('DeliveryName')"
                      color="lightFour"
                      [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'"
                    >
                    </ion-icon>
                  </ion-col>
                  <ion-col
                    size="2"
                    class="hidden-xs ion-text-left"
                    (click)="sort('DeliveryDate')"
                  >
                    <strong>{{ 'Ordrer.leveres' | translate }}</strong>

                    <ion-icon
                      ion-text
                      color="lightFour"
                      (click)="sort('DeliveryDate')"
                      [name]="(sortProperty == 'DeliveryDate' && sortDirection == 'asc') || sortProperty != 'DeliveryDate' ? 'caret-up' : 'caret-down'"
                    >
                    </ion-icon>
                  </ion-col>
                  <ion-col size="3" class="hidden-xs">
                    <strong>{{ 'Ordrer.produkterVolum' | translate }}</strong>
                  </ion-col>
                  <ion-col size="2" class="hidden-xs">
                    <strong></strong>
                  </ion-col>
                </ion-row>
                <div dragula="HANDLES">
                  <ion-list
                    [dragula]='"dragdrop"'
                    [dragulaModel]="filteredOrders"
                    (dragulaModelChange)="onDrop($event)"
                  >
                    <ion-row
                      *ngFor="let order of filteredOrders; let isOdd = odd; let i = index"
                      [class.odd]="isOdd"
                      [attr.itemid]="order.OrderNo"
                      id="{{order.OrderNo}}"
                    >
                      <ion-col>
                        <ion-row>
                          <ion-col
                            size-xs="6"
                            size-md="1"
                            class="drag-handle hidden-xs"
                            [class.bigger-font]="userService.fontSettings==2"
                            [class.biggest-font]="userService.fontSettings==4"
                          >
                            <ion-text
                              [color]="isOrderMine(order) == true ? 'success' : ''"
                            >
                              <strong>{{order.OrderNo}}</strong>

                              <ng-container *ngIf="order.RouteNo>0">
                                <br />
                                {{'OrderPlanner.RouteNo' | translate}}
                                <strong>{{order.RouteNo}}</strong>
                              </ng-container>
                              <br />{{ShowCompanyName(order.CompanyNo)}}
                            </ion-text>
                            <div
                              *ngIf="isOrderMine(order) == true && !UsePlanner"
                            >
                              <ion-icon
                                name="person-outline"
                                color="success"
                                class="litte-big-font"
                              ></ion-icon>
                            </div>
                          </ion-col>

                          <ion-col
                            size-xs="12"
                            size-md="4"
                            [class.bigger-font]="userService.fontSettings==2"
                            [class.biggest-font]="userService.fontSettings==4"
                          >
                            <div
                              class="cursor-pointer orderlist"
                              (click)="expandOrder(order)"
                            >
                              <ion-icon
                                color="primary"
                                [name]="!order.expandedView ? 'add-circle-outline' : 'remove-circle-outline'"
                                class="litte-big-font vertical-text-bottom orderlistIcon"
                              >
                              </ion-icon>
                              <div class="orderlistHeading">
                                <strong>{{order.CustomerName}}</strong>&nbsp;
                                ({{order.CustomerID}})
                                <ng-container
                                  *ngIf="order.CustomerName != order.DeliveryName"
                                >
                                  <br />
                                  {{order.DeliveryName}}
                                </ng-container>
                                <br />
                                {{order.DeliveryAddress1}}, &nbsp;
                                {{order.DeliveryAddress2}}
                                <br />{{order.DeliveryPostalCode}}
                                {{order.DeliveryCity}}
                              </div>
                            </div>
                          </ion-col>

                          <ion-col
                            size-xs="12"
                            class="hidden-xs"
                            [class.bigger-font]="userService.fontSettings==2"
                            [class.biggest-font]="userService.fontSettings==4"
                            size-md="2"
                          >
                            <div class="icon-date">
                              Ønsket: &nbsp;
                              <strong>
                                {{getDateText(order,1) | async }} </strong
                              ><br />
                            </div>
                            <div
                              class="icon-date"
                              *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate"
                            >
                              Senest:&nbsp;
                              <strong>{{getDateText(order,2) | async }}</strong>
                              <br />
                            </div>
                            <div
                              class="m-t-5 icon-date"
                              *ngIf="order.RouteNo >0"
                            >
                              Planlagt: &nbsp;<strong
                                >{{getDateText(order,3) | async }}</strong
                              >
                            </div>
                          </ion-col>

                          <ion-col
                            size-xs="12"
                            size-md="3"
                            [class.bigger-font]="userService.fontSettings==2"
                            [class.biggest-font]="userService.fontSettings==4"
                          >
                            <span
                              *ngFor="let orderLine of order.FilteredOrderlines"
                              class="m-r-5 orderline"
                            >
                              <ion-badge
                                [style.background]="orderLine.ProductColorCode"
                                >{{orderLine.ProductShortCode}}
                              </ion-badge>
                              {{orderLine.Quantity}}
                            </span>

                            <div
                              class="danger-small-font internal"
                              *ngIf="order.OrderInternalComments || (order.DeliveryInfo && order.FilteredOrderlines.length > 0)"
                            >
                              <ion-text color="danger"
                                ><span
                                  appPhoneRegex
                                  [inputText]="order.DeliveryInfo"
                                ></span>
                              </ion-text>
                              <span *ngIf="order.OrderInternalComments != ''"
                                ><br />
                                <ion-text color="danger"
                                  ><span
                                    appPhoneRegex
                                    [inputText]="order.OrderInternalComments"
                                  ></span> </ion-text
                              ></span>
                            </div>
                          </ion-col>

                          <ion-col
                            size-xs="3"
                            size-md="1"
                            class="hidden-sm-up"
                            [class.bigger-font]="userService.fontSettings==2"
                            [class.biggest-font]="userService.fontSettings==4"
                          >
                            <ion-text
                              [color]="isOrderMine(order) == true ? 'success' : ''"
                            >
                              <strong>{{order.OrderNo}}</strong>
                              <br />{{ShowCompanyName(order.CompanyNo)}}
                            </ion-text>
                            <div
                              *ngIf="isOrderMine(order) == true && !UsePlanner"
                            >
                              <ion-icon
                                name="person-outline"
                                color="success"
                                class="litte-big-font"
                              ></ion-icon>
                            </div>
                          </ion-col>

                          <ion-col
                            size-xs="4"
                            class="hidden-sm-up ion-text-center"
                            [class.bigger-font]="userService.fontSettings==2"
                            [class.biggest-font]="userService.fontSettings==4"
                          >
                            <div
                              class="circle"
                              [class.red-circle]="order.IsUrgent"
                              [class.grey-circle]="!order.IsUrgent"
                            ></div>
                            Ønsket:
                            <strong>
                              <span
                                class="vertical-top"
                                [innerText]="getDateText(order,1) | async"
                              ></span>
                            </strong>
                            <span
                              *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00' && (getDateText(order,2) |
                            async) != '01.01.0001'"
                              class="upper bold"
                            >
                              <br />

                              <ion-icon
                                name="alert-circle-outline"
                                class="circle red-circle"
                              ></ion-icon
                              >Frist: {{getDateText(order,2) | async}}
                            </span>
                          </ion-col>
                          <ion-col
                            size-xs="5"
                            size-md="2"
                            class="ion-text-right"
                          >
                            <ion-button
                              (click)="plukkOrApne(order)"
                              [color]="order.AssignedDriver != 0 ? 'success' : ''"
                              fill="solid"
                              class="plukk-apne"
                            >
                              <ion-icon name="checkbox-outline"></ion-icon>
                              &nbsp;
                              <ng-container *ngIf="order.AssignedDriver == 0"
                                >{{ 'Ordrer.plukk' | translate }}
                              </ng-container>
                              <ng-container *ngIf="order.AssignedDriver != 0"
                                >{{ 'Ordrer.apne' | translate }}
                              </ng-container>
                            </ion-button>
                            <ion-button
                              class="no-padding pull-right-md"
                              (click)="loadDrivingInstructions(order)"
                              *ngIf="latitude > 0"
                            >
                              <ion-icon name="navigate-outline"></ion-icon
                              >&nbsp;
                            </ion-button>
                          </ion-col>

                          <!-- EXPANDED VIEW -->
                          <ion-col
                            size-sm="12"
                            *ngIf="order.expandedView"
                            [class.expandedOrder]="!isOdd"
                            class="orderlistExpanded bgWhitee"
                          >
                            <ion-list lines="none">
                              <ion-item>
                                <ion-row class="w-100">
                                  <!-- EXPANDED VIEW - DATES -->
                                  <ion-col size-xs="12" size-sm="4">
                                    <p>
                                      <ion-icon
                                        name="calendar-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold">
                                        <span
                                          >{{ 'OrdreDetails.leveringsdato' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span class="detailsIconText"
                                      >{{order.DeliveryDate | date:dateFormat}}

                                      <span
                                        *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                                      >
                                        /
                                        <ion-icon
                                          name="alert-circle-outline"
                                          class="circle vertical-text-bottom"
                                          color="danger"
                                        ></ion-icon>
                                        {{order.ConfirmedDeliveryDate |
                                        date:dateFormat}}</span
                                      >
                                    </span>
                                  </ion-col>

                                  <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                                  <ion-col size-xs="12" size-sm="4">
                                    <p>
                                      <ion-icon
                                        name="people-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.CustomerBuyer' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span class="detailsIconText"
                                      >{{order.CustomerBuyer}}</span
                                    >
                                  </ion-col>

                                  <!-- EXPANDED VIEW - OUR SELLER -->
                                  <ion-col size-xs="12" size-sm="4">
                                    <p>
                                      <ion-icon
                                        name="person-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.OurSeller' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span class="detailsIconText"
                                      >{{order.Seller}}</span
                                    >
                                  </ion-col>
                                </ion-row>
                              </ion-item>

                              <ion-item>
                                <ion-row class="w-100 margin-top-10">
                                  <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                                  <ion-col size-xs="12" size-sm="4">
                                    <p>
                                      <ion-icon
                                        name="cube-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.leveringsinfo' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <ng-container *ngIf="order.TankInfo"
                                      ><span class="detailsIconText"
                                        >{{order.TankInfo}}</span
                                      >
                                      <br />
                                    </ng-container>
                                    <ng-container *ngIf="order.DeliveryInfo"
                                      ><span class="detailsIconText"
                                        >{{order.DeliveryInfo}}</span
                                      >
                                      <br />
                                    </ng-container>
                                  </ion-col>

                                  <!--Phone onsite-->
                                  <ion-col size-xs="12" size-sm="4">
                                    <p>
                                      <ion-icon
                                        name="call-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.onSite' | translate
                                          }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span
                                      class="detailsIconText"
                                      appPhoneRegex
                                      [inputText]="order.PhoneOnSite"
                                    ></span>
                                  </ion-col>
                                  <!--Phone manager-->
                                  <ion-col size-xs="12" size-sm="4">
                                    <p>
                                      <ion-icon
                                        name="call-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.manager' | translate
                                          }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span
                                      class="detailsIconText"
                                      appPhoneRegex
                                      [inputText]="order.PhoneManager"
                                    ></span>
                                  </ion-col>
                                </ion-row>
                              </ion-item>

                              <ion-item
                                *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite"
                              >
                                <ion-row class="w-100 margin-top-10">
                                  <!-- INTERNAL INFO-->
                                  <ion-col size-xs="12" size-sm="4">
                                    <ion-row *ngIf="order.InternalInfo != ''">
                                      <ion-col size-xs="12">
                                        <p>
                                          <ion-icon
                                            name="chatbox-ellipses-outline"
                                            class="vertical-text-bottom mr-10"
                                            color="primary"
                                          ></ion-icon>
                                          <ion-text class="upper bold"
                                            ><span
                                              >{{ 'OrdreDetails.internInfo' |
                                              translate }}</span
                                            ></ion-text
                                          >
                                        </p>
                                        <span
                                          class="detailsIconText"
                                          appPhoneRegex
                                          [inputText]="order.InternalInfo"
                                        ></span>
                                      </ion-col>
                                    </ion-row>
                                  </ion-col>

                                  <!-- EXTERNAL INFO -->
                                  <ion-col size-xs="12" size-sm="4">
                                    <ion-row *ngIf="order.ExternalInfo != ''">
                                      <ion-col size-xs="12">
                                        <p>
                                          <ion-icon
                                            name="chatbox-ellipses-outline"
                                            class="vertical-text-bottom mr-10"
                                            color="primary"
                                          ></ion-icon>
                                          <ion-text class="upper bold"
                                            ><span
                                              >{{ 'OrdreDetails.externInfo' |
                                              translate }}</span
                                            ></ion-text
                                          >
                                        </p>
                                        <span class="detailsIconText"
                                          >{{order.ExternalInfo}}</span
                                        >
                                      </ion-col>
                                    </ion-row>
                                  </ion-col>

                                  <ion-col size-xs="12" size-sm="4"> </ion-col>
                                </ion-row>
                              </ion-item>
                              <!-- PRODUCT LIST -->
                              <div
                                class="text-small ion-margin-bottom ion-margin-start margin-top-10 overflow-x"
                                *ngIf="order.Orderlines.length > 0 && userlevel <= 1"
                              >
                                <div class="product-grid">
                                  <ion-row
                                    class="orderlistExpandProductHeading"
                                  >
                                    <ion-col size-xs="12" size-sm="4"
                                      ><b
                                        >{{ 'OrdreDetails.produkt' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.levert' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.cost' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.pris' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.freight' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="2"
                                      ><b
                                        >{{ 'OrdreDetails.priceAdditions' |
                                        translate | uppercase }}&nbsp;&nbsp;{{
                                        'OrdreDetails.priceIncAdditions' |
                                        translate | uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.total' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.DB' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                  </ion-row>
                                  <ion-row
                                    *ngFor="let orderLine of order.Orderlines"
                                  >
                                    <ion-col size-xs="12" size-sm="4">
                                      <div class="flex-parent">
                                        <ion-badge
                                          [style.background]="orderLine.ProductColorCode"
                                          >{{orderLine.ProductShortCode}}</ion-badge
                                        >&nbsp;
                                        <span class="truncate-text"
                                          >{{orderLine.Description}}</span
                                        >
                                      </div>
                                    </ion-col>
                                    <ion-col size-xs="1">
                                      <span class="slight-big-font"
                                        ><strong
                                          >{{orderLine.QuantityDelivered |
                                          number:'1.0-1'}}</strong
                                        ></span
                                      >
                                    </ion-col>
                                    <ion-col size-xs="1"
                                      >{{ orderLine.UnitCost |
                                      number:'1.1-2'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{ orderLine.UnitPrice |
                                      number:'1.1-2'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{ orderLine.UnitFreightCost |
                                      number:'1.0-1'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{ orderLine.PriceAdditions |
                                      number:'1.1-2'}}</ion-col
                                    >
                                    <ion-col size-xs="1" class="bold"
                                      >{{
                                      orderLine.UnitPrice+orderLine.PriceAdditions+orderLine.UnitFreightCost
                                      | number:'1.1-2'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{ orderLine.OrderLineTotal |
                                      number:'1.1-2'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{
                                      (orderLine.UnitPrice+orderLine.PriceAdditions+orderLine.UnitFreightCost)-(orderLine.UnitCost)|
                                      number:'1.1-2'}}</ion-col
                                    >
                                  </ion-row>
                                </div>
                              </div>
                            </ion-list>
                          </ion-col>
                          <!-- END -->
                        </ion-row>
                        <!-- <ion-row class="hidden-sm-up">
                                            <ion-col size=8>
                                                <strong>{{order.DeliveryName}}</strong>
                                                <br /> {{order.DeliveryAddress1}}
                                                <br>{{order.DeliveryPostalCode}} {{order.DeliveryCity}}
                                                <br>
                                                <span *ngFor="let orderLine of order.FilteredOrderlines" class="m-r-5 orderline">
                                                    <ion-badge [style.background]="orderLine.ProductColorCode">{{orderLine.ProductShortCode}}</ion-badge> {{orderLine.Quantity}}
                                                </span>
                                                <div ion-text color="danger" class="danger-small-font internal" *ngIf="order.InternalInfo">
                                                    {{order.InternalInfo}}
                                                </div>
                                            </ion-col>
                                            <ion-col size=2 text-right>
                                                <button ion-button (click)="plukkOrApne(order)" [style.color]="order.AssignedDriver != 0 ? 'success' : ''" class="plukk-apne">
                                                    <ion-icon name="md-checkbox-fill="outline""></ion-icon> &nbsp;
                                                    <ng-container *ngIf="order.AssignedDriver == 0">{{ 'Ordrer.plukk' | translate }}</ng-container>
                                                    <ng-container *ngIf="order.AssignedDriver != 0">{{ 'Ordrer.apne' | translate }}</ng-container>
                                                </button>
                                            </ion-col>
                                        </ion-row> -->
                      </ion-col>
                    </ion-row>
                  </ion-list>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="view == viewType.MAP"
            class="map"
            leaflet
            [leafletOptions]="leafletOptions"
            [leafletLayers]="layers"
            (leafletMapReady)="onMapReady($event)"
            id="map"
          ></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="hidden-sm-up footer-border">
    <ion-button fill="outline" (click)="expandFooterMenu()">
      <ion-icon
        name="menu-outline"
        class="ion-no-padding"
        color="primary"
      ></ion-icon>
    </ion-button>
    <ion-button
      fill="outline"
      class="pull-right"
      (click)="visKunDagensChanged(null)"
    >
      <ion-icon name="refresh" color="primary"></ion-icon>
    </ion-button>
  </div>

  <div [hidden]="!expand">
    <div *ngIf="userType !== UserType.Supplier">
      {{ 'Ordrer.showTruckOrders' | translate }}
      <ion-toggle
        class="inline-block m-10"
        color="success"
        id="truckFilter"
        [(ngModel)]="showAssignedToTruck"
        (ionChange)="filterChanged($event)"
        [disabled]="DisableUserFilter"
      ></ion-toggle>
      &nbsp;&nbsp; {{ 'Ordrer.visKunDagens' | translate }}
      <ion-toggle
        class="inline-block m-10"
        color="success"
        [(ngModel)]="visKunDagens"
        (click)="filterChanged($event)"
        [disabled]="DisableUserFilter"
      ></ion-toggle>

      <ng-container *ngIf="UsePlanner && DisableUserFilter"
        >{{'General.PlannerInUse' | translate }}</ng-container
      >
      <ng-container *ngIf="!DisableUserFilter">
        &nbsp;&nbsp; {{ 'Ordrer.mine' | translate }}
        <ion-toggle
          class="inline-block m-10"
          color="success"
          [(ngModel)]="mine"
          (ionChange)="filterChanged($event)"
          [disabled]="DisableUserFilter"
        >
        </ion-toggle>
        &nbsp;&nbsp; {{ 'Ordrer.utildelteOrdrer' | translate }}
        <ion-toggle
          class="inline-block m-10"
          color="success"
          [(ngModel)]="utildelteOrdrer"
          (ionChange)="filterChanged($event)"
          [disabled]="DisableUserFilter"
        ></ion-toggle>
        &nbsp;&nbsp; {{ 'Ordrer.nerMeg' | translate }}
        <ion-toggle
          class="inline-block m-10"
          color="success"
          [disabled]="latitude == null || longitude == null"
          [(ngModel)]="nearMeg"
          (ionChange)="filterChanged($event)"
          [disabled]="DisableUserFilter"
        ></ion-toggle>
        &nbsp;&nbsp;
        <input
          type="number"
          autocomplete="off"
          class="nearmeg-input"
          [(ngModel)]="nearMegDistance"
          (ngModelChange)="filterChanged($event)"
          [disabled]="latitude == null || longitude == null || !nearMeg"
          placeholder="{{ 'Ordrer.near' | translate }}"
          [disabled]="DisableUserFilter"
        />&nbsp;&nbsp; {{ 'Ordrer.avgiftsfrie' | translate }}
        <ion-toggle
          class="inline-block m-10"
          color="success"
          [(ngModel)]="avgiftsfrie"
          (ionChange)="filterChanged($event)"
          [disabled]="DisableUserFilter"
        >
        </ion-toggle>
        &nbsp;&nbsp;

        <ng-container *ngIf="showAllProducts"
          >{{ 'Ordrer.visKunBulk' | translate }}
          <ion-toggle
            class="inline-block m-10"
            color="success"
            [(ngModel)]="visKunBulk"
            (ionChange)="filterChanged($event)"
            [disabled]="DisableUserFilter"
          ></ion-toggle>
        </ng-container>
      </ng-container>
      <div class="pull-right-md">
        <select [(ngModel)]="prioritet" (ngModelChange)="filterChanged($event)">
          <option [ngValue]="-1">{{ 'Ordrer.alle' | translate }}</option>
          <option [ngValue]="0">{{ 'Ordrer.normal' | translate }}</option>
          <option [ngValue]="1">{{ 'Ordrer.urgent' | translate }}</option>
        </select>
      </div>
      <div class="clear"></div>
    </div>
    <div class="footer-border hidden-xs"></div>
    <div class="primary litte-big-font">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="navigateToCreateOrder()"
        [disabled]="userType === UserType.Supplier"
      >
        <ion-icon name="add-circle-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'Ordrer.nyOrdre' | translate }}</span>
      </ion-button>
      <ion-button fill="outline" class="no-padding" (click)="switchView()">
        <ion-icon
          [name]="view == viewType.LIST ? 'map-outline' : 'list'"
          class="litte-big-font"
          color="primary"
        ></ion-icon>
        <span class="black hidden-xs"
          >&nbsp;{{ (view == viewType.LIST ? 'Ordrer.kartvisning' :
          'Ordrer.listevisning') | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        routerLink="/transaction-log"
      >
        <ion-icon name="document-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'TransactionLog.logg' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        routerLink="/orders-sales"
        *ngIf="userlevel <= 1"
        [disabled]="userType === UserType.Supplier"
      >
        <ion-icon
          name="checkbox-outline"
          color="primary"
          class="ion-no-padding"
        ></ion-icon
        >&nbsp;
        <span class="black">{{ 'Ordrer.approval' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        routerLink="/new-customer"
        [disabled]="userType === UserType.Supplier"
      >
        <ion-icon
          name="person-add"
          class="ion-no-padding"
          color="primary"
        ></ion-icon
        >&nbsp;
        <span class="black">{{ 'Ordrer.customers' | translate }}</span>
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding hidden-xs"
        (click)="visKunDagensChanged(true)"
      >
        <ion-icon name="refresh" color="primary"></ion-icon>
      </ion-button>
      <div
        class="pull-right hidden-xs"
        *ngIf="userlevel <= 3 && UsePlanner == false"
      >
        <ion-button
          fill="outline"
          class="no-padding"
          routerLink="/order-assignment"
          [disabled]="userType === UserType.Supplier"
        >
          <ion-icon
            name="people"
            class="ion-no-padding"
            color="primary"
          ></ion-icon
          ><span class="hidden-xs"
            >&nbsp; {{ 'Ordrer.ordretildeling' | translate }}</span
          >
        </ion-button>
      </div>
      <div
        class="pull-right hidden-xs"
        *ngIf="userlevel <= 3 && UsePlanner == true"
      >
        <ion-button
          fill="outline"
          class="no-padding"
          routerLink="/order-planner"
          [disabled]="userType === UserType.Supplier"
        >
          <ion-icon name="car" class="ion-no-padding" color="primary"></ion-icon
          ><span class="hidden-xs"
            >&nbsp; {{ 'Ordrer.ordretildeling' | translate }}</span
          >
        </ion-button>
      </div>
    </div>
  </div>
</ion-footer>
