<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span *ngIf="view == activityView.MonthView"
        >{{ 'ActivityRegistration.manedsoversikt' | translate }}</span
      >
      <span *ngIf="view == activityView.DateView"
        >{{ 'ActivityRegistration.timeregistrering' | translate }}</span
      >
      <logout
        (truckTrailerChanged)="truckTrailerChanged()"
        class="hidden-xs"
      ></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <form
      [formGroup]="activityForm"
      *ngIf="view==activityView.DateView && activityForm"
    >
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size-xs="12" class="overflow-x text-xs-center">
            <div class="page-title">
              <span class="secondary">
                <ion-button
                  (click)="previousDay()"
                  class="no-padding button-no-text vertical-top ion-no-margin"
                  fill="clear"
                >
                  <ion-icon
                    name="arrow-back-circle-outline"
                    color="primary"
                  ></ion-icon> </ion-button
                >&nbsp; {{'Day.' + currentDate.getDay() | translate}}

                <strong> {{currentDate | date:dateFormat}}</strong>
                &nbsp;
                <ion-button
                  (click)="nextDay()"
                  class="no-padding button-no-text vertical-top ion-no-margin"
                  fill="clear"
                >
                  <ion-icon
                    name="arrow-forward-circle-outline"
                    color="primary"
                  ></ion-icon>
                </ion-button>
                &nbsp;
                <ion-button
                  (click)="toggleShowEndDate()"
                  class="no-padding button-no-text vertical-top ion-no-margin"
                  fill="clear"
                >
                  <ion-icon name="repeat-outline"></ion-icon>
                </ion-button>
              </span>
            </div>
          </ion-col>
        </ion-row>
        <!--END DATE-->
        <ion-row *ngIf="showAddEndDate" class="margin-top-10 box-shadow">
          <ion-col size-xs="12" class="overflow-x text-xs-center">
            <h6 class="m-t-5">
              {{ 'ActivityRegistration.repeatTitle' | translate | uppercase }}
            </h6>
            {{ 'ActivityRegistration.repeatInfo' | translate }}
            <p>
              <ion-modal
                [cssClass]="'center'"
                [isOpen]="openModal"
                (didDismiss)="closeModal()"
              >
                <ng-template>
                  <ion-datetime
                    #picker
                    first-day-of-week="1"
                    presentation="date"
                    size="cover"
                    [showDefaultButtons]="true"
                    formControlName="endDate"
                    [(ngModel)]="endDate"
                    doneText="{{ 'buttons.ok' | translate }}"
                    cancelText="{{ 'buttons.cancel' | translate }}"
                    class="dateText"
                  >
                  </ion-datetime>
                </ng-template>
              </ion-modal>

              <ion-button
                class="m-t-5 button-no-text vertical-top ion-no-margin litte-big-font"
                color="primary"
                fill="solid"
                (click)="openModal = true"
              >
                {{endDate | date:dateFormat}} &nbsp;
                <ion-icon
                  color="grey"
                  name="calendar-outline"
                  slot="end"
                ></ion-icon>
              </ion-button>
            </p>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="holidayName != ''" class="m-5 summary-alert">
          <ion-col size-xs="12" class="overflow-x text-xs-center">
            <ion-badge color="warning" class="p-5 black"
              >{{ holidayName }}</ion-badge
            >
          </ion-col>
        </ion-row>
        <!-- CLOCK -->
        <ion-row>
          <ion-col size-xs="6">
            <!--span class="time-text"><strong>{{ 'ActivityRegistration.toFrom' | translate }}</strong></span-->
            <input
              type="time"
              class="time-input"
              formControlName="fromTime"
              (change)="onTimeChange()"
              placeholder="Fra kl."
            />
          </ion-col>
          <ion-col size-xs="6">
            <input
              type="time"
              class="time-input"
              formControlName="toTime"
              (change)="onTimeChange()"
              placeholder="Til kl."
            />
          </ion-col>
          <ion-col size-xs="12">
            <div class="time-text">
              <ion-text color="danger">
                <span
                  *ngIf="(activityForm.controls.fromTime.errors?.required && (activityForm.controls.fromTime.dirty || submitAttempt)) || (activityForm.controls.toTime.errors?.required && (activityForm.controls.toTime.dirty || submitAttempt))"
                  >{{'ActivityRegistration.quantityRequired' | translate }}
                </span></ion-text
              >
              <div>
                <ion-text color="danger">
                  <span *ngIf="timeSpanTwoDays"
                    >{{'ActivityRegistration.invalidTime' | translate }}
                  </span></ion-text
                >
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="overflow-x">
            <div class="registration-grid">
              <div>
                <!-- REGISTRATION LIST-->
                <ion-row
                  *ngFor="let item of activityList; let i=index;"
                  class="m-t-5"
                  formGroupName="wageTypes"
                >
                  <ion-col size-xs="5" size-sm="6" class="activity-name">
                    <div class="text-overflow w-100">
                      {{item.activity.Description}}
                    </div>
                  </ion-col>
                  <ion-col size-xs="7" size-sm="6" class="no-padding">
                    <input
                      type="number"
                      min="0"
                      max="24"
                      step="0.25"
                      placeholder="{{'ActivityRegistration.antall2' | translate }}"
                      class="border-input qty-input inputHeight ion-no-margin ion-text-center"
                      appRemoveZeroOnFocus
                      formControlName="{{'q'+item.lineNo}}"
                      (blur)="setQty(activityForm.controls.wageTypes.get('q'+item.lineNo).value,item,false)"
                    />
                    <ion-button
                      class="no-padding button-quntity button-outline hide-xxs"
                      (click)="setQty(8,item,false)"
                      *ngIf="item.activity.PredefinedQty === 0"
                      >8
                    </ion-button>
                    <ion-button
                      class="no-padding button-quntity button-outline"
                      (click)="setQty(0.5,item,true)"
                      *ngIf="item.activity.PredefinedQty === 0"
                    >
                      +
                    </ion-button>
                    <ion-button
                      class="no-padding button-quntity button-outline"
                      (click)="setQty(-0.5,item,true)"
                      *ngIf="item.activity.PredefinedQty === 0"
                    >
                      <span
                        *ngIf="activityForm.controls.wageTypes.get('q'+item.lineNo).value > 0"
                        >-</span
                      >
                      <span
                        *ngIf="activityForm.controls.wageTypes.get('q'+item.lineNo).value === 0"
                        >x</span
                      >
                    </ion-button>
                    <div>
                      <ion-text color="danger">
                        <span
                          *ngIf="activityForm.controls.wageTypes.get('q'+item.lineNo).errors?.required && (activityForm.controls.wageTypes.get('q'+item.lineNo).dirty || submitAttempt)"
                          >{{ 'ActivityRegistration.quantityRequired' |
                          translate }}</span
                        ></ion-text
                      >
                    </div>
                  </ion-col>
                </ion-row>
                <!--ADD EXTRA-->
                <ion-row *ngIf="!showAddExtra">
                  <ion-col
                    size-xs="12"
                    class="add-extra-heading text-xs-center"
                    (click)="toggleShowAddExtra()"
                  >
                    <ion-icon name="add-circle-outline"></ion-icon>&nbsp; {{
                    'ActivityRegistration.secondTimer' | translate }}
                  </ion-col>
                </ion-row>

                <ion-row class="add-extra" *ngIf="showAddExtra">
                  <ion-col size-xs="12" size-sm="5">
                    <ionic-selectable
                      class="activity-type inputHeight inputWidth inpSelect margin-top-10 p-5"
                      formControlName="activity"
                      [items]="activityTypes"
                      placeholder="{{ 'ActivityRegistration.selectWageTypes' | translate }}"
                      itemValueField="ActivityNo"
                      itemTextField="Description"
                      [canSearch]="true"
                      [canClear]="true"
                      searchPlaceholder="{{'ActivityRegistration.typeToSearch' | translate }}"
                      (onChange)="wagetypeChange($event)"
                      clearButtonText="{{'ActivityRegistration.clear' | translate }}"
                    >
                      <ng-template ionicSelectableCloseButtonTemplate>
                        <ion-icon
                          name="close-circle-outline"
                          style="font-size: 24px"
                        ></ion-icon>
                        &nbsp;{{ 'ActivityRegistration.cancel' | translate }}
                      </ng-template>
                    </ionic-selectable>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="7"
                    ><input
                      type="number"
                      min="0"
                      step="0.25"
                      max="24"
                      placeholder="{{'ActivityRegistration.antall2' | translate }}"
                      class="border-input qty-input inputHeight ion-no-margin ion-text-center add-input"
                      appRemoveZeroOnFocus
                      formControlName="qty"
                    />
                    <ion-button
                      class="no-padding ion-margin-start add-button"
                      [disabled]="activityForm.controls.qty.errors?.required && (activityForm.controls.qty.dirty || submitAttempt) || activityForm.controls.activity.value==''"
                      (click)="addToList()"
                    >
                      {{ 'ActivityRegistration.addToList' | translate }}
                    </ion-button>
                    <div>
                      <ion-text color="danger">
                        <span
                          class="w-100"
                          *ngIf="activityForm.controls.qty.errors?.required && (activityForm.controls.qty.dirty || submitAttempt)"
                          >{{ 'ActivityRegistration.quantityRequired' |
                          translate }}</span
                        >
                      </ion-text>
                    </div>
                  </ion-col>
                </ion-row>

                <!--SUMMARY-->
                <ion-row class="margin-top-10 summary-row box-shadow">
                  <ion-col size-xs="4">
                    <ion-icon name="time-outline"></ion-icon> &nbsp;
                    <span class="summary-value"
                      >{{totalTime | number:'1.1-2':'en'}}</span
                    >
                    &nbsp;
                  </ion-col>
                  <ion-col size-xs="4">
                    <ion-icon name="person-outline"></ion-icon>&nbsp;
                    <span class="summary-value"
                      >{{ getTotalActivityListQuantity() |
                      number:'1.1-2':'en'}}</span
                    >
                  </ion-col>
                  <ion-col
                    size-xs="4"
                    [class.summary-alert]="summaryAlert()"
                    [class.summary-ok]="!summaryAlert()"
                  >
                    <ng-container *ngIf="activityList.length > 0">
                      <ion-icon
                        name="checkmark-circle-outline"
                        *ngIf="!summaryAlert()"
                      ></ion-icon>
                      <ion-icon
                        name="alert-circle-outline"
                        *ngIf="summaryAlert()"
                      ></ion-icon>
                      &nbsp;
                      <span class="summary-value"
                        >{{ getDifference() | number:'1.1-2':'en'}}</span
                      >
                    </ng-container>
                  </ion-col>
                </ion-row>

                <!--DESCRIPTION-->
                <ion-row
                  *ngFor="let item of activityList"
                  style="display: none"
                >
                  <ion-col size-xs="12" size-sm="6">
                    {{item.activity.Description}}: {{item.qty}}
                  </ion-col>
                </ion-row>

                <!--ACCOUNTING INFO-->
                <ion-row class="margin-top-10 pb-10 box-shadow">
                  <ion-col
                    size-xs="12"
                    size-sm="6"
                    *ngIf="activityOrders && activityOrders.length > 0"
                  >
                    <ionic-selectable
                      class="activity-type inputHeight inputWidth inpSelect margin-top-10 p-5"
                      formControlName="orderNo"
                      [items]="activityOrders"
                      placeholder="{{ 'ActivityRegistration.ordre2' | translate }}"
                      itemValueField="UnitID"
                      itemTextField="UnitName"
                      [canSearch]="true"
                      [canClear]="true"
                      searchPlaceholder="{{'ActivityRegistration.typeToSearch' | translate }}"
                      clearButtonText="{{'ActivityRegistration.clear' | translate }}"
                    >
                      <ng-template ionicSelectableCloseButtonTemplate>
                        <ion-icon
                          name="close-circle-outline"
                          style="font-size: 24px"
                        ></ion-icon>
                        &nbsp;{{ 'ActivityRegistration.cancel' | translate }}
                      </ng-template>
                    </ionic-selectable>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="6">
                    <ionic-selectable
                      class="activity-type inputHeight inputWidth inpSelect margin-top-10 p-5"
                      formControlName="department"
                      [items]="departments"
                      *ngIf="departments && departments.length > 0"
                      placeholder="{{ 'ActivityRegistration.avdeling2' | translate }}"
                      itemValueField="UnitID"
                      itemTextField="UnitName"
                      [canSearch]="true"
                      [canClear]="true"
                      searchPlaceholder="{{'ActivityRegistration.typeToSearch' | translate }}"
                      clearButtonText="{{'ActivityRegistration.clear' | translate }}"
                    >
                      <ng-template ionicSelectableCloseButtonTemplate>
                        <ion-icon
                          name="close-circle-outline"
                          style="font-size: 24px"
                        ></ion-icon>
                        &nbsp;{{ 'ActivityRegistration.cancel' | translate }}
                      </ng-template>
                    </ionic-selectable>
                  </ion-col>
                  <ion-col
                    size-xs="12"
                    size-sm="6"
                    *ngIf="projects && projects.length > 0"
                  >
                    <ionic-selectable
                      class="activity-type inputHeight inputWidth inpSelect margin-top-10 p-5"
                      *ngIf="projects && projects.length > 0"
                      formControlName="project"
                      [items]="projects"
                      placeholder="{{ 'ActivityRegistration.prosjekt2' | translate }}"
                      itemValueField="UnitID"
                      itemTextField="UnitName"
                      [canSearch]="true"
                      [canClear]="true"
                      searchPlaceholder="{{'ActivityRegistration.typeToSearch' | translate }}"
                      clearButtonText="{{'ActivityRegistration.clear' | translate }}"
                    >
                      <ng-template ionicSelectableCloseButtonTemplate>
                        <ion-icon
                          name="close-circle-outline"
                          style="font-size: 24px"
                        ></ion-icon>
                        &nbsp;{{ 'ActivityRegistration.cancel' | translate }}
                      </ng-template>
                    </ionic-selectable>
                  </ion-col>
                </ion-row>
              </div>
              <ion-row class="margin-top-10 box-shadow">
                <ion-col size-xs="12" style="display: none">
                  <strong>{{ 'ActivityRegistration.notes' | translate }}</strong
                  >&nbsp;</ion-col
                >
                <ion-col size-xs="12">
                  <textarea
                    rows="3"
                    class="width"
                    formControlName="comment"
                    placeholder="{{'ActivityRegistration.beskrivelse' | translate}}"
                  ></textarea>
                  <br />
                </ion-col>
              </ion-row>
              <ion-row>
                <div class="page-title w-100">
                  <br />
                  <ion-icon
                    name="list-circle-outline"
                    color="primary"
                    class="litte-big-font vertical-middle"
                  ></ion-icon>
                  <strong
                    >{{'ActivityRegistration.dagensTimer' | translate}}</strong
                  >
                </div>
                <ion-grid class="grid">
                  <ion-row class="grid-header">
                    <ion-col size-md="6">
                      <strong
                        >{{ 'ActivityRegistration.aktivitet' | translate
                        }}</strong
                      >
                    </ion-col>
                    <ion-col size-md="4" class="hidden-xs"></ion-col>
                    <ion-col size-md="2">
                      <strong
                        >{{ 'ActivityRegistration.antall' | translate }}</strong
                      >
                    </ion-col>
                  </ion-row>
                  <ion-row
                    *ngFor="let activity of activities; let isOdd = odd"
                    [class.odd]="isOdd"
                    class="row-height"
                  >
                    <ion-col size-xs="12">
                      <ion-row>
                        <ion-col size-md="6" size-xs="6">
                          <strong
                            >{{activity.ActivityType}} -
                            {{activity.Description}}</strong
                          >
                          <span *ngIf="activity.Comment!=''">
                            <br />
                            {{activity.Comment}}
                          </span>
                        </ion-col>
                        <ion-col size-md="4" class="hidden-xs">
                          <strong
                            >{{ 'ActivityRegistration.avdeling' | translate
                            }}</strong
                          >
                          {{activity.Department}}&nbsp;&nbsp;
                        </ion-col>
                        <ion-col size-md="2" size-xs="6">
                          {{activity.Quantity}}
                          <ion-button
                            color="primary"
                            class="pull-right delete"
                            fill="solid"
                            [disabled]="activity.IsLocked"
                            (click)="deleteActivity(activity)"
                          >
                            <ion-icon name="trash-outline"></ion-icon>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                      <!--ion-row>
                                            <ion-col size-md-12>
                                                
                                                <span *ngIf="activity.OrderNo !=0">
                                                    <strong>{{ 'ActivityRegistration.ordre' | translate }}</strong> {{activity.OrderNo}}&nbsp;&nbsp;
                                                </span>

                                                <span *ngIf="activity.Department !=0">
                                                    <strong>{{ 'ActivityRegistration.avdeling' | translate }}</strong> {{activity.Department}}&nbsp;&nbsp;
                                                </span>

                                                <span *ngIf="activity.Project !=0">
                                                    <strong>{{ 'ActivityRegistration.prosjekt' | translate }}</strong> {{activity.Project}}
                                                </span>

                                            </ion-col>
                                        </!--ion-row-->
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size-md="12" class="text-xs-right">
                      <strong
                        >{{ 'ActivityRegistration.sumTotalt' | translate
                        }}</strong
                      >&nbsp;
                      <strong>{{ getTotalQuantity()}}</strong>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
    <ion-grid *ngIf="view == activityView.MonthView">
      <ion-row>
        <ion-col size-xs="12" class="overflow-x">
          <div class="page-title">
            <ion-icon
              name="calendar-outline"
              ion-text
              color="primary"
              class="vertical-middle"
            ></ion-icon>
            {{'Month.' + currentDate.getMonth() | translate}}
            <span class="secondary">
              <ion-button
                (click)="previousMonth()"
                class="no-padding button-no-text vertical-top ion-no-margin"
                fill="clear"
              >
                <ion-icon
                  color="lightFour"
                  slot="icon-only"
                  name="caret-back-circle"
                ></ion-icon>
              </ion-button>
              <ion-button
                (click)="nextMonth()"
                class="no-padding button-no-text vertical-top ion-no-margin"
                fill="clear"
              >
                <ion-icon
                  color="lightFour"
                  slot="icon-only"
                  name="caret-forward-circle"
                ></ion-icon>
              </ion-button>
            </span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="overflow-x">
          <div class="registration-grid">
            <ion-row>
              <div class="page-title completer-row">
                <ion-icon
                  name="list-circle-outline"
                  color="primary"
                  class="vertical-middle"
                ></ion-icon>
                <strong
                  >{{'ActivityRegistration.dagensTimer' | translate}}</strong
                >
              </div>
              <ion-grid class="grid">
                <ion-row class="grid-header">
                  <ion-col size-md="2">
                    <strong
                      >{{ 'ActivityRegistration.dato' | translate }}</strong
                    >
                  </ion-col>
                  <ion-col size-md="4">
                    <strong
                      >{{ 'ActivityRegistration.aktivitet' | translate
                      }}</strong
                    >
                  </ion-col>
                  <ion-col size-md="4" class="hidden-xs"></ion-col>
                  <ion-col size-md="2">
                    <strong
                      >{{ 'ActivityRegistration.antall' | translate }}</strong
                    >
                  </ion-col>
                </ion-row>
                <ion-row
                  *ngFor="let activity of activities; let isOdd = odd"
                  [class.odd]="isOdd"
                  class="row-height"
                >
                  <ion-col size-xs="12">
                    <ion-row>
                      <ion-col size-md="2">
                        {{activity.Date | date:dateFormat}}
                      </ion-col>
                      <ion-col size-md="4" size-xs="8">
                        {{activity.ActivityType}} - {{activity.Description}}
                      </ion-col>
                      <ion-col size-md="4" class="hidden-xs">
                        {{activity.Comment}}
                      </ion-col>
                      <ion-col size-md="2">
                        {{activity.Quantity}}
                        <ion-button
                          color="primary"
                          class="pull-right delete"
                          [disabled]="activity.IsLocked"
                          (click)="deleteActivity(activity)"
                          fill="solid"
                        >
                          <ion-icon name="trash-outline"></ion-icon>
                        </ion-button>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size-md="2" class="hidden-xs"></ion-col>
                      <ion-col size-md="10" size-xs="12">
                        <span class="hidden-sm-up" *ngIf="activity.Comment!=''"
                          >{{activity.Comment}}
                          <br />
                        </span>
                        <span *ngIf="activity.OrderNo !=0">
                          <strong
                            >{{ 'ActivityRegistration.ordre' | translate
                            }}</strong
                          >
                          {{activity.OrderNo}}&nbsp;&nbsp;
                        </span>

                        <span *ngIf="activity.Department !=0">
                          <strong
                            >{{ 'ActivityRegistration.avdeling' | translate
                            }}</strong
                          >
                          {{activity.Department}}&nbsp;&nbsp;
                        </span>

                        <span *ngIf="activity.Project !=0">
                          <strong
                            >{{ 'ActivityRegistration.prosjekt' | translate
                            }}</strong
                          >
                          {{activity.Project}}
                        </span>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-md="12" class="text-xs-right">
                    <strong
                      >{{ 'ActivityRegistration.sumTotalt' | translate
                      }}</strong
                    >&nbsp;
                    <strong>{{ getTotalQuantity()}}</strong>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-sm-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="showConfirm()"
        [disabled]="submitting || !activityForm.valid || summaryAlert()"
        *ngIf="activityForm"
        fill="solid"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'ActivityRegistration.lagre' | translate }}</span>
      </ion-button>
    </div>
    <div [hidden]="!expand">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="switchView(activityView.MonthView)"
        *ngIf="view == activityView.DateView"
      >
        <ion-icon name="calendar-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'ActivityRegistration.mandedsoversikt' | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="switchView(activityView.DateView)"
        *ngIf="view == activityView.MonthView"
      >
        <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'NewOrder.tilbake' | translate }}</span>
      </ion-button>
      <ion-button
        class="no-padding pull-right hidden-xs"
        (click)="showConfirm()"
        [disabled]="submitting || !activityForm.valid  || summaryAlert()"
        *ngIf="activityForm"
        fill="solid"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'ActivityRegistration.lagre' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
