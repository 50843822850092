import { Component } from '@angular/core';
import { NavController, ToastController, AlertController } from '@ionic/angular';
import { TransactionType } from '../../shared/enums';
import { Utils } from '../../shared/utils';
import { Router, NavigationExtras } from '@angular/router';
import { UserService } from '../../shared/user-service';
import { Geolocation } from '@capacitor/geolocation';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { forkJoin } from 'rxjs';
@Component({
    selector: 'page-egne-tanker-select',
    templateUrl: 'egne-tanker-select.html',
    styleUrls: ['egne-tanker-select.scss']
})
export class EgneTankerSelectPage {
    currentDate: any = new Date();
    transactionType: TransactionType = TransactionType.UnloadingAutoGas;
    TransactionType: any = TransactionType;
    nearMegDistance = 0.5;
    currentLatitude: number = null;
    currentLongitude: number = null;
    tank = null;
    dateFormat;
        dateSelector = this.currentDate.toISOString();
        openModal = false;


    constructor(
        private router: Router, public navCtrl: NavController, private userService: UserService, private geolocation: Geolocation,
        private translateService: TranslateService, private toastController: ToastController, private alertController: AlertController) {
    }

    ionViewDidEnter() {
        //  this.loadData();
    }
        closeModal(){
    this.openModal = false;
}

      async loadData() {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        const data = await this.userService.getTanks().toPromise(); // Converting Observable to Promise for async/await usage
        const posOptions = {
            enableHighAccuracy: false,
            timeout: 30000,
            maximumAge: 30000
        };

        try {
            const resp = await Geolocation.getCurrentPosition(posOptions);
            this.currentLatitude = resp.coords.latitude;
            this.currentLongitude = resp.coords.longitude;
            this.tank = _.filter(data, (item) => {
                return Utils.calculateDistance(this.currentLatitude, this.currentLongitude, item.Latitude, item.Longitude) <= this.nearMegDistance;
            });

            if (this.tank && this.tank.length > 0) {
                const translations = await forkJoin([
                    this.translateService.get('EgneTankerSelect.confirmation'),
                    this.translateService.get('buttons.yes'),
                    this.translateService.get('buttons.no')
                ]).toPromise(); // Convert Observable to Promise for consistency

                const alert = await this.alertController.create({
                    message: translations[0].format(this.tank[0].Name), // Assuming format function exists or replace with appropriate string formatting
                    buttons: [{
                        text: translations[1],
                        handler: () => {
                            this.transactionType = TransactionType.UnloadingAutoGas;
                            this.next();
                        }
                    }, {
                        text: translations[2],
                        role: 'cancel',
                        handler: () => {
                            this.tank = null;
                        }
                    }]
                });
                await alert.present();
            }
        } catch (error) {
            // Error handling for geolocation error
            this.translateService.get('Error.locationError').subscribe(async value => {
                const toast = await this.toastController.create({
                    message: value,
                    duration: 3000
                });
                toast.present();
            });
        }
    }

    
    setTransactionType(transactionType: TransactionType) {
        this.transactionType = transactionType;
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
    }

    next() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                transactionType: JSON.stringify(this.transactionType),
                currentDate: this.currentDate,
                tank: this.tank && this.tank.length > 0 ? JSON.stringify(this.tank[0]) : null
            },
            skipLocationChange: true
        };

        this.router.navigate(['/egne-tanker-automater'], navigationExtras);
    }

      dateChangeEvent(event) {
      
            this.currentDate = new Date(event.detail.value);
            this.loadData();
        
    }

}
