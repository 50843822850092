<ion-content class="ion-padding full-height mdvh65">
  <div>
    <div class="max-100">
      <div class="grid-width">
        <ion-grid class="full-height">
          <ion-row class="full-height">
            <ion-col>
              <ion-row class="flexCenter">
                <!--Host-->
                <ion-col>
                  <ion-searchbar
                    (ionChange)="filterChanged($event, false)"
                    *ngIf="!dataHost"
                    placeholder="{{ 'TankMonitoring.searchPlaceHolder' | translate }}"
                  ></ion-searchbar>

                  <ion-searchbar
                    [(ngModel)]="search"
                    (ngModelChange)="filterRecords()"
                    *ngIf="dataHost"
                    placeholder="{{ 'TankMonitoring.searchPlaceHolder' | translate }}"
                  ></ion-searchbar>
                </ion-col>

                <ion-col>
                  <strong class="block"
                    >{{ 'TankMonitoring.avdeling' | translate }}</strong
                  >
                  <select
                    size="1"
                    [(ngModel)]="department"
                    (ngModelChange)="onDepartmentChange($event)"
                  >
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let item of departments" value="{{item}}">
                      {{item}}
                    </option>
                  </select>
                </ion-col>

                <ion-col *ngIf="!dataHost">
                  <strong class="block"
                    >{{ 'TankMonitoring.drivstofftype' | translate }}</strong
                  >
                  <select
                    size="1"
                    [(ngModel)]="fueltype"
                    (ngModelChange)="filterChanged($event, true)"
                  >
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let item of fueltypes" value="{{item}}">
                      {{item}}
                    </option>
                  </select>
                </ion-col>

                <ion-col *ngIf="dataHost">
                  <!--
                  <span class="display-inline-block text-small">
                    <strong>{{ 'TankMonitoring.company' | translate }}</strong
                    >&nbsp;
                    <select
                      size="1"
                      [(ngModel)]="company"
                      class="datahost-select"
                      (ngModelChange)="filterRecords()"
                    >
                   
                      <option value="0">
                        {{ 'TankMonitoring.alle' | translate }}
                      </option>
                      <option
                        *ngFor="let company of companies"
                        value="{{company.CompanyNo}}"
                      >
                        {{company.Name}}
                      </option>
                    </select>
                    
                  </span>
                  -->
                </ion-col>
              </ion-row>
              <ion-row class="flexCenter">
                <ion-col class="ion-padding-bottom" *ngIf="dataHost">
                  {{ 'TankMonitoring.tenkerMedLaviNiva' | translate }}
                  <ion-toggle
                    class="inline-block m-10"
                    color="success"
                    [(ngModel)]="lowVolume"
                    (ionChange)="filterRecords()"
                  ></ion-toggle>
                </ion-col>

                <ion-col class="ion-padding-bottom" *ngIf="!dataHost">
                  {{ 'TankMonitoring.tenkerMedLaviNiva' | translate }}
                  <ion-toggle
                    class="inline-block m-10"
                    color="success"
                    [(ngModel)]="lowVolume"
                    (ionChange)="filterData($event, true)"
                  ></ion-toggle
                  >&nbsp; {{ 'TankMonitoring.fewDays' | translate }}
                  <ion-toggle
                    class="inline-block m-10"
                    color="success"
                    [(ngModel)]="lowDays"
                    (ionChange)="filterData($event, true)"
                  ></ion-toggle
                ></ion-col>
              </ion-row>
              <hr />

              <!--PEILERE-->
              <ng-container *ngIf="!dataHost">
                <ion-row class="grey-text ion-no-padding">
                  <ion-col
                    class="tank-inventory"
                    size-xs="12"
                    size-xl="6"
                    *ngFor="let item of filteredNorTraceItems"
                    [ngClass]="{'red': item.data.data.pri.percent <= 10, 'yellow': item.data.data.pri.percent > 10 ,'green': item.data.data.pri.percent > 50}"
                  >
                    <ion-row
                      [ngClass]="{'border-class': item.expandedView==true}"
                    >
                      <ion-col class="ion-no-padding">
                        <!--Peiler-->
                        <ion-row
                          class="w-100 border-class h-100 padding-8 box-shadow bg-white"
                        >
                          <ion-col size="1">
                            <div class="volume">
                              <div
                                class="volume-info"
                                style.height="{{item.data.data.pri.percent | number : '1.0-0'}}%"
                              ></div>
                              <span class="dark-text percent-text"
                                >{{item.data.data.pri.percent | number :
                                '1.0-0'}} %</span
                              >
                            </div>
                          </ion-col>
                          <ion-col size="11">
                            <ion-row class="flexCenter">
                              <ion-col size="8">
                                <strong class="dark-text bigger-font"
                                  >{{item.name}}</strong
                                >
                              </ion-col>
                              <ion-col size="4" class="ion-text-right">
                                <ion-icon
                                  [color]="sooloSyncstatus(item) >= 3 ? 'danger' : 'success'"
                                  [name]="sooloSyncstatus(item) >= 3 ?'cloud-outline':'cloud-done'"
                                  class="biggest-font-2 vertical-middle"
                                ></ion-icon
                                >&nbsp;
                                <ion-text
                                  [color]="sooloSyncstatus(item) >= 3? 'danger' : 'grey-text'"
                                >
                                  <span
                                    >{{item.data.data.meta.aquisitiontimeRFC3339
                                    | date:dateFormatTime}}</span
                                  ></ion-text
                                >
                              </ion-col>
                            </ion-row>
                            <ion-row class="flexCenter">
                              <!--Fueltype-->
                              <ion-col size="12">
                                <!--Fueltype-->
                                <ion-row>
                                  <ion-col>
                                    <ion-badge
                                      [style.background]="item.colorCode"
                                      >{{item.shortCode}}</ion-badge
                                    >&nbsp; {{item.data.appdata.fueltype}}
                                  </ion-col>
                                  <ion-col size="2" class="ion-text-right">
                                    <ion-icon
                                      name="business-outline"
                                    ></ion-icon>
                                    {{ item.custommerdata}}
                                  </ion-col>
                                </ion-row>
                                <!--Details-->
                                <ion-row class="flexCenter">
                                  <ion-col size="3">
                                    <div class="badge">
                                      <strong
                                        ><ion-icon
                                          name="battery-half-outline"
                                        ></ion-icon></strong
                                      >&nbsp;{{ sooloVolume(item)| number :
                                      '1.0-0'}} &nbsp; | &nbsp;
                                      {{item.data.data.pri.volume | number :
                                      '1.0-0'}}
                                    </div>
                                  </ion-col>

                                  <ion-col>
                                    <div class="badge">
                                      <strong>
                                        <ion-icon
                                          name="notifications-outline"
                                          title="{{ 'TankMonitoring.alaramLevel' | translate
                                    }}"
                                        ></ion-icon></strong
                                      >&nbsp;{{item.data?.appdata?.alarm?.alarm_level}}%
                                    </div>
                                  </ion-col>

                                  <ion-col>
                                    <div
                                      class="badge"
                                      [class.bg-success]="sooloAutoOrder(item)"
                                    >
                                      <ion-icon
                                        name="hourglass-outline"
                                      ></ion-icon>
                                      &nbsp;{{ sooloDaysLeft(item)}}&nbsp;dg
                                      <ion-icon
                                        name="rocket-outline"
                                        color="success"
                                        [hidden]="!sooloAutoOrder(item)"
                                      ></ion-icon>
                                    </div>
                                  </ion-col>

                                  <!--Input-->
                                  <ion-col size="3">
                                    <ion-input
                                      type="number"
                                      placeholder="liter"
                                      [(ngModel)]="item.installedtime"
                                    ></ion-input>
                                  </ion-col>

                                  <!--Buttton-->
                                  <ion-col size="1">
                                    <ion-button
                                      fill="outline"
                                      class="no-padding"
                                      (click)="createSooloOrder(item)"
                                    >
                                      <ion-icon
                                        name="add-circle"
                                        class="ion-no-padding"
                                        color="primary"
                                      ></ion-icon>
                                    </ion-button>
                                  </ion-col>
                                </ion-row>
                              </ion-col>
                            </ion-row>
                          </ion-col>
                        </ion-row>
                        <!--Peiler details-->
                        <ion-col size-xs="12" *ngIf="item.expandedView">
                          <div class="overflow-x">
                            <div class="dataHost-grid">
                              <ion-row>
                                <ion-col size-xs="11">
                                  <ion-row>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.produkt' | translate }}
                                      <br />
                                      <span>
                                        <b
                                          class="bigger-font ion-text-uppercase"
                                          >{{item.appdata.fueltype}}</b
                                        >&nbsp;
                                        <ion-badge
                                          [style.background]="item.colorCode"
                                          >{{item.shortCode}}</ion-badge
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.niva' | translate }}
                                      <br />
                                      <span class="fuel-level">
                                        <b class="bigger-font"
                                          >{{item.data.data.pri.volume | number
                                          : '1.0-0'}}L
                                          ({{item.data.data.pri.percent | number
                                          : '1.0-0'}})%</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.muligFylling' |
                                      translate }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{(item.data.data.pri.volume /
                                          item.data.data.pri.percent *
                                          100)-item.data.data.pri.volume |
                                          number : '1.0-0'}}L</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.tanktype' | translate
                                      }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{item.appdata.tanktype}}</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.alaramLevel' |
                                      translate }}
                                      <br />
                                      <b
                                        class="bigger-font"
                                        *ngIf="item.appdata?.alarm"
                                        >{{item.appdata.alarm?.alarm_level}}</b
                                      >
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.alaramPhoneNumber' |
                                      translate }}
                                      <br />
                                      <ng-container
                                        *ngIf="item.appdata.alarm && item.appdata.alarm.alarm_phone_number && item.appdata.alarm.alarm_phone_number.length > 0"
                                      >
                                        <span
                                          *ngFor="let phoneNumber of item.appdata.alarm.alarm_phone_number"
                                        >
                                          <b class="bigger-font"
                                            >{{phoneNumber}}</b
                                          >
                                        </span>
                                      </ng-container>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.intervalMinutes' |
                                      translate }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{item.appdata.schedule.interval_minutes}}</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.address' | translate }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{item.appdata.address}}</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.temp' | translate }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{item.data.data.sec.temp}}</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.press' | translate }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{item.data.data.sec.press}}</b
                                        >
                                      </span>
                                    </ion-col>
                                    <ion-col size-xs="3">
                                      {{ 'TankMonitoring.battery' | translate }}
                                      <br />
                                      <span>
                                        <b class="bigger-font"
                                          >{{item.data.data.sec.battery}}</b
                                        >
                                      </span>
                                    </ion-col>
                                  </ion-row>
                                </ion-col>
                              </ion-row>
                            </div>
                          </div>
                        </ion-col>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ng-container>

              <!--DATAHOST-->
              <ng-container *ngIf="dataHost">
                <ion-row>
                  <ion-col
                    *ngFor="let item of filteredTankInventory"
                    class="tank-inventory"
                    size="12"
                  >
                    <ion-row>
                      <ion-col
                        size-lg="12"
                        *ngIf="item.FilteredInventory.length>0"
                      >
                        <div class="tank-name display-inline-block">
                          <img
                            src="assets/icon/Icon_TankMonitor.svg"
                            class="vertical-middle"
                          />
                          <span><strong>{{item.Tank.Name}}</strong></span>
                        </div>
                        <div
                          class="fav ion-text-right display-inline-block cursor-pointer"
                        >
                          <span (click)="markTanks(item)">
                            <ion-icon
                              name="star-outline"
                              *ngIf="!item.fav"
                            ></ion-icon>
                            <ion-icon
                              name="star"
                              *ngIf="item.fav"
                              color="primary"
                            ></ion-icon>
                          </span>
                        </div>
                      </ion-col>

                      <ion-row
                        class="w-100"
                        *ngIf="item.FilteredInventory.length>0"
                      >
                        <ion-col
                          size-xs="12"
                          size-lg="6"
                          *ngFor="let inventory of item.FilteredInventory"
                        >
                          <ion-row
                            class="w-100 border-class h-100 padding-8 box-shadow bg-white"
                            [ngClass]="{'red': calculatePercent(inventory) <= 10, 'yellow': calculatePercent(inventory) > 10 ,'green': calculatePercent(inventory) > 50}"
                          >
                            <ion-col size="1">
                              <div class="volume">
                                <div
                                  class="volume-info"
                                  style.height="{{calculatePercent(inventory) | number : '1.0-0'}}%"
                                ></div>
                                <span class="dark-text percent-text"
                                  >{{calculatePercent(inventory) | number :
                                  '1.0-0'}} %</span
                                >
                              </div>
                            </ion-col>
                            <ion-col size="10">
                              <ion-row class="flexCenter">
                                <ion-col size="3">
                                  <ion-badge
                                    [style.background]="inventory.ProductColorCode"
                                    >{{inventory.ProductShortCode}}</ion-badge
                                  ><br />
                                  <div class="m-t-5">
                                    {{ 'TankMonitoring.tankNR' | translate
                                    }}:&nbsp;<strong
                                      >{{inventory.TankRoomNo}}</strong
                                    >
                                  </div>
                                </ion-col>

                                <ion-col size="4">
                                  <div class="badge">
                                    <ion-icon
                                      name="battery-half-outline"
                                    ></ion-icon
                                    >&nbsp; {{inventory.MaxQuantity | number :
                                    '1.0-0'}} &nbsp; | &nbsp;
                                    <strong
                                      >{{inventory.Quantity | number : '1.0-0'
                                      }}</strong
                                    >
                                  </div>
                                </ion-col>

                                <ion-col size="4">
                                  <ion-input
                                    type="number"
                                    placeholder="liter"
                                    [(ngModel)]="inventory.QuantityInOrder"
                                  ></ion-input>
                                </ion-col>
                                <ion-col size="1">
                                  <ion-button
                                    fill="outline"
                                    class="no-padding"
                                    (click)="createTankOrder([item, inventory])"
                                  >
                                    <ion-icon
                                      name="add-circle"
                                      class="ion-no-padding"
                                      color="primary"
                                    ></ion-icon>
                                  </ion-button>
                                </ion-col>
                              </ion-row>
                            </ion-col>
                          </ion-row>
                        </ion-col>
                      </ion-row>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</ion-content>
