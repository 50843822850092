import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomerData } from '../../shared/customer-data';
import { BaseHttpClient } from '../../shared/http-client';
import { AddressData } from '../../shared/address-data';
import { CustomValidators } from '../../shared/validators';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@ionic/storage-angular';
import { Router, NavigationExtras } from '@angular/router';
import { LeggTilProduktSalesComponent } from '../../components/legg-til-produkt-sales/legg-til-produkt-sales';
import _ from 'lodash';
import { OrderConfirmSalesComponent } from '../../components/order-confirm-sales/order-confirm-sales';
import { InternalOrdertype } from '../../shared/enums';
import { HttpClient } from '@angular/common/http';
import { Observable, debounceTime, filter, map, of, startWith, switchMap } from 'rxjs';
import { CustomerNorwegianData } from 'src/app/shared/customer-norwegian-data';


@Component({
    selector: 'page-new-order-product',
    templateUrl: 'new-order-product.html',
    styleUrls: ['new-order-product.scss']
})
export class NewOrderProductPage implements AfterViewInit {
    dateFormat;
    orderLines: any[] = [];
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    customerDataService;
    addressDataService: AddressData;
    currentLatitude: number = null;
    currentLongitude: number = null;
    DoNotCalcVolToStandard = false;
    VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = false;
    private showAllProducts = false;
    totalOrder: any = 0;
    products: any[];
    @ViewChild('picker')
    private picker: any;
    isHidden: boolean;
    private navigationExtras: NavigationExtras;
    expand = false;
    private addressID = 0;
    private selectedCustomer: any = null;
    priceAgreements;
    allowDescriptionChange;
    openModal = false;
     customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;

    addressCtrl = new FormControl();
    filteredAddresses: Observable<any[]>;
  
    @ViewChild('addressComplete') addressComplete: ElementRef;
    @ViewChild('customerComplete') customerComplete: ElementRef;

    ngAfterViewInit() {
        this.addressComplete.nativeElement.setAttribute('type', 'text');
        this.customerComplete.nativeElement.setAttribute('type', 'text');
    }

    constructor(
        private storage: Storage, private userService: UserService, public navCtrl: NavController,
        private translateService: TranslateService, private toastController: ToastController,
        private loadingController: LoadingController, private geolocation: Geolocation, private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private http: HttpClient, private router: Router, private alertCtrl: AlertController) {
        this.storage.get(AppSettings.StorageKeys.ShowAllProducts).then(data => this.showAllProducts = data);
        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });
        this.detailsForm = formBuilder.group(
            {
                customerName: ['', Validators.compose([Validators.required])],
                customerAddress1: ['', Validators.compose([Validators.required])],
                customerAddress2: [''],
                customerPostalCode: ['', Validators.compose([Validators.required])],
                priceAgreementID: [''],
                customerCity: [''],

                address: [''],
                addressAddress1: [''],
                addressAddress2: [''],
                addressZip: [''],
                addressCity: [''],

                customerBuyer: [''],
                email: ['', [CustomValidators.emailValidator]],
                phoneOnSite: [''],
                customerReference: [''],
                deliveryInfo: [''],

                additionalProductsDelivered: [false],
                orderComments: [''],
                orderInternalComments: [''],
                deliveryDate: [new Date().toISOString()],
                orderLines: new FormGroup({})
            });
        this.storage.get(AppSettings.StorageKeys.AllowDescriptionChange).then(data => {
            this.allowDescriptionChange = data;
        });

        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        // used to pass info to orders view for init an update of orderslist
        this.navigationExtras = {
            queryParams: {
                reloadData: true
            },
            skipLocationChange: true
        };

        const posOptions = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 30000
        };
        const that = this;
        const showGeolocationError = function error() {
            /*   that.translateService.get('Error.locationError').subscribe(value => {
                  that.toastController.create({
                      message: value,
                      duration: 3000
                  });//.then(toast => { toast.present(); });
   */
            that.currentLatitude = that.currentLongitude = null;
            // });
        };

       
        this.userService.getProducts().subscribe(data => {
            this.products = data;
        });
        this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
        this.customerDataService = new CustomerData(http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
        this.addressDataService = new AddressData(http, 0, `${AppSettings.API_ENDPOINT}/User/AddressSearch/?query=`);
    }



    getFormControl(field) {
        return this.detailsForm.get(field);
    }

    dynamicControl(formGroup: FormGroup, orderline) {
        orderline.changeProduct = false;
        const quantityDelivered: FormControl = new FormControl(orderline.QuantityDelivered,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const price: FormControl = new FormControl(orderline.UnitPrice,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const discount: FormControl = new FormControl(orderline.UnitDiscount,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const cost: FormControl = new FormControl(orderline.UnitCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));

        if (this.allowDescriptionChange) {
            const productNo: FormControl = new FormControl(orderline.Description, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        } else {
            const productNo: FormControl = new FormControl(orderline.ProductNo, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        }
        formGroup.addControl('quantity' + orderline.LineNo, quantityDelivered);
        formGroup.addControl('price' + orderline.LineNo, price);
        formGroup.addControl('discount' + orderline.LineNo, discount);
        formGroup.addControl('cost' + orderline.LineNo, cost);

    }
    closeModal(){
    this.openModal = false;
}


 
    ngOnInit() {
        this.getCurrentPosition();
        this.watchPosition();

        this.filteredCustomers = this.customerCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(name => name ? this._filterCustomers(name) : of([])) // Use switchMap instead of map
            );

        this.filteredAddresses = this.addressCtrl.valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            switchMap(name => name ? this._filterAddresses(name) : this.addressDataService.addressList.slice())
        );

        this.initializeAutocomplete();

    }

    displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

    private _filterAddresses(value: string): Observable<any[]> {
    return this.addressDataService.search(value);
  }

   initializeAutocomplete() {
    this.addressCtrl.valueChanges.subscribe(data => {
      const value = this.addressCtrl.value;
      if (!data.length && value.length > 3) {
        const autocomplete = new google.maps.places.Autocomplete(this.addressComplete.nativeElement);
        autocomplete.setComponentRestrictions({ country: ['no'] });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
        });
      }
    });
  }




  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    } catch (error) {
      //this.showGeolocationError();
    }
  }

    async gpsSearch() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    await this.getCurrentPosition();
    this.userService.addressGPSSearch(this.selectedCustomer.ErpID, this.currentLatitude, this.currentLongitude).subscribe(data => {
      const addresses = data.map((address: any) => ({
        name: address.Name, // Assuming 'Name' and 'Address' are properties in the data
        address: address.Address // Replace 'Address' with the actual property name if different
      }));

    });
  }

  watchPosition() {
    const watch = Geolocation.watchPosition({}, (position, error) => {
      if (error) {
        //this.showGeolocationError();
        return;
      }

      if (position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      } else {
       // this.showGeolocationError();
      }
    });
  }

getPlaceAutocomplete() {
    // Example of listening to form control changes and using the search method
    this.addressCtrl.valueChanges.pipe(
        filter(value => value.length > 3),  // Only search if value length is more than 3
        debounceTime(300),                 // Add debounce to limit requests
        switchMap(value => this.addressDataService.search(value))  // Switch to new search observable on new input
    ).subscribe(data => {
        if (data.length === 0) {
            const autocomplete = new google.maps.places.Autocomplete(
                this.addressComplete.nativeElement,
                // Optionally filter on types such as ['establishment']
            );
            autocomplete.setComponentRestrictions({country: ['no']});
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                const place = autocomplete.getPlace();
                this.invokeEvent(place);
            });
        }
    });
}

// Helper method to wrap Google Places Autocomplete setup
initializeGoogleAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
        this.addressComplete.nativeElement,
        // Optionally filter on types such as ['establishment']
    );
    autocomplete.setComponentRestrictions({country: ['no']});
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.invokeEvent(place);
    });
}

     invokeEvent(data) {
         this.addressID = 0;
         const place = data;
         if (place.formatted_address !== '' && place.formatted_address !== null || place.name !== '' && place.name !== null) {
             const components: any = {};
             place.address_components.map((value, index) => {
                 value.types.map((value2, index2) => {
                     components[value2] = value.long_name;
                     if (value2 === 'country') {
                         components.country_id = value.short_name;
                     }
                     if (value2 === 'administrative_area_level_1') {
                         components.state_code = value.short_name;
                     }
                 });
             });
             let address1 = typeof components.route !== 'undefined' ? components.route + ' '  : '' ;
             address1 += typeof components.street_number !== 'undefined' ? components.street_number + ' '  : '';
             if (place.name.trim().toLowerCase() !== address1.trim().toLowerCase()) {
                this.getFormControl('address').setValue(place.name);
            } else {
                this.getFormControl('address').setValue(this.getFormControl('customerName').value);
            }

             this.getFormControl('addressAddress1').setValue(address1);
             this.getFormControl('addressAddress2')
                .setValue(components.sublocality);
             this.getFormControl('addressZip').setValue(components.postal_code);
             this.getFormControl('addressCity').setValue(components.postal_town !== undefined ?
               components.postal_town : components.locality);
         }
     }

    

     searchCustomerNorwegianRegistry(event) {
        try {
              this.selectedCustomer = null;
        if (event.target.value.length >= 3) {
            this.customerDataService = new CustomerData(this.http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
            this.customerDataService.subscribe(customers => {
                if (customers.length === 0) {
                    this.customerDataService = new CustomerNorwegianData(this.http, AppSettings.NorwegianRegistryAPI + event.target.value);
                    this.customerDataService.search();
                }
            });
        }
        } catch (error) {
            
        }
      
    }
    changeElement($event, orderline) {
        if ($event) {
            orderline.changeProduct = false;
        } else {
            orderline.changeProduct = true;
        }
    }

    setValue(orderline, fieldName) {
        const control = this.detailsForm.controls.orderLines;
        if (fieldName === 'price') {
            orderline.UnitPrice = control.get('price' + orderline.LineNo).value;
        }
        if (fieldName === 'discount') {
            orderline.UnitDiscount = control.get('discount' + orderline.LineNo).value;
        }
        if (fieldName === 'cost') {
            orderline.UnitCost = control.get('cost' + orderline.LineNo).value;
        }
        if (fieldName === 'quantity') {
            orderline.QuantityDelivered = control.get('quantity' + orderline.LineNo).value;
        }

        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    getTotalOrder() {
        this.totalOrder = 0;
        for (const item of this.orderLines) {
            const total = +item.OrderLineTotal + (+this.totalOrder);
            this.totalOrder = Math.round(total).toFixed(2);
        }
    }

    orderlineTotal(orderline) {
        let lineTotal = 0;
        orderline.OrderLineTotal = 0;
        if (orderline.UnitPrice >= 0) {
            if (orderline.QuantityDelivered > 0) {
                lineTotal = (orderline.UnitPrice * orderline.QuantityDelivered);
            } else {
                lineTotal = (orderline.UnitPrice * orderline.Quantity);
            }
        }
        if (orderline.UnitDiscount > 0) {
            orderline.OrderLineTotal = (lineTotal - ((lineTotal * orderline.UnitDiscount) / 100)).toFixed(2);
        } else {
            orderline.OrderLineTotal = lineTotal.toFixed(2);
        }
    }

    selectProduct(event, orderline) {
        if (event.type === 'change') {
            const control = this.detailsForm.controls.orderLines;
            const product = _.find(this.products, { ProductNo: event.target.value });
            if (product) {
                orderline.ProductNo = product.ProductNo;
                orderline.Description = product.Description;
                orderline.ProductShortCode = product.ShortCode;
                orderline.ProductColorCode = product.ColorCode;
                orderline.UnitPrice = product.UnitPrice;
                orderline.UnitCost = product.UnitCost;
                control.get('price' + orderline.LineNo).setValue(orderline.UnitPrice);
                control.get('cost' + orderline.LineNo).setValue(orderline.UnitCost);
                this.orderlineTotal(orderline);
                this.getTotalOrder();
            }
            if (this.allowDescriptionChange) {
                if (control.get('productNo' + orderline.LineNo).value !== '') {
                    orderline.Description = control.get('productNo' + orderline.LineNo).value;
                }
            }
        } else {
            orderline.changeProduct = false;
        }
    }

    showConfirm(orderline) {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.confirmMessage').subscribe(value => { modalText = value.format(orderline.Description); });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.deleteOrderLine(orderline);
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }

    deleteOrderLine(orderline) {
        const index = _.findIndex(this.orderLines, { ProductNo: orderline.ProductNo });
        if (index > -1) {
            this.orderLines.splice(index, 1);
            this.getTotalOrder();
        }
    }
    clearAddress() {
        this.getFormControl('addressAddress1').setValue('');
        this.getFormControl('addressAddress2').setValue('');
        this.getFormControl('addressZip').setValue('');
        this.getFormControl('addressCity').setValue('');
        this.getFormControl('address').setValue('');
        this.addressID = 0;
    }

  
    openPicker() {
      //  this.picker.open();
    }

    addressSelected($event) {
        if ($event != null) {
            const originalObject = $event.originalObject;
            this.addressID = originalObject.AddressID;
            this.getFormControl('addressAddress1').setValue(originalObject.Address1);
            this.getFormControl('addressAddress2').setValue(originalObject.Address2);
            this.getFormControl('addressZip').setValue(originalObject.PostalCode);
            this.getFormControl('addressCity').setValue(originalObject.City);
        }
    }

    customerSelected($event) {
        if ($event != null) {
            const originalObject = $event.originalObject;
            const controls = this.detailsForm.controls;
            if (originalObject.CustomerBlockedStatus > 0) {
                this.getFormControl('customerName').setErrors({ incorrect: true });
            } else {
                this.selectedCustomer = originalObject;
                this.addressDataService.setErpIDParent(originalObject.ErpID);
                this.getFormControl('customerAddress1').setValue(originalObject.Address);
                this.getFormControl('customerAddress2').setValue('');
                this.getFormControl('customerPostalCode').setValue(originalObject.PostalCode);
                this.getFormControl('customerCity').setValue(originalObject.City);
            }
        }
    }

    updateCommentValidators() {
        const commentControl = this.detailsForm.get('orderComments');
        const additionalProductsDeliveredControl = this.detailsForm.get('additionalProductsDelivered');

        if (additionalProductsDeliveredControl.value) {
            commentControl.setValidators([Validators.required]);
        } else {
            commentControl.setValidators([]);
        }
        commentControl.updateValueAndValidity();
    }

    additionalProductsDeliveredChanged() {
        this.updateCommentValidators();
    }

    async leggTilProdukt() {
        const modal = await this.modalCtrl.create({
            component: LeggTilProduktSalesComponent,
            cssClass: 'legg-til-produkt-sales'
        });
        await modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                const orderline: any = {
                    ProductColorCode: data.data.Product.ColorCode,
                    ProductShortCode: data.data.Product.ShortCode,
                    Description: data.data.Product.Description,
                    LineNo: this.identity,
                    ProductNo: data.data.Product.ProductNo,
                    Quantity: data.data.Quantity,
                    QuantityDelivered: data.data.Quantity,
                    UnitPrice: data.data.Product.UnitPrice,
                    UnitCost: data.data.Product.UnitCost,
                    UnitDiscount: 0,
                    OrderLineTotal: 0
                };
                this.orderLines.push(orderline);
                const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
                this.dynamicControl(formGroup, orderline);
                this.identity--;
            }
        });
    }

    navigateToOrdersList() {
        this.navCtrl.pop();
    }

    showhide() {
        this.isHidden = !this.isHidden;
    }

    async  createNewOrder() {
        this.submitAttempt = true;
        if (this.detailsForm.valid) {
            const controls = this.detailsForm.controls;
            const order: any = {};

            if (!this.selectedCustomer) {

                order.CustomerID = 0;
                order.CustomerName = this.getFormControl('customerName').value;
                order.CustomerAddress1 = this.getFormControl('customerAddress1').value;
                order.CustomerAddress2 = this.getFormControl('customerAddress2').value;
                order.CustomerCity = this.getFormControl('customerCity').value;
                order.CustomerPostalCode = this.getFormControl('customerPostalCode').value;

                order.DeliveryName = this.getFormControl('address').value;
                order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                order.DeliveryCity = this.getFormControl('addressCity').value;
                order.DeliveryPostalCode = this.getFormControl('addressZip').value;

            } else {
                order.CustomerID = this.selectedCustomer.CustomerNo;


                if (this.addressID <= 0) {
                    order.DeliveryName = this.getFormControl('address').value;
                    order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                    order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                    order.DeliveryCity = this.getFormControl('addressCity').value;
                    order.DeliveryPostalCode = this.getFormControl('addressZip').value;
                } else {
                    order.DeliveryActorNo = this.addressID;
                }
            }

            order.DeliveryDate = this.getFormControl('deliveryDate').value;
            order.ConfirmedDeliveryDate = order.DeliveryDate;
            order.CustomerBuyer = this.getFormControl('customerBuyer').value;
            order.Email = this.getFormControl('email').value;
            order.PhoneOnSite = this.getFormControl('phoneOnSite').value;


            if (this.DoNotSendGPS) { // do not send gps location
                order.Longitude = -1;
                order.Latitude = -1;
            } else {
                order.Latitude = this.currentLatitude;
                order.Longitude = this.currentLongitude;
            }

            order.Orderlines = this.orderLines;
            order.OrderComments = this.getFormControl('orderComments').value;
            order.orderInternalComments = this.getFormControl('orderInternalComments').value;
            order.CustomerReference = this.getFormControl('customerReference').value;
            order.AdditionalProductsDelivered = this.getFormControl('additionalProductsDelivered').value;
            order.PriceAgreementID = this.getFormControl('priceAgreementID').value;
            order.DeliveryInfo = this.getFormControl('deliveryInfo').value;
            this.storage.get(AppSettings.StorageKeys.UserFullName).then(data => { order.Seller = data; });
            order.DoNotCalculateVolume = this.DoNotCalcVolToStandard;
            this.storage.get(AppSettings.StorageKeys.Depot).then(data => { order.DepotNo = data; });
            order.LoadingTemp = AppSettings.LoadingTemp;
            order.TotalOrder = this.totalOrder;
            order.InternalOrderType = InternalOrdertype.Bulk;
            this.storage.get(AppSettings.StorageKeys.CompanyNo).then(data => { order.CompanyNo = data; });
            const modal = await this.modalCtrl.create({
                component: OrderConfirmSalesComponent,
                cssClass: 'order-confirm-sales',
                componentProps: { order }

            });
            modal.present();
            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });

                        loader.present().then(async () => {
                            (await this.userService.createNewOrder(order)).subscribe(resp => {
                                if (resp) {
                                    this.router.navigate(['/orders'], this.navigationExtras);
                                }
                                loader.dismiss();
                            });
                        });
                    });
                }
            });
        }
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }
}


