import { Component, ViewChild } from '@angular/core';
import { NavController, LoadingController, IonModal } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import _ from 'lodash';
import { AppSettings } from '../../shared/app-settings';

@Component({
    styleUrls: ['transaction-log.scss'],
    selector: 'page-transaction-log',
    templateUrl: 'transaction-log.html',
})
export class TransactionLogPage {
    @ViewChild('picker') picker: any;
    @ViewChild(IonModal) modal: IonModal;
    currentDate: any = new Date();
    transactionLogs: any;
    filteredtransactionLogs: any = [];
    sortProperty = 'TransDate';
    sortDirection = 'desc';
    dateFormat;
    dateSelector = this.currentDate.toISOString();
    drivers: any = [];
    driver = AppSettings.Username;
    search = '';
    userlevel;
    openModal = false;
    constructor(
        public navCtrl: NavController, private userService: UserService, private translateService: TranslateService,
        private loadingController: LoadingController) {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });
        this.userlevel = AppSettings.UserLevel;
        this.loadData();
    }
 closeModal(){
    this.openModal = false;
}
    loadData(blankDate = false) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.getDrivers().subscribe(data => { this.drivers = data; });

                let date = this.currentDate;

                if (blankDate) { date = new Date(1900, 0, 1, 0, 0, 0, 0); }

                this.userService.transactionLog(date, this.driver, this.search).subscribe(data => {
                    this.transactionLogs = data;
                    // // mock data
                    // this.transactionLogs = [{
                    //     Comment: 'comment',
                    //     CustomerAddress: 'Amd',
                    //     CustomerName: 'temp',
                    //     CustomerNo: 123,
                    //     EmployeeNo: 999,
                    //     OrderNo: 125,
                    //     Quantity: 10,
                    //     SoldProduct: 'DF',
                    //     StockProductNo: 'p101',
                    //     TankStation: 'TankStation',
                    //     TransDate: '2020-02-01T00:00:00',
                    //     TransGroup: 10,
                    //     TransType: 12,
                    //     Truck: 'TruckName',
                    //     VolumeQuantity: 5,
                    //     VolumeTemp: 20,
                    //     ProductColorCode: '#ff0000',
                    //     ProductShortCode: 'DB',
                    //     Longitude: 0,
                    //     Latitude: 0
                    // }, {
                    //     Comment: 'comment',
                    //     CustomerAddress: 'Amd',
                    //     CustomerName: 'ABC',
                    //     CustomerNo: 123,
                    //     EmployeeNo: 999,
                    //     OrderNo: 125,
                    //     Quantity: 10,
                    //     SoldProduct: 'DF',
                    //     StockProductNo: 'p101',
                    //     TankStation: 'TankStation',
                    //     TransDate: '2020-02-01T00:00:00',
                    //     TransGroup: 10,
                    //     TransType: 12,
                    //     Truck: 'TruckName',
                    //     VolumeQuantity: 5,
                    //     VolumeTemp: 20,
                    //     ProductColorCode: '#ff0000',
                    //     ProductShortCode: 'DB',
                    //     Longitude: 0,
                    //     Latitude: 0
                    // }, {
                    //     Comment: 'comment',
                    //     CustomerAddress: 'Amd',
                    //     CustomerName: 'XYZ',
                    //     CustomerNo: 123,
                    //     EmployeeNo: 4,
                    //     OrderNo: 125,
                    //     Quantity: 10,
                    //     SoldProduct: 'DF',
                    //     StockProductNo: 'p101',
                    //     TankStation: 'TankStation',
                    //     TransDate: '2020-02-03T00:00:00',
                    //     TransGroup: 10,
                    //     TransType: 12,
                    //     Truck: 'TruckName',
                    //     VolumeQuantity: 5,
                    //     VolumeTemp: 20,
                    //     ProductColorCode: '#ff0000',
                    //     ProductShortCode: 'DB',
                    //     Longitude: 0,
                    //     Latitude: 0
                    // }];
                    this.filteredtransactionLogItems();
                    loader.dismiss();
                });
            });
        });
    }

    dateChangeEvent(event) {
        if (this.search === '') {
            this.currentDate = new Date(event.detail.value);
            this.loadData();
            
        }
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.dateSelector = this.currentDate.toISOString();
        this.loadData();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.dateSelector = this.currentDate.toISOString();
        this.loadData();
    }

    getDateText(transactionDate) {
        let transDate;
        transDate = new Date(transactionDate);
        return this.translateService.get('General.dateFormat').pipe(
            switchMap(format => of(moment(transDate).format(format.toUpperCase()))));
    }

    navigateBack() {
        this.navCtrl.pop();
    }

    sort(property) {
        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'asc';
        }

        this.transactionLogs = Utils.orderBy(this.transactionLogs, this.sortProperty, this.sortDirection);
    }

    

    filteredtransactionLogItemsSearch() {
        if (this.search.length >= 3) {
            this.loadData(true);
            // this.currentDate = _temp;
        }
    }

    filteredtransactionLogItems() {

        const filteredtransactionLogs = _.filter(this.transactionLogs, (log) => {
            if ((log.EmployeeNo.toString() === this.driver || this.driver === '0')
                /// &&
                // (this.search === '' || log.CustomerName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            ) {
                return log;
            }
        });
        this.filteredtransactionLogs = _.take(filteredtransactionLogs, 100);


    }

    driverName(empNo: any) {
        let name = '';
        this.drivers.forEach(element => {

            if (element.UserID.toString() === empNo.toString()) {
                console.log(element.UserID + ' ' + empNo);
                name = element.Name;

            }
        });
        return name;
    }
}
