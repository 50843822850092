import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import _ from 'lodash';
import { UserService } from '../../shared/user-service';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../shared/app-settings';
import { Location } from '@angular/common';
@Component({
    selector: 'page-work-orders-documents',
    templateUrl: 'work-orders-documents.html',
    styleUrls: ['work-orders-documents.scss'],
})

export class WorkOrdersDocumentsPage implements OnInit {
    httpFiles: any = [];
    orderNo;
    orderDocuments = [];
    constructor(
        private userService: UserService, private route: ActivatedRoute, private router: Router,
        private translateService: TranslateService, private location:Location,
        public navCtrl: NavController,private toastController:ToastController,
        private loadingController: LoadingController) {
        this.route.queryParams.subscribe(params => {
            this.orderNo = params.orderNo;
            if (this.orderNo !== undefined) {
                this.getOrdersDocuments();
            }
        });
    }

    ngOnInit1() {

        const fileInput: any = document.getElementById('file');
        fileInput.value = null;


      document.addEventListener('click', (e: any) => {
            if (e.target && e.target.id === 'file') {
                fileInput.value = null;
            }
        });

        document.addEventListener('change', (e: any) => {
            if (e.target && e.target.id === 'file') {
                const fileInput: any = document.getElementById('file');
                const files = fileInput.files;
                for (const file of files) {
                    const index = this.orderDocuments.findIndex(f => f.FilePath === file.name);
                    if (index === -1) {
                        this.httpFiles.push(file);
                        this.orderDocuments.push({ FilePath: file.name, IsInvoiceAttachment: false });
                    } else {
                        this.translateService.get('WorkOrders.alreadySelected').subscribe(data => {
                            alert(file.name + ' ' + data);
                        });
                    }
                }
            }
        });
    }


       ngOnInit() {
        const fileInput: any = document.getElementById('file');
        fileInput.value = null;
        document.addEventListener('click', (e: any) => {
            if (e.target && e.target.id === 'file') {
                fileInput.value = null;
            }
        });
        const that = this;
        that.httpFiles = [];
        document.addEventListener('change', (e: any) => {
            if (e.target && e.target.id === 'file') {
                const files = fileInput.files;
                for (const file of files) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event: any) => {
                        file.url = event.target.result;
                    };
                    const extFile = file.name.split('.')[1].toLowerCase();
                    if (extFile !== 'jpg' && extFile !== 'jpeg' && extFile !== 'png' && extFile !== 'svg' && extFile !== 'gif' && extFile !== 'pdf') {
                        that.translateService.get('NewDeviation.selectImage').subscribe(data => {
                            alert(data);
                        });
                    } else {
                        const index = _.findIndex(that.httpFiles, { name: file.name });
                       if (index === -1) {
                        this.httpFiles.push(file);
                        this.orderDocuments.push({ FilePath: file.name, IsInvoiceAttachment: false });
                    } else {
                        this.translateService.get('WorkOrders.alreadySelected').subscribe(data => {
                            alert(file.name + ' ' + data);
                        });
                    }
                    }
                }
                fileInput.value = null;
            }
        });
    }




    deleteFile(file) {
        this.httpFiles = _.filter(this.httpFiles, (f) => {
            return f.name !== file.FilePath;
        });
        this.orderDocuments = _.filter(this.orderDocuments, (f) => {
            return f.FilePath !== file.FilePath;
        });
    }

    saveWorkOrdersDocuments() {

         this.translateService.get('Loading.loading').subscribe(async value => {
        const loader = await this.loadingController.create({ message: value });
        const timeout = 30000; // 10 seconds timeout

        loader.present().then(() => {
           


            
        this.userService.saveWorkOrdersDocuments(this.httpFiles, this.orderNo, this.orderDocuments).subscribe(data => {
              loader.dismiss();
            this.navigateToBack();
        });
        });
    });
            
    }

    getOrdersDocuments() {
        this.userService.getOrdersDocuments(this.orderNo).subscribe(data => {
            this.orderDocuments = data;
        });
    }

    openDocument(file) {
        this.downloadDocument(file);

    }

downloadDocument(filePath) {
    this.translateService.get('Loading.loading').subscribe(async value => {
        const loader = await this.loadingController.create({ message: value });
        const timeout = 30000; // 10 seconds timeout

        loader.present().then(() => {
            const loaderTimeout = setTimeout(() => {
                loader.dismiss();
                this.translateService.get('Error.Timeout').subscribe(value => {
                    this.toastController.create({
                        message: value,
                        duration: 3000
                    }).then(toast => { toast.present(); });
                });
            }, timeout);

            if(filePath.FileData != "AA==") {
                this.downloadPDF(filePath.FileData, filePath.Description); 
                clearTimeout(loaderTimeout); // clear timeout if the process finishes successfully
                loader.dismiss();
            } else {
                this.userService.getOrderDocumentPDF(filePath.FilePath).subscribe(data => {
                    try {
                        this.downloadPDF(data, filePath.Description);
                        clearTimeout(loaderTimeout); // clear timeout if the process finishes successfully
                        loader.dismiss();
                    } catch (error) {
                        console.log("Error getting file: " + error + " - " + data);
                        clearTimeout(loaderTimeout); // clear timeout in case of error
                        loader.dismiss();
                        this.translateService.get('Error.FileNotFound').subscribe(value => {
                            this.toastController.create({
                                message: value,
                                duration: 3000
                            }).then(toast => { toast.present(); });
                        });
                    }
                }, error => {
                    clearTimeout(loaderTimeout); // clear timeout in case of error
                    loader.dismiss();
                    this.translateService.get('Error.FileNotFound').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });
                    });
                });
            }
        });
    });
}


    
    // Function to convert a base64 string to a Blob
base64ToBlob(base64: string, type = 'application/pdf') {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: type });
}

// Function to trigger the download of the PDF
downloadPDF(base64String: string, name:string) {
  const blob = this.base64ToBlob(base64String);
  const blobUrl = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = name + '.pdf'; // Name of the downloaded file
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(blobUrl); // Clean up the URL object
}

    navigateToBack() {
        this.location.back();

    }
}
