<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-text color="primary" class="ion-padding-left ion-padding-top">
            <h6>
              <ion-icon name="lock-open-outline"></ion-icon> {{
              'ForgotPassword.glemtPassord' | translate }}
            </h6>
          </ion-text>
          <form [formGroup]="passwordForm">
            <ion-list lines="none">
              <ion-item>
                <p>{{ 'ForgotPassword.oppgiDitt' | translate }}</p>
              </ion-item>
              <ion-item class="ion-icon icon-wrapper login-control">
                <ion-icon name="person-outline"></ion-icon>
                <ion-input
                  type="text"
                  formControlName="username"
                  autocomplete="off"
                  placeholder="{{ 'Login.username' | translate }}"
                ></ion-input>
              </ion-item>
              <ion-item
                class="item-validation"
                *ngIf="!passwordForm.controls.username.valid && passwordForm.controls.username.dirty"
              >
                <p ion-text no-margin>
                  {{ 'Login.validationUsernameRequired' | translate }}
                </p>
              </ion-item>
              <ion-item class="ion-icon icon-wrapper login-control">
                <ion-icon name="call-outline"></ion-icon>
                <ion-input
                  type="number"
                  formControlName="phoneNumber"
                  maxlength="8"
                  autocomplete="off"
                  placeholder="{{ 'ForgotPassword.mobilnummer' | translate }}"
                ></ion-input>
              </ion-item>
              <ion-item
                class="item-validation"
                *ngIf="passwordForm.controls.phoneNumber.dirty"
              >
                <p
                  ion-text
                  *ngIf="passwordForm.controls.phoneNumber.errors?.required"
                >
                  {{ 'ForgotPassword.validationMobileNumberRequired' | translate
                  }}
                </p>
                <p
                  ion-text
                  *ngIf="passwordForm.controls.phoneNumber.errors?.pattern"
                >
                  {{ 'ForgotPassword.phoneNo8Digits' | translate }}
                </p>
              </ion-item>
              <ion-item class="ion-float-right">
                <ion-buttons class="ion-margin-top">
                  <ion-button (click)="dismiss()" color="black" fill="outline">
                    <span>{{ 'General.avbryt' | translate }}</span>
                  </ion-button>
                  <ion-button
                    (click)="forgotPassword()"
                    [disabled]="!passwordForm.valid"
                    fill="solid"
                    color="black"
                  >
                    <span>{{ 'ForgotPassword.send' | translate }}</span>
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </ion-list>
          </form>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
