<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-text color="primary">
            <h6>{{ 'OrdreNextDay.utsettLeveringTilIMorgen' | translate }}</h6>
          </ion-text>
          <p>{{ 'OrdreNextDay.OskerDuAFlytte' | translate }}</p>
          <ion-list lines="none">
            <ion-item class="ion-float-right">
              <ion-buttons end>
                <ion-button
                  (click)="dismiss(false)"
                  color="grey"
                  size="small"
                  fill="outline"
                >
                  <span>{{ 'General.avbryt' | translate }}</span>
                </ion-button>
                <ion-button (click)="dismiss(true)" size="small" fill="solid">
                  <span>{{ 'General.save' | translate }}</span>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
