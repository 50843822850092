import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AppSettings } from './app-settings';
import { timeout, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class BaseHttpClient2 {
  constructor(private http: HttpClient) {}

  private createAuthorizationHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    if (AppSettings.Token) {
      headers = headers.set('Authorization', 'Bearer ' + AppSettings.Token);
    }
    return headers;
  }

  get<T>(url: string, options?: any): Observable<T | HttpEvent<T>> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();
    return this.http.get<T>(url, { ...options }).pipe(timeout(60000));
  }

  post<T>(url: string, data: any, options?: any): Observable<T> {
    options = options || {};
    options.headers = this.createAuthorizationHeader();
    return this.http.post<T>(url, data, { ...options }).pipe(
      timeout(60000),
      map((res) => res as T)
    );
  }

  
  getFile(url: string): Observable<Blob> {
    const options = {
      headers: this.createAuthorizationHeader(),
      responseType: 'blob' as 'json'
    };
    return this.http.get<Blob>(url, options).pipe(timeout(60000));
  }
}
