<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'WorkOrders.workOrdersDocuments' | translate |uppercase }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <label>{{ 'WorkOrders.photos/Documents' | translate }}</label>
          <div class="input-group form-group">
            <div class="custom-file">
              <label for="file" class="custom-file-upload">
                <ion-icon name="cloud-upload-outline"></ion-icon> {{
                'WorkOrders.selectFiles' | translate }}
              </label>
              <input type="file" multiple id="file" />
            </div>
          </div>
          <ion-row class="ion-margin-top" *ngIf="orderDocuments.length > 0">
            <ion-col size-xs="12">
              <div class="overflow-x">
                <div class="documents-grid">
                  <ion-row class="grid-header">
                    <ion-col size="8">
                      {{ 'WorkOrders.fileName' | translate }}</ion-col
                    >
                    <ion-col size="3" class="ion-text-center">
                      {{ 'WorkOrders.isInvoiceAttachment' | translate
                      }}</ion-col
                    >
                    <ion-col size="1"></ion-col>
                  </ion-row>
                  <ion-row *ngFor="let file of orderDocuments">
                    <ion-col size="8"
                      ><span
                        class="cursor-pointer"
                        (click)="openDocument(file)"
                      >
                        <ion-icon name="cloud-download-outline"></ion-icon
                        >&nbsp; {{file.Description}}</span
                      ></ion-col
                    >
                    <ion-col size="8" *ngIf="!file.FilePath">
                      <ion-icon name="cloud-download-outline"></ion-icon
                      >&nbsp;<span>{{file.FilePath}}</span>
                    </ion-col>
                    <ion-col size="3" class="ion-text-center">
                      <ion-checkbox [(ngModel)]="file.IsInvoiceAttachment">
                      </ion-checkbox>
                    </ion-col>
                    <ion-col size="1" class="ion-text-center">
                      <ion-icon
                        name="trash-outline"
                        color="primary"
                        class="icon-size cursor-pointer"
                        (click)="deleteFile(file)"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
      <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
      <span class="black">{{ 'OrdreDetails.tilbake' | translate }}</span>
    </ion-button>
    <ion-button
      class="no-padding pull-right-md"
      fill="outline"
      (click)="saveWorkOrdersDocuments()"
    >
      <ion-icon name="document"></ion-icon>&nbsp;
      <span>{{ 'WorkOrders.saveDocuments' | translate }}</span>
    </ion-button>
  </div>
</ion-footer>
