import { Component, ViewChild, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { DeviationType } from '../../shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { CustomerData } from '../../shared/customer-data';
import { Router } from '@angular/router';
import { Observable, map, of, startWith, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'page-new-deviation',
    templateUrl: 'new-deviation.html',
    styleUrls: ['new-deviation.scss']
})
export class NewDeviationPage implements AfterViewInit, OnInit {
    submitAttempt = false;
    deviationForm: FormGroup;
    deviationType = DeviationType;
    copyToCustomer = false;
    trafficConditions = false;
    deliveryConditions = false;
    accident = false;
    other = false;
    personalInjury = false;
    deviationTypeOption: DeviationType = DeviationType.Other;
    trailersAndTrucks = [];
    tanks = [];
    dateFormat;
    httpFiles: any = [];
    selectedCustomer: any = null;
    openModal = false;

    customerDataService;
    customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;

    @ViewChild('customerComplete') customerComplete: ElementRef;

    ngAfterViewInit() {
        if (this.deviationTypeOption === this.deviationType.Customer) {
            this.customerComplete.nativeElement.setAttribute('type', 'text');
        }
    }

    constructor(
        private formBuilder: FormBuilder, public userService: UserService,
        public navCtrl: NavController, private translateService: TranslateService, private loadingController: LoadingController,
        private http: HttpClient, private router:Router) {
        this.loadData();
        this.deviationForm = formBuilder.group({
            other: [''],
            customer: [''],
            trailersAndTrucks: [''],
            tank: [''],
            category: [''],
            comments: ['', Validators.required],
            reason: [''],
            date: [new Date().toISOString()],
            priority: [0, Validators.required],
        });
        this.customerDataService = new CustomerData(http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);

        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

    }

        closeModal(){
    this.openModal = false;
}
    ngOnInit() {
        const fileInput: any = document.getElementById('file');
        fileInput.value = null;
        document.addEventListener('click', (e: any) => {
            if (e.target && e.target.id === 'file') {
                fileInput.value = null;
            }
        });
        const that = this;
        that.httpFiles = [];
        document.addEventListener('change', (e: any) => {
            if (e.target && e.target.id === 'file') {
                const files = fileInput.files;
                for (const file of files) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event: any) => {
                        file.url = event.target.result;
                    };
                    const extFile = file.name.split('.')[1].toLowerCase();
                    if (extFile !== 'jpg' && extFile !== 'jpeg' && extFile !== 'png' && extFile !== 'svg' && extFile !== 'gif') {
                        that.translateService.get('NewDeviation.selectImage').subscribe(data => {
                            alert(data);
                        });
                    } else {
                        const index = _.findIndex(that.httpFiles, { name: file.name });
                        if (index === -1) {
                            that.httpFiles.push(file);
                        } else {
                            that.translateService.get('NewDeviation.alreadySelected').subscribe(data => {
                                alert(file.name + ' ' + data);
                            });
                        }
                    }
                }
                fileInput.value = null;
            }
        });

              this.filteredCustomers = this.customerCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(name => name ? this._filterCustomers(name) : of([])) // Use switchMap instead of map
            );

      
     //   this.initializeAutocomplete();

    }

    displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }




    getFormControl(field) {
        return this.deviationForm.get(field);
    }

    clearFormControl(form: FormGroup) {
        for (const key in form.controls) {
            if (key) {

                if(key=="date")
                {
                    try{form.get(key).setValue(new Date().toISOString())}catch{};}
                else if(key=="priority")
                {
                    try{form.get(key).setValue(0)}catch{};}
                else{
                    try{form.get(key).setValue('')}catch{};
                }

                try{form.get(key).clearValidators()}catch{};
                try{form.get(key).updateValueAndValidity()}catch{};

                
            }
        }
        this.copyToCustomer = false;
        this.trafficConditions = false;
        this.deliveryConditions = false;
        this.accident = false;
        this.other = false;
        this.personalInjury = false;
        this.httpFiles = [];
    }

    loadData() {
        this.getTrailersAndTrucks();
        this.getTanks();
    }

    removeRequiredValidator(control) {
        this.getFormControl(control).setValue('');
        this.getFormControl(control).setValidators([]);
        this.getFormControl(control).markAsPristine();
    }

    changeDeviationTypeOption() {
        if (this.deviationTypeOption === this.deviationType.Other) {
            this.getFormControl('other').setValidators([Validators.required]);
            this.removeRequiredValidator('category');
            this.removeRequiredValidator('trailersAndTrucks');
            this.removeRequiredValidator('tank');
            this.removeRequiredValidator('customer');
        } else if (this.deviationTypeOption === this.deviationType.CarTrailer) {
            this.getFormControl('trailersAndTrucks').setValidators([Validators.required]);
            this.removeRequiredValidator('other');
            this.removeRequiredValidator('tank');
            this.removeRequiredValidator('category');
            this.removeRequiredValidator('customer');
        } else if (this.deviationTypeOption === this.deviationType.AutomateStationStorageTank) {
            this.getFormControl('tank').setValidators([Validators.required]);
            this.removeRequiredValidator('other');
            this.removeRequiredValidator('trailersAndTrucks');
            this.removeRequiredValidator('category');
            this.removeRequiredValidator('customer');
        } else {
            this.selectedCustomer = null;
            if (this.selectedCustomer == null) {
                this.getFormControl('customer').setErrors({ incorrect: true });
            }
            this.removeRequiredValidator('trailersAndTrucks');
            this.removeRequiredValidator('tank');
            this.removeRequiredValidator('other');
            this.removeRequiredValidator('category');
        }
        this.getFormControl('other').updateValueAndValidity();
        this.getFormControl('trailersAndTrucks').updateValueAndValidity();
        this.getFormControl('tank').updateValueAndValidity();
        this.getFormControl('category').updateValueAndValidity();
        this.getFormControl('customer').updateValueAndValidity();
    }

    customerSelected($event) {
        if ($event != null) {
            this.selectedCustomer = $event.originalObject;
        } else {
            this.selectedCustomer = null;
        }
    }

    getTrailersAndTrucks() {
        this.userService.getTrailersAndTrucks().subscribe(data => {
            this.trailersAndTrucks = data.Trailers;
            this.trailersAndTrucks = this.trailersAndTrucks.concat(data.Trucks);
        });
    }

    getTanks() {
        this.userService.getTanks().subscribe(tanks => {
            this.tanks = tanks;
        });
    }

    deleteFile(file) {
        const files = _.filter(this.httpFiles, (item) => {
            return item.name !== file.name;
        });
        this.httpFiles = files;
    }

    navigateToBack() {
       this.router.navigate(['/documentation'])
    }

    async save() {
        if (this.deviationForm.valid) {

             this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });

                    loader.present().then(() => {
                 
                
            const deviation: any = {};
            deviation.DeviationType = this.deviationTypeOption;
            deviation.Other = this.getFormControl('other').value;
            deviation.CustomerNo = this.selectedCustomer ? this.selectedCustomer.CustomerNo : 0;
            deviation.CopyToCustomer = this.copyToCustomer;
            deviation.TruckTrailerID = this.getFormControl('trailersAndTrucks').value ? this.getFormControl('trailersAndTrucks').value.ID :
                this.getFormControl('trailersAndTrucks').value;
            deviation.TankID = this.getFormControl('tank').value ? this.getFormControl('tank').value.TankID :
                this.getFormControl('tank').value;
            deviation.TrafficCondition = this.trafficConditions;
            deviation.DeliveryConditions = this.deliveryConditions;
            deviation.PersonalInjury = this.personalInjury;
            deviation.Accident = this.accident;
            deviation.CategoryOther = this.getFormControl('category').value;
            deviation.Comments = this.getFormControl('comments').value;
            deviation.Reason = this.getFormControl('reason').value;
            deviation.DateOfIncident = this.getFormControl('date').value;
            deviation.Category = this.getFormControl('priority').value;
            deviation.AssignedTo = 0;
            deviation.AssignedToName = "";

            this.userService.saveDeviation(this.httpFiles, deviation).subscribe(data => {
                this.clearFormControl(this.deviationForm);
                loader.dismiss();
                this.router.navigate(['/documentation'])
            });
        });
    });
        }
    
    }

}

