import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/user-service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, LoadingController, ToastController, NavParams, } from '@ionic/angular';

@Component({
    selector: 'forgot-password',
    templateUrl: 'forgot-password.html',
    styleUrls: ['forgot-password.scss']
})
export class ForgotPasswordComponent {
    passwordForm: FormGroup;
    private clientID = 0;

    constructor(
        public modalCtrl: ModalController, private translateService: TranslateService,
        private formBuilder: FormBuilder, private userService: UserService, private loadingController: LoadingController,
        private toastController: ToastController, private navParams: NavParams) {

        this.passwordForm = formBuilder.group({
            username: ['', Validators.compose([Validators.required])],
            phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern('\\d{8,8}')])]
        });
        // Get company client from parent
        this.clientID = this.navParams.get('ClientID');

    }

    forgotPassword() {
        if (this.passwordForm.valid) {
            const controls = this.passwordForm.controls;
            const username = this.passwordForm.get('username').value;
            const phoneNumber = this.passwordForm.get('phoneNumber').value;
            this.translateService.get('Loading.saving').subscribe(async saving => {
                const loader = await this.loadingController.create({ message: saving });

                loader.present().then(() => {
                    this.userService.forgotPassword({ Username: username, PhoneNumber: phoneNumber, ClientID: this.clientID })
                        .subscribe(data => {
                            loader.dismiss();
                            if (data) {
                                this.translateService.get('ForgotPassword.nyttPassord').subscribe(newPassword => {

                                    this.toastController.create({
                                        message: newPassword,
                                        duration: 3000
                                    }).then(toast => { toast.present(); });

                                    this.modalCtrl.dismiss();
                                });
                            } else {
                                this.translateService.get('ForgotPassword.fantIkke').subscribe(value => {

                                    this.toastController.create({
                                        message: value,
                                        duration: 3000

                                    }).then(toast => { toast.present(); });
                                });
                            }
                        }, error => {
                            loader.dismiss();
                            this.toastController.create({
                                message: error.statusText,
                                duration: 3000
                            }).then(toast => { toast.present(); });


                        });
                });
            });
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
