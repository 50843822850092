<ion-app>
  <!-- <ion-menu side="start" menuId="first" contentId="menu-content">
    <ion-content>
      <ion-list>
        <button ion-item class="ion-text-center" (click)="openPage(pages[0])">
          <img src="assets/icon/KlogoWhite.svg"/>
        </button>
        <ng-container *ngFor="let page of pages">
          <div [ngClass]="{'current-menu': page.url == currentPage}" *ngIf="!page.hide">
            <button ion-item (click)="openPage(page)" [ngClass]="{'current-menu': page.url == currentPage}">
              <img *ngIf="page.blackIconName || page.whiteIconName" src="assets/icon/{{page.url == currentPage ? page.blackIconName : page.whiteIconName}}"
                width="60" height="60" />
              <ion-icon *ngIf="page.ionIconName" [name]="page.ionIconName" class="menu-ion-icon"></ion-icon>
              <br />
              <span class="menu-title">{{page.title}}</span>
            </button>
          </div>
        </ng-container>
      </ion-list>
    </ion-content>
  </ion-menu> -->
  <ion-menu side="start" menuId="first" contentId="menu-content">
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" class="ion-margin-bottom">
            <button ion-item  (click)="openPage(pages[0])">
              <img src="assets/icon/KlogoWhite.svg" />
            </button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let page of menuItems;let i = index">
            <ion-col [offsetSm]="(i*1) %10==0 || (i*1) %10==5 ?1:0" offset-xs="0" size-xs="6" size-sm="2" *ngIf="!page.hide">
              <div [ngClass]="{'current-menu2': page.url == currentPage}">
                <button ion-item (click)="openPage(page)" [ngClass]="{'current-menu': page.url == currentPage}">
                  <img *ngIf="page.blackIconName || page.whiteIconName"
                    src="assets/icon/{{page.url == currentPage ? page.blackIconName : page.whiteIconName}}" width="60"
                    height="60" />
                  <ion-icon *ngIf="page.ionIconName" [name]="page.ionIconName" class="menu-ion-icon"></ion-icon>
                  <br />
                  <span class="menu-title">{{page.title}}</span>
                </button>
              </div>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-menu>

  <!-- <ion-toolbar [root]="rootPage" #content swipeBackEnabled="false"></ion-toolbar> -->
  <ion-router-outlet id="menu-content"></ion-router-outlet>
</ion-app>