export enum TransactionType {
    LoadingFromStorage = 11,
    UnloadingAutoGas = 21,
    UnloadingToStorage = 22,
    LoadingFromTruck = 12,
    UnloadingToTruck = 23
    // LoadingFromTruckToTruck = -1
}

export enum TypeOfTank {
    AutoGas = 1,
    NotInUse = 2,
    Storage = 3
}

export enum Userlevel {
    Administrator = 1,
    Superuser = 2,
    User = 3
}

export enum UserType {
    Employee = 0,
    Customer = 1,
    Supplier = 2
}

export enum AssignmentOrderView {
    VisSjaForensOrdrer = 1,
    VisOrdreListe = 2
}

export enum PlannerOrderView {
    showTruckOrder = 1,
    showOrderlist = 2
}

export enum Modules {
    Home = 0,
    Inventory = 1,
    Orders = 2,
    InternalOrders = 3,
    Activity = 4,
    CreateNewOrder = 5,
    TankMonitoring = 6,
    ActivityNew = 7,
    StockGoods = 8,
    WorkOrder = 9,
        Forms = 10,
        Approval = 11,
        Fleet = 12,
        Planner = 13,
    Settings = 99,
    Documentation = 100,
    OrdersProduct = 101,
    SooloOrders= 102,
    Leads = 103

}

export enum ActivityView {
    DateView = 1,
    MonthView = 2
}

export enum RespUnit {
    Department = 1,
    Project = 2,
    ActivityOrders = 3
}

export enum StockTransactiontype {
    Incoming = 1,
    Outgoing = 2,
    Inventory = 3,
    Transfer = 4,
    OutgoingInternal = 5
}

export enum InternalOrdertype {
    Rental = 1,
    Bulk = 2,
    Product = 3,
    Workorder = 4,
    PurchaseOrder = 9
}

export enum ProductType {
    Bulk = 10,
    Other = 20,
    Workorder = 30
}

export enum DeviationType {
    Customer = 1,
    AutomateStationStorageTank = 2,
    CarTrailer = 3,
    Other = 4
}

export enum DocumentationType {
    Deviation = 1,
    CustomerLead = 2,
    MonthlyChecklist = 3,
    DailyChecklist = 4
}

export enum CustomerType {
    Private = 'Private',
    Company = 'Company',
    Unknown = 'Unknown',
}

