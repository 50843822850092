<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'LeadView.formsHandling' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height forms-handling">
      <ion-row>
        <ion-col size-xs="12" size-lg="6" class="pull-left">
          <ion-segment
            value="default"
            (ionChange)="tabViewChange($event)"
            [(ngModel)]="tabView"
            class="tabFont justify-left"
          >
            <ion-segment-button value="checklist">
              <ion-label>{{ 'LeadView.checklist' | translate }}</ion-label>
            </ion-segment-button>
            <ion-segment-button value="avvik">
              <ion-label>{{ 'LeadView.deviationList' | translate }}</ion-label>
            </ion-segment-button>
            <ion-segment-button value="leads">
              <ion-label>{{ 'LeadView.leads' | translate }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-col>

        <ion-col size-xs="12" size-lg="6" class="pull-right search-bar">
          <div>
            <ion-searchbar
              placeholder="{{ 'LeadView.searchDriver' | translate }}"
              [(ngModel)]="searchQuery"
              (ngModelChange)="filterForms()"
              debounce="1200"
            ></ion-searchbar>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="overflow-x">
            <div class="grid-width">
              <!-- CHECKLISTS-->
              <ion-list *ngIf="tabView == 'checklist'">
                <ion-grid>
                  <ion-row>
                    <ion-col><strong>ID</strong></ion-col>
                    <ion-col><strong>Type</strong></ion-col>
                    <ion-col><strong>Dato</strong></ion-col>
                    <ion-col><strong>Kjøretøy</strong></ion-col>
                    <ion-col><strong>Last ned</strong></ion-col>
                  </ion-row>
                  <ion-row
                    *ngFor="let formResponse of filteredForms; let isOdd = odd; let i = index"
                    [class.odd]="isOdd"
                  >
                    <ion-col>{{ formResponse.Id }}</ion-col>
                    <ion-col
                      >{{ formResponse.FormType == 3 ? 'Dagskontoll' :
                      'Månedskontroll' }}</ion-col
                    >
                    <ion-col>{{ formResponse.FormDate | date }}</ion-col>
                    <ion-col>{{ formResponse.FormObject }}</ion-col>
                    <ion-col>
                      <ion-button
                        (click)="downloadPdf(formResponse.Id)"
                        fill="outline"
                      >
                        <ion-icon name="document-attach"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-list>
              <!--LEADS-->
              <ion-list *ngIf="tabView == 'leads'">
                <ion-row
                  *ngFor="let form of forms | paginate: config ; let isOdd = odd; let i = index"
                  [class.odd]="isOdd"
                  class="border-bottom"
                >
                  <ion-col size-md="3">
                    <span
                      ><strong>
                        <ion-text *ngIf="form.FormTypeId==1" color="success">
                          {{ 'LeadView.lead' | translate |uppercase }}</ion-text
                        >
                        <ion-text *ngIf="form.FormTypeId==2" color="primary">
                          {{ 'LeadView.checklist' | translate |uppercase }}
                        </ion-text>
                      </strong></span
                    >
                    <span class="pull-right">
                      {{form.FormCreationDateTime | date:dateFormat}}
                    </span>
                    <div class="clear"></div>
                    <div>
                      <strong>{{form.DriverName}}</strong><br />

                      <span *ngIf="isAssignedTo(form.Leads[0])">
                        <em>{{ 'LeadView.assignedUser' | translate }} </em>
                        <strong
                          >{{ getAssignedName(form.Leads[0].AssignedUserId) }}
                        </strong>
                      </span>
                    </div>
                  </ion-col>
                  <ion-col size-md="4">
                    <div *ngIf="form.FormTypeId==1">
                      {{form.Leads[0].CustomerName}}
                    </div>
                    <div *ngIf="form.FormTypeId==2">
                      {{form.CheckLists[0].OwnerName}}
                    </div>
                    <div *ngIf="form.FormTypeId==1">
                      <div *ngIf="form.Leads[0].Messages.length > 0">
                        {{form.Leads[0].Messages[0].Message}}
                      </div>
                    </div>
                  </ion-col>
                  <ion-col size-md="2"
                    ><span *ngIf="form.FormTypeId==1"
                      >{{form.Leads[0].Status.StatusName}}</span
                    ></ion-col
                  >
                  <ion-col size-md="1">
                    <p>
                      <strong>
                        <ion-text *ngIf="form.FormTypeId==1">
                          {{form.Leads[0].Award}}p</ion-text
                        >
                      </strong>
                    </p>
                  </ion-col>
                  <ion-col size-md="1" class="ion-text-center">
                    <ion-button
                      (click)="openLead(form)"
                      *ngIf="form.FormTypeId==1"
                    >
                      <ion-icon name="create-outline"></ion-icon> &nbsp; {{
                      'LeadView.view' | translate }}
                    </ion-button>
                    <ion-button
                      *ngIf="form.FormTypeId==2"
                      (click)="openChecklist(form)"
                    >
                      <ion-icon name="create-outline"></ion-icon> &nbsp; {{
                      'LeadView.view' | translate }}
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-list>

              <!--AVVIK-->
              <ion-list *ngIf="tabView == 'avvik'">
                <ion-row
                  *ngFor="let form of deviationForms | paginate: config ; let isOdd = odd; let i = index"
                  [class.odd]="isOdd"
                  class="border-bottom"
                >
                  <ion-col size-md="3"
                    ><strong>
                      <span>
                        #{{form.ID }} - {{form.DateOfIncident |
                        date:dateFormat}}
                      </span>
                      -
                      <span *ngIf="form.DeviationType==1"
                        >Farlige trafikkforhold</span
                      >
                      <span *ngIf="form.DeviationType==2"
                        >Farlige leveringsforhold</span
                      >
                      <span *ngIf="form.DeviationType==3"
                        >Nesten uhell/hendelse</span
                      >
                      <span *ngIf="form.DeviationType==4"
                        >Uhell uten personskade</span
                      >
                      <span *ngIf="form.DeviationType==5">Annet</span></strong
                    >
                    <br />
                    {{form.Comments}}<br />
                    {{form.Other}}<br />
                    {{form.CategoryOther}}
                  </ion-col>
                  <ion-col size-md="4">
                    <span *ngIf="form.AssignedTo>0">
                      <em *ngIf="form.DeviationStatus==0"
                        >{{ 'LeadView.assignedUser' | translate }}
                      </em>
                      <span
                        class="color-primary"
                        *ngIf="form.DeviationStatus==9"
                        ><strong
                          >{{ 'LeadView.completed' | translate }}</strong
                        > </span
                      ><br />
                      <strong>{{ getAssignedName(form.AssignedTo) }} </strong>
                    </span>
                    <span *ngIf="form.AssignedTo==0">
                      <em>{{ 'LeadView.notAssigned' | translate }} </em>
                    </span>
                  </ion-col>
                  <ion-col size-md="4">
                    <div>
                      <div *ngIf="form.Messages?.length > 0">
                        <div *ngFor="let msg of form.Messages">
                          <u>
                            <span
                              >{{msg.MessageCreationDateTime | date:
                              dateFormat}}</span
                            >
                          </u>
                          &nbsp; {{msg.MessageValue}}
                        </div>
                      </div>
                    </div>
                  </ion-col>

                  <ion-col size-md="1" class="ion-text-center">
                    <ion-button (click)="openChecklist(form)" [hidden]="true">
                      <ion-icon name="create-outline"></ion-icon> &nbsp; {{
                      'LeadView.view' | translate }}
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-list>
            </div>
          </div>
          <pagination-controls
            (pageChange)="pageChanged($event)"
            *ngIf="forms.length > 0"
            previousLabel=""
            nextLabel=""
          ></pagination-controls>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="footer-border ion-margin-top-16"></div>
  <div>
    <ion-button fill="outline" class="no-padding" routerLink="/documentation">
      <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
      <span class="black">{{ 'Menu.documentation' | translate }}</span>
    </ion-button>
    <div class="pull-right">
      <strong>{{ 'LeadView.status' | translate }}</strong> &nbsp;&nbsp;
      <select
        [(ngModel)]="status"
        size="1"
        (ngModelChange)="filterEvents()"
        title="Velg"
      >
        <option value="null">{{ 'General.select' | translate }}</option>
        <option *ngFor="let item of statuses" value="{{item.StatusId}}">
          {{item.StatusName}}
        </option>
      </select>
      <div class="clear"></div>
    </div>
  </div>
</ion-footer>
