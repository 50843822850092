import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';

@Component({
    selector: 'orderline-split',
    templateUrl: 'orderline-split.html',
    styleUrls: ['orderline-split.scss']
})
export class OrderlineSplitComponent {
     convertProductForm: FormGroup;
     inventoryList: any[];
     description: string;
     description2: string;
     descriptionProd: string;
     min = 0;
     max = 0;
     order:any;

    constructor(
        public modalCtrl: ModalController, private userService: UserService, private translateService: TranslateService,
        public navParams: NavParams, private loadingController: LoadingController, private formBuilder: FormBuilder,
        private toastController: ToastController) {
        this.inventoryList = this.navParams.data.inventoryList;
        this.order = this.navParams.data.item;

       const orderlinesGroup: FormGroup = new FormGroup({});

if (this.order.Orderlines) {
                let orderline;
                for (orderline of this.order.Orderlines) {
                    const control: FormControl = new FormControl(orderline.QuantityDelivered,
                        Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
                    orderlinesGroup.addControl('o' + orderline.LineNo, control);
                }
            }

         this.convertProductForm = formBuilder.group({
                orderLines: orderlinesGroup,
            
            });

        this.translateService.get('OrderlineSplit.description').subscribe(value => {
            this.description = value;
        });

        this.translateService.get('OrderlineSplit.description2').subscribe(value => {
            this.description2 = value;
        });

     
    }

     formValue(ctrlName){
         const control = this.convertProductForm.controls.orderLines;
         return control.get(ctrlName).value;
}


    addProduct() {
                
        
     
        if (1 > 2) {
            const product = this.convertProductForm.get('o1').value;
            const quantity = this.convertProductForm.get('quantity').value;
            this.modalCtrl.dismiss({ Product: product, Quantity: quantity });
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

}
