<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-text color="primary">
            <h6>
              <ion-icon name="add-circle-outline"></ion-icon> {{
              'OrdreDetails.leggTilProdukt' | translate }}
            </h6>
          </ion-text>
          <ion-list lines="none">
            <form [formGroup]="leggForm">
              <ion-item>
                <ion-searchbar
                  class="search-bar"
                  placeholder="{{ 'LeggTilProdukt.searchProduct' | translate }}"
                  formControlName="productNo"
                  debounce="300"
                  (ionInput)="searchProducts($event)"
                ></ion-searchbar>
              </ion-item>
              <ion-item
                class="item-validation"
                *ngIf="!leggForm.controls.productNo.valid && (leggForm.controls.productNo.dirty)"
              >
                <ion-text color="danger">
                  <p
                    [hidden]="!leggForm.controls.productNo.errors.required"
                    class="ion-no-margin"
                  >
                    {{ 'LeggTilProdukt.validationProductRequired' | translate }}
                  </p>
                </ion-text>
              </ion-item>
              <div class="product-item">
                <ion-item
                  *ngFor="let product of searchProduct"
                  (click)="selectProduct(product)"
                >
                  <ion-badge [style.background]="product.ColorCode"
                    >{{product.ShortCode}}</ion-badge
                  >&nbsp;
                  <span>{{product.Description}}</span>
                </ion-item>
              </div>
              <ion-item
                class="ion-margin-start"
                *ngIf="searchProduct.length === 0 && leggForm.controls['productNo'].value && !product"
              >
                {{'LeggTilProdukt.noProductsFound' | translate }}
              </ion-item>
              <ion-item>
                <ion-input
                  type="number"
                  formControlName="quantity"
                  class="ion-text-right"
                  autocomplete="off"
                  placeholder="{{ 'LeggTilProdukt.quantity' | translate }}"
                  #qty
                />
              </ion-item>
              <ion-item
                class="item-validation"
                *ngIf="!leggForm.controls.quantity.valid && (leggForm.controls.quantity.dirty)"
              >
                <ion-text color="danger">
                  <p
                    [hidden]="!leggForm.controls.quantity.errors.required"
                    class="ion-no-margin"
                  >
                    {{ 'LeggTilProdukt.quantityRequired' | translate }}
                  </p>
                </ion-text>
                <ion-text color="danger">
                  <p
                    [hidden]="!leggForm.controls.quantity.errors.pattern"
                    class="ion-no-margin"
                  >
                    {{ 'LeggTilProdukt.quantityOnlyNumbers' | translate }}
                  </p>
                </ion-text>
              </ion-item>

              <ion-row class="noflex m-t-10">
                <ion-col>
                  <ion-text color="primary">
                    <h6>
                      <ion-icon name="star"></ion-icon> {{
                      'General.featuredProducts' | translate }}
                    </h6>
                  </ion-text>
                </ion-col>
                <ion-col
                  *ngFor="let item of featuredProducts"
                  class="featuredProductsList"
                >
                  <ion-button
                    fill="outline"
                    (click)="selectProduct(item)"
                    class="prodButton"
                  >
                    <ion-badge [style.background]="item.ColorCode"
                      >{{item.ShortCode}}
                    </ion-badge>
                    &nbsp; {{item.Description}}
                  </ion-button>
                </ion-col>
              </ion-row>
            </form>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer>
  <ion-buttons class="ion-float-right ion-margin">
    <ion-button
      small
      (click)="dismiss()"
      color="grey"
      fill="outline"
      size="small"
    >
      <span>{{ 'General.avbryt' | translate }}</span>
    </ion-button>
    <ion-button
      padding-right
      small
      (click)="addProdukt()"
      [disabled]="!leggForm.valid || !product"
      fill="solid"
      size="small"
    >
      <span>{{ 'General.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</ion-footer>
