<ion-header>
    <ion-toolbar hideBackButton="true">
        <ion-menu-toggle>
            <ion-button fill="clear">
                <ion-icon slot="icon-only" name="menu"></ion-icon>
            </ion-button>
        </ion-menu-toggle>
        <ion-title>
            <span>{{ 'Menu.tildelOrdrer' | translate }}</span>
            <logout class="hidden-xs"></logout>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <div class="scroll-content" style="margin-top: 65px;">
        <ion-grid class="full-height">
            <ion-row class="full-height">
                <ion-col>
                    <div class="page-title">
                        <ng-container *ngIf="visKunDagens">
                            <ion-icon name="calendar-outline" ion-text color="primary"
                                class="litte-big-font vertical-middle"></ion-icon>
                            {{'Day.' + currentDate.getDay() | translate}}
                            <span class="secondary">
                                {{currentDate | date:dateFormat}}
                                <ion-button (click)="previousDay()"
                                    class="no-padding button-no-text vertical-top ion-no-margin" fill="clear">
                                    <ion-icon color="lightFour" slot="icon-only" name="caret-back-circle">
                                    </ion-icon>
                                </ion-button>
                                <ion-button (click)="nextDay()"
                                    class="no-padding button-no-text vertical-top ion-no-margin" fill="clear">
                                    <ion-icon color="lightFour" slot="icon-only" name="caret-forward-circle">
                                    </ion-icon>
                                </ion-button>
                            </span>
                        </ng-container>
                    </div>
                    <ion-grid class="ion-no-padding">
                        <ion-row class="grid-header">
                            <ion-col size-xs="12">
                                <strong class="ion-padding">{{'OrderAssignment.velgSjafor' | translate}}</strong>
                            </ion-col>
                        </ion-row>
                        <div class="overflow-x">
                        <div class="grid-width">
                                <ion-row>
                                        <ion-col size-xs="3" *ngFor="let driver of filteredDrivers" class="ion-no-padding">
                                            <div class="list-item" [ngClass]="{'selected-item': driver == currentDriver}"
                                                (click)="selectDriver(driver)">
                                                <ion-icon name="person-outline" ion-text
                                                    [color]="driver == currentDriver ? 'secondary' : 'primary'"></ion-icon>
                                                {{driver.Name}}
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </div>
                        </div>
                    </ion-grid>
                    <br />
                    <div class="max-100 overflow-x">
                        <div class="grid-width">
                            <ion-row class="grid-header">
                                <ion-col size="1" class="ion-text-center">
                                    <strong>{{ 'Ordrer.ordre' | translate }}</strong>
                                    <ion-button (click)="sort('OrderNo')"
                                        class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">

                                        <ion-icon color="lightFour"
                                            [name]="(sortProperty == 'OrderNo' && sortDirection == 'asc') || sortProperty != 'OrderNo' ? 'caret-up' : 'caret-down'">
                                        </ion-icon>
                                    </ion-button>
                                </ion-col>
                                <ion-col size="4">
                                    <strong>{{ 'Ordrer.kunde' | translate }}</strong>
                                    <ion-button (click)="sort('DeliveryName')"
                                        class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">
                                        <ion-icon ion-text color="lightFour"
                                            [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'">
                                        </ion-icon>
                                    </ion-button>
                                </ion-col>
                                <ion-col size="2" class="ion-text-center">
                                    <strong>{{ 'Ordrer.leveres' | translate }}</strong>
                                    <ion-button (click)="sort('DeliveryDate')"
                                        class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">
                                        <ion-icon ion-text color="lightFour"
                                            [name]="(sortProperty == 'DeliveryDate' && sortDirection == 'asc') || sortProperty != 'DeliveryDate' ? 'caret-up' : 'caret-down'">
                                        </ion-icon>
                                    </ion-button>
                                </ion-col>
                                <ion-col size="3">
                                    <strong>{{ 'Ordrer.produkterVolum' | translate }}</strong>
                                </ion-col>
                                <ion-col size="2">
                                    <strong></strong>
                                </ion-col>
                            </ion-row>
                            <ion-row *ngFor="let order of filteredOrders; let isOdd = odd" [class.odd]="isOdd">
                                <ion-col>
                                    <ion-row>
                                        <ion-col size-xs="1" class="handle ion-text-center">
                                            <ion-icon color="primary" (click)="expandOrder(order)"
                                                [name]="!order.expandedView ? 'add-circle-outline' : 'remove-circle-outline'"
                                                class="litte-big-font" class="vertical-text-bottom"></ion-icon>&nbsp;
                                            <ion-text [color]="isOrderMine(order) == true ? 'success' : ''">
                                                <strong>{{order.OrderNo}}</strong></ion-text>
                                            <div *ngIf="isOrderMine(order) == true">
                                                <ion-icon name="person-outline" color="success" class="litte-big-font handle">
                                                </ion-icon>
                                            </div>
                                        </ion-col>
                                        <ion-col size-xs="4">
                                            <strong>{{order.DeliveryName}}</strong>
                                            <br /> {{order.DeliveryAddress1}} {{order.DeliveryPostalCode}}
                                            {{order.DeliveryCity}}
                                        </ion-col>
                                        <ion-col size-xs="2" class="ion-text-center">
                                            <div class="circle" [class.red-circle]="order.IsUrgent"
                                                [class.grey-circle]="!order.IsUrgent"></div>
                                            <span [innerText]="getDateText(order) | async"></span>
                                        </ion-col>
                                        <ion-col size-xs="3">
                                            <span *ngFor="let orderLine of order.Orderlines" class="m-r-5 orderline">
                                                <ion-badge [style.background]="orderLine.ProductColorCode">
                                                    {{orderLine.ProductShortCode}}</ion-badge>
                                                {{orderLine.Quantity}}
                                            </span>
                                            <div class="small-font internal" *ngIf="order.DeliveryInfo">
                                                <ion-text color="danger">{{order.DeliveryInfo}}</ion-text>
                                            </div>
                                        </ion-col>
                                        <ion-col size-xs="2" class="ion-text-right">
                                            <ion-button (click)="tildel(order)" *ngIf="order.AssignedDriver == 0"
                                                fill="solid" [disabled]="!currentDriver" [style.color]="'primary'"
                                                class="plukk-apne">
                                                <ion-icon name="person-outline"></ion-icon> &nbsp; {{
                                                'OrderAssignment.tildel' |
                                                translate }}
                                            </ion-button>
                                            <ion-button (click)="unassignOrder(order)" *ngIf="order.AssignedDriver != 0"
                                                [style.color]="'primary'" fill="solid">
                                                <ion-icon name="close-circle-outline"></ion-icon> &nbsp; {{
                                                'OrderAssignment.slippOrdre' | translate }}
                                            </ion-button>
                                        </ion-col>
                                            <!-- EXPANDED VIEW -->
                                            <ion-col size-sm="12" *ngIf="order.expandedView" [class.expandedOrder]="!isOdd" class="orderlistExpanded">
                                                <ion-list lines="none">
                                                <ion-item>
                                                    <ion-row class="w-100">

                                                    <!-- EXPANDED VIEW - DATES -->
                                                    <ion-col size-xs="12" size-sm="4">
                                                        <p>
                                                        <ion-icon name="calendar-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                        <ion-text class="upper bold"> <span>{{ 'OrdreDetails.leveringsdato' | translate }}</span></ion-text>
                                                        </p>
                                                        <span class="detailsIconText">{{order.DeliveryDate | date:dateFormat}}

                                                        <span *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"> /
                                                        <ion-icon name="alert-circle-outline" class="circle vertical-text-bottom" color="danger"></ion-icon>
                                                        {{order.ConfirmedDeliveryDate | date:dateFormat}}</span>
                                                        </span>
                                                    </ion-col>
                                                    
                                                    <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                                                    <ion-col size-xs="12" size-sm="4">
                                                        <p>
                                                        <ion-icon name="people-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                        <ion-text class="upper bold"><span>{{ 'OrdreDetails.CustomerBuyer' | translate }}</span></ion-text>
                                                        </p> 
                                                        <span class="detailsIconText">{{order.CustomerBuyer}}</span>

                                                    </ion-col>

                                                    <!-- EXPANDED VIEW - OUR SELLER -->
                                                    <ion-col size-xs="12" size-sm="4">
                                                        <p>
                                                        <ion-icon name="person-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon> 
                                                        <ion-text class="upper bold"><span>{{ 'OrdreDetails.OurSeller' | translate }}</span></ion-text>
                                                        </p>
                                                        <span class="detailsIconText">{{order.Seller}}</span>
                                                    </ion-col>
                                                    </ion-row>
                                                </ion-item>

                                                <ion-item>
                                                    <ion-row class="w-100 margin-top-10">
                                                    <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                                                    <ion-col size-xs="12" size-sm="4">
                                                        
                                                        <p>
                                                            <ion-icon name="cube-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                            <ion-text class="upper bold"><span>{{ 'OrdreDetails.leveringsinfo' | translate }}</span></ion-text>
                                                        </p>
                                                        <ng-container *ngIf="order.TankInfo"><span class="detailsIconText">{{order.TankInfo}}</span>
                                                            <br />
                                                        </ng-container>
                                                        <ng-container *ngIf="order.DeliveryInfo"><span class="detailsIconText">{{order.DeliveryInfo}}</span>
                                                            <br />
                                                        </ng-container>
                                                        
                                                        
                                                    </ion-col>
                                                        <!--Phone onsite-->
                                                        <ion-col size-xs="12" size-sm="4">
                                                        <p>
                                                            <ion-icon name="call-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                            <ion-text class="upper bold"><span>{{ 'OrdreDetails.onSite' | translate }}</span></ion-text>
                                                        </p>
                                                        <span class="detailsIconText" appPhoneRegex [inputText]="order.PhoneOnSite"></span>

                                                        </ion-col>
                                                        <!--Phone manager-->
                                                        <ion-col size-xs="12" size-sm="4">
                                                        <p>
                                                            <ion-icon name="call-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                            <ion-text class="upper bold"><span>{{ 'OrdreDetails.manager' | translate }}</span></ion-text>
                                                        </p>
                                                        <span class="detailsIconText" appPhoneRegex [inputText]="order.PhoneManager"></span>

                                                        </ion-col>
                                                        
                                                    </ion-row>
                                            
                                                </ion-item>

                                                
                                                <ion-item *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite">
                                                    <ion-row class="w-100 margin-top-10">
                                                    
                                                        <!-- INTERNAL INFO-->
                                                        <ion-col size-xs="12" size-sm="4">
                                                        <ion-row *ngIf="order.InternalInfo != ''">
                                                            <ion-col size-xs="12">
                                                            <p>
                                                                <ion-icon name="chatbox-ellipses-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                                <ion-text class="upper bold"><span>{{ 'OrdreDetails.internInfo' | translate }}</span></ion-text>
                                                            </p>
                                                            <span class="detailsIconText" appPhoneRegex [inputText]="order.InternalInfo"></span>
                                                            </ion-col>
                                                        </ion-row>
                                                        </ion-col>

                                                        <!-- EXTERNAL INFO -->
                                                        <ion-col size-xs="12" size-sm="4">
                                                        <ion-row *ngIf="order.ExternalInfo != ''">
                                                            <ion-col size-xs="12">
                                                            <p>
                                                                <ion-icon name="chatbox-ellipses-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                                                <ion-text class="upper bold"><span>{{ 'OrdreDetails.externInfo' | translate }}</span></ion-text>
                                                            </p>
                                                            <span class="detailsIconText">{{order.ExternalInfo}}</span>
                                                            </ion-col>
                                                        </ion-row>
                                                        </ion-col>

                                                            <ion-col size-xs="12" size-sm="4">

                                                            </ion-col>
                                                    </ion-row>
                                                </ion-item>

                                                <!-- PRODUCT LIST -->
                                                <div class="text-small ion-margin-bottom ion-margin-start margin-top-10" *ngIf="order.Orderlines.length > 0">
                                                
                                                            <ion-row class="orderlistExpandProductHeading">
                                                                <ion-col size-xs="12" size-sm="5"><b>{{ 'OrdreDetails.produkt' | translate | uppercase }}</b></ion-col>
                                                                <ion-col size-xs="1"><b>{{ 'OrdreDetails.levert' | translate | uppercase }}</b></ion-col>
                                                                <ion-col size-xs="1"><b>{{ 'OrdreDetails.cost' | translate | uppercase }}</b></ion-col>
                                                                <ion-col size-xs="1"><b>{{ 'OrdreDetails.pris' | translate | uppercase }}</b></ion-col>
                                                                <ion-col size-xs="1"><b>{{ 'OrdreDetails.rab' | translate | uppercase }}</b></ion-col>
                                                                <ion-col size-xs="2"><b>{{ 'OrdreDetails.total' | translate | uppercase }}</b></ion-col>
                                                                <ion-col size-xs="1"><b>{{ 'OrdreDetails.DB' | translate | uppercase }}</b></ion-col>
                                                            </ion-row>
                                                            <ion-row *ngFor="let orderLine of order.Orderlines">
                                                                <ion-col size-xs="12" size-sm="5">
                                                                <div class="flex-parent">
                                                                    <ion-badge [style.background]="orderLine.ProductColorCode">{{orderLine.ProductShortCode}}</ion-badge>&nbsp;
                                                                        <span class="truncate-text">{{orderLine.Description}}</span> 
                                                                </div>
                                                                </ion-col>
                                                                <ion-col size-xs="1">
                                                                <span class="slight-big-font"><strong>{{orderLine.QuantityDelivered | number:'1.0-1'}}</strong></span>
                                                                </ion-col>
                                                                <ion-col size-xs="1">{{ orderLine.UnitCost | number:'1.1-3'}}</ion-col>
                                                                <ion-col size-xs="1">{{ orderLine.UnitPrice | number:'1.1-3'}}</ion-col>
                                                                <ion-col size-xs="1">{{ orderLine.UnitDiscount | number:'1.0-1'}}</ion-col>
                                                                <ion-col size-xs="2">{{ orderLine.OrderLineTotal | number:'1.0-0'}}</ion-col>
                                                                <ion-col size-xs="1">{{ orderLine.OrderLineTotal-(orderLine.UnitCost * orderLine.QuantityDelivered)| number:'1.0-0'}}</ion-col>
                                                            </ion-row>
                                                </div>
                                                </ion-list>
                                                
                                            </ion-col>
                                            <!-- END -->
                                                                                
                                    </ion-row>
                                </ion-col>
                            </ion-row>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>
<ion-footer class="ion-padding">
    <div class="hidden-sm-up primary litte-big-font footer-border">
        <ion-button fill="outline" (click)="expandFooterMenu()">
            <ion-icon name="menu-outline" class="ion-no-padding" color="primary"></ion-icon>
        </ion-button>
        <ion-button fill="outline" class="no-padding pull-right" (click)="visKunDagensChanged(null)">
            <ion-icon name="refresh-outline" color="primary"></ion-icon>
        </ion-button>
    </div>
    <div [hidden]="!expand">
        <div>
            <!--{{ 'Ordrer.mine' | translate }} <ion-toggle class="inline-block" color="success" [(ngModel)]="mine" (ionChange)="filterChanged($event)"></ion-toggle> &nbsp;&nbsp;-->
            {{ 'Ordrer.utildelteOrdrer' | translate }}
            <ion-toggle class="inline-block" color="success" [(ngModel)]="utildelteOrdrer"
                [disabled]="assignmentOrderView == AssignmentOrderView.VisSjaForensOrdrer"
                (ionChange)="filterChanged($event)"></ion-toggle> &nbsp;&nbsp;
            <!--{{ 'Ordrer.nerMeg' | translate }} <ion-toggle class="inline-block" color="success" [disabled]="latitude == null || longitude == null" [(ngModel)]="nearMeg" (ionChange)="filterChanged($event)"></ion-toggle> &nbsp;&nbsp;
        <input type="text" autocomplete="off" class="nearmeg-input" [(ngModel)]="nearMegDistance" (ngModelChange)="filterChanged($event)" [disabled]="latitude == null || longitude == null || !nearMeg" placeholder="{{ 'Ordrer.near' | translate }}" />&nbsp;&nbsp;-->
            {{ 'Ordrer.avgiftsfrie' | translate }}
            <ion-toggle class="inline-block" color="success" [(ngModel)]="avgiftsfrie"
                (ionChange)="filterChanged($event)">
            </ion-toggle>
            &nbsp;&nbsp; {{ 'Ordrer.visKunDagens' | translate }}
            <ion-toggle class="inline-block" color="success" [(ngModel)]="visKunDagens"
                (click)="visKunDagensChanged($event)"></ion-toggle>
            &nbsp;&nbsp;
            <div class="pull-right-md">
                <span class="display-inline-block">
                    <strong>{{ 'Ordrer.prioritet' | translate }}</strong> &nbsp;&nbsp;
                    <select [(ngModel)]="prioritet" (ngModelChange)="filterChanged($event)" class="margin-boottom-4">
                        <option [ngValue]="-1">{{ 'Ordrer.alle' | translate }}</option>
                        <option [ngValue]="0">{{ 'Ordrer.normal' | translate }}</option>
                        <option [ngValue]="1">{{ 'Ordrer.urgent' | translate }}</option>
                    </select>&nbsp;&nbsp;
                </span>
                <span class="display-inline-block">
                    <strong class="text-small">{{ 'Ordrer.company' | translate }}</strong>&nbsp;&nbsp;
                    <select [(ngModel)]="company" (ngModelChange)="visKunDagensChanged(null)" class="margin-boottom-4">
                        <option value="0">{{ 'General.select' | translate }}</option>
                        <option *ngFor="let company of companies" value="{{company.CompanyNo}}">{{company.Name}}
                        </option>
                    </select>
                </span>
            </div>
            <div class="clear"></div>
        </div>
        <div class="footer-border hidden-xs"></div>
        <div class="primary litte-big-font">
            <ion-button fill="outline" class="no-padding"
                *ngIf="assignmentOrderView == AssignmentOrderView.VisOrdreListe"
                (click)="changeAssignmentOrderView(AssignmentOrderView.VisSjaForensOrdrer)">
                <ion-icon name="person-outline" color="primary"></ion-icon>&nbsp;
                <span class="black">{{ 'OrderAssignment.visSjaforensOrdrer' | translate }}</span>
            </ion-button>
            <ion-button fill="outline" class="no-padding"
                *ngIf="assignmentOrderView == AssignmentOrderView.VisSjaForensOrdrer"
                (click)="changeAssignmentOrderView(AssignmentOrderView.VisOrdreListe)">
                <ion-icon name="clipboard-outline" color="primary"></ion-icon>&nbsp;
                <span class="black">{{ 'OrderAssignment.visOrdreliste' | translate }}</span>
            </ion-button>
            <ion-button fill="outline" class="no-padding hidden-xs" (click)="visKunDagensChanged(null)">
                <ion-icon name="refresh" color="primary"></ion-icon>
            </ion-button>
            <div class="pull-right hidden-xs">
                <ion-button fill="outline" class="no-padding" routerLink="/orders">
                    <ion-icon name="book-outline" class="ion-no-padding" color="primary"></ion-icon>&nbsp; {{
                'OrderAssignment.avslutttildeling' | translate }}
                </ion-button>
            </div>
        </div>
    </div>
</ion-footer>