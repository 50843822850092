import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Handler } from 'leaflet';
import _ from 'lodash';

declare global {
    interface Date {
        isLeapYear: () => any;
        getDaysInMonth: () => any;
        addMonths: (value) => any;
    }

}

Date.prototype.isLeapYear = function() {
    const year = this.getFullYear();
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
};

Date.prototype.getDaysInMonth = function() {
    const year = this.getFullYear();
    const month = this.getMonth();
    return [31, (this.isLeapYear() ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.addMonths = function(value) {
    const date = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(date, this.getDaysInMonth()));
    return this;
};

@Injectable()
export class Utils {

    static isSameDateAs(date1: Date, date2: Date) {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    static addDays(date: Date, days): Date {
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
            date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }

    static orderBy(data: any, property: string, dir: string) {
        return _.orderBy(data, [property], [dir]);
    }

    static orderByList(data: any, property: any, dir: any) {
        return _.orderBy(data, property, dir);
    }



    static padNumber(no) {
        return no > 9 ? '' + no : '0' + no;
    }

    static calculateDistance(lat1: number, long1: number, lat2: number, long2: number): number {

        const r = 6371;

        const dLat: number = this.toRadian((lat2 - lat1));

        const dLon: number = this.toRadian((long2 - long1));

        lat1 = this.toRadian(lat1);

        lat2 = this.toRadian(lat2);

        const a: number = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) *
            Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

        const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d: number = r * c;

        return d;

    }

    static toRadian(value: number): number {
        return value * Math.PI / 180;
    }

    static RoundNumber(value:number)
    {
        return Math.round(value);
    }

    static async presentAlert(
        header: string,
        message: string,
        cancelButtonName: string,
        confirmButtonName: string,
        context: object,
        confirmAction: (a,b,c,d)=> {},
        cancelAction:(a,b,c)=>{},
        arg:any[]
      ) {
        
        let alertController:AlertController = new AlertController();
        const alert = await alertController.create({
          header,
          message,
          buttons: [
            {
              text: cancelButtonName,
              role: 'cancel',
              handler:cancelAction.bind(context, arg[0], arg[2],arg[3])
            }, {
              text: confirmButtonName,
              handler: confirmAction.bind(context, arg[0],arg[1],arg[2],arg[3])
            }
          ]
        });
        await alert.present();
    }

     static async presentAlertYesNo(
        header: string,
        message: string,
        cancelButtonName: string,
        confirmButtonName: string
      ) {
        let alertController:AlertController = new AlertController();
        const alert = await alertController.create({
          header,
          message,
          buttons: [
            {
              text: cancelButtonName,
              role: 'cancel',
              handler: () => {
                    alert.dismiss(false);
                    return false;
                }
            }, {
              text: confirmButtonName,
              role:'ok',
              handler: () => {
                    alert.dismiss(false);
                    return true;
                }
            }
          ]
        });
        let retValue = false;
        await alert.present();
        await alert.onDidDismiss().then((data) => {
        retValue = data.role == 'cancel'?false:true;
    })
    return retValue;
    }

}
