import { Component, OnInit } from '@angular/core';
import { NavController, LoadingController, ToastController, ModalController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { Utils } from '../../shared/utils';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BeholdningLagreConfirmComponent } from '../../components/beholdning-lagre-confirm/beholdning-lagre-confirm';
import { ConvertProductComponent } from '../../components/convert-product/convert-product';
import { Storage } from '@ionic/storage-angular';
import { Geolocation } from '@capacitor/geolocation';

@Component({
    selector: 'page-beholdning',
    templateUrl: 'beholdning.html',
    styleUrls: ['beholdning.scss']
})
export class BeholdningPage implements OnInit {
    inventoryList: any[];
    currentDate: any = new Date();
    totalNyttVolum = 0;
    totalQuantityInOrder = 0;
    totalQuantity = 0;
    totalAvvik = 0;
    deviationStatus: any;
    private inventoryDeliveryStats: any[];
    options: any;
    private chart: any;
    submitting = false;
    inventoryForm: FormGroup;
    submitAttempt = false;
    showStats = false;
    IsStandardLiter = true;
    temperature: number = null;
    depos: any[];
    dateFormat;
    currentLatitude = null;
    currentLongitude = null;
    expand = false;
        dateSelector = this.currentDate.toISOString();
        openModal = false;

    constructor(
        public navCtrl: NavController, private userService: UserService, private loadingController: LoadingController,
        private toastController: ToastController, private formBuilder: FormBuilder, private translateService: TranslateService,
        private geolocation: Geolocation, private modalCtrl: ModalController, private storage: Storage) {

        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });

        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        this.inventoryForm = formBuilder.group({
            comment: ['', []],
            loadingTemperature: ['', Validators.compose([Validators.required])],
            depot: ['', Validators.compose([Validators.required])],
        });
        this.storage.get(AppSettings.StorageKeys.Depot).then(data => {
            if (data) {
                this.getFormControl('depot').setValue(data);
            }
        });
        this.options = {
            spacing: [0, 0, 0, 0],
            xAxis: {
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            chart: {
                type: 'line',
                width: 460,
                height: 200
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: {
                enabled: false
            },
            series: [],
            tooltip: {
                formatter: function formatter() {
                    return this.y;
                }
            }
        };

        try {
            this.IsStandardLiter = !AppSettings.Vol2StdOff;
        } catch (Exception) { }


    }

    ionViewDidEnter() {
        this.loadData();
    }


    ngOnInit() {
        // this.loadData();
    }

    getFormControl(field) {
        return this.inventoryForm.get(field);
    }

    truckTrailerChanged() {
        this.loadData();
    }

    saveChart(chart) {
        this.chart = chart;
    }

    toggleStats() {
        this.showStats = !this.showStats;
    }

    nullstillAvvik() {
        this.submitAttempt = true;
        if (this.inventoryForm.valid) {

            const controls = this.inventoryForm.controls;

            this.submitting = true;
            const dateStr = (Utils.padNumber(this.currentDate.getMonth() + 1)) + '-'
                + (Utils.padNumber(this.currentDate.getDate())) + '-' + (Utils.padNumber(this.currentDate.getFullYear()));

            this.userService.saveDeviations({ Comment: this.getFormControl('comment').value, CurrentDateStr: dateStr }).subscribe(data => {
                this.submitting = false;
                if (data.OK === false) {
                    this.translateService.get('Error.grensenFor').subscribe(value => {
                        this.toastController.create({
                            message: value.format(data.Limit),
                            duration: 5000
                        }).then(toast => { toast.present(); });

                    });
                } else {
                    this.loadData();
                }
            });
        }
    }

    async lagreLasting() {
        this.submitAttempt = true;
        if (this.inventoryForm.valid) {
            const modal = await this.modalCtrl.create(
                {
                    component: BeholdningLagreConfirmComponent,
                    cssClass: 'beholdning-lagre-confirm',
                    componentProps: { inventoryList: this.inventoryList }
                });
            modal.present();

            modal.onDidDismiss().then(data => {
                if (data.data) {
                    const controls = this.inventoryForm.controls;
                    this.submitting = true;
                    const dateStr = (Utils.padNumber(this.currentDate.getMonth() + 1)) + '-' +
                        (Utils.padNumber(this.currentDate.getDate())) + '-' + (Utils.padNumber(this.currentDate.getFullYear()));

                    this.userService.saveTransactions({
                        Comment: this.getFormControl('comment').value, CurrentDateStr: dateStr,
                        InventoryList: this.inventoryList, LoadingTemp: this.getFormControl('loadingTemperature').value,
                        StdLitre: this.IsStandardLiter,
                        DepotNo: this.getFormControl('depot').value
                    }).subscribe(res => {
                        this.submitting = false;
                        this.storage.set(AppSettings.StorageKeys.Depot, this.getFormControl('depot').value);
                        AppSettings.LoadingTemp = this.getFormControl('loadingTemperature').value;
                        this.loadData();
                    });
                }
            });
        }
    }

    async loadData() {
        if (this.chart) {

            while (this.chart.series.length > 0) {
                this.chart.series[0].remove(false);
            }

            this.chart.redraw();
        }

        const commentControl = this.inventoryForm.get('comment');

        commentControl.setValidators([]);

        commentControl.updateValueAndValidity();

        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {
                this.userService.getCurrentInventory().subscribe(data => {
                    this.inventoryList = data;
                    this.updateTotals();
                    loader.dismiss();
                });
            });
        });

        // need not be inside loader since this is separate section

        this.userService.getDeviationStatus(this.currentDate).subscribe(data => {
            this.deviationStatus = data;
        });

        this.userService.getDepos().subscribe(data => {
            this.depos = data;
        });

        const posOptions = {
            enableHighAccuracy: false,
            timeout: 30000,
            maximumAge: 30000
        };

        await this.getCurrentLocation();

            this.userService.weatherLookup(this.currentLatitude, this.currentLongitude).subscribe(data => {
                this.temperature = data.temperature;
            });

       
        this.userService.getDeliveryStats(this.currentDate).subscribe(data => {
            const seriesCurrent = { name: 'I år', data: [], color: '#FFCE55', allowPointSelect: true };
            const seriesPrevious = { name: 'I fjor', data: [], color: '#4CD0B0', allowPointSelect: true };

            for (let index = 1; index <= 12; index++) {

                const item = _.find(data, { Month: index }) as any;

                let currentYear = 0;
                let previousYear = 0;

                if (item) {
                    currentYear = item.CurrentYear;
                    previousYear = item.PreviousYear;
                }

                seriesCurrent.data.push(currentYear);
                seriesPrevious.data.push(previousYear);

            }

            if (this.chart) {
                this.chart.addSeries(seriesCurrent);
                this.chart.addSeries(seriesPrevious);
            }

            this.inventoryDeliveryStats = data;
        });

    }

    async getCurrentLocation() {
    try {
        const position = await Geolocation.getCurrentPosition();
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      //  console.log(`Current position: ${position.coords.latitude}, ${position.coords.longitude}`);
    } catch (error) {
        console.error('Error getting location:', error);
        // Handle the error, for example, by showing an error message to the user
    }
}

    setTemperature() {
        this.getFormControl('loadingTemperature').setValue(this.temperature);
    }

    updateTotals() {
        this.totalNyttVolum = 0;
        this.totalQuantityInOrder = 0;
        this.totalQuantity = 0;
        this.totalAvvik = 0;
        let item;
        for (item of this.inventoryList) {
            this.totalNyttVolum += (item.Quantity + item.Liter);
            this.totalQuantityInOrder += item?.QuantityInOrder;
            this.totalQuantity += item.Quantity;
            this.totalAvvik += (item.Quantity + item.Liter - item.QuantityInOrder);
        }
    }

    literUpdated($event) {
        this.totalNyttVolum = 0;
        this.totalAvvik = 0;
        let isCommentRequired = false;
        let item;
        for (item of this.inventoryList) {
            if (item.Liter < 0) {
                isCommentRequired = true;
            }

            this.totalNyttVolum += (item.Quantity + item.Liter);
            this.totalAvvik += (item.Quantity + item.Liter - item.QuantityInOrder);
        }
        const commentControl = this.inventoryForm.get('comment');

        if (isCommentRequired === true) {
            commentControl.setValidators([Validators.required]);
        } else {
            commentControl.setValidators([]);
        }

        commentControl.updateValueAndValidity();
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.loadData();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.loadData();
    }

    setQty(qty: number) {
        if (!this.getFormControl('loadingTemperature').value) {
            this.getFormControl('loadingTemperature').setValue(0);
        }
        this.getFormControl('loadingTemperature').setValue(this.getFormControl('loadingTemperature').value + qty);
    }

    async convertProduct(item) {
        const inventoryList = _.cloneDeep(this.inventoryList);
        const index = inventoryList.findIndex(f => f.ProductNo === item.ProductNo);
        if (index !== -1) {
            inventoryList.splice(index, 1);
        }

        const modal = await this.modalCtrl.create({
            component: ConvertProductComponent,
            cssClass: 'convert-product',
            componentProps: { inventoryList, item }
        });
        modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.convertProduct(item.ProductNo, data.data.Product.ProductNo, data.data.Quantity, this.currentDate)
                            .subscribe(resp => {
                                loader.dismiss();
                                this.loadData();
                            });
                    });
                });
            }
        });
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    dateChangeEvent(event) {
      
            this.currentDate = new Date(event.detail.value);
            this.loadData();
        
            
    }
        closeModal(){
    this.openModal = false;
}
}
