<ion-content class="ion-padding">
    <div class="map-item">
        <iframe [src]="'https://kart.1881.no/?direction={' + currentLatitude + '|' + currentLongitude + '|START}{' + latitude + '|' + longitude + '|M%C3%85L}' | safe" class="driv-iframe"></iframe>
    </div>
    <div>
        <ion-buttons end>
            <ion-button fill="outline" (click)="dismiss()">
                <span>{{ 'General.lukk' | translate }}</span>
            </ion-button>
            <ion-button (click)="launchNativeNavigator()">
                <span>{{ 'DrivingInstructions.navigator' | translate }}</span>
            </ion-button>
        </ion-buttons>
    </div>
</ion-content>
