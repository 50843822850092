<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Menu.sooloOrders' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col class="fleet">
          <div class="page-title">
            <ng-container *ngIf="visKunDagens">
              <ion-icon
                name="calendar-outline"
                ion-text
                color="primary"
                class="litte-big-font vertical-middle"
                id="datepicker"
              ></ion-icon>

              <ion-modal trigger="datepicker" [cssClass]="'center'">
                <ng-template>
                  <ion-datetime
                    #picker
                    [(ngModel)]="dateSelector"
                    presentation="date"
                    (ionChange)="dateChangeEvent($event)"
                    size="cover"
                    [showDefaultButtons]="true"
                    doneText="{{ 'buttons.ok' | translate }}"
                    cancelText="{{ 'buttons.cancel' | translate }}"
                    first-day-of-week="1"
                    class="dateText"
                  >
                  </ion-datetime>
                </ng-template>
              </ion-modal>
              {{'Day.' + currentDate.getDay() | translate}}
              <span class="secondary">
                {{currentDate | date:dateFormat}}
                <ion-button
                  (click)="previousDay()"
                  class="no-padding button-no-text vertical-top ion-no-margin"
                  fill="clear"
                >
                  <ion-icon
                    color="lightFour"
                    slot="icon-only"
                    name="caret-back-circle"
                  ></ion-icon>
                </ion-button>
                <ion-button
                  (click)="nextDay()"
                  class="no-padding button-no-text vertical-top ion-no-margin"
                  fill="clear"
                >
                  <ion-icon
                    color="lightFour"
                    slot="icon-only"
                    name="caret-forward-circle"
                  ></ion-icon>
                </ion-button>
              </span>
            </ng-container>
          </div>
          <div
            class="map"
            *ngIf="mapLoading"
            leaflet
            [leafletOptions]="leafletOptions"
            [leafletLayers]="layers"
            (leafletMapReady)="onMapReady($event)"
          ></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div>
    {{ 'Ordrer.utildelteOrdrer' | translate }}
    <ion-toggle
      class="inline-block m-10"
      color="success"
      [(ngModel)]="utildelteOrdrer"
      (ionChange)="filterChanged($event)"
    ></ion-toggle>
    &nbsp;&nbsp; {{ 'Ordrer.nerMeg' | translate }}
    <ion-toggle
      class="inline-block m-10"
      color="success"
      [disabled]="latitude == null || longitude == null"
      [(ngModel)]="nearMeg"
      (ionChange)="filterChanged($event)"
    ></ion-toggle>
    &nbsp;&nbsp;
    <input
      type="number"
      autocomplete="off"
      class="nearmeg-input"
      [(ngModel)]="nearMegDistance"
      (ngModelChange)="filterChanged($event)"
      [disabled]="latitude == null || longitude == null || !nearMeg"
      placeholder="{{ 'Ordrer.near' | translate }}"
    />
    &nbsp;&nbsp; {{ 'Ordrer.visKunDagens' | translate }}
    <ion-toggle
      class="inline-block m-10"
      color="success"
      [(ngModel)]="visKunDagens"
      (ionChange)="visKunDagensChanged($event)"
    ></ion-toggle>
    &nbsp;&nbsp; {{ 'Ordrer.showAll' | translate }}
    <ion-toggle
      class="inline-block m-10"
      color="success"
      [(ngModel)]="showAll"
      (ionChange)="togglePins()"
    ></ion-toggle>
    &nbsp;&nbsp; {{ 'Ordrer.showInventory' | translate }}
    <ion-toggle
      class="inline-block m-10"
      color="success"
      [(ngModel)]="showInventory"
      (ionChange)="filterChanged($event)"
    ></ion-toggle>
    <div class="footer-border"></div>
    <div class="primary litte-big-font">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="visKunDagensChanged(null)"
      >
        <ion-icon name="refresh" color="primary"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-footer>
