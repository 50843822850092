<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'EgneTanker.internOrdre' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3" class="ion-text-center">
          <h3>{{ 'EgneTankerSelect.hvaOnskerDuAGjore' | translate }}</h3>
          <ion-list lines="none" class="ion-no-padding">
            <ion-item>
              <ion-button
                [fill]="transactionType != TransactionType.UnloadingAutoGas?'outline': 'solid'"
                (click)="setTransactionType(TransactionType.UnloadingAutoGas)"
                size="default"
                expand="block"
                class="ion-margin-bottom ion-margin-top"
                >{{ 'EgneTankerSelect.fylleEnAutomatstasjon' | translate
                }}</ion-button
              >
            </ion-item>
            <ion-item>
              <ion-button
                [fill]="transactionType != TransactionType.UnloadingToStorage?'outline': 'solid'"
                (click)="setTransactionType(TransactionType.UnloadingToStorage)"
                size="default"
                expand="block"
                class="ion-margin-bottom"
                >{{ 'EgneTankerSelect.fyllePAEnLagertank' | translate
                }}</ion-button
              >
            </ion-item>
            <ion-item>
              <ion-button
                [fill]="transactionType != TransactionType.LoadingFromStorage?'outline': 'solid'"
                (click)="setTransactionType(TransactionType.LoadingFromStorage)"
                size="default"
                expand="block"
                class="ion-margin-bottom"
                >{{ 'EgneTankerSelect.lasteFraEnLagertank' | translate
                }}</ion-button
              >
            </ion-item>
            <ion-item>
              <ion-button
                [fill]="transactionType != TransactionType.LoadingFromTruck?'outline': 'solid'"
                (click)="setTransactionType(TransactionType.LoadingFromTruck)"
                size="default"
                expand="block"
                class="ion-margin-bottom"
                >{{ 'EgneTankerSelect.overforeFraLastebil' | translate
                }}</ion-button
              >
            </ion-item>
          </ion-list>
          <h3>
            {{ 'EgneTankerSelect.hvilkenDatoBleDetteUtfort' | translate }}
          </h3>
          <div class="page-title">
            <ion-icon
              name="calendar-outline"
              ion-text
              color="primary"
              class="vertical-middle m-r-10"
              id="datepicker"
              (click)="openModal = true"
            ></ion-icon>

            <ion-modal
              [cssClass]="'center'"
              id="dtOrder"
              [isOpen]="openModal"
              (didDismiss)="closeModal()"
            >
              <ng-template>
                <ion-datetime
                  #picker
                  [(ngModel)]="dateSelector"
                  (ionChange)="dateChangeEvent($event)"
                  presentation="date"
                  first-day-of-week="1"
                  size="cover"
                  [showDefaultButtons]="true"
                  doneText="{{ 'buttons.ok' | translate }}"
                  cancelText="{{ 'buttons.cancel' | translate }}"
                  class=""
                >
                </ion-datetime>
              </ng-template>
            </ion-modal>
            <span (click)="openModal = true"
              >{{'Day.' + currentDate.getDay() | translate}}</span
            >
            <span class="secondary">
              {{currentDate | date:dateFormat}}
              <ion-button
                (click)="previousDay()"
                class="no-padding button-no-text vertical-top ion-no-margin"
                fill="clear"
              >
                <ion-icon
                  color="lightFour"
                  slot="icon-only"
                  name="caret-back-circle"
                ></ion-icon>
              </ion-button>
              <ion-button
                (click)="nextDay()"
                class="no-padding button-no-text vertical-top ion-no-margin"
                fill="clear"
              >
                <ion-icon
                  color="lightFour"
                  slot="icon-only"
                  name="caret-forward-circle"
                ></ion-icon>
              </ion-button>
            </span>
          </div>
          <br />
          <ion-button fill="solid" color="primary" (click)="next()"
            >{{ 'EgneTankerSelect.neste' | translate }}</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
