import { Component } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
    selector: 'truck-trailer-change',
    templateUrl: 'truck-trailer-change.html',
    styleUrls: ['truck-trailer-change.scss']
})
export class TruckTrailerChangeComponent {
    submitAttempt = false;
    submitting = false;
    trucks: any;
    trailers: any;
    trailerForm: FormGroup;

    constructor(
        public modalCtrl: ModalController, private userService: UserService, private translateService: TranslateService,
        private loadingController: LoadingController, private formBuilder: FormBuilder) {

        this.trailerForm = formBuilder.group({
            truckID: ['', Validators.compose([Validators.required])],
            trailerID: ['', Validators.compose([Validators.required])]
        });

        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {
                // first() is called only then forkJoin would be called. Additionally here unsubscribe is not needed as it is first()
                forkJoin([this.userService.getCurrentTruckAndTrailer().pipe(first()),
                this.userService.getTrailersAndTrucks()]).subscribe(data => {
                    this.trailerForm.get('truckID').setValue(data[0].Truck.ID);
                    this.trailerForm.get('trailerID').setValue(data[0].Trailer.ID);

                    this.trucks = data[1].Trucks;
                    this.trailers = data[1].Trailers;
                    loader.dismiss();
                });
            });

        });
    }

    saveTrailerAndTruck() {
        this.submitAttempt = true;

        if (this.trailerForm.valid) {
            const controls = this.trailerForm.controls;
            this.submitting = true;
            const truckID = this.trailerForm.get('truckID').value;
            const trailerID = this.trailerForm.get('trailerID').value;

            this.userService.saveTrailerAndTruck(truckID, trailerID).subscribe(data => {
                this.submitting = false;
                this.modalCtrl.dismiss(true);
            });
        }
    }

    dismiss() {
        this.modalCtrl.dismiss(false);
    }
}
