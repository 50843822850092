<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'NewOrder.nyOrdre' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <form [formGroup]="detailsForm">
        <ion-row>
          <ion-col size-xs="12" size-md="6" [hidden]="isHidden">
            <!--Customer-->
            <ion-list lines="none">
              <ion-item class="text-small ion-no-padding">
                <!--[(ngModel)]="customer"-->
                <ion-label>
                  <p class="h6">
                    <ion-icon
                      name="person-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<ion-text color="primary">
                      <span>{{ 'NewOrder.kunde' | translate }}</span></ion-text
                    >
                  </p>
                </ion-label>
              </ion-item>
              <div class="address">
                <mat-form-field appearance="fill">
                  <mat-label></mat-label>
                  <input
                    type="text"
                    placeholder="{{ 'NewOrder.customerName' | translate }}"
                    matInput
                    [formControl]="customerCtrl"
                    [matAutocomplete]="auto"
                    class="input"
                    #customerComplete
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option
                      *ngFor="let customer of filteredCustomers | async"
                      [value]="customer"
                    >
                      {{ customer.name }} - {{ customer.company }} - {{
                      customer.description }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!detailsForm.controls.customerName.valid && (detailsForm.controls.customerName.dirty || submitAttempt)"
              >
                <ion-text
                  color="danger"
                  *ngIf="detailsForm.controls.customerName.errors.required"
                >
                  <p>
                    {{ 'NewOrder.validationcustomerNameRequired' | translate }}
                  </p>
                </ion-text>
                <ion-text
                  color="danger"
                  *ngIf="detailsForm.controls.customerName.errors.incorrect"
                >
                  <p>{{'NewOrder.customerBlockedStatus' | translate }}</p>
                </ion-text>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress1' | translate }}"
                  formControlName="customerAddress1"
                />
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="!detailsForm.controls.customerAddress1.valid && (detailsForm.controls.customerAddress1.dirty || submitAttempt)"
              >
                <ion-text color="danger">
                  <p>{{ 'NewOrder.validationAddress1Required' | translate }}</p>
                </ion-text>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress2' | translate }}"
                  formControlName="customerAddress2"
                />
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <ion-row class="completer-row2">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <input
                      type="text"
                      placeholder="{{ 'NewOrder.zip' | translate }}"
                      formControlName="customerPostalCode"
                    />
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <div class="city">
                      <input
                        type="text"
                        placeholder="{{ 'NewOrder.city' | translate }}"
                        formControlName="customerCity"
                      />
                    </div>
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-item
                class="item-validation ion-no-padding"
                *ngIf="(!detailsForm.controls.customerCity.valid || !detailsForm.controls.customerPostalCode.valid) && (detailsForm.controls.customerCity.dirty || detailsForm.controls.customerPostalCode.dirty || submitAttempt)"
              >
                <ion-row class="completer-row2">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        *ngIf="!detailsForm.controls.customerPostalCode.valid && (detailsForm.controls.customerPostalCode.dirty || submitAttempt)"
                      >
                        {{ 'NewOrder.validationZipRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <ion-text color="danger">
                      <p
                        class="cityVal"
                        *ngIf="!detailsForm.controls.customerCity.valid && (detailsForm.controls.customerCity.dirty || submitAttempt)"
                      >
                        {{ 'NewOrder.validationCityRequired' | translate }}
                      </p>
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-item>

              <ion-item class="text-small ion-no-padding">
                <select id="priceAgreements" formControlName="priceAgreementID">
                  <option value="">
                    {{ 'NewOrder.priceAgreement' | translate }}
                  </option>
                  <option
                    *ngFor="let item of priceAgreements"
                    value="{{item.ID}}"
                  >
                    {{item.Description}}
                  </option>
                </select>
                &nbsp;
                <select id="transporters" formControlName="transporterID">
                  <option value="">
                    {{ 'NewOrder.transporter' | translate }}
                  </option>
                  <option *ngFor="let item of transporters" value="{{item.ID}}">
                    {{item.Description}}
                  </option>
                </select>
              </ion-item>
            </ion-list>

            <!--Delivery address-->
            <ion-list lines="none">
              <ion-item class="text-small address ion-no-padding z-index">
                <ion-label>
                  <p class="h6">
                    <ion-icon
                      name="location-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<ion-text color="primary"
                      ><span
                        >{{ 'NewOrder.leveringsadresse' | translate }}</span
                      ></ion-text
                    >
                  </p>
                </ion-label>
              </ion-item>
              <div class="completer-container address">
                <mat-form-field appearance="fill">
                  <mat-label></mat-label>
                  <input
                    type="text"
                    placeholder="Leveringsadresse søk"
                    matInput
                    [formControl]="addressCtrl"
                    [matAutocomplete]="auto"
                    #addressComplete
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                  >
                    <mat-option
                      *ngFor="let address of filteredAddresses | async"
                      [value]="address"
                    >
                      {{ address.name }} - {{ address.street }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <ion-button
                  fill="solid"
                  (click)="clearAddress()"
                  class="locate"
                >
                  <ion-icon name="trash-outline"></ion-icon>
                </ion-button>
                <div class="clear"></div>
              </div>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress1' | translate }}"
                  formControlName="addressAddress1"
                />
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewOrder.customerAddress2' | translate }}"
                  formControlName="addressAddress2"
                />
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <ion-row class="completer-row2">
                  <ion-col size-xs="3" class="ion-no-padding">
                    <input
                      type="text"
                      placeholder="{{ 'NewOrder.zip' | translate }}"
                      formControlName="addressZip"
                    />
                  </ion-col>
                  <ion-col size-xs="9" class="ion-no-padding">
                    <input
                      type="text"
                      placeholder="{{ 'NewOrder.city' | translate }}"
                      class="city"
                      formControlName="addressCity"
                    />
                  </ion-col>
                </ion-row>
              </ion-item>
              <ion-item class="text-small ion-no-padding">
                <input
                  type="text"
                  placeholder="{{ 'NewCustomer.gps' | translate }}"
                  formControlName="gps"
                />
              </ion-item>
            </ion-list>
          </ion-col>

          <ion-col size-xs="12" size-md="6" [hidden]="isHidden">
            <!--Contact-->

            <ion-row
              ><ion-col>
                <ion-label>
                  <p class="h6">
                    <ion-icon
                      name="call-outline"
                      color="lightFive"
                      class="vertical-text-bottom"
                    ></ion-icon>
                    &nbsp;
                    <ion-text color="primary"
                      ><span
                        >{{ 'OrdreDetails.kontaktinfo' | translate }}</span
                      ></ion-text
                    >
                  </p>
                </ion-label>
              </ion-col>
            </ion-row>
            <!--Buyer-->
            <ion-row>
              <ion-col class="text-small ion-no-padding">
                <ion-label>
                  <ion-row>
                    <b>{{ 'NewOrder.nameOfPurchaser' | translate }}</b>
                    <br />
                    <input
                      type="text"
                      formControlName="customerBuyer"
                      class="m-t-5"
                    />
                  </ion-row>
                </ion-label>
              </ion-col>
              <ion-col
                class="item-validation ion-no-padding"
                *ngIf="!detailsForm.controls.customerBuyer.valid && (detailsForm.controls.customerBuyer.dirty || submitAttempt)"
              >
                <ion-text color="danger">
                  <p>
                    {{ 'NewOrder.validationCustomerBuyerRequired' | translate }}
                  </p>
                </ion-text>
              </ion-col>
            </ion-row>

            <!--Email-->
            <ion-col class="text-small ion-padding-bottom">
              <ion-label>
                <ion-row>
                  <ion-col class="ion-no-padding" size-lg="6">
                    <div>
                      <ion-row>
                        <b>{{ 'OrdreDetails.email' | translate }}</b>
                        <br />
                        <input
                          type="text"
                          formControlName="email"
                          class="m-t-5"
                        />
                        <span
                          class="item-validation ion-no-padding"
                          *ngIf="!detailsForm.controls.email.valid && (detailsForm.controls.email.dirty || submitAttempt)"
                        >
                          <ion-text color="danger">
                            <p ion-text color="danger">
                              {{ 'NewOrder.validationEmail' | translate }}
                            </p>
                          </ion-text>
                        </span>
                      </ion-row>
                    </div>
                  </ion-col>

                  <ion-col class="ion-no-padding" size-lg="6">
                    <div style="padding-left: 5px">
                      <ion-row>
                        <b>{{ 'OrdreDetails.emailDelivery' | translate }}</b>
                        <br />
                        <input
                          type="text"
                          formControlName="DeliveryEmail"
                          class="m-t-5"
                        />
                        <span
                          class="item-validation ion-no-padding"
                          *ngIf="!detailsForm.controls.DeliveryEmail.valid && (detailsForm.controls.DeliveryEmail.dirty || submitAttempt)"
                        >
                          <ion-text color="danger">
                            <p ion-text color="danger">
                              {{ 'NewOrder.validationEmail' | translate }}
                            </p>
                          </ion-text>
                        </span>
                      </ion-row>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-label>
            </ion-col>

            <!--PhoneOn site-->
            <ion-col class="text-small ion-no-padding">
              <ion-label>
                <ion-row>
                  <!--Phone on site site-->
                  <ion-col class="ion-no-padding" size-lg="6">
                    <div class="ion-padding-bottom">
                      <b>{{ 'OrdreDetails.on-site' | translate }}</b>
                      <br />
                      <input
                        type="text"
                        formControlName="phoneOnSite"
                        class="m-t-5"
                      />
                    </div>
                  </ion-col>
                  <!--Phone manager site-->
                  <ion-col
                    class="ion-no-padding"
                    size-lg="6"
                    style="padding-left: 5px"
                  >
                    <div class="ion-padding-bottom">
                      <b>{{ 'OrdreDetails.project-manager' | translate }}</b>
                      <br />
                      <input
                        type="text"
                        formControlName="phoneManager"
                        class="m-t-5"
                      />
                    </div>
                  </ion-col>
                </ion-row>
              </ion-label>
            </ion-col>
            <!--Customer ref-->
            <ion-col class="text-small ion-no-padding">
              <ion-label>
                <ion-row>
                  <!--Customer ref-->
                  <ion-col class="ion-no-padding" size-lg="6">
                    <div class="ion-padding-bottom">
                      <b>{{ 'NewOrder.customerReference' | translate }}</b>
                      <br />
                      <input
                        type="text"
                        formControlName="customerReference"
                        class="m-t-5"
                      />
                    </div>
                  </ion-col>
                  <!--Customer PO-->
                  <ion-col
                    class="ion-no-padding"
                    size-lg="6"
                    style="padding-left: 5px"
                  >
                    <div class="ion-padding-bottom">
                      <b
                        >{{ 'OrdreDetails.customerPurchaseOrderNo' | translate
                        }}</b
                      >
                      <br />
                      <input
                        type="text"
                        formControlName="customerPurchaseOrderNo"
                        class="m-t-5"
                      />
                    </div>
                  </ion-col>
                </ion-row>
              </ion-label>
            </ion-col>

            <!--Delivery info-->
            <ion-col class="text-small ion-no-padding">
              <ion-label>
                <ion-row class="ion-padding-bottom">
                  <b>{{ 'OrdreDetails.leveringsinfo' | translate }}</b>
                  <br />
                  <input
                    type="text"
                    formControlName="deliveryInfo"
                    class="m-t-5"
                  />
                </ion-row>
              </ion-label>
            </ion-col>
            <ion-col class="text-small ion-no-padding">
              <ion-row class="ion-no-padding">
                <!--Internal info-->
                <ion-col class="text-small ion-no-padding" size-lg="6">
                  <ion-label>
                    <ion-row class="ion-padding-bottom">
                      <b>{{ 'OrdreDetails.internInfo' | translate }}</b>
                      <br />
                      <input
                        type="text"
                        formControlName="internalInfo"
                        class="m-t-5"
                      />
                    </ion-row>
                  </ion-label>
                </ion-col>

                <!--Tankinfo-->
                <ion-col
                  size-lg="6"
                  class="text-small ion-no-padding"
                  style="padding-left: 5px"
                >
                  <b>{{ 'OrdreDetails.tankInfo' | translate }}</b>
                  <br />
                  <input type="text" formControlName="tankInfo" class="m-t-5" />
                </ion-col>
              </ion-row>
            </ion-col>

            <!--Prg by-->
            <ion-col class="text-small ion-no-padding">
              <ion-label>
                <ion-row class="ion-padding-bottom">
                  <b>{{ 'OrdreDetails.organizationNo' | translate }}</b>
                  <br />
                  <input
                    type="text"
                    formControlName="organizationNo"
                    class="m-t-5"
                  />
                </ion-row>
              </ion-label>
            </ion-col>

            <ion-list lines="none">
              <!--Delivery date-->
              <ion-item class="ion-no-padding">
                <ion-label color="primary">
                  <p>
                    <ion-icon
                      name="calendar-outline"
                      color="lightFive"
                    ></ion-icon>
                    &nbsp;<span
                      >{{ 'NewOrder.leveringsdato' | translate }}</span
                    >
                  </p>
                </ion-label>
              </ion-item>

              <strong
                [class.biggest-font]="userService.fontSettings==4"
                [class.bigger-font]="userService.fontSettings==2"
                >{{getFormControl('deliveryDate').value |
                date:dateFormat}}</strong
              >
              <!--Delivery date-->
              &nbsp;&nbsp;
              <ion-button
                class="vertical-middle"
                size="small"
                fill="outline"
                (click)="openModal = true"
              >
                <ion-modal
                  [cssClass]="'center'"
                  [isOpen]="openModal"
                  (didDismiss)="closeModal()"
                >
                  <ng-template>
                    <ion-datetime
                      #picker
                      presentation="date"
                      formControlName="deliveryDate"
                      size="cover"
                      [showDefaultButtons]="true"
                      doneText="{{ 'buttons.ok' | translate }}"
                      cancelText="{{ 'buttons.cancel' | translate }}"
                      class="dateText"
                      first-day-of-week="1"
                    >
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
                <ion-icon name="calendar-outline" slot="start"></ion-icon
                >&nbsp;{{ 'General.edit' | translate }}
              </ion-button>
            </ion-list>
            <div>
              <ion-item lines="none">
                <ion-checkbox
                  class="ion-no-margin"
                  formControlName="additionalProductsDelivered"
                  (ionChange)="additionalProductsDeliveredChanged()"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'NewOrder.andreProdukterErUtlevert' | translate
                  }}</ion-label
                >
              </ion-item>
              <ion-item
                lines="none"
                *ngIf="VolumeToStandardTruck && !VolumeToStandardOff"
              >
                <ion-checkbox
                  class="ion-no-margin"
                  [(ngModel)]="DoNotCalcVolToStandard"
                  [ngModelOptions]="{standalone: true}"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'OrdreDetails.DoNotCalc' | translate }}</ion-label
                >
              </ion-item>
              <ion-item lines="none" [hidden]="true">
                <ion-checkbox
                  class="ion-no-margin"
                  [(ngModel)]="DoNotSendGPS"
                  [ngModelOptions]="{standalone: true}"
                ></ion-checkbox>
                <ion-label
                  >&nbsp;{{ 'OrdreDetails.DoNotSendGPS' | translate
                  }}</ion-label
                >
              </ion-item>
            </div>
            <div class="clear"></div>
          </ion-col>

          <!--EXTERNAL COMMENTS-->
          <ion-col size-xs="12" size-md="6">
            <ion-list lines="none">
              <ion-item class="text-small ion-no-padding">
                <ion-label>
                  <p class="h6">
                    <ion-icon
                      name="chatbox-outline"
                      class="vertical-text-bottom"
                      color="lightFive"
                    >
                    </ion-icon>
                    &nbsp;
                    <ion-text color="primary">
                      <span
                        >{{ 'OrdreDetails.kommentar' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <div class="padding-left">
                    <textarea
                      rows="2"
                      formControlName="orderComments"
                    ></textarea>
                    <ion-item
                      class="item-validation text-small ion-no-padding"
                      *ngIf="!detailsForm.controls.orderComments.valid"
                    >
                      <ion-text color="danger">
                        <p ion-text color="danger">
                          {{ 'OrdreDetails.validationComments' | translate }}
                        </p>
                      </ion-text>
                    </ion-item>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>

          <ion-col size-xs="12" size-md="6">
            <ion-list lines="none">
              <!---INTERNAL COMMENTS-->
              <ion-item class="text-small ion-no-padding">
                <ion-label>
                  <p class="h6">
                    <ion-icon
                      name="chatbox-outline"
                      class="vertical-text-bottom"
                      color="lightFive"
                    >
                    </ion-icon>
                    &nbsp;
                    <ion-text color="primary">
                      <span
                        >{{ 'OrdreDetails.internkommentar' | translate }}</span
                      ></ion-text
                    >
                  </p>
                  <div class="padding-left">
                    <textarea
                      rows="2"
                      formControlName="orderInternalComments"
                    ></textarea>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-xs="12">
            <div class="ion-margin-top">
              <p class="pull-left-md">
                <ion-icon name="flame-outline" color="lightFive"></ion-icon>
                <ion-text color="primary"
                  ><span ion-text
                    >{{ 'NewOrder.produkter' | translate }}</span
                  ></ion-text
                >
              </p>
              <ion-button
                size="default"
                class="no-padding pull-right-md ion-margin-bottom"
                (click)="leggTilProdukt()"
              >
                <ion-icon name="add-circle-outline"></ion-icon>&nbsp;
                <span>{{ 'NewOrder.leggTilProdukt' | translate }}</span>
              </ion-button>
              <div class="clear"></div>
              <div class="overflow-x">
                <div class="product-grid">
                  <ion-row class="grid-header">
                    <ion-col size-xs="3"
                      >{{ 'OrdreDetails.produkt' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.bestilt' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.levert' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.cost' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.pris' | translate }}</ion-col
                    >
                    <ion-col size-xs="1">
                      {{ 'OrdreDetails.freight' | translate | uppercase }}
                    </ion-col>
                    <ion-col size-xs="1"
                      >{{ 'OrdreDetails.rab' | translate }}</ion-col
                    >
                    <ion-col size-xs="2"
                      >{{ 'OrdreDetails.total' | translate }}</ion-col
                    >
                    <ion-col size-xs="1"></ion-col>
                  </ion-row>
                  <ion-row
                    *ngFor="let orderLine of orderLines;let isOdd = odd; let i = index"
                    [class.odd]="isOdd"
                    formGroupName="orderLines"
                    class="text-small"
                  >
                    <ion-col
                      class="cursor-pointer litte-big-font-2"
                      size-xs="3"
                      size-md="3"
                      appClickOutside
                      (clickOutside)="changeElement($event,orderLine)"
                    >
                      <div
                        [hidden]="orderLine.changeProduct"
                        class="flex-parent"
                      >
                        <ion-badge
                          [style.background]="orderLine.ProductColorCode"
                        >
                          {{orderLine.ProductShortCode}}</ion-badge
                        >&nbsp;
                        <span class="truncate-text"
                          >{{orderLine.Description}}</span
                        >
                      </div>
                      <div [hidden]="!orderLine.changeProduct">
                        <select
                          *ngIf="!allowDescriptionChange"
                          id="productNo"
                          formControlName="{{'productNo' + orderLine.LineNo}}"
                          (change)="selectProduct($event,orderLine)"
                          (blur)="selectProduct($event,orderLine)"
                        >
                          <option value="">
                            {{ 'General.select' | translate }}
                          </option>
                          <option
                            *ngFor="let product of products"
                            value="{{product.ProductNo}}"
                          >
                            {{product.Description}}
                          </option>
                        </select>
                        <input
                          type="text"
                          formControlName="{{'productNo' + orderLine.LineNo}}"
                          (change)="selectProduct($event,orderLine)"
                          *ngIf="allowDescriptionChange"
                          class="border-input litte-big-font-2 ion-no-margin"
                        />
                        <ng-container
                          *ngIf="!detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).dirty || submitAttempt))"
                        >
                          <ion-text color="danger"
                            ><span
                              *ngIf="detailsForm.controls.orderLines.get('productNo' + orderLine.LineNo).errors.required"
                            >
                              {{'OrdreDetails.descriptionRequired' | translate
                              }}</span
                            >
                          </ion-text>
                        </ng-container>
                      </div>
                    </ion-col>
                    <ion-col size-xs="1" size-md="1" class="litte-big-font-2">
                      <div class="inline-block bestilt">
                        {{orderLine.Quantity}}
                      </div>
                    </ion-col>
                    <ion-col size-xs="3" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        (keyup)="setValue(orderLine, 'quantity')"
                        (click)="setValue(orderLine, 'quantity')"
                        formControlName="{{'quantity' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('quantity' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>
                    <ion-col size-xs="3" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        (keyup)="setValue(orderLine, 'cost')"
                        (click)="setValue(orderLine, 'cost')"
                        formControlName="{{'cost' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('cost' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>
                    <ion-col size-xs="2" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        (keyup)="setValue(orderLine, 'price')"
                        (click)="setValue(orderLine, 'price')"
                        formControlName="{{'price' + orderLine.LineNo}}"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('price' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('price' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('price' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('price' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>

                    <ion-col size-xs="2" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        formControlName="{{'UnitFreightCost' + orderLine.LineNo}}"
                        (keyup)="setValue(orderLine, 'UnitFreightCost')"
                        (click)="setValue(orderLine, 'UnitFreightCost')"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('UnitFreightCost' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>

                    <ion-col size-xs="2" size-md="1">
                      <input
                        type="number"
                        appRemoveZeroOnFocus
                        class="border-input ion-no-margin"
                        formControlName="{{'discount' + orderLine.LineNo}}"
                        (keyup)="setValue(orderLine, 'discount')"
                        (click)="setValue(orderLine, 'discount')"
                        ng-pattern="[0-9]+([\,|\.][0-9]+)?"
                        step="any"
                      />
                      <ng-container
                        *ngIf="!detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).valid && ((detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).dirty || submitAttempt))"
                      >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).errors.pattern"
                          >
                            {{'OrdreDetails.onlyNumbers' | translate }}</span
                          ></ion-text
                        >
                        <ion-text color="danger"
                          ><span
                            *ngIf="detailsForm.controls.orderLines.get('discount' + orderLine.LineNo).errors.required"
                          >
                            {{'OrdreDetails.quantityRequired' | translate
                            }}</span
                          >
                        </ion-text>
                      </ng-container>
                    </ion-col>

                    <ion-col size-xs="6" size-md="2"
                      >{{orderLine.OrderLineTotal | number:'1.2-2'}}</ion-col
                    >
                    <ion-col size-xs="6" size-md="1" class="ion-no-padding">
                      <ion-icon
                        name="trash-outline"
                        color="primary"
                        class="icon-size cursor-pointer"
                        (click)="showConfirm(orderLine)"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                  <ion-row class="m-t-5">
                    <ion-col
                      size-xs="9"
                      size-md="9"
                      class="bigger-font ion-text-right"
                    >
                      <ion-text color="black">
                        <b>{{'OrdreDetails.orderTotal' | translate }}</b
                        >&nbsp;</ion-text
                      >
                    </ion-col>
                    <ion-col size-xs="3" size-md="3" class="bigger-font">
                      <ion-text color="black"
                        ><b>{{ totalOrder | number:'1.2-2'}}</b></ion-text
                      >
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="primary litte-big-font footer-border">
    <div class="hidden-sm-up">
      <ion-button fill="outline" (click)="expandFooterMenu()">
        <ion-icon
          name="menu-outline"
          class="ion-no-padding"
          color="primary"
        ></ion-icon>
      </ion-button>
      <ion-button
        class="no-padding pull-right"
        (click)="createNewOrder()"
        [disabled]="!detailsForm.valid"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'NewOrder.ferdigmeldOrdre' | translate }}</span>
      </ion-button>
    </div>
    <div [hidden]="!expand">
      <ion-button
        fill="outline"
        class="no-padding"
        (click)="navigateToOrdersList()"
      >
        <ion-icon name="book-outline" color="primary"></ion-icon>
        &nbsp; <span class="black">{{ 'NewOrder.tilbake' | translate }}</span>
      </ion-button>

      <ion-button
        fill="outline"
        [hidden]="isHidden"
        class="no-padding"
        (click)="showhide()"
        size="default"
      >
        <ion-icon name="caret-up-circle-outline"></ion-icon>
        &nbsp;
        <span class="black">{{ 'OrdreDetails.HideContact' | translate}}</span>
      </ion-button>

      <ion-button
        fill="outline"
        [hidden]="!isHidden"
        size="default"
        class="no-padding"
        (click)="showhide()"
      >
        <ion-icon name="caret-down-circle-outline"></ion-icon>
        &nbsp;
        <span class="black">{{ 'OrdreDetails.ShowContact' | translate}}</span>
      </ion-button>

      <ion-button
        class="no-padding pull-right-md hidden-xs"
        (click)="createNewOrder()"
        [disabled]="!detailsForm.valid"
      >
        <ion-icon name="checkmark-circle-outline"></ion-icon>&nbsp;
        <span>{{ 'NewOrder.ferdigmeldOrdre' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-footer>
