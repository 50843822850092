import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { UserService } from '../../shared/user-service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
    selector: 'release-notes',
    templateUrl: 'release-notes.html',
    styleUrls: ['release-notes.scss']
})
export class ReleaseNotesPage {

    releaseNotes = '';

    constructor(
        private userService: UserService, private translateService: TranslateService,
        private loadingController: LoadingController, private router:Router) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.getReleaseNotes().subscribe(data => {
                    loader.dismiss();
                    this.releaseNotes = data.ReleaseNotes;

                    

                });
            });
        });
    }

    goHome()
    {
        this.router.navigate(['/home']);
     
    }
}
