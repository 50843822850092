import { CanActivate, Router} from '@angular/router';
import { AppSettings } from '../shared/app-settings';
import { Observable, from, of } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService implements CanActivate {
    constructor(private router: Router, private storage: Storage) { }
    canActivate(): Observable<boolean> {
        return from(this.storage.get(AppSettings.StorageKeys.Token)).pipe(switchMap(token => {
            if (token) {
                return of(true);
            }
            this.router.navigate(['/login']);
            return of(false);
        }));
    }
}
