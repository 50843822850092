import { Component } from '@angular/core';
import { NavParams, ToastController, ModalController } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
import { TranslateService } from '@ngx-translate/core';
import { AppLauncher } from '@capacitor/app-launcher';

@Component({
    selector: 'driving-instructions',
    templateUrl: 'driving-instructions.html',
    styleUrls: ['driving-instructions.scss']
})
export class DrivingInstructionsComponent {
    latitude: number;
    longitude: number;
    currentLatitude: number;
    currentLongitude: number;

    constructor(
        public modalCtrl: ModalController, public navParams: NavParams, private geolocation: Geolocation,
        private translateService: TranslateService, private toastController: ToastController) {

        this.latitude = this.navParams.get('latitude');
        this.longitude = this.navParams.get('longitude');

        this.getCurrentPosition();
    }

    async launchNativeNavigator() {
        let destinationUrl: string;

        // Check if coordinates are valid, otherwise use a default address or alert the user
        if (this.latitude !== 0 && this.longitude !== 0) {
            // Using Google Maps URL scheme to open directions
            destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.latitude},${this.longitude}`;
        } else {
            this.translateService.get('Error.invalidCoordinates').subscribe(value => {
                this.toastController.create({
                    message: value,
                    duration: 3000
                }).then(toast => { toast.present(); });
                return;
            });
        }

        // Try launching the URL
        try {
            await AppLauncher.openUrl({ url: destinationUrl });
        } catch (error) {
            this.translateService.get('Error.navigatorError').subscribe(value => {
                this.toastController.create({
                    message: value + error,
                    duration: 3000
                }).then(toast => { toast.present(); });
            });
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async getCurrentPosition() {
        try {
            const position = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true, // Enable high accuracy if needed
                timeout: 10000, // Set timeout as required
                maximumAge: 0 // Set maximumAge as required to avoid cached positions if needed
            });
            this.currentLatitude = position.coords.latitude;
            this.currentLongitude = position.coords.longitude;
        } catch (error) {
            this.translateService.get('Error.locationError').subscribe(value => {
                this.toastController.create({
                    message: value,
                    duration: 3000
                }).then(toast => { toast.present(); });
            });
        }
    }
}
