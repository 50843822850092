<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'TankMonitoring.selectTanks' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px;">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3" class="ion-text-center">
          <h3>{{ 'EgneTankerSelect.hvaOnskerDuAGjore' | translate }}</h3>
          <ion-list lines="none" class="ion-no-padding">
            <ion-item>
              <ion-button [fill]="!selectDatahost ?'solid': 'outline'" (click)="navigate(false)" size="default" expand="block" class="ion-margin-bottom ion-margin-top">{{
                'TankMonitoring.soloRemote' | translate }}</ion-button>
            </ion-item>
            <ion-item>
              <ion-button [fill]="selectDatahost ?'solid': 'outline'" (click)="navigate(true)" size="default" expand="block" class="ion-margin-bottom">{{
                'TankMonitoring.automaticStations' | translate }}</ion-button>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>