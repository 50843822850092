import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { ModalController, NavParams, LoadingController } from '@ionic/angular';
import _ from 'lodash';
import { ProductType } from '../../shared/enums';

@Component({
    selector: 'work-legg-til-produkt',
    templateUrl: 'work-legg-til-produkt.html',
    styleUrls: ['work-legg-til-produkt.scss']
})
export class WorkLeggTilProduktComponent {
    leggForm: FormGroup;
    private products: any[];
    searchProduct: any[] = [];
    private inventoryList: any[];
    private deviationLimit = 0;
    product;

    constructor(
        public viewCtrl: ModalController, private userService: UserService, private translateService: TranslateService,
        public navParams: NavParams, private loadingController: LoadingController, private formBuilder: FormBuilder) {
        this.inventoryList = this.navParams.data.inventoryList;
        this.deviationLimit = this.navParams.data.deviationLimit;
        this.leggForm = formBuilder.group({
            productNo: ['', Validators.compose([Validators.required])],
            quantity: ['', Validators.compose([Validators.required, Validators.pattern('\\d+')])]
        });
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.getProducts().subscribe(data => {
                    this.products = _.filter(data, (product) => {
                        return product.ProductType !== ProductType.Bulk;
                    });
                    loader.dismiss();
                });
            });
        });
    }

    addProdukt() {
        if (this.product) {
            this.viewCtrl.dismiss({ Product: this.product, Quantity: this.leggForm.get('quantity').value });
        }
    }

    searchProducts(event) {
        this.product = null;
        this.searchProduct = _.filter(this.products, (product) => {
            // search for text, prodno and EAN
            if (product.EAN.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
                || product.Description.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
                || product.ProductNo.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) {
                return product;
            }
        });
    }

    selectProduct(product) {
        this.leggForm.get('productNo').setValue(product.Description);
        this.product = product;
        this.searchProduct = [];
    }

    dismiss() {
        this.viewCtrl.dismiss();
    }
}
