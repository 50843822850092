import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from '../../shared/validators';
import { UserService } from '../../shared/user-service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerData } from '../../shared/customer-data';
import { AppSettings } from '../../shared/app-settings';
import { BaseHttpClient } from '../../shared/http-client';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { Location } from '@angular/common'
import { CustomerNorwegianData } from 'src/app/shared/customer-norwegian-data';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'page-customer-create',
    templateUrl: 'customer-create.html',
    styleUrls: ['customer-create.scss']
})
export class CustomerCreatePage implements AfterViewInit {
    submitAttempt = false;
    submitAddress = false;
    customerForm: FormGroup;
    addressForm: FormGroup;
    priceAgreements;
    addUserToDatahost = false;
    addUserToOrderApp = false;
    selectedCustomer: any = null;
    customerAddresses = [];
    @ViewChild('customerComplete')
    customerDataService;
    addressSeed = -1;
    currentAddress = null;
    private navigationExtras: NavigationExtras;
    CameFromPlanner = 'false';
    currentPriceID = 0;

        customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;

    addressCtrl = new FormControl();
    filteredAddresses: Observable<any[]>;
  
    @ViewChild('addressComplete') addressComplete: ElementRef;
    @ViewChild('customerComplete') customerComplete: ElementRef;

    constructor(
        private userService: UserService, public navCtrl: NavController, private translateService: TranslateService, private location:Location,
        private loadingController: LoadingController, private formBuilder: FormBuilder, private http: HttpClient,
        private router: Router, private route:ActivatedRoute) {
        this.customerForm = formBuilder.group(
            {
                customerName: ['', Validators.compose([Validators.required])],
                customerAddress1: ['', Validators.compose([Validators.required])],
                customerAddress2: [''],
                customerPostalCode: ['', Validators.compose([Validators.required])],
                priceAgreementID: [''],
                customerCity: ['', Validators.compose([Validators.required])],
                email: ['', [CustomValidators.emailValidator]],
                customerReference: [''],
                organizationNo: ['']


            });
        this.addressForm = formBuilder.group(
            {
                deliveryName: ['', Validators.compose([Validators.required])],
                addressAddress1: ['', Validators.compose([Validators.required])],
                addressAddress2: [''],
                addressZip: ['', Validators.compose([Validators.required])],
                addressCity: ['', Validators.compose([Validators.required])],
                phoneOnSite: [''],
                addressType: ['', Validators.required],
                addressDetails: [''],
                contactNameOnSite: [''],
                gps: [''],
                emailOnSite: [''],

            });

        this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
        this.customerDataService = new CustomerData(http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);

        try {
                    //get navigationextras
        this.route.queryParams.subscribe(params => {
            
            this.CameFromPlanner = params.CameFromPlanner;
        });
        } catch (error) {
            
        }
        
    }

    ngAfterViewInit() {
        this.customerComplete.nativeElement.setAttribute('type', 'text');
    }

    ngOnInit(){
          this.filteredCustomers = this.customerCtrl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(name => name ? this._filterCustomers(name) : of([])) // Use switchMap instead of map
            );
    }

    
    displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

    getFormControl(field) {
        return this.customerForm.get(field);
    }

    getAddressFormControl(field) {
        return this.addressForm.get(field);
    }

    navigateToBack() {
        this.clearFormControl(this.customerForm);
         this.location.back()
    }

    customerSelected($event) {
        if ($event != null) {
            const originalObject = $event.originalObject;
            if (originalObject.CustomerBlockedStatus > 0) {
                this.customerForm.get('customerName').setErrors({ incorrect: true });
            } else {
                this.selectedCustomer = originalObject;
                this.getFormControl('customerAddress1').setValue(originalObject.Address);
                this.getFormControl('customerAddress2').setValue(originalObject.Address2);
                this.getFormControl('customerPostalCode').setValue(originalObject.PostalCode);
                this.getFormControl('customerCity').setValue(originalObject.City);
                this.getFormControl('email').setValue(originalObject.Email);
                this.getFormControl('customerReference').setValue(originalObject.CustomerReference);
                this.getFormControl('organizationNo').setValue(originalObject.OrgNo);

                this.currentPriceID = originalObject.priceAgreementID;

                this.customerAddresses = [];
                this.currentAddress = null;
                this.addressSeed = -1;
                this.addressForm.reset();
                this.getAddressFormControl('addressType').setValue('');
                if (this.selectedCustomer.CustomerAddresses) {
                    for (const item of this.selectedCustomer.CustomerAddresses) {
                        item.addressSeed = 0;
                        this.customerAddresses.push(item);
                    }
                }
            }
        }
    }

    setAddressValue(customerAddress) {

        //check GPS
        try {
            var arrGPS = this.getAddressFormControl('gps').value.split(",");

        customerAddress.Latitude = parseFloat(arrGPS[0].trim());
        customerAddress.Longitude = parseFloat(arrGPS[1].trim());

        } catch (error) {
            
        }

        customerAddress.Name = this.getAddressFormControl('deliveryName').value;
        customerAddress.Address1 = this.getAddressFormControl('addressAddress1').value;
        customerAddress.Address2 = this.getAddressFormControl('addressAddress2').value;
        customerAddress.PostalCode = this.getAddressFormControl('addressZip').value;
        customerAddress.City = this.getAddressFormControl('addressCity').value;
        customerAddress.AddressType = this.getAddressFormControl('addressType').value;
        customerAddress.PhoneOnSite = this.getAddressFormControl('phoneOnSite').value;
        customerAddress.Email = this.getAddressFormControl('emailOnSite').value;
        //customerAddress.PhoneOnSite = this.getAddressFormControl('phoneOnSite').value;
        customerAddress.AddressDetails = this.getAddressFormControl('addressDetails').value;
        customerAddress.ContactNameOnSite = this.getAddressFormControl('contactNameOnSite').value;
        customerAddress.GPS = this.getAddressFormControl('gps').value;



        return customerAddress;
    }

    addAddress() {4
        this.submitAddress = true;
        if (this.addressForm.valid) {
            if (this.currentAddress) {
                const address = _.find(this.customerAddresses, {
                    addressSeed: this.currentAddress.addressSeed,
                    AddressID: this.currentAddress.AddressID
                });
                this.setAddressValue(address);
            } else {
                let customerAddress: any = {};
                customerAddress = this.setAddressValue(customerAddress);
                customerAddress.AddressID = 0;
                customerAddress.addressSeed = this.addressSeed;
                this.customerAddresses.push(customerAddress);
                this.addressSeed--;
            }
            this.currentAddress = null;
            this.addressForm.reset();
            this.getAddressFormControl('addressType').setValue('');
            this.submitAddress = false;
        }
    }

    editAddress(address) {
        this.currentAddress = address;
        this.getAddressFormControl('deliveryName').setValue(address.Name);
        this.getAddressFormControl('addressAddress1').setValue(address.Address1);
        this.getAddressFormControl('addressAddress2').setValue(address.Address2);
        this.getAddressFormControl('addressZip').setValue(address.PostalCode);
        this.getAddressFormControl('addressCity').setValue(address.City);
        this.getAddressFormControl('addressType').setValue(address.AddressType);
        this.getAddressFormControl('phoneOnSite').setValue(address.PhoneOnSite);
        this.getAddressFormControl('addressDetails').setValue(address.AddressDetails);
        this.getAddressFormControl('contactNameOnSite').setValue(address.ContactNameOnSite);
        this.getAddressFormControl('emailOnSite').setValue(address.Email);
        this.getAddressFormControl('gps').setValue(address.GPS);
    }

    deleteAddress(address) {
        const index = _.findIndex(this.customerAddresses, {
            addressSeed: address.addressSeed,
            AddressID: address.AddressID
        });
        if (index > -1) {
            this.customerAddresses.splice(index, 1);
        }
        if (this.currentAddress && this.currentAddress.addressSeed === address.addressSeed &&
            this.currentAddress.AddressID === address.AddressID) {
            this.currentAddress = null;
            this.addressForm.reset();
            this.getAddressFormControl('addressType').setValue('');
        }
    }

    navigateToWorkOrderHistory(addressID) {
        this.navigationExtras = {
            queryParams: {
                deliveryActorNo: addressID
            },
        };
        this.router.navigate(['/work-order-history'], this.navigationExtras);
    }

    createCustomer() {
        this.submitAttempt = true;
        if (this.addressForm.valid) {
            this.addAddress();
        }
        if (this.customerForm.valid) {
            const customer: any = {};
            customer.Name = this.getFormControl('customerName').value;
            customer.Address = this.getFormControl('customerAddress1').value;
            customer.Address2 = this.getFormControl('customerAddress2').value;
            customer.City = this.getFormControl('customerCity').value;
            customer.PostalCode = this.getFormControl('customerPostalCode').value;
            customer.PriceAgreementID = this.getFormControl('priceAgreementID').value > 0 ? this.getFormControl('priceAgreementID').value : this.currentPriceID;
            customer.Email = this.getFormControl('email').value;
            customer.OrgNo = this.getFormControl('organizationNo').value;
            customer.CustomerReference = this.getFormControl('customerReference').value;
            customer.AddUCustomerToDatahost = this.addUserToDatahost;
            customer.AddUCustomerToOrderApp = this.addUserToOrderApp;
            customer.CustomerAddresses = this.customerAddresses;
            customer.ErpID = this.selectedCustomer ? this.selectedCustomer.ErpID : null;
            customer.CustomerNo = this.selectedCustomer ? this.selectedCustomer.CustomerNo : null;
            const customerList = [];
            customerList.push(customer);
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.createCustomer(customerList).subscribe(resp => {
                        if (resp) {
                            this.clearFormControl(this.customerForm);
                            this.clearFormControl(this.addressForm);
                              if(this.CameFromPlanner == 'true')
                                    this.router.navigate(['/order-planner'], this.navigationExtras);
                                    else
                                    this.router.navigate(['/orders'], this.navigationExtras);
                        }
                        loader.dismiss();
                    });
                });
            });
        }
    }

 searchCustomerNorwegianRegistry(event) {
        try {
              this.selectedCustomer = null;
        if (event.target.value.length >= 3) {
            this.customerDataService = new CustomerData(this.http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
            this.customerDataService.subscribe(customers => {
                if (customers.length === 0) {
                    this.customerDataService = new CustomerNorwegianData(this.http, AppSettings.NorwegianRegistryAPI + event.target.value);
                    this.customerDataService.search();
                }
            });
        }
        } catch (error) {
            
        }
      
    }


     clearFormControl(form: FormGroup) {
        for (const key in form.controls) {
            if (key) {
                try{form.get(key).setValue('');}catch{};
                 try{form.get(key).clearValidators();}catch{};
                 try{form.get(key).updateValueAndValidity();}catch{};

            }
        }
            try {
               
                this.customerDataService = null;
              
               this.customerComplete = null;
             
                this.selectedCustomer = null;
            } catch (error) {
                console.log(error);
            }
    }

}
