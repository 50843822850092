<ion-content class="ion-padding">
  <div class="scroll-content-wo-padding">
    <ion-grid>
      <ion-text color="primary">
        <h6>
          <ion-icon name="checkmark-circle-outline"></ion-icon> {{
          'OrderConfirm.bekreftLevering' | translate }}
        </h6>
      </ion-text>
      <ion-row class="grid-header">
        <ion-col size-xs="4">
          {{ 'OrdreDetails.produkt' | translate }}
        </ion-col>
        <ion-col size-xs="3">
          {{ 'OrdreDetails.bestilt' | translate }}
        </ion-col>
        <ion-col size-xs="3"> {{ 'OrdreDetails.levert' | translate }} </ion-col>
        <ion-col size-xs="2">
          {{ 'OrdreDetails.standard' | translate }}
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let orderLine of order.Orderlines">
        <ion-col size-xs="4"> {{orderLine.Description}} </ion-col>
        <ion-col size-xs="3">
          <div class="inline-block bestilt">{{orderLine.Quantity}}</div>
        </ion-col>
        <ion-col size-xs="3">
          <span class="slight-big-font"
            ><strong>{{orderLine.QuantityDelivered}}</strong></span
          >
        </ion-col>
        <ion-col
          size-xs="2"
          *ngIf="VolumeToStandardTruck && orderLine.ProductType == 10"
        >
          <span class="slight-big-font"
            ><strong
              >{{orderLine.QuantityDelivered*orderLine.VolumeToStandardFactor |
              number:'1.0-0'}}</strong
            ></span
          >
        </ion-col>
        <ion-col size-xs="2" *ngIf="!VolumeToStandardTruck">
          <span class="slight-big-font"
            ><strong
              >{{orderLine.QuantityDelivered | number:'1.0-1'}}</strong
            ></span
          >
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-list lines="none">
      <ion-item class="ion-float-right">
        <ion-buttons>
          <ion-button
            (click)="dismiss(false)"
            color="grey"
            fill="outline"
            size="small"
          >
            <span>{{ 'General.avbryt' | translate }}</span>
          </ion-button>
          <ion-button (click)="dismiss(true)" fill="solid" size="small">
            <span>{{ 'General.save' | translate }}</span>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
