import { Component, OnInit } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationExtras } from '@angular/router';
import { InternalOrdertype } from 'src/app/shared/enums';

@Component({
    selector: 'page-orders-product',
    templateUrl: 'orders-product.html',
    styleUrls: ['orders-product.scss'],
})

export class OrdrersProductPage implements OnInit {
    orders: any[];
    sortProperty = 'OrderSorting';
    sortDirection = 'asc';
    dateFormat: any;
    

    constructor(
        public navCtrl: NavController, public userService: UserService, private translateService: TranslateService,
        private loadingController: LoadingController, private router: Router) { }

    ngOnInit() {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });
        this.loadData();
    }

    loadData() {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.getOrders(null, AppSettings.Username, false,
                    InternalOrdertype.Product).subscribe(data => {
                        this.orders = data;
                        this.sort('OrderNo');
                       // this.filterChanged(null);
                        loader.dismiss();
                    });
            });
        });
    }

    isOrderMine(order): boolean {
        if (order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

    navigteToOrderDetilsSales(order) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                order: JSON.stringify(order),
                approval: true,
            },
            skipLocationChange: true
        };
        this.router.navigate(['/order-details-product'], navigationExtras);
    }

    approveForInvoice(order) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.approveForInvoice(order).subscribe(data => {
                    order.Approve = true;
                    this.removeApprovedItem(order);
                    loader.dismiss();
                });
            });
        });
    }

    sort(property) {
        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'desc';
        }
        this.orders = Utils.orderBy(this.orders, this.sortProperty, this.sortDirection);
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    removeApprovedItem(order) {
        this.orders.splice(
            this.orders.findIndex(x => x.OrderNo = order.OrderNo),
            1
        );

    }

    navigateToCreateOrder() {
        this.router.navigate(['/new-order-product']);
    }
}
