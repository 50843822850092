<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title class="ion-text-left">
      <span
        class="hidden-xs"
        *ngIf="name"
        [innerText]="('Home.heyHer' | translate).format(name)"
        >-</span
      >
      <logout></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content">
    <ion-grid>
      <ion-row>
        <ion-col class="text-cs-center" size-xs="12" *ngIf="showActivityAlert">
          <div
            class="alert"
            [class.alert-warning]="!showDanger"
            [class.alert-danger]="showDanger"
          >
            <strong>{{ 'General.ReminderTitle' | translate }}</strong> &nbsp;
            <ng-container *ngIf="showActivityAlertMessage === ''"
              >{{ 'General.ActivityReminder' | translate }}</ng-container
            >
            <ng-container *ngIf="showActivityAlertMessage != ''"
              >{{ showActivityAlertMessage }}</ng-container
            >
          </div>
        </ion-col>
        <ion-col class="text-cs-center" size-xs="12" *ngIf="showAlert != ''">
          <div
            class="alert"
            [class.alert-warning]="!showDanger"
            ]
            [class.alert-danger]="showDanger"
          >
            <strong>{{ showAlert }}</strong>
          </div>
        </ion-col>
      </ion-row>
      <!--SCOREBOARD-->
      <ion-row [hidden]="true">
        <ion-col size-xs="12">
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="chatbox-ellipses-outline"
                ion-text
                color="primary"
                class="vertical-text-bottom"
              ></ion-icon>
              {{ 'Home.leadsScoreBoard' | translate }}
            </ion-card-header>
            <ion-card-content class="content-card-container">
              <ion-row>
                <ion-col size-sm="5" size-xs="12" *ngIf="userScore">
                  <div class="user-score">
                    <div>
                      <span><strong>{{userScore.Count}}p</strong></span
                      ><br />
                      {{ 'Home.leadsPoint' |translate }}
                      <br />
                      <div class="ion-margin-top-16">
                        <strong>{{ 'Home.message' |translate}}</strong>
                      </div>
                      {{userScore.Message}}
                    </div>
                  </div>
                </ion-col>
                <ion-col size-sm="7" size-xs="12">
                  <div class="lead-score ion-padding-top">
                    <ion-row>
                      <ion-col
                        size-sm="3"
                        size-xs="4"
                        class="second ion-no-padding"
                      >
                        <div class="top"></div>
                        <div class="score">
                          <div class="score-text">
                            <strong>{{topLeadScore.second.score}}p</strong
                            ><br />
                            <div class="text-overflow">
                              {{topLeadScore.second.name}}
                            </div>
                          </div>
                        </div>
                        <div class="rank"><div>2</div></div>
                      </ion-col>
                      <ion-col
                        size-sm="4"
                        size-xs="4"
                        class="first ion-no-padding"
                      >
                        <div class="top">
                          <div class="score">
                            <div class="score-text">
                              <strong>{{topLeadScore.first.score}}p</strong
                              ><br />
                              <div class="text-overflow">
                                {{topLeadScore.first.name}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="left">
                          <div class="rank"><div>1</div></div>
                        </div>
                        <div class="right"></div>
                        <div class="clear"></div>
                      </ion-col>
                      <ion-col
                        size-sm="3"
                        size-xs="4"
                        class="third ion-no-padding"
                      >
                        <div class="top"></div>
                        <div class="score">
                          <div class="score-text">
                            <span
                              ><strong
                                >{{topLeadScore.third.score}}p</strong
                              ></span
                            ><br />
                            <div class="text-overflow">
                              {{topLeadScore.third.name}}
                            </div>
                          </div>
                        </div>
                        <div class="rank"><div>3</div></div>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="ion-no-padding" size-xs="12" size-sm>
          <!--Apps shortcuts-->
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="arrow-redo-outline"
                ion-text
                color="primary"
                class="card-icon"
                size="large"
              ></ion-icon>
              {{ 'Home.shortcuts' | translate }}</ion-card-header
            >
            <ion-card-content class="content-card-container">
              <span *ngFor="let shortcut of appShortcuts">
                <ion-button
                  fill="outline"
                  color="primary"
                  class="no-padding"
                  href="{{shortcut.url}}"
                  target="_blank"
                >
                  <ion-icon name="arrow-redo-outline"></ion-icon>&nbsp;{{
                  shortcut.name }}
                </ion-button>
              </span>
            </ion-card-content>
          </ion-card>

          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="chatbox-ellipses-outline"
                ion-text
                color="primary"
                class="card-icon"
                size="large"
              ></ion-icon>
              {{ 'Home.meldinger' |translate }}
            </ion-card-header>

            <ion-card-content class="content-card-container">
              <div
                class="content-card"
                *ngIf="messages && messages?.length != 0"
              >
                <div class="d-flex">
                  <ion-icon
                    name="calendar-outline"
                    color="lightFive"
                    class="vertical-middle mr-5"
                  ></ion-icon>
                  <ion-text color="primary"
                    ><span
                      >{{messages[currentMessageIndex].Created |
                      date:dateFormat}}</span
                    ></ion-text
                  >
                  <ion-icon
                    name="person-outline"
                    class="ion-margin-start -vertical-middle mr-5"
                    color="lightFive"
                  ></ion-icon>
                  <ion-text color="primary"
                    ><span>
                      {{messages[currentMessageIndex].CreatedByUser}}
                    </span>
                  </ion-text>
                  <ion-icon
                    name="people-outline"
                    class="vertical-middle ion-margin-start mr-5"
                    color="lightFive"
                  ></ion-icon>
                  <ion-text color="primary"
                    ><span
                      >{{(messages[currentMessageIndex].UserID <= 0 ?
                      'Home.alli' : 'Home.deg' ) | translate}}</span
                    >
                  </ion-text>
                </div>
                <br />
                <span class="message-text"
                  >{{messages ? messages[currentMessageIndex].Message :
                  ''}}</span
                >
              </div>
              <div>
                <ion-button
                  class="no-padding"
                  fill="outline"
                  (click)="showNewMessageDialog()"
                  color="primary"
                >
                  <ion-icon name="add-outline"></ion-icon>&nbsp;
                  <span>{{ 'Home.nyMelding' | translate }}</span> </ion-button
                >&nbsp;
                <div
                  class="pull-right lnHt45"
                  *ngIf="messages && messages.length > 0"
                >
                  <ion-button
                    (click)="previousMessage()"
                    *ngIf="currentMessageIndex > 0"
                    fill="clear"
                    class="no-padding ion-no-padding ion-no-margin button-no-text vertical-middle"
                  >
                    <ion-icon
                      class="litte-big-font"
                      name="caret-back-circle"
                    ></ion-icon>
                  </ion-button>
                  <span class="litte-big-font-2"
                    >{{currentMessageIndex + 1}}/{{messages.length}}</span
                  >
                  <ion-button
                    (click)="nextMessage()"
                    *ngIf="currentMessageIndex + 1 < messages.length"
                    fill="clear"
                    class="ion-no-margin no-padding button-no-text vertical-middle"
                  >
                    <ion-icon
                      class="litte-big-font"
                      name="caret-forward-circle"
                    ></ion-icon>
                  </ion-button>
                </div>
              </div>
              <div class="clear"></div>
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col size-xs="12" size-sm class="full-height ion-no-padding">
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <img
                alt="Stock"
                src="assets/icon/Icon_Truck_small.svg"
                class="card-icon"
              />
              {{ 'Home.status' | translate }}
            </ion-card-header>

            <ion-card-content class="content-card-container">
              <div class="content-card-msg">
                <div class="overflow-x home">
                  <div class="product-grid">
                    <ion-row class="ion-no-padding">
                      <ion-col size-xs="6"> </ion-col>
                      <ion-col>
                        <strong>{{ 'Home.na' | translate }}</strong>
                      </ion-col>
                      <ion-col>
                        <strong>{{ 'Home.iOrdre' | translate }}</strong>
                      </ion-col>
                      <ion-col>
                        <strong>{{ 'Home.avvik' | translate }}</strong>
                      </ion-col>
                    </ion-row>
                    <ion-row
                      class="ion-no-padding"
                      *ngFor="let item of inventoryList; let isOdd = odd; let i = index"
                      [class.odd]="isOdd"
                    >
                      <ion-col size-xs="6">
                        <ion-badge [style.background]="item.ProductColorCode"
                          >{{item.ProductShortCode}}</ion-badge
                        >
                        {{item.ProductDescription}}
                      </ion-col>
                      <ion-col> {{item.Quantity}} </ion-col>
                      <ion-col>
                        <strong>{{item.QuantityInOrder}}</strong>
                      </ion-col>
                      <ion-col>
                        <strong
                          >{{item.Quantity + item.Liter -
                          item.QuantityInOrder}}</strong
                        >
                      </ion-col>
                    </ion-row>
                  </div>
                </div>
                <br />
                <ion-button
                  fill="outline"
                  class="no-padding"
                  *ngIf="!hideShortcutInventory"
                  color="primary"
                  (click)="navigateToBeholdning()"
                >
                  <ion-icon name="arrow-redo-outline"></ion-icon>&nbsp;{{
                  'Home.inventory' | translate }} </ion-button
                >&nbsp;
                <ion-button
                  fill="outline"
                  color="primary"
                  class="no-padding"
                  *ngIf="!hideShortcutOrder"
                  (click)="navigateToOrders()"
                >
                  <ion-icon name="arrow-redo-outline"></ion-icon>&nbsp;{{
                  'Home.orders' | translate }} </ion-button
                >&nbsp;
                <ion-button
                  fill="outline"
                  color="primary"
                  class="no-padding"
                  *ngIf="!hideShortcutInternalOrder"
                  (click)="navigateToInternalOrders()"
                >
                  <ion-icon name="arrow-redo-outline"></ion-icon>&nbsp;{{
                  'Home.internalOrder' | translate }} </ion-button
                >&nbsp;
              </div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row [hidden]="true">
        <ion-col
          size-xs="12"
          class="min-height-250 ion-no-padding"
          *ngIf="hideShortcutTankMonitoring"
        >
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <ion-icon
                name="podium-outline"
                ion-text
                color="primary"
                class="vertical-text-bottom"
              ></ion-icon>
              {{ 'Home.dineLiterLevertPrManed' | translate }}
            </ion-card-header>
            <ion-card-content #chartCon>
              <!--chart (load)="saveChart($event.context)"></chart-->
            </ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col
          size-xs="12"
          class="min-height-250 ion-no-padding"
          *ngIf="!hideShortcutTankMonitoring"
        >
          <ion-card class="card-ios-full">
            <ion-card-header class="card-header-ios">
              <img src="assets/icon/Icon_TankMonitor.svg" class="card-icon" />
              {{ 'Home.tankLevels' | translate }}
            </ion-card-header>
            <ion-card-content>
              <page-soolo-tanklevels></page-soolo-tanklevels>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
