<ion-header>
  <ion-toolbar>
    <ion-title>{{ "NewCustomer.search" | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">{{
        "General.lukk" | translate
      }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item class="input-search">
    <ion-icon name="search-outline"></ion-icon>
    &nbsp;&nbsp;
    <ion-input
      [formControl]="customerCtrl"
      placeholder="{{ 'General.Type2Search' | translate }}"
      #customerSearch
    ></ion-input>
    <ion-button fill="none" (click)="clearSearch()" class="ion-input-button">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-list>
    <ion-item
      *ngFor="let customer of filteredCustomers | async"
      (click)="selectCustomer(customer)"
      ><ion-label
        [ngClass]="{ 'alert-danger': customer.CustomerBlockedStatus > 0 }"
      >
        <strong>{{ customer.Name }} </strong> -
        <ion-badge class="ion-badge">
          {{
            customer.CustomerNo == 0 ? "Ny kunde" : customer.CustomerNo
          }}</ion-badge
        >

        <ion-icon
          name="alert-circle-outline"
          *ngIf="customer.CustomerBlockedStatus > 0"
          class="alert-danger"
          title="Kreditsperret"
        ></ion-icon>
        <br />
        {{ customer.Address }} {{ customer.Address2 }},
        {{ customer.PostalCode }} {{ customer.City }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
