import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonInput, IonicModule, ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';
import { AppSettings } from '../../../shared/app-settings';
import { CustomerData } from '../../../shared/customer-data';
import { AddressData } from '../../../shared/address-data';
import { HttpClient } from '@angular/common/http';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CustomerNorwegianData } from 'src/app/shared/customer-norwegian-data';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrl: './customer-search.component.css'
})
export class CustomerSearchComponent {
  @Input() query: string = '';
  @Input() editMode: boolean = false;

  customerCtrl = new FormControl();
  filteredCustomers: Observable<any[]>;
  selectedCustomer: any = null;

  private customerDataService: CustomerData;

  @ViewChild('customerSearch', { static: false }) customerSearch: IonInput;
    @ViewChild('customerSearchRef', { static: false }) customerSearchRef: ElementRef;



  constructor(private http: HttpClient, private modalCtrl: ModalController) {
    this.customerDataService = new CustomerData(this.http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
  }

  ngOnInit() {
    this.customerCtrl.setValue(this.query);

    this.filteredCustomers = this.customerCtrl.valueChanges.pipe(
      startWith(this.query),
      debounceTime(300),
      switchMap(name => this.searchCustomerNorwegianRegistry(name))
    );
  }

   ngAfterViewInit() {
    // Set focus on the input field after the view has been initialized
    setTimeout(() => this.customerSearch.setFocus(), 1000);

     // Trigger search on every keyup event
   
  }


  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

  private searchCustomerNorwegianRegistry(query: string): Observable<any[]> {
    return new Observable(observer => {
      this._filterCustomers(query).subscribe(customers => {
        if (customers.length === 0 && query.length >= 3) {
          const norwegianDataService = new CustomerNorwegianData(this.http, `${AppSettings.NorwegianRegistryAPI}${query}`);
          norwegianDataService.search().subscribe(norwegianCustomers => {
            observer.next(norwegianCustomers);
            observer.complete();
          });
        } else {
          observer.next(customers);
          observer.complete();
        }

         if(this.editMode && customers.length == 1)
        {
          this.selectCustomer(customers[0]);
        }
      });

     
    });
  }

   async clearSearch()
  {
      this.customerCtrl.setValue('');
    this.customerSearch.setFocus();
  }


  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  async selectCustomer(customer) {
    await this.modalCtrl.dismiss({ customer });
  }
}