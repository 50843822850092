import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, AlertController, IonInput } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TransactionType } from '../../shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { OrderNextDayConfirmComponent } from '../../components/order-next-day-confirm/order-next-day-confirm';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLauncher } from '@capacitor/app-launcher';
import { Geolocation } from '@capacitor/geolocation';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CustomValidators } from '../../shared/validators';
import { Storage } from '@ionic/storage-angular';
import { WorkLeggTilProduktComponent } from '../../components/work-legg-til-produkt/work-legg-til-produkt';
import { WorkOrderConfirmComponent } from '../../components/work-order-confirm/work-order-confirm';
import { EditOrderSharedComponent } from '../../components/edit-order-shared/edit-order-shared';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CustomerAddressSearchComponent } from 'src/app/components/customer-address-search/customer-address-search/customer-address-search.component';
import { CustomerSearchComponent } from 'src/app/components/customer-search/customer-search/customer-search.component';



@Component({
    selector: 'page-work-order-details',
    templateUrl: 'work-order-details.html',
    styleUrls: ['work-order-details.scss']
})
export class WorkOrderDetailsPage implements OnDestroy {
    order: any;
    dateFormat;
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    currentLatitude: number = null;
    currentLongitude: number = null;
    private VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    private VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = true;
    private currentTruckTrailerSub$: Subscription;
    private deviationLimit = 0;
    private inventoryList: any;
    errorMessages: any = [];
    products: any[];
    isHidden: boolean;
    private CameFromApproval: boolean;
    private navigationExtras: NavigationExtras;
    allowDescriptionChange;
    expand = false;
    hideCost = false;
    @ViewChild('picker')
    private picker: any;
    openModal = false;
    
  private posOptions = {
    enableHighAccuracy: true, // or false, depending on your needs
    timeout: 10000,
    maximumAge: 0,
  };    
    mapLong: number;
    mapLat: number;
    isMapVisible: boolean;
    selectedCustomer;
    customerModal: boolean = false;
    customerAddressModal: boolean = false;
priceAgreements;

   
    @ViewChild('customerNameInput', { static: false }) customerNameInput: IonInput;
    @ViewChild('addressInput', { static: false }) addressInput: IonInput;
     latLongPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    bolKeepDetails =  true;
    addressID: number;
     inEdit = false;
    
     


    constructor(
        private formBuilder: FormBuilder, private route: ActivatedRoute, public userService: UserService,
        public navCtrl: NavController, private router: Router, private alertCtrl: AlertController,
        private translateService: TranslateService, private loadingController: LoadingController, private modalCtrl: ModalController,
        private geolocation: Geolocation, private toastController: ToastController,
        private storage: Storage) 
        {
        this.route.queryParams.subscribe(params => {
            this.order = JSON.parse(params.order);
            this.CameFromApproval = params.approval;
            // this.order = JSON.parse(params['order']);
            this.CameFromApproval = (params.approval === 'true');

            // used to pass info to orders view for init an update of orderslist
            this.userService.smUpLargeScreen.subscribe(data => {
                if (data) {
                    this.expand = data;
                }
            });
            const orderlinesGroup: FormGroup = new FormGroup({});
            this.currentTruckTrailerSub$ = this.userService.getCurrentTruckAndTrailer().subscribe(resp => {
                if (AppSettings.ValidateTruckStock) {
                    this.userService.getCurrentInventoryCurrent(null, null).subscribe(data => {
                        this.inventoryList = data.InventoryList2;
                        this.userService.getDeviationLimit(TransactionType.LoadingFromTruck).subscribe(totalData => {
                            this.deviationLimit = totalData.DeviationLimit;
                        });
                    });
                }
            });
            // console.log(this.order);
            this.order.TotalOrder = 0;
            this.storage.get(AppSettings.StorageKeys.AllowDescriptionChange).then(data => {
                this.allowDescriptionChange = data;
                if (this.order.Orderlines) {
                    for (const orderline of this.order.Orderlines) {
                        this.dynamicControl(orderlinesGroup, orderline);
                    }
                    this.getTotalOrder();
                }
            });

            this.detailsForm = formBuilder.group({
                orderLines: orderlinesGroup,
                orderComments: [this.order.OrderComments],
                orderInternalComments: [this.order.OrderInternalComments],
                internalInfo: [this.order.InternalInfo],
                tankInfo: [this.order.TankInfo],
                deliveryInfo: [this.order.DeliveryInfo],
                phoneOnSite: [this.order.PhoneOnSite],
                phoneManager: [this.order.PhoneManager],
                email: [this.order.Email, [CustomValidators.emailValidator]],
                doNotCalculateVolume: [this.order.DoNotCalculateVolume],
                doNotSendGPS: true,
                customerReference: [this.order.CustomerReference],
                prioritet: [this.order.OrderCategory, Validators.required],
                deliveryDate: [this.order.DeliveryDate],
                customerName: [this.order.CustomerName, Validators.compose([Validators.required])],
                customerAddress1: [this.order.CustomerAddress1],
                customerAddress2: [this.order.CustomerAddress2],
                customerPostalCode: [this.order.CustomerPostalCode],
                priceAgreementID: [this.order.PriceAgreementID],
                transporterID: [this.order.Transporter],
                customerCity: [this.order.CustomerCity],
                address: [this.order.DeliveryName],
                addressAddress1: [this.order.DeliveryAddress1],
                addressAddress2: [this.order.DeliveryAddress2],
                addressZip: [this.order.DeliveryPostalCode],
                addressCity: [this.order.DeliveryCity],
                gps: [this.order.Latitude + ","+ this.order.Longitude, [Validators.pattern(this.latLongPattern)]],
                organizationNo: [],
                DeliveryEmail: [this.order.EmailDelivery, [CustomValidators.emailValidator]],
                additionalProductsDelivered: [this.order.AdditionalProductsDelivered],
                customerPurchaseOrderNo: [this.order.CustomerPurchaseOrderNo],
                emailTo: ['', [CustomValidators.emailValidator]],
                customerBuyer: [this.order.CustomerBuyer],
                repeatInterval:[this.order.RepeatInterval]
            });

             this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
       
            this.userService.searchProducts('energiteknikk').subscribe(data => {
                this.products = data;
            });

            this.translateService.get('General.dateFormat').subscribe(value => {
                this.dateFormat = value;
            });

           
            const that = this;

            this.mapLat = this.order.Latitude;
            this.mapLong = this.order.Longitude;
           

           
        });
    }

    showhide() {
        this.isHidden = !this.isHidden;
    }

    closeModal(){
        this.openModal = false;
    }

     ngOnInit() {
    this.getCurrentPosition();
    this.watchPosition();
  }

  async getCurrentPosition() {
    try {
      const resp = await Geolocation.getCurrentPosition(this.posOptions);
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    } catch (error) {
      this.showGeolocationError();
    }
  }

  watchPosition() {
    const watch = Geolocation.watchPosition(this.posOptions, (position, err) => {
      if (err) {
        this.showGeolocationError();
        return;
      }
      if (position && position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      }
    });
  }

  showGeolocationError() {
    // Implement your error handling logic here
    // For example, display a toast or set the latitude and longitude to null
    this.currentLatitude = -1;
    this.currentLongitude = -1;
    // You can also log the error or inform the user through a UI element
  }
    
    dynamicControl(formGroup: FormGroup, orderline) {
        orderline.changeProduct = false;
        const quantityDelivered: FormControl = new FormControl(orderline.QuantityDelivered,
          //  Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')])
          );
        const price: FormControl = new FormControl(orderline.UnitPrice,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const discount: FormControl = new FormControl(orderline.UnitDiscount,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const cost: FormControl = new FormControl(orderline.UnitCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        //  if (this.allowDescriptionChange) {
        const txtproductNo: FormControl = new FormControl(orderline.Description);
        formGroup.addControl('txt-productNo' + orderline.LineNo, txtproductNo);
        //  } else {
        const productNo: FormControl = new FormControl(orderline, Validators.required);
        formGroup.addControl('productNo' + orderline.LineNo, productNo);
        //     }
        if (orderline.IsTimesheetProduct) {
            const timesheetQuantity: FormControl = new FormControl(orderline.TimesheetQuantity);
            formGroup.addControl('hours' + orderline.LineNo, timesheetQuantity);
        }
        formGroup.addControl('quantity' + orderline.LineNo, quantityDelivered);
        formGroup.addControl('price' + orderline.LineNo, price);
        formGroup.addControl('discount' + orderline.LineNo, discount);
        formGroup.addControl('cost' + orderline.LineNo, cost);
    }

    validateQuantity(orderline) {
        this.setValue(orderline, 'quantity');
        if (this.inventoryList != null) {
            this.errorMessages = [];
            let item;
            for (item of this.order.Orderlines) {
                if (item.ProductType === 10) {
                    let truckProduct = _.find(this.inventoryList, (p: any) => {
                        return p.ProductNo === item.InventoryProductNo;
                    });
                    if (!truckProduct) {
                        this.translateService.get('LeggTilProdukt.productNotFound').subscribe(value => {
                            this.errorMessages.push(value);
                        });
                        return;
                    } else {
                        if (truckProduct.InventoryProductNo) {
                            truckProduct = _.find(this.inventoryList, (p: any) => {
                                return p.ProductNo === truckProduct.InventoryProductNo;
                            });
                        }

                        let maxValue: any = null;

                        if (truckProduct.Quantity + this.deviationLimit < item.QuantityDelivered) {
                            maxValue = truckProduct.Quantity;
                        }
                        if (maxValue != null) {
                            this.translateService.get('OrdreDetails.maxValue').subscribe(value => {
                                this.errorMessages.push(value.format(item.Description, maxValue));
                            });
                        }
                    }
                }
            }
        }
        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    setValue(orderline, fieldName) {
        const control = this.detailsForm.controls.orderLines;
        if (fieldName === 'price') {
            orderline.UnitPrice = control.get('price' + orderline.LineNo).value;
            orderline.UserChangedPrice = true;
        }
        if (fieldName === 'discount') {
            orderline.UnitDiscount = control.get('discount' + orderline.LineNo).value;
        }
        if (fieldName === 'cost') {
            orderline.UnitCost = control.get('cost' + orderline.LineNo).value;
            orderline.UserChangedCost = true;
        }
        if (fieldName === 'quantity') {
            orderline.QuantityDelivered = control.get('quantity' + orderline.LineNo).value;
        }
        if (fieldName === 'hours') {
            orderline.TimesheetQuantity = control.get('hours' + orderline.LineNo).value;
        }
        if (orderline.QuantityDelivered === 0 && !orderline.IsTimesheetProduct) {
            orderline.TimesheetCode = '';
        }
        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    getTotalOrder() {
        this.order.TotalOrder = 0;
        for (const item of this.order.Orderlines) {
            const total = +item.OrderLineTotal + (+this.order.TotalOrder);
            this.order.TotalOrder = Math.round(total).toFixed(2);
        }
    }

    orderlineTotal(orderline) {
        let lineTotal = 0;
        orderline.OrderLineTotal = 0;
        if (orderline.UnitPrice >= 0) {
            if (orderline.QuantityDelivered != 0) {
                lineTotal = (orderline.UnitPrice * orderline.QuantityDelivered);
            } else {
                lineTotal = (orderline.UnitPrice * orderline.Quantity);
            }
        }
        if (orderline.UnitDiscount > 0) {
            orderline.OrderLineTotal = (lineTotal - ((lineTotal * orderline.UnitDiscount) / 100)).toFixed(2);
        } else {
            orderline.OrderLineTotal = lineTotal.toFixed(2);
        }
    }

    changeElement($event, orderline) {
        if ($event) {
            orderline.changeProduct = false;
        } else {
            orderline.changeProduct = true;
        }
    }

   selectProduct(event, orderline) {
        if (event.type === 'change' || event.value) {
            const control = this.detailsForm.controls.orderLines as FormGroup;
            const product = _.find(this.products, { ProductNo: event.target ? event.target.value : event.value.ProductNo });
            if (product) {
                orderline.ProductNo = product.ProductNo;
                orderline.Description = product.Description;
                orderline.ProductShortCode = product.ShortCode;
                orderline.ProductColorCode = product.ColorCode;
                orderline.UnitPrice = product.UnitPrice;
                orderline.UnitCost = product.UnitCost;
                orderline.IsTimesheetProduct = product.IsTimesheetProduct;
                orderline.TimesheetCode = product.TimesheetCode;
                if (orderline.IsTimesheetProduct && control.get('hours' + orderline.LineNo) == null) {
                    const timesheetQuantity: FormControl = new FormControl(orderline.TimesheetQuantity);
                    control.addControl('hours' + orderline.LineNo, timesheetQuantity);
                } else if (!orderline.IsTimesheetProduct && control.get('hours' + orderline.LineNo)) {
                    control.removeControl('hours' + orderline.LineNo);
                }
                if (orderline.QuantityDelivered === 0 && !orderline.IsTimesheetProduct) {
                    orderline.TimesheetCode = '';
                }
                control.get('price' + orderline.LineNo).setValue(orderline.UnitPrice);
                control.get('quantity' + orderline.LineNo).setValue(orderline.Quantity);
                control.get('cost' + orderline.LineNo).setValue(orderline.UnitCost);
                this.orderlineTotal(orderline);
                this.getTotalOrder();
            }
            if (this.allowDescriptionChange) {
                if (control.get('txt-productNo' + orderline.LineNo).value.trim() !== '') {
                    orderline.Description = control.get('txt-productNo' + orderline.LineNo).value;
                }
            }
        } else {
            orderline.changeProduct = false;
        }
    }

    showConfirm(orderline) {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.confirmMessage').subscribe(value => { modalText = value.format(orderline.Description); });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.deleteOrderLine(orderline);
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }

    deleteOrderLine(orderline) {
        const index = _.findIndex(this.order.Orderlines, { ProductNo: orderline.ProductNo });
        if (index > -1) {

            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.deleteOrderline(this.order.OrderNo, orderline.LineNo).subscribe(resp => {
                        loader.dismiss();

                        this.order.Orderlines.splice(index, 1);
                        this.getTotalOrder();

                    });
                });
            });
        }


    }

    deliveredQuantityExists() {
        const orderline = _.find(this.order.Orderlines, (orderLine: any) => {
            return orderLine.QuantityDelivered > 0;
        });
        if (orderline) {
            return true;
        }
        return false;
    }

    async  moveOrderToNextDay() {
        const modal = await this.modalCtrl.create(
            {
                component: OrderNextDayConfirmComponent,
                cssClass: 'order-next-day-confirm'
            });
        modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.moveOrderToNextDay(this.order.OrderNo).subscribe(resp => {
                            loader.dismiss();
                            this.router.navigate(['/work-orders']);
                        });
                    });
                });
            }
        });
    }

    showhideCameFrom() {
        return this.CameFromApproval;
    }

    async leggTilProdukt() {
       
     
        const orderline: any = {
            LineNo: this.identity,
            Quantity: 0,
            QuantityDelivered: 0,
            UnitPrice: 0,
            UnitCost: 0,
            UnitDiscount: 0,
            OrderLineTotal: 0.00,
            TimesheetQuantity: 0,
            IsTimesheetProduct: false,
            changeProduct: true
        };
        this.order.Orderlines.push(orderline);
        const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
        this.dynamicControl(formGroup, orderline);
        this.identity--;
    }

    async setOrderAsCompleted() {
        const modal = await this.modalCtrl.create({
            component: WorkOrderConfirmComponent,
            cssClass: 'order-confirm-sales',
            componentProps: { order: this.order }
        });

        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });
                        const date = new Date();
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.InternalInfo = this.detailsForm.get('internalInfo').value;
                        this.order.TankInfo = this.detailsForm.get('tankInfo').value;
                        this.order.DeliveryInfo = this.detailsForm.get('deliveryInfo').value;
                        this.order.CustomerReference = this.detailsForm.get('customerReference').value;
                        this.order.PhoneOnSite = this.detailsForm.get('phoneOnSite').value;
                        this.order.PhoneManager = this.detailsForm.get('phoneManager').value;
                        this.order.Email = this.detailsForm.get('email').value;
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.DoNotSendGPS = this.detailsForm.get('doNotSendGPS').value;
                        this.order.OrderCategory = this.detailsForm.get('prioritet').value;
                        this.order.DoNotConfirmOrder = false;
                        this.order.EmailTo = this.detailsForm.get('emailTo').value;
                        this.order.DeliveryDate = this.detailsForm.get('deliveryDate').value;
                        this.order.RepeatInterval = this.detailsForm.get('repeatInterval').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });

                         this.order.CustomerBuyer = this.detailsForm.get('customerBuyer').value;


                        if (this.DoNotSendGPS) {
                            this.order.Longitude = -1;
                            this.order.Latitude = -1;
                        } else {
                            this.order.Latitude = this.currentLatitude;
                            this.order.Longitude = this.currentLongitude;
                        }
                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();

                                this.router.navigate(['/work-orders']);
                            });
                        });
                    });
                }
            }
        });
    }

      getFormControl(field) {
        return this.detailsForm.get(field);
    }
    async editCustomerAndAddress2() {
        const modal = await this.modalCtrl.create({
            component: EditOrderSharedComponent,
            cssClass: 'order-shared',
            componentProps: { order: this.order }
        });
        modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        //this.userService.updateOrder(data.data.Order).subscribe(() => { //removed update to Db until user saved the whole order
                            this.order.CustomerID = data.data.Order.CustomerID;
                            this.order.DeliveryActorNo = data.data.Order.DeliveryActorNo;
                            this.order.CustomerName = data.data.CustomerDetails.CustomerName;
                            this.order.CustomerAddress1 = data.data.CustomerDetails.CustomerAddress1;
                            this.order.CustomerAddress2 = data.data.CustomerDetails.CustomerAddress2;
                            this.order.CustomerCity = data.data.CustomerDetails.CustomerCity;
                            this.order.CustomerPostalCode = data.data.CustomerDetails.CustomerPostalCode;
                            this.order.DeliveryName = data.data.CustomerDetails.DeliveryName;
                            this.order.DeliveryAddress1 = data.data.CustomerDetails.DeliveryAddress1;
                            this.order.DeliveryAddress2 = data.data.CustomerDetails.DeliveryAddress2;
                            this.order.DeliveryCity = data.data.CustomerDetails.DeliveryCity;
                            this.order.DeliveryPostalCode = data.data.CustomerDetails.DeliveryPostalCode;
                            this.order.PriceAgreementID = data.data.CustomerDetails.PriceAgreementID;
                            loader.dismiss();
                       // });
                    });
                });
            }
        });
    }
    openPicker() {
     //   this.picker.open();
    }

    async SaveOrder() {
     
                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });
                        const date = new Date();
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.InternalInfo = this.detailsForm.get('internalInfo').value;
                        this.order.TankInfo = this.detailsForm.get('tankInfo').value;
                        this.order.DeliveryInfo = this.detailsForm.get('deliveryInfo').value;
                        this.order.CustomerReference = this.detailsForm.get('customerReference').value;
                        this.order.CustomerPurchaseOrderNo = this.detailsForm.get('customerReference').value;
                        this.order.PhoneOnSite = this.detailsForm.get('phoneOnSite').value;
                        this.order.PhoneManager = this.detailsForm.get('phoneManager').value;
                        this.order.Email = this.detailsForm.get('email').value;
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.DoNotSendGPS = this.detailsForm.get('doNotSendGPS').value;
                        this.order.OrderCategory = this.detailsForm.get('prioritet').value;
                        this.order.DeliveryDate = this.detailsForm.get('deliveryDate').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });
                        this.order.RepeatInterval = this.detailsForm.get('repeatInterval').value;
                        this.order.CustomerBuyer = this.detailsForm.get('customerBuyer').value;
                        this.order.DoNotConfirmOrder = true;
                        if (this.DoNotSendGPS) {
                            this.order.Longitude = -1;
                            this.order.Latitude = -1;
                        } else {
                            this.order.Latitude = this.currentLatitude;
                            this.order.Longitude = this.currentLongitude;
                        }
                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setWorkOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();
                                 if (this.CameFromApproval === true) {
                                    this.router.navigate(['/workorder-approval'], this.navigationExtras);
                                } else {
                                    this.router.navigate(['/work-orders'], this.navigationExtras);
                                } 
                            });
                        });
                    });
                }
            
     
    }

    isOrderMine(): boolean {
        if (this.order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

   async loadDrivingInstructions() {
    let destination;

    if (this.order.Latitude !== 0 && this.order.Longitude !== 0) {
        destination = `${this.order.Latitude},${this.order.Longitude}`;
    } else {
        destination = encodeURIComponent(this.order.DeliveryAddress1 + ' ' + this.order.DeliveryPostalCode + ' ' + this.order.DeliveryCity);
    }

    const url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

    try {
        await AppLauncher.openUrl({ url });
    } catch (error) {
        console.error('Navigation error:', error);
        this.toastController.create({
            message: `Error launching navigator: ${error.message}`,
            duration: 3000
        }).then(toast => toast.present());
    }
}


    unassignOrder() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.unassignOrder(this.order.OrderNo).subscribe(data => {
                    this.order.AssignedDriver = 0;
                    loader.dismiss();
                    this.router.navigate(['/work-orders']);
                });
            });
        });
    }

    ngOnDestroy() {
        this.currentTruckTrailerSub$.unsubscribe();
    }

    navigateToOrdersList() {
        this.navCtrl.pop();
    }

    navigateToWorkOrderDocuments() {
        this.navigationExtras = {
            queryParams: {
                orderNo: this.order.OrderNo
            },
        };
        this.router.navigate(['/work-orders-documents'], this.navigationExtras);
    }

    navigateToWorkOrderHistory() {
        this.navigationExtras = {
            queryParams: {
                deliveryActorNo: this.order.DeliveryActorNo
            },
        };
        this.router.navigate(['/work-order-history'], this.navigationExtras);
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    //Ionic-selectable - search multiple fields
    searchProducts(event: {
        component: IonicSelectableComponent,
        text: string
    }) 
    {
    let text = event.text.trim().toLowerCase();

    if(text != '')
    {
        event.component.startSearch();
        event.component.items = this.filterProducts(this.products, text);
        event.component.endSearch();
    }
    else
    {
        event.component.items = this.products;
    }


};

  filterProducts(products: any[], text: string) {
    return products.filter(prod => {
      return prod.ProductNo.toLowerCase().indexOf(text) !== -1 ||
      prod.Description.toLowerCase().indexOf(text) !== -1;
    });
  }

  
  confirmDeleteOrder() {
    let modalTitle;
    let labelText;
    let cancel;
    let save;
    this.translateService.get('OrdreDetails.orderDeleteMessage').subscribe(value => {
        labelText = value;
    });
    this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
    this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
    this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
    this.alertCtrl.create({
        header: modalTitle,
        inputs: [
            {
                name: 'checkbox',
                type: 'checkbox',
                label: labelText,
                checked: false,
                value: 'true',
                handler: (input) => {
                    const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
                    if (input.checked) {
                        confirmBtn.disabled = false;
                    } else {
                        confirmBtn.disabled = true;
                    }
                },
            }],
        buttons: [
            {
                text: cancel,
                handler: () => {
                }
            },
            {
                text: save,
                cssClass: 'confirmation',
                handler: data => {
                    if (data.length > 0) {
                        this.deleteOrder();
                    }
                }
            }
        ]
    }).then(confirm => {
        confirm.present();
        const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
        confirmBtn.disabled = true;
    });
}

deleteOrder() {
    this.userService.deleteOrder(this.order.OrderNo).subscribe(data => {
        this.router.navigate(['/work-orders'], this.navigationExtras);
    });
}

toggleHideCost()
    {
        this.hideCost = !this.hideCost;
    }

    plukkOrApne() {
        
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.assignOrderToDriver(this.order.OrderNo, null).subscribe(data => {
                    this.order.AssignedDriver = AppSettings.Username;
                    loader.dismiss();
                    this.router.navigate(['/orders'], this.navigationExtras);
                    //this.loadData();
                });
            });
        });
    
}


   checkInput(controlName: string) {
    try {
         const control = this.detailsForm.get(controlName);
    const inputValue = control ? control.value : '';
    const ionInput = document.querySelector(`[formControlName="${controlName}"]`);

    if (ionInput) {
      if (inputValue) {
        ionInput.classList.add('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.add('item-has-value');
        }
      } else {
        ionInput.classList.remove('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.remove('item-has-value');
        }
      }
    }
    } catch (error) {
        
    }
   
  }

  checkAllInputs() {
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo'
    ];

   formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        this.checkInput(controlName);
    }
});
  }
 clearAddress() {
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo'
    ];

    formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        control.setValue('');
      }
    });

    this.addressID = 0;
    this.mapLat = 0;
            this.mapLong = 0;

    this.checkAllInputs(); // Ensure labels are updated after clearing values
  }

  
 

    async initCustomerSearch(editMode)
    {
        this.findCustomer(this.getFormControl('customerName').value, editMode);
    }
    
    async initCustomerAddressSearch()
    {
        if(this.selectedCustomer != null)
            this.findCustomerAddress(this.getFormControl('address').value);
        else
            {
             this.translateService.get('General.SelectCustomer').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
      }
    }


      async findCustomer(query, editMode) {
        
      
        const modal = await this.modalCtrl.create({
            component: CustomerSearchComponent,
            cssClass: 'customer-search.component', componentProps: { query: query, editMode:editMode }
        });
        this.customerModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
            if (data) {
                
                this.customerSelected(data.data.customer);
               
            }
        });
    }


       async findCustomerAddress(query) {
        
        const modal = await this.modalCtrl.create({
            component: CustomerAddressSearchComponent,
            cssClass: 'customer-address-search.component', componentProps: { customerNo: this.selectedCustomer.CustomerNo, addressList: this.selectedCustomer.AddressList, query: query }
        });
        this.customerAddressModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
          try {
             if (data) {
               if(data != null)
                this.addressSelected(data.data.address);
               
            }
          } catch (error) {
            
          }
           
        });
    }
onGpsInputChange(event: any) {
    const value = event.detail.value; // The new value of the input field
    console.log('GPS Input Changed:', value);

    // Additional processing, such as validation or updating other component properties
    this.splitGpsCoordinates(value);
  }

 splitGpsCoordinates(coordinates: string) {


      if(coordinates.trim() == "")
      {
        this.mapLat = 0.0;
        this.mapLong = 0.0;
      }
      else
{
      try {
        const [latitudeStr, longitudeStr] = coordinates.split(',');

  if (latitudeStr && longitudeStr) {
    const latitude = parseFloat(latitudeStr.trim());
    const longitude = parseFloat(longitudeStr.trim());

    // Validate that parsed values are numbers
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.mapLat = latitude;
            this.mapLong = longitude;
  }else
{this.mapLat = 0.0;
  this.mapLong = 0.0;
}
  }
      } catch (error) {
        this.mapLat = 0;
    this.mapLong = 0;
      }
  
    }
}
  toggleMapVisibility() {
    this.isMapVisible = !this.isMapVisible;  // Toggle visibility
  }

    showCustomerNo()
  {
if(this?.selectedCustomer != null)
        return this.selectedCustomer?.CustomerNo == 0 ? true : false;
else
    return false;
  }

  
  addressSelected($event) {
        if ($event != null) {
                        this.customerAddressModal = true;

            const originalObject = $event;
            this.order.addressID = originalObject.AddressID;
            this.order.DeliveryActorNo = originalObject.AddressID;
            this.getFormControl('address').setValue(originalObject.Name == originalObject.Address1 ? this.selectedCustomer.Name : originalObject.Name);
            this.getFormControl('addressAddress1').setValue(originalObject.Address1);
            this.getFormControl('addressAddress2').setValue(originalObject.Address2);
            this.getFormControl('addressZip').setValue(originalObject.PostalCode);
            this.getFormControl('addressCity').setValue(originalObject.City);
           
            if(!this.bolKeepDetails){
            this.getFormControl('DeliveryEmail').setValue(originalObject.DeliveryEmail);
            this.getFormControl('internalInfo').setValue(originalObject.InternalInfo);
            this.getFormControl('customerBuyer').setValue(originalObject.ContactNameOnSite);
            this.getFormControl('tankInfo').setValue(originalObject.TankInfo);
            this.getFormControl('phoneOnSite').setValue(originalObject.PhoneOnSite);
            this.getFormControl('phoneManager').setValue(originalObject.ProjectManager);
            this.getFormControl('deliveryInfo').setValue(originalObject.DeliveryInfo);
           
                      //  this.customerAddressModal = false;
            }
            this.getFormControl('gps').setValue(originalObject.GPS);
                           

            this.splitGpsCoordinates(originalObject.GPS);
        }
    }

  

    customerSelected(customer) {
        {
            try {
                
                       this.customerModal = true;

            if (customer.CustomerBlockedStatus > 0) {
                this.getFormControl('customerName').setErrors({ incorrect: true });
                this.customerModal = false;
            } else {
                if(customer.CustomerNo != this.order.CustomerID)
                    {
                        this.clearAddress();
                          this.order.CustomerCreationStatus = 4;
                    }
                this.selectedCustomer = customer;
                this.order.CustomerID = customer.CustomerNo;
                //this.addressDataService.addressList = this.selectedCustomer.CustomerAddresses;
                //this.addressDataService.setErpIDParent(customer.ErpID);
                
                this.getFormControl('customerName').setValue(customer.Name);
                this.getFormControl('customerAddress1').setValue(customer.Address.trim() == '' ? customer.Address2 : customer.Address);
                this.getFormControl('customerAddress2').setValue('');
                this.getFormControl('customerPostalCode').setValue(customer.PostalCode);
                this.getFormControl('customerCity').setValue(customer.City);
                this.getFormControl('organizationNo').setValue(customer.OrgNo);
                this.getFormControl('email').setValue(customer.Email);
                  if(!this.bolKeepDetails){
                this.getFormControl('customerReference').setValue(customer.CustomerReference);
                  }
                if(this.selectedCustomer?.AddressList?.length > 0)
                    {
                        this.initCustomerAddressSearch();
                    }
                                    //   this.customerModal = false;

            }
             } catch (error) {
                console.error(error);
            }
          
        }
    }

      readOnly()
  {
    return !this.inEdit;
  }

    async editCustomerAndAddress() {

   //     this.inEdit =  !this.inEdit;
   //     setTimeout(()=>{
 //if(this.inEdit){this.initCustomerSearch(this.inEdit);}
   //     },100);

}

  toggleKeepDetails(change)
{
    this.bolKeepDetails = !change;
    return this.bolKeepDetails;
}
}
