<ion-header>
  <ion-toolbar>
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span *ngIf="!dataHost">{{ 'TankMonitoring.tankOverVakning' | translate }}</span>
      <span *ngIf="dataHost">{{ 'TankMonitoring.pageTitle' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px;">
    <div class="max-100">
      <div class="grid-width">
        <ion-grid class="full-height">
          <ion-row class="full-height">
            <ion-col>
              <ion-row><!--Host-->
                <ion-col size-xs="6" size-md="6" class="tank-monitoring" size-lg="6">
                  <ion-searchbar (ionChange)="filterChanged($event, false)" *ngIf="!dataHost"
                    placeholder="{{ 'TankMonitoring.searchPlaceHolder' | translate }}"></ion-searchbar>
                    <ion-searchbar [(ngModel)]="search" (ngModelChange)="filterRecords()" *ngIf="dataHost"
                    placeholder="{{ 'TankMonitoring.searchPlaceHolder' | translate }}"></ion-searchbar>
                </ion-col>
                <ion-col size-xs="12" size-sm="6" size-md="3" class="ion-padding-bottom" size-lg="3" *ngIf="!dataHost">
                  <strong class="block">{{ 'TankMonitoring.avdeling' | translate }}</strong>
                  <select size="1"
                    [(ngModel)]="department" (ngModelChange)="onDepartmentChange($event)">
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let item of departments" value="{{item}}">{{item}} </option>
                  </select>
                </ion-col>
                <ion-col size-xs="12" size-sm="6" size-md="3" class="ion-padding-bottom" size-lg="3" *ngIf="!dataHost">
                  <strong class="block">{{ 'TankMonitoring.drivstofftype' | translate }}</strong> <select size="1"
                    [(ngModel)]="fueltype" (ngModelChange)="filterChanged($event, true)">
                    <option value="">{{ 'General.select' | translate }}</option>
                    <option *ngFor="let item of fueltypes" value="{{item}}">{{item}} </option>
                  </select>
                </ion-col>
                <ion-col size-xs="12" size-md="6" class="ion-padding-bottom ion-text-md-right" size-lg="6" *ngIf="dataHost">
                    <span class="display-inline-block text-small">
                    <strong>{{ 'TankMonitoring.company' | translate }}</strong>&nbsp;
                    <select size="1" [(ngModel)]="company" class="datahost-select"
                    (ngModelChange)="filterRecords()">
                    <option value="0">{{ 'TankMonitoring.alle' | translate }}</option>
                    <option *ngFor="let company of companies" value="{{company.CompanyNo}}">
                        {{company.Name}}</option>
                     </select>
                     </span>
                  </ion-col>
              </ion-row>
              <hr />
              <ng-container *ngIf="!dataHost">
              <ion-row *ngFor="let item of filteredNorTraceItems" class="grey-text ion-no-padding">
                <ion-col class="ion-no-padding ion-padding-bottom"
                  [ngClass]="{'red': item.data.data.pri.percent <= 10, 'yellow': item.data.data.pri.percent > 10 ,'green': item.data.data.pri.percent > 50}">
                  <ion-row [ngClass]="{'border-class': item.expandedView==true}">
                    <ion-col class="ion-no-padding">
                      <ion-row (click)="expandNorTraceItem(item)">
                        <ion-col size-xs="10" size-md="4" class="odd row-padding ion-no-padding ion-padding-start">
                          <img src="assets/icon/Icon_TankMonitor.svg" class="vertical-middle" />
                          &nbsp;
                          <strong class="dark-text">{{item.name}}</strong>

                        </ion-col>
                        <ion-col size-xs="2" size-md="2" class="odd row-padding">
                          <ng-container *ngIf="item.custommerdata && item.custommerdata.customernumber"
                            class="hidden-xs">
                            <ion-icon name="person-outline" class="biggest-font-2 vertical-middle"></ion-icon>&nbsp;
                            <span
                              [ngClass]="{'dark-text': item.expandedView == true}">{{item.custommerdata.customernumber}}</span>&nbsp;&nbsp;
                          </ng-container>
                          <ion-badge [style.background]="item.colorCode">{{item.shortCode}}</ion-badge>
                        </ion-col>
                        <ion-col size-xs="9" size-md="1" class="odd row-padding">
                          <ion-range [(ngModel)]="item.data.data.pri.percent">
                          </ion-range>
                        </ion-col>
                        <ion-col size-xs="3" size-md="1" class="odd row-padding bigger-font">
                          <ion-text class="vertical-sub">{{item.data.data.pri.percent | number : '1.0-0'}}%</ion-text>
                        </ion-col>
                        <ion-col size-xs="11" size-md="3" class="odd row-padding ion-text-sm-right ion-padding-end">
                          <ion-icon [color]="item.days_since_report >= 3 ? 'danger' : 'success'"
                            [name]="item.days_since_report >= 3 ?'cloud-outline':'cloud-done'"
                            class="biggest-font-2 vertical-middle"></ion-icon>&nbsp;
                          <ion-text [color]="item.days_since_report >= 3? 'danger' : 'grey-text'">
                            <span>{{item.data.data.meta.aquisitiontimeRFC3339
                              | date:dateFormatTime}}</span></ion-text>
                        </ion-col>
                        <ion-col size-xs="1" size-md="1" class="odd row-padding ion-text-sm-right">
                          <div class="fav ion-text-right display-inline-block vertical-middle cursor-pointer">
                            <span (click)="markLevels(item);$event.stopPropagation();">
                            <ion-icon name="star-outline" *ngIf="!item.fav"></ion-icon> 
                            <ion-icon name="star" *ngIf="item.fav" color="primary"></ion-icon>
                            </span>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-col size-xs="12" *ngIf="item.expandedView">
                        <div class="overflow-x">
                        <div class="dataHost-grid">
                        <ion-row>
                          <ion-col size-xs="1" class="ion-padding-start" [style.height]="160+'px'">
                            <div class="volume">
                              <div class="volume-info"
                                style.height="{{item.data.data.pri.percent | number : '1.0-0'}}%">
                                <span class="dark-text"></span>
                              </div>
                            </div>
                          </ion-col>
                          <ion-col size-xs="11">
                            <ion-row>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.produkt' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font ion-text-uppercase">{{item.appdata.fueltype}}</b>&nbsp;
                                  <ion-badge [style.background]="item.colorCode">{{item.shortCode}}</ion-badge>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.niva' | translate }}
                                <br>
                                <span class="fuel-level">
                                  <b class="bigger-font">{{item.data.data.pri.volume | number : '1.0-0'}}L
                                    ({{item.data.data.pri.percent | number : '1.0-0'}})%</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.muligFylling' | translate }}
                                <br>
                                <span>
                                  <b
                                    class="bigger-font">{{(item.data.data.pri.volume / item.data.data.pri.percent * 100)-item.data.data.pri.volume | number : '1.0-0'}}L</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.tanktype' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font">{{item.appdata.tanktype}}</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.alaramLevel' | translate }}
                                <br>
                                <b class="bigger-font" *ngIf="item.appdata.alarm">{{item.appdata.alarm.alarm_level}}</b>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.alaramPhoneNumber' | translate }}
                                <br>
                                <ng-container
                                  *ngIf="item.appdata.alarm && item.appdata.alarm.alarm_phone_number && item.appdata.alarm.alarm_phone_number.length > 0">
                                  <span *ngFor="let phoneNumber of item.appdata.alarm.alarm_phone_number">
                                    <b class="bigger-font">{{phoneNumber}}</b>
                                  </span>
                                </ng-container>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.intervalMinutes' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font">{{item.appdata.schedule.interval_minutes}}</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.address' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font">{{item.appdata.address}}</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.temp' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font">{{item.data.data.sec.temp}}</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.press' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font">{{item.data.data.sec.press}}</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="3">
                                {{ 'TankMonitoring.battery' | translate }}
                                <br>
                                <span>
                                  <b class="bigger-font">{{item.data.data.sec.battery}}</b>
                                </span>
                              </ion-col>
                              <ion-col size-xs="12" text-right>
                                <ion-button fill="outline" class="no-padding" (click)="loadDrivingInstructions(item)">
                                  <ion-icon name="send" class="ion-no-padding" color="primary"></ion-icon>&nbsp;
                                  <ion-text color="black">
                                    {{
                                    'TankMonitoring.veibeskrivelse' | translate }}</ion-text>
                                </ion-button>
                                <ion-button fill="outline" class="no-padding" (click)="createTankOrder(item)"
                                  *ngIf="item.custommerdata >0">
                                  <ion-icon name="clipboard-outline" class="ion-no-padding" color="primary"></ion-icon>&nbsp;
                                  <ion-text color="black">{{
                                    'TankMonitoring.opprettOrdre' | translate }}</ion-text>
                                </ion-button>
                              </ion-col>
                            </ion-row>
                          </ion-col>
                        </ion-row>
                      </div>
                      </div>
                      </ion-col>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
              </ng-container>
              <ng-container *ngIf="dataHost">
                <ion-row *ngFor="let item of filteredTankInventory" class="tank-inventory">
                  <ion-col size-lg="12" *ngIf="item.FilteredInventory.length>0">
                    <div class="tank-name display-inline-block">
                      <img src="assets/icon/Icon_TankMonitor.svg" class="vertical-middle">
                      <span><strong>{{item.Tank.Name}}</strong></span>
                    </div>
                    <div class="fav ion-text-right display-inline-block cursor-pointer">
                      <span (click)="markTanks(item)">
                      <ion-icon name="star-outline" *ngIf="!item.fav"></ion-icon> 
                      <ion-icon name="star" *ngIf="item.fav" color="primary"></ion-icon>
                      </span>
                    </div>
                  </ion-col>
                  <ion-row class="w-100" *ngIf="item.FilteredInventory.length>0">
                    <ion-col size-sm="6" size-md="4" size-lg="3" size-xs="6" *ngFor="let inventory of item.FilteredInventory">
                      <ion-row class="w-100 border-class h-100 padding-8 box-shadow" [ngClass]="{'red': calculatePercent(inventory) <= 10, 'yellow': calculatePercent(inventory) > 10 ,'green': calculatePercent(inventory) > 50}">
                          <ion-col size="3">
                              <div class="volume">
                                <div class="volume-info" style.height="{{calculatePercent(inventory) | number : '1.0-0'}}%">
                                </div>
                                <span class="dark-text percent-text">{{calculatePercent(inventory) | number : '1.0-0'}}%</span>
                              </div>
                          </ion-col>
                          <ion-col size="9">
                            <div class="margin-boottom-4 ion-text-uppercase"><strong>{{inventory.ProductDescription}}</strong>&nbsp;
                              <ion-badge [style.background]="inventory.ProductColorCode">{{inventory.ProductShortCode}}</ion-badge>
                            </div>
                            <div class="margin-boottom-4">
                                <strong>{{ 'TankMonitoring.quantity' | translate }}:&nbsp;{{inventory.Quantity}}L</strong>
                            </div>
                            <div class="margin-boottom-4">
                                {{ 'TankMonitoring.volum' | translate }}:&nbsp;{{inventory.MaxQuantity}}L
                            </div>
                            <div class="margin-boottom-4">
                                {{ 'TankMonitoring.tankNR' | translate }}:&nbsp;<strong>{{inventory.TankRoomNo}}</strong>
                            </div>
                          </ion-col>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                  <hr class="w-100" *ngIf="item.FilteredInventory.length>0" />
                </ion-row>
              </ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div>
    <ng-container *ngIf="dataHost">
        {{ 'TankMonitoring.tenkerMedLaviNiva' | translate }}
        <ion-toggle class="inline-block m-10" color="success" [(ngModel)]="lowVolume"
          (ionChange)="filterRecords()"></ion-toggle>&nbsp;&nbsp;
          {{ 'TankMonitoring.favorites' | translate }}
          <ion-toggle class="inline-block m-10" color="success" [(ngModel)]="favorites"
          (ionChange)="filterRecords()"></ion-toggle>
    <div class="pull-right">
      <span class="display-inline-block text-small">
      <strong>{{ 'TankMonitoring.produkt' | translate }}</strong> &nbsp;&nbsp;
      <select [(ngModel)]="prioritet" (ngModelChange)="filterRecords()" class="datahost-select">
        <option [ngValue]="-1">{{ 'TankMonitoring.alle' | translate }}</option>
        <option *ngFor="let item of products" [ngValue]="item.Description">{{ item.Description }}</option>
      </select>
      </span>
      </div>
    </ng-container>
    <ng-container *ngIf="!dataHost">
        {{ 'TankMonitoring.tenkerMedLaviNiva' | translate }}
        <ion-toggle class="inline-block m-10" color="success" [(ngModel)]="lowVolume"
          (ionChange)="filterChanged($event, true)"></ion-toggle>
        &nbsp;&nbsp; {{ 'TankMonitoring.tenkerINearHeten' | translate }}
        <ion-toggle class="inline-block m-10" color="success" [(ngModel)]="nearMeg"
          (ionChange)="filterChanged($event, true)"></ion-toggle>
        &nbsp;&nbsp;
        <input type="number" autocomplete="off" class="nearmeg-input" [(ngModel)]="nearMegDistance"
          (ngModelChange)="filterChanged($event, true)" [disabled]="!nearMeg" />
      </ng-container>
    </div>
    <div class="clear"></div>
  <div class="primary footer-border">
      <ion-button fill="outline" class="no-padding" (click)="navigateToBack()">
          <ion-icon name="book-outline" color="primary"></ion-icon>&nbsp;
          <span class="black">{{ 'TankMonitoring.back' | translate }}</span>
      </ion-button>
    <div class="pull-right hidden-xs">
      <ion-button fill="outline" class="no-padding" routerLink="/orders" *ngIf="!dataHost">
        <ion-icon name="clipboard-outline" class="ion-no-padding" color="primary"></ion-icon>&nbsp;<ion-text color="black"> {{
          'TankMonitoring.order'|
          translate }}</ion-text>
      </ion-button>
      <ion-button fill="outline" class="no-padding" routerLink="/egne-tanker-select" *ngIf="dataHost">
        <ion-icon name="clipboard-outline" class="ion-no-padding" color="primary"></ion-icon>&nbsp;<ion-text color="black"> {{
          'TankMonitoring.egneTanker'| translate }}</ion-text>
      </ion-button>
    </div>
  </div>
</ion-footer>