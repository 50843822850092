import { Component, OnInit } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationExtras } from '@angular/router';
import { InternalOrdertype } from 'src/app/shared/enums';
import _ from 'lodash';
@Component({
    selector: 'page-workorder-approval',
    templateUrl: 'workorder-approval.html',
    styleUrls: ['workorder-approval.scss'],
})

export class WorkorderApprovalPage implements OnInit {
    orders: any[];
    filteredOrders: any[];
    sortProperty = 'OrderSorting';
    sortDirection = 'asc';
    dateFormat: any;
    transporters: any;
    currentTransporter=4;
    search='';

    constructor(
        public navCtrl: NavController, public userService: UserService, private translateService: TranslateService,
        private loadingController: LoadingController, private router: Router) { }

    ngOnInit() {
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });
           // this.userService.GetTransporters().subscribe(data => { this.transporters = data; });
             this.transporters = [
                {ID:4, Description: 'Til godkjenning'},
                {ID:1, Description: 'Godkjent'}
            ];

        this.loadData(false);
    }

    
    ionViewDidEnter() {

  this.currentTransporter = 4;
            this.loadData(false);
     
    }
    
 clearSearch()
    {
        this.search = ''; 
        this.filterChanged(null);
    }
    loadData(approved:boolean) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                if(approved)
                {
                      this.userService.getApprovedOrders(InternalOrdertype.Workorder, this.search).subscribe(data => {
                    this.orders = data;
                    this.filterData();
                   // this.sort('OrderNo');
                    loader.dismiss();
                });

                }
                else
                this.userService.getOrdersForApproval(InternalOrdertype.Workorder).subscribe(data => {
                    this.orders = data;
                    this.filterData();
                   // this.sort('OrderNo');
                    loader.dismiss();
                });
            });
        });
    }

     getinvoicePDF(invoiceNo) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                
                this.userService.getInvoicePDF(invoiceNo).subscribe(data => {
                   try {
                    this.downloadPDF(data, invoiceNo);
                    loader.dismiss();
                   } catch (error) {
                    console.log(error + " - " + data);
                    loader.dismiss();
                   } 
                });
        });
        });
    }

    
    // Function to convert a base64 string to a Blob
base64ToBlob(base64: string, type = 'application/pdf') {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: type });
}

// Function to trigger the download of the PDF
downloadPDF(base64String: string, name:string) {
  const blob = this.base64ToBlob(base64String);
  const blobUrl = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = name + '.pdf'; // Name of the downloaded file
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(blobUrl); // Clean up the URL object
}


    isOrderMine(order): boolean {
        if (order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

    navigteToOrderDetilsSales(order) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                order: JSON.stringify(order),
                approval: true,
            },
            skipLocationChange: true
        };
        this.router.navigate(['/work-order-details'], navigationExtras);
    }

    approveForInvoice(order) {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.approveForInvoice(order).subscribe(data => {
                    order.Approve = true;
                    this.removeApprovedItem(order);
                    loader.dismiss();
                });
            });
        });
    }

    sort(property) {
        if (this.sortProperty === property) {
            this.sortDirection = ('' || this.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
            this.sortProperty = property;
            this.sortDirection = 'desc';
        }
        this.filteredOrders = Utils.orderBy(this.filteredOrders, this.sortProperty, this.sortDirection);
    }

    expandOrder(order) {
        order.expandedView = !order.expandedView;
    }

    expandAllOrders() {
        this.orders.forEach(order => {
        order.expandedView = !order.expandedView;
        });
    }


    removeApprovedItem(order) {
        this.orders.splice(
            this.orders.findIndex(x => x.OrderNo = order.OrderNo),
            1
        );

    }

    filterChanged($event) {
        this.filterData();
    }

    filterOrders($event)
    {
        //this.search = '';

        if(this?.currentTransporter== 4)
            {
                
                this.loadData(false);
                //value = value && order.Transporter == this.currentTransporter;
            }
            else{
                this.loadData(true);}
                }
    
    filterData(){
        this.filteredOrders = _.filter(this.orders, (order) => {
            let value = true;
         
 //search
            if(this.search.length >= 3)
            {
                value = value && (order.OrderNo.toString().toLowerCase().includes(this.search.toLowerCase()) 
                    || order.CustomerName.toLowerCase().includes(this.search.toLowerCase())
                    || order.DeliveryInfo.toLowerCase().includes(this.search.toLowerCase())
                    || order.CustomerReference.toLowerCase().includes(this.search.toLowerCase())
                    || order.CustomerPurchaseOrderNo.toLowerCase().includes(this.search.toLowerCase())

                    
                    );
            }
            
            return value;

        });
    }
}
