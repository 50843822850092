import { Component, DebugElement, OnDestroy } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TransactionType } from '../../shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { LeggTilProduktComponent } from '../../components/legg-til-produkt/legg-til-produkt';
import { OrderNextDayConfirmComponent } from '../../components/order-next-day-confirm/order-next-day-confirm';
import { OrderConfirmComponent } from '../../components/order-confirm/order-confirm'; // DrivingInstructions
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLauncher } from '@capacitor/app-launcher';
import { Geolocation } from '@capacitor/geolocation';
import _ from 'lodash';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { CustomValidators } from '../../shared/validators';
import { Console } from 'console';

@Component({
    selector: 'page-ordre-details',
    templateUrl: 'ordre-details.html',
    styleUrls: ['ordre-details.scss']
})
export class OrdreDetailsPage implements OnDestroy {
    order: any;
    dateFormat;
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    currentLatitude: number = null;
    currentLongitude: number = null;
    VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = false;
    leafletOptions: any;
    private currentTruckTrailerSub$: Subscription;
    private deviationLimit = 0;
    private inventoryList: any;
    errorMessages: any = [];
    expand = false;
    UsePlanner = false;
 selectedDeliveryDate = new Date().toISOString();
 dateChanged:boolean = false;
 openModal = false;

    private navigationExtras: NavigationExtras;


    constructor(
        private formBuilder: FormBuilder, private route: ActivatedRoute, public userService: UserService,
        public navCtrl: NavController, private router: Router,
        private translateService: TranslateService, private loadingController: LoadingController, private modalCtrl: ModalController,
        private geolocation: Geolocation, private toastController: ToastController,
        private storage: Storage) {
        this.route.queryParams.subscribe(params => {
            this.order = JSON.parse(params.order);
        //  this.selectedDeliveryDate = this.order.DeliveryDate;
            const orderlinesGroup: FormGroup = new FormGroup({});

            // used to pass info to orders view for init an update of orderslist
            this.navigationExtras = {
                queryParams: {
                    reloadData: true
                },
                skipLocationChange: true
            };

            this.storage.get(AppSettings.StorageKeys.UsePlanner).then(planner => { this.UsePlanner = planner; });


            this.userService.smUpLargeScreen.subscribe(data => {
                if (data) {
                    this.expand = data;
                }
            });

            this.currentTruckTrailerSub$ = this.userService.getCurrentTruckAndTrailer().subscribe(resp => {
                if (AppSettings.ValidateTruckStock) {
                    this.userService.getCurrentInventoryCurrent(null, null).subscribe(data => {
                        this.inventoryList = data.InventoryList2;
                        this.userService.getDeviationLimit(TransactionType.LoadingFromTruck).subscribe(totalData => {
                            this.deviationLimit = totalData.DeviationLimit;
                        });
                    });
                }
            });



            if (this.order.Orderlines) {
                let orderline;
                for (orderline of this.order.Orderlines) {
                    const control: FormControl = new FormControl(orderline.QuantityDelivered,
                        Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
                    orderlinesGroup.addControl('o' + orderline.LineNo, control);
                }
            }
            this.detailsForm = formBuilder.group({
                orderLines: orderlinesGroup,
                orderComments: [this.order.OrderComments],
                orderInternalComments: [this.order.orderInternalComments],
                additionalProductsDelivered: [this.order.AdditionalProductsDelivered],
                doNotCalculateVolume: [this.order.DoNotCalculateVolume],
                doNotSendGPS: [this.order.DoNotSendGPS],
                deliveryDate: [this.selectedDeliveryDate],
                emailTo: ['', [CustomValidators.emailValidator]]
            });

            this.updateCommentValidators();

            // Validators.compose([Validators.required])

            this.translateService.get('General.dateFormat').subscribe(value => {
                this.dateFormat = value;
            });


       
            
            this.leafletOptions = {
                layers: [
                    L.tileLayer('https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png',
                        { maxZoom: 18, attribution: ' ' }),
                    L.marker([this.order.Latitude, this.order.Longitude], {
                        icon: L.icon({
                            iconSize: [30, 41],
                            iconAnchor: [13, 0],
                            iconUrl: 'assets/icon/marker-icon.png',
                            shadowUrl: 'assets/icon/marker-shadow.png'
                        })
                    }).bindPopup(this.order.CustomerName)
                ],
                zoom: 10,
                center: L.latLng({ lat: this.order.Latitude, lng: this.order.Longitude })
            };

            const posOptions = {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 30000

            };
            const that = this;
            const showGeolocationError = function error() {
                /*      that.translateService.get('Error.locationError').subscribe(value => {
                          that.toastController.create({
                              message: value,
                              duration: 3000
                          }).then(toast => { toast.present(); });
      */
                that.currentLatitude = that.currentLongitude = null;
                //              });
            };

          
        });
    }

     closeModal(){
    this.openModal = false;
}


  ngOnInit() {
    this.getCurrentLocation();
  }

  async getCurrentLocation() {
    const posOptions = {
      enableHighAccuracy: true, // Set based on your requirements
      timeout: 10000, // Set based on your requirements
      maximumAge: 0 // Set based on your requirements
    };

    await Geolocation.getCurrentPosition(posOptions).then(resp => {
      if (resp.coords) {
        this.currentLatitude = resp.coords.latitude;
        this.currentLongitude = resp.coords.longitude;
      }
    }).catch();

    const watch = await Geolocation.watchPosition({}, (position, err) => {
      if (err) {
       // this.showGeolocationError();
        return;
      }
      if (position && position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      }
    });
  }


    validateQuantity(orderline) {
        const control = this.detailsForm.controls.orderLines;
        orderline.QuantityDelivered = control.get('o' + orderline.LineNo).value;
        if (this.inventoryList != null) {
            this.errorMessages = [];
            let item;
            for (item of this.order.Orderlines) {
                if (item.ProductType === 10) {
                    let truckProduct = _.find(this.inventoryList, (p: any) => {
                        return p.ProductNo === item.InventoryProductNo;
                    });
                    if (!truckProduct) {
                        this.translateService.get('LeggTilProdukt.productNotFound').subscribe(value => {
                            this.errorMessages.push(value);
                        });
                        return;
                    } else {
                        if (truckProduct.InventoryProductNo) {
                            truckProduct = _.find(this.inventoryList, (p: any) => {
                                return p.ProductNo === truckProduct.InventoryProductNo;
                            });
                        }

                        let maxValue: any = null;

                        if (truckProduct.Quantity + this.deviationLimit < item.QuantityDelivered) {
                            maxValue = truckProduct.Quantity;
                        }
                        if (maxValue != null) {
                            this.translateService.get('OrdreDetails.maxValue').subscribe(value => {
                                this.errorMessages.push(value.format(item.Description, maxValue));
                            });
                        }
                    }
                }
            }
        }
    }

    updateCommentValidators() {
        const commentControl = this.detailsForm.get('orderComments');
        if (this.detailsForm.get('additionalProductsDelivered').value) {
            commentControl.setValidators([Validators.required]);
        } else {
            commentControl.setValidators([]);
        }
        commentControl.updateValueAndValidity();
    }

    deliveredQuantityExists() {
        const orderline = _.find(this.order.Orderlines, (orderLine: any) => {
            return orderLine.QuantityDelivered > 0;
        });

        if (orderline) {
            return true;
        }
        return false;

    }

    additionalProductsDeliveredChanged() {
        this.updateCommentValidators();
    }

    async  moveOrderToNextDay() {
        const modal = await this.modalCtrl.create(
            {
                component: OrderNextDayConfirmComponent,
                cssClass: 'order-next-day-confirm'
            });
        modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.moveOrderToNextDay(this.order.OrderNo).subscribe(resp => {
                            loader.dismiss();
                            this.router.navigate(['/orders']);
                        });
                    });
                });
            }
        });
    }

    async leggTilProdukt() {
        const modal = await this.modalCtrl.create({
            component: LeggTilProduktComponent,
            cssClass: 'legg-til-produkt',
            componentProps: { inventoryList: this.inventoryList, deviationLimit: this.deviationLimit }
        });
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                const orderline: any = {
                    ProductColorCode: data.data.Product.ColorCode,
                    ProductShortCode: data.data.Product.ShortCode,
                    Description: data.data.Product.Description,
                    LineNo: this.identity,
                    ProductNo: data.data.Product.ProductNo,
                    Quantity: data.data.Quantity,
                    QuantityDelivered: data.data.Quantity,
                    UserChangedPrice:false,
                    UserChangedCost:false,
                    UserChangedFreight:false

                };

                this.order.Orderlines.push(orderline);

                const control: FormControl = new FormControl(orderline.QuantityDelivered,
                    Validators.compose([Validators.required, Validators.pattern('\\d+')]));
                const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
                formGroup.addControl('o' + orderline.LineNo, control);

                this.identity--;
            }
        });
    }

    async setOrderAsCompleted() {
        const modal = await this.modalCtrl.create({
            component: OrderConfirmComponent,
            cssClass: 'order-confirm',
            componentProps: { order: this.order }
        });


        modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });

                        const selectedDate = this.dateChanged ? this.detailsForm.get('deliveryDate').value : this.selectedDeliveryDate;

                        let date = new Date();

                        if(selectedDate != undefined){
                            date = new Date(selectedDate);
                        }
                        
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));

                      
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                         this.order.DeliveryDate = selectedDate;//this.detailsForm.get('deliveryDate').value;
                        this.order.ConfirmedDeliveryDate = selectedDate;//this.order.DeliveryDate;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.order.EmailTo = this.detailsForm.get('emailTo').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });

                        if (this.detailsForm.get('doNotSendGPS').value) { // do not send gps location
                            this.order.Longitude = -1;
                            this.order.Latitude = -1;
                        } else { // send gps location
                            this.order.Latitude = this.currentLatitude;
                            this.order.Longitude = this.currentLongitude;
                        }

                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();
                                this.router.navigate(['/orders'], this.navigationExtras);
                            });
                        });
                    });
                }
            }
        });
    }

    isOrderMine(): boolean {

        
        if (this.order.AssignedDriver && this.order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

   async loadDrivingInstructions() {
    let destinationUrl;

    // Check if coordinates are valid, otherwise use address
    if (this.order.Latitude !== 0 && this.order.Longitude !== 0) {
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.order.Latitude},${this.order.Longitude}`;
    } else {
        // Encode the address
        const encodedAddress = encodeURIComponent(`${this.order.DeliveryAddress1} ${this.order.DeliveryPostalCode} ${this.order.DeliveryCity}`);
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    }

    // Try opening the URL
    try {
        await AppLauncher.openUrl({ url: destinationUrl });
    } catch (error) {
        console.error('Error launching navigation:', error);
        this.toastController.create({
            message: 'Could not open navigation: ' + error.message,
            duration: 3000
        }).then(toast => toast.present());
    }
}


    unassignOrder() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.unassignOrder(this.order.OrderNo).subscribe(data => {
                    this.order.AssignedDriver = 0;
                     if(this.UsePlanner)
                    {
                        this.order.AssignedToTruck = 0;
                        this.order.AssignedToTrailer = 0;
                    }
                    
                    loader.dismiss();
                    this.router.navigate(['/orders'], this.navigationExtras);
                });
            });
        });
    }

    ngOnDestroy() {
        this.currentTruckTrailerSub$.unsubscribe();
    }

    navigateToOrdersList() {
        this.navCtrl.pop();
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    
    plukkOrApne() {
        
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.assignOrderToDriver(this.order.OrderNo, null).subscribe(data => {
                        this.order.AssignedDriver = AppSettings.Username;
                        loader.dismiss();
                        this.router.navigate(['/orders'], this.navigationExtras);
                        //this.loadData();
                    });
                });
            });
        
    }

     dateTimeUpdated(ev:any)
    {
        try {
        
        this.selectedDeliveryDate = ev.detail.value;
        this.dateChanged = true;
        } catch (error) {
        console.error(error);
        }
        
    }

    showDeliveryDate()
    {
        if(this.dateChanged)
            return this.selectedDeliveryDate;
        else
            return this.order.ConfirmedDeliveryDate;
      
    }


}
