import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';

@Component({
    selector: 'new-message',
    templateUrl: 'new-message.html',
    styleUrls: ['new-message.scss']
})
export class NewMessageComponent {
    public messageForm: FormGroup;
    public drivers: any[];

    constructor(
        public modalCtrl: ModalController, private formBuilder: FormBuilder, private loadingController: LoadingController,
        private translateService: TranslateService, private userService: UserService) {

        this.messageForm = formBuilder.group({
            userID: ['0', Validators.compose([Validators.required])],
            message: ['', Validators.compose([Validators.required])]
        });
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {
                this.userService.getDrivers().subscribe(drivers => this.drivers = drivers);
                loader.dismiss();
            });
        });
    }

    newMessage() {
        const userID = this.messageForm.get('userID').value;
        const message = this.messageForm.get('message').value;

        this.modalCtrl.dismiss({ UserID: userID, Message: message, Created: new Date() });
        // although date is assigned here but real date is assigned from the server in newmessage().
        // Here it is assigned so when we push the data after saving date is available locally to us.
        // This date may be wrong however for the purpose of efficient it is done so we don't have to
        // refetch all messages again. -> 16th Jul, 2017
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
