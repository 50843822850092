<div class="pull-right">
  <ion-button (click)="logout()" class="logout vertical-middle" fill="clear">
    <ion-icon slot="icon-only" size="large" name="power-outline"></ion-icon>
  </ion-button>
</div>
<div class="pull-right">
  <div class="dash-line-1 hidden-xs">
    <ion-icon
      ion-text
      color="primary"
      name="person-outline"
      class="hidden-xs vertical-text-bottom"
    ></ion-icon>
    <strong class="hidden-xs">{{name}}</strong>
    <ion-icon
      ion-text
      color="primary"
      name="business-outline"
      class="vertical-text-bottom ion-margin-start"
    ></ion-icon>
    {{company}}
  </div>
  <div class="dash-line-2">
    <ion-button
      *ngIf="userService.localStorageData.length > 10000000"
      (click)="processQueue()"
      class="vertical-top"
    >
      <ion-text color="black">
        <span>
          <ion-icon
            name="sync-outline"
            slot="icon-only"
            color="primary"
          ></ion-icon>
          {{ 'Sync.sync' | translate }}
        </span>
      </ion-text>
    </ion-button>
    <div
      class="blinkWrapper blink hidden-xs m-r-10"
      *ngIf="showAlert"
      (click)="navigateHome()"
    >
      <span>
        <ion-icon name="chatbox-ellipses-outline" color="primary"></ion-icon> {{
        'Home.nyMelding' | translate }}
      </span>
    </div>
    <div
      class="blinkWrapper blink hidden-xs m-r-20"
      *ngIf="showReleaseAlert"
      (click)="navigateToRelease()"
    >
      <span>
        <ion-icon name="megaphone-outline" color="primary"></ion-icon> {{
        'Home.newVersion' | translate }}
      </span>
    </div>
    <ion-button
      class="vertical-top"
      fill="clear"
      (click)="showTrucksTrailersModal()"
    >
      <img src="assets/icon/Icon_Truck.svg" />
      <span ion-text class="black mr-10"
        >{{currentTruckTrailer?.Truck?.Description}}</span
      >
    </ion-button>
    <ion-button
      class="vertical-top hidden-xs"
      fill="clear"
      (click)="showTrucksTrailersModal()"
    >
      <img src="assets/icon/Icon_Trailer.svg" />
      <span ion-text class="black"
        >{{currentTruckTrailer?.Trailer?.Description}}</span
      >
    </ion-button>
  </div>
</div>
