import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { ModalController, NavParams, LoadingController, IonInput } from '@ionic/angular';
import _ from 'lodash';
import { AppSettings } from 'src/app/shared/app-settings';

@Component({
    selector: 'legg-til-produkt-sales',
    templateUrl: 'legg-til-produkt-sales.html',
    styleUrls: ['legg-til-produkt-sales.scss']
})
export class LeggTilProduktSalesComponent {
    leggForm: FormGroup;
    products: any[];
    featuredProducts:any[];
    searchProduct: any[] = [];
    private inventoryList: any[];
    private deviationLimit = 0;
    product;
@ViewChild('qty', { static: false }) qty: IonInput;


    constructor(
        public viewCtrl: ModalController, private userService: UserService, private translateService: TranslateService,
        public navParams: NavParams, private loadingController: LoadingController, private formBuilder: FormBuilder) {
        this.inventoryList = this.navParams.data.inventoryList;
        this.deviationLimit = this.navParams.data.deviationLimit;
        this.leggForm = formBuilder.group({
            productNo: ['', Validators.compose([Validators.required])],
            quantity: ['', Validators.compose([Validators.required, Validators.pattern('\\d+')])]
        });
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {

                try {
                     var ftProd = localStorage.getItem(AppSettings.StorageKeys.FeaturedProducts);
                     if(ftProd)this.featuredProducts = JSON.parse(ftProd);
                } catch (error) {
                    
                }
               
                

                this.userService.getProducts().subscribe(data => {
                    this.products = data;
                    loader.dismiss();
                });
            });
        });
    }

    ngAfterViewInit()
    {
         if (this.qty) {
            this.qty.getInputElement().then(input => {
                input.addEventListener('keyup', (event) => {
                    if (event.key === 'Enter') {

                        if(this.leggForm.get('quantity').value != '')
                        this.addProdukt();
                    }
                });
            });
        }
    }

    addProdukt() {
        if (this.product) {
            this.viewCtrl.dismiss({ Product: this.product, Quantity: this.leggForm.get('quantity').value });
        }
    }

    searchProducts(event) {
        this.product = null;
        this.searchProduct = _.filter(this.products, (product) => {
            // search for text, prodno and EAN
            if (product.EAN.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
                || product.Description.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
                || product.ProductNo.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) {
                return product;
            }
        });
    }

    selectProduct(product) {
        this.leggForm.get('productNo').setValue(product.Description);
        this.product = product;
        this.searchProduct = [];
        this.qty.setFocus();
    }

    dismiss() {
        this.viewCtrl.dismiss();
    }
}
