import { Component, ViewChild } from '@angular/core';
import { NavController, LoadingController, AlertController } from '@ionic/angular';
import { ActivityView, RespUnit } from '../../shared/enums';
import { UserService } from '../../shared/user-service';
import { Utils } from '../../shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import _ from 'lodash';
import * as moment from 'moment';
/*import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';*/
import { IonicSelectableComponent } from 'ionic-selectable';



@Component({
    selector: 'page-activity-registration-multiple',
    templateUrl: 'activity-registration-multiple.html',
    styleUrls: ['activity-registration-multiple.scss']
})
export class ActivityRegistrationMultiplePage {
    currentDate: any = new Date();
    activities: any[];
    activityTypes: any[];
    activityOrders: any[];
    departments: any[];
    projects: any[];
    dateFormat;
    activityForm: FormGroup;
    submitting = false;
    submitAttempt = false;
    private modalTitle;
    // wageTypes: any;
    activityView: any = ActivityView;
    view: any = this.activityView.DateView;
    private wageTypesGroup: FormGroup;
    expand = false;
    activityList: any[] = [];
    identity = -1;
    timeSpanTwoDays = false;
    totalTime = '0';
    regExQty = "'^\\d+\\.\\d{2}$'";
    showAddExtra = false;
    showAddEndDate = false;
    clockRequiered = true;
    endDate = new Date().toISOString();
    holidayName = "";
    openModal = false;

    @ViewChild('picker')
    private picker: any;

    constructor(
        public navCtrl: NavController, private userService: UserService, private alertCtrl: AlertController,
        private loadingController: LoadingController, private translateService: TranslateService, private formBuilder: FormBuilder) {
        this.userService.smUpLargeScreen.subscribe(data => {
            if (data) {
                this.expand = data;
            }
        });
        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });
        this.loadData();
    }

        closeModal(){
    this.openModal = false;
}

    onTimeChange() {
        if (this.activityForm.get('fromTime').value !== '' && this.activityForm.get('toTime').value !== ''
            && this.activityForm.get('fromTime').value !== null && this.activityForm.get('toTime').value !== null) {
            const fromTime = moment(this.activityForm.get('fromTime').value, 'HH:mm');
            const toTime = moment(this.activityForm.get('toTime').value, 'HH:mm ');
            if (toTime.isAfter(fromTime)) {
                this.timeSpanTwoDays = false;
                const difference = moment.duration(toTime.diff(fromTime));
                const precision = +((100 * difference.minutes() / 60).toFixed(0));
                const time = +([difference.hours(), precision].join('.'))
                this.totalTime = (time).toFixed(2);
            } else {
                this.timeSpanTwoDays = true;
                this.totalTime = '0';

                //split hours in two days
                let midnight = moment("23:59", 'HH:mm');
                let difference = moment.duration(midnight.diff(fromTime));
                let precision = +((100 * difference.minutes() / 60).toFixed(0));
                let time = +([difference.hours(), precision].join('.'))
                this.totalTime = (time).toFixed(2);

                midnight = moment("00:00", 'HH:mm');
                difference = moment.duration(toTime.diff(midnight));
                precision = +((100 * difference.minutes() / 60).toFixed(0));
                time = +([difference.hours(), precision].join('.'))

                let tot:number = this.roundByQuarter(+this.totalTime +time);

                this.totalTime = tot.toFixed(2);
            }


        }
    }

    roundByQuarter(value) {
        var inv = 1.0 / 0.25;
        return Math.round(value * inv) / inv;
    }

    getDifference() {
        const fromTime = moment(this.activityForm.get('fromTime').value, 'HH:mm');
        const toTime = moment(this.activityForm.get('toTime').value, 'HH:mm ');
        const difference = moment.duration(toTime.diff(fromTime));
        const precision = +((100 * difference.minutes() / 60).toFixed(0));
        const time = +([difference.hours(), precision].join('.'));
        const totalQuntity = this.getTotalActivityListQuantity();
        return (+this.totalTime-totalQuntity);
    }

  summaryAlert()
  {
      if(this.clockRequiered)
      return this.getDifference() !==0 ? true : false;
      else
      return false;
  }

  toggleShowAddExtra()
  {
      this.showAddExtra = !this.showAddExtra;
  }

  toggleShowEndDate()
  {
      this.showAddEndDate = !this.showAddEndDate;
  }

  openPicker() {
    
 //   this.picker.open();
}


    getTotalQuantity() {
        return _.sumBy(this.activities, activity => {
            if(this.activityTypes.find(i => i.ActivityNo == activity.ActivityType).ExcludeFromSummary === false)
            return activity.Quantity;
        });
    }

    loadData() {
        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {

                forkJoin([this.userService.getRegistrations(this.currentDate, this.view),
                this.userService.getResponsibleUnits(RespUnit.ActivityOrders),
                this.userService.getResponsibleUnits(RespUnit.Department),
                this.userService.getResponsibleUnits(RespUnit.Project), this.userService.getActivityTypes()]).subscribe(data => {
                    this.activities = data[0];
                    this.activityOrders = null; // data[1];
                    this.departments = data[2];
                    this.projects = data[3];
                    this.activityTypes = data[4];
                    this.wageTypesGroup = new FormGroup({});
                    this.activityForm = this.formBuilder.group({
                        comment: ['', Validators.compose([])],
                        fromTime: ['', Validators.compose([Validators.required])],
                        toTime: ['', Validators.compose([Validators.required])],
                        department: ['', Validators.compose([])],
                        project: ['', Validators.compose([])],
                        orderNo: ['', Validators.compose([])],
                        activity: ['', Validators.compose([])],
                        endDate: ['', Validators.compose([])],
                        qty: [0, Validators.compose([])],
                        wageTypes: this.wageTypesGroup,
                    });
                    this.addPredefinedWagetypes();
                    this.checkHoliday();
                    
                    loader.dismiss();
                });
            });
        });
    }

 

    loadRegistrations() {
        this.userService.getRegistrations(this.currentDate, this.view).subscribe(data => {
            this.activities = data;
        });
    }

    deleteActivity(activity) {
        this.submitting = true;
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.deleteActivity(activity.ActivityID).subscribe(data => {
                    this.submitting = false;
                    this.activities = _.remove(this.activities, (current) => {
                        return current.ActivityID !== activity.ActivityID;
                    });
                    loader.dismiss();
                });
            });
        });
    }

    timeSplitter(fromTime, toTime, value)
    {
         //split hours in two days
         let midnight = moment("23:59", 'HH:mm');
         let difference = moment.duration(midnight.diff(fromTime));
         let precision = +((100 * difference.minutes() / 60).toFixed(0));
         let time = +([difference.hours(), precision].join('.'))
         let day1:number = this.roundByQuarter(+time);

         midnight = moment("00:00", 'HH:mm');
         difference = moment.duration(toTime.diff(midnight));
         precision = +((100 * difference.minutes() / 60).toFixed(0));
         let time2 = +([difference.hours(), precision].join('.'))

         let day2:number = this.roundByQuarter(+time2);

        return value == 1?day1 : day2;
    }

    save() {
        this.submitAttempt = true;
        if (this.activityForm.valid) {
            const controls = this.activityForm.controls;
            const activities: any[] = [];
            const twoSpanCompleted:boolean = false;
            let twoSpanSummary:number = 0;
            let twoSpanSummary2:number = 0;

            for (const item of this.activityList) {
                const activity: any = {};

                if (controls.wageTypes.get('q' + item.lineNo).value > 0) {

                    activity.DateStr = (Utils.padNumber(this.currentDate.getMonth() + 1)) + '-'
                        + (Utils.padNumber(this.currentDate.getDate())) + '-' + (Utils.padNumber(this.currentDate.getFullYear()));
                    activity.Description = item.activity.Description;
                    activity.OrderNo = this.activityForm.get('orderNo').value.UnitID;
                    activity.Project = this.activityForm.get('project').value.UnitID;
                    activity.Invoiceable = false;
                    activity.Comment = this.activityForm.get('comment').value;
                    activity.EndDate = this.activityForm.get('endDate').value;
                    activity.ActivityType = item.activity.ActivityNo;
                    activity.Department = this.activityForm.get('department').value.UnitID;

                    let time1 = this.timeSplitter(moment(this.activityForm.get('fromTime').value, 'HH:mm'), moment(this.activityForm.get('toTime').value, 'HH:mm'), 1)
                     let time2 =  this.timeSplitter(moment(this.activityForm.get('fromTime').value, 'HH:mm'), moment(this.activityForm.get('toTime').value, 'HH:mm'), 2);
                    let userQty = controls.wageTypes.get('q' + item.lineNo).value;
                    let restTwoSpan = time1 - twoSpanSummary;

                    activity.Quantity = this.timeSpanTwoDays ? (userQty >= restTwoSpan)?(restTwoSpan):(userQty) : userQty;
                        twoSpanSummary += activity.Quantity;

                    activity.ToHHMM = moment.duration(this.activityForm.get('toTime').value).asMinutes();
                    activity.FromHHMM = moment.duration(this.activityForm.get('fromTime').value).asMinutes();

                    if(activity.Quantity != 0)
                    activities.push(activity);

                    //add second line if multiple days
                    if(this.timeSpanTwoDays && twoSpanSummary2 < time2 && (userQty-activity.Quantity) > 0)
                    {
                        let activity2:any = {};
                        activity2 = Object.assign({},activity)

                        let tomorrow = new Date(this.currentDate.getTime());

                        tomorrow.setDate(tomorrow.getDate() + 1);

                        activity2.DateStr = (Utils.padNumber(tomorrow.getMonth() + 1)) + '-'
                        + (Utils.padNumber(tomorrow.getDate())) + '-' + (Utils.padNumber(tomorrow.getFullYear()));
                        
                       
                    let restTwoSpan2 = time2 - twoSpanSummary2;
                        activity2.Quantity = ((userQty-activity.Quantity) >= restTwoSpan2)?(restTwoSpan2):((userQty-activity.Quantity));
                        twoSpanSummary2 += activity2.Quantity;
                    
                    activity2.ToHHMM = moment.duration(this.activityForm.get('toTime').value).asMinutes();
                    activity2.FromHHMM = moment.duration(this.activityForm.get('fromTime').value).asMinutes();
                    activities.push(activity2);


                    }
                }
            }

            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                this.submitting = true;
                loader.present().then(() => {
                    this.userService.saveActivities(activities).subscribe(data => {
                        this.submitting = false;
                        this.submitAttempt = false;
                        this.loadRegistrations();
                        this.activityForm.reset();
                        this.activityForm.get('orderNo').setValue('');
                        this.activityForm.get('project').setValue('');
                        this.activityForm.get('comment').setValue('');
                        this.activityForm.get('department').setValue('');
                        this.activityForm.get('activity').setValue('');
                        this.activityForm.get('endDate').setValue('');
                        this.activityForm.get('qty').setValue(0);
                        this.activityList = [];
                        this.activityForm.controls.wageTypes = this.wageTypesGroup = new FormGroup({});
                        this.identity = -1;
                        this.timeSpanTwoDays = false;
                        this.totalTime = '0';
                        this.showAddEndDate = false;
                        this.clockRequiered = true;
                        this.addPredefinedWagetypes();
                        loader.dismiss();
                    });
                });
            });
        }
    }

    setQty(qty: number, item, increment:boolean) {
        const controls = this.activityForm.controls.wageTypes.get('q' + item.lineNo);
        if (increment === false) {
            controls.setValue(qty);
        } else {
            if(controls.value+ qty >=0)
            {
                controls.setValue(controls.value + qty);
            }
            else //remove if qty is < 0
            {
                this.activityList = _.remove(this.activityList, (current) => {
                    return current.lineNo !== item.lineNo;
                });
            }
        }
        _.filter(this.activityList, (act) => {
            if (act.activity.ActivityNo === item.activity.ActivityNo) {
                act.qty = controls.value;
                return act;
            }
        });
    }

    showConfirm() {

        let modalTitle;
        let modalText;
        let cancel;
        let save;

        if (this.getTotalQuantity() + this.getTotalActivityListQuantity > 14) {
            this.translateService.get('ActivityRegistration.ManyHoursTitle').subscribe(value => { modalTitle = value; });
            this.translateService.get('ActivityRegistration.ManyHours').subscribe(value => { modalText = value; });
            this.translateService.get('ActivityRegistration.tilbake').subscribe(value => { cancel = value; });
            this.translateService.get('ActivityRegistration.lagre').subscribe(value => { save = value; });

            this.alertCtrl.create({
                header: modalTitle,
                message: modalText,
                buttons: [
                    {
                        text: cancel,
                        handler: () => {

                        }
                    },
                    {
                        text: save,
                        handler: () => {
                            this.save();
                        }
                    }
                ]
            }).then(confirm => { confirm.present(); });
            return;
        } else {
            this.save();
        }
    }

    switchView(viewType: ActivityView) {
        this.activities = null;
        this.view = viewType;
        this.loadRegistrations();
    }

    previousDay() {
        this.currentDate = Utils.addDays(this.currentDate, -1);
        this.loadRegistrations();
        this.checkHoliday();
    }

    nextDay() {
        this.currentDate = Utils.addDays(this.currentDate, 1);
        this.loadRegistrations();
        this.checkHoliday();
    }

    checkHoliday()
    {
        this.userService.getHoliday(this.currentDate).then(data => {
            this.holidayName = data;
        });
    }
    previousMonth() {
        this.currentDate = this.currentDate.addMonths(-1);
        this.loadRegistrations();
    }

    nextMonth() {
        this.currentDate = this.currentDate.addMonths(1);
        this.loadRegistrations();

    }

    truckTrailerChanged() {

    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    addToList() {
        const activityNo = this.activityForm.get('activity').value.ActivityNo;
        const data = _.filter(this.activityList, (act) => {
            if (act.activity.ActivityNo === activityNo) {
                act.qty = this.activityForm.get('qty').value;
                this.activityForm.controls.wageTypes.get('q' + act.lineNo).setValue(act.qty);
                return act;
            }
        });

        if (data.length === 0) {
            const formGroup: FormGroup = this.activityForm.controls.wageTypes as FormGroup;
            const quntity: FormControl = new FormControl(this.activityForm.get('qty').value, Validators.compose([]));
            formGroup.addControl('q' + this.identity, quntity);
            const activity = {
                activity: this.activityForm.get('activity').value, qty: this.activityForm.get('qty').value,
                lineNo: this.identity
            };
            this.activityList.push(activity);
            this.identity--;
        }
        this.checkTimeValidatiors(activityNo);
        this.activityForm.get('qty').setValue(0);
        this.activityForm.get('activity').setValue('');
        this.toggleShowAddExtra();
        this.sortActivitiesList();
        
    }

    checkTimeValidatiors(actNo:string)
    {
        if(this.activityTypes.find(e => e.ActivityNo==actNo).ClockNotRequired === false){
            this.activityForm.controls['toTime'].addValidators(Validators.required);               
            this.activityForm.controls['fromTime'].addValidators(Validators.required);  
            this.clockRequiered = true;
        } else {                
            this.activityForm.controls['toTime'].clearValidators();               
            this.activityForm.controls['fromTime'].clearValidators(); 
            this.clockRequiered = false;    
        }

        this.activityForm.controls['toTime'].updateValueAndValidity();               
        this.activityForm.controls['fromTime'].updateValueAndValidity(); 


    }

    addPredefinedWagetypes() {
        
        this.activityTypes.forEach(item => {
            
            if(item.Predefined)
            {
            const formGroup: FormGroup = this.activityForm.controls.wageTypes as FormGroup;
            const quntity: FormControl = new FormControl(item.PredefinedQty, Validators.compose([]));
            formGroup.addControl('q' + this.identity, quntity);
            const activity = {
                activity: item, qty: item.PredefinedQty,
                lineNo: this.identity
            };
            this.activityList.push(activity);
            this.identity--;
            
        }
        });
        this.sortActivitiesList();
    }


    getTotalActivityListQuantity() {
        return _.sumBy(this.activityList, activity => {
            if(activity.activity.ExcludeFromSummary === false)
                return activity.qty;
                else
                return 0;
        });
    }

    sortActivitiesList() {
      
        this.activityList = Utils.orderBy(this.activityList, "activity.Sorting", "ASC");
    }

    wagetypeChange(event: {
        component: IonicSelectableComponent,
        value: any
      }) {
        let item = event.value;
        if(item !== null)
        this.activityForm.get('qty').setValue(item.PredefinedQty);

      }
}
