import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of, from,forkJoin, Subject, BehaviorSubject, catchError} from 'rxjs';
import { BaseHttpClient } from './http-client';
import { Utils } from './utils';
import { ActivityView, RespUnit, InternalOrdertype } from './enums';
import { AppSettings } from './app-settings';
import _ from 'lodash';
import { Storage } from '@ionic/storage-angular';
import { Platform, isPlatform } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Console } from 'console';

//const { Network } = Plugins;


@Injectable({
    providedIn: 'root',
})
export class UserService {
    private currentTruckTrailer$: ReplaySubject<any>;
    private trucksTrailers: any;
    private products: any;
    private tanks: any;
    private basicData: any;
    private drivers: any;
    private activityTypes: any;
    private responsibleUnits: any;
    public localStorageData: any = [];
    public fontSettings: any;
    public events = new Subject();
    public startupViewSettings: any;
    public smUpLargeScreen: ReplaySubject<any>;
    public moduleAccess = new BehaviorSubject<any>(null);

    constructor(
       private http: BaseHttpClient, private storage: Storage, private platform: Platform, private httpClient: HttpClient,
        private screenOrientation: ScreenOrientation) {
        // load any local data present
        this.ngOnInit();

        this.storage.get(AppSettings.StorageKeys.LocalStorageData).then(data => {
            if (data) {
                this.localStorageData = data;
            }
        });

        this.storage.get(AppSettings.StorageKeys.FontSettings).then(data => {
            this.fontSettings = data;
        });

        this.storage.get(AppSettings.StorageKeys.StartupViewSettings).then(data => {
            this.startupViewSettings = data;
        });

        this.currentTruckTrailer$ = new ReplaySubject(1);
        this.smUpLargeScreen = new ReplaySubject(1);

        this.smUpLargeScreen.next(platform.width() >= 576);

          this.initializeOrientationChange();

    }

    initializeOrientationChange() {
    ScreenOrientation.addListener('screenOrientationChange', () => {
        this.smUpLargeScreen.next(this.platform.width() >= 576);
    });
}

    async ngOnInit() {

        await this.storage.create();
    }

    clearCache() {
        this.currentTruckTrailer$ = new ReplaySubject(1);
        this.trucksTrailers = null;
        this.basicData = null;
        this.tanks = null;
        this.drivers = null;
    }

    login(username: string, password: string, clientID: number): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/Login`,
            { username, password, clientID }).pipe(map((response: any) => {
                return response;
            }));
    }

    getTrailersAndTrucks(): Observable<any> {
        if (this.trucksTrailers) {
            return of(this.trucksTrailers);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetTrailersAndTrucks`).pipe(map((response: any) => {
                if (response) {
                    this.trucksTrailers = response;
                    return this.trucksTrailers;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

    getBasicData(): Observable<any> {
        if (this.basicData) {
            return of(this.basicData);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetBasicData`).pipe(map((response: any) => {
                if (response) {
                    this.basicData = response;
                    return this.basicData;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

    getMessages(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetMessages/`).pipe(map((response: any) => {
            if (response) {
                return response.Messages;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getDrivers(): Observable<any> {
        if (this.drivers) {
            return of(this.drivers);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDrivers`).pipe(map((response: any) => {
                if (response) {
                    this.drivers = response;
                    return this.drivers;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

    getActivityTypes(): Observable<any> {
        if (this.activityTypes) {
            return of(this.activityTypes);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetActivityTypes`).pipe(map((response: any) => {
                if (response) {
                    this.activityTypes = response;
                    return this.activityTypes;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

    getResponsibleUnits(respUnit: RespUnit): Observable<any> {

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetResponsibleUnits?respUnit=${respUnit}`).pipe(map((response: any) => {
            if (response) {
                this.responsibleUnits = response;
                return this.responsibleUnits;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getOrderCount(date: Date): Observable<any> {

        let dateStr = '';

        if (date != null) {
            dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' + (Utils.padNumber(date.getDate())) + '-' +
                (Utils.padNumber(date.getFullYear()));
        }

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetOrderCount/?date=${dateStr}`).pipe(map((response: any) => {
            if (response) {
                return response.Count;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getRegistrations(date: Date, activityView: ActivityView): Observable<any> {

        let dateStr = '';

        if (date != null) {
            dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' + (Utils.padNumber(date.getDate())) + '-' +
                (Utils.padNumber(date.getFullYear()));
        }

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetRegistrations/?dateStr=${dateStr}&activityView=${activityView}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    saveActivity(data: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveActivity`, data).pipe(map((response: any) => {
            return response;
        }));
    }

    saveActivities(data: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveActivities`, data).pipe(map((response: any) => {
            return response;
        }));
    }

    saveStockGoods(data: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveStockGoods`, data).pipe(map((response: any) => {
            return response;
        }));
    }

    deleteActivity(activityID: number): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/DeleteActivity/?activityID=${activityID}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }


    deleteOrderline(orderNo: number, lineNo: number): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/DeleteOrderline/?orderno=${orderNo}&lineno=${lineNo}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

getOrders(date: Date, userID: string, filterMineOnly: boolean = false,
    internalOrdertype: InternalOrdertype = InternalOrdertype.Bulk, companyNo: number = 0): Observable<any> {
    let dateStr = '';

    if (date != null) {
        dateStr = Utils.padNumber(date.getMonth() + 1) + '-' + Utils.padNumber(date.getDate()) + '-' +
            Utils.padNumber(date.getFullYear());
    }

    return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetOrders/?date=${dateStr}&userID=${userID}&filterMineOnly=${filterMineOnly}&requestedType=${internalOrdertype}&companyNo=${companyNo}`)
        .pipe(
            map((response: any) => response || 'FAILURE'), // If response is falsy, return 'FAILURE'
          //  retry(2), // Retry the request up to 2 times in case of an error
            catchError(error => {
                console.error('Error fetching orders:', error);
               // this.refreshApp(); // Custom method to refresh the app or part of it
                return of(() => new Error('Error fetching orders')); // Rethrow the error
            })
        );
}

refreshApp() {
    // Implement your refresh logic here
    // For a simple page refresh, you can use:
    window.location.reload();
    // Or for Ionic, you might want to navigate back to the root page and then forward again:
    // this.navCtrl.navigateRoot('/home').then(() => this.navCtrl.navigateForward('/orders'));
}

    getOrdersForApproval(internalOrdertype: InternalOrdertype = InternalOrdertype.Bulk): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetOrdersForApproval/?requestedType=${internalOrdertype}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

        getApprovedOrders(internalOrdertype: InternalOrdertype = InternalOrdertype.Bulk, search:string = ''): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetApprovedOrders/?requestedType=${internalOrdertype}&search=${search}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getPurchaseOrders(stockNo: string): Observable<any> {
        if (stockNo != null) {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetPurchaseOrders/?stockno=${stockNo}`).pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
        }
        else { return of(null); }


    }

    approveForInvoice(order: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/ApproveForInvoice`, order).pipe(map((response: any) => {
            return response;
        }));
    }

     sendOrderToApproval(order: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/OrderApproval`, order).pipe(map((response: any) => {
            return response;
        }));
    }

        getInvoicePDF(invoiceNo: string): Observable<any> {
        if (invoiceNo != null) {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/InvoicePDF/?InvoiceNo=${invoiceNo}`).pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
        }
        else { return of(null); }


    }

        getOrderDocumentPDF(path: string): Observable<any> {
        if (path != null) {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/OrderDocumentPDF/?path=${path}`).pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
        }
        else { return of(null); }


    }




    getCurrentInventory(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetCurrentInventory/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getCurrentInventoryCurrent(truckID: string, tankID: string): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetCurrentInventoryCurrent/?truckID=${truckID === null ? '' : truckID}
        &tankID=${tankID === null ? '' : tankID}`).pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    // getCurrentInventoryPlusDeviationLimit(transactionType : any): Observable<any> {

    //    return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetCurrentInventoryPlusDeviationLimit/?
    // transactionType=${transactionType}`).pipe(map((response: any) => {
    //        if (response) {
    //            return response;
    //        }
    //        else {
    //            return 'FAILURE';
    //        }
    //    });

    // }

    getDeviationLimit(transactionType: any): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDeviationLimit/?transactionType=${transactionType}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));

    }

    forgotPassword(forgotPasswordRequest: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/ForgotPassword/`, forgotPasswordRequest).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    setPassword(passwordChangeRequest: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SetPassword/`, passwordChangeRequest).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    moveOrderToNextDay(orderNo: number): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/MoveOrderToNextDay/`, { OrderNo: orderNo })
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    SaveOrderSorting(orders: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveOrderSorting/`, orders).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    weatherLookup(latitude: number, longitude: number): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/weatherLookup/?latitude=${latitude}&longitude=${longitude}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    getDeviationStatus(date: Date): Observable<any> {

        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' + (Utils.padNumber(date.getDate())) + '-'
            + (Utils.padNumber(date.getFullYear()));

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDeviationStatus/?date=${dateStr}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getDeliveryStats(date: Date): Observable<any> {
        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' + (Utils.padNumber(date.getDate())) + '-'
            + (Utils.padNumber(date.getFullYear()));

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDeliveryStats/?date=${dateStr}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    customerSearch(query: string): Observable<any> {

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=${query}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    customerByGroup(groupId: string): Observable<any> {

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/CustomerByGroup/?GroupID=${groupId}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    addressGPSSearch(erpIDParent: number, latitude: number, longitude: number): Observable<any> {
        return this.http.
            get(`${AppSettings.API_ENDPOINT}/User/AddressGPSSearch/?erpIDParent=${erpIDParent}&latitude=${latitude}&longitude=${longitude}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    assignOrderToDriver(orderNo: string, userID: string): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/AssignOrderToDriver`, { orderNo, userID })
            .pipe(map((response: any) => {
                return response;
            }));
    }

    assignOrderToTruck(orderNo: string, truckID: number, trailerID: number, routeNo: number, PlannedDate: Date): Observable<any> {
        let _trailerID = (trailerID > 0 ? trailerID : 0);

        return this.http.post(`${AppSettings.API_ENDPOINT}/User/AssignOrderToTruck`, { orderNo, truckID, trailerID, routeNo, PlannedDate })
            .pipe(map((response: any) => {
                return response;
            }));
    }
    getDeliveryZones(): Observable<any> {


        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDeliveryZones/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    UpdateOrderETA(orderNo: string, time: string): Observable<any> {


        return this.http.post(`${AppSettings.API_ENDPOINT}/User/UpdateOrderETA/?orderNo=${orderNo}&time=${time}`, {})
            .pipe(map((response: any) => {
                return response;
            }));
    }

    assignToRoom(assignment: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/AssignOrderRoom/`, assignment).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    assignRoute(route: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/AssignRoute/`, route).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getAssignedRoute(route: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/GetAssignedRoute/`, route).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }


    deleteAssignedToRoom(assignment: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/DeleteAssignedOrderRoom/`, assignment).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    deleteAssignedRoute(route: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/DeleteAssignedRoute/`, route).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }


    newMessage(internalMessage: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/NewMessage`,
            { UserID: internalMessage.UserID, Message: internalMessage.Message }).pipe(map((response: any) => {
                return response;
            }));
    }

    unassignOrder(orderNo: string): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/UnassignOrder`, { orderNo }).pipe(map((response: any) => {
            return response;
        }));
    }

    unassignOrderFromTruck(orderNo: string): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/UnassignOrderFromTruck`, { orderNo }).pipe(map((response: any) => {
            return response;
        }));
    }


    setOrderAsCompleted(order: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SetOrderAsCompleted`, order
            // {
            //     OrderNo: order.OrderNo, ConDeliveryDateStr: order.ConDeliveryDateStr, Latitude: order.Latitude,
            // Longitude: order.Longitude,
            //     AdditionalProductsDelivered: order.AdditionalProductsDelivered, LoadingTemp: order.LoadingTemp,
            // DoNotCalculateVolume: order.DoNotCalculateVolume, OrderComments: order.OrderComments, InternalInfo:
            // order.InternalInfo, OrderInternalComments:order.OrderInternalComments,
            //     TankInfo : order.TankInfo, DeliveryInfo: order.DeliveryInfo, DoNotConfirmOrder:
            //  order.DoNotConfirmOrder, PhoneOnSite: order.PhoneOnSite, CustomerReference: order.CustomerReference,
            //     PhoneManager: order.PhoneManager,
            //     Email: order.Email, Orderlines: order.Orderlines
            // }
        ).pipe(map((response: any) => {
            return response;
        }));
    }

    setWorkOrderAsCompleted(order: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SetWorkOrderAsCompleted`, order

        ).pipe(map((response: any) => {
            return response;
        }));
    }

    /**
     * Creates a new order.
     * @param order - The order object.
     * @param addIfOffline - Optional. Specifies whether to add the order to local storage if the device is offline. Default is true.
     * @returns A Promise that resolves to an Observable containing the response from the server, or null if the device is offline and addIfOffline is false.
     */
    async createNewOrder(order: any, addIfOffline: boolean = true): Promise<Observable<any>> {
    return await Network.getStatus().then((status: any) => {
        if (!status.connected) {
            // Handle offline scenario
            if (addIfOffline) {
                this.localStorageData.push({ name: 'new-order', value: order });
                this.storage.set(AppSettings.StorageKeys.LocalStorageData, this.localStorageData);
            }
            return of(null);
        } else {
            // Online scenario: Make HTTP request
            return this.http.post(`${AppSettings.API_ENDPOINT}/User/CreateNewOrder`, order).pipe(map((response: any) => {
                return response;
            }));
        }
    });
}







    createCustomer(data: any) {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/CreateCustomer`, data).pipe(map((response: any) => {
            return response;
        }));
    }

processQueue(): Observable<any> {
    return new Observable(observer => {
        Network.getStatus().then(status => {
            if (
                (isPlatform('hybrid') && (status.connected === false || status.connectionType === 'none')) ||
                (!isPlatform('hybrid') && !navigator.onLine)
            ) {
                observer.next(null); // No network, emit null
                observer.complete(); // Complete the observable
            } else {
                const arr: any[] = [];
                for (let index = 0; index < this.localStorageData.length; index++) {
                    switch (this.localStorageData[index].name) {
                        case 'new-order':
                            arr.push(this.createNewOrder(this.localStorageData[index].value, false));
                            break;

                        case 'egne-add-transaction':
                            arr.push(this.egneAddTransaction(this.localStorageData[index].value, false));
                            break;
                    }
                }
                if (arr.length > 0) {
                    // Use forkJoin to handle multiple observables
                    forkJoin(arr).subscribe({
                        next: (results) => observer.next(results),
                        error: (err) => observer.error(err),
                        complete: () => observer.complete()
                    });
                } else {
                    observer.next([]); // No items to process, emit an empty array
                    observer.complete();
                }
            }
        }).catch(error => {
            observer.error(error); // Error in getting network status
        });
    });
}

    saveDeviations(data: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveDeviations`, data).pipe(map((response: any) => {
            return response;
        }));
    }

    getDepos(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDepos`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveTransactions(data: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveTransactions`, data).pipe(map((response: any) => {
            return response;
        }));
    }


egneAddTransaction(data: any, addIfOffline: boolean = true): Observable<any> {
    return new Observable(observer => {
        Network.getStatus().then(status => {
            if (
                (isPlatform('hybrid') && (status.connected === false || status.connectionType === 'none')) ||
                (!isPlatform('hybrid') && !navigator.onLine)
            ) {
                if (addIfOffline) {
                    this.localStorageData.push({ name: 'egne-add-transaction', value: data });
                    this.storage.set(AppSettings.StorageKeys.LocalStorageData, this.localStorageData);
                }
                observer.next(null); // Emit a value for the subscriber
                observer.complete(); // Complete the observable stream
            } else {
                this.http.post(`${AppSettings.API_ENDPOINT}/User/EgneAddTransaction`, data).pipe(
                    map((response: any) => {
                        return response;
                    })
                ).subscribe({
                    next: (response) => observer.next(response),
                    error: (err) => observer.error(err),
                    complete: () => observer.complete()
                });
            }
        }).catch(error => {
            observer.error(error); // Emit an error if there's an issue with getting the network status
        });
    });
}

    getOrder(orderID: number): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetOrder/${orderID}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveTrailerAndTruck(truckID: string, trailerID: string): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveTrailerAndTruck`, { truckID, trailerID })
            .pipe(map((response: any) => {

                const truck = _.find(this.trucksTrailers.Trucks, function trucks(item: any) {
                    return item.ID === truckID;
                });

                const trailer = _.find(this.trucksTrailers.Trailers, function trailers(item: any) {
                    return item.ID === trailerID;
                });

                this.currentTruckTrailer$.next({ Truck: truck, Trailer: trailer });

                return response;
            }));
    }

    getCurrentTruckAndTrailer() {
        // If the Subject was NOT subscribed before
        if (!this ?.currentTruckTrailer$ ?.observers.length || this.currentTruckTrailer$ === undefined) {
            this.http.get(`${AppSettings.API_ENDPOINT}/User/GetCurrentTruckAndTrailer`)
                .pipe(map((response: any) => {
                    return response;
                })).subscribe(
                    data => this.currentTruckTrailer$.next(data),
                    error => {
                        this.currentTruckTrailer$.error(error);
                        // Recreate the Observable as after Error we cannot emit data anymore
                        this.currentTruckTrailer$ = new ReplaySubject(1);
                    }
                );
        }
        return this.currentTruckTrailer$;
    }

    getProducts() {
        if (this.products) {
            return of(this.products);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetProducts`).pipe(map((response: any) => {
                if (response) {
                    this.products = response;
                    return this.products;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

      getFeaturedProducts() {
        if (this.products) {
            return of(this.products);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetFeaturedProducts`).pipe(map((response: any) => {
                if (response) {
                    this.products = response;
                    return this.products;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

          getAppShortcuts() {
       try{
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetAppShortcuts`).pipe(map((response: any) => {
                if (response) {
                    this.products = response;
                    return this.products;
                } else {
                    return 'FAILURE';
                }
            }));
        }
        catch
{}    }



    searchProducts(query: string, stockno: string = ""): Observable<any> {

        return this.http.get(`${AppSettings.API_ENDPOINT}/User/SearchProducts?query=${query}&stockno=${stockno}`).pipe(map((response: any) => {
            if (response) {
                this.products = response;
                return this.products;
            } else {
                return 'FAILURE';
            }
        }));

    }

    getTankProducts(tankID: string): Observable<any> {
        const that = this;

        const fetch = function getTankProducts() {
            return that.http.get(`${AppSettings.API_ENDPOINT}/User/GetTankProducts?tankID=${tankID}`).pipe(map((response: any) => {
                if (response) {
                    const tankProducts = response;
                    return _.filter(that.products, product => {
                        const productLocal = product as any;
                        return _.includes(tankProducts, productLocal.ProductNo);
                    });
                } else {
                    return 'FAILURE';
                }
            }));
        };

        if (this.products) {
            return fetch();
        } else {
            return this.getProducts().pipe(switchMap(data => fetch()));
        }
    }

    getCurrentTruckProducts(): Observable<any> {
        const that = this;

        const fetch = function getCurrentTruckProducts() {
            return that.http.get(`${AppSettings.API_ENDPOINT}/User/GetCurrentTruckProducts`).pipe(map((response: any) => {
                if (response) {
                    const tankProducts = response;
                    return _.filter(that.products, product => {
                        const productLocal = product as any;
                        return _.includes(tankProducts, productLocal.ProductNo);
                    });
                } else {
                    return 'FAILURE';
                }
            }));
        };

        if (this.products) {
            return fetch();
        } else {
            return this.getProducts().pipe(switchMap(data => fetch()));
        }
    }

    getInventoryProducts(): Observable<any> {
        const that = this;

        const fetch = function getInventoryProducts() {
            return that.http.get(`${AppSettings.API_ENDPOINT}/User/GetInventoryProducts`).pipe(map((response: any) => {
                if (response) {
                    const inventoryProducts = response;
                    return _.filter(that.products, product => {
                        const productLocal = product as any;
                        return _.includes(inventoryProducts, productLocal.ProductNo);
                    });
                } else {
                    return 'FAILURE';
                }
            }));
        };

        if (this.products) {
            return fetch();
        } else {
            return this.getProducts().pipe(switchMap(data => fetch()));
        }
    }

    getTanks() {
        if (this.tanks) {
            return of(this.tanks);
        } else {
            return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetTanks`).pipe(map((response: any) => {
                if (response) {
                    this.tanks = response;
                    return this.tanks;
                } else {
                    return 'FAILURE';
                }
            }));
        }
    }

    transactionLog(date: Date, emplNo: string, searchText: string): Observable<any> {
        let dateStr = '';
        if (date != null) {
            dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' + (Utils.padNumber(date.getDate())) + '-' +
                (Utils.padNumber(date.getFullYear()));
        }
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/TransactionLog/?date=${dateStr}&emplNo=${emplNo}&searchText=${searchText}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    convertProduct(fromProductNo: string, toProductNo: string, quntity: number, transDate: Date): Observable<any> {
        let transDateStr = '';
        if (transDate != null) {
            transDateStr = (Utils.padNumber(transDate.getMonth() + 1)) + '-' + (Utils.padNumber(transDate.getDate())) + '-' +
                (Utils.padNumber(transDate.getFullYear()));
        }

        return this.http.post(`${AppSettings.API_ENDPOINT}/User/ConvertProduct/`, {
            FromProductNo: fromProductNo, ToProductNo: toProductNo,
            Quantity: quntity, TransDateStr: transDateStr
        }).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getReleaseNotes(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetReleaseNotes/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveFontSettings(value: any) {
        this.storage.set(AppSettings.StorageKeys.FontSettings, value);
        this.fontSettings = value;
    }

    saveStartupViewSettings(value: any) {
        this.storage.set(AppSettings.StorageKeys.StartupViewSettings, value);
        this.startupViewSettings = value;
    }




getNorTraceItems(): Observable<any> {
    return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetNorTraceItems/`).pipe(
        map((response: any) => {
            // Assuming 'response' would be the object you expect
            // You can also add additional conditions to check if the response is as expected
            if (response) {
                return response;
            } else {
                // This could be a case where your API returns a 200 OK but with an unexpected body
                return 'FAILURE'; // Or throw new Error('Unexpected response body');
            }
        }),
        catchError((error) => {
            // Handle different types of errors accordingly
            console.error('Error occurred:', error);

            // Here, you could return an Observable with a custom error message
            // Or you could return 'of' a default value to ensure the Observable stream continues
            return of('API CALL FAILED'); // Or throw error; to rethrow the original error
        })
    );
}


    GetPriceAgreements(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetPriceAgreements/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

        GetTransporters(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetTransporters/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }
 
    
    getCompanies(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetCompanies/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getStocks(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/user/GetStocks/`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveWorkOrdersDocuments(files: any, orderNo: number, orderDocuments: any): Observable<any> {
        const headers: any = { Accept: 'application/json' };
        const options: any = {
            headers: new HttpHeaders(headers)
        };
        const formData: FormData = new FormData();
        if (files) {
            for (const file of files) {
                formData.append('Files[]', file);
            }
        }
        formData.append('orderDocuments', JSON.stringify(orderDocuments));
        return this.httpClient.post(`${AppSettings.API_ENDPOINT}/User/SaveWorkOrdersDocuments/?orderNo=${orderNo}`, formData, options)
            .pipe(map((response: any) => {
                return response;
            }));
    }

    getOrdersDocuments(orderNo): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/user/GetOrdersDocuments/?orderNo=${orderNo}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getCustomerHistory(deliveryActorNo): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/user/GetCustomerHistory/?deliveryActorNo=${deliveryActorNo}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    saveDeviation(files: any, deviation: any): Observable<any> {
        const headers: any = { Accept: 'application/json' };
        const options: any = {
            headers: new HttpHeaders(headers)
        };
        const formData: FormData = new FormData();
        if (files) {
            for (const file of files) {
                formData.append('Files[]', file);
            }
        }
        formData.append('deviation', JSON.stringify(deviation));
        return this.httpClient.post(`${AppSettings.API_ENDPOINT}/User/SaveDeviation/`, formData, options)
            .pipe(map((response: any) => {
                return response;
            }));
    }




    saveCustomerLead(data: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveCustomerLead`, data).pipe(map((response: any) => {
            return response;
        }));
    }

    deleteOrder(orderNo: number): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/DeleteOrder/?orderNo=${orderNo}`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    updateOrder(order: any): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/UpdateOrder`, order).pipe(map((response: any) => {
            return response;
        }));
    }

    getTankInventory(): Observable<any> {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetTankInventory`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getLocation(from, to) {
        return this.httpClient.
            get(`https://mqtt.soolo.no/api/v1/history/locations?fromiso=${from}&toiso=${to}`)
            .pipe(map((response: any) => {
                if (response) {
                    return response;
                } else {
                    return 'FAILURE';
                }
            }));
    }

    getTruckInventory(truckIDs) {
        let params = new HttpParams();
        for (const id of truckIDs) {
            params = params.append('truckIDs', id);
        }
        return this.httpClient.get(`${AppSettings.API_ENDPOINT}/User/getTruckInventory/`,{ params: params }).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getVehicles() {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetVehicles`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getForms(pageNo, pageSize, search, leads, checklists, statusID) {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetForms/?pageNo=${pageNo}
        &pageSize=${pageSize}&search=${search}&leads=${leads}&checklists=${checklists}&statusID=${statusID}`)
        .pipe(map((response: any) => {
            if (response) {
                return response;    
            } else {
                return 'FAILURE';
            }
        }));
    }

        getDeviationForms(pageNo, pageSize, search,statusID) {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetDeviationForms/?pageNo=${pageNo}
        &pageSize=${pageSize}&search=${search}&statusID=${statusID}`)
        .pipe(map((response: any) => {
            if (response) {
                return response;    
            } else {
                return 'FAILURE';
            }
        }));
    }


    getLeadsProducts() {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetLeadsProducts`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveMessage(data): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveMessage/`, data).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveLead(data): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveLead/`, data).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    saveChecklist(data): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/SaveChecklist/`, data).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getCheckPoints() {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetCheckPoints`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    updateCheckList(data): Observable<any> {
        return this.http.post(`${AppSettings.API_ENDPOINT}/User/UpdateCheckList/`, data).pipe(map((response: any) => {
            if (response) {
                return response.OK;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getTopLeadScore() {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetTopLeadScore`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getUserLeadScore() {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetUserLeadScore`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    getStatuses() {
        return this.http.get(`${AppSettings.API_ENDPOINT}/User/GetStatuses`).pipe(map((response: any) => {
            if (response) {
                return response;
            } else {
                return 'FAILURE';
            }
        }));
    }

    async getHoliday(date: Date, forceapi = false) {



        //check local storage
        let holidays = await this.storage.get(AppSettings.StorageKeys.Holidays);

        if (holidays == undefined || holidays == "reload" || holidays == null || forceapi == true) {
            (await this.getHolidaysFromAPI(date)).subscribe(res => {
                setTimeout(() => {
                    this.getHoliday(date);
                }, 1000);
            });

        }
        else {
            try {
                holidays = JSON.parse(holidays);

                //check if correct year is loaded
                if (holidays.find(y => y.date.substring(0, 4) == date.getFullYear().toString()) == undefined) {
                    await this.storage.set(AppSettings.StorageKeys.Holidays, "reload").then(res => {
                        this.getHoliday(date);
                    });

                }
                else {

                    return holidays.find(x => x.date == date.toISOString().substring(0, 10) + "T00:00:00").description;
                }
            }
            catch (error) {
                return "";
            }
        }


    }

    async getHolidaysFromAPI(date: Date) {
        let url = "https://webapi.no/api/v1/holidays/" + date.getFullYear() + '/';
        return this.http.get(`${url}`).pipe(map((response: any) => {
            if (response) {

                try {
                    this.storage.set(AppSettings.StorageKeys.Holidays, JSON.stringify(response.data));
                    //  return response.data.find(x=>x.date == date.toISOString().substring(0,10)+"T00:00:00").description;
                    return "ok"
                } catch (error) {
                    return "";
                }

            } else {
                //  return 'FAILURE';
            }
        }));

    }

}
