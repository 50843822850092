import { Component, OnInit } from '@angular/core';
import { NavController, LoadingController, ToastController, ModalController } from '@ionic/angular';
import { CompanyService } from '../../shared/company-service';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { forkJoin } from 'rxjs';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password';
import { Router } from '@angular/router';

@Component({
    selector: 'page-login',
    templateUrl: 'login.html',
    styleUrls: ['login.scss']
})
export class LoginPage implements OnInit {
    submitAttempt = false;
    submitting = false;
    companies: any;
    loginForm: FormGroup;
    private SelectedClient = 0;
    private Username = '';
    version = AppSettings.appVersion;

    constructor(
        public navCtrl: NavController, private companyService: CompanyService,
        private loadingController: LoadingController, private router: Router,
        private toastController: ToastController, public translateService: TranslateService,
        private formBuilder: FormBuilder, private userService: UserService, private storage: Storage, private modalCtrl: ModalController) {

        this.loginForm = formBuilder.group({
            username: ['', Validators.compose([Validators.required])],
            password: ['', Validators.compose([Validators.required])],
            clientID: ['', Validators.compose([Validators.required])]
        });
    }

    ngOnInit() {
        this.loadData();
    }

    getFormControl(field) {
        return this.loginForm.get(field);
    }

    loadData() {

        forkJoin([this.storage.get(AppSettings.StorageKeys.ClientID),
            this.storage.get(AppSettings.StorageKeys.Username)]).subscribe(data => {
                this.SelectedClient = data[0];
                this.Username = data[1];
            });

        this.translateService.get('Loading.loadingCompanies').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {
                this.companyService.getCompanies().subscribe(data => {
                    this.companies = data;

                    if (this.Username !== '') {
                        this.getFormControl('username').setValue(this.Username);
                    }
                    if (this.SelectedClient != null) {
                        this.getFormControl('clientID').setValue(this.SelectedClient);
                    }
                    loader.dismiss();
                });
            });
        });
    }

    async showForgotPasswordDialog() {
        const modal = await this.modalCtrl.create({
            component: ForgotPasswordComponent,
            cssClass: 'forgot-password',
            componentProps: { ClientID: this.getFormControl('clientID').value, cssClass: 'forgot-password' }
        });
        modal.present();
    }

    login() {
        this.submitAttempt = true;
        if (this.loginForm.valid) {
            const controls = this.loginForm.controls;
            // call api to login
            this.submitting = true;
            this.userService.login(this.getFormControl('username').value,
                this.getFormControl('password').value, this.getFormControl('clientID').value).subscribe(data => {
                    this.submitting = false;
                    localStorage.setItem('token', data.Token);
                    forkJoin([this.storage.set(AppSettings.StorageKeys.Token, data.Token),
                        this.storage.set(AppSettings.StorageKeys.Username, this.getFormControl('username').value),
                        this.storage.set(AppSettings.StorageKeys.Userlevel, data.UserLevel),
                        this.storage.set(AppSettings.StorageKeys.UserType, data.UserType),
                        this.storage.set(AppSettings.StorageKeys.ClientID, this.getFormControl('clientID').value),
                        this.storage.set(AppSettings.StorageKeys.ShowAllProducts, data.ShowAllProducts),
                        this.storage.set(AppSettings.StorageKeys.ValidateTruckStock, data.ValidateTruckStock),
                        this.storage.set(AppSettings.StorageKeys.CompanyNo, data.CompanyNo),
                        this.storage.set(AppSettings.StorageKeys.UserFullName, data.Name),
                        this.storage.set(AppSettings.StorageKeys.UserSettings, data.UserSettings),

                        this.storage.set(AppSettings.StorageKeys.UsePlanner, data.UsePlanner == '1'?'1':'0'),
                        this.storage.set(AppSettings.StorageKeys.DisableUserFilter, data.DisableUserFilter == '1'?'1':'0'),
                        this.storage.set(AppSettings.StorageKeys.DisableGPSSend, data.DisableGPSSend == '1'?'1':'0'),

                        this.storage.set(AppSettings.StorageKeys.AllowDescriptionChange, data.AllowDescriptionChange)]).subscribe(val => {
                            AppSettings.UnauthenticatedRequest = false;
                            AppSettings.Username = this.getFormControl('username').value;
                            AppSettings.UserLevel = data.UserLevel;
                            AppSettings.UserType = data.UserType;
                            AppSettings.Token = data.Token;
                            
                            this.userService.moduleAccess.next(data.ModuleAccess);
                            localStorage.setItem('moduleAccess', JSON.stringify(data.ModuleAccess));

                             this.userService.getFeaturedProducts().subscribe(data =>{
                    localStorage.setItem(AppSettings.StorageKeys.FeaturedProducts, JSON.stringify(data));
                });

                            AppSettings.Vol2StdOff = data.Volume2Standard;
                            AppSettings.ValidateTruckStock = data.ValidateTruckStock;


                            if (this.userService.startupViewSettings === null) {
                                this.router.navigate(['/home']);
                                } else {
                                  this.router.navigate(['/' + this.userService.startupViewSettings]);
                                }
                        });

                }, data => {
                    this.translateService.get('Login.invalid').subscribe(value => {
                        this.toastController.create({
                            message: data.ErrorMessage == null ? value : data.ErrorMessage,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
                    this.submitting = false;
                });

        }
    }
}
