<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Menu.ordersProduct' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px;">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <div class="max-100 overflow-x">
            <div class="grid-width">
              <ion-row class="grid-header">
                <ion-col size="1" class="ion-text-center">
                  <strong>{{ 'Ordrer.ordre' | translate }}</strong>
                  <ion-button (click)="sort('OrderNo')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">
                    <ion-icon ion-text color="lightFour"
                      [name]="(sortProperty == 'OrderNo' && sortDirection == 'asc') || sortProperty != 'OrderNo' ? 'caret-up' : 'caret-down'">
                    </ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col size="4">
                  <strong>{{ 'Ordrer.kunde' | translate }}</strong>
                  <ion-button (click)="sort('DeliveryName')"
                    class="no-padding button-small-no-text vertical-top ion-no-margin" fill="clear">
                    <ion-icon ion-text color="lightFour"
                      [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'">
                    </ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col size="3">
                  &nbsp;
                </ion-col>
                <ion-col size="4">
                  <strong></strong>
                </ion-col>
              </ion-row>
              <ion-list>
                <ion-row *ngFor="let order of orders; let isOdd = odd; let i = index" [class.odd]="isOdd"
                  [attr.itemid]="order.OrderNo" id="{{order.OrderNo}}">
                  <ion-col>
                    <ion-row>
                      <ion-col size="1" class="handle ion-text-center">
                        <div (click)="expandOrder(order)" class="cursor-pointer">
                          <ion-text [color]="isOrderMine(order) == true ? 'success' : ''">
                            <strong>{{order.OrderNo}}</strong></ion-text>
                          <div *ngIf="isOrderMine(order) == true" class="handle">
                            <ion-icon name="person-outline" color="success" class="litte-big-font handle"></ion-icon>
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size="4">
                        <div class="cursor-pointer orderlist" (click)="expandOrder(order)">
                          <ion-icon color="primary" 
                          [name]="!order.expandedView ? 'add-circle-outline' : 'remove-circle-outline'"
                          class="litte-big-font vertical-text-bottom orderlistIcon">
                          </ion-icon>
                          <div class="orderlistHeading">
                            <strong>{{order.DeliveryName}}</strong>&nbsp; ({{order.CustomerID}})
                            <ng-container *ngIf="order.CustomerName != order.DeliveryName">
                              <br>
                              {{order.CustomerName}} 
                            </ng-container>
                            <br /> {{order.DeliveryAddress1}} <br>{{order.DeliveryPostalCode}} {{order.DeliveryCity}}
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size="3">
                        {{order.DeliveryInfo}}<br>
                        <div class="internal" [class.bigger-font]='userService.fontSettings==2'
                          [class.biggest-font]='userService.fontSettings==4'
                          *ngIf="order.CustomerPurchaseOrderNo && order.Orderlines.length > 0">
                          <ion-text><strong>{{ 'NewOrder.customerReference' | translate }}</strong>&nbsp;
                            {{order.CustomerPurchaseOrderNo}}</ion-text>
                        </div>
                      </ion-col>
                      <ion-col size="4" class="ion-text-right">
                        <ion-button (click)="navigteToOrderDetilsSales(order)" fill="solid">
                          <ion-icon name="create-outline"></ion-icon> &nbsp;
                          {{ 'Ordrer.edit' | translate }}
                        </ion-button>
                        <ion-button (click)="approveForInvoice(order)" [hidden]="order.Approve" fill="solid"
                          color="success">
                          <ion-icon name="checkbox-outline"></ion-icon> &nbsp;
                          {{ 'Ordrer.approve' | translate }}
                        </ion-button>
                      </ion-col>


                      <!-- EXPANDED VIEW -->
                      <ion-col size-sm="12" *ngIf="order.expandedView" [class.expandedOrder]="!isOdd" class="orderlistExpanded">
                        <ion-list lines="none">
                          <ion-item>
                            <ion-row class="w-100">

                              <!-- EXPANDED VIEW - DATES -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon name="calendar-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                  <ion-text class="upper bold"> <span>{{ 'OrdreDetails.leveringsdato' | translate }}</span></ion-text>
                                </p>
                                <span class="detailsIconText">{{order.DeliveryDate | date:dateFormat}}

                                <span *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"> /
                                  <ion-icon name="alert-circle-outline" class="circle vertical-text-bottom" color="danger"></ion-icon>
                                  {{order.ConfirmedDeliveryDate | date:dateFormat}}</span>
                                </span>
                              </ion-col>
                            
                              <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon name="people-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                  <ion-text class="upper bold"><span>{{ 'OrdreDetails.CustomerBuyer' | translate }}</span></ion-text>
                                </p> 
                                <span class="detailsIconText">{{order.CustomerBuyer}}</span>

                              </ion-col>

                              <!-- EXPANDED VIEW - OUR SELLER -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon name="person-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon> 
                                  <ion-text class="upper bold"><span>{{ 'OrdreDetails.OurSeller' | translate }}</span></ion-text>
                                </p>
                                <span class="detailsIconText">{{order.Seller}}</span>
                              </ion-col>
                            </ion-row>
                          </ion-item>

                          <ion-item>
                            <ion-row class="w-100 margin-top-10">
                               <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                               <ion-col size-xs="12" size-sm="4">
                                
                                  <p>
                                    <ion-icon name="cube-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                    <ion-text class="upper bold"><span>{{ 'OrdreDetails.leveringsinfo' | translate }}</span></ion-text>
                                  </p>
                                  <ng-container *ngIf="order.TankInfo"><span class="detailsIconText">{{order.TankInfo}}</span>
                                    <br />
                                  </ng-container>
                                  <ng-container *ngIf="order.DeliveryInfo"><span class="detailsIconText">{{order.DeliveryInfo}}</span>
                                    <br />
                                  </ng-container>
                                
                                
                              </ion-col>
                                <!--Phone onsite-->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon name="call-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                    <ion-text class="upper bold"><span>{{ 'OrdreDetails.onSite' | translate }}</span></ion-text>
                                  </p>
                                  <span class="detailsIconText" appPhoneRegex [inputText]="order.PhoneOnSite"></span>

                                </ion-col>
                                <!--Phone manager-->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon name="call-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                    <ion-text class="upper bold"><span>{{ 'OrdreDetails.manager' | translate }}</span></ion-text>
                                  </p>
                                  <span class="detailsIconText" appPhoneRegex [inputText]="order.PhoneManager"></span>

                                </ion-col>
                                
                            </ion-row>
                       
                          </ion-item>

                          
                          <ion-item *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite">
                            <ion-row class="w-100 margin-top-10">
                            
                                <!-- INTERNAL INFO-->
                                <ion-col size-xs="12" size-sm="4">
                                  <ion-row *ngIf="order.InternalInfo != ''">
                                    <ion-col size-xs="12">
                                      <p>
                                        <ion-icon name="chatbox-ellipses-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                        <ion-text class="upper bold"><span>{{ 'OrdreDetails.internInfo' | translate }}</span></ion-text>
                                      </p>
                                      <span class="detailsIconText" appPhoneRegex [inputText]="order.InternalInfo"></span>
                                    </ion-col>
                                </ion-row>
                                </ion-col>

                                <!-- EXTERNAL INFO -->
                                <ion-col size-xs="12" size-sm="4">
                                  <ion-row *ngIf="order.ExternalInfo != ''">
                                    <ion-col size-xs="12">
                                      <p>
                                        <ion-icon name="chatbox-ellipses-outline" class="vertical-text-bottom mr-10" color="primary"></ion-icon>
                                        <ion-text class="upper bold"><span>{{ 'OrdreDetails.externInfo' | translate }}</span></ion-text>
                                      </p>
                                      <span class="detailsIconText">{{order.ExternalInfo}}</span>
                                    </ion-col>
                                  </ion-row>
                                </ion-col>
            
                                    <ion-col size-xs="12" size-sm="4">

                                    </ion-col>
                            </ion-row>
                          </ion-item>

                          <!-- PRODUCT LIST -->
                          <div class="text-small ion-margin-bottom ion-margin-start margin-top-10 overflow-x" *ngIf="order.Orderlines.length > 0">
                            <div class="product-grid">
                                      <ion-row class="orderlistExpandProductHeading">
                                        <ion-col size-xs="12" size-sm="5"><b>{{ 'OrdreDetails.produkt' | translate | uppercase }}</b></ion-col>
                                        <ion-col size-xs="1"><b>{{ 'OrdreDetails.levert' | translate | uppercase }}</b></ion-col>
                                        <ion-col size-xs="1"><b>{{ 'OrdreDetails.cost' | translate | uppercase }}</b></ion-col>
                                        <ion-col size-xs="1"><b>{{ 'OrdreDetails.pris' | translate | uppercase }}</b></ion-col>
                                        <ion-col size-xs="1"><b>{{ 'OrdreDetails.rab' | translate | uppercase }}</b></ion-col>
                                        <ion-col size-xs="2"><b>{{ 'OrdreDetails.total' | translate | uppercase }}</b></ion-col>
                                        <ion-col size-xs="1"><b>{{ 'OrdreDetails.DB' | translate | uppercase }}</b></ion-col>
                                      </ion-row>
                                      <ion-row *ngFor="let orderLine of order.Orderlines">
                                        <ion-col size-xs="12" size-sm="5">
                                          <div class="flex-parent">
                                            <ion-badge [style.background]="orderLine.ProductColorCode">{{orderLine.ProductShortCode}}</ion-badge>&nbsp;
                                                <span class="truncate-text">{{orderLine.Description}}</span> 
                                          </div>
                                        </ion-col>
                                        <ion-col size-xs="1">
                                          <span class="slight-big-font"><strong>{{orderLine.QuantityDelivered | number:'1.0-1'}}</strong></span>
                                        </ion-col>
                                        <ion-col size-xs="1">{{ orderLine.UnitCost | number:'1.1-3'}}</ion-col>
                                        <ion-col size-xs="1">{{ orderLine.UnitPrice | number:'1.1-3'}}</ion-col>
                                        <ion-col size-xs="1">{{ orderLine.UnitDiscount | number:'1.0-1'}}</ion-col>
                                        <ion-col size-xs="2">{{ orderLine.OrderLineTotal | number:'1.0-0'}}</ion-col>
                                        <ion-col size-xs="1">{{ orderLine.OrderLineTotal-(orderLine.UnitCost * orderLine.QuantityDelivered)| number:'1.0-0'}}</ion-col>
                                      </ion-row>
                            </div>          
                          </div>
                        </ion-list>
                        
                      </ion-col>
                      <!-- END -->

                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-list>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button fill="outline" class="no-padding" (click)="loadData()">
    <ion-icon name="refresh" color="primary"></ion-icon>
  </ion-button>
  <ion-button fill="outline" class="no-padding" (click)="navigateToCreateOrder()">
    <ion-icon name="add-circle-outline" color="primary"></ion-icon>&nbsp;
    <span class="black">{{ 'Ordrer.nyOrdre' | translate }}</span>
  </ion-button>
</ion-footer>