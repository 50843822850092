import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';

@Component({
    selector: 'convert-product',
    templateUrl: 'convert-product.html',
    styleUrls: ['convert-product.scss']
})
export class ConvertProductComponent {
     convertProductForm: FormGroup;
     inventoryList: any[];
     description: string;
     description2: string;
     descriptionProd: string;
     min = 0;
     max = 0;

    constructor(
        public modalCtrl: ModalController, private userService: UserService, private translateService: TranslateService,
        public navParams: NavParams, private loadingController: LoadingController, private formBuilder: FormBuilder,
        private toastController: ToastController) {
        this.inventoryList = this.navParams.data.inventoryList;
        const product = this.navParams.data.item;

        if (product.Quantity < 0) {
            this.max = 0;
            this.min = product.Quantity;
        } else {
            this.min = 0;
            this.max = product.Quantity;
        }

        this.convertProductForm = formBuilder.group({
            productNo: ['', Validators.compose([Validators.required])],
            quantity: ['', Validators.compose([Validators.required, Validators.min(this.min), Validators.max(this.max)])]
        });

        this.translateService.get('ConvertProduct.description').subscribe(value => {
            this.description = value;
        });

        this.translateService.get('ConvertProduct.description2').subscribe(value => {
            this.description2 = value;
        });

        this.translateService.get('ConvertProduct.descriptionProd').subscribe(value => {
            this.descriptionProd = value.format(product.ProductDescription);
        });
    }

    addProduct() {
        if (this.convertProductForm.valid) {
            const product = this.convertProductForm.get('productNo').value;
            const quantity = this.convertProductForm.get('quantity').value;
            this.modalCtrl.dismiss({ Product: product, Quantity: quantity });
        }
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

}
