import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { AppSettings } from './shared/app-settings';
import { Modules } from './shared/enums';
//import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { forkJoin } from 'rxjs';
import { MenuController } from '@ionic/angular';
import { UserService } from './shared/user-service';
import _ from 'lodash';
import { environment } from '../environments/environment';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  // @ViewChild(Router) router: Router;
  rootPage: any;
  currentPage: any;
  menuItems: any;
  pages: Array<{
    title: string, whiteIconName: string, blackIconName: string,
    ionIconName: string, moduleID: Modules, hide: boolean, url: string
  }>;
  constructor(
    public platform: Platform, 
    private translateService: TranslateService, private storage: Storage, private router: Router,
    private menu: MenuController, private userService: UserService
  ) {
   // this.ngOnInit();
  
  try {
    SplashScreen.show();
  } catch (error) {
    
  } 

    const modules = localStorage.getItem('moduleAccess');
    this.userService.moduleAccess.next(modules ? JSON.parse(modules) : null);
 
    this.languageNavigator();
    this.loadGoogleMaps();

    this.pages = [
      {//0
        title: '', whiteIconName: 'Icon_Info_W.svg', blackIconName: 'Icon_Info.svg',
        ionIconName: null, moduleID: Modules.Home, hide: false, url: 'home'
      },
      {//1
        title: '', whiteIconName: 'Icon_Truck_W.svg', blackIconName: 'Icon_Truck.svg',
        ionIconName: null, moduleID: Modules.Inventory, hide: false, url: 'beholdning'
      },
      {//2
        title: '', whiteIconName: 'Icon_Orders_W.svg', blackIconName: 'Icon_Orders.svg',
        ionIconName: null, moduleID: Modules.Orders, hide: false, url: 'orders'
      },
      {//3
        title: '', whiteIconName: 'Icon_Orders_W.svg', blackIconName: 'Icon_Orders.svg',
        ionIconName: null, moduleID: Modules.WorkOrder, hide: false, url: 'work-orders'
      },
      {//4
        title: '', whiteIconName: 'Icon_Orders_W.svg', blackIconName: 'Icon_Orders.svg',
        ionIconName: null, moduleID: Modules.OrdersProduct, hide: false, url: 'orders-product'
      },
      {//5
        title: '', whiteIconName: 'Icon_Station_W.svg', blackIconName: 'Icon_Station.svg',
        ionIconName: null, moduleID: Modules.InternalOrders, hide: false, url: 'egne-tanker-select'
      },
      {//6
        title: '', whiteIconName: 'Icon_TankMonitor_W.svg', blackIconName: 'Icon_TankMonitor.svg',
        ionIconName: null, moduleID: Modules.TankMonitoring, hide: false, url: 'tank-monitoring-select'
      },
      {//7
        title: '', whiteIconName: 'Icon_Time_W.svg', blackIconName: 'Icon_Time.svg',
        ionIconName: null, moduleID: Modules.Activity, hide: true, url: 'activity-registration'
      },
      {//8
        title: '', whiteIconName: 'Icon_Time_W.svg', blackIconName: 'Icon_Time.svg',
        ionIconName: null, moduleID: Modules.ActivityNew, hide: false,
        url: 'activity-registration-multiple'
      },
      {//9
        title: '', whiteIconName: 'Icon_Fleet_W.svg', blackIconName: 'Icon_Fleet.svg',
        ionIconName: null, moduleID: Modules.SooloOrders, hide: false, url: 'fleet'
        
      },
      {//10
        title: '', whiteIconName: 'Icon_Stock_W.svg', blackIconName: 'Icon_Stock.svg',
        ionIconName: null, moduleID: Modules.StockGoods, hide: false, url: 'stock-goods'
      },
      {//11
        title: '', whiteIconName: 'Icon_Forms_W.svg', blackIconName: 'Icon_Forms.svg',
        ionIconName: null, moduleID: Modules.Documentation, hide: false, url: 'documentation'
      },
      {//12
        title: '', whiteIconName: 'Icon_FormsAdm_W.svg', blackIconName: 'Icon_FormsAdm.svg',
        ionIconName: null, moduleID: Modules.Leads, hide: false, url: 'forms-handling'
        
      },
      {//13
        title: '', whiteIconName: 'Icon_Settings_W.svg', blackIconName: 'Icon_Settings.svg',
        ionIconName: null, moduleID: Modules.Settings, hide: false, url: 'settings'
        
      },
      {//14
        title: '', whiteIconName: 'Icon_Orders_W.svg', blackIconName: 'Icon_Orders.svg',
        ionIconName: null, moduleID: Modules.Planner, hide: false, url: 'order-planner'
      },
    ];
//Keep settings as last item in menu!

    this.translateService.get('Menu.oversikt').subscribe(data => this.pages[0].title = data);
    this.translateService.get('Menu.beholdning').subscribe(data => this.pages[1].title = data);
    this.translateService.get('Menu.ordrer').subscribe(data => this.pages[2].title = data);
    this.translateService.get('Menu.workOrder').subscribe(data => this.pages[3].title = data);
    this.translateService.get('Menu.ordersProduct').subscribe(data => this.pages[4].title = data);
    this.translateService.get('Menu.egneTankerAutomater').subscribe(data => this.pages[5].title = data);
    this.translateService.get('Menu.tankpeiling').subscribe(data => this.pages[6].title = data);
    this.translateService.get('Menu.timer').subscribe(data => this.pages[7].title = data);
    this.translateService.get('Menu.timer').subscribe(data => this.pages[8].title = data);
    this.translateService.get('Menu.documentation').subscribe(data => this.pages[11].title = data);
    this.translateService.get('Menu.stockGoods').subscribe(data => this.pages[10].title = data);
    this.translateService.get('Menu.settings').subscribe(data => this.pages[13].title = data);
    this.translateService.get('Menu.sooloOrders').subscribe(data => this.pages[9].title = data);
    this.translateService.get('Menu.formsADM').subscribe(data => this.pages[12].title = data);
    this.translateService.get('Menu.orderPlanner').subscribe(data => this.pages[14].title = data);
    this.initializeApp();
    this.userService.moduleAccess.subscribe( data => {
      if (data != null) {
        this.pages.forEach(p => {
          data.forEach(m => {
            if (p.moduleID === m.Module) {
              p.hide = m.HideModule;
            }
          });
        });
      }
      const pages = _.filter(this.pages, (page) => {
        if (page.hide === false) {
          return page;
        }
      });
      this.menuItems = pages;
    });

     this.platform.ready().then(() => {
      //StatusBar..styleDefault();
      setTimeout(() => {
    try {
      SplashScreen.hide();
    } catch (error) {
      // Handle any errors here
    }
  }, 2000); // Adjust the time as necessary
      
    });
  }

  

  initializeApp() {
    forkJoin([this.storage.get(AppSettings.StorageKeys.Token), this.storage.get(AppSettings.StorageKeys.Username),
    this.storage.get(AppSettings.StorageKeys.Userlevel),
    this.storage.get(AppSettings.StorageKeys.ValidateTruckStock),
    this.storage.get(AppSettings.StorageKeys.UserType), this.userService.moduleAccess, this.userService.events]).subscribe(data => {
      AppSettings.Token = data[0];
      AppSettings.Username = data[1];
      AppSettings.UserLevel = data[2];
      AppSettings.ValidateTruckStock = data[3];
      AppSettings.UserType = data[4];
      if (data[5] != null) {
        this.pages.forEach(p => {
          data[5].forEach(m => {
            if (p.moduleID === m.Module) {
              p.hide = m.HideModule;
            }
          });
        });
      }
      this.currentPage = data[6];
      const pages = _.filter(this.pages, (page) => {
        if (page.hide === false) {
          return page;
        }
      });
      this.menuItems = pages;
      if (AppSettings.Token == null || AppSettings.Username == null || AppSettings.UserLevel == null ||
        data[5] == null) {
        this.currentPage = 'login';
        this.router.navigate(['/login']);
      } else {
        if (this.userService.startupViewSettings === null) {
          this.currentPage = 'home';
          this.router.navigate(['/home']);
        } else {
          this.currentPage = this.userService.startupViewSettings;
          this.router.navigate(['/' + this.userService.startupViewSettings]);
        }
      }
    }, error => { this.router.navigate(['/login']); });

    // this.languageNavigator();
  }

  languageNavigator() {
    let userLang = navigator.language.split('-')[0]; // use navigator lang if available
    userLang = /(nb|en)/gi.test(userLang) ? userLang : 'en';

    // optional, default is 'en'
    this.translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(userLang);

   
  }

  openPage(page) {
    this.currentPage = page.url;
    this.router.navigate(['/' + page.url]);
    this.menu.close('first');
  }
/*
  setGoogleApi() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&callback=Function.prototype`;
    script.async = true;
        script.defer = true;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
*/
setGoogleApi() {
    return new Promise((resolve, reject) => {
        // Check if the Google Maps API is already loaded
        if (typeof google !== 'undefined' && google.maps) {
            resolve(google); // Resolve with the google object if already loaded
            return;
        }

        // Create a global callback
        window['googleMapsApiLoaded'] = () => resolve(google); // Resolve with the google object when loaded

        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
script.async = true; // This ensures the script is loaded asynchronously
script.defer = true; // This defers execution until the page has loaded
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&callback=googleMapsApiLoaded`;
  
        script.onerror = () => reject(new Error('Google Maps API failed to load')); // Reject the promise on error

        // Append the script to the head element
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
    });
}


async loadGoogleMaps() {
    try {
        await this.setGoogleApi();
        // Google Maps API is loaded and ready to use
        console.log('Google Maps API loaded successfully.');
        // You can now initialize your map and other Google services here
    } catch (error) {
        console.error('Error loading Google Maps API:', error);
    }
}

 
}
