import { Component, OnDestroy, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AppSettings } from '../../shared/app-settings';
import { UserService } from '../../shared/user-service';
import { TruckTrailerChangeComponent } from '../truck-trailer-change/truck-trailer-change';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription, interval, Subject,forkJoin,firstValueFrom} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'logout',
    templateUrl: 'logout.html',
    styleUrls: ['logout.scss']
})
export class LogoutComponent implements OnDestroy, OnInit {

     currentTruckTrailer: any;
   // private currentTruckTrailerSub$: Subscription;
     name: string = "";
     company: string;
     showAlert = false;
     showReleaseAlert = false;
    private i = 0;
    private alertMsg = '';
    private stopPolling = new Subject();

    @Output()
    private truckTrailerChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private navCtrl: NavController, private storage: Storage, private loadingController: LoadingController,
        public translateService: TranslateService, private modalCtrl: ModalController,  public userService: UserService,
        private cd: ChangeDetectorRef, private alertCtrl: AlertController, private router: Router) {

    }

      async ngOnInit() {
        const loader = await this.loadingController.create({ message: "Henter data..." });
        await loader.present();

        try {
            // Parallel requests
            const truckAndTrailerPromise = firstValueFrom(this.userService.getCurrentTruckAndTrailer());
            const basicDataPromise = firstValueFrom(this.userService.getBasicData());

            const [currentTruckTrailer, basicDataResponse] = await Promise.all([truckAndTrailerPromise, basicDataPromise]);

            // Process currentTruckTrailer response
            this.currentTruckTrailer = currentTruckTrailer;
            if (this.currentTruckTrailer.Truck.ID === '') {
                // this.showTrucksTrailersModal(); // Call your method here
            } else {
                AppSettings.VolumeToStandardTruck = this.currentTruckTrailer.Truck.ConvertToStandardLitre;
            }

            // Process basicDataResponse
            this.name = basicDataResponse.Name;
            this.company = basicDataResponse.Company;
            AppSettings.Username = basicDataResponse.UserID;

            // Sequential requests
            const releaseNotesRead = await this.storage.get(AppSettings.StorageKeys.ReleaseNotesRead);
            this.showReleaseAlert = releaseNotesRead != AppSettings.appVersion;

            // Handling messages
            const messages = await firstValueFrom(this.userService.getMessages());
            // Process messages response

            loader.dismiss();
        } catch (error) {
            console.error(error);
            loader.dismiss();
        }

        // Start the interval for periodic tasks
        this.startPeriodicTasks();
    }

    private startPeriodicTasks() {
        interval(3000 * 60).pipe(
            takeUntil(this.stopPolling)
        ).subscribe(() => {
            this.checkForNewMessages();
            // Any other periodic tasks...
        });
    }

    private checkForNewMessages() {
        // Implement the logic to check for new messages
        this.userService.getMessages().subscribe(data => {
            // Your logic to handle new messages
        });
    }


    async showTrucksTrailersModal() {
        const modal = await this.modalCtrl.create({
            component: TruckTrailerChangeComponent,
            cssClass: 'trailer-modal'
        });
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data) {
                this.truckTrailerChanged.emit();
            }
        });
    }

     delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
    showNewMessageAlert(title: string, message: string, id: number) {
        if (!this.showAlert) {
            this.alertCtrl.create({
                header: message,
                message: title,
                buttons: [
                    {
                        text: 'OK',
                        handler: () => {
                           this.storage.set(AppSettings.StorageKeys.LastMessageId, id);
                            this.showAlert = true;
                        }
                    }
                ]
            }).then(confirm => { confirm.present(); });

        }

    }

    processQueue() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                const showError = () => {
                    forkJoin([this.translateService.get('Sync.error'), this.translateService.get('Sync.errorSyncing')]).subscribe(data => {
                        this.alertCtrl.create({
                            header: data[0],
                            subHeader: data[1],
                            buttons: ['OK']
                        }).then(alert => { alert.present(); });

                    });
                };

                this.userService.processQueue().subscribe(data => {
                    if (data) {
                        this.storage.remove(AppSettings.StorageKeys.LocalStorageData);
                        this.userService.localStorageData = [];
                        forkJoin([this.translateService.get('Sync.successful'),
                        this.translateService.get('Sync.syncCompletedSuccessfully')]).subscribe(sync => {
                            this.alertCtrl.create({
                                header: data[0],
                                subHeader: data[1],
                                buttons: ['OK']
                            }).then(alert => { alert.present(); });
                        });
                    } else {
                        showError();
                    }
                }, error => {
                    showError();
                });
            });
            loader.dismiss();
        });
    }

    navigateHome() {
        this.showAlert = false;
        this.router.navigate(['/home']);
        this.userService.events.next('home');
    }

    logout() {
        console.log("logout called");
        this.storage.remove(AppSettings.StorageKeys.Token).then(value => {
            this.storage.remove(AppSettings.StorageKeys.UserFullName);
            this.storage.remove(AppSettings.StorageKeys.Userlevel);
            this.storage.remove(AppSettings.StorageKeys.CompanyNo);
            localStorage.removeItem('moduleAccess');
            this.userService.moduleAccess.next(null);
            this.userService.clearCache();
            this.router.navigate(['/login']);
            this.userService.events.next('home');
        });
    }

    ngOnDestroy() {
          this.stopPolling.next(null);
        this.stopPolling.complete();

       // this.currentTruckTrailerSub$.unsubscribe();
    }

    navigateToRelease()
    {
        this.storage.set(AppSettings.StorageKeys.ReleaseNotesRead, AppSettings.appVersion);
        this.showReleaseAlert = false;
        this.router.navigate(['/release-notes']);
    }
}
