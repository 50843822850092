<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'ReleaseNotes.releaseNotes' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div [innerHTML]="releaseNotes"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button fill="outline" (click)="goHome()">
      <ion-icon name="arrow-back-circle-outline"></ion-icon>&nbsp;
      Hjem</ion-button
    >
  </div>
</ion-content>
