import { ErrorHandler, Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { AppSettings } from './app-settings';
import { UserService } from './user-service';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class KCMAErrorHandler extends ErrorHandler {

    constructor(
        private storage: Storage, private alertController: AlertController, private translateService: TranslateService,
        private loadingController: LoadingController, private userService: UserService) {
        // super(true);
        super();
    }

    async handleError(error) {
        if (error.status === 401) {
            if (!AppSettings.UnauthenticatedRequest) {

                const loading = await this.loadingController.create();
                loading.present().then(value => loading.dismiss());

                AppSettings.UnauthenticatedRequest = true;
                // mostly happens when token is expired..
                this.translateService.get('Error.tokenExpired').subscribe(tokenExpired => {
                    this.translateService.get('Error.authFailed').subscribe(authFailed => {
                        const elements = document.getElementsByTagName('ion-loading') as any;
                        for (const element of elements) {
                            element.style.display = 'none';
                        }
                        this.translateService.get('buttons.ok').subscribe(ok => {
                            this.alertController.create({
                                header: tokenExpired,
                                message: authFailed,
                                buttons: [{
                                    text: ok,
                                    role: 'cancel',
                                    handler: () => {
                                        this.userService.clearCache();
                                        this.storage.remove(AppSettings.StorageKeys.Token)
                                            .then(value => location.href = 'login');
                                    }
                                }]
                            }).then(al => al.present());
                        });

                    });
                });
            }
        } else {
            this.translateService.get('buttons.ok').subscribe(ok => {

                const alert = this.alertController.create({
                    header: 'Ooops!',
                    message: error.statusText,
                    buttons: [{
                        text: ok,
                        role: 'cancel'
                    }]
                });

                // alert.present();
            });
        }
        // log error to DB at a later date--> Tapesh - 12th May, 2017. For now log to console.
        console.log(error);
    }
}
