import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../shared/user-service';
import { ModalController, NavParams, LoadingController, ToastController } from '@ionic/angular';
import _ from 'lodash';
import { Storage } from '@ionic/storage-angular';

@Component({
    selector: 'legg-til-produkt',
    templateUrl: 'legg-til-produkt.html',
    styleUrls: ['legg-til-produkt.scss']
})
export class LeggTilProduktComponent {
    leggForm: FormGroup;
    products: any[];
    private inventoryList: any[];
    private deviationLimit = 0;
    private showAllProducts: boolean;

    constructor(
        public viewCtrl: ModalController, private userService: UserService, private translateService: TranslateService,
        public navParams: NavParams, private loadingController: LoadingController, private formBuilder: FormBuilder,
        private toastController: ToastController, private storage: Storage) {

        this.inventoryList = this.navParams.data.inventoryList;
        this.deviationLimit = this.navParams.data.deviationLimit;
        this.showAllProducts = this.navParams.data.showAllProducts;

        this.leggForm = formBuilder.group({
            productNo: ['', Validators.compose([Validators.required])],
            quantity: ['', Validators.compose([Validators.required, Validators.pattern('\\d+')])]
        });

        this.translateService.get('Loading.loading').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });

            loader.present().then(() => {
                this.userService.getProducts().subscribe(data => {
                    const products: any[] = data;
                    this.products = _.filter(products, (p) => {
                        if (this.showAllProducts === true) {
                            return p;
                        } else {
                            return p.ProductType === 10;
                        }
                    });
                    loader.dismiss();
                });

                // this.userService.getProducts().subscribe(data => {

                //    let products: any[] = data;

                //    this.products = _.filter(products, (p) => {
                //        return p.ProductType == 10;
                //    });

                //  loader.dismiss();
                // });

            });
        });
    }

    addProdukt() {
        const controls = this.leggForm.controls;
        const productNo = this.leggForm.get('productNo').value;
        const product = _.find(this.products, (p) => {
            return p.ProductNo === productNo;
        });
        this.viewCtrl.dismiss({ Product: product, Quantity: this.leggForm.get('quantity').value });
    }

    dismiss() {
        this.viewCtrl.dismiss();
    }
}
