import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
    selector: '[appRemoveZeroOnFocus]' // Attribute selector
})
export class RemoveZeroOnFocusDirective {

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    @HostListener('focus')
    onFocus(): void {
        const value = this.elementRef.nativeElement.value;
        if (parseFloat(value) === 0) {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
        }
    }

    @HostListener('blur')
    onBlur(): void {
        const value = this.elementRef.nativeElement.value;
        if (value.trim() === '') {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', '0');
        }
    }
}
