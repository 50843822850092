import { Component, OnDestroy, QueryList, ViewChild } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, AlertController, IonInput } from '@ionic/angular';
import { Utils } from '../../shared/utils';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { InternalOrdertype, TransactionType } from '../../shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { OrderNextDayConfirmComponent } from '../../components/order-next-day-confirm/order-next-day-confirm';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppLauncher } from '@capacitor/app-launcher';
import { Geolocation } from '@capacitor/geolocation';
import _ from 'lodash';
import { Subscription, debounceTime } from 'rxjs';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LeggTilProduktSalesComponent } from '../../components/legg-til-produkt-sales/legg-til-produkt-sales';
import { CustomValidators } from '../../shared/validators';
import { OrderConfirmSalesComponent } from '../../components/order-confirm-sales/order-confirm-sales';
import { Storage } from '@ionic/storage-angular';
import { EditOrderSharedComponent } from '../../components/edit-order-shared/edit-order-shared';
/*import { DriverProvider } from 'protractor/built/driverProviders';*/
import { doesNotThrow } from 'assert';
import { CustomerSearchComponent } from 'src/app/components/customer-search/customer-search/customer-search.component';
import { CustomerAddressSearchComponent } from 'src/app/components/customer-address-search/customer-address-search/customer-address-search.component';

@Component({
    selector: 'page-ordre-details-sales',
    templateUrl: 'ordre-details-sales.html',
    styleUrls: ['ordre-details-sales.scss']
})
export class OrdreDetailsSalesPage implements OnDestroy {
    order: any;
    originalOrder: any;
    dateFormat;
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    currentLatitude: number = null;
    currentLongitude: number = null;
    VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = false;
    private currentTruckTrailerSub$: Subscription;
    private deviationLimit = 0;
    private inventoryList: any;
    errorMessages: any = [];
    products: any[];
    isHidden: boolean;
    private CameFromApproval: boolean;
    private navigationExtras: NavigationExtras;
    allowDescriptionChange; 
    expand = false;
    location = location.pathname;
    hideCost = false;
    userlevel;
    UsePlanner = false;
    selectedDeliveryDate = new Date().toISOString();
    dateChanged: boolean = false;
    transporters;
    openModal = false;
    DisableGPSSend = false;
        DoNotCalcVolToStandard: false
        priceAgreements;

     latLongPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    customerModal: boolean = false;
    customerAddressModal: boolean = false;
    mapLat: number = 0.0;
    mapLong: number = 0.0;
    selectedCustomer;
    inEdit = false;
isMapVisible = false;    
bolKeepDetails:boolean = true;
    DoNotChangePrice = false;

    @ViewChild('customerNameInput', { static: false }) customerNameInput: IonInput;
    @ViewChild('addressInput', { static: false }) addressInput: IonInput;

 private posOptions = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 30000,
    

 
  };
  private watchId: string;
    isEdited: boolean;

    constructor(
        private formBuilder: FormBuilder, private route: ActivatedRoute, public userService: UserService,
        public navCtrl: NavController, private router: Router, private alertCtrl: AlertController,
        private translateService: TranslateService, private loadingController: LoadingController, private modalCtrl: ModalController,
        private geolocation: Geolocation, private toastController: ToastController,
        private storage: Storage) {
        this.route.queryParams.subscribe(params => {
            this.order = JSON.parse(params.order);
            this.CameFromApproval = params.approval;
           // this.selectedDeliveryDate = this.order.DeliveryDate;
            //    this.order = JSON.parse(params['order']);f
            this.CameFromApproval = (params.approval.toString() === 'true');
            this.userlevel = AppSettings.UserLevel;

            // used to pass info to orders view for init an update of orderslist
            this.navigationExtras = {
                queryParams: {
                    reloadData: true,
                    orderno:this.order.OrderNo,
                },
                skipLocationChange: true
            };

            this.storage.get(AppSettings.StorageKeys.UsePlanner).then(planner => { this.UsePlanner = planner; });
            this.storage.get(AppSettings.StorageKeys.DisableGPSSend).then(gps => { this.DisableGPSSend = gps; });


            this.userService.smUpLargeScreen.subscribe(data => {
                if (data) {
                    this.expand = data;
                }
            });
            const orderlinesGroup: FormGroup = new FormGroup({});
            this.currentTruckTrailerSub$ = this.userService.getCurrentTruckAndTrailer().subscribe(resp => {
                if (AppSettings.ValidateTruckStock) {
                    this.userService.getCurrentInventoryCurrent(null, null).subscribe(data => {
                        this.inventoryList = data.InventoryList2;
                        this.userService.getDeviationLimit(TransactionType.LoadingFromTruck).subscribe(totalData => {
                            this.deviationLimit = totalData.DeviationLimit;
                        });
                    });
                }
            });

             this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
       
            this.userService.GetTransporters().subscribe(data => { this.transporters = data; });

            // console.log(this.order);
            this.order.TotalOrder = 0;
            this.storage.get(AppSettings.StorageKeys.AllowDescriptionChange).then(data => {
                this.allowDescriptionChange = data;
                if (this.order.Orderlines) {
                    for (const orderline of this.order.Orderlines) {
                        this.dynamicControl(orderlinesGroup, orderline);

                        //check if manual pricing
                        if(orderline.UserChangedCost || orderline.UserChangedPrice || orderline.UserChangedFreight)
                        {
                            this.DoNotChangePrice = true;
                        }
                    }
                    this.getTotalOrder();
                }
            });

            this.detailsForm = formBuilder.group({ 
                customerName: [this.order.CustomerName, Validators.compose([Validators.required])],
                customerAddress1: [this.order.CustomerAddress1],
                customerAddress2: [this.order.CustomerAddress2],
                customerPostalCode: [this.order.CustomerPostalCode],
                priceAgreementID: [this.order.PriceAgreementID],
                transporterID: [this.order.Transporter],
                customerCity: [this.order.CustomerCity],
                address: [this.order.DeliveryName],
                addressAddress1: [this.order.DeliveryAddress1],
                addressAddress2: [this.order.DeliveryAddress2],
                addressZip: [this.order.DeliveryPostalCode],
                addressCity: [this.order.DeliveryCity],
                gps: [this.order.Latitude + ","+ this.order.Longitude, [Validators.pattern(this.latLongPattern)]],
                organizationNo: [],
                orderLines: orderlinesGroup,
                orderComments: [this.order.OrderComments],
                orderInternalComments: [this.order.OrderInternalComments],
                internalInfo: [this.order.InternalInfo],
                tankInfo: [this.order.TankInfo],
                deliveryInfo: [this.order.DeliveryInfo],
                phoneOnSite: [this.order.PhoneOnSite],
                phoneManager: [this.order.PhoneManager],
                email: [this.order.Email, [CustomValidators.emailValidator]],
                DeliveryEmail: [this.order.EmailDelivery, [CustomValidators.emailValidator]],
                additionalProductsDelivered: [this.order.AdditionalProductsDelivered],
                doNotCalculateVolume: [this.order.DoNotCalculateVolume],
                doNotSendGPS: this.DisableGPSSend,
                customerReference: [this.order.CustomerReference],
                customerPurchaseOrderNo: [this.order.CustomerPurchaseOrderNo],
                deliveryDate: [this.order.ConfirmedDeliveryDate],
                emailTo: ['', [CustomValidators.emailValidator]],
                customerBuyer: [this.order.CustomerBuyer],
            });

            this.mapLat = this.order.Latitude;
            this.mapLong = this.order.Longitude;
            


            this.userService.getProducts().subscribe(data => {
                this.products = data;
            });

            this.updateCommentValidators();

            this.translateService.get('General.dateFormat').subscribe(value => {
                this.dateFormat = value;
            });

        });
    }

    showhide() {
        this.isHidden = !this.isHidden;
    }
     closeModal(){
    this.openModal = false;
}


  ngOnInit() {

   
    setTimeout(() => {
        this.getCurrentPosition();
        this.watchPosition();
    }, 0);

    this.detailsForm.valueChanges.pipe(
        debounceTime(500)
    ).subscribe(() => {
        this.isEdited = true;
    });

  }

    getUpdatedFields(): any {
    const updatedFields = {};
    const currentValues = this.detailsForm.value;
try {
     Object.keys(currentValues).forEach(key => {
      if (JSON.stringify(currentValues[key]) !== JSON.stringify(this.originalOrder[key])) {
        updatedFields[key] = currentValues[key];
      }
    });
} catch (error) {
    
}
   

    return updatedFields;
  }



  ngOnDestroy() {

     this.currentTruckTrailerSub$.unsubscribe();

    if (this.watchId) {
      Geolocation.clearWatch({ id: this.watchId }).catch((error) => {
        console.error('Error stopping geolocation watch', error);
      });
    }
  }

  toggleKeepDetails(change)
{
    this.bolKeepDetails = !change;
    return this.bolKeepDetails;
}
   async getCurrentPosition() {
    try {
      const resp = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 30000,
      });
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    } catch (error) {
      // Implement your error handling logic here
    }
  }

    getFormControl(field) {
        return this.detailsForm.get(field);
    }
  watchPosition() {
    const watch = Geolocation.watchPosition({}, (position, error) => {
      if (error) {
        //this.showGeolocationError();
        return;
      }

      if (position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      } else {
       // this.showGeolocationError();
      }
    });
  }

  async showGeolocationError() {
    const message = await this.translateService.get('Error.locationError').toPromise();
    const toast = await this.toastController.create({
      message,
      duration: 3000,
    });
    toast.present();
    this.currentLatitude = this.currentLongitude = null;
  }

  readOnly()
  {
    return !this.inEdit;
  }
    dynamicControl(formGroup: FormGroup, orderline) {
        orderline.changeProduct = false;
        const quantityDelivered: FormControl = new FormControl(orderline.QuantityDelivered,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const price: FormControl = new FormControl(orderline.UnitPrice,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const discount: FormControl = new FormControl(orderline.UnitDiscount,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const freight: FormControl = new FormControl(orderline.UnitFreightCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const cost: FormControl = new FormControl(orderline.UnitCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        if (this.allowDescriptionChange) {
            const productNo: FormControl = new FormControl(orderline.Description, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        } else {
            const productNo: FormControl = new FormControl(orderline, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        }
        formGroup.addControl('quantity' + orderline.LineNo, quantityDelivered);
        formGroup.addControl('price' + orderline.LineNo, price);
        formGroup.addControl('discount' + orderline.LineNo, discount);
                formGroup.addControl('freight' + orderline.LineNo, freight);

        formGroup.addControl('cost' + orderline.LineNo, cost);
    }

 

    validateQuantity(orderline) {
        this.setValue(orderline, 'quantity');
        if (this.inventoryList != null) {
            this.errorMessages = [];
            let item;
            for (item of this.order.Orderlines) {
                if (item.ProductType === 10) {
                    let truckProduct = _.find(this.inventoryList, (p: any) => {
                        return p.ProductNo === item.InventoryProductNo;
                    });
                    if (!truckProduct) {
                        this.translateService.get('LeggTilProdukt.productNotFound').subscribe(value => {
                            this.errorMessages.push(value);
                        });
                        return;
                    } else {
                        if (truckProduct.InventoryProductNo) {
                            truckProduct = _.find(this.inventoryList, (p: any) => {
                                return p.ProductNo === truckProduct.InventoryProductNo;
                            });
                        }

                        let maxValue: any = null;

                        if (truckProduct.Quantity + this.deviationLimit < item.QuantityDelivered) {
                            maxValue = truckProduct.Quantity;
                        }
                        if (maxValue != null) {
                            this.translateService.get('OrdreDetails.maxValue').subscribe(value => {
                                this.errorMessages.push(value.format(item.Description, maxValue));
                            });
                        }
                    }
                }
            }
        }
        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    setValue(orderline, fieldName) {
        const control = this.detailsForm.controls.orderLines;
        if (fieldName === 'price') {
            orderline.UnitPrice = control.get('price' + orderline.LineNo).value;
            orderline.UserChangedPrice = true;
        }
        if (fieldName === 'discount') {
            orderline.UnitDiscount = control.get('discount' + orderline.LineNo).value;
        }
        if (fieldName === 'freight') {
            orderline.UnitFreightCost = control.get('freight' + orderline.LineNo).value;
            orderline.UserChangedFreight = true;
        }
        if (fieldName === 'cost') {
            orderline.UnitCost = control.get('cost' + orderline.LineNo).value;
            orderline.UserChangedCost = true;
        }
        if (fieldName === 'quantity') {
            orderline.QuantityDelivered = control.get('quantity' + orderline.LineNo).value;
        }

        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    getTotalOrder() {
        this.order.TotalOrder = 0.0;
        for (const item of this.order.Orderlines) {
            const total = +item.OrderLineTotal + (+this.order.TotalOrder);
            this.order.TotalOrder = (total).toFixed(2);
        }
    }

    orderlineTotal(orderline) {
        let lineTotal = 0;
        orderline.OrderLineTotal = 0;
        if (orderline.UnitPrice >= 0) {
            if (orderline.QuantityDelivered > 0) {
                lineTotal = ((orderline.UnitPrice+orderline.PriceAdditions) * orderline.QuantityDelivered);
            } else {
                lineTotal = ((orderline.UnitPrice+orderline.PriceAdditions) * orderline.Quantity);
            }
        }

        if(orderline.UnitFreightCost != 0 || orderline.UnitFreightCost != undefined)
        {
             if (orderline.QuantityDelivered > 0) {
                lineTotal += ((orderline.UnitFreightCost) * orderline.QuantityDelivered);
            } else {
                lineTotal += ((orderline.UnitFreightCost) * orderline.Quantity);
            }
        }

        if (orderline.UnitDiscount > 0) {
            orderline.OrderLineTotal = (lineTotal - ((lineTotal * orderline.UnitDiscount) / 100)).toFixed(2);
        } else {
            orderline.OrderLineTotal = lineTotal.toFixed(2);
        }
    }

    changeElement($event, orderline) {
        if ($event) {
            orderline.changeProduct = false;
        } else {
            orderline.changeProduct = true;
        }
    }

    selectProduct(event, orderline) {
        if (event.type === 'change' || event.value) {
            const control = this.detailsForm.controls.orderLines;
            const product = _.find(this.products, { ProductNo: event.target ? event.target.value : event.value.ProductNo });
            if (product) {
                orderline.ProductNo = product.ProductNo;
                orderline.Description = product.Description;
                orderline.ProductShortCode = product.ShortCode;
                orderline.ProductColorCode = product.ColorCode;
                orderline.UnitPrice = product.UnitPrice;
                orderline.UnitCost = product.UnitCost;
                control.get('price' + orderline.LineNo).setValue(orderline.UnitPrice);
                control.get('cost' + orderline.LineNo).setValue(orderline.UnitCost);
                
                this.orderlineTotal(orderline);
                this.getTotalOrder();
            }
            if (this.allowDescriptionChange) {
                if (control.get('productNo' + orderline.LineNo).value !== '') {
                    orderline.Description = control.get('productNo' + orderline.LineNo).value;
                }
            }
        } else {
            orderline.changeProduct = false;
        }
    }

    showConfirm(orderline) {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.confirmMessage').subscribe(value => { modalText = value.format(orderline.Description); });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.deleteOrderLine(orderline);
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }

    deleteOrderLine(orderline) {
        const index = _.findIndex(this.order.Orderlines, { ProductNo: orderline.ProductNo });
        if (index > -1) {
            this.translateService.get('Loading.saving').subscribe(async value => {
                const loader = await this.loadingController.create({ message: value });
                loader.present().then(() => {
                    this.userService.deleteOrderline(this.order.OrderNo, orderline.LineNo).subscribe(resp => {
                        loader.dismiss();

                        this.order.Orderlines.splice(index, 1);
                        this.getTotalOrder();

                    });
                });
            });
        }
    }

    updateCommentValidators() {
        const commentControl = this.detailsForm.get('orderComments');
        const additionalProductsDelivered = this.detailsForm.get('additionalProductsDelivered');
        if (additionalProductsDelivered.value) {
            commentControl.setValidators([Validators.required]);
        } else {
            commentControl.setValidators([]);
        }
        commentControl.updateValueAndValidity();
    }

    deliveredQuantityExists() {
        const orderline = _.find(this.order.Orderlines, (orderLine: any) => {
            return orderLine.QuantityDelivered > 0;
        });
        if (orderline) {
            return true;
        }
        return false;
    }

    additionalProductsDeliveredChanged() {
        this.updateCommentValidators();
    }

    async  moveOrderToNextDay() {
        const modal = await this.modalCtrl.create(
            {
                component: OrderNextDayConfirmComponent,
                cssClass: 'order-next-day-confirm'
            });
        modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                this.translateService.get('Loading.saving').subscribe(async value => {
                    const loader = await this.loadingController.create({ message: value });
                    loader.present().then(() => {
                        this.userService.moveOrderToNextDay(this.order.OrderNo).subscribe(resp => {
                            loader.dismiss();
                            this.router.navigate(['/orders']);
                        });
                    });
                });
            }
        });
    }

    showhideCameFrom() {
        return this.CameFromApproval;
    }

    async leggTilProdukt() {
        const modal = await this.modalCtrl.create({
            component: LeggTilProduktSalesComponent,
            cssClass: 'legg-til-produkt-sales',
            componentProps: { inventoryList: this.inventoryList, deviationLimit: this.deviationLimit }
        });
        await modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                const orderline: any = {
                    ProductColorCode: data.data.Product.ColorCode,
                    ProductShortCode: data.data.Product.ShortCode,
                    Description: data.data.Product.Description,
                    LineNo: this.identity,
                    ProductNo: data.data.Product.ProductNo,
                    Quantity: data.data.Quantity,
                    QuantityDelivered: data.data.Quantity,
                    UnitPrice: data.data.Product.UnitPrice,
                    UnitCost: data.data.Product.UnitCost,
                    UnitDiscount: 0,
                    UnitFreightCost: 0,
                    OrderLineTotal: 0,
                    UserChangedCost:false,
                    UserChangedPrice:false,
                    UserChangedFreight:false
                };
                this.order.Orderlines.push(orderline);
                const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
                this.dynamicControl(formGroup, orderline);
                this.identity--;
            }
        });
    }

    async setOrderAsCompleted() {
        const modal = await this.modalCtrl.create({
            component: OrderConfirmSalesComponent,
            cssClass: 'order-confirm-sales',
            componentProps: { order: this.order }
        });


        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });
                        
                        const selectedDate = this.dateChanged ? this.detailsForm.get('deliveryDate').value : this.selectedDeliveryDate;

                        let date = new Date();

                        if(selectedDate != undefined){
                            date = new Date(selectedDate);
                        }
                        
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));

                        if(this.selectedCustomer){
                            this.order.CustomerID = this.selectedCustomer.CustomerID;
                        }
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.InternalInfo = this.detailsForm.get('internalInfo').value;
                        this.order.TankInfo = this.detailsForm.get('tankInfo').value;
                        this.order.DeliveryInfo = this.detailsForm.get('deliveryInfo').value;
                        this.order.CustomerReference = this.detailsForm.get('customerReference').value;
                        this.order.CustomerPurchaseOrderNo = this.detailsForm.get('customerPurchaseOrderNo').value;

                        this.order.PhoneOnSite = this.detailsForm.get('phoneOnSite').value;
                        this.order.PhoneManager = this.detailsForm.get('phoneManager').value;
                        this.order.Email = this.detailsForm.get('email').value;
                        this.order.EmailDelivery = this.detailsForm.get('DeliveryEmail').value;
                        this.order.DeliveryEmail = this.order.EmailDelivery;
                        this.order.AdditionalProductsDelivered = this.detailsForm.get('additionalProductsDelivered').value;
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.DoNotSendGPS = this.detailsForm.get('doNotSendGPS').value;
                        this.order.DoNotConfirmOrder = false;
                        this.order.DeliveryDate = selectedDate;//this.detailsForm.get('deliveryDate').value;
                        this.order.ConfirmedDeliveryDate = selectedDate;//this.order.DeliveryDate;
                        this.order.EmailTo = this.detailsForm.get('emailTo').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });
                        this.order.Transporter = this.detailsForm.get('transporterID').value;

                        if(this.DoNotChangePrice)
                            {
                                this.order.Orderlines.forEach(element => {
                                
                                    element.UserChangedCost = true,
                                    element.UserChangedPrice = true,
                                    element.UserChangedFreight = true
                                });
                            }

                        if (this.DoNotSendGPS) {
                            this.order.Longitude = -1;
                            this.order.Latitude = -1;
                        } else {
                            this.order.Latitude = this.currentLatitude;
                            this.order.Longitude = this.currentLongitude;
                        }
                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();

                                this.router.navigate(['/orders'], this.navigationExtras);
                            });
                        });
                    });
                }
            }
        });
    }



    async SaveOrder() {
     

                this.submitAttempt = true;
                if (this.detailsForm.valid) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });

                        var selectedDate;
                        if(this.CameFromApproval) 
                            selectedDate = this.dateChanged ? this.detailsForm.get('deliveryDate').value : this.order.ConfirmedDeliveryDate;
                        else
                            selectedDate = this.dateChanged ? this.detailsForm.get('deliveryDate').value : this.order.DeliveryDate;

                        let date = new Date();

                        if(selectedDate != undefined){
                            date = new Date(selectedDate);
                        }
                        const dateStr = (Utils.padNumber(date.getMonth() + 1)) + '-' +
                            (Utils.padNumber(date.getDate())) + '-' + (Utils.padNumber(date.getFullYear()));

                        this.order.IsEdited = this.isEdited;
                        this.order.OrderComments = this.detailsForm.get('orderComments').value;
                        this.order.OrderInternalComments = this.detailsForm.get('orderInternalComments').value;
                        this.order.InternalInfo = this.detailsForm.get('internalInfo').value;
                        this.order.TankInfo = this.detailsForm.get('tankInfo').value;
                        this.order.DeliveryInfo = this.detailsForm.get('deliveryInfo').value;
                        this.order.CustomerReference = this.detailsForm.get('customerReference').value;
                        this.order.CustomerPurchaseOrderNo = this.detailsForm.get('customerPurchaseOrderNo').value;
                        this.order.PhoneOnSite = this.detailsForm.get('phoneOnSite').value;
                        this.order.PhoneManager = this.detailsForm.get('phoneManager').value;
                        this.order.Email = this.detailsForm.get('email').value;
                        this.order.EmailDelivery = this.detailsForm.get('DeliveryEmail').value;
                        this.order.AdditionalProductsDelivered = this.detailsForm.get('additionalProductsDelivered').value;

                        this.order.CustomerOrgNo = this.detailsForm.get('organizationNo').value;
          
                        this.order.CustomerBuyer = this.detailsForm.get('customerBuyer').value;
                        this. order.DeliveryEmail = this.detailsForm.get('DeliveryEmail').value;
                        this.order.Transporter = this.detailsForm.get('transporterID').value;

                        
                        this.order.DoNotCalculateVolume = this.detailsForm.get('doNotCalculateVolume').value;
                        this.order.ConDeliveryDateStr = dateStr;
                        this.order.EmailTo = this.detailsForm.get('emailTo').value;
                        this.order.DeliveryDate = selectedDate;// this.detailsForm.get('deliveryDate').value;
                        this.order.ConfirmedDeliveryDate = selectedDate;//this.order.DeliveryDate;
                        if(this.DoNotChangePrice)
                            {
                                this.order.Orderlines.forEach(element => {
                                
                                    element.UserChangedCost = true,
                                    element.UserChangedPrice = true,
                                    element.UserChangedFreight = true
                                });
                            }

                        this.DoNotSendGPS = this.detailsForm.get('doNotSendGPS').value;
                        this.storage.get(AppSettings.StorageKeys.Depot).then(depotNo => { this.order.DepotNo = depotNo; });
                        this.order.DoNotConfirmOrder = true;
                        
                        
                        if(this.mapLat > 0 && this.mapLong > 0){
                         this.order.Latitude = this.mapLat;
                            this.order.Longitude = this.mapLong;
                            this.order.DeliveryGPS = this.mapLat + "," + this.mapLong;
                        }

                        if (this.order.LoadingTemp === 15) {
                            this.order.LoadingTemp = AppSettings.LoadingTemp;
                        }
                        loader.present().then(() => {
                            this.userService.setOrderAsCompleted(this.order).subscribe(resp => {
                                loader.dismiss();

                                if (this.CameFromApproval === true) {
                                    this.router.navigate(['/orders-sales'], this.navigationExtras);
                                } else {
                                    this.router.navigate(['/orders'], this.navigationExtras);
                                }
                            });
                        });
                    });
                } 
                else
                {
                   this.showValidationErrors();
                }
            
        
    }



    isOrderMine(): boolean {
        
        if (this.order.AssignedDriver && this.order.AssignedDriver.toString() === AppSettings.Username) {
            return true;
        }
        return false;
    }

    async loadDrivingInstructions() {
    let destinationUrl;

    // Check if coordinates are valid, otherwise use the address
    if (this.order.Latitude !== 0 && this.order.Longitude !== 0) {
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.order.Latitude},${this.order.Longitude}`;
    } else {
        // Encode the address
        const encodedAddress = encodeURIComponent(`${this.order.DeliveryAddress1} ${this.order.DeliveryPostalCode} ${this.order.DeliveryCity}`);
        destinationUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
    }

    // Try opening the URL
    try {
        await AppLauncher.openUrl({ url: destinationUrl });
    } catch (error) {
        console.error('Error launching navigation:', error);
        const toast = await this.toastController.create({
            message: 'Could not open navigation: ' + error.message,
            duration: 3000
        });
        toast.present();
    }
}


    unassignOrder() {
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.unassignOrder(this.order.OrderNo).subscribe(data => {
                    this.order.AssignedDriver = 0;

                    if(this.UsePlanner)
                    {
                        this.order.AssignedToTruck = 0;
                        this.order.AssignedToTrailer = 0;
                    }

                    loader.dismiss();
                    this.router.navigate(['/orders']);
                });
            });
        });
    }



    navigateToOrdersList() {
        this.navCtrl.pop();
    }

    expandFooterMenu() {
        this.expand = !this.expand;
    }

    async editCustomerAndAddress() {

        this.inEdit =  !this.inEdit;
        setTimeout(()=>{
 if(this.inEdit){this.initCustomerSearch(this.inEdit);}
        },100);
       

        // const modal = await this.modalCtrl.create({
        //     component: EditOrderSharedComponent,
        //     cssClass: 'order-shared',
        //     componentProps: { order: this.order }
        // });
        // modal.present();
        // modal.onDidDismiss().then((data: any) => {
        //     if (data.data) {
        //         this.translateService.get('Loading.saving').subscribe(async value => {
        //             const loader = await this.loadingController.create({ message: value });
        //             loader.present().then(() => {
        //                 this.userService.updateOrder(data.data.Order).subscribe(() => {
        //                     this.order.CustomerName = data.data.CustomerDetails.CustomerName;
        //                     this.order.CustomerAddress1 = data.data.CustomerDetails.CustomerAddress1;
        //                     this.order.CustomerAddress2 = data.data.CustomerDetails.CustomerAddress2;
        //                     this.order.CustomerCity = data.data.CustomerDetails.CustomerCity;
        //                     this.order.CustomerPostalCode = data.data.CustomerDetails.CustomerPostalCode;
        //                     this.order.DeliveryName = data.data.CustomerDetails.DeliveryName;
        //                     this.order.DeliveryAddress1 = data.data.CustomerDetails.DeliveryAddress1;
        //                     this.order.DeliveryAddress2 = data.data.CustomerDetails.DeliveryAddress2;
        //                     this.order.DeliveryCity = data.data.CustomerDetails.DeliveryCity;
        //                     this.order.DeliveryPostalCode = data.data.CustomerDetails.DeliveryPostalCode;
        //                     this.order.PriceAgreementID = data.data.CustomerDetails.PriceAgreementID;
                            
        //                     this.order.DeliveryActorNo = data.data.Order.DeliveryActorNo;

        //             if(data.data.Order.CustomerID != undefined)
        //                     this.order.CustomerID = data.data.Order.CustomerID
        //                 else 
        //                     this.order.CustomerID = -1;

        //                     loader.dismiss();
        //                 });
        //             });
        //         });
        //     }
        // });
    }

    confirmDeleteOrder() {
        let modalTitle;
        let labelText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.orderDeleteMessage').subscribe(value => {
            labelText = value;
        });
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            inputs: [
                {
                    name: 'checkbox',
                    type: 'checkbox',
                    label: labelText,
                    checked: false,
                    value: 'true',
                    handler: (input) => {
                        const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
                        if (input.checked) {
                            confirmBtn.disabled = false;
                        } else {
                            confirmBtn.disabled = true;
                        }
                    },
                }],
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    cssClass: 'confirmation',
                    handler: data => {
                        if (data.length > 0) {
                            this.deleteOrder();
                        }
                    }
                }
            ]
        }).then(confirm => {
            confirm.present();
            const confirmBtn = document.querySelector('.confirmation') as HTMLButtonElement;
            confirmBtn.disabled = true;
        });
    }

    deleteOrder() {
        this.userService.deleteOrder(this.order.OrderNo).subscribe(data => {
            
            if(this.CameFromApproval)
                this.router.navigate(['/orders-sales'], this.navigationExtras);
            else
                this.router.navigate(['/orders'], this.navigationExtras);

        });
    }

    toggleHideCost()
    {
        this.hideCost = !this.hideCost;
    }

    plukkOrApne() {
        
        this.translateService.get('Loading.saving').subscribe(async value => {
            const loader = await this.loadingController.create({ message: value });
            loader.present().then(() => {
                this.userService.assignOrderToDriver(this.order.OrderNo, null).subscribe(data => {
                    this.order.AssignedDriver = AppSettings.Username;
                    loader.dismiss();
                    this.router.navigate(['/orders'], this.navigationExtras);
                    //this.loadData();
                });
            });
        });

    
    }
    dateTimeUpdated(ev:any)
    {
        try {
        
        this.selectedDeliveryDate = ev.detail.value;
        this.dateChanged = true;
        } catch (error) {
        console.error(error);
        }
        
    }

    showDeliveryDate()
    {
        if(this.dateChanged || !this.CameFromApproval)
            return this.selectedDeliveryDate;
        else
            return this.order.ConfirmedDeliveryDate;
      
    }

      showCustomerNo()
  {
if(this?.selectedCustomer != null)
        return this.selectedCustomer?.CustomerNo == 0 ? true : false;
else
    return false;
  }


    async initCustomerSearch(editMode)
    {
        this.findCustomer(this.getFormControl('customerName').value, editMode);
    }
    
    async initCustomerAddressSearch()
    {
        if(this.selectedCustomer)
            {
                var query = this.getFormControl('address').value;
              
                this.findCustomerAddress(query);
            }
            
        else
            {
             this.translateService.get('General.SelectCustomer').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
      }
    }

    
      async findCustomer(query, editMode) {
        
      
        const modal = await this.modalCtrl.create({
            component: CustomerSearchComponent,
            cssClass: 'customer-search.component', componentProps: { query: query, editMode:editMode }
        });
        this.customerModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
            if (data) {
                
                this.customerSelected(data.data.customer, editMode);
               
            }
        });
    }

       async findCustomerAddress(query) {
        
        const modal = await this.modalCtrl.create({
            component: CustomerAddressSearchComponent,
            cssClass: 'customer-address-search.component', componentProps: { customerNo: this.selectedCustomer.CustomerNo, addressList: this.selectedCustomer.AddressList, query: query, editMode: this.inEdit }
        });
        this.customerAddressModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
          try {
             if (data) {
               if(data != null)
                this.addressSelected(data.data.address);
               
            }
          } catch (error) {
            
          }
           
        });
    }


  addressSelected($event) {
        if ($event != null) {
                        this.customerAddressModal = true;

            const originalObject = $event;
            this.order.addressID = originalObject.AddressID;
            this.order.DeliveryActorNo = originalObject.AddressID;
            this.getFormControl('address').setValue(originalObject.Name == originalObject.Address1 ? this.selectedCustomer.Name : originalObject.Name);
            this.getFormControl('addressAddress1').setValue(originalObject.Address1);
            this.getFormControl('addressAddress2').setValue(originalObject.Address2);
            this.getFormControl('addressZip').setValue(originalObject.PostalCode);
            this.getFormControl('addressCity').setValue(originalObject.City);

                         this.order.DeliveryName = this.getFormControl('address').value;
                this.order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                this.order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                this.order.DeliveryCity = this.getFormControl('addressCity').value;
                this.order.DeliveryPostalCode = this.getFormControl('addressZip').value;


           
            if(!this.bolKeepDetails){
            this.getFormControl('DeliveryEmail').setValue(originalObject.DeliveryEmail);
            this.getFormControl('internalInfo').setValue(originalObject.InternalInfo);
            this.getFormControl('customerBuyer').setValue(originalObject.ContactNameOnSite);
            this.getFormControl('tankInfo').setValue(originalObject.TankInfo);
            this.getFormControl('phoneOnSite').setValue(originalObject.PhoneOnSite);
            this.getFormControl('phoneManager').setValue(originalObject.ProjectManager);
            this.getFormControl('deliveryInfo').setValue(originalObject.DeliveryInfo);
           
                      //  this.customerAddressModal = false;
            }
            this.getFormControl('gps').setValue(originalObject.GPS);
                           

            this.splitGpsCoordinates(originalObject.GPS);
        }
    }

    splitGpsCoordinates(coordinates: string) {


      if(coordinates.trim() == "")
      {
        this.mapLat = 0.0;
        this.mapLong = 0.0;
      }
      else
{
      try {
        const [latitudeStr, longitudeStr] = coordinates.split(',');

  if (latitudeStr && longitudeStr) {
    const latitude = parseFloat(latitudeStr.trim());
    const longitude = parseFloat(longitudeStr.trim());

    // Validate that parsed values are numbers
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.mapLat = latitude;
            this.mapLong = longitude;
  }else
{this.mapLat = 0.0;
  this.mapLong = 0.0;
}
  }
      } catch (error) {
        this.mapLat = 0;
    this.mapLong = 0;
      }
  
    }
    }

    

    customerSelected(customer, editMode = false) {
        {
            try {
                
                       this.customerModal = true;

            if (customer.CustomerBlockedStatus > 0) {
                this.getFormControl('customerName').setErrors({ incorrect: true });
                this.customerModal = false;
            } else {
                if(customer.CustomerNo != this.order.CustomerID)
                    {
                        this.clearAddress();
                        this.order.CustomerCreationStatus = 4;
                    }
                this.selectedCustomer = customer;
                this.order.CustomerID = customer.CustomerNo;
               
                //this.addressDataService.addressList = this.selectedCustomer.CustomerAddresses;
                //this.addressDataService.setErpIDParent(customer.ErpID);
                
                this.getFormControl('customerName').setValue(customer.Name);
                this.getFormControl('customerAddress1').setValue(customer.Address.trim() == '' ? customer.Address2 : customer.Address);
                this.getFormControl('customerAddress2').setValue('');
                this.getFormControl('customerPostalCode').setValue(customer.PostalCode);
                this.getFormControl('customerCity').setValue(customer.City);
                this.getFormControl('organizationNo').setValue(customer.OrgNo);
                this.getFormControl('email').setValue(customer.Email);


                this.order.CustomerName = this.getFormControl('customerName').value;
                this.order.CustomerAddress1 = this.getFormControl('customerAddress1').value;
                this.order.CustomerAddress2 = this.getFormControl('customerAddress2').value;
                this.order.CustomerCity = this.getFormControl('customerCity').value;
                this.order.CustomerPostalCode = this.getFormControl('customerPostalCode').value;



                  if(!this.bolKeepDetails){
                this.getFormControl('customerReference').setValue(customer.CustomerReference);
                this.order.CustomerReference = this.getFormControl('customerReference').value;
                  }
                if(this.selectedCustomer?.AddressList?.length > 0 && editMode == false)
                    {
                        this.initCustomerAddressSearch();
                    }
                                    //   this.customerModal = false;

            }
             } catch (error) {
                console.error(error);
            }
          
        }
    }

     checkInput(controlName: string) {
    const control = this.detailsForm.get(controlName);
    const inputValue = control ? control.value : '';
    const ionInput = document.querySelector(`[formControlName="${controlName}"]`);

    if (ionInput) {
      if (inputValue) {
        ionInput.classList.add('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.add('item-has-value');
        }
      } else {
        ionInput.classList.remove('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.remove('item-has-value');
        }
      }
    }
  }
  clearAddress() {

   
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'gps'
    
    ];

    formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        control.setValue('');
      }
    });

    this.order.addressID = 0;
    this.mapLat = 0;
            this.mapLong = 0;

    this.clearAddressDetails();
         
    this.checkAllInputs(); // Ensure labels are updated after clearing values

  }
clearAddressDetails() {

    if(!this.bolKeepDetails){
    const formControls = [
    
      'DeliveryEmail',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo',
       'customerReference'
    ];

    formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        control.setValue('');
      }
    });

    this.order.addressID = 0;
    this.mapLat = 0;
            this.mapLong = 0;

    this.checkAllInputs(); // Ensure labels are updated after clearing values
}
  }

   checkAllInputs() {

    if(!this.bolKeepDetails){
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo',
      'customerReference'
    ];

   formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        this.checkInput(controlName);
    }
});
  }}

  
onGpsInputChange(event: any) {
    const value = event.detail.value; // The new value of the input field
    console.log('GPS Input Changed:', value);

    // Additional processing, such as validation or updating other component properties
    this.splitGpsCoordinates(value);
  }

    toggleMapVisibility() {
    this.isMapVisible = !this.isMapVisible;  // Toggle visibility
  }

    async showValidationErrors() {
    const formControls = this.detailsForm.controls;
    let errorMessage = 'Før du kan lagre må du ordne følgende: \n\n';
    
    for (const field in formControls) {
      if (formControls[field].invalid) {
        const errors = formControls[field].errors;
        if (errors?.required) {
          errorMessage += `${field} er påkrevd. \n`;
        }
        else if (errors?.minlength) {
          errorMessage += `${field} må være minst ${errors.minlength.requiredLength} antall tegn. \n`;
        }
        else if (errors?.email) {
          errorMessage += `${field} må være en gyldig e-post adresse. \n`;
        }
        else {
          errorMessage += `${field} inneholder feil. \n`;
        }
      }
    }

    const toast = await this.toastController.create({
      message: errorMessage,
      duration: 8000,
      color: 'danger',
      position: 'bottom',
   
     buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

    calculateTotal(orderLine)
    {
        var total = 0;
        var qty = orderLine.QuantityDelivered > 0 ? orderLine.QuantityDelivered : orderLine.Quantity;
        total = ((orderLine.OrderLineTotal)-(orderLine.UnitCost*qty))/qty;


        return total;
    }
}
