import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL, ERP_ID_PARENT, IS_CUSTOMER_NO } from './tokens';

@Injectable({
  providedIn: 'root'
})
export class AddressData {
  private url: string;
  public addressList: any[] = [];

  constructor(private http: HttpClient
    ,@Inject(ERP_ID_PARENT) private erpIDParent: number
    ,@Inject(API_URL) private apiurl: string
    ,@Inject(IS_CUSTOMER_NO) private isCustomerNo: boolean = false) {
    this.url = apiurl;
  }

  setErpIDParent(erpIDParent: number, erpIsCustomerNo: boolean = false) {
    this.erpIDParent = erpIDParent;
    this.isCustomerNo = erpIsCustomerNo;
  }

  setAddresslist(addressList:any[])
  {
    this.addressList = addressList;
  }

  search(term: string): Observable<any[]> {
    if (this.addressList?.length > 0) {
      return new Observable(observer => {
        const matches = this.addressList
          .filter(address => address.Name.toLowerCase().includes(term.toLowerCase())
        || address.Address1.toLowerCase().includes(term.toLowerCase()
         || address.ContactNameOnSite.toLowerCase().includes(term.toLowerCase())
          || address.ProjectManager.toLowerCase().includes(term.toLowerCase())
      || address.Address2?.toLowerCase().includes(term.toLowerCase())))
         .map(address => ({
    AddressID: address.AddressID,
    Name: address.Name,
    Address1: address.Address1,
    Address2: address?.Address2,
    PostalCode: address.PostalCode,
    City: address.City,
    ContactNameOnSite: address?.ContactNameOnSite,
    ProjectManager: address.ProjectManager,
    GPS: address.GPS,
    PhoneOnSite: address?.PhoneOnSite,
    Email: address?.Email,
    InternalInfo: address?.InternalInfo,
    TankInfo: address?.TankInfo,
    Latitude: address?.Latitude,
    Longitude: address?.Longitude,
    AddressType: address?.AddressType,
    AddressDetails: address?.AddressDetails,
    DeliveryInfo: address?.DeliveryInfo,
    DeliveryZone: address?.DeliveryZone
}));

        observer.next(matches);
        observer.complete();
      });
    } else if (this.erpIDParent > 0) {
      return this.http.get<any[]>(`${this.url}${term}&erpIDParent=${this.erpIDParent}&isCustomerNo=${this.isCustomerNo}`)
        .pipe(map(res => res.map(address => ({
   AddressID: address.AddressID,
    Name: address.Name,
    Address1: address.Address1,
    Address2: address?.Address2,
    PostalCode: address.PostalCode,
    City: address.City,
    ContactNameOnSite: address?.ContactNameOnSite,
    ProjectManager: address.ProjectManager,
    GPS: address.GPS,
    PhoneOnSite: address?.PhoneOnSite,
    Email: address?.Email,
    InternalInfo: address?.InternalInfo,
    TankInfo: address?.TankInfo,
    Latitude: address?.Latitude,
    Longitude: address?.Longitude,
    AddressType: address?.AddressType,
    AddressDetails: address?.AddressDetails,
    DeliveryInfo: address?.DeliveryInfo,
    DeliveryZone: address?.DeliveryZone
        }))));
    }
  }
}
