import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'order-next-day-confirm',
    templateUrl: 'order-next-day-confirm.html',
    styleUrls: ['order-next-day-confirm.scss']
})

export class OrderNextDayConfirmComponent {
    constructor(public modalCtrl: ModalController) {
    }

    dismiss(flag) {
        this.modalCtrl.dismiss(flag);
    }
}
