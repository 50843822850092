<ion-content class="ion-padding">
  <ion-grid class="ion-no-padding customer-sign">
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-text color="primary">
          <h6>
            <ion-icon name="create-outline"></ion-icon> {{ 'CustomerSign.title'
            | translate }}
          </h6>
        </ion-text>
        <ion-list lines="none">
          <form [formGroup]="signForm">
            <ion-item>
              <span class="placeholder-width"
                >{{ 'CustomerSign.name' | translate }}</span
              >
              <input type="text" formControlName="name" />
            </ion-item>
            <ion-item
              class="item-validation"
              *ngIf="!signForm.controls.name.valid && (signForm.controls.name.dirty)"
            >
              <ion-text color="danger">
                <p [hidden]="!signForm.controls.name.errors.required" no-margin>
                  {{ 'CustomerSign.required' | translate }}
                </p>
              </ion-text>
            </ion-item>
            <ion-item>
              <span class="placeholder-width"
                >{{ 'CustomerSign.sign' | translate }}</span
              >
              <signature-pad
                formControlName="sign"
                [options]="signaturePadOptions"
                (onEndEvent)="drawComplete()"
                ngDefaultControl
                id="sign"
              >
              </signature-pad>
            </ion-item>
            <ion-item
              class="item-validation"
              *ngIf="!signForm.controls.sign.valid && (signForm.controls.sign.dirty)"
            >
              <ion-text color="danger">
                <p [hidden]="!signForm.controls.sign.errors.required" no-margin>
                  {{ 'CustomerSign.required' | translate }}
                </p>
              </ion-text>
            </ion-item>
          </form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-buttons class="ion-float-right ion-margin">
    <ion-button
      small
      (click)="dismiss()"
      color="grey"
      fill="outline"
      size="small"
    >
      <span>{{ 'General.avbryt' | translate }}</span>
    </ion-button>
    <ion-button
      small
      (click)="addSign()"
      [disabled]="!signForm.valid"
      fill="solid"
      size="small"
    >
      <span>{{ 'General.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</ion-footer>
