<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'WorkOrders.workOrders' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <ion-row class="full-height">
        <ion-col>
          <ion-row>
            <ion-col size-xs="12">
              <div class="page-title">
                <ng-container *ngIf="visKunDagens">
                  <ion-icon
                    name="calendar-outline"
                    ion-text
                    color="primary"
                    class="vertical-middle"
                  ></ion-icon>
                  {{'Day.' + currentDate.getDay() | translate}}
                  <span class="secondary">
                    {{currentDate | date:dateFormat}}
                    <ion-button
                      (click)="previousDay()"
                      class="no-padding button-no-text vertical-top ion-no-margin"
                      fill="clear"
                    >
                      <ion-icon
                        color="lightFour"
                        slot="icon-only"
                        name="caret-back-circle"
                      ></ion-icon>
                    </ion-button>
                    <ion-button
                      (click)="nextDay()"
                      class="no-padding button-no-text vertical-top ion-no-margin"
                      fill="clear"
                    >
                      <ion-icon
                        color="lightFour"
                        slot="icon-only"
                        name="caret-forward-circle"
                      ></ion-icon>
                    </ion-button>
                  </span>
                </ng-container>
              </div>
            </ion-col>
            <!--Company-->
            <ion-col>
              <div class="search-container">
                <ion-searchbar
                  [(ngModel)]="search"
                  class="customer-search"
                  placeholder="{{ 'TransactionLog.searchPlaceHolder' | translate }}"
                  (keyup)="filterChanged(null)"
                >
                </ion-searchbar>
                <ion-icon
                  name="close-circle-outline"
                  class="clear-icon"
                  (click)="clearSearch()"
                ></ion-icon>
              </div>
            </ion-col>
            <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4">
              <strong class="block label"
                >{{ 'Ordrer.company' | translate }}</strong
              >
              <select
                title="Velg selskap"
                size="1"
                [(ngModel)]="company"
                (ngModelChange)="visKunDagensChanged(null)"
                class="margin-boottom-4"
              >
                <option value="0">{{ 'General.select' | translate }}</option>
                <option
                  *ngFor="let company of companies"
                  value="{{company.CompanyNo}}"
                >
                  {{company.Name}}
                </option>
              </select>
              &nbsp;&nbsp;
            </ion-col>

            <!--Location-->
            <ion-col size-xs="6" size-sm="2" size-md="2" size-lg="2">
              <strong class="block label"
                >{{ 'Ordrer.orderlocation' | translate }}</strong
              >
              <select
                title="Lokasjon"
                size="1"
                [(ngModel)]="ordlocation"
                (ngModelChange)="filterChanged($event)"
                class="margin-boottom-4"
              >
                <option value=""></option>
                <option value="West">
                  {{ 'General.LocationWest' | translate }}
                </option>
                <option value="South">
                  {{ 'General.LocationSouth' | translate }}
                </option>
                <option value="North">
                  {{ 'General.LocationNorth' | translate }}
                </option>
                <option value="East">
                  {{ 'General.LocationEast' | translate }}
                </option>
              </select>
            </ion-col>

            <!--Priority-->
            <ion-col size-xs="6" size-sm="2" size-md="2" size-lg="2">
              <strong class="block label"
                >{{ 'Ordrer.prioritet' | translate }}</strong
              >
              <select
                title="Prioritet"
                size="1"
                [(ngModel)]="prioritet"
                (ngModelChange)="filterChanged($event)"
              >
                <option [ngValue]="-1">{{ 'Ordrer.alle' | translate }}</option>
                <option [ngValue]="0">{{ 'Ordrer.normal' | translate }}</option>
                <option [ngValue]="1">{{ 'Ordrer.urgent' | translate }}</option>
              </select>
            </ion-col>
          </ion-row>
          <div class="clear"></div>
          <div class="max-100 overflow-x">
            <div class="grid-width">
              <ion-row class="grid-header">
                <ion-col
                  size-xs="3"
                  size-md="1"
                  class="ion-text-center"
                  (click)="sort('OrderNo')"
                >
                  <strong>{{ 'Ordrer.ordre' | translate }}</strong>
                  &nbsp;
                  <ion-icon
                    ion-text
                    color="primary"
                    [name]="(sortProperty == 'OrderNo' && sortDirection == 'asc') || sortProperty != 'OrderNo' ? 'caret-up' : 'caret-down'"
                  >
                  </ion-icon>
                </ion-col>
                <ion-col size-xs="9" size-md="4" (click)="sort('DeliveryName')">
                  <strong>{{ 'Ordrer.kunde' | translate }}</strong>
                  &nbsp;
                  <ion-icon
                    ion-text
                    color="primary"
                    [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'"
                  >
                  </ion-icon>
                </ion-col>
                <ion-col size="3">
                  <strong>&nbsp;</strong>
                </ion-col>
                <ion-col size="4">
                  <strong></strong>
                </ion-col>
              </ion-row>
              <ion-list>
                <ion-row
                  *ngFor="let order of filteredOrders; let isOdd = odd; let i = index"
                  [class.odd]="isOdd"
                  [attr.itemid]="order.OrderNo"
                  id="{{order.OrderNo}}"
                >
                  <ion-col>
                    <ion-row>
                      <ion-col
                        size-xs="3"
                        size-md="1"
                        class="handle ion-text-center"
                      >
                        <div
                          (click)="expandOrder(order)"
                          class="cursor-pointer"
                        >
                          <ion-text
                            [color]="isOrderMine(order) == true ? 'success' : ''"
                          >
                            <strong>{{order.OrderNo}}</strong></ion-text
                          >
                          <div
                            *ngIf="isOrderMine(order) == true"
                            class="handle"
                          >
                            <ion-icon
                              name="person-outline"
                              color="success"
                              class="litte-big-font handle"
                            ></ion-icon>
                          </div>
                        </div>
                      </ion-col>
                      <ion-col size-xs="9" size-md="3">
                        <div
                          class="cursor-pointer orderlist"
                          (click)="expandOrder(order)"
                        >
                          <ion-icon
                            color="primary"
                            [name]="!order.expandedView ? 'add-circle-outline' : 'remove-circle-outline'"
                            class="litte-big-font vertical-text-bottom orderlistIcon"
                          >
                          </ion-icon>
                          <div class="orderlistHeading">
                            <strong>{{order.DeliveryName}}</strong>&nbsp;
                            ({{order.CustomerID}})
                            <ng-container
                              *ngIf="order.CustomerName != order.DeliveryName"
                            >
                              <br />
                              {{order.CustomerName}}
                            </ng-container>
                            <br />
                            {{order.DeliveryAddress1}}
                            <br />{{order.DeliveryPostalCode}}
                            {{order.DeliveryCity}}
                          </div>
                        </div>
                      </ion-col>
                      <ion-col
                        size-xs="12"
                        class="hidden-xs ion-text-center"
                        [class.bigger-font]="userService.fontSettings==2"
                        [class.biggest-font]="userService.fontSettings==4"
                        size-md="2"
                      >
                        <div
                          class="circle"
                          [class.red-circle]="order.IsUrgent"
                          [class.grey-circle]="!order.IsUrgent"
                        ></div>
                        <strong>
                          <span
                            class="vertical-top"
                            [innerText]="getDateText(order,1) | async"
                          ></span>
                        </strong>
                        <span
                          *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00' && order.ConfirmedDeliveryDate != '01.01.0001'"
                          color="danger"
                          class="upper bold"
                        >
                          <br />

                          <ion-icon
                            name="alert-circle-outline"
                            class="circle"
                            color="danger"
                          ></ion-icon
                          >{{getDateText(order,2) | async}}
                        </span>
                      </ion-col>

                      <ion-col
                        size-xs="3"
                        size-md="1"
                        class="ion-text-center"
                        [class.urgent]="order.OrderCategory == 1"
                      >
                        <ion-icon
                          name="location-outline"
                          class="litte-big-font handle"
                        ></ion-icon>
                        <br />{{ showOrderLocation(order.OrderLocation) }}
                      </ion-col>

                      <ion-col size-xs="9" size-md="2">
                        <div
                          [class.bigger-font]="userService.fontSettings==2"
                          [class.biggest-font]="userService.fontSettings==4"
                          *ngIf="order.CustomerPurchaseOrderNo || order.DeliveryInfo"
                        >
                          <ion-text
                            ><strong
                              >{{ 'NewOrder.customerReference' | translate
                              }}</strong
                            >&nbsp; {{order.CustomerPurchaseOrderNo}}<br />
                            {{order.DeliveryInfo}}
                          </ion-text>
                        </div>
                        {{order.OrderComments}}
                      </ion-col>

                      <ion-col size-xs="12" size-md="3" class="ion-text-right">
                        <ion-button
                          *ngIf="order.AssignedDriver == 0"
                          (click)="plukkOrApne(order)"
                          [color]="order.AssignedDriver != 0 ? 'success' : ''"
                          fill="solid"
                          class="plukk-apne"
                        >
                          <ion-icon name="checkbox-outline"></ion-icon> &nbsp;
                          <ng-container
                            >{{ 'Ordrer.plukk' | translate }}
                          </ng-container>
                        </ion-button>

                        <ion-button
                          (click)="navigteToWorkOrderDetils(order)"
                          fill="solid"
                        >
                          <ion-icon name="create-outline"></ion-icon> &nbsp; {{
                          'Ordrer.edit' | translate }}
                        </ion-button>
                        <ion-button
                          *ngIf="userlevel <= 9"
                          (click)="approveForInvoice(order)"
                          [hidden]="order.Approve"
                          fill="solid"
                          color="success"
                        >
                          <ion-icon name="checkbox-outline"></ion-icon> &nbsp;
                          {{ 'Ordrer.complete' | translate }}
                        </ion-button>
                      </ion-col>

                      <!-- EXPANDED VIEW -->
                      <ion-col
                        size-sm="12"
                        *ngIf="order.expandedView"
                        [class.expandedOrder]="!isOdd"
                        class="orderlistExpanded"
                      >
                        <ion-list lines="none">
                          <ion-item>
                            <ion-row class="w-100">
                              <!-- EXPANDED VIEW - DATES -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="calendar-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold">
                                    <span
                                      >{{ 'OrdreDetails.leveringsdato' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span class="detailsIconText"
                                  >{{order.DeliveryDate | date:dateFormat}}

                                  <span
                                    *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                                  >
                                    /
                                    <ion-icon
                                      name="alert-circle-outline"
                                      class="circle vertical-text-bottom"
                                      color="danger"
                                    ></ion-icon>
                                    {{order.ConfirmedDeliveryDate |
                                    date:dateFormat}}</span
                                  >
                                </span>
                              </ion-col>

                              <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="people-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.CustomerBuyer' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span class="detailsIconText"
                                  >{{order.CustomerBuyer}}</span
                                >
                              </ion-col>

                              <!-- EXPANDED VIEW - OUR SELLER -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="person-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.OurSeller' | translate
                                      }} / {{ 'OrdreDetails.assigned' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span class="detailsIconText"
                                  >{{order.Seller}} /
                                  {{order.AssignedToName}}</span
                                >
                              </ion-col>
                            </ion-row>
                          </ion-item>

                          <ion-item>
                            <ion-row class="w-100 margin-top-10">
                              <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="cube-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.leveringsinfo' |
                                      translate }}</span
                                    ></ion-text
                                  >
                                </p>
                                <ng-container *ngIf="order.TankInfo"
                                  ><span class="detailsIconText"
                                    >{{order.TankInfo}}</span
                                  >
                                  <br />
                                </ng-container>
                                <ng-container *ngIf="order.DeliveryInfo"
                                  ><span class="detailsIconText"
                                    >{{order.DeliveryInfo}}</span
                                  >
                                  <br />
                                </ng-container>
                              </ion-col>
                              <!--Phone onsite-->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="call-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.onSite' | translate
                                      }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span
                                  class="detailsIconText"
                                  appPhoneRegex
                                  [inputText]="order.PhoneOnSite"
                                ></span>
                              </ion-col>
                              <!--Phone manager-->
                              <ion-col size-xs="12" size-sm="4">
                                <p>
                                  <ion-icon
                                    name="call-outline"
                                    class="vertical-text-bottom mr-10"
                                    color="primary"
                                  ></ion-icon>
                                  <ion-text class="upper bold"
                                    ><span
                                      >{{ 'OrdreDetails.manager' | translate
                                      }}</span
                                    ></ion-text
                                  >
                                </p>
                                <span
                                  class="detailsIconText"
                                  appPhoneRegex
                                  [inputText]="order.PhoneManager"
                                ></span>
                              </ion-col>
                            </ion-row>
                          </ion-item>

                          <ion-item
                            *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite"
                          >
                            <ion-row class="w-100 margin-top-10">
                              <!-- INTERNAL INFO-->
                              <ion-col size-xs="12" size-sm="4">
                                <ion-row *ngIf="order.InternalInfo != ''">
                                  <ion-col size-xs="12">
                                    <p>
                                      <ion-icon
                                        name="chatbox-ellipses-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.internInfo' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span
                                      class="detailsIconText"
                                      appPhoneRegex
                                      [inputText]="order.InternalInfo"
                                    ></span>
                                  </ion-col>
                                </ion-row>
                              </ion-col>

                              <!-- EXTERNAL INFO -->
                              <ion-col size-xs="12" size-sm="4">
                                <ion-row *ngIf="order.ExternalInfo != ''">
                                  <ion-col size-xs="12">
                                    <p>
                                      <ion-icon
                                        name="chatbox-ellipses-outline"
                                        class="vertical-text-bottom mr-10"
                                        color="primary"
                                      ></ion-icon>
                                      <ion-text class="upper bold"
                                        ><span
                                          >{{ 'OrdreDetails.externInfo' |
                                          translate }}</span
                                        ></ion-text
                                      >
                                    </p>
                                    <span class="detailsIconText"
                                      >{{order.ExternalInfo}}</span
                                    >
                                  </ion-col>
                                </ion-row>
                              </ion-col>

                              <ion-col size-xs="12" size-sm="4"> </ion-col>
                            </ion-row>
                          </ion-item>

                          <!-- PRODUCT LIST -->
                          <div
                            class="text-small ion-margin-bottom ion-margin-start margin-top-10 overflow-x"
                            *ngIf="order.Orderlines.length > 0"
                          >
                            <div class="product-grid">
                              <ion-row class="orderlistExpandProductHeading">
                                <ion-col size-xs="12" size-sm="5"
                                  ><b
                                    >{{ 'OrdreDetails.produkt' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.levert' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.cost' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.pris' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.rab' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="2"
                                  ><b
                                    >{{ 'OrdreDetails.total' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.DB' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                              </ion-row>
                              <ion-row
                                *ngFor="let orderLine of order.Orderlines"
                              >
                                <ion-col size-xs="12" size-sm="5">
                                  <div class="flex-parent">
                                    <ion-badge
                                      [style.background]="orderLine.ProductColorCode"
                                      >{{orderLine.ProductShortCode}}</ion-badge
                                    >&nbsp;
                                    <span class="truncate-text"
                                      >{{orderLine.Description}}</span
                                    >
                                  </div>
                                </ion-col>
                                <ion-col size-xs="1">
                                  <span class="slight-big-font"
                                    ><strong
                                      >{{orderLine.QuantityDelivered |
                                      number:'1.0-1'}}</strong
                                    ></span
                                  >
                                </ion-col>
                                <ion-col size-xs="1"
                                  >{{ orderLine.UnitCost |
                                  number:'1.1-3'}}</ion-col
                                >
                                <ion-col size-xs="1"
                                  >{{ orderLine.UnitPrice |
                                  number:'1.1-3'}}</ion-col
                                >
                                <ion-col size-xs="1"
                                  >{{ orderLine.UnitDiscount |
                                  number:'1.0-1'}}</ion-col
                                >
                                <ion-col size-xs="2"
                                  >{{ orderLine.OrderLineTotal |
                                  number:'1.0-0'}}</ion-col
                                >
                                <ion-col size-xs="1"
                                  >{{
                                  orderLine.OrderLineTotal-(orderLine.UnitCost *
                                  orderLine.QuantityDelivered)|
                                  number:'1.0-0'}}</ion-col
                                >
                              </ion-row>
                            </div>
                          </div>
                        </ion-list>
                      </ion-col>
                      <!-- END -->
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-list>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="hidden-sm-up footer-border">
    <ion-button fill="outline" (click)="expandFooterMenu()">
      <ion-icon
        name="menu-outline"
        class="ion-no-padding"
        color="primary"
      ></ion-icon>
    </ion-button>
    <ion-button
      fill="outline"
      class="no-padding pull-right"
      (click)="loadData()"
    >
      <ion-icon name="refresh" color="primary"></ion-icon>
    </ion-button>
  </div>
  <div [hidden]="!expand">
    <div *ngIf="userType !== UserType.Supplier">
      {{ 'Ordrer.mine' | translate }}
      <ion-toggle
        class="inline-block m-10"
        color="success"
        [(ngModel)]="mine"
        (ionChange)="filterChanged($event)"
      >
      </ion-toggle>
      &nbsp;&nbsp; {{ 'Ordrer.utildelteOrdrer' | translate }}
      <ion-toggle
        class="inline-block m-10"
        color="success"
        [(ngModel)]="utildelteOrdrer"
        (ionChange)="filterChanged($event)"
      ></ion-toggle>
      &nbsp;&nbsp; {{ 'Ordrer.nerMeg' | translate }}
      <ion-toggle
        class="inline-block m-10"
        color="success"
        [disabled]="latitude == null || longitude == null"
        [(ngModel)]="nearMeg"
        (ionChange)="filterChanged($event)"
      ></ion-toggle>
      &nbsp;&nbsp;
      <input
        type="number"
        autocomplete="off"
        class="nearmeg-input"
        [(ngModel)]="nearMegDistance"
        (ngModelChange)="filterChanged($event)"
        [disabled]="latitude == null || longitude == null || !nearMeg"
        placeholder="{{ 'Ordrer.near' | translate }}"
      />&nbsp;&nbsp; {{ 'Ordrer.visKunDagens' | translate }}
      <ion-toggle
        class="inline-block m-10"
        color="success"
        [(ngModel)]="visKunDagens"
        (click)="visKunDagensChanged($event)"
      ></ion-toggle>
      &nbsp;&nbsp;
    </div>
    <div class="footer-border hidden-xs"></div>
    <ion-button fill="outline" class="no-padding" routerLink="/work-new-order">
      <ion-icon name="add-circle-outline" color="primary"></ion-icon>&nbsp;
      <span class="black">{{ 'Ordrer.nyOrdre' | translate }}</span>
    </ion-button>
    <ion-button
      fill="outline"
      class="no-padding"
      routerLink="/new-customer"
      [disabled]="false"
    >
      <ion-icon
        name="person-add"
        class="ion-no-padding"
        color="primary"
      ></ion-icon
      >&nbsp;
      <span class="black">{{ 'Ordrer.customers' | translate }}</span>
    </ion-button>
    <ion-button
      fill="outline"
      class="no-padding"
      routerLink="/workorder-approval"
      *ngIf="userlevel <= 1"
      [disabled]="userType === UserType.Supplier"
    >
      <ion-icon
        name="checkbox-outline"
        color="primary"
        class="ion-no-padding"
      ></ion-icon
      >&nbsp;
      <span class="black">{{ 'Ordrer.approval' | translate }}</span>
    </ion-button>
    <ion-button
      fill="outline"
      class="no-padding hidden-xs"
      (click)="loadData()"
    >
      <ion-icon name="refresh" color="primary"></ion-icon>
    </ion-button>
  </div>
</ion-footer>
