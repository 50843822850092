import { FormControl } from '@angular/forms';

export class CustomValidators {
    static emailValidator(c: FormControl) {
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        return (!c.value || EMAIL_REGEXP.test(c.value)) ? null : {
            emailValidator: {
                valid: false
            }
        };
    }
}
