<ion-header>
  <ion-toolbar hideBackButton="true">
    <ion-menu-toggle>
      <ion-button fill="clear">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
    <ion-title>
      <span>{{ 'Menu.tildelOrdrer' | translate }}</span>
      <logout class="hidden-xs"></logout>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="scroll-content" style="margin-top: 65px">
    <ion-grid class="full-height">
      <!--OVERVIEW-->

      <ng-container *ngIf="showOverview ==true">
        <ion-row class="grid-header m-b-10"></ion-row>

        <ion-row>
          <ion-col
            *ngFor="let tr of assignedTrucks(); let isOdd = odd"
            class="overviewTruckWrapper"
          >
            <ion-row>
              <ion-col class="overviewTruckTitle ion-text-center"
                >{{ getTruckName(tr.TruckID) }} {{ getTrailerName(tr.TrailerID)
                }}</ion-col
              >
            </ion-row>

            <ion-row>
              <ion-col
                *ngFor="let list of assignedDates(tr); let isOdd = odd"
                class="overviewDayWrapper"
              >
                <ion-row>
                  <ion-col class="overviewTruckDate ion-text-center">
                    <ion-icon
                      name="calendar-outline"
                      ion-text
                      class="vertical-middle"
                    ></ion-icon>
                    {{list.PlannedDeliveryDate | date:dateFormat}}</ion-col
                  ></ion-row
                >
                <ion-row
                  *ngFor="let order of assignedOrdersOverview(); let isOdd = odd"
                  class="overviewOrderDayList"
                >
                  <ion-row
                    *ngIf="order.AssignedToTruck == tr.TruckID && order.AssignedToTrailer == tr.TrailerID && order.PlannedDeliveryDate == list.PlannedDeliveryDate"
                    class="w-100 margin-top-10"
                    [class.oddOverview]="isOdd"
                    [class.evenOverview]="!isOdd"
                  >
                    <ion-col size="10" class="padding-10">
                      <ion-badge
                        >Tur: <strong>{{order.RouteNo}}</strong></ion-badge
                      >
                      &nbsp;
                      <div
                        class="circle"
                        [class.red-circle]="order.IsUrgent"
                        [class.grey-circle]="!order.IsUrgent"
                      ></div>
                      &nbsp;<strong>{{order.OrderNo}}</strong><br />
                      <p>
                        <strong>{{order.DeliveryName}}</strong>
                        <span *ngIf="order.DeliveryName != order.CustomerName"
                          >{{order.CustomerName}}</span
                        >
                        <br />
                        {{order.DeliveryAddress1}},&nbsp;
                        {{order.DeliveryAddress2}}
                        <br />{{order.DeliveryPostalCode}}
                        {{order.DeliveryCity}}
                      </p>
                      <p>
                        <span
                          *ngFor="let orderLine of order.Orderlines"
                          class="m-r-5 orderline"
                        >
                          <ion-badge
                            [style.background]="orderLine.ProductColorCode"
                          >
                            {{orderLine.ProductShortCode}}</ion-badge
                          >
                          {{orderLine.Quantity}}
                        </span>
                      </p>
                      <div
                        class="small-font internal"
                        *ngIf="order.DeliveryInfo"
                      >
                        <ion-text color="danger"
                          >{{order.DeliveryInfo}}</ion-text
                        >
                      </div>
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                      <ion-button
                        (click)="unassignOrder(order)"
                        *ngIf="order.AssignedTruck != 0"
                        [style.color]="'primary'"
                        fill="outline"
                      >
                        <ion-icon name="close-circle-outline"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ng-container>

      <!--PLANNER-->
      <ng-container *ngIf="showOverview == false">
        <ion-row class="full-height">
          <ion-col>
            <ion-grid class="ion-no-oadding">
              <ion-row>
                <!--trucks-->

                <ion-col size-xs="12" size-md="4">
                  <ion-grid class="ion-no-padding">
                    <ion-row class="grid-header">
                      <ion-col size-xs="12">
                        <h6>
                          <strong class="ion-padding"
                            >{{'OrderPlanner.velgSjafor' | translate}}</strong
                          >
                        </h6>
                      </ion-col>
                    </ion-row>
                    <div class="overflow-x">
                      <div class="grid-width">
                        <ion-row class="lightBg">
                          <ion-col
                            size-xs="3"
                            *ngFor="let truck of filteredTrucks"
                            class="ion-no-padding"
                          >
                            <div
                              class="list-item"
                              [ngClass]="{'selected-item': truck == currentTruck}"
                              (click)="selectTruck(truck)"
                            >
                              <ion-icon
                                name="person-outline"
                                ion-text
                                [color]="truck == currentTruck ? 'secondary' : 'primary'"
                              ></ion-icon>
                              <strong>{{truck.Description}}</strong>
                              {{truck.Volume/1000}}m<sup>3</sup>
                            </div>
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                  </ion-grid>
                </ion-col>
                <ion-col size-xs="12" size-md="4">
                  <ion-grid class="ion-no-padding">
                    <ion-row class="grid-header">
                      <ion-col size-xs="12">
                        <h6>
                          <strong class="ion-padding"
                            >{{'OrderPlanner.velgTrailer' | translate}}</strong
                          >
                        </h6>
                      </ion-col>
                    </ion-row>
                    <div class="overflow-x">
                      <div class="grid-width">
                        <ion-row class="lightBg">
                          <ion-col
                            size-xs="3"
                            *ngFor="let trailer of filteredTrailers"
                            class="ion-no-padding"
                          >
                            <div
                              class="list-item"
                              [ngClass]="{'selected-item': trailer == currentTrailer}"
                              (click)="selectTrailer(trailer)"
                            >
                              <ion-icon
                                name="person-outline"
                                ion-text
                                [color]="trailer == currentTrailer ? 'secondary' : 'primary'"
                              ></ion-icon>
                              <strong>{{trailer.Description}}</strong>
                              {{trailer.Volume/1000}}m<sup>3</sup>
                            </div>
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                  </ion-grid>
                </ion-col>

                <!--sones-->
                <ion-col size-xs="12" size-md="4">
                  <ion-grid class="ion-no-padding">
                    <ion-row class="grid-header">
                      <ion-col size-xs="12">
                        <h6>
                          <strong class="ion-padding"
                            >{{'OrderPlanner.selectZone' | translate}}</strong
                          >
                        </h6>
                      </ion-col>
                    </ion-row>
                    <div class="overflow-x2">
                      <div class="grid-width">
                        <ion-row class="lightBg">
                          <ion-col
                            size-xs="3"
                            *ngFor="let zone of zones"
                            class="ion-no-padding"
                          >
                            <div
                              class="list-item"
                              [ngClass]="{'selected-item': zone == currentZone}"
                              (click)="selectZone(zone)"
                            >
                              <ion-icon
                                name="location-outline"
                                ion-text
                                [color]="zone == currentZone ? 'secondary' : 'primary'"
                              ></ion-icon>
                              {{zone.ZoneName}}
                            </div>
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="max-100 overflow-x">
              <div class="grid-width">
                <ion-row class="grid-header">
                  <ion-col
                    size-xs="12"
                    size-xl="12"
                    size="12"
                    class="ion-text-center"
                  >
                    <ion-segment
                      value="default"
                      (ionChange)="unassignedView($event)"
                      [(ngModel)]="tabUnassinged"
                      class="tabFont"
                    >
                      <ion-segment-button value="bulk">
                        <ion-label>Bulkordre</ion-label>
                      </ion-segment-button>
                      <ion-segment-button value="st1">
                        <ion-label>St1 kjøring</ion-label>
                      </ion-segment-button>

                      <ion-segment-button value="soolo">
                        <ion-label>Egne peilere</ion-label>
                      </ion-segment-button>
                      <ion-segment-button value="automat">
                        <ion-label>Automater</ion-label>
                      </ion-segment-button>
                    </ion-segment>
                  </ion-col>

                  <ion-col size-xs="12" size-xl="6"> </ion-col>
                </ion-row>
              </div>
            </div>

            <div class="max-100 overflow-x">
              <div class="grid-width">
                <ion-grid>
                  <ion-row>
                    <!--UNASSIGNED ORDERS-->

                    <ion-col col-xs="12" class="splitter-line">
                      <!--UNASSIGNED ORDERS-->
                      <ion-row [hidden]="showUnassigned('bulk')">
                        <ion-col size="2" size-sm="4">
                          <h4>
                            {{ 'OrderPlanner.UnAssigned' | translate }}
                            <span class="margin-lef"></span
                            ><ion-icon
                              name="clipboard-outline"
                              color="primary"
                            ></ion-icon
                            ><span class="color-primary"
                              ><span class="total-info"
                                >{{ cntUnAssigned }}</span
                              ></span
                            >
                          </h4>
                        </ion-col>
                        <ion-col size="2" size-sm="8">
                          <div class="pull-right-md flexCenter">
                            <div class="mr-10">
                              <ionic-selectable
                                class="prodFilter"
                                [items]="products"
                                placeholder="{{ 'General.ProductFilter' | translate }}"
                                itemValueField="ProductNo"
                                itemTextField="Description"
                                [canSearch]="true"
                                [canClear]="true"
                                [isMultiple]="true"
                                [shouldStoreItemValue]="true"
                                [(ngModel)]="prodFilter"
                                searchPlaceholder="{{'ActivityRegistration.typeToSearch' | translate }}"
                                clearButtonText="{{'ActivityRegistration.clear' | translate }}"
                                (onClose)="onProdfilterSelect($event)"
                              >
                                <ng-template ionicSelectableCloseButtonTemplate>
                                  <ion-icon
                                    name="close-circle-outline"
                                    style="font-size: 24px"
                                  ></ion-icon>
                                  &nbsp;{{ 'ActivityRegistration.cancel' |
                                  translate }}
                                </ng-template>
                                <ng-template
                                  ionicSelectableValueTemplate
                                  let-port="value"
                                  ><strong
                                    >{{prodFilter?.length}}
                                    {{'General.SelectedItems' | translate
                                    }}</strong
                                  >
                                </ng-template>
                              </ionic-selectable>
                            </div>
                            <select
                              title="Transporter filter"
                              id="transporters"
                              class="mr-10"
                              [(ngModel)]="currentTransporter"
                              (ngModelChange)="filterChanged($event)"
                            >
                              <option value="0">
                                {{ 'NewOrder.transporter' | translate }}
                              </option>
                              <option
                                *ngFor="let item of transporters"
                                value="{{item.ID}}"
                              >
                                {{item.Description}}
                              </option>
                            </select>

                            <select
                              title="Priority"
                              [(ngModel)]="prioritet"
                              (ngModelChange)="filterChanged($event)"
                              class="mr-10"
                            >
                              <option [ngValue]="-1">
                                {{ 'Ordrer.alle' | translate }}
                              </option>
                              <option [ngValue]="0">
                                {{ 'Ordrer.normal' | translate }}
                              </option>
                              <option [ngValue]="1">
                                {{ 'Ordrer.urgent' | translate }}
                              </option>
                            </select>

                            <ion-button
                              fill="outline"
                              class="no-padding pull-right mr-10"
                              (click)="visKunDagensChanged(null)"
                            >
                              <ion-icon
                                name="refresh-outline"
                                color="primary"
                              ></ion-icon>
                            </ion-button>
                          </div>
                        </ion-col>
                        <ion-col size="8" size-sm="11">
                          <ion-button
                            (click)="sort('DeliveryDate')"
                            class="sortButton"
                            fill="clear"
                          >
                            <ion-icon
                              ion-text
                              color="lightFour"
                              [name]="(sortProperty == 'DeliveryDate' && sortDirection == 'asc') || sortProperty != 'DeliveryDate' ? 'caret-up' : 'caret-down'"
                            >
                            </ion-icon
                            >{{ 'OrdreDetails.leveringsdato' | translate }}
                          </ion-button>

                          <ion-button
                            (click)="sort('DeliveryName')"
                            class="sortButton"
                            fill="clear"
                          >
                            <ion-icon
                              ion-text
                              color="lightFour"
                              [name]="(sortProperty == 'DeliveryName' && sortDirection == 'asc') || sortProperty != 'DeliveryName' ? 'caret-up' : 'caret-down'"
                            >
                            </ion-icon
                            >{{ 'OrdreDetails.kundeLeveringsadresse' | translate
                            }}
                          </ion-button>

                          <ion-button
                            (click)="sort('DeliveryPostalCode')"
                            class="sortButton"
                            fill="clear"
                          >
                            <ion-icon
                              ion-text
                              color="lightFour"
                              [name]="(sortProperty == 'DeliveryPostalCode' && sortDirection == 'asc') || sortProperty != 'DeliveryPostalCode' ? 'caret-up' : 'caret-down'"
                            >
                            </ion-icon
                            >{{ 'NewOrder.zip' | translate | uppercase }}
                          </ion-button>

                          <ion-button
                            (click)="sort('IsUrgent')"
                            class="sortButton"
                            fill="clear"
                          >
                            <ion-icon
                              ion-text
                              color="lightFour"
                              [name]="(sortProperty == 'IsUrgent' && sortDirection == 'asc') || sortProperty != 'IsUrgent' ? 'caret-up' : 'caret-down'"
                            >
                            </ion-icon
                            >{{ 'Ordrer.prioritet' | translate | uppercase }}
                          </ion-button>
                        </ion-col>
                      </ion-row>

                      <ion-row
                        class="grid-header m-b-10"
                        [hidden]="showUnassigned('bulk')"
                      ></ion-row>

                      <ion-row
                        class="w-100"
                        *ngFor="let order of filteredOrders; let isOdd = odd"
                        [class.odd]="isOdd"
                        [class.even]="!isOdd"
                        [hidden]="showUnassigned('bulk')"
                      >
                        <ion-col size="1" class="handle ion-text-center">
                          <ion-text
                            [color]="isOrderMine(order) == true ? 'success' : ''"
                            (click)="expandOrder(order)"
                          >
                            <div
                              class="circle"
                              [class.red-circle]="order.IsUrgent"
                              [class.grey-circle]="!order.IsUrgent"
                            ></div>
                            <strong>{{order.OrderNo}}</strong>
                            <br />
                            <span
                              [innerText]="getDateText(order) | async"
                              [class.color-primary]="isSelectedDate(order)"
                            ></span
                            ><br />
                            <span
                              *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                            >
                              /
                              <ion-icon
                                name="alert-circle-outline"
                                class="circle vertical-text-bottom"
                                color="danger"
                              ></ion-icon>
                              {{order.ConfirmedDeliveryDate |
                              date:dateFormat}}</span
                            >
                          </ion-text>
                          <div *ngIf="isOrderMine(order) == true">
                            <ion-icon
                              name="person-outline"
                              color="success"
                              class="litte-big-font handle"
                            >
                            </ion-icon>
                          </div>
                        </ion-col>
                        <ion-col size="3">
                          <strong>{{order.DeliveryName}}</strong>
                          <span *ngIf="order.DeliveryName != order.CustomerName"
                            ><br />{{order.CustomerName}}</span
                          >
                          <br />
                          {{order.DeliveryAddress1}},&nbsp;
                          {{order.DeliveryAddress2}}
                        </ion-col>
                        <ion-col size="2">
                          {{order.DeliveryPostalCode}} {{order.DeliveryCity}}
                        </ion-col>
                        <ion-col size="4" class="ion-text-left">
                          <span
                            *ngFor="let orderLine of order.Orderlines"
                            class="m-r-5 orderline"
                          >
                            <ion-badge
                              [style.background]="orderLine.ProductColorCode"
                            >
                              {{orderLine.ProductShortCode}}</ion-badge
                            >
                            {{orderLine.Quantity}}
                          </span>
                          <div
                            class="small-font internal"
                            *ngIf="order.DeliveryInfo"
                          >
                            <ion-text color="danger"
                              >{{order.DeliveryInfo}}</ion-text
                            >
                          </div>
                        </ion-col>
                        <ion-col size="2" class="ion-text-right">
                          <ion-button
                            fill="outline"
                            size="default"
                            class="no-padding"
                            (click)="confirmDeleteOrder(order.OrderNo)"
                          >
                            <ion-icon
                              name="trash-outline"
                            ></ion-icon> </ion-button
                          >&nbsp;
                          <ion-button
                            (click)="assignOrder(order)"
                            *ngIf="order.AssignedToTruck == 0"
                            fill="solid"
                            [disabled]="enableAssign()"
                            [style.color]="'success'"
                            class="plukk-apne"
                          >
                            <ion-icon name="person-outline"></ion-icon> &nbsp;
                            {{'OrderPlanner.tildel' | translate }}
                          </ion-button>
                          <ion-button
                            (click)="unassignOrder(order)"
                            *ngIf="order.AssignedToTruck != 0"
                            [style.color]="'primary'"
                            fill="solid"
                          >
                            <ion-icon name="close-circle-outline"></ion-icon>
                            &nbsp; {{ 'OrderPlanner.slippOrdre' | translate }}
                          </ion-button>
                        </ion-col>
                        <!-- EXPANDED VIEW -->
                        <ion-col
                          size-sm="12"
                          *ngIf="order.expandedView"
                          [class.expandedOrder]="!isOdd"
                          class="orderlistExpanded"
                        >
                          <ion-list lines="none">
                            <ion-item>
                              <ion-row class="w-100">
                                <!-- EXPANDED VIEW - DATES -->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon
                                      name="calendar-outline"
                                      class="vertical-text-bottom mr-10"
                                      color="primary"
                                    ></ion-icon>
                                    <ion-text class="upper bold">
                                      <span
                                        >{{ 'OrdreDetails.leveringsdato' |
                                        translate }}</span
                                      ></ion-text
                                    >
                                  </p>
                                  <span class="detailsIconText"
                                    >{{order.DeliveryDate | date:dateFormat}}

                                    <span
                                      *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                                    >
                                      /
                                      <ion-icon
                                        name="alert-circle-outline"
                                        class="circle vertical-text-bottom"
                                        color="danger"
                                      ></ion-icon>
                                      {{order.ConfirmedDeliveryDate |
                                      date:dateFormat}}</span
                                    >
                                  </span>
                                </ion-col>

                                <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon
                                      name="people-outline"
                                      class="vertical-text-bottom mr-10"
                                      color="primary"
                                    ></ion-icon>
                                    <ion-text class="upper bold"
                                      ><span
                                        >{{ 'OrdreDetails.CustomerBuyer' |
                                        translate }}</span
                                      ></ion-text
                                    >
                                  </p>
                                  <span class="detailsIconText"
                                    >{{order.CustomerBuyer}}</span
                                  >
                                </ion-col>

                                <!-- EXPANDED VIEW - OUR SELLER -->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon
                                      name="person-outline"
                                      class="vertical-text-bottom mr-10"
                                      color="primary"
                                    ></ion-icon>
                                    <ion-text class="upper bold"
                                      ><span
                                        >{{ 'OrdreDetails.OurSeller' | translate
                                        }}</span
                                      ></ion-text
                                    >
                                  </p>
                                  <span class="detailsIconText"
                                    >{{order.Seller}}</span
                                  >
                                </ion-col>
                              </ion-row>
                            </ion-item>

                            <ion-item>
                              <ion-row class="w-100 margin-top-10">
                                <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon
                                      name="cube-outline"
                                      class="vertical-text-bottom mr-10"
                                      color="primary"
                                    ></ion-icon>
                                    <ion-text class="upper bold"
                                      ><span
                                        >{{ 'OrdreDetails.leveringsinfo' |
                                        translate }}</span
                                      ></ion-text
                                    >
                                  </p>
                                  <ng-container *ngIf="order.TankInfo"
                                    ><span class="detailsIconText"
                                      >{{order.TankInfo}}</span
                                    >
                                    <br />
                                  </ng-container>
                                  <ng-container *ngIf="order.DeliveryInfo"
                                    ><span class="detailsIconText"
                                      >{{order.DeliveryInfo}}</span
                                    >
                                    <br />
                                  </ng-container>
                                </ion-col>
                                <!--Phone onsite-->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon
                                      name="call-outline"
                                      class="vertical-text-bottom mr-10"
                                      color="primary"
                                    ></ion-icon>
                                    <ion-text class="upper bold"
                                      ><span
                                        >{{ 'OrdreDetails.onSite' | translate
                                        }}</span
                                      ></ion-text
                                    >
                                  </p>
                                  <span
                                    class="detailsIconText"
                                    appPhoneRegex
                                    [inputText]="order.PhoneOnSite"
                                  ></span>
                                </ion-col>
                                <!--Phone manager-->
                                <ion-col size-xs="12" size-sm="4">
                                  <p>
                                    <ion-icon
                                      name="call-outline"
                                      class="vertical-text-bottom mr-10"
                                      color="primary"
                                    ></ion-icon>
                                    <ion-text class="upper bold"
                                      ><span
                                        >{{ 'OrdreDetails.manager' | translate
                                        }}</span
                                      ></ion-text
                                    >
                                  </p>
                                  <span
                                    class="detailsIconText"
                                    appPhoneRegex
                                    [inputText]="order.PhoneManager"
                                  ></span>
                                </ion-col>
                              </ion-row>
                            </ion-item>

                            <ion-item
                              *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite"
                            >
                              <ion-row class="w-100 margin-top-10">
                                <!-- INTERNAL INFO-->
                                <ion-col size-xs="12" size-sm="4">
                                  <ion-row *ngIf="order.InternalInfo != ''">
                                    <ion-col size-xs="12">
                                      <p>
                                        <ion-icon
                                          name="chatbox-ellipses-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.internInfo' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span
                                        class="detailsIconText"
                                        appPhoneRegex
                                        [inputText]="order.InternalInfo"
                                      ></span>
                                    </ion-col>
                                  </ion-row>
                                </ion-col>

                                <!-- EXTERNAL INFO -->
                                <ion-col size-xs="12" size-sm="4">
                                  <ion-row *ngIf="order.ExternalInfo != ''">
                                    <ion-col size-xs="12">
                                      <p>
                                        <ion-icon
                                          name="chatbox-ellipses-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.externInfo' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span class="detailsIconText"
                                        >{{order.ExternalInfo}}</span
                                      >
                                    </ion-col>
                                  </ion-row>
                                </ion-col>

                                <ion-col size-xs="12" size-sm="4"> </ion-col>
                              </ion-row>
                            </ion-item>

                            <!-- PRODUCT LIST -->
                            <div
                              class="text-small ion-margin-bottom ion-margin-start margin-top-10"
                              *ngIf="order.Orderlines.length > 0"
                            >
                              <ion-row class="orderlistExpandProductHeading">
                                <ion-col size-xs="12" size-sm="5"
                                  ><b
                                    >{{ 'OrdreDetails.produkt' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.levert' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.cost' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.pris' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.rab' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="2"
                                  ><b
                                    >{{ 'OrdreDetails.total' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                                <ion-col size-xs="1"
                                  ><b
                                    >{{ 'OrdreDetails.DB' | translate |
                                    uppercase }}</b
                                  ></ion-col
                                >
                              </ion-row>
                              <ion-row
                                *ngFor="let orderLine of order.Orderlines"
                              >
                                <ion-col size-xs="12" size-sm="5">
                                  <div class="flex-parent">
                                    <ion-badge
                                      [style.background]="orderLine.ProductColorCode"
                                      >{{orderLine.ProductShortCode}}</ion-badge
                                    >&nbsp;
                                    <span class="truncate-text"
                                      >{{orderLine.Description}}</span
                                    >
                                  </div>
                                </ion-col>
                                <ion-col size-xs="1">
                                  <span class="slight-big-font"
                                    ><strong
                                      >{{orderLine.QuantityDelivered |
                                      number:'1.0-1'}}</strong
                                    ></span
                                  >
                                </ion-col>
                                <ion-col size-xs="1"
                                  >{{ orderLine.UnitCost |
                                  number:'1.1-3'}}</ion-col
                                >
                                <ion-col size-xs="1"
                                  >{{ orderLine.UnitPrice |
                                  number:'1.1-3'}}</ion-col
                                >
                                <ion-col size-xs="1"
                                  >{{ orderLine.UnitDiscount |
                                  number:'1.0-1'}}</ion-col
                                >
                                <ion-col size-xs="2"
                                  >{{ orderLine.OrderLineTotal |
                                  number:'1.0-0'}}</ion-col
                                >
                                <ion-col size-xs="1"
                                  >{{
                                  orderLine.OrderLineTotal-(orderLine.UnitCost *
                                  orderLine.QuantityDelivered)|
                                  number:'1.0-0'}}</ion-col
                                >
                              </ion-row>
                            </div>
                          </ion-list>
                        </ion-col>
                        <!-- END -->
                      </ion-row>
                      <!-- END UNASSIGNED-->

                      <!--ST1 ORDERS-->
                      <ion-row [hidden]="showUnassigned('st1')">
                        <ion-col size="8">
                          <h4>
                            {{ 'OrderPlanner.st1' | translate }}
                            <span class="margin-lef"></span
                            ><ion-icon
                              name="clipboard-outline"
                              color="primary"
                            ></ion-icon
                            ><span class="color-primary"
                              ><span class="total-info"
                                >{{ numberOfCustomers() }}</span
                              ></span
                            >
                          </h4>
                        </ion-col>
                        <ion-col size="4" class="pull-right">
                          <ion-button
                            fill="outline"
                            class="no-padding pull-right"
                            (click)="visKunDagensChanged(null)"
                            [hidden]="true"
                          >
                            <ion-icon
                              name="refresh-outline"
                              color="primary"
                            ></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="12">
                          <ion-row
                            class="w-100 padding-8"
                            *ngFor="let cust of customerList; let isOdd = odd"
                            [class.odd]="isOdd"
                            [class.even]="!isOdd"
                            [hidden]="showUnassigned('st1')"
                          >
                            <ion-col size="3">
                              <h2>
                                <span class="total-info2">
                                  {{cust.Name}}<br />
                                  <span style="font-weight: normal !important">
                                    {{cust.Address}} {{cust.PostalCode}}
                                    {{cust.City}}</span
                                  ></span
                                >
                              </h2>
                            </ion-col>
                            <!--PRODUCTS-->
                            <ion-col size="8">
                              <ion-row class="product-item">
                                <ion-col
                                  *ngFor="let product of cust.st1Products"
                                >
                                  <div *ngFor="let tank of st1ProductTanks">
                                    <span>{{product.Description}}</span><br />
                                    <ion-badge
                                      [style.background]="product.ColorCode"
                                      class="badge-margin"
                                      >{{product.ShortCode + ' T.' +
                                      tank}}</ion-badge
                                    >
                                    <input
                                      class="bold ion-text-right"
                                      type="number"
                                      placeholder="liter"
                                      [(ngModel)]="product.DefaultQty"
                                      appRemoveZeroOnFocus
                                    />
                                  </div>
                                </ion-col>
                              </ion-row>
                            </ion-col>
                            <ion-col size="1" class="ion-text-right">
                              <ion-button
                                (click)="createNewOrder(cust)"
                                [style.color]="'primary'"
                                fill="outline"
                              >
                                <ion-icon name="add-circle"></ion-icon>
                              </ion-button>
                            </ion-col>
                          </ion-row>
                        </ion-col>
                      </ion-row>
                      <ion-row [hidden]="showUnassigned('soolo')">
                        <ion-col>
                          <h4>{{ 'OrderPlanner.soolo' | translate }}</h4>
                          <page-tank-monitoring2
                            #soolo
                            [datahost]="false"
                            [dataIsLoaded]="true"
                            [selectedZone]="currentZone"
                            [currentDate]="currentDate"
                            [company]="company"
                            (addTankOrder)="addTankOrder($event)"
                          ></page-tank-monitoring2>
                        </ion-col>
                      </ion-row>

                      <!--AUTOMATER-->
                      <ion-row [hidden]="showUnassigned('automat')">
                        <!--  <ion-col size="2">
                          <h4>{{ 'OrderPlanner.automat' | translate }}</h4>
                        </ion-col>
                        <ion-col> </ion-col>-->
                      </ion-row>
                      <ion-row [hidden]="showUnassigned('automat')">
                        <ion-col>
                          <page-tank-monitoring2
                            #automat
                            [datahost]="true"
                            [dataIsLoaded]="true"
                            [currentDate]="currentDate"
                            [company]="company"
                            (addTankOrder)="addTankOrder($event)"
                          ></page-tank-monitoring2>
                        </ion-col>
                      </ion-row>
                    </ion-col>

                    <!--Assigned orders-->
                    <ion-col col-xs="12" class="ion-text-left lightBg">
                      <div class="page-title m-b-15">
                        <ng-container>
                          <ion-icon
                            name="calendar-outline"
                            ion-text
                            color="primary"
                            class="vertical-middle m-r-10"
                            id="datepicker"
                            (click)="openModal = true"
                          ></ion-icon>

                          <ion-modal
                            [cssClass]="'center'"
                            id="dtOrder"
                            [isOpen]="openModal"
                            (didDismiss)="closeModal()"
                          >
                            <ng-template>
                              <ion-datetime
                                #picker
                                [(ngModel)]="dateSelector"
                                (ionChange)="dateChangeEvent($event)"
                                presentation="date"
                                first-day-of-week="1"
                                size="cover"
                                [showDefaultButtons]="true"
                                doneText="{{ 'buttons.ok' | translate }}"
                                cancelText="{{ 'buttons.cancel' | translate }}"
                                class=""
                              >
                              </ion-datetime>
                            </ng-template>
                          </ion-modal>
                          <span (click)="openModal = true"
                            >{{'Day.' + currentDate.getDay() | translate}}</span
                          >
                          <span class="secondary">
                            {{currentDate | date:dateFormat}}
                            <ion-button
                              (click)="previousDay()"
                              class="no-padding button-no-text vertical-top ion-no-margin"
                              fill="clear"
                            >
                              <ion-icon
                                color="lightFour"
                                slot="icon-only"
                                name="caret-back-circle"
                              >
                              </ion-icon>
                            </ion-button>
                            <ion-button
                              (click)="nextDay()"
                              class="no-padding button-no-text vertical-top ion-no-margin"
                              fill="clear"
                            >
                              <ion-icon
                                color="lightFour"
                                slot="icon-only"
                                name="caret-forward-circle"
                              >
                              </ion-icon>
                            </ion-button>
                          </span>
                        </ng-container>
                      </div>
                      <ion-row class="margin-top-10">
                        <h4>
                          {{ 'OrderPlanner.Assigned' | translate}} &nbsp;<span
                            class="color-primary total-info"
                            >{{currentTruck?.Description}} &gt;
                            {{currentTrailer?.Description}}
                          </span>
                          <span class="margin-left"></span
                          ><ion-icon
                            name="rocket-outline"
                            color="primary"
                          ></ion-icon
                          >{{' Tur nr.: '}}
                          <span class="color-primary"
                            ><span class="total-info"
                              >{{currentRoute}}</span
                            ></span
                          >
                          <span class="margin-left"></span
                          ><ion-icon
                            name="cube-outline"
                            color="primary"
                          ></ion-icon>
                          {{' Kapasitet: '}}
                          <span class="color-primary"
                            ><span class="total-info"
                              >{{selectedTruckTrailerVolume |
                              number:'1.0-0'}}</span
                            ></span
                          >
                          <span class="margin-left"></span
                          ><ion-icon
                            name="clipboard-outline"
                            color="primary"
                          ></ion-icon>
                          {{' Tildelt: '}}<span class="color-primary"
                            ><span class="total-info"
                              >{{assignedTruckTrailerVolume |
                              number:'1.0-0'}}</span
                            ></span
                          >
                          <span class="margin-left"></span
                          ><ion-icon
                            name="calculator-outline"
                            color="primary"
                          ></ion-icon
                          >{{' Tilgjengelig: '}}<span class="color-primary"
                            ><span class="total-info"
                              >{{(selectedTruckTrailerVolume-assignedTruckTrailerVolume)
                              | number:'1.0-0'}}</span
                            ></span
                          >
                        </h4>
                      </ion-row>
                      <ion-row>
                        <ion-radio-group
                          value="RouteNo"
                          (ionChange)="selectRouteNo($event)"
                        >
                          <ion-row>
                            <ion-col *ngFor="let i of RouteNos">
                              <ion-item>
                                <ion-radio [value]="i"></ion-radio>
                                <ion-label>&nbsp;Tur nr. {{i}}</ion-label>
                              </ion-item>
                            </ion-col>
                          </ion-row>
                        </ion-radio-group>
                      </ion-row>
                      <ion-row>
                        <ion-item class="w-100">
                          <ion-textarea
                            placeholder="Tur notater"
                            [autoGrow]="true"
                            value=""
                            [(ngModel)]="currentNote"
                            (ionChange)="routeDBCheck(0,false)"
                          >
                          </ion-textarea>
                        </ion-item>
                      </ion-row>
                      <ion-row class="grid-header m-b-10"></ion-row>
                      <ion-row
                        class="w-100"
                        *ngFor="let order of assignedOrders; let isOdd = odd"
                        [class.odd]="isOdd"
                        [class.even]="!isOdd"
                      >
                        <ion-col>
                          <ion-row (click)="expandOrder(order)">
                            <ion-col size="2" class="handle">
                              <ion-text
                                [color]="isOrderMine(order) == true ? 'success' : ''"
                                (click)="expandOrder(order)"
                              >
                                <h2>{{order.OrderNo}}</h2></ion-text
                              >&nbsp;
                              <div
                                class="circle"
                                [class.red-circle]="order.IsUrgent"
                                [class.grey-circle]="!order.IsUrgent"
                              ></div>
                              &nbsp;
                              <span
                                [innerText]="getDateText(order) | async"
                              ></span>
                            </ion-col>
                            <ion-col size="4">
                              <strong>{{order.DeliveryName}}</strong>
                              <span
                                *ngIf="order.DeliveryName != order.CustomerName"
                                ><br />{{order.CustomerName}}</span
                              >
                              <br />
                              {{order.DeliveryAddress1}},&nbsp;{{order.DeliveryAddress2}}
                              {{order.DeliveryPostalCode}}
                              {{order.DeliveryCity}}
                            </ion-col>
                            <ion-col size="2">
                              <div
                                class="small-font internal"
                                *ngIf="order.DeliveryInfo"
                              >
                                <ion-text color="danger"
                                  >{{order.DeliveryInfo}}</ion-text
                                >
                              </div>
                            </ion-col>
                            <ion-col size="1" class="ion-text-right">
                              <ion-item>
                                <ion-label
                                  >{{ 'OrderPlanner.ETA' | translate
                                  }}</ion-label
                                >
                                <ion-input
                                  label
                                  [(ngModel)]="order.Information6"
                                  class="bold"
                                  placeholder="hh.mm"
                                  (ionBlur)="UpdateOrderETA(order)"
                                ></ion-input>
                              </ion-item>
                            </ion-col>
                            <ion-col size="3" class="ion-text-right">
                              <ion-button
                                (click)="assignOrder(order)"
                                *ngIf="order.AssignedTruck == 0"
                                fill="solid"
                                [disabled]="!currentTruck"
                                [style.color]="'primary'"
                                class="plukk-apne"
                              >
                                <ion-icon name="person-outline"></ion-icon>
                                &nbsp; {{ 'OrderPlanner.tildel' | translate }}
                              </ion-button>

                              <ion-button
                                (click)="unassignOrder(order)"
                                *ngIf="order.AssignedTruck != 0"
                                [style.color]="'primary'"
                                fill="solid"
                              >
                                <ion-icon
                                  name="close-circle-outline"
                                ></ion-icon>
                                &nbsp; {{ 'OrderPlanner.slippOrdre' | translate
                                }}
                              </ion-button>

                              <ion-button
                                (click)="splitOrder(order)"
                                *ngIf="order.AssignedTruck != 0"
                                [style.color]="'primary'"
                                fill="outline"
                              >
                                <ion-icon
                                  name="close-circle-outline"
                                ></ion-icon>
                                &nbsp; {{ 'OrderPlanner.SplitOrder' | translate
                                }}
                              </ion-button>
                            </ion-col>
                          </ion-row>
                          <ion-row [hidden]="order.expandedView">
                            <ion-col size="12">
                              <ion-row
                                *ngFor="let orderLine of order.Orderlines"
                                class="m-r-5 orderline lineDivider"
                              >
                                <ion-col size="2" class="orderLineItem">
                                  <ion-badge
                                    [style.background]="orderLine.ProductColorCode"
                                    >{{orderLine.ProductShortCode}}</ion-badge
                                  >&nbsp;<strong>{{orderLine.Quantity}}</strong>
                                  &nbsp;|&nbsp;<strong
                                    >{{totalAssigned(order,
                                    orderLine.LineNo)}}</strong
                                  >&nbsp;<ion-icon
                                    name="checkmark-circle"
                                    color="success"
                                    *ngIf="(orderLine.Quantity-totalAssigned(order, orderLine.LineNo) ==0)"
                                  ></ion-icon>
                                </ion-col>
                                <ion-col size="10">
                                  <ion-row
                                    class="rowRoom"
                                    [hidden]="truckSelected()"
                                  >
                                    <div>
                                      <img
                                        _ngcontent-qxv-c204=""
                                        src="assets/icon/Icon_Truck.svg"
                                        class="roomIcon"
                                      />
                                    </div>
                                    <ion-item
                                      *ngFor="let room of gettrucktrailerroom(true,order, orderLine.LineNo)"
                                      class="chkRoom"
                                    >
                                      <ion-checkbox
                                        slot="start"
                                        value="{{room.Id}}"
                                        class="chkInput"
                                        name="{{room.Id}}"
                                        (ionChange)="fillRoom($event, true, order, orderLine.LineNo)"
                                        [(ngModel)]="room.IsChecked"
                                        [disabled]="room.IsDisabled"
                                      ></ion-checkbox>
                                      <ion-label
                                        ><strong>{{room.Name}}</strong> {{
                                        getRoomVolume(room.Id,true) }}
                                        <strong>|</strong>
                                        {{getRoomVolume(room.Id,true)-(getAssignedRoomVolume(room.Id,true))
                                        | number:'1.1-1'}}
                                      </ion-label>
                                    </ion-item>
                                  </ion-row>

                                  <ion-row
                                    class="rowRoom"
                                    [hidden]="!trailerSelected()"
                                  >
                                    <div>
                                      <img
                                        _ngcontent-qxv-c204=""
                                        src="assets/icon/Icon_Trailer.svg"
                                        class="roomIcon"
                                      />
                                    </div>
                                    <ion-item
                                      *ngFor="let room of gettrucktrailerroom(false, order, orderLine.LineNo)"
                                      class="chkRoom"
                                    >
                                      <ion-checkbox
                                        slot="start"
                                        value="{{room.Id}}"
                                        class="chkInput"
                                        name="{{room.Id}}"
                                        (ionChange)="fillRoom($event, false, order, orderLine.LineNo)"
                                        [(ngModel)]="room.IsChecked"
                                        [disabled]="room.IsDisabled"
                                      ></ion-checkbox>
                                      <ion-label
                                        ><strong>{{room.Name}}</strong>
                                        {{getRoomVolume(room.Id,false) }}
                                        <strong>|</strong>
                                        {{getRoomVolume(room.Id,false)-(getAssignedRoomVolume(room.Id,false))
                                        | number:'1.1-1'}}
                                      </ion-label>
                                    </ion-item>
                                  </ion-row>
                                </ion-col>
                              </ion-row>
                            </ion-col>

                            <!-- EXPANDED VIEW -->
                            <ion-col
                              size-sm="12"
                              *ngIf="1>2"
                              [class.expandedOrder]="!isOdd"
                              class="orderlistExpanded"
                            >
                              <ion-list lines="none">
                                <ion-item>
                                  <ion-row class="w-100">
                                    <!-- EXPANDED VIEW - DATES -->
                                    <ion-col size-xs="12" size-sm="4">
                                      <p>
                                        <ion-icon
                                          name="calendar-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold">
                                          <span
                                            >{{ 'OrdreDetails.leveringsdato' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span class="detailsIconText"
                                        >{{order.DeliveryDate |
                                        date:dateFormat}}

                                        <span
                                          *ngIf="order.ConfirmedDeliveryDate != order.DeliveryDate && order.ConfirmedDeliveryDate != '0001-01-01T00:00:00'"
                                        >
                                          /
                                          <ion-icon
                                            name="alert-circle-outline"
                                            class="circle vertical-text-bottom"
                                            color="danger"
                                          ></ion-icon>
                                          {{order.ConfirmedDeliveryDate |
                                          date:dateFormat}}</span
                                        >
                                      </span>
                                    </ion-col>

                                    <!-- EXPANDED VIEW - CUSTOMER / BUYER -->
                                    <ion-col size-xs="12" size-sm="4">
                                      <p>
                                        <ion-icon
                                          name="people-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.CustomerBuyer' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span class="detailsIconText"
                                        >{{order.CustomerBuyer}}</span
                                      >
                                    </ion-col>

                                    <!-- EXPANDED VIEW - OUR SELLER -->
                                    <ion-col size-xs="12" size-sm="4">
                                      <p>
                                        <ion-icon
                                          name="person-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.OurSeller' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span class="detailsIconText"
                                        >{{order.Seller}}</span
                                      >
                                    </ion-col>
                                  </ion-row>
                                </ion-item>

                                <ion-item>
                                  <ion-row class="w-100 margin-top-10">
                                    <!-- EXPANDED VIEW - DELIVERY DETAIL -->
                                    <ion-col size-xs="12" size-sm="4">
                                      <p>
                                        <ion-icon
                                          name="cube-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.leveringsinfo' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <ng-container *ngIf="order.TankInfo"
                                        ><span class="detailsIconText"
                                          >{{order.TankInfo}}</span
                                        >
                                        <br />
                                      </ng-container>
                                      <ng-container *ngIf="order.DeliveryInfo"
                                        ><span class="detailsIconText"
                                          >{{order.DeliveryInfo}}</span
                                        >
                                        <br />
                                      </ng-container>
                                    </ion-col>
                                    <!--Phone onsite-->
                                    <ion-col size-xs="12" size-sm="4">
                                      <p>
                                        <ion-icon
                                          name="call-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.onSite' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span
                                        class="detailsIconText"
                                        appPhoneRegex
                                        [inputText]="order.PhoneOnSite"
                                      ></span>
                                    </ion-col>
                                    <!--Phone manager-->
                                    <ion-col size-xs="12" size-sm="4">
                                      <p>
                                        <ion-icon
                                          name="call-outline"
                                          class="vertical-text-bottom mr-10"
                                          color="primary"
                                        ></ion-icon>
                                        <ion-text class="upper bold"
                                          ><span
                                            >{{ 'OrdreDetails.manager' |
                                            translate }}</span
                                          ></ion-text
                                        >
                                      </p>
                                      <span
                                        class="detailsIconText"
                                        appPhoneRegex
                                        [inputText]="order.PhoneManager"
                                      ></span>
                                    </ion-col>
                                  </ion-row>
                                </ion-item>

                                <ion-item
                                  *ngIf="order.InternalInfo || order.TankInfo || order.DeliveryInfo || order.PhoneManager || order.PhoneOnSite"
                                >
                                  <ion-row class="w-100 margin-top-10">
                                    <!-- INTERNAL INFO-->
                                    <ion-col size-xs="12" size-sm="4">
                                      <ion-row *ngIf="order.InternalInfo != ''">
                                        <ion-col size-xs="12">
                                          <p>
                                            <ion-icon
                                              name="chatbox-ellipses-outline"
                                              class="vertical-text-bottom mr-10"
                                              color="primary"
                                            ></ion-icon>
                                            <ion-text class="upper bold"
                                              ><span
                                                >{{ 'OrdreDetails.internInfo' |
                                                translate }}</span
                                              ></ion-text
                                            >
                                          </p>
                                          <span
                                            class="detailsIconText"
                                            appPhoneRegex
                                            [inputText]="order.InternalInfo"
                                          ></span>
                                        </ion-col>
                                      </ion-row>
                                    </ion-col>

                                    <!-- EXTERNAL INFO -->
                                    <ion-col size-xs="12" size-sm="4">
                                      <ion-row *ngIf="order.ExternalInfo != ''">
                                        <ion-col size-xs="12">
                                          <p>
                                            <ion-icon
                                              name="chatbox-ellipses-outline"
                                              class="vertical-text-bottom mr-10"
                                              color="primary"
                                            ></ion-icon>
                                            <ion-text class="upper bold"
                                              ><span
                                                >{{ 'OrdreDetails.externInfo' |
                                                translate }}</span
                                              ></ion-text
                                            >
                                          </p>
                                          <span class="detailsIconText"
                                            >{{order.ExternalInfo}}</span
                                          >
                                        </ion-col>
                                      </ion-row>
                                    </ion-col>

                                    <ion-col size-xs="12" size-sm="4">
                                    </ion-col>
                                  </ion-row>
                                </ion-item>

                                <!-- PRODUCT LIST -->
                                <div
                                  class="text-small ion-margin-bottom ion-margin-start margin-top-10"
                                  *ngIf="order.Orderlines.length > 0"
                                >
                                  <ion-row
                                    class="orderlistExpandProductHeading"
                                  >
                                    <ion-col size-xs="12" size-sm="5"
                                      ><b
                                        >{{ 'OrdreDetails.produkt' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.levert' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.cost' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.pris' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.rab' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="2"
                                      ><b
                                        >{{ 'OrdreDetails.total' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                    <ion-col size-xs="1"
                                      ><b
                                        >{{ 'OrdreDetails.DB' | translate |
                                        uppercase }}</b
                                      ></ion-col
                                    >
                                  </ion-row>
                                  <ion-row
                                    *ngFor="let orderLine of order.Orderlines"
                                  >
                                    <ion-col size-xs="12" size-sm="5">
                                      <div class="flex-parent">
                                        <ion-badge
                                          [style.background]="orderLine.ProductColorCode"
                                          >{{orderLine.ProductShortCode}}</ion-badge
                                        >&nbsp;
                                        <span class="truncate-text"
                                          >{{orderLine.Description}}</span
                                        >
                                      </div>
                                    </ion-col>
                                    <ion-col size-xs="1">
                                      <span class="slight-big-font"
                                        ><strong
                                          >{{orderLine.QuantityDelivered |
                                          number:'1.0-1'}}</strong
                                        ></span
                                      >
                                    </ion-col>
                                    <ion-col size-xs="1"
                                      >{{ orderLine.UnitCost |
                                      number:'1.1-3'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{ orderLine.UnitPrice |
                                      number:'1.1-3'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{ orderLine.UnitDiscount |
                                      number:'1.0-1'}}</ion-col
                                    >
                                    <ion-col size-xs="2"
                                      >{{ orderLine.OrderLineTotal |
                                      number:'1.0-0'}}</ion-col
                                    >
                                    <ion-col size-xs="1"
                                      >{{
                                      orderLine.OrderLineTotal-(orderLine.UnitCost
                                      * orderLine.QuantityDelivered)|
                                      number:'1.0-0'}}</ion-col
                                    >
                                  </ion-row>
                                </div>
                              </ion-list>
                            </ion-col>
                            <!-- END -->
                          </ion-row>
                        </ion-col>
                      </ion-row> </ion-col
                    ><!-- END ASSIGNED -->
                  </ion-row>
                </ion-grid>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
  </div>
</ion-content>
<ion-footer class="ion-padding">
  <div class="hidden-sm-up primary litte-big-font footer-border">
    <ion-button fill="outline" (click)="expandFooterMenu()">
      <ion-icon
        name="menu-outline"
        class="ion-no-padding"
        color="primary"
      ></ion-icon>
    </ion-button>
  </div>
  <div [hidden]="!expand">
    <div class="m-b-15">
      <ion-row class="grid-header">
        <ion-col size="6">
          <ng-container *ngFor="let item of inventoryList">
            <span
              *ngIf="item.QuantityInOrderList != 0 && tabUnassinged == 'bulk'"
              class="m-r-15 inventoryItem"
            >
              <ion-badge
                class="badge m-r-5"
                [style.background]="item.ProductColorCode"
                title="{{item.ProductNo + ' - ' + item.ProductDescription}}"
                >{{item.ProductShortCode}}</ion-badge
              >
              <strong> {{item?.QuantityInOrderList | number:'1.0-0'}}</strong>
            </span>
          </ng-container>
          &nbsp;
        </ion-col>

        <ion-col size="6">
          <ng-container *ngFor="let item of inventoryList">
            <span
              *ngIf="item.QuantityInOrder != 0 || item.Quantity != 0"
              class="m-r-15 inventoryItem"
            >
              <ion-badge
                class="badge m-r-5"
                [style.background]="item.ProductColorCode"
                title="{{item.ProductNo + ' - ' + item.ProductDescription}}"
                >{{item.ProductShortCode}}</ion-badge
              >
              {{item.Quantity | number:'1.0-0' }} &nbsp; | &nbsp;
              <span>
                <strong> {{item.QuantityInOrder | number:'1.0-0'}}</strong>
              </span>
            </span>
          </ng-container>
        </ion-col>
      </ion-row>
    </div>
    <div>
      <!--{{ 'Ordrer.mine' | translate }} <ion-toggle class="inline-block" color="success" [(ngModel)]="mine" (ionChange)="filterChanged($event)"></ion-toggle> &nbsp;&nbsp;-->
      <span *ngIf="tabUnassinged == 'bulk'">
        {{ 'Ordrer.utildelteOrdrer' | translate }}
        <ion-toggle
          class="inline-block"
          color="success"
          [(ngModel)]="utildelteOrdrer"
          [disabled]="plannerOrderView == PlannerOrderView.showTruckOrder"
          (ionChange)="filterChanged($event)"
        ></ion-toggle>
        &nbsp;&nbsp;
        <!--{{ 'Ordrer.nerMeg' | translate }} <ion-toggle class="inline-block" color="success" [disabled]="latitude == null || longitude == null" [(ngModel)]="nearMeg" (ionChange)="filterChanged($event)"></ion-toggle> &nbsp;&nbsp;
        <input type="text" autocomplete="off" class="nearmeg-input" [(ngModel)]="nearMegDistance" (ngModelChange)="filterChanged($event)" [disabled]="latitude == null || longitude == null || !nearMeg" placeholder="{{ 'Ordrer.near' | translate }}" />&nbsp;&nbsp;-->
        {{ 'Ordrer.avgiftsfrie' | translate }}
        <ion-toggle
          class="inline-block"
          color="success"
          [(ngModel)]="avgiftsfrie"
          (ionChange)="filterChanged($event)"
        >
        </ion-toggle>
        &nbsp;&nbsp; {{ 'Ordrer.visKunDagens' | translate }}
        <ion-toggle
          class="inline-block"
          color="success"
          [(ngModel)]="visKunDagens"
          (click)="visKunDagensChanged($event)"
        ></ion-toggle>
        &nbsp;&nbsp;</span
      >
      <div class="pull-right-md">
        <span class="display-inline-block">
          <strong class="text-small">{{ 'Ordrer.company' | translate }}</strong
          >&nbsp;&nbsp;
          <select
            title="Selskap"
            [(ngModel)]="company"
            (ngModelChange)="visKunDagensChanged(null)"
            class="margin-boottom-4"
          >
            <option value="0">{{ 'General.select' | translate }}</option>
            <option
              *ngFor="let company of companies"
              value="{{company.CompanyNo}}"
            >
              {{company.Name}}
            </option>
          </select>
        </span>
      </div>
      <div class="clear"></div>
    </div>
    <div class="footer-border hidden-xs"></div>
    <div class="primary litte-big-font">
      <ion-button
        fill="outline"
        class="no-padding"
        *ngIf="!showOverview"
        (click)="toggleOverview()"
      >
        <ion-icon name="clipboard-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'OrderPlanner.visSjaforensOrdrer' | translate }}</span
        >
      </ion-button>
      <ion-button
        fill="outline"
        class="no-padding"
        *ngIf="showOverview"
        (click)="toggleOverview()"
      >
        <ion-icon name="clipboard-outline" color="primary"></ion-icon>&nbsp;
        <span class="black"
          >{{ 'OrderPlanner.visOrdreliste' | translate }}</span
        >
      </ion-button>
      <ion-button fill="outline" class="no-padding" routerLink="/new-customer">
        <ion-icon
          name="person-add"
          class="ion-no-padding"
          color="primary"
        ></ion-icon
        >&nbsp;
        <span class="black">{{ 'Ordrer.customers' | translate }}</span>
      </ion-button>

      <ion-button
        fill="outline"
        class="no-padding"
        (click)="navigateToCreateOrder()"
      >
        <ion-icon name="add-circle-outline" color="primary"></ion-icon>&nbsp;
        <span class="black">{{ 'Ordrer.nyOrdre' | translate }}</span>
      </ion-button>

      <div class="pull-right hidden-xs">
        <ion-button fill="outline" class="no-padding" routerLink="/orders">
          <ion-icon
            name="book-outline"
            class="ion-no-padding"
            color="primary"
          ></ion-icon
          >&nbsp; {{ 'OrderPlanner.avslutttildeling' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</ion-footer>
