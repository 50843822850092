<ion-content class="ion-padding" style="background-color:#43423d;">
    <div class="scroll-content">
        <ion-grid class="grid">
            <ion-row>
                <ion-col col-md size-md="4" offset-md="4">
                    <ion-list lines="none" class="login-content">
                        <form [formGroup]="loginForm">

                            <ion-item class="ion-margin-bottom ion-padding-start ion-text-center logo">
                                
                                <img alt="logo" src="../../../assets/icon/KnapphusConnectLogo.svg" class="login-img"/>
                            </ion-item>

                            <ion-item class="ion-icon login-control-company">
                                <ion-icon name="business-outline" color="black" class="company-icon"></ion-icon>
                            <ion-select formControlName="clientID" class="ion-margin-start" placeholder="{{ 'Login.selectCompany' | translate }}" width="350px">
                                <ion-select-option value="">{{ 'Login.selectCompany' | translate }}</ion-select-option>
                                <ion-select-option *ngFor="let company of companies" value="{{company.ClientID}}">{{company.ClientName}}</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item class="item-validation" *ngIf="!loginForm.controls.clientID.valid && (loginForm.controls.clientID.dirty || submitAttempt)">
                            <ion-text class="ion-margin-start" color="secondary">
                                <p ion-text color="secondary" class="ion-margin-start">{{ 'Login.validationCompanyRequired' |
                                    translate }}</p>
                            </ion-text>
                        </ion-item>

                            <ion-item class="ion-icon icon-wrapper login-control input-bg">
                                <ion-icon name="person-outline" slot="start" color="black"></ion-icon>
                                <ion-input type="text" formControlName="username" autocomplete="off" placeholder="{{ 'Login.username' | translate }}"></ion-input>
                            </ion-item>
                            <ion-item class="item-validation" *ngIf="!loginForm.controls.username.valid && (loginForm.controls.username.dirty || submitAttempt)">
                                <ion-text class="ion-margin-start" color="secondary">
                                    <p>{{ 'Login.validationUsernameRequired' | translate }}</p>
                                </ion-text>
                            </ion-item>

                            <ion-item class="ion-icon icon-wrapper login-control input-bg">
                                <ion-icon name="lock-closed-outline" slot="start" color="black"></ion-icon>
                                <ion-input type="password" formControlName="password" autocomplete="off" placeholder="{{ 'Login.password' | translate }}"></ion-input>
                            </ion-item>
                            <ion-item class="item-validation" *ngIf="!loginForm.controls.password.valid && (loginForm.controls.password.dirty || submitAttempt)">
                                <ion-text class="ion-margin-start" color="secondary">
                                    <p>{{ 'Login.validationPasswordRequired' | translate
                                        }}</p>
                                </ion-text>
                            </ion-item>
                            
                            <ion-item>
                                <div>
                                    <ion-button color="secondary" [disabled]="!loginForm.valid || submitting" class="login-button" size="default"
                                        (click)="login()">
                                        <ion-icon name="log-in-outline" slot="start"></ion-icon><strong>{{ 'Login.login' |
                                            translate }}</strong>
                                    </ion-button> <br /><br />
                                    <ion-button color="secondary" (click)="showForgotPasswordDialog()" class="forgot-link" fill="clear">{{
                                        'Login.forgotPassword' | translate }}</ion-button>
                                        <p><span class="version">v.{{version}}</span></p>
                                </div>
                            </ion-item>

                        </form>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>