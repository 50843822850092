import { Component, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NavController, LoadingController, ModalController, ToastController, IonInput, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomerData } from '../../shared/customer-data';
import { BaseHttpClient } from '../../shared/http-client';
import { AddressData } from '../../shared/address-data';
import { CustomValidators } from '../../shared/validators';
import { UserService } from '../../shared/user-service';
import { AppSettings } from '../../shared/app-settings';
import { TranslateService } from '@ngx-translate/core';
import { LeggTilProduktComponent } from '../../components/legg-til-produkt/legg-til-produkt';
import { OrderConfirmComponent } from '../../components/order-confirm/order-confirm';
import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@ionic/storage-angular';
import { Router, NavigationExtras } from '@angular/router';
import { InternalOrdertype } from '../../shared/enums';
import { isNgTemplate } from '@angular/compiler';
import { CustomerNorwegianData } from '../../shared/customer-norwegian-data';
import { Observable, debounceTime, filter, map, of, startWith, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomerSearchComponent } from 'src/app/components/customer-search/customer-search/customer-search.component';
import { CustomerAddressSearchComponent } from 'src/app/components/customer-address-search/customer-address-search/customer-address-search.component';
import _ from 'lodash';
import { LeggTilProduktSalesComponent } from 'src/app/components/legg-til-produkt-sales/legg-til-produkt-sales';
import { OrderConfirmSalesComponent } from 'src/app/components/order-confirm-sales/order-confirm-sales';

@Component({
    selector: 'page-new-order',
    templateUrl: 'new-order.html',
    styleUrls: ['new-order.scss']
})


export class NewOrderPage implements AfterViewInit {
    dateFormat;
    orderLines: any[] = [];
    submitAttempt = false;
    detailsForm: FormGroup;
    private identity = -1;
    // private customer: string;
    // private address: string;
    customerDataService;
    addressDataService: AddressData;
    currentLatitude: number = null;
    currentLongitude: number = null;
    DoNotCalcVolToStandard = false;
    VolumeToStandardTruck: boolean = AppSettings.VolumeToStandardTruck;
    VolumeToStandardOff: boolean = AppSettings.Vol2StdOff;
    DoNotSendGPS = false;
    private showAllProducts = false;
    totalOrder: any = 0;
    products: any[];
    DeliveryDate:Date = new Date();
    openModal = false;
    @ViewChild('picker')
    isHidden: boolean;
    private picker: any;
    private navigationExtras: NavigationExtras;
    transporters;
    priceAgreements;
    private addressID = 0;
    selectedCustomer: any = null;
        allowDescriptionChange;
    UsePlanner = false;
    SelectedRoute = 0;
    CameFromPlanner = 'false';
        userlevel: number = null;


    customerCtrl = new FormControl();
    filteredCustomers: Observable<any[]>;

    addressCtrl = new FormControl();
    filteredAddresses: Observable<any[]>;
  
    
 latLongPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    
    customerModal: boolean = false;

    customerAddressModal: boolean = false;

    DoNotChangePrice = false;

    mapLat: number = 0.0;
    mapLong: number = 0.0;
    

    @ViewChild('customerNameInput', { static: false }) customerNameInput: IonInput;
    @ViewChild('addressInput', { static: false }) addressInput: IonInput;

      isMapVisible = false;  // Initially set to false to hide the map

  toggleMapVisibility() {
    this.isMapVisible = !this.isMapVisible;  // Toggle visibility
  }

ngAfterViewInit() {
        setTimeout(() => {
            if (this.customerNameInput) {
                this.customerNameInput.setFocus();
            }
        }, 1000);

        if (this.customerNameInput) {
            this.customerNameInput.getInputElement().then(input => {
                input.addEventListener('keyup', (event) => {
                    if (event.key === 'Enter') {
                        
                        this.initCustomerSearch();
                    }
                });
            });
        }

        if (this.addressInput) {
            this.addressInput.getInputElement().then(input => {
                input.addEventListener('keyup', (event) => {
                    if (event.key === 'Enter') {
                        
                        this.initCustomerAddressSearch();
                    }
                });
            });
        }
    }
    

    constructor(
        private storage: Storage, public userService: UserService, public navCtrl: NavController,
        private translateService: TranslateService, private toastController: ToastController,private alertCtrl: AlertController,
        private loadingController: LoadingController, private geolocation: Geolocation, private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private http: HttpClient, private router: Router) {

        this.storage.get(AppSettings.StorageKeys.ShowAllProducts).then(data => this.showAllProducts = data);

        this.storage.get(AppSettings.StorageKeys.Userlevel).then(data => this.userlevel = data);

        // used to pass info to orders view for init an update of orderslist
        this.navigationExtras = {
            queryParams: {
                reloadData: true
            },
            skipLocationChange: true
        };

        this.detailsForm = formBuilder.group(
            {
                customerName: ['', Validators.compose([Validators.required])],
                customerAddress1: ['', Validators.compose([Validators.required])],
                customerAddress2: [''],
                customerPostalCode: ['', Validators.compose([Validators.required])],
                priceAgreementID: [''],
                transporterID: [''],

                customerCity: [''],

                address: [''],
                addressAddress1: [''],
                addressAddress2: [''],
                addressZip: [''],
                addressCity: [''],
                gps: ['',[Validators.pattern(this.latLongPattern)]],

                customerBuyer: [''],
                email: ['', [CustomValidators.emailValidator]],
                DeliveryEmail: ['', [CustomValidators.emailValidator]],
                phoneOnSite: [''],
                phoneManager: [''],
                customerReference: [''],
                customerPurchaseOrderNo: [''],
                deliveryInfo: [''],
                internalInfo: [''],
                tankInfo: [''],

                additionalProductsDelivered: [false],
                orderComments: [''],
                orderInternalComments: [''],
                deliveryDate: [new Date().toISOString()],
                orderLines: new FormGroup({}),
                organizationNo: ['']
            });


        this.translateService.get('General.dateFormat').subscribe(value => {
            this.dateFormat = value;
        });

        this.userService.GetPriceAgreements().subscribe(data => { this.priceAgreements = data; });
        this.userService.GetTransporters().subscribe(data => { this.transporters = data; });


        const posOptions = {
            enableHighAccuracy: false,
            timeout: 30000,
            maximumAge: 30000
        };
        const that = this;
       
       
    }

    ngOnInit() {
        this.getCurrentPosition();
        this.watchPosition();



            this.onCustomerNameChanges();
            this.onCustomerAddressChanges();

 this.setupValueChanges();


    }


    displayFn(customer: any): string {
        return customer && customer.name ? customer.name : '';
    }

  private _filterCustomers(value: string): Observable<any[]> {
    return this.customerDataService.search(value);
  }

    private _filterAddresses(value: string): Observable<any[]> {
    return this.addressDataService.search(value);
  }

showhide() {
        this.isHidden = !this.isHidden;
    }

  showCustomerNo()
  {
if(this?.selectedCustomer != null)
        return this.selectedCustomer?.CustomerNo == 0 ? true : false;
else
    return false;
  }


  

  async getCurrentPosition() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    try {
      const resp = await Geolocation.getCurrentPosition(posOptions);
      this.currentLatitude = resp.coords.latitude;
      this.currentLongitude = resp.coords.longitude;
    } catch (error) {
      //this.showGeolocationError();
    }
  }

    async gpsSearch() {
    const posOptions = {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 30000
    };

    await this.getCurrentPosition();
    this.userService.addressGPSSearch(this.selectedCustomer.ErpID, this.currentLatitude, this.currentLongitude).subscribe(data => {
      const addresses = data.map((address: any) => ({
        name: address.Name, // Assuming 'Name' and 'Address' are properties in the data
        address: address.Address // Replace 'Address' with the actual property name if different
      }));

    });
  }

  watchPosition() {
    const watch = Geolocation.watchPosition({}, (position, error) => {
      if (error) {
        //this.showGeolocationError();
        return;
      }

      if (position.coords) {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
      } else {
       // this.showGeolocationError();
      }
    });
  }

    setupValueChanges() {
    Object.keys(this.detailsForm.controls).forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        control.valueChanges.subscribe(() => {
          this.checkInput(controlName);
        });
      }
    });
  }

  checkAllInputs() {
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo'
    ];

   formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        this.checkInput(controlName);
    }
});
  }

  checkInput(controlName: string) {
    const control = this.detailsForm.get(controlName);
    const inputValue = control ? control.value : '';
    const ionInput = document.querySelector(`[formControlName="${controlName}"]`);

    if (ionInput) {
      if (inputValue) {
        ionInput.classList.add('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.add('item-has-value');
        }
      } else {
        ionInput.classList.remove('has-value');
        const ionItem = ionInput.closest('ion-item');
        if (ionItem) {
          ionItem.classList.remove('item-has-value');
        }
      }
    }
  }
  clearAddress() {
    const formControls = [
      'addressAddress1',
      'addressAddress2',
      'addressZip',
      'addressCity',
      'address',
      'DeliveryEmail',
      'gps',
      'internalInfo',
      'customerBuyer',
      'tankInfo',
      'phoneOnSite',
      'phoneManager',
      'deliveryInfo'
    ];

    formControls.forEach(controlName => {
      const control = this.detailsForm.get(controlName);
      if (control) {
        control.setValue('');
      }
    });

    this.addressID = 0;
    this.mapLat = 0;
            this.mapLong = 0;

    this.checkAllInputs(); // Ensure labels are updated after clearing values
  }

     searchCustomerNorwegianRegistry(event) {
        try {
              this.selectedCustomer = null;
        if (event.target.value.length >= 3) {
            if( this.openModal == false){this.findCustomer(event.target.value);}

            this.customerDataService = new CustomerData(this.http, `${AppSettings.API_ENDPOINT}/User/CustomerSearch/?query=`);
            this.customerDataService.subscribe(customers => {
                if (customers.length === 0) {
                    this.customerDataService = new CustomerNorwegianData(this.http, AppSettings.NorwegianRegistryAPI + event.target.value);
                    this.customerDataService.search();
                }
            });
        }
        } catch (error) {
            
        }
      
    }

  onCustomerNameChanges(): void {
   
    this.getFormControl('customerName').valueChanges.pipe(
      debounceTime(1000),  // Wait for 300ms pause in events
      filter(query => query.length >= 3 && !this.customerModal)  // Only emit if the input length is 3 or more
    ).subscribe(query => {
 
      // Trigger the findCustomer method or handle it as needed
             if (!this.customerModal)
         this.findCustomer(query);

         
    });

  }

  onCustomerAddressChanges(): void {
   
    this.getFormControl('address').valueChanges.pipe(
      debounceTime(500),  // Wait for 300ms pause in events
      filter(query => query.length >= 3 && !this.customerAddressModal)  // Only emit if the input length is 3 or more
    ).subscribe(query => {
 
      // Trigger the findCustomer method or handle it as needed
       if (!this.customerAddressModal)
         this.findCustomerAddress(query);

         
    });

  }


    getFormControl(field) {
        return this.detailsForm.get(field);
    }

    openPicker() {
       //     this.picker.open();
    }

    addressSelected($event) {
        if ($event != null) {
                        this.customerAddressModal = true;

            const originalObject = $event;
            this.addressID = originalObject.AddressID;
            this.getFormControl('address').setValue(originalObject.Name == originalObject.Address1 ? this.selectedCustomer.Name : originalObject.Name);
            this.getFormControl('addressAddress1').setValue(originalObject.Address1);
            this.getFormControl('addressAddress2').setValue(originalObject.Address2);
            this.getFormControl('addressZip').setValue(originalObject.PostalCode);
            this.getFormControl('addressCity').setValue(originalObject.City);
//            this.getFormControl('email').setValue(originalObject.Email);
            this.getFormControl('internalInfo').setValue(originalObject.InternalInfo);
            this.getFormControl('customerBuyer').setValue(originalObject.ContactNameOnSite);
            this.getFormControl('tankInfo').setValue(originalObject.TankInfo);
            this.getFormControl('phoneOnSite').setValue(originalObject.PhoneOnSite);
    //        this.getFormControl('phoneManager').setValue(originalObject.ProjectManager);
            this.getFormControl('deliveryInfo').setValue(originalObject.DeliveryInfo);
            this.getFormControl('gps').setValue(originalObject.GPS);
           this.splitGpsCoordinates(originalObject.GPS);
                      //  this.customerAddressModal = false;

        }
    }

    splitGpsCoordinates(coordinates: string) {


      if(coordinates.trim() == "")
      {
        this.mapLat = 0.0;
        this.mapLong = 0.0;
      }
      else
{
      try {
        const [latitudeStr, longitudeStr] = coordinates.split(',');

  if (latitudeStr && longitudeStr) {
    const latitude = parseFloat(latitudeStr.trim());
    const longitude = parseFloat(longitudeStr.trim());

    // Validate that parsed values are numbers
    if (!isNaN(latitude) && !isNaN(longitude)) {
      this.mapLat = latitude;
            this.mapLong = longitude;
  }else
{this.mapLat = 0.0;
  this.mapLong = 0.0;
}
  }
      } catch (error) {
        this.mapLat = 0;
    this.mapLong = 0;
      }
  
    }
    }

    

onGpsInputChange(event: any) {
    const value = event.detail.value; // The new value of the input field
    console.log('GPS Input Changed:', value);

    // Additional processing, such as validation or updating other component properties
    this.splitGpsCoordinates(value);
  }


    customerSelected(customer) {
        {
            try {
                
                       this.customerModal = true;

            if (customer.CustomerBlockedStatus > 0) {
                this.getFormControl('customerName').setErrors({ incorrect: true });
                this.customerModal = false;
            } else {
                if(customer != this.selectedCustomer)
                    {
                        this.clearAddress();
                    }
                this.selectedCustomer = customer;

                //this.addressDataService.addressList = this.selectedCustomer.CustomerAddresses;
                //this.addressDataService.setErpIDParent(customer.ErpID);
                
                this.getFormControl('customerName').setValue(customer.Name);
                this.getFormControl('customerAddress1').setValue(customer.Address.trim() == '' ? customer.Address2 : customer.Address);
                this.getFormControl('customerAddress2').setValue('');
                this.getFormControl('customerPostalCode').setValue(customer.PostalCode);
                this.getFormControl('customerCity').setValue(customer.City);
                this.getFormControl('organizationNo').setValue(customer.OrgNo);
                this.getFormControl('email').setValue(customer.Email);
                this.getFormControl('customerReference').setValue(customer.CustomerReference);

                if(this.selectedCustomer?.AddressList?.length > 0)
                    {
                        this.initCustomerAddressSearch();
                    }
                                    //   this.customerModal = false;

            }
             } catch (error) {
                console.error(error);
            }
          
        }
    }

    updateCommentValidators() {
        const commentControl = this.detailsForm.get('orderComments');
        const additionalProductsDeliveredControl = this.detailsForm.get('additionalProductsDelivered');

        if (additionalProductsDeliveredControl.value) {
            commentControl.setValidators([Validators.required]);
        } else {
            commentControl.setValidators([]);
        }
        commentControl.updateValueAndValidity();
    }

    additionalProductsDeliveredChanged() {
        this.updateCommentValidators();
    }

        closeModal(){
    this.openModal = false;
}


    async leggTilProdukt1() {
        const modal = await this.modalCtrl.create({
            component: LeggTilProduktComponent,
            cssClass: 'legg-til-produkt', componentProps: { showAllProducts: false }// this.showAllProducts }
        });
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                const orderline: any = {
                    ProductColorCode: data.data.Product.ColorCode,
                    ProductShortCode: data.data.Product.ShortCode,
                    Description: data.data.Product.Description,
                    LineNo: this.identity,
                    ProductNo: data.data.Product.ProductNo,
                    Quantity: data.data.Quantity,
                    QuantityDelivered: data.data.Quantity
                };

                this.orderLines.push(orderline);

                const control: FormControl = new FormControl(orderline.QuantityDelivered,
                    Validators.compose([Validators.required, Validators.pattern('\\d+')]));
                const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
                formGroup.addControl('o' + orderline.LineNo, control);

                this.identity--;
            }
        });
    }

     async leggTilProdukt() {
        const modal = await this.modalCtrl.create({
            component: LeggTilProduktSalesComponent,
            cssClass: 'legg-til-produkt-sales'
        });
        await modal.present();
        modal.onDidDismiss().then((data: any) => {
            if (data.data) {
                const orderline: any = {
                    ProductColorCode: data.data.Product.ColorCode,
                    ProductShortCode: data.data.Product.ShortCode,
                    Description: data.data.Product.Description,
                    LineNo: this.identity,
                    ProductNo: data.data.Product.ProductNo,
                    Quantity: data.data.Quantity,
                    QuantityDelivered: data.data.Quantity,
                    UnitPrice: data.data.Product.UnitPrice,
                    UnitCost: data.data.Product.UnitCost,
                    UnitFreightCost:0,
                    UnitDiscount: 0,
                    OrderLineTotal: 0,
                    UserChangedCost:false,
                    UserChangedPrice:false,
                    UserChangedFreight:false
                };
                this.orderLines.push(orderline);
                const formGroup: FormGroup = this.detailsForm.controls.orderLines as FormGroup;
                this.dynamicControl(formGroup, orderline);
                this.identity--;
            }
        });
    }

      dynamicControl(formGroup: FormGroup, orderline) {
        orderline.changeProduct = false;
        const quantityDelivered: FormControl = new FormControl(orderline.QuantityDelivered,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const price: FormControl = new FormControl(orderline.UnitPrice,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const discount: FormControl = new FormControl(orderline.UnitDiscount,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));
        const cost: FormControl = new FormControl(orderline.UnitCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));

            const freight: FormControl = new FormControl(orderline.UnitFreightCost,
            Validators.compose([Validators.required, Validators.pattern('\\d+(\.\d{2})?|\.\d{2}')]));


        if (this.allowDescriptionChange) {
            const productNo: FormControl = new FormControl(orderline.Description, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        } else {
            const productNo: FormControl = new FormControl(orderline.ProductNo, Validators.required);
            formGroup.addControl('productNo' + orderline.LineNo, productNo);
        }
        formGroup.addControl('quantity' + orderline.LineNo, quantityDelivered);
        formGroup.addControl('price' + orderline.LineNo, price);
        formGroup.addControl('discount' + orderline.LineNo, discount);
        formGroup.addControl('UnitFreightCost' + orderline.LineNo, freight);
        formGroup.addControl('cost' + orderline.LineNo, cost);

        
    }


      async findCustomer(query) {
        
      
        const modal = await this.modalCtrl.create({
            component: CustomerSearchComponent,
            cssClass: 'customer-search.component', componentProps: { query: query }
        });
        this.customerModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
            if (data) {
                
                this.customerSelected(data.data.customer);
               
            }
        });
    }

       async findCustomerAddress(query) {
        
        const modal = await this.modalCtrl.create({
            component: CustomerAddressSearchComponent,
            cssClass: 'customer-address-search.component', componentProps: { customerNo: this.selectedCustomer.CustomerNo, addressList: this.selectedCustomer.AddressList, query: query }
        });
        this.customerAddressModal = true;
        await modal.present();

        modal.onDidDismiss().then((data: any) => {
           
          try {
             if (data) {
               if(data != null)
                this.addressSelected(data.data.address);
               
            }
          } catch (error) {
            
          }
           
        });
    }


    async initCustomerSearch()
    {
        this.findCustomer(this.getFormControl('customerName').value);
    }
    
    async initCustomerAddressSearch()
    {
        if(this.selectedCustomer != null)
            this.findCustomerAddress(this.getFormControl('address').value);
        else
            {
             this.translateService.get('General.SelectCustomer').subscribe(value => {
                        this.toastController.create({
                            message: value,
                            duration: 3000
                        }).then(toast => { toast.present(); });

                    });
      }
    }

    navigateToOrdersList() {
        this.navCtrl.pop();
    }

     async  createNewOrder_old() {
        this.submitAttempt = true;
        if (this.detailsForm.valid) {
            const controls = this.detailsForm.controls;
            const order: any = {};

       //     if (!this.selectedCustomer || (this.selectedCustomer && this.selectedCustomer.CustomerNo === 0)) {

                order.CustomerID = 0;
                order.CustomerName = this.getFormControl('customerName').value;
                order.CustomerAddress1 = this.getFormControl('customerAddress1').value;
                order.CustomerAddress2 = this.getFormControl('customerAddress2').value;
                order.CustomerCity = this.getFormControl('customerCity').value;
                order.CustomerPostalCode = this.getFormControl('customerPostalCode').value;

                order.DeliveryName = this.getFormControl('address').value;
                order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                order.DeliveryCity = this.getFormControl('addressCity').value;
                order.DeliveryPostalCode = this.getFormControl('addressZip').value;

         //   } else {
                order.CustomerID = this.selectedCustomer.CustomerNo;


             //   if (this.addressID <= 0) {
                    order.DeliveryName = this.getFormControl('address').value;
                    order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                    order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                    order.DeliveryCity = this.getFormControl('addressCity').value;
                    order.DeliveryPostalCode = this.getFormControl('addressZip').value;


              //  } else {
                    order.DeliveryActorNo = this.addressID;
             //   }
        //    }
            order.CustomerOrgNo = this.getFormControl('organizationNo').value;
            order.DeliveryDate = this.getFormControl('deliveryDate').value;
            order.ConfirmedDeliveryDate = order.DeliveryDate;
            order.CustomerBuyer = this.getFormControl('customerBuyer').value;
            order.Email = this.getFormControl('email').value;
            order.PhoneOnSite = this.getFormControl('phoneOnSite').value;
            order.PhoneManager = this.getFormControl('phoneManager').value;
            order.InternalInfo = this.getFormControl('internalInfo').value;
            order.CustomerPurchaseOrderNo = this.getFormControl('customerPurchaseOrderNo').value;
            order.TankInfo = this.getFormControl('tankInfo').value;
            order.DeliveryEmail = this.getFormControl('DeliveryEmail').value;


            if (this.DoNotSendGPS) { // do not send gps location
                order.Longitude = -1;
                order.Latitude = -1;
                var arrGPS = this.getFormControl('gps').value.split(",");
            } else {
                
                //check GPS
                if(this.getFormControl('gps').value.includes(","))
                {
                try {
                    order.DeliveryGPS = this.getFormControl('gps').value;
                    var arrGPS = this.getFormControl('gps').value.split(",");

                order.Latitude = arrGPS[0];
                order.Longitude = arrGPS[1];

                } catch (error) {
                    
                }
            }
            else
            {
                  var arrGPS = this.getFormControl('gps').value.split(",");

                order.Latitude = arrGPS[0];
                order.Longitude = arrGPS[1];
                order.DeliveryGPS = this.getFormControl('gps').value;
            }
        }

            //set values if planner is in use
            if(this.UsePlanner)
            {
             //   this.storage.get(AppSettings.StorageKeys.tr).then(data => { order.Seller = data; });
             order.RouteNo = this.SelectedRoute == 0 ? 1 : this.SelectedRoute;
            }

            order.Orderlines = this.orderLines;
            order.OrderComments = this.getFormControl('orderComments').value;
            order.orderInternalComments = this.getFormControl('orderInternalComments').value;
            order.CustomerReference = this.getFormControl('customerReference').value;
            order.AdditionalProductsDelivered = this.getFormControl('additionalProductsDelivered').value;
            order.PriceAgreementID = this.getFormControl('priceAgreementID').value;
                        order.Transporter = this.getFormControl('transporterID').value;

            order.DeliveryInfo = this.getFormControl('deliveryInfo').value;
            this.storage.get(AppSettings.StorageKeys.UserFullName).then(data => { order.Seller = data; });
            order.DoNotCalculateVolume = this.DoNotCalcVolToStandard;
            this.storage.get(AppSettings.StorageKeys.Depot).then(data => { order.DepotNo = data; });
            order.LoadingTemp = AppSettings.LoadingTemp;
            order.TotalOrder = this.totalOrder;
            order.InternalOrderType = InternalOrdertype.Bulk;
            this.storage.get(AppSettings.StorageKeys.CompanyNo).then(data => { order.CompanyNo = data; });
            const modal = await this.modalCtrl.create({
                component: OrderConfirmComponent,
                cssClass: 'order-confirm-sales',
                componentProps: { order }

            });
            modal.present();
            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });

                        loader.present().then(async () => {
                            (await this.userService.createNewOrder(order)).subscribe(resp => {
                                this.clearFormControl(this.detailsForm);
                              //  this.detailsForm.reset();
                                loader.dismiss();
                                if (resp) {
                                    if(this.CameFromPlanner == 'true')
                                    this.router.navigate(['/order-planner'], this.navigationExtras);
                                    else
                                    this.router.navigate(['/orders'], this.navigationExtras);
                                }
                                
                            });
                        });
                    });
                }
            });
        }
    }

    
    async  createNewOrder() {
        this.submitAttempt = true;
        if (this.detailsForm.valid) {
            const controls = this.detailsForm.controls;
            const order: any = {};

            if (!this.selectedCustomer || (this.selectedCustomer && this.selectedCustomer.CustomerNo === 0)) {

                order.CustomerID = 0;
                order.CustomerName = this.getFormControl('customerName').value;
                order.CustomerAddress1 = this.getFormControl('customerAddress1').value;
                order.CustomerAddress2 = this.getFormControl('customerAddress2').value;
                order.CustomerCity = this.getFormControl('customerCity').value;
                order.CustomerPostalCode = this.getFormControl('customerPostalCode').value;

                order.DeliveryName = this.getFormControl('address').value;
                order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                order.DeliveryCity = this.getFormControl('addressCity').value;
                order.DeliveryPostalCode = this.getFormControl('addressZip').value;

            } else {
                order.CustomerID = this.selectedCustomer.CustomerNo;


                if (this.addressID <= 0) {
                    order.DeliveryName = this.getFormControl('address').value;
                    order.DeliveryAddress1 = this.getFormControl('addressAddress1').value;
                    order.DeliveryAddress2 = this.getFormControl('addressAddress2').value;
                    order.DeliveryCity = this.getFormControl('addressCity').value;
                    order.DeliveryPostalCode = this.getFormControl('addressZip').value;


                } else {
                    order.DeliveryActorNo = this.addressID;
                }
            }
            order.CustomerOrgNo = this.getFormControl('organizationNo').value;
            order.DeliveryDate = this.getFormControl('deliveryDate').value;
            order.ConfirmedDeliveryDate = order.DeliveryDate;
            order.CustomerBuyer = this.getFormControl('customerBuyer').value;
            order.Email = this.getFormControl('email').value;
            order.PhoneOnSite = this.getFormControl('phoneOnSite').value;
            order.PhoneManager = this.getFormControl('phoneManager').value;
            order.InternalInfo = this.getFormControl('internalInfo').value;
            order.CustomerPurchaseOrderNo = this.getFormControl('customerPurchaseOrderNo').value;
            order.TankInfo = this.getFormControl('tankInfo').value;
            order.DeliveryEmail = this.getFormControl('DeliveryEmail').value;


            if (this.DoNotSendGPS) { // do not send gps location
                order.Longitude = -1;
                order.Latitude = -1;
                var arrGPS = this.getFormControl('gps').value.split(",");
            } else {
                
                //check GPS
                if(this.getFormControl('gps').value.includes(","))
                {
                try {
                    order.DeliveryGPS = this.getFormControl('gps').value;
                    var arrGPS = this.getFormControl('gps').value.split(",");

                order.Latitude = arrGPS[0];
                order.Longitude = arrGPS[1];

                } catch (error) {
                    
                }
            }
            else
            {
                  var arrGPS = this.getFormControl('gps').value.split(",");

                order.Latitude = arrGPS[0];
                order.Longitude = arrGPS[1];
                order.DeliveryGPS = this.getFormControl('gps').value;
            }
        

            //set values if planner is in use
            if(this.UsePlanner)
            {
             //   this.storage.get(AppSettings.StorageKeys.tr).then(data => { order.Seller = data; });
             order.RouteNo = this.SelectedRoute == 0 ? 1 : this.SelectedRoute;
            }

            order.Orderlines = this.orderLines;
            order.OrderComments = this.getFormControl('orderComments').value;
            order.orderInternalComments = this.getFormControl('orderInternalComments').value;
            order.CustomerReference = this.getFormControl('customerReference').value;
            order.AdditionalProductsDelivered = this.getFormControl('additionalProductsDelivered').value;
            order.PriceAgreementID = this.getFormControl('priceAgreementID').value;
                        order.Transporter = this.getFormControl('transporterID').value;

            order.DeliveryInfo = this.getFormControl('deliveryInfo').value;
            this.storage.get(AppSettings.StorageKeys.UserFullName).then(data => { order.Seller = data; });
            order.DoNotCalculateVolume = this.DoNotCalcVolToStandard;
            this.storage.get(AppSettings.StorageKeys.Depot).then(data => { order.DepotNo = data; });
            order.LoadingTemp = AppSettings.LoadingTemp;
            order.TotalOrder = this.totalOrder;
            order.InternalOrderType = InternalOrdertype.Bulk;
            this.storage.get(AppSettings.StorageKeys.CompanyNo).then(data => { order.CompanyNo = data; });
            const modal = await this.modalCtrl.create({
                component: OrderConfirmSalesComponent,
                cssClass: 'order-confirm-sales',
                componentProps: { order }

            });
            modal.present();
            modal.onDidDismiss().then((data: any) => {
                if (data.data) {
                    this.translateService.get('Loading.saving').subscribe(async value => {
                        const loader = await this.loadingController.create({ message: value });

                        loader.present().then(async () => {
                            (await this.userService.createNewOrder(order)).subscribe(resp => {
                                this.clearFormControl(this.detailsForm);
                              //  this.detailsForm.reset();
                                loader.dismiss();
                                if (resp) {
                                    if(this.CameFromPlanner == 'true')
                                    this.router.navigate(['/order-planner'], this.navigationExtras);
                                    else
                                    this.router.navigate(['/orders'], this.navigationExtras);
                                }
                                
                            });
                        });
                    });
                }
            });
        }
    }else
    {
        
      await this.showValidationErrors();
    } 
    }


      clearFormControl(form: FormGroup) {
        for (const key in form.controls) {
            if (key) {
                try{form.get(key).setValue('');}catch{};
                 try{form.get(key).clearValidators();}catch{};
                 try{form.get(key).updateValueAndValidity();}catch{};

            }
            try {
                this.orderLines= [];
                
                this.selectedCustomer = null;
            } catch (error) {
                
            }
        }
    }

      changeElement($event, orderline) {
        if ($event) {
            orderline.changeProduct = false;
        } else {
            orderline.changeProduct = true;
        }
    }

     selectProduct(event, orderline) {
        if (event.type === 'change') {
            const control = this.detailsForm.controls.orderLines;
            const product = _.find(this.products, { ProductNo: event.target.value });
            if (product) {
                orderline.ProductNo = product.ProductNo;
                orderline.Description = product.Description;
                orderline.ProductShortCode = product.ShortCode;
                orderline.ProductColorCode = product.ColorCode;
                orderline.UnitPrice = product.UnitPrice;
                orderline.UnitCost = product.UnitCost;
                orderline.UnitFreightCost = 0;
                control.get('price' + orderline.LineNo).setValue(orderline.UnitPrice);
                control.get('cost' + orderline.LineNo).setValue(orderline.UnitCost);
                this.orderlineTotal(orderline);
                this.getTotalOrder();
                
            }
            if (this.allowDescriptionChange) {
                if (control.get('productNo' + orderline.LineNo).value !== '') {
                    orderline.Description = control.get('productNo' + orderline.LineNo).value;
                }
            }
        } else {
            orderline.changeProduct = false;
        }
    }

     getTotalOrder() {
        this.totalOrder = 0;
        for (const item of this.orderLines) {
            const total = +item.OrderLineTotal + (+this.totalOrder);
            this.totalOrder = Math.round(total).toFixed(2);
        }
    }

 orderlineTotal(orderline) {
        let lineTotal = 0;
        orderline.OrderLineTotal = 0;
        orderline.PriceAdditions = 0;
        if (orderline.UnitPrice >= 0) {
            if (orderline.QuantityDelivered > 0) {
                lineTotal = ((orderline.UnitPrice+orderline.PriceAdditions) * orderline.QuantityDelivered);
            } else {
                lineTotal = ((orderline.UnitPrice+orderline.PriceAdditions) * orderline.Quantity);
            }
        }

        if(orderline.UnitFreightCost != 0 || orderline.UnitFreightCost != undefined)
        {
             if (orderline.QuantityDelivered > 0) {
                lineTotal += ((orderline.UnitFreightCost) * orderline.QuantityDelivered);
            } else {
                lineTotal += ((orderline.UnitFreightCost) * orderline.Quantity);
            }
        }

        if (orderline.UnitDiscount > 0) {
            orderline.OrderLineTotal = (lineTotal - ((lineTotal * orderline.UnitDiscount) / 100)).toFixed(2);
        } else {
            orderline.OrderLineTotal = lineTotal.toFixed(2);
        }
        console.debug(lineTotal);
    }

     setValue(orderline, fieldName) {
        const control = this.detailsForm.controls.orderLines;
        if (fieldName === 'price') {
            orderline.UnitPrice = control.get('price' + orderline.LineNo).value;
            orderline.UserChangedPrice = true;
        }
        if (fieldName === 'discount') {
            orderline.UnitDiscount = control.get('discount' + orderline.LineNo).value;
        }
        if (fieldName === 'UnitFreightCost') {
            orderline.UnitFreightCost = control.get('UnitFreightCost' + orderline.LineNo).value;
            orderline.UserChangedFreight = true;
        }
        if (fieldName === 'cost') {
            orderline.UnitCost = control.get('cost' + orderline.LineNo).value;
            orderline.UserChangedCost = true;
        }
        if (fieldName === 'quantity') {
            orderline.QuantityDelivered = control.get('quantity' + orderline.LineNo).value;
        }
        if(fieldName === '')
          orderline.QuantityDelivered = control.get('o' + orderline.LineNo).value;

        this.orderlineTotal(orderline);
        this.getTotalOrder();
    }

    
    showConfirm(orderline) {
        let modalTitle;
        let modalText;
        let cancel;
        let save;
        this.translateService.get('OrdreDetails.confirmation').subscribe(value => { modalTitle = value; });
        this.translateService.get('OrdreDetails.confirmMessage').subscribe(value => { modalText = value.format(orderline.Description); });
        this.translateService.get('OrdreDetails.cancel').subscribe(value => { cancel = value; });
        this.translateService.get('OrdreDetails.delete').subscribe(value => { save = value; });
        this.alertCtrl.create({
            header: modalTitle,
            message: modalText,
            buttons: [
                {
                    text: cancel,
                    handler: () => {
                    }
                },
                {
                    text: save,
                    handler: () => {
                        this.deleteOrderLine(orderline);
                    }
                }
            ]
        }).then(confirm => { confirm.present(); });
    }
       deleteOrderLine(orderline) {
        const index = _.findIndex(this.orderLines, { ProductNo: orderline.ProductNo });
        if (index > -1) {
            this.orderLines.splice(index, 1);
            this.getTotalOrder();
        }
    }

  async showValidationErrors() {
    const formControls = this.detailsForm.controls;
    let errorMessage = 'Før du kan lagre må du ordne følgende: \n\n';
    
    for (const field in formControls) {
      if (formControls[field].invalid) {
        const errors = formControls[field].errors;
        if (errors?.required) {
          errorMessage += `${field} er påkrevd. \n`;
        }
        else if (errors?.minlength) {
          errorMessage += `${field} må være minst ${errors.minlength.requiredLength} antall tegn. \n`;
        }
        else if (errors?.email) {
          errorMessage += `${field} må være en gyldig e-post adresse. \n`;
        }
        else {
          errorMessage += `${field} inneholder feil. \n`;
        }
      }
    }

    const toast = await this.toastController.create({
      message: errorMessage,
      duration: 8000,
      color: 'danger',
      position: 'bottom',
   
     buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }
}


